import React from 'react';
import ErrorPage from './ErrorPage';

const Page404 = () => (
  <ErrorPage message="Oopssss! Page not found">
    The page you are looking for might have been removed or temporarily
    unavailable.
    <br /> Return to
    <a href="/"> homepage</a>
  </ErrorPage>
);

export default Page404;
