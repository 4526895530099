import React, { useContext, useEffect, useRef, useState } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import MdEditor from 'react-markdown-editor-lite';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import MarkdownIt from 'markdown-it';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ProductType } from '../../../../common/types';
import AppContext from '../../../App/AppContext';
import { normalisePricingTiers } from '../../../../common/helpers/billingHelper';
import generateFriendlyString from '../../../../common/helpers/generateFriendlyString';
import { compareSortByProp } from '../../../../common/helpers/arrayHelper';
import { fetchFieldsets } from '../../../../reducers/fieldsets/actions';
import {
  fetchProduct,
  updateProduct,
} from '../../../../reducers/product/actions';

import Form, {
  CreatableSelect,
  TextArea,
  TextInput,
  Toggle,
  ToggleGroup,
  useForm,
} from '../../../Form';
import Page, {
  PageBody,
  PageHeader,
  PageInner,
  PageLoading,
  PageNav,
} from '../../../Page';
import { Button } from '../../../../library/atoms/Buttons';
import PricingTable from '../PricingTable/PricingTable';
import LegacyPriceWarning from './LegacyPriceWarning';

import 'react-markdown-editor-lite/lib/index.css';
import '../../../Form/components/Markdown/Markdown.scss';
import './Admin.scss';

const mdParser = new MarkdownIt();

const getAccessFee = pricing => {
  if (Array.isArray(pricing)) {
    const price = pricing.find(p => p.type === 'access_fee');
    return price && price.accessFee;
  }
  return 0;
};

const setFormState = product => {
  const fieldsets = (product.fieldsets || []).map(f => f.id || f);

  const form = {
    id: {
      value: product.id,
    },
    productId: {
      value: product.productId,
      required: true,
    },
    name: {
      value: product.name || '',
      required: true,
    },
    description: {
      value: product.description || '',
    },
    companyId: {
      value: product.companyId || '',
    },
    'fieldsets[]': {
      value: fieldsets,
    },
    'tags[]': {
      value: product.tags || [],
    },
    tileImageUrl: {
      value: product.tileImageUrl || '',
    },
    imageUrl: {
      value: product.imageUrl || '',
    },
    defaultSortIdx: {
      value: product.defaultSortIdx || undefined,
    },
    content: {
      value: product.content || '',
    },
    documentation: {
      value: product.documentation || '',
    },
    publishOnMarketplace: {
      value: product.publishOnMarketplace || false,
    },
    stripeMeta: {
      value: product.stripeMeta || '',
    },
    pricing: {
      value: product.pricing || [],
    },
    accessFee: {
      value: getAccessFee(product.pricing || []) || undefined,
    },
    updated: {
      value: product.updated,
    },
    created: {
      value: product.created,
    },
    productType: {
      value: product.productType,
    },
  };

  return form;
};

const mapStateToProps = (state, props) => {
  const { auth, product, fieldsets } = state;
  const { match } = props;
  return {
    productId: match.params.id || null,
    fieldsets: fieldsets.data || [],
    fieldsetsLoading: fieldsets.loading,
    product: product.data || {},
    scopes: auth.claims ? auth.claims.allowedScopes : [],
    isLoading: product.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      fetchFieldsets: () => dispatch(fetchFieldsets()),
      fetchProduct: productId => dispatch(fetchProduct(productId)),
      updateProduct: (productId, product) =>
        dispatch(updateProduct(productId, product)),
    },
  };
};

const EditProduct = ({
  productId,
  fieldsets,
  fieldsetsLoading,
  product,
  isLoading,
  dispatch,
}) => {
  const initialState = setFormState(product);
  const [form] = useForm(initialState);
  const { fields, errors } = form;
  const [fieldsetError, setFieldsetError] = useState({});
  const [appState, setAppState] = useContext(AppContext);

  const fieldsetRef = useRef(null);
  const productNameRef = useRef(null);
  const buttonGroupRef = useRef(null);
  const fieldsetOptions = (fieldsets || []).map(f => ({
    value: f.id,
    label: f.id,
  }));

  const productTypeOptions = [
    {
      label: 'Capture AI',
      value: ProductType.Capture,
    },
    {
      label: 'Signals AI',
      value: ProductType.Signals,
    },
    {
      label: 'Tools',
      value: 'platform',
    },
  ];

  const [pricingRows, setPricingRows] = useState([]);
  const [legacyPricingRows, setLegacyPricingRows] = useState([]);

  const setProductId = id => {
    if (product.idEditable) {
      const suggestedProductId = generateFriendlyString(id);
      form.handleChange({
        target: { name: 'productId', value: suggestedProductId },
      });
    }
  };

  useEffect(() => {
    productNameRef.current.focus();
  }, []);

  useEffect(() => {
    if (
      Object.keys(product).length > 0 &&
      Array.isArray(product.pricing) &&
      product.pricing.length > 0
    ) {
      product.pricing.forEach(price => {
        if (price.type === 'metered') {
          const pRows = [...price.tiers];
          pRows.sort(compareSortByProp('threshold'));
          const updatedRows = pRows.map((current, idx, array) => {
            const arr = { ...current };

            // 2nd row up
            if (idx) {
              const firstUnit = array[idx - 1].threshold + 1;
              arr.firstUnit = firstUnit;
              return arr;
            }

            // 1st row
            arr.firstUnit = 0;
            return arr;
          });
          setPricingRows(updatedRows);
          setLegacyPricingRows([]);
        }
        if (price.type === 'access_fee') {
          form.handleChange({
            target: {
              name: 'accessFee',
              value: price.accessFee,
            },
          });
        }
      });
    } else if (
      Object.keys(product).length > 0 &&
      product.pricing &&
      product.pricing.tiers
    ) {
      const pRows = [...product.pricing.tiers];
      pRows.sort(compareSortByProp('threshold'));
      const updatedRows = pRows.map((current, idx, array) => {
        const arr = { ...current };

        // 2nd row up
        if (idx) {
          const firstUnit = array[idx - 1].threshold + 1;
          arr.firstUnit = firstUnit;
          return arr;
        }

        // 1st row
        arr.firstUnit = 0;
        return arr;
      });
      setLegacyPricingRows(updatedRows);
      setPricingRows([]);
    } else {
      setPricingRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  useEffect(() => {
    if (productId) {
      dispatch.fetchProduct(productId);
    }
  }, [dispatch, productId]);

  useEffect(() => {
    if (!fieldsetsLoading && fieldsets.length === 0) {
      dispatch.fetchFieldsets();
    }
  }, [dispatch, fieldsetsLoading, fieldsets]);

  useEffect(() => {
    if (product) {
      form.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  useEffect(() => {
    if (appState.isScrollLock !== isLoading) {
      setAppState(x => ({ ...x, isScrollLock: isLoading }));
    }
  }, [appState, setAppState, appState.isScrollLock, isLoading]);

  useEffect(() => {
    const pricing = [];
    if (
      (fields.productType.value === ProductType.Capture ||
        fields.productType.value === ProductType.Signals) &&
      fields.accessFee.value
    ) {
      pricing.push({
        type: 'access_fee',
        accessFee: fields.accessFee.value,
      });
    }
    if (
      (fields.productType.value === ProductType.Capture ||
        fields.productType.value === ProductType.Signals) &&
      pricingRows.length > 0
    ) {
      pricing.push({ type: 'metered', tiers: pricingRows });
    }
    if (pricing.length > 0) {
      form.handleChange({
        target: {
          name: 'pricing',
          value: pricing,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricingRows, fields.accessFee, fields.productType.value]);

  const isFormValid = () => {
    return fields.name.length > 0;
  };

  const handleSubmit = e => {
    e.preventDefault();

    const productData = {
      name: fields.name.value, // string
      productId: fields.productId.value,
      description: fields.description.value, // string
      companyId: fields.companyId.value, // uuid
      fieldsets: fields['fieldsets[]'].value, // string[]
      tags: fields['tags[]'].value, // string[]
      tileImageUrl: fields.tileImageUrl.value, // string,
      imageUrl: fields.imageUrl.value, // string,
      defaultSortIdx: fields.defaultSortIdx.value
        ? Number(fields.defaultSortIdx.value)
        : null, // number,
      content: fields.content.value, // string,
      documentation: fields.documentation.value, // string
      publishOnMarketplace: fields.publishOnMarketplace.value, // bool
      stripeMeta: product.stripeMeta,
      productType: product.productType,
    };

    const pricing = fields.pricing.value;
    if (pricing && pricing.length > 0) {
      productData.pricing = pricing.map(p =>
        p.type === 'metered'
          ? { ...p, tiers: normalisePricingTiers(p.tiers) }
          : p,
      );
    }

    // Focus on product name if failed validation
    if (productData.name.length <= 0) {
      productNameRef.current.focus();
      return;
    }

    // Validate fieldsets on submit
    if (
      (fields.productType.value === ProductType.Capture ||
        fields.productType.value === ProductType.Signals) &&
      productData.fieldsets.length <= 0
    ) {
      setFieldsetError({
        'fieldsets[]': 'Please add at least one fieldset.',
      });
      fieldsetRef.current.focus();
      return;
    }
    setFieldsetError({});

    // Submit form
    dispatch.updateProduct(productId, productData);
  };

  const handleProductIdChange = e => {
    setProductId(e.target.value);
  };

  const handleEditorChange = ({ text }, name) => {
    form.handleChange({
      target: { name, value: text },
    });
  };

  const handleSelectChange = (option, name) => {
    form.handleChange({
      target: { name, value: (option || []).map(o => o.value) },
    });
  };

  const productTypeLabel = productTypeOptions.find(
    p => p.value === fields.productType.value,
  )
    ? productTypeOptions.find(p => p.value === fields.productType.value).label
    : '';

  return (
    <Page
      className="marketplace marketplace-form"
      title={
        product.name
          ? `Edit ${product.name} - Marketplace Admin`
          : 'Marketplace Admin'
      }
    >
      <PageInner>
        <PageNav
          items={[
            { label: 'Marketplace', to: '/marketplace' },
            { label: 'Admin', to: '/marketplace/admin' },
            { label: 'Products', to: '/marketplace/admin/products' },
            {
              label: product.name,
              to: `/marketplace/admin/products/${product.productId}/edit`,
            },
          ]}
        />
        <PageHeader>
          <h1 className="page__heading">
            <span className="text--grey">Edit: </span>
            {product ? (
              <>
                {product.name}
                <Link
                  className="external-link"
                  to={`/marketplace/products/${product.productId}`}
                >
                  <FaExternalLinkAlt />
                </Link>
              </>
            ) : (
              'product'
            )}
          </h1>
        </PageHeader>
        <PageBody>
          {product && (
            <Form onSubmit={handleSubmit}>
              <TextInput
                name="productType"
                label="Product Type"
                value={productTypeLabel}
                errors={errors}
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                disabled
              />
              <TextInput
                label="Company id"
                name="companyId"
                value={fields.companyId.value}
                errors={errors}
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                disabled
                autoFocus
              />
              <TextInput
                label="Product name"
                name="name"
                value={fields.name.value}
                errors={errors}
                forwardRef={productNameRef}
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                required
              />
              <TextInput
                label="Product Id"
                name="productId"
                value={fields.productId.value}
                errors={errors}
                onBlur={e => handleProductIdChange(e)}
                onChange={e => handleProductIdChange(e)}
                disabled={!product.idEditable}
              />
              <TextArea
                label="Description"
                name="description"
                value={fields.description.value}
                errors={errors}
                onBlur={form.handleBlur}
                onChange={form.handleChange}
              />
              {(fields.productType.value === ProductType.Capture ||
                fields.productType.value === ProductType.Signals) && (
                <CreatableSelect
                  label="Fieldsets *"
                  name="fieldsets[]"
                  options={fieldsetOptions}
                  value={fields['fieldsets[]'].value.map(f => ({
                    value: f,
                    label: f,
                  }))}
                  errors={fieldsetError}
                  forwardRef={fieldsetRef}
                  isClearable={false}
                  onChange={e => handleSelectChange(e, 'fieldsets[]')}
                />
              )}
              {
                <CreatableSelect
                  label="Tags"
                  name="tags[]"
                  value={fields['tags[]'].value.map(f => ({
                    value: f,
                    label: f,
                  }))}
                  isClearable={false}
                  onChange={e => handleSelectChange(e, 'tags[]')}
                />
              }
              <TextInput
                label="Tile image url"
                name="tileImageUrl"
                value={fields.tileImageUrl.value}
                errors={errors}
                onBlur={form.handleBlur}
                onChange={form.handleChange}
              />
              <TextInput
                label="Product image url"
                name="imageUrl"
                value={fields.imageUrl.value}
                errors={errors}
                onBlur={form.handleBlur}
                onChange={form.handleChange}
              />
              <div className="form__group">
                <span className="form__input-label form__input-label--md">
                  Content
                </span>
                <MdEditor
                  value={fields.content.value}
                  renderHTML={text => mdParser.render(text)}
                  onChange={e => handleEditorChange(e, 'content')}
                />
              </div>
              <div className="form__group">
                <span className="form__input-label form__input-label--md">
                  Documentation
                </span>
                <MdEditor
                  value={fields.documentation.value}
                  renderHTML={text => mdParser.render(text)}
                  onChange={e => handleEditorChange(e, 'documentation')}
                />
              </div>
              <TextInput
                label="Sort order"
                name="defaultSortIdx"
                value={fields.defaultSortIdx.value}
                errors={errors}
                onBlur={form.handleBlur}
                onChange={form.handleChange}
              />
              <ToggleGroup
                label="Published on marketplace"
                labelFor="publishOnMarketplace"
                type="single"
              >
                <Toggle
                  id="publishOnMarketplace"
                  labelPosition="right"
                  name="publishOnMarketplace"
                  checked={fields.publishOnMarketplace.value}
                  onBlur={form.handleBlur}
                  onChange={e => form.handleChange(e, e.target.checked)}
                />
              </ToggleGroup>
              {(fields.productType.value === ProductType.Capture ||
                fields.productType.value === ProductType.Signals) && (
                <>
                  {legacyPricingRows.length > 0 && (
                    <LegacyPriceWarning pricingRows={legacyPricingRows} />
                  )}
                  <TextInput
                    label="Monthly Access Fee"
                    name="accessFee"
                    type="number"
                    value={fields.accessFee.value}
                    errors={errors}
                    onBlur={form.handleBlur}
                    onChange={form.handleChange}
                  />
                  <div className="form__group">
                    <span className="form__input-label form__input-label--md">
                      Pricing
                    </span>
                    <PricingTable
                      rows={pricingRows}
                      onUpdate={setPricingRows}
                    />
                  </div>
                </>
              )}
              <TextInput
                label="Last updated"
                name="updated"
                value={moment(fields.updated.value).format(
                  'ddd, MMM DD YYYY, hh:mm A',
                )}
                errors={errors}
                disabled
              />
              <TextInput
                label="Created"
                name="created"
                value={moment(fields.created.value).format(
                  'ddd, MMM DD YYYY, hh:mm A',
                )}
                errors={errors}
                disabled
              />
              <div
                className="button-group"
                id="button_group"
                ref={buttonGroupRef}
              >
                <NavLink
                  to="/marketplace/admin/products"
                  className="button button-secondary-border"
                >
                  Cancel
                </NavLink>
                <Button
                  className="submit-button"
                  type="submit"
                  disabled={isFormValid()}
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
          {isLoading && <PageLoading />}
        </PageBody>
      </PageInner>
    </Page>
  );
};

EditProduct.propTypes = {
  productId: PropTypes.string,
  fieldsets: PropTypes.arrayOf(PropTypes.shape({})),
  fieldsetsLoading: PropTypes.bool.isRequired,
  product: PropTypes.shape({
    idEditable: PropTypes.bool,
    pricing: PropTypes.arrayOf(PropTypes.shape({})),
    name: PropTypes.string,
    stripeMeta: PropTypes.arrayOf(PropTypes.shape({})),
    productType: PropTypes.string,
    productId: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  dispatch: PropTypes.shape({
    fetchProduct: PropTypes.func,
    fetchFieldsets: PropTypes.func,
    updateProduct: PropTypes.func,
  }),
};

EditProduct.defaultProps = {
  productId: PropTypes.string,
  fieldsets: null,
  product: null,
  isLoading: false,
  dispatch: {
    fetchProduct: () => {},
    fetchFieldsets: () => {},
    updateProduct: () => {},
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProduct);
