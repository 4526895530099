import React, { Fragment } from 'react';
import classNames from 'classnames';
import {
  FaDatabase,
  FaCheckCircle,
  FaExclamationTriangle,
} from 'react-icons/fa';

const SignalValue = ({ field }) => {
  const isHappy = field.value_norm >= 0.75;
  const signalClassName = isHappy ? 'happy' : 'sad';

  return (
    <div className={classNames('field', 'stats', 'signals')}>
      <h3>{field.fieldName}</h3>
      {field.source.sources.map((s, i) => (
        <div className="stats-header">
          <FaDatabase /> {s}
        </div>
      ))}
      <div className={`stat-item ${signalClassName}`}>
        {isHappy ? <FaCheckCircle /> : <FaExclamationTriangle />}
        {Math.round(field.value_norm * 1000) / 10}%
      </div>
    </div>
  );
};

export default SignalValue;
