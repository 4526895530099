import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Switch, Route } from 'react-router';
import { Redirect } from 'react-router-dom';
import Admin from '../Admin/Admin';
import Profile from '../Profile/Profile';
import Credentials from '../Credentials/Credentials';
import AuthRoute from '../AuthRoute/AuthRoute';
import App from '../App/App';
import Callback from '../Callback/Callback';
import Documents from '../../pages/Documents/Documents';
import Document from '../Document/Document';
import Login from '../Login/Login';
import Marketplace from '../Marketplace';
import MarketplaceCatalog from '../Marketplace/MarketplaceCatalog';
import Analytics from '../Analytics';
import ForgotPassword from '../Password/ForgotPassword';
import Register from '../Register/Register';
import RegisterInvite from '../Register/RegisterInvite';
import EmailIntegration from '../../pages/Settings/EmailIntegration';
import Task from '../Task/Task';
import Tasks from '../../pages/Tasks/Tasks';
import Workbench from '../Workbench/Workbench';
import { Page404, Page403 } from '../ErrorPage';
import Users from '../Users/Users';
import User from '../User/User';
import Capture, { CaptureDemo } from '../Capture';
import Insights from '../Insights/Insights';
import Signals from '../Signals/Signals';
import Onboarding from '../Onboarding/Onboarding';
import DocSplit from '../DocSplit/DocSplit';
import { Tags } from '../Tags';
import TagsV2 from '../../pages/Settings/Tags';
import {
  CreateValidateRule,
  EditValidateRule,
  ValidateRules,
} from '../Validate';
import './Routes.scss';
import TaskRedirect from '../Task/TaskRedirect';
import PlatformTourPanel from '../OnboardingTour/PlatformTourPanel';

const NoPage = () => <div />;

const mapStateToProps = state => {
  const { auth } = state;
  return {
    history: state.history,
    canAdministrateCredentials: auth.permissions.canAdministrateCredentials,
    canAdministrateUsers: auth.permissions.canAdministrateUsers,
    canAdministrateCompany: auth.permissions.canAdministrateCompany,
    canSuperUserRead: auth.permissions.canSuperUserRead,
    canEditTaskRules: auth.permissions.canEditTaskRules,
    canSuperUserWrite: auth.permissions.canSuperUserWrite,
    canReadAnyAnalytics: auth.permissions.canReadAnyAnalytics,
    canViewMarketplace: auth.permissions.canViewMarketplace,
  };
};

class Routes extends Component {
  render() {
    const {
      canAdministrateCredentials,
      canAdministrateUsers,
      canAdministrateCompany,
      canEditTaskRules,
      canSuperUserRead,
      canSuperUserWrite,
      canReadAnyAnalytics,
      canViewMarketplace,
    } = this.props;
    return (
      <Switch>
        <Route path="/demo/signals" component={Signals} />
        <Route path="/demo/capture/:productType" component={CaptureDemo} />
        <Route
          exact
          path="/demo/capture"
          render={() => (
            <Redirect to={`/demo/capture/invoices${window.location.search}`} />
          )}
        />
        <Route exact path="/demo/insights" component={Insights} />
        <Route path="/marketplace/catalog" component={MarketplaceCatalog} />
        <App>
          <Switch>
            <Route exact path="/" component={withRouter(Login)} />
            <Route exact path="/register" component={withRouter(Register)} />
            <Route
              exact
              path="/register/user/:serial"
              component={withRouter(RegisterInvite)}
            />
            <Route
              exact
              path="/forgotpassword"
              component={withRouter(ForgotPassword)}
            />
            <Route exact path="/callback" render={Callback} />
            <AuthRoute back="/">
              <Switch>
                <Route path="/capture/:docId" component={Capture} />
                <Route exact path="/tasks" component={withRouter(Tasks)} />
                <Route path="/tasks/:taskId/user/:userId" component={Task} />
                <Route path="/tasks/:taskId" component={TaskRedirect} />
                <Route
                  path="/documents"
                  exact
                  component={withRouter(Documents)}
                />
                <Route
                  path="/documents/split/:taskId/user/:userId"
                  component={DocSplit}
                />
                <Route
                  path="/documents/:docId"
                  exact
                  component={withRouter(Document)}
                />
                <Route path="/admin" component={withRouter(Admin)} />
                {canReadAnyAnalytics && (
                  <Route path="/analytics" component={withRouter(Analytics)} />
                )}
                {canViewMarketplace && (
                  <Route
                    path="/marketplace"
                    component={withRouter(Marketplace)}
                  />
                )}
                <Route path="/profile" component={withRouter(Profile)} />
                {canAdministrateCredentials && (
                  <Route
                    path="/credentials"
                    component={withRouter(Credentials)}
                  />
                )}
                {canAdministrateUsers && (
                  <Route exact path="/users" component={withRouter(Users)} />
                )}
                {canAdministrateUsers && (
                  <Route exact path="/users/:id" component={withRouter(User)} />
                )}
                {canEditTaskRules && (
                  <Route
                    exact
                    path="/validate/rules"
                    component={withRouter(ValidateRules)}
                  />
                )}
                {canEditTaskRules && (
                  <Route
                    exact
                    path="/validate/rules/create"
                    component={withRouter(CreateValidateRule)}
                  />
                )}
                {canEditTaskRules && (
                  <Route
                    exact
                    path="/validate/rules/:productId"
                    component={withRouter(EditValidateRule)}
                  />
                )}
                {canAdministrateCompany && (
                  <Route
                    exact
                    path="/settings/email"
                    component={withRouter(EmailIntegration)}
                  />
                )}
                {canSuperUserRead && (
                  <Route path="/workbench" component={withRouter(Workbench)} />
                )}
                <Route exact path="/tags" component={withRouter(Tags)} />
                {canSuperUserWrite && (
                  <Route exact path="/v2/tags" component={withRouter(TagsV2)} />
                )}
                <Route path="/404" component={Page404} />
                <Route path="/403" component={Page403} />
                <Redirect to="/404" />
              </Switch>
              <Onboarding />
              <PlatformTourPanel />
            </AuthRoute>
            <Route path="*" component={NoPage} />
          </Switch>
        </App>
      </Switch>
    );
  }
}

Routes.propTypes = {
  canAdministrateCredentials: PropTypes.bool,
  canAdministrateCompany: PropTypes.bool,
  canAdministrateUsers: PropTypes.bool,
  canEditTaskRules: PropTypes.bool,
  canSuperUserRead: PropTypes.bool,
  canSuperUserWrite: PropTypes.bool,
  canReadAnyAnalytics: PropTypes.bool,
  canViewMarketplace: PropTypes.bool,
};

Routes.defaultProps = {
  canAdministrateCredentials: false,
  canAdministrateCompany: false,
  canAdministrateUsers: false,
  canEditTaskRules: false,
  canSuperUserRead: false,
  canSuperUserWrite: false,
  canReadAnyAnalytics: false,
  canViewMarketplace: false,
};

export default withRouter(connect(mapStateToProps)(Routes));
