import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { TabsContext } from './TabsContext';
import './Tabs.scss';

const TabPanel = ({ id, children, className }) => {
  const [tabs] = useContext(TabsContext);

  return (
    <div
      className={classNames(className, 'tab-panel', {
        'tab-panel--active': tabs.active === id,
      })}
    >
      {children}
    </div>
  );
};

TabPanel.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};

TabPanel.defaultProps = {
  className: null,
  children: null,
};

export default TabPanel;
