export const convertToBase64 = async file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      return resolve(reader.result);
    };

    reader.onerror = error => {
      console.error('Error converting file to Base64:', error);
      reject(new Error('Error converting file to Base64.'));
    };
  });
};
