import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { FaInfoCircle } from 'react-icons/fa';
import PropTypes from 'prop-types';

import Page, { PageBody, PageLoading, PageRow } from '../../../../Page';
import {
  ToggleContainer,
  ToggleButton,
  ToggleGroup,
} from '../../../../UI/ToggleGroup';

import DashboardDropdown from '../../../../Analytics/components/DashboardDropdown/DashboardDropdown';

import { fetchSubscriptions } from '../../../../../reducers/admin/actions';
import { fetchSubscriptionStats } from '../../../../../reducers/analytics/actions';
import PredictionsGraph from '../../../../Analytics/components/PredictionsGraph/PredictionsGraph';
import Card, { CardHeader, CardContent } from '../../../../UI/Card';
import ErrorPage from '../../../../ErrorPage/ErrorPage';

const ALL_PRODUCTS = { label: 'All products', value: 'all' };

const mapStateToProps = state => {
  const { admin, analytics, auth } = state;

  return {
    billingPeriods: admin.subscriptions.billingPeriods,
    subscriptions: admin.subscriptions.data,
    subscriptionStats: analytics.data.stats,
    user: auth.user.data,
    isLoading: analytics.loading,
    totalSpend: analytics.data.totalSpend,
    totalPages: analytics.data.totalPages,
    totalDocs: analytics.data.totalDocs,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      fetchSubscriptions: companyId => dispatch(fetchSubscriptions(companyId)),
      fetchSubscriptionStats: filters =>
        dispatch(fetchSubscriptionStats(filters)),
    },
  };
};

const Billing = ({
  billingPeriods,
  subscriptions,
  subscriptionStats,
  dispatch,
  isLoading,
  totalSpend,
  totalPages,
  totalDocs,
  companyId,
}) => {
  const [product, setProduct] = useState(ALL_PRODUCTS);
  const [billingPeriod, setBillingPeriod] = useState(null);
  const [timeFilter, setTimeFilter] = useState('days');
  const [mode, setMode] = useState('spend');

  const changeTimeFilter = type => {
    setTimeFilter(type);
  };

  const changeMode = type => {
    setMode(type);
  };

  // DROPDOWN VALUES
  const productOptions = (subscriptions || []).map(s => ({
    label: s.productId,
    value: s.productId,
  }));

  const billingPeriodOptions = (billingPeriods || [])
    .map(b => ({
      label: `${b.startLabel} - ${b.endLabel}`,
      value: b.id,
      products: b.products,
      startDate: b.startDate,
      endDate: b.endDate,
    }))
    .filter(s => {
      if (!product || product.value === 'all') return true;
      return s.products.indexOf(product.value) > -1;
    });

  const updateFieldsetUsage = () => {
    if (!billingPeriod) return;

    const { startDate, endDate, products } = billingPeriod;
    const selectedProducts =
      !product || product.value === 'all' ? products : [product.value];

    dispatch.fetchSubscriptionStats({
      startDate,
      endDate,
      products: selectedProducts,
      companyId,
    });
  };

  // USE EFFECT: ON LOAD / SUBSCRIPTIONS CHANGE
  useEffect(() => {
    if (companyId) {
      dispatch.fetchSubscriptions(companyId);
    }
  }, [companyId]);

  // USE EFFECT: BILLING PERIODS LOADED
  useEffect(() => {
    setBillingPeriod(billingPeriodOptions[0]);
  }, [billingPeriods]);

  // USE EFFECT: DROPDOWN CHANGE
  useEffect(() => {
    updateFieldsetUsage();
  }, [product, billingPeriod, companyId]);

  // EVENT HANDLERS
  const handleProductChange = fieldSetValue => {
    setProduct(fieldSetValue);
  };

  const handleBillingPeriodChange = fieldSetValue => {
    setBillingPeriod(fieldSetValue);
  };

  return (
    <Page className="analytics" title="Billing Dashboard">
      <PageBody>
        {/* eslint-disable-next-line no-nested-ternary */}
        {!subscriptionStats && !isLoading ? (
          <ErrorPage message="Oopssss! No Subscriptions Found" />
        ) : subscriptionStats ? (
          <>
            <PageRow>
              <div className="analytics__stats">
                <div className="analytics__stats-graph">
                  <PredictionsGraph
                    title="Successful Predictions vs Time"
                    product={product}
                    billingPeriod={billingPeriod}
                    subscriptionStats={subscriptionStats}
                    mode={mode}
                    timeFilter={timeFilter}
                    isVisible
                  />
                </div>
                <div className="analytics__stats-filters">
                  {billingPeriod && subscriptionStats && (
                    <>
                      <DashboardDropdown
                        className="dashboard__dropdown"
                        label="Select a product"
                        value={product}
                        defaultValue={ALL_PRODUCTS}
                        options={productOptions}
                        onChange={handleProductChange}
                      />
                      <DashboardDropdown
                        className="dashboard__dropdown"
                        label="Select billing period"
                        value={billingPeriod}
                        options={billingPeriodOptions}
                        onChange={handleBillingPeriodChange}
                      />

                      <ToggleContainer>
                        <ToggleGroup defaultActive="spendView">
                          <ToggleButton
                            onClick={() => changeMode('spend')}
                            buttonFor="spendView"
                          >
                            $ Spend
                          </ToggleButton>
                          <ToggleButton
                            onClick={() => changeMode('usage')}
                            buttonFor="usageView"
                          >
                            # Pages
                          </ToggleButton>
                          <ToggleButton
                            onClick={() => changeMode('docs')}
                            buttonFor="docsView"
                          >
                            # Docs
                          </ToggleButton>
                        </ToggleGroup>
                      </ToggleContainer>

                      <ToggleContainer>
                        <ToggleGroup defaultActive="dayView">
                          <ToggleButton
                            onClick={() => changeTimeFilter('days')}
                            buttonFor="dayView"
                          >
                            D
                          </ToggleButton>
                          <ToggleButton
                            onClick={() => changeTimeFilter('weeks')}
                            buttonFor="weekView"
                          >
                            W
                          </ToggleButton>
                          <ToggleButton
                            onClick={() => changeTimeFilter('months')}
                            buttonFor="monthView"
                          >
                            M
                          </ToggleButton>
                        </ToggleGroup>
                      </ToggleContainer>
                    </>
                  )}
                </div>
              </div>
            </PageRow>
            <PageRow>
              {subscriptionStats && billingPeriod && (
                <div className="analytics__summary">
                  <Card className="analytics__summary-card">
                    <CardHeader>
                      <div className="analytics__summary-card__heading">
                        Selected Month Summary
                      </div>
                    </CardHeader>
                    <CardContent className="analytics__summary-card__content">
                      <div className="analytics__summary-card__row">
                        <h3 className="analytics__summary-card__row-value">
                          ${totalSpend.toFixed(2)}
                        </h3>
                        <p className="analytics__summary-card__row-description">
                          spent to date
                          <br />(
                          {moment(billingPeriod.startDate, 'YYYY-MM-D').format(
                            'DD/MM',
                          )}
                          -
                          {moment(billingPeriod.endDate, 'YYYY-MM-D').format(
                            'DD/MM',
                          )}
                          )
                        </p>
                      </div>
                      <div className="analytics__summary-card__row">
                        <h3 className="analytics__summary-card__row-value">
                          {totalPages.toCommaFormat()}
                        </h3>
                        <p className="analytics__summary-card__row-description">
                          pages extracted
                          <br />(
                          {moment(billingPeriod.startDate, 'YYYY-MM-D').format(
                            'DD/MM',
                          )}
                          -
                          {moment(billingPeriod.endDate, 'YYYY-MM-D').format(
                            'DD/MM',
                          )}
                          )
                        </p>
                      </div>
                      <div className="analytics__summary-card__row">
                        <h3 className="analytics__summary-card__row-value">
                          {totalDocs.toCommaFormat()}
                        </h3>
                        <p className="analytics__summary-card__row-description">
                          docs processed
                          <br />(
                          {moment(billingPeriod.startDate, 'YYYY-MM-D').format(
                            'DD/MM',
                          )}
                          -
                          {moment(billingPeriod.endDate, 'YYYY-MM-D').format(
                            'DD/MM',
                          )}
                          )
                        </p>
                      </div>
                    </CardContent>
                  </Card>
                  <div className="analytics__summary-legend">
                    <div className="analytics__summary-legend__title">
                      AI Product Legend
                    </div>
                    <div
                      id="chartJS-legend-list"
                      className="analytics__summary-legend__list"
                    />
                  </div>
                </div>
              )}
            </PageRow>
            <PageRow>
              <div className="analytics__footer-advice">
                <FaInfoCircle /> Prices are listed in Australian Dollars (AUD)
                and do not include any sales or applicable taxes.
              </div>
            </PageRow>
          </>
        ) : (
          <PageLoading />
        )}
      </PageBody>
    </Page>
  );
};

Billing.propTypes = {
  billingPeriods: PropTypes.arrayOf(PropTypes.object),
  subscriptions: PropTypes.arrayOf(PropTypes.object),
  subscriptionStats: PropTypes.arrayOf(PropTypes.object),
  dispatch: PropTypes.shape({
    fetchActiveSubscriptions: PropTypes.func,
    fetchSubscriptionStats: PropTypes.func,
    fetchSubscriptions: PropTypes.func,
  }),
  user: PropTypes.shape({}),
  companyId: PropTypes.string,
  isLoading: PropTypes.bool,
  totalSpend: PropTypes.number,
  totalPages: PropTypes.number,
  totalDocs: PropTypes.number,
};

Billing.defaultProps = {
  billingPeriods: null,
  subscriptions: null,
  subscriptionStats: null,
  dispatch: {
    fetchActiveSubscriptions: () => {},
    fetchSubscriptionStats: () => {},
    fetchSubscriptions: () => {},
  },
  user: null,
  companyId: null,
  isLoading: null,
  totalSpend: null,
  totalPages: null,
  totalDocs: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
