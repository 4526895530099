import React, { useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import PageHelmet from './PageHelmet';
import useEnterAnimation from './hooks/useEnterAnimation';
import './Page.scss';

const Page = ({ children, className, layout, title, animateOnReload }) => {
  const pageRef = useRef(null);
  const enterAnimation = useEnterAnimation(pageRef, 'page', animateOnReload);

  return (
    <div
      className={classNames(
        'page',
        className,
        enterAnimation,
        layout ? `page--${layout}` : null,
      )}
      ref={pageRef}
    >
      {!title ? (
        <PageHelmet title="Sypht App" suffix="" />
      ) : (
        <PageHelmet title={title} />
      )}
      {children}
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
  animateOnReload: PropTypes.bool,
};

Page.defaultProps = {
  children: null,
  className: null,
  title: null,
  animateOnReload: true,
};

export default Page;
