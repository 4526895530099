import { useState } from 'react';
import { getQueryParams } from '../../../common/helpers';

const useTasksFilters = (initialFilters, userId) => {
  const queryFilters = getQueryParams(window.location.search);
  let filters;

  if (!Object.keys(queryFilters).length && userId) {
    filters = {
      ...initialFilters,
      reviewerId: [userId],
    };
  } else {
    const state = queryFilters.state
      ? queryFilters.state.split(',')
      : undefined;
    const tags = queryFilters.tags ? queryFilters.tags.split(',') : undefined;
    const reviewerId = queryFilters.reviewerId
      ? queryFilters.reviewerId.split(',')
      : [userId];
    const reviewState = queryFilters.reviewState
      ? queryFilters.reviewState.split(',')
      : undefined;

    delete queryFilters.page;

    filters = {
      ...queryFilters,
      state,
      reviewerId,
      reviewState,
      tags,
    };
  }
  return useState(filters);
};

export default useTasksFilters;
