import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './_tag.scss';

const Tag = ({ children, className, iconSize, forwardRef, ...props }) => {
  return (
    <span className={classNames('tag', className)} ref={forwardRef} {...props}>
      {children}
    </span>
  );
};

Tag.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  iconSize: PropTypes.number,
  forwardRef: PropTypes.object,
};

Tag.defaultProps = {
  children: null,
  className: null,
  iconSize: undefined,
  forwardRef: null,
};

export default Tag;
