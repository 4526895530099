import React from 'react';
import TourContent from '../TourContent';
import Export from '../../../../../../assets/images/onboarding/introduction-to-sypht/export.svg';

const ExportData = () => (
  <TourContent
    icon={Export}
    text="Export the structured data extracted from your document(s), to easily integrate it into your own systems for further use."
  />
);

export default ExportData;
