/* eslint-disable no-nested-ternary */
import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { mergeRight as merge } from 'ramda';
import Button from '../UI/Button/Button';
import { ModalContext } from '../UI/Modal/context/ModalContext';
import { showModal } from '../UI/ModalV2';
import MfaModal from '../Profile/MfaModal';
import Form, {
  TextInput,
  Toggle,
  ToggleGroup,
  validatorType,
  useForm,
  Checkbox,
  SelectInput,
} from '../Form';
import EditUserRoles from './EditUserRoles';
import UserRoles from './UserRoles';
import '../Admin/AdminForm.scss';
import { forgotPassword, sendInviteEmail } from '../../reducers/user/actions';
import useUserRoles from './useUserRoles';

const MFA_ENABLED = 'Enabled';
const MFA_DISABLED = 'Disabled';

const getInitialState = user => {
  const initialState = {
    firstName: {
      value: user.firstName || '',
      required: true,
    },
    lastName: {
      value: user.lastName || '',
      required: true,
    },
    email: {
      value: user.email,
      required: true,
      validators: [validatorType.Email],
    },
    phone: {
      value: user.phone || '',
      validators: [validatorType.Phone],
    },
    companyName: {
      value: user.company ? user.company.name : '',
      required: true,
    },
    address: {
      value: user.company && user.company.address ? user.company.address : '',
      required: true,
    },
    country: {
      value: user.company ? user.company.country : '',
      required: true,
    },
    abn: {
      value: user.company && user.company.abn ? user.company.abn : '',
      validators: [validatorType.Number, validatorType.Length],
      length: 11,
    },
    uploadLimitPerMinute: {
      value: user.company
        ? user.company.uploadLimitPerMinute === -1
          ? 'Unlimited'
          : user.company.uploadLimitPerMinute
        : null,
    },
    maxFileSize: {
      value: user.company
        ? user.company.maxFileSize === -1
          ? 'Unlimited'
          : `${Math.floor(user.company.maxFileSize / 1000000)} MB`
        : null,
    },
    pageLimit: {
      value: user.company
        ? user.company.pageLimit === -1
          ? 'Unlimited'
          : user.company.pageLimit
        : null,
    },
    processingLimit: {
      value: user.company
        ? user.company.processingLimit === -1
          ? 'Unlimited'
          : user.company.processingLimit
        : null,
    },
    settings: {
      required: false,
      value: user.company ? user.company.settings : null,
    },
    enableMfa: {
      value: user.enableMfa,
    },
    created: {
      value: user.created,
    },
    status: {
      value: user.status || '',
    },
  };

  // TO-DO: No more company tiers
  // remove form object if not dev tier and not already enabled
  if (user.company && user.enableMfa.value !== MFA_ENABLED) {
    delete initialState.enableMfa;
  }

  return initialState;
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      dispatch,
      forgotPassword: email => dispatch(forgotPassword(email)),
      sendInviteEmail: user => dispatch(sendInviteEmail(user)),
    },
  };
};

const mapStateToProps = state => {
  const { auth } = state;
  return {
    companyRoles: state.company.roles,
    subscriptions: state.subscriptions,
    canAdministrateCompany: auth.permissions.canAdministrateCompany,
  };
};

const showMfaModal = () => {
  return showModal(
    <MfaModal confirmText="Change secret" onConfirmClick={() => {}} />,
  );
};

const EditUserForm = ({
  editorId,
  countries,
  user,
  isUserAdmin,
  isInternalAdmin,
  onSubmit,
  dispatch,
  companyRoles,
  subscriptions,
  variant,
  canAdministrateCompany,
}) => {
  const isUserSelf = user && user.id === editorId;
  const isUserProfilePage = variant === 'profile';
  const canEditRoles = isUserAdmin && !isUserProfilePage;
  const [form] = useForm(getInitialState(user));
  const { fields, errors } = form;
  const [statusText, setStatusText] = useState(form.fields.status.value);
  const { userRoles, roleData, seatData, handleRoleChange } = useUserRoles({
    enable: canEditRoles,
    dispatch: dispatch.dispatch,
    initialUserRoles: user.roles,
    companyRoles,
    subscriptions,
    companyId: user.companyId,
  });
  // if any of the target user's roles are higher than the logged in user's role
  // then roleData.data[0].currentRole will be undefined, we want to not allow editing in this case
  const readOnly =
    !!roleData.data &&
    !roleData.data.find(p => p.productId === 'core').currentRole;

  const [, setModal] = useContext(ModalContext);
  const hasErrors = Object.keys(errors || {}).length > 0;
  const countryOptions = (countries || []).map(f => ({
    value: f.name,
    label: f.name,
  }));
  const hasMfaOption =
    form.fields.enableMfa && form.fields.enableMfa.value !== MFA_ENABLED;
  const isOutOfSync = user && fields && user.email !== fields.email.value;

  if (isOutOfSync) {
    form.reset();
  }
  const handleSubmit = e => {
    e.preventDefault();

    const {
      firstName,
      lastName,
      email,
      phone,
      enableMfa,
      companyName,
      country,
      abn,
      settings,
    } = fields;

    const userData = {
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      phone: phone.value,
      roles: userRoles,
    };

    const companyData =
      canAdministrateCompany && isUserSelf
        ? {
            company: companyName.value,
            abn: abn.value,
            country: country.value,
            settings: settings.value,
          }
        : null;

    if (canAdministrateCompany && isUserSelf) {
      userData.company = companyName.value;
      userData.abn = abn.value;
      userData.country = country.value;
      userData.settings = settings.value;
    }

    if (isUserSelf && hasMfaOption) {
      userData.enableMfa = enableMfa.value;
    } else {
      userData.status = statusText;
    }

    onSubmit(e, userData, companyData);
  };

  const handleEnableMfaChange = e => {
    form.handleChange(e, e.target.checked);
  };

  const handleStatusChange = e => {
    const status = e.target.checked ? 'active' : 'deactivated';
    setStatusText(status);
    form.handleChange(e, status);
  };

  const handleChangePasswordClick = () => {
    setModal(p => {
      return merge(p, {
        isOpen: true,
      });
    });
  };

  const sendResetPassword = () => {
    dispatch.forgotPassword(user.email);
  };

  const resendInviteEmail = () => {
    dispatch.sendInviteEmail(user);
  };

  const handleGlobalSettingsChange = e => {
    const settings = fields.settings.value ? { ...fields.settings.value } : {};
    const { name, checked } = e.target;
    settings[name] = checked;
    form.handleChange({
      target: { name: 'settings', value: settings },
    });
  };

  return (
    <Form className="admin-form" onSubmit={handleSubmit}>
      <div className="row__wrapper">
        <div className="row__wrapper-left">
          <TextInput
            label="First name"
            name="firstName"
            placeholder="First name"
            value={fields.firstName.value}
            errors={errors}
            onBlur={form.handleBlur}
            onChange={form.handleChange}
          />
        </div>
        <div className="row__wrapper-right">
          <TextInput
            label="Last name"
            name="lastName"
            placeholder="Last name"
            value={fields.lastName.value}
            errors={errors}
            onBlur={form.handleBlur}
            onChange={form.handleChange}
          />
        </div>
      </div>
      <TextInput
        type="tel"
        label="Phone number"
        name="phone"
        placeholder="Phone number"
        value={fields.phone.value}
        errors={errors}
        onBlur={form.handleBlur}
        onChange={form.handleChange}
      />
      <TextInput
        label="Email address"
        name="email"
        placeholder="Email"
        value={fields.email.value}
        errors={errors}
        required
        disabled
        onBlur={form.handleBlur}
        onChange={form.handleChange}
      />
      {!isUserSelf && user.status !== 'active' && (
        <div className="form__group">
          <div className="row__wrapper">
            <button
              type="button"
              className="button button-secondary-border button--pwd"
              onClick={resendInviteEmail}
            >
              Send Invite Email
            </button>
          </div>
        </div>
      )}
      {!isUserSelf && (
        <div className="form__group">
          <div className="row__wrapper">
            <button
              type="button"
              className="button button-secondary-border button--pwd"
              onClick={sendResetPassword}
              disabled={user.status !== 'active'}
            >
              Send Reset Password Link
            </button>
          </div>
        </div>
      )}
      {isUserSelf && (
        <>
          <div className="form__group">
            <div className="row__wrapper">
              <div className="row__wrapper-left">
                <div className="password__wrapper">
                  <span className="form__input-label">Password</span>
                  <button
                    type="button"
                    className="button button-secondary-border button--pwd"
                    onClick={handleChangePasswordClick}
                  >
                    Change password
                  </button>
                </div>
              </div>
              <div className="row__wrapper-right">
                {hasMfaOption && (
                  <ToggleGroup
                    label="Multi-factor authentication"
                    labelFor="enableMfa"
                    type="single"
                    tooltip
                    tooltipClick={showMfaModal}
                  >
                    <Toggle
                      id="enableMfa"
                      label={
                        form.fields.enableMfa.value ? MFA_ENABLED : MFA_DISABLED
                      }
                      labelPosition="right"
                      name="enableMfa"
                      defaultChecked={form.fields.enableMfa.value}
                      onBlur={form.handleBlur}
                      onChange={handleEnableMfaChange}
                    />
                  </ToggleGroup>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {isUserProfilePage && user.company && (
        <>
          <TextInput
            label="Company name"
            name="companyName"
            placeholder="Company name"
            value={fields.companyName.value}
            errors={errors}
            required
            onBlur={form.handleBlur}
            onChange={form.handleChange}
            disabled={!canAdministrateCompany}
          />
          <TextInput
            label="Company ABN/ACN"
            name="abn"
            placeholder="Company ABN/ACN"
            value={fields.abn.value}
            errors={errors}
            onBlur={form.handleBlur}
            onChange={form.handleChange}
            disabled={!canAdministrateCompany}
          />
          <TextInput
            label="Address"
            name="address"
            placeholder="Address"
            value={fields.address.value}
            errors={errors}
            onBlur={form.handleBlur}
            onChange={form.handleChange}
            disabled={!canAdministrateCompany}
          />
          {!canAdministrateCompany ? (
            <TextInput
              label="Country"
              name="country"
              placeholder="Country"
              value={fields.country.value}
              errors={errors}
              required
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              disabled={!canAdministrateCompany}
            />
          ) : (
            <SelectInput
              className="sort-filter"
              label="Country"
              name="country"
              placeholder="Country"
              value={fields.country.value}
              errors={errors}
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              options={countryOptions}
            />
          )}
        </>
      )}
      {!isUserSelf && (
        <ToggleGroup label="Active" labelFor="status" type="single">
          <Toggle
            id="status"
            label={(statusText || '').toTitleCase()}
            labelPosition="right"
            className={`toggle--${statusText}`}
            name="status"
            defaultChecked={form.fields.status.value === 'active'}
            value={form.fields.status.value}
            onChange={handleStatusChange}
            disabled={
              !isUserAdmin ||
              isUserSelf ||
              form.fields.status.value === 'pending'
            }
          />
        </ToggleGroup>
      )}
      {isUserProfilePage && user.company && (
        <div className="form__group form__group--limits">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="form__group-label">Upload limits</label>
          <p className="form__group-description">
            Need an upgrade on upload limits? Get in touch to discuss what&#39;s
            right for your company{' '}
            <a
              href="https://www.sypht.com/contact"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </p>
          <TextInput
            label="# File Uploads per minute"
            name="uploadLimit"
            placeholder="# Fileuploads per minute"
            value={fields.uploadLimitPerMinute.value}
            disabled
          />
          <TextInput
            label="Max File Size Per Upload (MB)"
            name="fileSizeLimit"
            placeholder="Max File Size"
            value={fields.maxFileSize.value}
            disabled
          />
          <TextInput
            label="Max # of Pages Per Upload"
            name="MaxPageSize"
            placeholder="Max # of Pages Per Upload"
            value={fields.pageLimit.value}
            disabled
          />
          <TextInput
            label="Max # of Pages Processed"
            name="MaxPageSize"
            placeholder="Max # of Pages Per Upload"
            value={fields.processingLimit.value}
            disabled
          />
        </div>
      )}

      <div className="form__group form__group-roles">
        <div className="form__group form__group-label">User Roles</div>
        <div className="form__group-roles-inner">
          {canEditRoles && !readOnly ? (
            <EditUserRoles
              roleData={roleData}
              seatData={seatData}
              onChange={handleRoleChange}
            />
          ) : (
            <UserRoles roles={user.roles} />
          )}
        </div>
      </div>

      <div className="form__group form__group--settings">
        <label className="form__group-label" htmlFor="settings">
          Global settings
        </label>
        <Checkbox
          id="isSmartSplitAuto"
          name="isSmartSplitAuto"
          label="Apply smart split to uploaded documents"
          defaultChecked={
            fields.settings.value
              ? !!fields.settings.value.isSmartSplitAuto
              : false
          }
          onChange={handleGlobalSettingsChange}
          disabled={!isUserAdmin}
        />
      </div>

      <div className="button-group">
        <NavLink
          to={
            !isInternalAdmin
              ? '/users'
              : `/admin/companies/${user.company.id}#users`
          }
          className="button button-secondary-border"
        >
          Cancel
        </NavLink>
        <Button
          type="submit"
          onClick={handleSubmit}
          disabled={hasErrors || readOnly}
        >
          Save
        </Button>
      </div>
    </Form>
  );
};

EditUserForm.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.shape({})),
  user: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    scopes: PropTypes.arrayOf(PropTypes.string),
    status: PropTypes.string,
    companyId: PropTypes.string,
    company: PropTypes.shape({
      id: PropTypes.string,
    }),
    roles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        roleName: PropTypes.string,
        productId: PropTypes.string,
      }),
    ),
  }).isRequired,
  companyRoles: PropTypes.shape({
    error: PropTypes.string,
    data: PropTypes.objectOf(
      PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }),
      ),
    ),
  }).isRequired,
  subscriptions: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  editorId: PropTypes.string,
  isUserAdmin: PropTypes.bool,
  onSubmit: PropTypes.func,
  isInternalAdmin: PropTypes.bool.isRequired,
  dispatch: PropTypes.shape({
    dispatch: PropTypes.func,
    sendInviteEmail: PropTypes.func,
    forgotPassword: PropTypes.func,
  }).isRequired,
  canAdministrateCompany: PropTypes.bool.isRequired,
  /**
   * Variant = 'profile' is for the user profile page.
   */
  variant: PropTypes.oneOf(['default', 'profile']),
};

EditUserForm.defaultProps = {
  countries: null,
  editorId: null,
  isUserAdmin: false,
  onSubmit: () => {},
  variant: 'default',
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUserForm);
