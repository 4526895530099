import Skeleton from './Skeleton';
import SkeletonElem from './SkeletonElem';

export const SkeletonDoc = props => SkeletonElem({ ...props, elemName: 'doc' });
export const SkeletonH1 = props => SkeletonElem({ ...props, elemName: 'h1' });
export const SkeletonH2 = props => SkeletonElem({ ...props, elemName: 'h2' });
export const SkeletonH3 = props => SkeletonElem({ ...props, elemName: 'h3' });
export const SkeletonH4 = props => SkeletonElem({ ...props, elemName: 'h4' });
export const SkeletonH5 = props => SkeletonElem({ ...props, elemName: 'h5' });
export const SkeletonOL = props => SkeletonElem({ ...props, elemName: 'ol' });
export const SkeletonLI = props => SkeletonElem({ ...props, elemName: 'li' });

export { default as SkeletonSpinner } from './SkeletonSpinner';
export { default as SkeletonTable } from './SkeletonTable';
export default Skeleton;
