import SpecificationLookup from '../../../common/lookups/specificationLookup';

/**
 * Transform react-table sortBy to the format used by app-service api's.
 *
 * sortBy should be an array like [{ id, desc: bool }, ...] (react-table
 * useSorbBy format).
 */
export const transformSortBy = sortBy => {
  if (!sortBy) {
    return sortBy;
  }
  return sortBy.map(s => ({
    column: s.id,
    order: s.desc ? 'desc' : 'asc',
  }));
};

export const getTasks = (taskIds, tasks) => {
  const mappedTasks = {};
  taskIds.forEach(id => {
    const task = tasks.find(t => t.id === id);
    if (!task) return;
    mappedTasks[id] = task;
  });
  return mappedTasks;
};

export const getStateFilterLabel = (placeholder, states, options) => {
  const noOfStates = (states || []).length;
  if (!noOfStates) return placeholder;
  const mappedStates = [];
  states.forEach(value => {
    const label = options.find(r => r.value === value);
    mappedStates.push(label?.label);
  });
  return mappedStates.join(', ');
};

export const getReviewerLabel = (placeholder, reviewers, options) => {
  if (!(reviewers || []).length) return placeholder;
  const mappedReviewers = [];
  reviewers.forEach(value => {
    const label = options.find(r => r.value === value);
    mappedReviewers.push(label?.label);
  });
  return mappedReviewers.join(', ');
};

export const getTagsFilterLabel = tags => {
  const noOfTags = (tags || []).length;
  if (!noOfTags) return 'Tags';
  if (noOfTags === 1) return `${noOfTags} tag`;
  return `${noOfTags} tags`;
};

export const getTargetPath = (task, userId) => {
  const { specificationName: spec } = task;

  let targetPath = `/tasks/${task.id}/user/${userId}`;
  if (spec && spec === SpecificationLookup.DOCUMENT_SPLIT) {
    targetPath = `/documents/split/${task.id}/user/${userId}`;
  }

  return targetPath;
};

export const isNotAnnotatedUser = states => {
  const statesJson = JSON.stringify(states);
  return (
    statesJson === JSON.stringify(['created']) ||
    statesJson === JSON.stringify(['created', 'in_progress']) ||
    statesJson === JSON.stringify(['in_progress', 'created'])
  );
};
