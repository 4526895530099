/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { FaTrash, FaCheck } from 'react-icons/fa';
import {
  InitialTableState,
  withTableContext,
} from '../DocumentView/TableContext';
import Annotation from './Annotation';
import * as helper from './helper';
import './Task.scss';
import TaskContext from './TaskContext';

class MultiValueAnnotation extends Component {
  static contextType = TaskContext;

  constructor(props) {
    super(props);
    this.state = {
      editable: false,
    };
  }

  getItems = () => {
    return this.props.annotation && this.props.annotation.value
      ? this.props.annotation.value
      : [];
  };

  onItemSave = (idx, v) => {
    const { field } = this.props;
    const items = this.getItems();

    if (field.type.includes('group')) {
      const ci = items[idx].fields.findIndex(f => f.id == v.id);
      if (ci != -1) {
        items[idx].fields[ci] = v;
      } else {
        items[idx].fields.push(v);
      }
    } else {
      items[idx] = v;
    }
    this.saveItems(items);
  };

  onItemSplitSave = (idx, values) => {
    const items = this.getItems();
    items.splice(idx, 1, ...values);
    this.saveItems(items);
  };

  onRemoveItem = idx => {
    const { field } = this.props;
    const { toggleEditMode } = this.context;
    const items = this.getItems();

    items.splice(idx, 1);
    this.saveItems(items);
  };

  canAddItem = items => {
    const { field } = this.props;
    if (items == null) {
      items = this.getItems();
    }
    return items.every(i =>
      field.type.includes('group')
        ? i.fields.length > 0 && i.fields.length === field.components.length
        : i != null,
    );
  };

  onAddItem = () => {
    const { field } = this.props;
    const items = this.getItems();

    items.push(
      field.type.includes('group')
        ? {
            fields: [],
          }
        : null,
    );
    this.saveItems(items);
  };

  saveItems(items) {
    const { field, saveAnnotation } = this.props;

    saveAnnotation({
      id: field.id,
      value: items,
    });
  }

  onEnterEditMode() {
    // Close the table annotation first if it was being viewed in readOnly mode.
    const {
      tableContext: [, setTableContext],
    } = this.props;
    setTableContext(state => ({
      ...state,
      ...InitialTableState,
    }));
    // Now open and edit:
    const { toggleEditMode } = this.context;
    this.setState({
      editable: true,
    });

    // controls the context editMode value
    toggleEditMode(true);
  }

  onExitEditMode() {
    const { toggleEditMode } = this.context;

    this.setState({
      editable: false,
    });
    if (this.ref.contains(document.activeElement)) {
      this.ref.blur();
    }

    // controls the context editMode value
    toggleEditMode(false);
  }

  componentWillMount() {
    if (!this.props.annotation) {
      this.onEnterEditMode();
    }
  }

  componentDidMount() {
    if (
      this.ref &&
      this.props.takeFocus &&
      !this.ref.contains(document.activeElement)
    ) {
      this.ref.focus();
    }
  }

  componentDidUpdate = () => {
    if (
      this.ref &&
      this.props.takeFocus &&
      !this.ref.contains(document.activeElement)
    ) {
      this.ref.focus();
    }
  };

  onConfirmItems = () => {
    if (!this.props.annotation) {
      this.saveItems(this.getItems());
    }
    this.onExitEditMode();
  };

  onKeyDown = e => {
    let handled = true;

    if (e.key === 'e') {
      this.onEnterEditMode();
    } else if (this.isEditable() && e.key === 'a') {
      if (this.canAddItem()) {
        this.onAddItem();
      }
    } else if (this.isEditable() && e.key === ' ') {
      this.onConfirmItems();
    } else {
      handled = false;
    }

    if (handled) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  onItemKeyDown = (e, idx) => {
    let handled = true;

    if (e.key === 'x' && (e.metaKey || e.ctrlKey)) {
      this.onRemoveItem(idx);
    } else {
      handled = false;
    }

    if (handled) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  isEditable = () => {
    return this.state.editable && this.props.editable;
  };

  render() {
    const {
      field,
      prediction,
      annotation,
      saveAnnotation,
      context,
      fieldParams,
      fieldPath,
      progress,
      ...props
    } = this.props;

    const editable = this.isEditable();
    const subPrediction =
      !prediction && field.nullable
        ? [
            {
              value: null,
              value_norm: null,
              confidence: 0,
              confidence_norm: 0,
              bounds: null,
            },
          ]
        : [];

    const items = this.getItems();
    let assignedFocus = false;
    const annotatedTableData = field.type.includes('table')
      ? items
          .filter(i => i !== null)
          .map((i, idx) => ({ ...i.tableData, index: idx }))
      : [];

    const hasAnnotation = annotation !== undefined;
    const isTableAnnotation = helper.isTableAnnotation(field);
    const enablePlusButton = !isTableAnnotation;
    const addItemLabel = isTableAnnotation ? 'Add Table' : 'Add Item';
    const confirmLabel = isTableAnnotation ? 'Confirm Tables' : 'Confirm Items';

    return (
      <div
        ref={r => (this.ref = r)}
        className="annotation-group"
        tabIndex="0"
        onKeyDown={this.onKeyDown}
      >
        <div className="multi-value-header">
          <div>
            {hasAnnotation && !editable && <FaCheck className="field-status" />}
            {field.name} ({items ? items.length : 0})
          </div>
          {editable ? (
            enablePlusButton && (
              <div
                className={`button add ${
                  !this.canAddItem(items) ? 'disabled' : ''
                }`}
                onClick={this.onAddItem}
              >
                +
              </div>
            )
          ) : this.props.editable ? (
            <div className="button edit" onClick={e => this.onEnterEditMode()}>
              Edit
            </div>
          ) : null}
        </div>
        {field.type.includes('group') &&
          items.map((item, idx) => (
            <div
              key={idx}
              className="group-instance"
              onKeyDown={e => this.onItemKeyDown(e, idx)}
            >
              <div className="instance-header">
                <div className="title">
                  <span>
                    {field.name.replace(/[s]+$/, '')} &raquo;
                    {idx + 1}
                  </span>
                </div>
                {editable ? (
                  <div
                    className="button"
                    onClick={() => this.onRemoveItem(idx)}
                  >
                    <FaTrash />
                  </div>
                ) : null}
              </div>
              <div className="instance-fields">
                {field.components.map((c, i) => {
                  const shouldTakeFocus =
                    !items[idx].fields.find(f => f.id == c.id) ||
                    (idx == items.length - 1 &&
                      i == field.components.length - 1);
                  const itemEditable = editable && this.props.editable;

                  const itemContext = {
                    neighbours: items[idx].fields.filter(f => f.id !== c.id),
                    parent: context,
                  };
                  const itemAnnotation = items[idx].fields.find(
                    f => f.id == c.id,
                  );

                  let takeFocus =
                    this.props.takeFocus !== false &&
                    itemEditable &&
                    shouldTakeFocus &&
                    !assignedFocus;

                  if (progress && i === 1) {
                    takeFocus = true;
                  }

                  if (takeFocus) {
                    assignedFocus = true;
                  }
                  let subGroupPrediction = subPrediction;

                  if (prediction && prediction[0] && prediction[0].value_norm) {
                    subGroupPrediction = prediction
                      .map(pred =>
                        pred.value
                          .filter(p => p.value && c.id in p.value[i])
                          .map((p, i) => ({
                            field: c.id,
                            value: pred.value[i][c.id],
                            value_norm: pred.value_norm[i][c.id],
                            confidence: pred.confidence,
                            confidence_norm: pred.confidence_norm,
                            bounds: pred.bounds[i][c.id],
                          })),
                      )
                      .flat();
                  }

                  const path = fieldPath
                    ? `${fieldPath}[${idx}].${c.id}`
                    : `${field.id}[${idx}].${c.id}`;

                  return c.multiple ? (
                    <MultiValueAnnotation
                      key={path}
                      {...props}
                      field={c}
                      fieldPath={path}
                      fieldParams={fieldParams}
                      annotation={itemAnnotation}
                      saveAnnotation={v => this.onItemSave(idx, v)}
                      context={itemContext}
                      takeFocus={takeFocus}
                      editable={itemEditable}
                      progress={progress}
                    />
                  ) : (
                    <Annotation
                      key={path}
                      {...props}
                      field={c}
                      fieldPath={path}
                      fieldParams={fieldParams ? fieldParams[path] : null}
                      context={itemContext}
                      annotation={itemAnnotation}
                      prediction={subGroupPrediction}
                      saveAnnotation={v => this.onItemSave(idx, v)}
                      onSelectRemove={() => this.onRemoveItem(idx)}
                      takeFocus={takeFocus}
                      editable={itemEditable}
                      progress={progress}
                    />
                  );
                })}
              </div>
            </div>
          ))}
        {field.type.includes('table') &&
          items.map((item, idx) => {
            const shouldTakeFocus = idx == items.length - 1;
            const itemEditable = editable && this.props.editable;

            let takeFocus =
              this.props.takeFocus !== false &&
              itemEditable &&
              shouldTakeFocus &&
              !assignedFocus;

            if (progress && idx === 1) {
              takeFocus = true;
            }
            if (takeFocus) {
              assignedFocus = true;
            }

            let subGroupPrediction = subPrediction;
            if (prediction) {
              subGroupPrediction = prediction[0].value.items;
            }
            const fieldName = `Table ${idx + 1}`;
            return (
              <div
                key={idx}
                className="group-instance"
                tabIndex="0"
                onKeyDown={e => this.onItemKeyDown(e, idx)}
              >
                <div className="instance-header">
                  <div className="title">
                    <span>
                      Table &raquo;
                      {idx + 1}
                    </span>
                  </div>
                  {editable ? (
                    <div
                      className="button"
                      onClick={() => this.onRemoveItem(idx)}
                    >
                      <FaTrash />
                    </div>
                  ) : null}
                </div>
                <div className="instance-fields">
                  <Annotation
                    key={fieldPath}
                    {...props}
                    field={field}
                    fieldName={fieldName}
                    fieldPath={fieldPath}
                    fieldParams={fieldParams ? fieldParams[fieldPath] : null}
                    annotation={items[idx]}
                    prediction={subGroupPrediction}
                    saveAnnotation={v => this.onItemSave(idx, v)}
                    takeFocus={takeFocus}
                    editable={itemEditable}
                    progress={progress}
                    index={idx}
                    anotatedTableData={annotatedTableData}
                  />
                </div>
              </div>
            );
          })}

        {!field.type.includes('group') && !field.type.includes('table') && (
          <div className="field-multi-value-container">
            {items.map((item, idx) => {
              const shouldTakeFocus =
                editable &&
                this.props.takeFocus &&
                (!items[idx] || idx == items.length - 1);
              const itemEditable = editable && this.props.editable;
              const takeFocus =
                this.props.takeFocus &&
                itemEditable &&
                shouldTakeFocus &&
                !assignedFocus;

              if (takeFocus) {
                assignedFocus = true;
              }
              return (
                <Annotation
                  key={idx}
                  index={idx}
                  field={field}
                  fieldPath={fieldPath}
                  fieldParams={fieldParams ? fieldParams[fieldPath] : null}
                  prediction={subPrediction}
                  annotation={items[idx]}
                  saveAnnotation={v => this.onItemSave(idx, v)}
                  saveSplitAnnotations={vs => this.onItemSplitSave(idx, vs)}
                  onSelectRemove={() => this.onRemoveItem(idx)}
                  takeFocus={takeFocus}
                  {...props}
                  editable={itemEditable}
                  progress={progress}
                />
              );
            })}
          </div>
        )}

        {editable ? (
          <div className="multi-value-footer">
            <div
              className={`button-div ${
                !this.canAddItem(items) ? 'disabled' : ''
              }`}
              onClick={this.onAddItem}
            >
              {addItemLabel}
            </div>
            <div
              className={`button-div ${
                !this.canAddItem(items) ? 'disabled' : ''
              }`}
              onClick={this.onConfirmItems}
            >
              {confirmLabel}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withTableContext(MultiValueAnnotation);
