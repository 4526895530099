import React from 'react';
import PropTypes from 'prop-types';
import './PricingTable.scss';
import {
  FaPen,
  FaTrashAlt,
  FaTimes,
  FaCheck,
  FaPlusCircle,
} from 'react-icons/fa';
import classnames from 'classnames';
import { TextInput } from '../../../../Form';
import { Button } from '../../../../../library/atoms/Buttons';
import * as pm from './PricingModel';

const INF_CHAR = '∞';

function renderNum(num) {
  return num === Infinity ? INF_CHAR : num.toLocaleString();
}

function returnNumberIfPossible$(text) {
  if (text === '') {
    return text;
  }
  const num = Number(text);
  return Number.isNaN(num) ? text : num;
}

function returnNumberIfPossible(tier) {
  return {
    upTo: returnNumberIfPossible$(tier.upTo),
    flatPrice: returnNumberIfPossible$(tier.flatPrice),
    unitPrice: returnNumberIfPossible$(tier.unitPrice),
  };
}

// eslint-disable-next-line react/prop-types
const IconButton = ({ children, variant, size = 'md', ...props }) => (
  <div className="subn-pricing-tbl__icon-button">
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <Button size={size} variant={variant} {...props}>
      <div className="subn-pricing-tbl__icon-button-inner">{children}</div>
    </Button>
  </div>
);

const PricingTable = ({ tiers: rawTiers, editable, onChange }) => {
  const tiers = pm.nullToInf(rawTiers || []);
  const [editRow, setEditRow] = React.useState({
    index: null,
    value: null,
    validation: null,
  });
  const isEditing = editRow.index !== null;
  const isLastRow = editRow.index === tiers.length - 1;

  const handleStartEdit = React.useCallback(
    index => {
      if (isEditing) return;
      setEditRow({
        index,
        value: tiers[index],
        validation: pm.validate(tiers),
      });
    },
    [isEditing, tiers],
  );
  const handleStopEdit = () => {
    setEditRow({ index: null, value: null, validation: null });
  };
  const handleValueChange = (name, value) => {
    setEditRow(r => ({
      ...r,
      value: { ...r.value, [name]: value },
      validation: pm.validate(
        pm.updateTier(
          tiers,
          r.index,
          returnNumberIfPossible({ ...r.value, [name]: value }),
        ),
      ),
    }));
  };
  const handleSubmitEdit = () => {
    const newTiers = pm.updateTier(
      tiers,
      editRow.index,
      returnNumberIfPossible(editRow.value),
    );
    const validation = pm.validate(newTiers);
    if (!validation.ok) {
      setEditRow(r => ({
        ...r,
        validation,
      }));
      return;
    }
    onChange(newTiers);
    handleStopEdit();
  };
  const handleDelete = index => {
    // eslint-disable-next-line no-alert
    const yes = window.confirm('Are you sure you want to delete this tier?');
    if (yes) {
      onChange(pm.deleteTier(tiers, index));
    }
  };
  const handleAddTier = () => {
    const updated = pm.appendTier(tiers);
    onChange(updated);
  };
  const handleInputKeyUp = e => {
    if (e.key === 'Enter') {
      handleSubmitEdit();
    }
    if (e.key === 'Escape') {
      handleStopEdit();
    }
  };
  const handleInputFocus = e => {
    const input = e.target;
    input.setSelectionRange(0, input.value.length);
  };

  if (tiers.length === 0) {
    return (
      <div className="subn-pricing-tbl__no-data">
        <p>There is no pricing for this subscription.</p>
        {editable && (
          <p className="subn-pricing-tbl__no-data-btn-ctn">
            <span className="subn-pricing-tbl__no-data-btn">
              <Button onClick={handleAddTier}>
                <>
                  <FaPlusCircle />
                  <span>Add tier</span>
                </>
              </Button>
            </span>
          </p>
        )}
      </div>
    );
  }

  return (
    <div className="subn-pricing-tbl__container">
      <table className="subn-pricing-tbl">
        <thead>
          <tr>
            <th>#</th>
            <th>Tier</th>
            <th>Per Unit ($)</th>
            <th>Flat Fee ($)</th>
            {editable && <th>&nbsp;</th>}
          </tr>
        </thead>
        <tbody>
          {tiers.map((tier, index) => (
            <tr
              onDoubleClick={() => {
                if (editable) {
                  handleStartEdit(index);
                }
              }}
              key={String(tier.upTo)}
              className={classnames(
                editRow.index === index && 'subn-pricing-tbl__row--edit',
              )}
            >
              {editRow.index === index && (
                <>
                  <td>{index + 1}</td>
                  <td>
                    <div className="subn-pricing-tbl__edit-row">
                      {isLastRow ? (
                        <>
                          {renderNum(pm.startFrom(tiers, index))} &mdash;{' '}
                          {renderNum(tier.upTo)}
                        </>
                      ) : (
                        <>
                          {renderNum(pm.startFrom(tiers, index))} &mdash;&nbsp;
                          <TextInput
                            name="upTo"
                            value={
                              editRow.value.upTo === Infinity
                                ? INF_CHAR
                                : editRow.value.upTo
                            }
                            autoFocus
                            onFocus={handleInputFocus}
                            onKeyUp={handleInputKeyUp}
                            onChange={e => {
                              handleValueChange('upTo', e.target.value);
                            }}
                          />
                        </>
                      )}
                    </div>
                    {editRow.validation.upTo && (
                      <div className="subn-pricing-tbl__input-error">
                        {editRow.validation.upTo}
                      </div>
                    )}
                  </td>
                  <td>
                    <div className="subn-pricing-tbl__edit-row">
                      &nbsp;
                      <TextInput
                        name="unitPrice"
                        value={editRow.value.unitPrice}
                        onFocus={handleInputFocus}
                        onKeyUp={handleInputKeyUp}
                        onChange={e => {
                          handleValueChange('unitPrice', e.target.value);
                        }}
                        autoFocus={isLastRow}
                      />
                    </div>
                    {editRow.validation.unitPrice && (
                      <div className="subn-pricing-tbl__input-error">
                        {editRow.validation.unitPrice}
                      </div>
                    )}
                  </td>
                  <td>
                    <div className="subn-pricing-tbl__edit-row">
                      <TextInput
                        name="flatPrice"
                        value={editRow.value.flatPrice}
                        onFocus={handleInputFocus}
                        onKeyUp={handleInputKeyUp}
                        onChange={e => {
                          handleValueChange('flatPrice', e.target.value);
                        }}
                      />
                    </div>
                    {editRow.validation.flatPrice && (
                      <div className="subn-pricing-tbl__input-error">
                        {editRow.validation.flatPrice}
                      </div>
                    )}
                  </td>
                  <td className="subn-pricing-tbl__actions">
                    <div className="subn-pricing-tbl__edit-row">
                      {editRow.validation.ok && (
                        <IconButton onClick={handleSubmitEdit}>
                          <FaCheck size={15} />
                        </IconButton>
                      )}
                      <IconButton
                        variant="outline-warning"
                        onClick={handleStopEdit}
                      >
                        <FaTimes size={15} />
                      </IconButton>
                    </div>
                  </td>
                </>
              )}
              {editRow.index !== index && (
                <>
                  <td>{index + 1}</td>
                  <td>
                    {renderNum(pm.startFrom(tiers, index))} &mdash;{' '}
                    {renderNum(tier.upTo)}
                  </td>
                  <td>{renderNum(tier.unitPrice)}</td>
                  <td>{renderNum(tier.flatPrice)}</td>
                  {editable && (
                    <td className="subn-pricing-tbl__actions">
                      {!isEditing && (
                        <IconButton
                          variant="inverted"
                          onClick={() => handleStartEdit(index)}
                        >
                          <FaPen size={15} />
                        </IconButton>
                      )}
                      {!isEditing && (
                        <IconButton
                          variant="inverted"
                          onClick={() => {
                            handleDelete(index);
                          }}
                        >
                          <FaTrashAlt size={15} />
                        </IconButton>
                      )}
                    </td>
                  )}
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {editable && !isEditing && (
        <Button onClick={handleAddTier}>
          <>
            <FaPlusCircle />
            <span>Add tier</span>
          </>
        </Button>
      )}
    </div>
  );
};

PricingTable.propTypes = {
  tiers: PropTypes.arrayOf(
    PropTypes.shape({
      upTo: PropTypes.number,
      unitPrice: PropTypes.number.isRequired,
      flatPrice: PropTypes.number.isRequired,
    }).isRequired,
  ),
  editable: PropTypes.bool,
  onChange: PropTypes.func,
};

PricingTable.defaultProps = {
  tiers: [],
  editable: false,
  onChange: () => {},
};

export default PricingTable;
