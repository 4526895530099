// Need a string value for SelectInput.
const NONE = 'none';

/**
 * How to interpret date tokens from a document.
 *
 * After a model extracts a date (as a string) it can be normalised into a real
 * date using this setting.
 */
const documentDateFormat = Object.freeze([
  /**
   * US date: month/day/year
   */
  {
    label: 'month/day/year',
    value: 'en_US',
  },
  /**
   * Austalian date: day/month/year
   */
  {
    label: 'day/month/year',
    value: NONE,
  },
]);

const tip = `Sets the document date format for localization in ISO 8601 format. Useful for countries that don't follow ISO 8601 date formats.`;

export default documentDateFormat;
export { tip, NONE };
