import * as utils from './utils';

const apiBaseUrl = process.env.APP_API_HOST_NAME;
const validateServiceBaseUrl =
  process.env.VALIDATE_SERVICE_HOST_NAME || apiBaseUrl;

class ProductService {
  createProduct(accessToken, product) {
    return utils.postData(`${validateServiceBaseUrl}/app/products`, {
      accessToken,
      data: product,
    });
  }

  getProducts(accessToken, params, isPublic) {
    const url = isPublic
      ? `${validateServiceBaseUrl}/app/products/public`
      : `${validateServiceBaseUrl}/app/products`;
    return utils.getData(url, {
      accessToken,
      ...params,
    });
  }

  getProduct(accessToken, productId, isPublic) {
    const url = isPublic
      ? `${validateServiceBaseUrl}/app/products/public/${productId}`
      : `${validateServiceBaseUrl}/app/products/${productId}`;
    return utils.getData(url, {
      accessToken,
    });
  }

  updateProduct(accessToken, productId, product) {
    // product = {
    //   name,                 // required
    //   description,          // string
    //   publishOnMarketplace, // bool
    //   fieldsets,            // string[]
    //   tags                  // string[]
    //   tileImageUrl          // string,
    //   imageUrl              // string,
    //   defaultSortIdx        // number,
    //   content               // string,
    //   documentation         // string
    // };
    return utils.putData(
      `${validateServiceBaseUrl}/app/products/${productId}`,
      {
        accessToken,
        data: product,
      },
    );
  }

  deleteProduct(accessToken, productId) {
    // prettier-ignore
    return utils.deleteData(`${validateServiceBaseUrl}/app/products/${productId}`, {
      accessToken
    });
  }
}

export default new ProductService();
