import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './_skeleton.scss';

const Skeleton = ({ children, className }) => {
  return <div className={classNames(`skeleton`, className)}>{children}</div>;
};

Skeleton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Skeleton.defaultProps = {
  children: null,
  className: null,
};

export default Skeleton;
