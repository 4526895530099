export const abbrevToK = (threshold, maxThreshold = 30000) => {
  if (Number.isNaN(threshold) || threshold < 1000) {
    return threshold.toString();
  }

  // Floors the number to the closest 100 or 1000.
  // 101 becomes 100
  // 1256 becomes 1200
  const flooredThreshold =
    threshold % 100 > 0 ? threshold - (threshold % 100) : threshold;

  if (flooredThreshold % 1000 === 0) {
    return `${(flooredThreshold || 0) / 1000}k`;
  }

  if (flooredThreshold % 100 === 0) {
    return `${((flooredThreshold || 0) / 1000).toFixed(1)}k`;
  }

  if (Number.isFinite(Number(flooredThreshold)) === false) {
    return `${(maxThreshold || 0) / 1000}k`;
  }

  return flooredThreshold.toString();
};

export const currency = (number, options = {}) => {
  const dp = options.decimalPlaces || 0;
  return new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'USD', // avoids adding "A"
    maximumFractionDigits: dp,
    minimumFractionDigits: dp,
  }).format(number);
};

const pluralRules = {
  en: {
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th',
  },
};

export const ordinal = number => {
  if ('PluralRules' in Intl) {
    const format = new Intl.PluralRules('en', {
      type: 'ordinal',
    });
    return `${number}${pluralRules.en[format.select(number)]}`;
  }
  return number;
};
