const mapFromTo = (t, prev) => ({
  ...t,
  last: !prev ? 0 : prev.threshold,
  from: !prev ? 0 : prev.threshold + 1,
  to: t.threshold === 'inf' ? Infinity : t.threshold,
  basePrice: (!prev ? t.threshold : t.threshold - prev.threshold) * t.unitPrice,
});

const mapBasePrice = (t, prev) => {
  return {
    ...t,
    basePrice: !prev ? 0 : prev.basePrice,
  };
};

export const setVisibleTiers = tiers => {
  let visibleTiers = tiers.map(t => t);

  if (
    tiers.length > 1 &&
    tiers[0].flatPrice > 0 &&
    tiers[0].unitPrice === 0 &&
    Math.ceil(tiers[0].flatPrice / tiers[1].unitPrice) === tiers[0].threshold
  ) {
    visibleTiers.shift();
  }

  visibleTiers = visibleTiers.map((t, i) => mapFromTo(t, visibleTiers[i - 1]));
  visibleTiers = visibleTiers.map((t, i) =>
    mapBasePrice(t, visibleTiers[i - 1]),
  );

  return visibleTiers;
};
