import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const NumberCell = ({ value }) => {
  return <>{value || <>&mdash;</>}</>;
};

NumberCell.propTypes = {
  value: PropTypes.any,
};

NumberCell.defaultProps = {
  value: undefined,
};

export default NumberCell;
