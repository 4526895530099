import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '../../atoms/Box';
import { abbrevToK } from '../../../common/helpers';
import AnchorButton from '../../atoms/Buttons/AnchorButton';

import './RateCard.scss';

const MAX_THRESHOLD = 30000;

const RateCard = ({ lowerBound, upperBound, pricePerPage, isCallToAction }) => {
  return (
    <div className="rate-card-new">
      <Box variant="pricing">
        <div className="rate-card-new__header">
          {/* Starting Tier */}
          {!isCallToAction && !!lowerBound && !upperBound && (
            <div>{abbrevToK(lowerBound, MAX_THRESHOLD)} pages</div>
          )}

          {/* Middle Tier */}
          {!isCallToAction && !!lowerBound && !!upperBound && (
            <div>
              {abbrevToK(lowerBound, MAX_THRESHOLD)} to{' '}
              {abbrevToK(upperBound, MAX_THRESHOLD)} pages
            </div>
          )}

          {/* Ending Tier */}
          {!isCallToAction && !lowerBound && !!upperBound && (
            <div>Up to {abbrevToK(upperBound, MAX_THRESHOLD)} pages</div>
          )}

          {/* Talk to sales tier Tier */}
          {isCallToAction && <div>{abbrevToK(MAX_THRESHOLD)}+ pages</div>}

          {/* Unknown Tier */}
          {!isCallToAction && !lowerBound && !upperBound && <div>Unkonwn</div>}

          <div>/ month</div>
        </div>
      </Box>
      <div className="rate-card-new__body">
        {!isCallToAction && (
          <div className="rate-card-new__body__price-per-page">
            <div>${`${Number(pricePerPage).toFixed(2)}`}c</div>
            <div>/ page</div>
          </div>
        )}
        {isCallToAction && (
          <div className="rate-card-new__body__talk-to-sales">
            <AnchorButton
              href="https://www.sypht.com/contact"
              target="_blank"
              rel="noopener noreferrer"
              variant="outline"
            >
              Talk to sales
            </AnchorButton>
          </div>
        )}
      </div>
    </div>
  );
};

RateCard.propTypes = {
  lowerBound: PropTypes.number,
  upperBound: PropTypes.number,
  pricePerPage: PropTypes.number,
  isCallToAction: PropTypes.bool,
};

RateCard.defaultProps = {
  upperBound: null,
  lowerBound: null,
  pricePerPage: null,
  isCallToAction: false,
};

export default RateCard;
