/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
  useRef,
  useEffect,
  useState,
  Fragment,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { FaTimes } from 'react-icons/fa';
import { Button } from '../../../../../library/atoms/Buttons';
import Checkbox from '../../../../../components/UI/Checkbox/Checkbox';
import Dropdown from './Dropdown';
import './StateFilter.scss';

const initialLogicState = filters => {
  return {
    extracted: filters ? filters.isExtracted : null,
    validated: filters ? filters.isValidated : null,
    exported: filters ? filters.isExported : null,
  };
};

const LOGIC_OPTIONS = [
  { value: true, label: 'Includes' },
  { value: false, label: 'Excludes' },
];

const Menu = ({
  isOpen,
  offset,
  setOpen,
  btnRef,
  disableItemClose,
  setInput,
  applyStateFilters,
  filters,
}) => {
  const menuRef = useRef(null);
  const [isFiltered, setFiltered] = useState(false);
  const [logicFilters, setLogicFilters] = useState(initialLogicState(filters));
  const handleClickOutside = useCallback(
    e => {
      if (
        menuRef.current &&
        !menuRef.current.contains(e.target) &&
        !btnRef.current.contains(e.target)
      ) {
        setOpen(false);
        if (!isFiltered) {
          setLogicFilters(initialLogicState(filters));
        }
      }
    },
    [btnRef, filters, isFiltered, setOpen],
  );

  const clearFilters = useCallback(
    e => {
      e.stopPropagation();
      setInput(
        <span className="state__filter--input__text">Document State</span>,
      );
      setOpen(false);
      setFiltered(false);
      setLogicFilters(initialLogicState());
      applyStateFilters(initialLogicState());
    },
    [applyStateFilters, setInput, setOpen],
  );

  const populateInput = useCallback(() => {
    let filter = [];
    Object.keys(logicFilters).forEach(f => {
      const key = f[0].toUpperCase() + f.substring(1);
      const { value } = logicFilters[f] || {};
      if (value === true) {
        filter.push(`Include: ${key}`);
      } else if (value === false) {
        filter.push(`Exclude: ${key}`);
      }
    });
    if (filter.length === 0) {
      setInput(
        <span className="state__filter--input__text">Document State</span>,
      );
      setFiltered(false);
    } else {
      filter = filter.join(', ');
      setInput(
        <>
          <span className="state__filter--input__text">{filter}</span>
          <FaTimes
            className="state__filter--input__clear"
            onClick={clearFilters}
          />
        </>,
      );
      setFiltered(true);
    }
  }, [clearFilters, logicFilters, setInput]);

  useEffect(() => {
    if (Object.values(logicFilters).find(f => f !== null)) {
      populateInput();
    }
  }, [logicFilters, populateInput]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleItemClick = () => {
    if (!disableItemClose) {
      setOpen(false);
    }
  };

  const onSubmit = () => {
    populateInput();
    applyStateFilters(logicFilters);
    setOpen(false);
  };

  const logicFilterChange = (value, action) => {
    setLogicFilters(f => ({ ...f, [action.name]: value }));
  };

  const checkboxChange = e => {
    e.persist();
    const { name, checked } = e.target;
    const value = checked ? { value: true, label: 'Includes' } : null;
    setLogicFilters(f => ({ ...f, [name]: value }));
  };

  const getListComponent = () => {
    const style = offset
      ? {
          top: offset.top + offset.height + 10,
          left: offset.right - 300,
        }
      : {};

    return isOpen ? (
      <div style={style} className="state__filter--menu" ref={menuRef}>
        <div className="state__filter--menu__title">
          Filter by document state
        </div>
        <ul>
          <li className="state__filter--menu__item" onClick={handleItemClick}>
            <Checkbox
              id="state__filter--extracted"
              name="extracted"
              defaultChecked={!!logicFilters.extracted}
              onChange={checkboxChange}
              className="state__filter--checkbox"
            />
            <span className="state__filter--menu__item-text">Extracted</span>
            <Dropdown
              id="state__filter--extracted-option"
              name="extracted"
              classNamePrefix="state__filter--dropdown"
              placeholder="Includes"
              options={LOGIC_OPTIONS}
              value={logicFilters.extracted}
              isDisabled={logicFilters.extracted === null}
              onChange={logicFilterChange}
            />
          </li>
          <li className="state__filter--menu__item" onClick={handleItemClick}>
            <Checkbox
              id="state__filter--validated"
              defaultChecked={!!logicFilters.validated}
              name="validated"
              onChange={checkboxChange}
              className="state__filter--checkbox"
            />
            <span className="state__filter--menu__item-text">Fixed</span>
            <Dropdown
              id="state__filter--validated-option"
              name="validated"
              classNamePrefix="state__filter--dropdown"
              placeholder="Includes"
              options={LOGIC_OPTIONS}
              value={logicFilters.validated}
              isDisabled={logicFilters.validated === null}
              onChange={logicFilterChange}
            />
          </li>
          <li className="state__filter--menu__item" onClick={handleItemClick}>
            <Checkbox
              id="state__filter--exported"
              defaultChecked={!!logicFilters.exported}
              name="exported"
              onChange={checkboxChange}
              className="state__filter--checkbox"
            />
            <span className="state__filter--menu__item-text">Exported</span>
            <Dropdown
              id="state__filter--exported-option"
              name="exported"
              classNamePrefix="state__filter--dropdown"
              placeholder="Includes"
              options={LOGIC_OPTIONS}
              value={logicFilters.exported}
              isDisabled={logicFilters.exported === null}
              onChange={logicFilterChange}
            />
          </li>
          <li className="state__filter--menu__item">
            <Button onClick={onSubmit} size="md" variant="outline">
              Apply filter
            </Button>
          </li>
        </ul>
      </div>
    ) : null;
  };

  return ReactDOM.createPortal(
    getListComponent(),
    document.querySelector('#portal'),
  );
};

Menu.propTypes = {
  isOpen: PropTypes.bool,
  offset: PropTypes.shape({}),
  setOpen: PropTypes.func,
  btnRef: PropTypes.shape({}),
  disableItemClose: PropTypes.bool,
  setInput: PropTypes.func,
  applyStateFilters: PropTypes.func,
  filters: PropTypes.shape({}),
};

Menu.defaultProps = {
  isOpen: false,
  offset: {},
  setOpen: () => {},
  btnRef: null,
  disableItemClose: false,
  setInput: () => {},
  applyStateFilters: () => {},
  filters: {},
};

export default Menu;
