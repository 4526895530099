import React from 'react';
import PropTypes from 'prop-types';

import { FaInfoCircle } from 'react-icons/fa';
import './field.scss';

const Field = ({ name, tooltip, additionalText }) => {
  return (
    <div className="field2">
      <div className="field2__section">
        <h3 className="field2__header">{name}</h3>
        <FaInfoCircle
          className="field2__info-icon"
          data-tooltip-id="document-tooltip"
          data-tooltip-place="right"
          data-tooltip-html={tooltip}
        />
      </div>
      {additionalText && (
        <div className="field2__section">
          <p className="field2__text">{additionalText}</p>
        </div>
      )}
    </div>
  );
};

Field.propTypes = {
  name: PropTypes.string.isRequired,
  tooltip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  additionalText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

Field.defaultProps = {
  tooltip: undefined,
  additionalText: undefined,
};

export default Field;
