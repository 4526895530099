import CompanyService from '../../services/CompanyService';
import { addNotification } from '../notifications';
import {
  errorMessage,
  updatedMessage,
  rolesByProductFailedFetch,
  rolesByProductNoCompany,
} from './messages';

export const TRANSMIT_COMPANY = 'sypht/company/TRANSMIT_COMPANY';
export const RECEIVE_COMPANY = 'sypht/company/RECEIVE_COMPANY';
export const RECEIVE_COMPANY_ROLES = 'sypht/company/RECEIVE_COMPANY_ROLES';

const transmitCompany = () => {
  return {
    type: TRANSMIT_COMPANY,
    payload: {
      loading: true,
      sending: true,
      sent: false,
    },
  };
};

const receiveCompany = (data, sent = true) => {
  return {
    type: RECEIVE_COMPANY,
    payload: {
      data,
      loading: false,
      sending: false,
      sent,
      receivedAt: Date.now(),
    },
  };
};

const requestCompanyRoles = () => {
  return {
    type: RECEIVE_COMPANY_ROLES,
    payload: {
      loading: true,
    },
  };
};

const receiveCompanyRoles = data => {
  return {
    type: RECEIVE_COMPANY_ROLES,
    payload: {
      data,
      error: null,
      loading: false,
    },
  };
};

const receiveCompanyRolesError = err => {
  return {
    type: RECEIVE_COMPANY_ROLES,
    payload: {
      data: null,
      error: err,
      loading: false,
    },
  };
};

export const updateCompany = (companyId, companyData, isNotify = true) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;

    try {
      dispatch(transmitCompany());

      const response = await CompanyService.updateCompany(
        accessToken,
        companyId,
        companyData,
      );

      if (response.data && response.data.updated) {
        dispatch(receiveCompany(response.data));
        if (isNotify) {
          dispatch(addNotification(updatedMessage(response.data.name)));
        }
      } else {
        dispatch(receiveCompany(null));
        dispatch(addNotification(errorMessage));
      }
    } catch (error) {
      dispatch(receiveCompany(null));
      dispatch(addNotification({ style: 'error', message: error.message }));
    }
  };
};

export const fetchCompany = companyId => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;
    try {
      dispatch(transmitCompany());
      const response = await CompanyService.getCompany(accessToken, companyId);
      
      if (response.data) {
        dispatch(receiveCompany(response.data));
      } else {
        dispatch(receiveCompany(null));
        dispatch(addNotification(errorMessage));
      }
    } catch (error) {
      // ignore these for now - these are the login errors we get
    }
  };
};

/**
 * Try to fetch company roles for companyId.
 *
 * Normally this would be for the logged in user's company.
 * It can also be a different company, if a superuser is hitting this.
 */
export const fetchCompanyRoles = companyId => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;
    if (!companyId) {
      dispatch(receiveCompanyRolesError('Cannot determine user companyId'));
      dispatch(addNotification(rolesByProductNoCompany()));
      return;
    }
    try {
      dispatch(requestCompanyRoles());
      const roles = await CompanyService.getCompanyRoles(
        accessToken,
        companyId,
      );
      dispatch(receiveCompanyRoles(roles.data));
    } catch (err) {
      dispatch(receiveCompanyRolesError('Failed to fetch company roles'));
      dispatch(addNotification(rolesByProductFailedFetch()));
    }
  };
};
