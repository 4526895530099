export const CORE_PRODUCT_ID = 'core';
export const VALIDATE_PRODUCT_ID = 'validate';
export const VALIDATE_ROLENAME = {
  /**
   * This role does not count towards the subscription seat (license) count.
   */
  NO_ROLE: 'No Role',
};
export const ONBOARDING_TYPE = {
  /**
   * Non-technical users should get a tour.
   */
  TOUR: 'Tour',
  /**
   * Developers should default to visiting API docs.
   */
  API: 'API',
};
export const API_DOCS_URL = 'https://docs.sypht.com';
/**
 * The system may use 'redirectTo` in session storage to route the user after
 * login registration.  If this is not set, use this as the default.
 */
export const DEFAULT_LANDING_ROUTE = '/documents';

export const SAMPLE_DOCS = ['Sample_Invoice.pdf', 'Sample_Receipt.pdf'];
