import React, { createElement } from 'react';
import PropTypes from 'prop-types';

const MultiCell = props => {
  const { selector } = props;
  const component = selector(props);
  if (component) {
    return <div>{createElement(component, props)}</div>;
  }
  return null;
};

MultiCell.propTypes = {
  selector: PropTypes.func.isRequired,
};

MultiCell.defaultProps = {};

export default MultiCell;
