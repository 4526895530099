/**
 * Tooltips for Quick Fix and Fixing Task (HITL) buttons.
 */
const tooltip = {
  quickFixOnly: [
    'Quickly fix any errors ',
    'in the extracted data ',
    'yourself',
  ],
  fixingTaskOnly: [
    'Create a fixing task ',
    'for someone in your ',
    'company (only ',
    'available with Human ',
    'Review add-on)',
  ],
  both: [
    'Quickly fix any errors ',
    'in the extracted data ',
    'yourself, or create a ',
    'fixing task for someone ',
    'in your company (only ',
    'available with Human ',
    'Review add-on)',
  ],
};

const getTooltip = ({
  canDoBoth,
  goToTasksImmediately,
  goToQuickFixImmediately,
  canWriteTask,
}) => {
  if (canDoBoth) {
    return tooltip.both;
  }
  if (goToTasksImmediately) {
    return tooltip.fixingTaskOnly;
  }
  if (goToQuickFixImmediately) {
    return tooltip.quickFixOnly;
  }
  if (canWriteTask) {
    return tooltip.both;
  }
  return tooltip.quickFixOnly;
};

/**
 * Calculate fix button state for use in document list view where user can
 * select 0 or more docs that may or may not be extracted.
 */
export const calcForListView = ({
  hasValidateProduct,
  canWriteTask,
  noOfSelectedDocs,
  selectedDocs,
}) => {
  const canDoTasks =
    canWriteTask && hasValidateProduct && noOfSelectedDocs >= 1;
  const canDoQuickFix =
    noOfSelectedDocs === 1 && Object.values(selectedDocs || {})[0].canQuickFix;
  const canDoBoth = canDoTasks && canDoQuickFix;
  const goToQuickFixImmediately = !canDoTasks && canDoQuickFix;
  const goToTasksImmediately = canDoTasks && !canDoQuickFix;
  const isDisabled =
    !canDoBoth && !goToQuickFixImmediately && !goToTasksImmediately;
  return {
    isDisabled,
    canDoBoth,
    goToQuickFixImmediately,
    goToTasksImmediately,
    tooltip: getTooltip({
      canDoBoth,
      goToTasksImmediately,
      goToQuickFixImmediately,
      canWriteTask,
    }),
  };
};

/**
 * Calculate fix button state for use in single document view.
 */
export const calcForDocView = ({ hasValidateProduct, canWriteTask, doc }) => {
  const { canQuickFix } = doc || {};
  const canDoTasks = canWriteTask && hasValidateProduct;
  const canDoQuickFix = !!canQuickFix;
  const canDoBoth = canDoTasks && canDoQuickFix;
  const goToTasksImmediately = canDoTasks && !canDoQuickFix;
  const goToQuickFixImmediately = !canDoTasks && canDoQuickFix;
  const isDisabled =
    !canDoBoth && !goToQuickFixImmediately && !goToTasksImmediately;
  return {
    isDisabled,
    canDoBoth,
    goToQuickFixImmediately,
    goToTasksImmediately,
    tooltip: getTooltip({
      canDoBoth,
      goToTasksImmediately,
      goToQuickFixImmediately,
      canWriteTask,
    }),
  };
};
