import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './Card.scss';

const CardHeader = ({ children, className }) => {
  const cardHeaderClass = classNames(className, 'card__header');
  return <div className={cardHeaderClass}>{children}</div>;
};

CardHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

CardHeader.defaultProps = {
  children: null,
  className: null,
};

export default CardHeader;
