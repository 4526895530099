import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const initialState = {
  isOpen: false,
  isSending: false,
  isSent: false,
};

const ModalContext = createContext([{}, () => {}]);

const ModalContextProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  return (
    <ModalContext.Provider value={[state, setState]}>
      {children}
    </ModalContext.Provider>
  );
};

ModalContextProvider.propTypes = {
  children: PropTypes.node,
};

ModalContextProvider.defaultProps = {
  children: null,
};

export { ModalContext, ModalContextProvider };
