import React from 'react';
import Select from 'react-select/creatable';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './Select.scss';

const CreatableSelect = ({
  id,
  className,
  errors,
  forwardRef,
  label,
  name,
  ...props
}) => {
  const dropdown = (
    <Select
      inputId={id}
      classNamePrefix="select"
      className={classNames('select select--creatable', className)}
      isMulti
      ref={forwardRef}
      {...props}
    />
  );

  return (
    <div
      className={classNames('form__group', {
        'form__group--error': !!errors[name],
      })}
    >
      {label ? (
        <div className="select__container">
          <label className="select__label" htmlFor={id}>
            {label}
          </label>
          {dropdown}
          {errors[name] ? (
            <span className="form__input-error">{errors[name]}</span>
          ) : null}
        </div>
      ) : (
        dropdown
      )}
    </div>
  );
};

CreatableSelect.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  errors: PropTypes.object,
  forwardRef: PropTypes.object,
  label: PropTypes.string,
};

CreatableSelect.defaultProps = {
  id: null,
  className: null,
  name: '',
  errors: {},
  forwardRef: null,
  label: null,
};

export default CreatableSelect;
