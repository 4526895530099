import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './_dropdownMenu.scss';

const DropdownMenuHeading = ({ className, children }) => {
  return (
    <h3 className={classNames('dropdown-menu__heading', className)}>
      {children}
    </h3>
  );
};

DropdownMenuHeading.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

DropdownMenuHeading.defaultProps = {
  children: null,
  className: null,
};

export default DropdownMenuHeading;
