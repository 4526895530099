import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'react-tiny-popover';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import './MenuButton.scss';

/**
 * Add a popover to a clickable element.
 *
 * Usually the popover goes below but it can be placed.
 *
 * @param {*} props
 * @param {*} props.isOpen Controls open state of popover menu
 * @param {*} props.children A clickable thing like a button
 * @param {*} props.disablePopover Never open the popover
 * @param {*} props.content The content in the popover
 * @param {*} props.positions Preferred popover positions by priority
 */
const MenuButton = ({
  isOpen,
  onOutsideClick,
  children,
  content,
  positions,
  align,
}) => {
  // useEffect(() => {
  //   if (isOpen) {
  //     // If user has opened the dropdown, hide the tooltip.
  //     ReactTooltip.hide();
  //   }
  // }, [isOpen]);

  return (
    <Popover
      padding={8}
      align={align}
      isOpen={isOpen}
      positions={positions}
      content={content}
      onClickOutside={evt => {
        onOutsideClick(evt);
      }}
      containerClassName="menu-button"
    >
      {children}
    </Popover>
  );
};

MenuButton.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.func.isRequired,
  content: PropTypes.func,
  onOutsideClick: PropTypes.func.isRequired,
  align: PropTypes.oneOf(['start', 'center', 'end']),
  positions: PropTypes.arrayOf(
    PropTypes.oneOf(['bottom', 'top', 'left', 'right']),
  ),
};

MenuButton.defaultProps = {
  content: null,
  positions: ['bottom', 'top', 'left', 'right'],
  align: 'start',
};

export default MenuButton;
