import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3-scale-chromatic';
import { Bar } from 'react-chartjs-2';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Card, { CardContent, CardHeader } from '../../../UI/Card';
import {
  getDays,
  getWeeks,
  getMonths,
  getWeeklyUsage,
} from './timeFilterHelper';
import './PredictionGraph.scss';
import { generateLegend } from '../../../../common/helpers/generateLegend';

const getGraphConfig = mode => ({
  legend: {
    display: false,
    position: 'bottom',
    labels: {
      fontSize: 12,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: 'Date (DD/MM)',
        },
        stacked: true,
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        scaleLabel: {
          display: true,
          labelString:
            (mode === 'spend' && '$ Spend') ||
            (mode === 'usage' && '# Pages') ||
            (mode === 'docs' && '# Documents'),
        },
        stacked: true,
        ticks: {
          type: 'logarithmic',
          beginAtZero: true,
          precision: 0,
        },
      },
    ],
  },
});

const COLOR_RANGE_CONFIG = {
  colorStart: 0,
  colorEnd: 1,
  useEndAsStart: false,
};

const getColors = noOfColors => {
  const { colorStart, colorEnd, useEndAsStart } = COLOR_RANGE_CONFIG;
  const colorScale = d3.interpolateRainbow;
  const colorRange = colorEnd - colorStart;
  const intervalSize = colorRange / noOfColors;

  const calculatePoint = i => {
    return useEndAsStart
      ? colorEnd - i * intervalSize
      : colorStart + i * intervalSize;
  };

  const colorArray = [];
  for (let i = 0; i < noOfColors; i++) {
    colorArray.push(colorScale(calculatePoint(i)));
  }

  return colorArray;
};

const getDatasets = (subscriptionStats, product, timeFilter, mode) => {
  const colors = getColors(subscriptionStats.length);
  const predictions = subscriptionStats
    .map((s, i) => {
      const originalDataset =
        (mode === 'spend' && s.totalSpendDaily) ||
        (mode === 'usage' && s.dailyUsage) ||
        (mode === 'docs' && s.dailyDocs);
      let data = [];
      if (timeFilter === 'days') {
        data = [...originalDataset];
      } else if (timeFilter === 'weeks') {
        data = getWeeklyUsage(originalDataset, mode);
      } else if (timeFilter === 'months') {
        data = mode === 'spend' ? [s.totalSpend] : [s.totalUsage];
      }
      return {
        label: s.code,
        data,
        backgroundColor: colors[i],
        borderWidth: 1,
        fill: true,
      };
    })
    .filter(s => {
      if (!product || product.value === 'all') return true;
      return s.label === product.value;
    });
  return predictions;
};

const PredictionsGraph = ({
  billingPeriod,
  product,
  subscriptionStats,
  timeFilter,
  mode,
  isVisible,
}) => {
  if (!billingPeriod || !subscriptionStats) return null;

  const [labels, setLabels] = useState(null);
  const [datasets, setDatasets] = useState(null);

  useEffect(() => {
    if (timeFilter === 'days') {
      setLabels(getDays(billingPeriod));
    } else if (timeFilter === 'weeks') {
      setLabels(getWeeks(billingPeriod));
    } else if (timeFilter === 'months') {
      setLabels(getMonths(billingPeriod));
    }
    setDatasets(getDatasets(subscriptionStats, product, timeFilter, mode));
  }, [timeFilter, billingPeriod, subscriptionStats, mode]);

  const chartJsRef = useRef();

  generateLegend({
    chartId: 'chartJS-legend-list',
    chartRef: chartJsRef,
    data: labels,
  });

  return (
    <Card
      className={classNames('prediction-graph', {
        visibility: isVisible,
      })}
    >
      <CardHeader>
        <h2 className="card__heading">
          {(mode === 'spend' && '$ Total Spend vs Time') ||
            (mode === 'usage' && '# Pages Extracted vs Time') ||
            (mode === 'docs' && '# Documents Processed vs Time')}
        </h2>
      </CardHeader>
      <CardContent>
        <Bar
          id="chartBilling"
          ref={chartJsRef}
          data={{
            labels,
            datasets,
          }}
          options={getGraphConfig(mode)}
        />
      </CardContent>
    </Card>
  );
};

PredictionsGraph.propTypes = {
  billingPeriod: PropTypes.object,
  product: PropTypes.object,
  subscriptionStats: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  timeFilter: PropTypes.string,
  mode: PropTypes.string,
};

PredictionsGraph.defaultProps = {
  title: '# Pages Extracted vs Time',
  product: 'all',
  billingPeriod: null,
  subscriptionStats: null,
  timeFilter: 'days',
  mode: 'spend',
};

export default PredictionsGraph;
