import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaCut } from 'react-icons/fa';
import { Button } from '../../../../library/atoms/Buttons';
import MenuButton from '../../../../library/molecules/MenuButton/MenuButton';
import DropdownList from '../../../../library/atoms/DropdownList/DropdownList';
import DropdownListItem from '../../../../library/atoms/DropdownListItem/DropdownListItem';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const SplitButton = ({
  isDisabled,
  showModal,
  hasValidateProduct,
  ...buttonProps
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const disablePopover = !hasValidateProduct;

  return (
    <MenuButton
      isOpen={isOpen}
      disablePopover={disablePopover}
      onOutsideClick={() => {
        setIsOpen(false);
      }}
      content={
        <DropdownList>
          <DropdownListItem
            onClick={() => {
              showModal('isSmartSplitModalOpen');
              setIsOpen(false);
            }}
          >
            Quick Split
          </DropdownListItem>
          <DropdownListItem
            onClick={() => {
              showModal('isSplitTaskModalOpen');
              setIsOpen(false);
            }}
          >
            Create Splitting Task
          </DropdownListItem>
        </DropdownList>
      }
    >
      {
        <span data-tooltip-id="split-button-tooltip">
          <Button
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...buttonProps}
            disabled={isDisabled}
            onClick={() => {
              if (!hasValidateProduct) {
                showModal('isSmartSplitModalOpen');
                setIsOpen(false);
                return;
              }
              setIsOpen(state => (disablePopover ? false : !state));
            }}
          >
            <FaCut />
            <span>Split</span>
          </Button>
          <ReactTooltip
            id="split-button-tooltip"
            content="Split a multi-document file using Sypht's Smart Split AI model"
            place="bottom"
            style={{
              top: '4',
              bottom: '4',
              left: '4',
              position: 'fixed',
              zIndex: 9999,
            }}
            hidden={isOpen}
          />
        </span>
      }
    </MenuButton>
  );
};

SplitButton.propTypes = {
  isDisabled: PropTypes.bool,
  showModal: PropTypes.func.isRequired,
  hasValidateProduct: PropTypes.bool.isRequired,
};

SplitButton.defaultProps = {
  isDisabled: false,
};

export default SplitButton;
