import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './Page.scss';

const Elem = ({ children, className, elemName }) => {
  return (
    <div className={classNames(`page__${elemName}`, className)}>{children}</div>
  );
};

Elem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  elemName: PropTypes.string.isRequired,
};

Elem.defaultProps = {
  children: null,
  className: null,
};

export default Elem;
