/* eslint-disable func-names */
import { mergeRight as merge } from 'ramda';
import { isCancel } from 'axios';
import { replace } from 'connected-react-router';
import DocsService from '../services/DocsService';
import CompanyService from '../services/CompanyService';

import { addNotification } from './notifications';
import { STYLE } from '../components/UI/Notification/Notification';
import { SMART_SPLIT } from '../components/SplitModal/SplitModal';
import { SpecificationLookup } from '../common/lookups';
import {
  addManualSplit,
  RECEIVE_ADD_BATCH_TASK,
  RECEIVE_ADD_TASK,
} from './tasks/actions';

import {
  REQUEST_DOC_TASKS,
  RECEIVE_DOC_TASKS,
  RECEIVE_DOC_META,
  fetchDocMeta,
} from './docs/actions';
import { SAMPLE_DOCS } from '../common/constants';

import ingest from '../services/workflowService';

const moment = require('moment');

export const REQUEST_DOCS = 'sypht/docs/REQUEST_DOCS';
export const RECEIVE_DOCS = 'sypht/docs/RECEIVE_DOCS';
export const REQUEST_DOC = 'sypht/docs/REQUEST_DOC';
export const RECEIVE_DOC = 'sypht/docs/RECEIVE_DOC';
export const REQUEST_DOC_EXPORT = 'sypht/docs/REQUEST_DOC_EXPORT';
export const REQUEST_BATCH_DOC_EXPORT = 'sypht/docs/REQUEST_BATCH_DOC_EXPORT';
export const BATCH_EXPORT_PROGRESS = 'sypht/docs/BATCH_EXPORT_PROGRESS';
export const RECEIVE_DOC_EXPORT = 'sypht/docs/RECEIVE_DOC_EXPORT';
export const REQUEST_DOCS_DELETED = 'sypht/docs/REQUEST_DOCS_DELETED';
export const RECEIVE_DOCS_DELETED = 'sypht/docs/RECEIVE_DOCS_DELETED';

export const REQUEST_EXTRACT = 'sypht/docs/REQUEST_EXTRACT';
export const RECEIVE_EXTRACT = 'sypht/docs/RECEIVE_EXTRACT';
export const REQUEST_EXTRACT_DOC = 'sypht/docs/REQUEST_EXTRACT_DOC';

export const REQUEST_DOCS_PRODUCTS = 'sypht/docs/REQUEST_DOCS_PRODUCTS';
export const RECEIVE_DOCS_PRODUCTS = 'sypht/docs/RECEIVE_DOCS_PRODUCTS';

export const RECORD_EXTRACTED_DOC = 'sypht/docs/RECORD_EXTRACTED_DOC';

export const REQUEST_COMPANIES = 'sypht/docs/REQUEST_COMPANIES';
export const RECEIVE_COMPANIES = 'sypht/docs/RECEIVE_COMPANIES';

export const RECEIVE_GENERIC_DATA = 'sypht/docs/RECEIVE_GENERIC_DATA';
export const RECEIVE_GENERIC_TABLES = 'sypht/docs/RECEIVE_GENERIC_TABLES';
export const RECEIVE_GENERIC_FIELDS = 'sypht/docs/RECEIVE_GENERIC_FIELDS';

export const REQUEST_DOC_DOWNLOAD = 'sypht/docs/REQUEST_DOC_DOWNLOAD';
export const RECEIVE_DOC_DOWNLOAD = 'sypht/docs/RECEIVE_DOC_DOWNLOAD';

export const REQUEST_SPLIT = 'sypht/docs/REQUEST_SPLIT';
export const RECEIVE_SPLIT = 'sypht/docs/RECEIVE_SPLIT';

function isExtracting(doc, docCount, state) {
  if (state.docsRequested.includes(doc.id)) {
    return true;
  }
  if (
    docCount === 2 &&
    SAMPLE_DOCS.includes(doc.filename) &&
    doc.extractedAt === null &&
    doc.products.length === 0
  ) {
    return true;
  }
  return false;
}

const initial = {
  data: null,
  loading: false,
  extract: null,
  extractedFieldsets: null,
  docsExtracted: [],
  docsRequested: [],
  exportLoading: false,
  batchExportLoading: false,
  batchExportProgress: 0,
  batchExportTotal: 0,
  exportResponse: null,
  filters: {},
  active: {
    data: null,
  },
  companies: {
    data: null,
    loading: false,
  },
  tasks: {},
  splitRequested: false,
};

export default function docsReducer(state = initial, action) {
  const { payload, type } = action;
  const docs = state.data && state.data.docs ? [...state.data.docs] : [];

  switch (type) {
    case REQUEST_DOCS:
      return merge(state, {
        filters: action.data.filters,
        loading: true,
      });
    case RECEIVE_DOCS:
      if (isCancel(action.data)) {
        return state;
      }
      return merge(state, {
        data: {
          ...action.data,
          docs: action.data.docs.map((d, _, ds) => ({
            ...d,
            extracting: isExtracting(d, ds.length, state),
          })),
          numPages: !state.filters.showCount
            ? state.data?.numPages
            : action.data.numPages,
          count: !state.filters.showCount
            ? state.data?.count
            : action.data.count,
        },
        loading: false,
      });
    case REQUEST_COMPANIES:
      return merge(state, {
        companies: {
          loading: true,
        },
      });
    case RECEIVE_COMPANIES:
      return merge(state, {
        companies: {
          loading: false,
          data: action.data,
        },
      });
    case REQUEST_DOC:
      return merge(state, { ...action.data });
    case RECEIVE_DOC:
      return merge(state, {
        active: {
          data: action.data,
        },
      });
    case REQUEST_DOC_DOWNLOAD:
      return merge(state, {
        exportLoading: true,
      });
    case RECEIVE_DOC_DOWNLOAD:
      return merge(state, {
        exportResponse: action.data,
        exportLoading: false,
      });
    case REQUEST_DOC_EXPORT:
      return merge(state, {
        exportLoading: true,
      });
    case REQUEST_BATCH_DOC_EXPORT:
      return merge(state, {
        exportLoading: true,
        batchExportLoading: true,
        batchExportTotal: parseInt(action.data.total, 10),
        batchExportProgress: 0,
      });
    case BATCH_EXPORT_PROGRESS:
      if (state.batchExportLoading) {
        return merge(state, {
          batchExportProgress: parseInt(state.batchExportProgress + 1, 10),
        });
      }
      return state;
    case RECEIVE_DOC_EXPORT:
      return merge(state, {
        exportResponse: action.data,
        exportLoading: false,
        batchExportLoading: false,
        batchExportTotal: 0,
        bacthExportProgress: 0,
      });
    case REQUEST_DOCS_DELETED:
      return merge(state, { ...action.data });
    case RECEIVE_DOCS_DELETED:
      docs.filter(d => action.data.find(doc => doc.id === d.id));
      return merge(state, {
        data: {
          ...state.data,
          docs,
        },
      });
    case REQUEST_EXTRACT:
      return merge(state, {
        docsExtracted: [],
        docsRequested: action.docsRequested,
        active: {
          ...state.active,
        },
        data: {
          docs: docs.map(d => ({
            ...d,
            extracting: action.docsRequested.includes(d.id),
          })),
        },
      });
    case RECEIVE_EXTRACT:
      return merge(state, {
        extract: 'COMPLETE',
        docsRequested: state.docsRequested.filter(
          d => !action.data.docsExtracted.includes(d),
        ),
        docsExtracted: action.data.docsExtracted || [],
        active: {
          ...state.active,
          docsExtracted: action.data.docsExtracted || [],
        },
        data: {
          docs: docs.map(d => ({
            ...d,
            extracting: state.docsRequested
              .filter(dr => !action.data.docsExtracted.includes(dr))
              .includes(d.id),
          })),
        },
      });
    case REQUEST_EXTRACT_DOC:
      return merge(state, {
        docsRequested: [...state.docsRequested, action.docId],
        active: {
          ...state.active,
        },
        data: {
          docs: docs.map(d => ({
            ...d,
            extracting: action.docId === d.id ? true : d.extracting,
          })),
        },
      });

    case REQUEST_SPLIT:
      return merge(state, {
        splitRequested: true,
      });
    case RECEIVE_SPLIT:
      return merge(state, {
        splitRequested: false,
      });
    case REQUEST_DOCS_PRODUCTS:
      return merge(state, {
        active: {
          data: action.data,
        },
      });
    case RECEIVE_DOCS_PRODUCTS:
      return merge(state, {
        active: {
          data: {
            ...state.active.data,
            ...action.data,
          },
        },
      });
    case RECORD_EXTRACTED_DOC:
      return merge(state, {
        docsExtracted: [...state.docsExtracted, action.data],
      });

    case RECEIVE_GENERIC_DATA:
      return merge(state, {
        genericData: action.data,
      });

    case RECEIVE_GENERIC_TABLES:
      return merge(state, {
        genericTables: action.data,
      });

    case RECEIVE_GENERIC_FIELDS:
      return merge(state, {
        genericFields: action.data,
      });

    case RECEIVE_DOC_META: {
      const { data } = payload;
      const { id } = data || {};
      const doc = docs.findIndex(d => d.id === id);
      if (doc !== -1) {
        docs[doc] = {
          ...docs[doc],
          ...data,
        };
      }
      return merge(state, {
        data: {
          ...state.data,
          docs,
        },
      });
    }

    case REQUEST_DOC_TASKS: {
      const { docId } = payload.data;
      const newTasks = { ...state.tasks };
      const task = newTasks[docId];

      if (task) {
        task.loading = true;
      } else {
        newTasks[docId] = {
          loading: true,
          noOfTasks: null,
          lastUpdated: null,
        };
      }

      return merge(state, {
        tasks: newTasks,
      });
    }

    case RECEIVE_DOC_TASKS: {
      const { data, lastUpdated } = payload;
      const { docId, noOfTasks } = data;
      const newTasks = { ...state.tasks };

      newTasks[docId] = {
        loading: false,
        noOfTasks: Number(noOfTasks || '0'),
        lastUpdated,
      };

      return merge(state, {
        tasks: newTasks,
      });
    }

    case RECEIVE_ADD_TASK: {
      const { data } = payload;
      const { docId } = data;
      const newTasks = { ...state.tasks };

      if (newTasks[docId]) {
        delete newTasks[docId];
      }

      return merge(state, {
        tasks: newTasks,
      });
    }

    case RECEIVE_ADD_BATCH_TASK: {
      return merge(state, {
        tasks: {},
      });
    }

    default:
      return state;
  }
}

export const requestDocs = filters => ({
  type: REQUEST_DOCS,
  data: { filters },
});
export const receiveDocs = data => ({ type: RECEIVE_DOCS, data });

export function fetchDocs(params, cancelToken) {
  return function (dispatch, getState) {
    const {
      docs: {
        filters: { tags, query, filename, showCount },
        loading,
        data,
      },
    } = getState();
    // checking to see if there is a new search and appending showCount parameter
    // we do this in the reducer rather than through the query parameters
    // because we want to avoid querying the account on every request.
    // this puts too much strain on the db

    const reqShowCount =
      params.query !== query ||
      params.tags !== tags ||
      params.filename !== filename ||
      data === null ||
      data.numDocs === 0 ||
      (showCount && loading);

    const token = getState().auth.accessToken;
    dispatch(requestDocs({ ...params, showCount: reqShowCount }));
    return (
      DocsService.getDocs(
        token,
        { ...params, showCount: reqShowCount },
        cancelToken,
      )
        // eslint-disable-next-line no-shadow
        .then(({ data }) => dispatch(receiveDocs(data)))
        .catch(e => dispatch(receiveDocs(e)))
    );
  };
}

export function fetchDocsWithStoredFilters() {
  return function (dispatch, getState) {
    const token = getState().auth.accessToken;
    const { filters } = getState().docs;
    dispatch(requestDocs(filters));
    return DocsService.getDocs(token, filters)
      .then(({ data }) => dispatch(receiveDocs(data)))
      .catch(e => dispatch(receiveDocs(e)));
  };
}

export const requestDoc = () => ({ type: REQUEST_DOC });
export const receiveDoc = data => ({ type: RECEIVE_DOC, data });
export function fetchDoc(docId) {
  return function (dispatch, getState) {
    const token = getState().auth.accessToken;
    dispatch(requestDoc());
    return DocsService.getDoc(token, docId)
      .then(({ data }) => dispatch(receiveDoc(data)))
      .catch(e => dispatch(receiveDoc(e)));
  };
}

export const requestCompanies = () => ({ type: REQUEST_COMPANIES });
export const receiveCompanies = data => ({ type: RECEIVE_COMPANIES, data });
export function fetchCompanies() {
  return function (dispatch, getState) {
    const token = getState().auth.accessToken;
    dispatch(requestCompanies());
    return CompanyService.getCompanies(token)
      .then(res => dispatch(receiveCompanies(res.data)))
      .catch(e => dispatch(receiveCompanies(e)));
  };
}

export const requestDocDownload = () => ({ type: REQUEST_DOC_DOWNLOAD });
export const receiveDocDownload = (data, error) => ({
  type: RECEIVE_DOC_DOWNLOAD,
  data,
  error,
});
export function downloadDoc(docId, filename) {
  return function (dispatch, getState) {
    dispatch(requestDocDownload());
    const token = getState().auth.accessToken;
    return DocsService.getDoc(token, docId)
      .then(({ data: document }) => {
        // original files are downloaded as octet-streams,
        // need to hack axios to override content type header
        return DocsService.downloadDoc(
          document.original,
          document.received.contentType,
        );
      })
      .then(({ headers, data }) => {
        // content type override
        const blob = new Blob([data], { type: headers['content-type'] });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = filename;
        anchor.click();
        window.URL.revokeObjectURL(url);
        const response = {
          data,
          filename,
        };
        dispatch(receiveDocDownload(response, null));
      })
      .catch(e => {
        dispatch(receiveDocDownload(null, e));
      });
  };
}

export const requestDocExport = () => ({ type: REQUEST_DOC_EXPORT });
export const requestBatchDocExport = data => ({
  type: REQUEST_BATCH_DOC_EXPORT,
  data,
});
export const batchExportProgress = () => ({ type: BATCH_EXPORT_PROGRESS });
export const receiveDocExport = (data, error) => ({
  type: RECEIVE_DOC_EXPORT,
  data,
  error,
});

export function exportDocs(data, format = 'csv') {
  const { docs, isSelectAll, total } = data;
  return function (dispatch, getState) {
    if (isSelectAll) {
      dispatch(requestBatchDocExport({ total }));
    }
    dispatch(requestDocExport());
    const token = getState().auth.accessToken;
    const timestamp = moment().format('YYYYMMDD-HHmmss');
    return (
      DocsService.exportDocs(token, data, format)
        // eslint-disable-next-line no-shadow
        .then(({ data }) => {
          let blob = new Blob([data.headerFields], { type: `text/${format}` });
          let url = window.URL.createObjectURL(blob);
          let anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = `export.${timestamp}.${format}`;
          anchor.click();
          window.URL.revokeObjectURL(url);

          if (data.lineItemData) {
            // janky delay
            setTimeout(() => {
              blob = new Blob([data.lineItemData], { type: `text/${format}` });
              url = window.URL.createObjectURL(blob);
              anchor = document.createElement('a');
              anchor.href = url;
              anchor.download = `export-lineitems.${timestamp}.${format}`;
              anchor.click();
              window.URL.revokeObjectURL(url);
            }, 1000);
          }

          dispatch(receiveDocExport(data));
        })
        .then(() => {
          if (docs) {
            docs.forEach(docId => {
              dispatch(fetchDocMeta(docId));
            });
          }
        })
        .catch(e => {
          dispatch(receiveDocExport(null, e));
        })
    );
  };
}

export const exportNDIS = (data, format = 'csv') => {
  const { total, isSelectAll } = data;
  return (dispatch, getState) => {
    if (isSelectAll) {
      dispatch(requestBatchDocExport({ total }));
    }
    dispatch(requestDocExport());
    const token = getState().auth.accessToken;
    const timestamp = moment().format('YYYYMMDD-HHmmss');
    return (
      DocsService.exportNDISDocs(token, data, format)
        // eslint-disable-next-line no-shadow
        .then(({ data }) => {
          const blob = new Blob([data], { type: `text/${format}` });
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = `exportNDIS.${timestamp}.${format}`;
          anchor.click();
          window.URL.revokeObjectURL(url);
          dispatch(receiveDocExport(data));
        })
        .catch(e => dispatch(receiveDocExport(null, e)))
    );
  };
};

export const requestDocsDeleted = () => ({ type: REQUEST_DOCS_DELETED });
export const receiveDocsDeleted = (data, error) => ({
  type: RECEIVE_DOCS_DELETED,
  data,
  error,
});

export function deleteDocs(docs) {
  return function (dispatch, getState) {
    const token = getState().auth.accessToken;
    dispatch(requestDocsDeleted());
    return DocsService.deleteDocs(token, docs)
      .then(({ data }) => {
        dispatch(receiveDocsDeleted(data, null));
      })
      .catch(e => dispatch(receiveDocsDeleted(null, e)));
  };
}

export const requestExtract = (numberOfDocs, docsRequested) => ({
  type: REQUEST_EXTRACT,
  numberOfDocs,
  docsRequested,
});
export const receiveExtract = data => ({ type: RECEIVE_EXTRACT, data });

export function extract(docs, fieldsets, companyId) {
  return function (dispatch, getState) {
    const token = getState().auth.accessToken;
    const userCompanyId = getState().auth.claims
      ? getState().auth.claims.companyId
      : null;
    const reqCompanyId = companyId || userCompanyId;
    dispatch(requestExtract(docs.length, docs));
    dispatch(
      addNotification({
        style: STYLE.CONFIRMATION,
        message: `Extraction request has been sent to queue!`,
      }),
    );
    return DocsService.extract(token, docs, fieldsets, reqCompanyId)
      .then(({ data }) => {
        if (data.length === 0) dispatch(receiveExtract());
      })
      .catch(e => dispatch(receiveExtract(e)));
  };
}

export const requestExtractDoc = docId => ({
  type: REQUEST_EXTRACT_DOC,
  docId,
});

export function ingestAndExtractDoc(docId, region, fieldsets, companyId) {
  return async function (dispatch, getState) {
    const { auth } = getState();
    const { accessToken } = auth;

    dispatch(requestExtractDoc(docId));

    await ingest(accessToken, docId, region);

    const userCompanyId = getState().auth.claims
      ? getState().auth.claims.companyId
      : null;
    const reqCompanyId = companyId || userCompanyId;

    return DocsService.extract(accessToken, [docId], fieldsets, reqCompanyId)
      .then(({ data }) => {
        if (data.length === 0) dispatch(receiveExtract());
      })
      .catch(e => dispatch(receiveExtract(e)));
  };
}

export const requestSplit = (numberOfDocs, docsRequested) => ({
  type: REQUEST_SPLIT,
  numberOfDocs,
  docsRequested,
});
export const receiveSplit = data => ({ type: RECEIVE_SPLIT, data });

/**
 * Split document using Sypht AI, no tasks involved.
 */
export const autoSplit = (docs, companyId) => {
  const workflowOptions = {
    childWorkflow: 'prediction',
    childWorkflowOptions: {
      prediction: {
        fieldSets: [SpecificationLookup.DOCUMENT_TYPE],
      },
    },
  };
  return function (dispatch, getState) {
    const token = getState().auth.accessToken;
    const userCompanyId = getState().auth.claims
      ? getState().auth.claims.companyId
      : null;
    const reqCompanyId = companyId || userCompanyId;
    dispatch(requestSplit(docs.length, docs));
    dispatch(
      addNotification({
        style: STYLE.CONFIRMATION,
        message: `Smart Split request has been sent to queue!`,
      }),
    );
    return DocsService.split(token, docs, workflowOptions, reqCompanyId)
      .then(({ data }) => {
        if (data.length === 0) dispatch(receiveSplit());
      })
      .catch(e => dispatch(receiveSplit(e)));
  };
};

/**
 * Manual split = "split doc using task with task_type = 'fix'".
 * Both HITL and non-HITL users can do this.
 */
export const manualSplit = docs => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { userId } = auth;
    const urls = await Promise.all(
      docs.map(async docId =>
        dispatch(
          addManualSplit({
            docId,
          }),
        ).then(({ task }) => {
          return `/documents/split/${task.id}/user/${userId}`;
        }),
      ),
    );
    const firstSplitTask = urls[0];
    if (!firstSplitTask) {
      dispatch(
        addNotification({
          style: STYLE.ERROR,
          message: `Failed to process the split task(s)`,
        }),
      );
      return;
    }
    dispatch(replace(firstSplitTask));
  };
};

export const autoSplitOrManualSplit = (
  splitType,
  selectedDocs,
  companyIdFromFilters,
) => {
  const docs = selectedDocs ? Object.keys(selectedDocs) : [];
  return async dispatch => {
    if (splitType === SMART_SPLIT) {
      return dispatch(autoSplit(docs, companyIdFromFilters));
    }
    return dispatch(manualSplit(docs));
  };
};

export const requestDocsPredictions = () => ({ type: REQUEST_DOCS_PRODUCTS });
export const receiveDocsPredictions = data => ({
  type: RECEIVE_DOCS_PRODUCTS,
  data,
});

export function getDocsPredictions(docs) {
  return function (dispatch, getState) {
    const token = getState().auth.accessToken;
    dispatch(requestDocsPredictions());
    return DocsService.getDocsPredictions(token, docs)
      .then(({ data }) => dispatch(receiveDocsPredictions(data)))
      .catch(e => dispatch(receiveDocsPredictions(e)));
  };
}

export function onReceiveExtract(data) {
  return function (dispatch) {
    dispatch(
      receiveExtract({
        productsExtracted: data.products,
        docsRequested: data.docs.length,
        docsExtracted: data.docsExtracted,
      }),
    );
    (data.docsExtracted || []).forEach(docId => dispatch(fetchDocMeta(docId)));
  };
}

export const receiveGenericData = data => ({
  type: RECEIVE_GENERIC_DATA,
  data,
});

export const receiveGenericTables = data => ({
  type: RECEIVE_GENERIC_TABLES,
  data,
});

export const receiveGenericFields = data => ({
  type: RECEIVE_GENERIC_FIELDS,
  data,
});
