import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmModal } from '../../../../../UI/ModalV2';

const UnassignModal = ({ tag, onConfirmClick, ...props }) => {
  if (tag) {
    return (
      <ConfirmModal
        className="tags__delete-modal"
        heading="Convert access tag back to normal?"
        confirmText="Continue"
        onConfirmClick={onConfirmClick}
        {...props}
      >
        Are you sure you want to convert <strong>{tag.name}</strong> to a normal
        tag. This action will unassign this tag from {tag.numberOfUsers} user
        {tag.numberOfUsers > 1 && 's'}?
      </ConfirmModal>
    );
  }
};

UnassignModal.propTypes = {
  tag: PropTypes.object,
  onConfirmClick: PropTypes.func,
};

UnassignModal.defaultProps = {
  tag: null,
  onConfirmClick: () => {},
};

export default UnassignModal;
