import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './TextInput.scss';

const getPlaceHolderText = (placeholder, required) => {
  if (required && placeholder) {
    return `${placeholder} (required)`;
  }

  if (required) {
    return 'Required';
  }

  return placeholder || '';
};

const TextArea = ({
  id,
  errors,
  placeholder,
  tooltip,
  tooltipClick,
  dataTip,
  ...props
}) => {
  const { label, name, required } = props;
  const fieldId = id || name;
  const errorText = errors[name] ? errors[name][errors[name].length - 1] : null;
  const placeholderText = getPlaceHolderText(placeholder || label, required);

  const formGroupClass = classNames('form__group', {
    'form__group--error': !!errors[name],
  });

  return (
    <div className={formGroupClass}>
      <textarea
        id={fieldId}
        className="form__input form__input--textarea"
        placeholder={placeholderText}
        {...props}
      />
      {label ? (
        <label className="form__input-label" htmlFor={name}>
          {label}
          {required && ' *'}
          {tooltip && (
            <button
              className="form__tooltip-button"
              type="button"
              data-tooltip-content={dataTip || "What's this?"}
              onClick={tooltipClick}
            >
              <FaInfoCircle />
              <ReactTooltip place="top" />
            </button>
          )}
        </label>
      ) : null}
      {errorText ? (
        <span className="form__input-error">{errorText}</span>
      ) : null}
    </div>
  );
};

TextArea.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  tooltip: PropTypes.bool,
  tooltipClick: PropTypes.func,
  dataTip: PropTypes.string,
};

TextArea.defaultProps = {
  id: null,
  label: PropTypes.string,
  placeholder: null,
  value: '',
  required: false,
  disabled: false,
  errors: {},
  tooltip: false,
  tooltipClick: () => {},
  dataTip: null,
};

export default TextArea;
