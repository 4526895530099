import { mergeRight as merge } from 'ramda';
import {
  RECEIVE_DATA,
  RECEIVE_PASSWORD_RESULT,
  RECEIVE_USER,
  REQUEST_USER,
  SEND_USER,
  TRANSMIT_DATA,
} from './actions';

const initialState = {
  data: null,
  error: null,
  loading: false,
  sending: false,
  sent: false,
  lastUpdated: null,
  changePassword: {
    data: null,
    lastUpdated: null,
  },
};

const userReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case SEND_USER:
    case TRANSMIT_DATA:
    case RECEIVE_DATA: {
      return merge(state, payload);
    }

    case REQUEST_USER: {
      return merge(state, {
        loading: true,
      });
    }

    case RECEIVE_USER: {
      const { data, error, loading, sent, receivedAt } = payload;
      return merge(state, {
        data,
        error,
        loading,
        sent,
        lastUpdated: receivedAt,
      });
    }

    case RECEIVE_PASSWORD_RESULT: {
      const { data, receivedAt } = payload;
      return merge(state, {
        changePassword: {
          data,
          lastUpdated: receivedAt,
        },
      });
    }

    default:
      return state;
  }
};

export default userReducer;
