/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../../../atoms/Checkbox/Checkbox';
import Dropdown from '../../../../molecules/Dropdown';
import {
  DropdownMenu,
  DropdownMenuBody,
  DropdownMenuHeading,
} from '../../../../molecules/DropdownMenu';

const ShowColumns = ({ allColumns, toggleColumns }) => {
  const columnNames = useMemo(() => {
    if (toggleColumns === true) return {};

    // create dictionary of toggleColumns eg. { 'column': 'Custom label' }
    const names = {};
    toggleColumns.forEach(c => {
      // in case just the id is provided, convert to object eg. { 'name': 'name' }
      if (typeof c === 'string') {
        names[c] = c;
        return;
      }

      // add custom names eg. { 'name': 'Custom label for name' }
      Object.entries(c).forEach(([k, v]) => {
        names[k] = v;
      });
    });
    return names;
  }, [toggleColumns]);

  const filteredColumns = useMemo(() => {
    // always remove checkbox columns
    let columns = allColumns.filter(col => col.id !== 'checkbox');
    if (toggleColumns === true) return columns;

    // only include toggleColumns (transformed to columnNames)
    if (Object.keys(columnNames)) {
      columns = columns.filter(col => {
        return Object.keys(columnNames).includes(col.id);
      });
    }
    return columns;
  }, [allColumns, columnNames, toggleColumns]);

  return (
    <Dropdown
      className="show-columns"
      type="ellipsis"
      position="right"
      autoWidth
      defaultOpen={false}
    >
      <DropdownMenu className="show-more-menu" isOpen>
        <DropdownMenuHeading>Show columns</DropdownMenuHeading>
        <DropdownMenuBody>
          {filteredColumns.map(column => (
            <Checkbox
              label={column.Header || ''}
              key={`show-hide|${column.id}`}
              {...column.getToggleHiddenProps()}
            />
          ))}
        </DropdownMenuBody>
      </DropdownMenu>
    </Dropdown>
  );
};

ShowColumns.propTypes = {
  allColumns: PropTypes.arrayOf(PropTypes.shape({})),
  toggleColumns: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
};

ShowColumns.defaultProps = {
  allColumns: [],
  toggleColumns: undefined,
};

export default ShowColumns;
