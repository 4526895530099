import axios from 'axios';

const apiBaseUrl = process.env.APP_API_HOST_NAME;

class SpecsService {
  getFieldsets(accessToken) {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    };
    return axios.get(`${apiBaseUrl}/app/fieldsets`, config);
  }

  getSpecs(accessToken, filters) {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    };
    return axios.post(
      `${apiBaseUrl}/app/specifications/search`,
      filters || {
        offset: 0,
        limit: 500,
        companyId: '',
        isPublic: false,
      },
      config,
    );
  }

  getSpec(accessToken, name) {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    };
    return axios.get(`${apiBaseUrl}/app/specifications/${name}`, config);
  }

  pushSpec(accessToken, body) {
    return axios.post(`${apiBaseUrl}/app/specifications`, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    });
  }
}

export default new SpecsService();
