// put null values to end
export const compareSortByProp = propName => (a, b) => {
  if (a[propName] === b[propName]) return 0;
  if (a[propName] === null) return 1;
  if (b[propName] === null) return -1;
  if (typeof a[propName] === 'string') return 1;
  if (typeof b[propName] === 'string') return -1;
  if (a[propName] < b[propName]) return -1;
  return 1;
};

export const arrayEquals = (a1, a2) =>
  a1.length === a2.length && a1.every((o, i) => o === a2[i]);
