const AUTH_CONFIG = {
  domain: process.env.OAUTH_DOMAIN,
  clientId: process.env.OAUTH_CLIENT_ID,
  callbackUrl: process.env.OAUTH_CALLBACK_URL,
  dbConnectionName: process.env.OAUTH_CONNECTION,
  audience: process.env.OAUTH_AUDIENCE,
  returnTo: process.env.OAUTH_RETURNTO,
};

export { AUTH_CONFIG as default };
