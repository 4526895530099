import React from 'react';
import PropTypes from 'prop-types';

const countTotal = (array = null) => {
  return array ? array.reduce((a, b) => a + b, 0) : 'N/A';
};

const SummaryList = ({ title, data }) => {
  return (
    <div className="task__analytics__summary-list-wrapper">
      <h3 className="task__analytics__summary-list-title">{title}</h3>
      <ul className="task__analytics__summary-list">
        {Object.keys(data).map((item, index) => (
          <li className="task__analytics__summary-list-item" key={index}>
            <b>{countTotal(data[item])}</b> {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

const Summary = ({ children, title }) => {
  return (
    <div className="task__analytics__summary">
      <h2 className="task__analytics__summary-title">{title}</h2>
      <hr className="task__analytics__summary-hr" />
      {children}
    </div>
  );
};

Summary.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

SummaryList.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

Summary.List = SummaryList;

export default Summary;
