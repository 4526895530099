import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { fetchCompanies } from '../../../../reducers/docs';
import { debounce } from '../../../../common/helpers';

import Select, { DocumentOption } from '../../../../library/atoms/Select';
import Input from '../../../../components/UI/Input/Input';
import {
  ReviewerFilter,
  ReviewStateFilter,
  TaskStateFilter,
} from './StateFilter';
import TagsFilter from './TagsFilter';
import './_tasksFilters.scss';

export const InitialFilters = {
  state: ['created', 'in_progress'],
  specification: undefined,
  reviewerId: undefined,
  reviewState: ['not_started', 'started'],
  companyId: undefined,
  docId: undefined,
  tags: undefined,
};

const mapStateToProps = state => {
  const { auth, docs, specs, tasks } = state;
  return {
    companies: docs.companies.data,
    reviewers: tasks?.data?.users,
    specs: specs?.data?.specifications, // dispatched from TasksMenu
    user: auth?.user?.data,
    canSuperUserAny: auth.permissions.canSuperUserAny,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch: {
    fetchCompanies: () => dispatch(fetchCompanies()),
  },
});

const TasksFilters = ({
  filters: initialFilters,
  onFilterChange,
  // redux
  companies,
  reviewers,
  specs,
  user,
  dispatch,
  canSuperUserAny,
}) => {
  const [filters, setFilters] = useState(initialFilters);
  const [reviewerIdActive, setReviewerIdActive] = useState(true);
  const [reviewStateActive, setReviewStateActive] = useState(true);

  useEffect(() => {
    if (canSuperUserAny && !companies) dispatch.fetchCompanies();
  }, [canSuperUserAny, companies, dispatch]);

  useEffect(() => {
    setFilters(initialFilters);
  }, [setFilters, initialFilters]);

  useEffect(() => {
    onFilterChange(filters);
  }, [onFilterChange, filters]);

  const handleStateChange = option => {
    if (option?.value?.length === 1 && option.value.includes('created')) {
      setFilters({
        ...filters,
        reviewerId: [],
        reviewState: [],
        state: option.value,
      });

      setReviewerIdActive(false);
      setReviewStateActive(false);
    } else {
      setFilters({
        ...filters,
        state: option?.value || undefined,
      });
      setReviewerIdActive(true);
    }
  };

  const handleReviewerIdChange = option => {
    // if reviewerId === undefined, set reviewerState === undefined
    if (option.value === undefined) {
      setFilters({
        ...filters,
        reviewerId: undefined,
        reviewState: undefined,
      });

      setReviewStateActive(false);
    } else {
      setFilters({
        ...filters,
        reviewerId: option.value,
      });

      setReviewStateActive(true);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFilterChange = useCallback(
    debounce((key, option) => {
      if (key === 'reviewerId') {
        handleReviewerIdChange(option);
      } else if (key === 'state') {
        handleStateChange(option);
      } else {
        setFilters({
          ...filters,
          [key]: option?.value || undefined,
        });
      }
    }, 1000),
    [filters],
  );

  const companyOptions = useMemo(
    () =>
      (companies || [])
        .map(c => ({
          label: c.name,
          value: c.id,
        }))
        .sort((a, b) =>
          a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1,
        ),
    [companies],
  );

  const taskTypeOptions = useMemo(
    () =>
      (specs || [])
        .map(s => ({
          label: s.fieldset_version.description,
          value: s.id,
        }))
        .sort((a, b) =>
          a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1,
        ),
    [specs],
  );

  const reviewerOptions = useMemo(() => {
    if (!user) return [];
    return [
      {
        label: `${user.firstName} ${user.lastName} (Me)`,
        value: user.id,
      },
      ...(reviewers || [])
        .filter(u => u.id !== user.id)
        .map(v => ({
          label: !v.lastName ? v.firstName : `${v.firstName} ${v.lastName}`,
          value: v.id,
        }))
        .sort((a, b) =>
          a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1,
        ),
    ];
  }, [user, reviewers]);

  return (
    <div className="tasks-filters">
      <Select
        className="tasks__filters-item"
        placeholder="Task"
        options={taskTypeOptions || []}
        value={taskTypeOptions.find(o => o.value === filters.specification)}
        formatOptionLabel={({ label, value }) => (
          <DocumentOption name={label} id={value} />
        )}
        onChange={option => handleFilterChange('specification', option)}
      />
      <TaskStateFilter
        id="tasks_filters_taskstate"
        className="tasks-filters__select"
        value={filters.state}
        onChange={option => handleFilterChange('state', option)}
      />
      <ReviewerFilter
        id="tasks_filters_reviewer"
        options={reviewerOptions}
        value={filters.reviewerId}
        onChange={option => handleFilterChange('reviewerId', option)}
        active={reviewerIdActive}
      />
      <ReviewStateFilter
        id="tasks_filters_reviewstate"
        value={filters.reviewState}
        onChange={option => handleFilterChange('reviewState', option)}
        active={reviewStateActive}
      />
      <Input
        className="tasks-filters__select"
        placeholder="Doc Id"
        defaultValue={filters.docId}
        onChange={e => handleFilterChange('docId', e.target)}
      />
      <TagsFilter
        id="tasks_filters_tags"
        className="tasks-filters__select"
        companyId={user.companyId}
        value={filters.tags}
        onChange={newTags => handleFilterChange('tags', newTags)}
      />
      <Select
        className="tasks__filters-item"
        placeholder="Company"
        options={companyOptions}
        value={companyOptions.find(o => o.value === filters.companyId)}
        onChange={o => handleFilterChange('companyId', o)}
      />
    </div>
  );
};

TasksFilters.propTypes = {
  filters: PropTypes.shape({
    specification: PropTypes.string,
    state: PropTypes.arrayOf(PropTypes.string),
    reviewerId: PropTypes.arrayOf(PropTypes.shape({})),
    reviewState: PropTypes.arrayOf(PropTypes.string),
    companyId: PropTypes.string,
    tags: PropTypes.string,
    docId: PropTypes.string,
  }),
  dispatch: PropTypes.shape({
    fetchCompanies: PropTypes.func,
  }),
  companies: PropTypes.arrayOf(PropTypes.shape({})),
  reviewers: PropTypes.arrayOf(PropTypes.shape({})),
  specs: PropTypes.arrayOf(PropTypes.shape({})),
  user: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  onFilterChange: PropTypes.func,
  canSuperUserAny: PropTypes.bool.isRequired,
};

TasksFilters.defaultProps = {
  filters: {},
  companies: null,
  reviewers: null,
  specs: null,
  user: null,
  onFilterChange: () => {},
  dispatch: {
    fetchCompanies: () => {},
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksFilters);
