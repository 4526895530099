import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import Form, { TextInput, useForm, validatorType } from '../../Form';

import { Button } from '../../../library/atoms/Buttons';
import Card, { CardContent } from '../../UI/Card';
import PasswordFormCheck from '../../User/PasswordFormCheck';
import hubspotTracker from '../../../common/helpers/hubspotTracker';

import '../../Form/components/TextInput/TextInput.scss';

const getInitialState = user => {
  const initialState = {
    firstName: {
      value: user ? user.f : '',
      required: true,
      isValid: true,
    },
    lastName: {
      value: user ? user.l : '',
      required: true,
      isValid: true,
    },
    email: {
      value: user ? user.e : '',
      required: true,
      isValid: true,
    },
    password: {
      required: true,
      validators: [validatorType.Password],
    },
    verifyPassword: {
      required: true,
      validators: [validatorType.Match],
      match: 'password',
    },
  };

  return initialState;
};

const RegisterInviteForm = ({ handleSubmit, user }) => {
  hubspotTracker('/register/user');

  const [form] = useForm(getInitialState(user));
  const { fields, errors } = form;

  const [termsAccepted, updateTerms] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmitForm = e => {
    e.preventDefault();

    const userData = {
      companyId: user.c,
      email: user.e,
      firstName: fields.firstName.value,
      id: user.i,
      lastName: fields.lastName.value,
      password: fields.password.value,
    };

    if (Object.keys(errors).length > 0 || !form.isValid) {
      setErrorMessage({
        message: 'Please fill-up required and invalid fields.',
      });
    } else {
      setErrorMessage(null);
      handleSubmit(userData);
    }
  };

  const handleTermsChange = () => {
    updateTerms(!termsAccepted);
  };

  return (
    <>
      {user && (
        <Card className="register-form">
          <CardContent>
            <Form onSubmit={handleSubmitForm}>
              <TextInput
                label="First Name"
                name="firstName"
                value={fields.firstName.value}
                errors={errors}
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                required
              />

              <TextInput
                label="Last Name"
                name="lastName"
                value={fields.lastName.value}
                errors={errors}
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                required
              />

              <TextInput
                type="email"
                label="Email"
                name="email"
                value={fields.email.value || user.e}
                errors={errors}
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                disabled
                required
              />

              <TextInput
                type="password"
                label="Password"
                name="password"
                value={fields.password.value}
                errors={errors}
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                required
              />

              <TextInput
                type="password"
                label="Verify Password"
                name="verifyPassword"
                value={fields.verifyPassword.value}
                errors={errors}
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                required
              />

              <PasswordFormCheck password={fields.password.value} />

              <div className="form-check">
                <input
                  id="termsAccepted"
                  type="checkbox"
                  className="form-check-input"
                  value={termsAccepted}
                  name="termsAccepted"
                  onChange={handleTermsChange}
                />
                <label className="sub" htmlFor="termsAccepted">
                  I have read and accept the{' '}
                  <a
                    target="_blank"
                    href="https://www.sypht.com/terms"
                    rel="noreferrer"
                  >
                    terms & conditions
                  </a>{' '}
                  and
                  <a
                    target="_blank"
                    href="https://www.sypht.com/privacy-policy"
                    rel="noreferrer"
                  >
                    {' '}
                    privacy policy.
                  </a>
                </label>
              </div>

              {errorMessage && (
                <span className="register-form__error">
                  {errorMessage.message}
                </span>
              )}

              <Button type="submit" disabled={!termsAccepted}>
                Create an account
              </Button>
            </Form>
          </CardContent>
        </Card>
      )}
    </>
  );
};

RegisterInviteForm.propTypes = {
  user: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func,
};

RegisterInviteForm.defaultProps = {
  handleSubmit: () => {},
};

export default RegisterInviteForm;
