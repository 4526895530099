export const addRowColSvg =
  '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  '  <circle cx="8" cy="8" r="8" fill="#00B6EC"/>\n' +
  '  <path d="M11 7.25H8.75V5C8.75 4.72391 8.52609 4.5 8.25 4.5H7.75C7.47391 4.5 7.25 4.72391 7.25 5V7.25H5C4.72391 7.25 4.5 7.47391 4.5 7.75V8.25C4.5 8.52609 4.72391 8.75 5 8.75H7.25V11C7.25 11.2761 7.47391 11.5 7.75 11.5H8.25C8.52609 11.5 8.75 11.2761 8.75 11V8.75H11C11.2761 8.75 11.5 8.52609 11.5 8.25V7.75C11.5 7.47391 11.2761 7.25 11 7.25Z" fill="white"/>\n' +
  '</svg>';

export const moveSvg =
  '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  '  <path d="M11.0056 13.3048L8.5307 15.7796C8.23779 16.0725 7.76295 16.0725 7.47004 15.7796L4.99517 13.3048C4.5227 12.8323 4.85732 12.0244 5.52551 12.0244H7.12432L7.12429 8.87429H3.97417V10.4731C3.97417 11.1413 3.16632 11.4759 2.69382 11.0034L0.218947 8.52857C-0.073959 8.23567 -0.073959 7.76079 0.218947 7.46792L2.69382 4.99304C3.16629 4.52057 3.97417 4.8552 3.97417 5.52338V7.12429H7.12429V3.97417H5.52335C4.85517 3.97417 4.52054 3.16632 4.99301 2.69382L7.46788 0.218947C7.76079 -0.073959 8.23564 -0.073959 8.52854 0.218947L11.0034 2.69382C11.4759 3.16629 11.1413 3.97417 10.4731 3.97417H8.87426V7.12429H12.0244V5.52548C12.0244 4.85729 12.8322 4.52267 13.3047 4.99513L15.7796 7.47001C16.0725 7.76292 16.0725 8.23779 15.7796 8.53067L13.3047 11.0055C12.8322 11.478 12.0244 11.1434 12.0244 10.4752V8.87429H8.87429V12.0244H10.4752C11.1434 12.0244 11.478 12.8323 11.0056 13.3048Z" fill="#00B6EC"/>\n' +
  '</svg>';

export const resizeSvg =
  '<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  '  <path d="M10 8V10H8L10 8Z" fill="#00B6EC"/>\n' +
  '  <path d="M10 6L6 10H4L10 4V6Z" fill="#00B6EC"/>\n' +
  '  <path d="M2 10L10 2V0L0 10H2Z" fill="#00B6EC"/>\n' +
  '</svg>';

export const tableLockedSvg =
  '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  '  <path d="M13.5 7H12.75V4.75C12.75 2.13125 10.6187 0 8 0C5.38125 0 3.25 2.13125 3.25 4.75V7H2.5C1.67188 7 1 7.67188 1 8.5V14.5C1 15.3281 1.67188 16 2.5 16H13.5C14.3281 16 15 15.3281 15 14.5V8.5C15 7.67188 14.3281 7 13.5 7ZM10.25 7H5.75V4.75C5.75 3.50938 6.75938 2.5 8 2.5C9.24063 2.5 10.25 3.50938 10.25 4.75V7Z" fill="#00B6EC"/>\n' +
  '</svg>';

export const tableUnlockedSvg =
  '<svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  '  <path d="M13.2344 -0.000945796C10.6094 0.0084292 8.5 2.17093 8.5 4.79593V6.99905H1.5C0.671875 6.99905 0 7.67093 0 8.49905V14.4991C0 15.3272 0.671875 15.9991 1.5 15.9991H12.5C13.3281 15.9991 14 15.3272 14 14.4991V8.49905C14 7.67093 13.3281 6.99905 12.5 6.99905H11V4.77718C11 3.53968 11.9906 2.51155 13.2281 2.49905C14.4781 2.48655 15.5 3.50218 15.5 4.74905V7.24905C15.5 7.66468 15.8344 7.99905 16.25 7.99905H17.25C17.6656 7.99905 18 7.66468 18 7.24905V4.74905C18 2.12405 15.8594 -0.0103208 13.2344 -0.000945796Z" fill="#00B6EC"/>\n' +
  '</svg>';

export const removeRowColSvg =
  '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  '  <circle cx="8" cy="8" r="8" fill="#00B6EC"/>\n' +
  '  <path d="M11.0002 7.25H5.00021C4.72412 7.25 4.50021 7.47391 4.50021 7.75V8.25C4.50021 8.52609 4.72412 8.75 5.00021 8.75H11.0002C11.2763 8.75 11.5002 8.52609 11.5002 8.25V7.75C11.5002 7.47391 11.2763 7.25 11.0002 7.25Z" fill="white"/>\n' +
  '</svg>';

export const checkboxSvg =
  '<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  '  <rect x="1" y="1" width="16" height="16" rx="1" fill="white" stroke="#00B6EC" stroke-width="2"/>\n' +
  '  <path d="M7.23609 12.963L3.66108 9.36741C3.44631 9.1514 3.44631 8.80115 3.66108 8.58512L4.43888 7.80282C4.65366 7.58679 5.00193 7.58679 5.21671 7.80282L7.625 10.2249L12.7833 5.03701C12.9981 4.821 13.3463 4.821 13.5611 5.03701L14.3389 5.8193C14.5537 6.03532 14.5537 6.38556 14.3389 6.6016L8.01391 12.963C7.79911 13.179 7.45087 13.179 7.23609 12.963Z" fill="#00B6EC"/>\n' +
  '</svg>';
