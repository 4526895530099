/* eslint-disable jsx-a11y/no-noninteractive-element-interactions,react/jsx-wrap-multilines */
import React, { Fragment, useEffect, useMemo } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import objectHash from 'object-hash';

import history from '../../../../../../services/historyService';
import { ConfirmModal, showModal } from '../../../../../UI/ModalV2';

import { rulesLookup } from '../../../../store/rules/lookups';
import {
  retrieveProductFields,
  deleteRule,
} from '../../../../store/rules/actions';
import './_listView.scss';

const getCreateUrl = productId => {
  const query = productId ? `?product=${encodeURIComponent(productId)}` : '';
  return `/validate/rules/create${query}`;
};

const renderFields = (fields, productFields) => {
  if (!fields || !productFields) return null;

  const selectedFields = fields.map(f => {
    return (productFields[f] || {}).name;
  });

  return selectedFields.join(', ');
};

const renderRuleLabel = rule => {
  if (!rule) return null;
  const r = rulesLookup[rule.type];
  // prettier-ignore
  return r && r.label ? r.label : (
    <span className="list-view__invalid-rule">
      <strong>Invalid rule:</strong>
      <br />
      {rule.type}
    </span>
  );
};

const renderRuleText = rule => {
  if (!rule) return null;
  const r = rulesLookup[rule.type] || {};
  let description = r.description || '';

  Object.entries(rule).forEach(([k, v]) => {
    let value = v;
    if (description.includes('%') && Number(v) && v > 0 && v <= 1) {
      value = Math.round(v * 100);
    }
    description = description.replaceAll(`{${k}}`, value);
  });

  return description || null;
};

// STATE
const mapStateToProps = (state, props) => {
  const { validateRules } = state;
  const { fields } = validateRules;
  const { productId } = props;

  return {
    productFields: fields ? fields[productId] : null,
  };
};

// DISPATCH
const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      retrieveProductFields: productId =>
        dispatch(retrieveProductFields(productId)),
      deleteRule: (ruleId, ruleType) => dispatch(deleteRule(ruleId, ruleType)),
    },
  };
};

const ListView = ({ productId, productFields, rules, dispatch }) => {
  if (!productFields && !productId) return null;

  useEffect(() => {
    if (!productFields && productId) {
      dispatch.retrieveProductFields(productId);
    }
  }, [productId]);

  const rulesArr = useMemo(() => {
    if (!rules) return [];
    const rulesIdx = [];
    const rulesItems = [];

    (rules.conditions || []).forEach(c => {
      const { field, ...rule } = c;
      const hash = objectHash({ productId, ...rule });

      // create entry if not exists
      if (!rulesIdx.includes(hash)) {
        rulesIdx.push(hash);
        rulesItems.push({
          id: rules.id,
          hash,
          fields: [field],
          rule,
        });
        return;
      }

      // update fields list only
      const itemIdx = rulesItems.findIndex(r => r.hash === hash);
      const item = rulesItems[itemIdx];
      rulesItems.splice(itemIdx, 1, {
        ...item,
        fields: [...item.fields, field],
      });
    });

    return rulesItems;
  }, [productId, rules]);

  const handleRowClick = (e, rowIdx) => {
    const row = rulesArr[rowIdx];
    const state = {
      id: row.id,
      conditions: row.fields.map(f => ({
        field: f,
        ...row.rule,
      })),
    };

    history.push(`/validate/rules/${productId}`, state);
  };

  const handleDeleteClick = (e, { row, ruleObj, ruleName, ruleFields }) => {
    e.stopPropagation();
    showModal(
      <ConfirmModal
        className="list-view__modal"
        heading={`Delete row ${row}?`}
        confirmText="Delete"
        onConfirmClick={() => {
          dispatch.deleteRule(ruleObj.id, ruleObj.rule);
        }}
      >
        Are you sure you want to delete the rule on{' '}
        <strong>
          Row
          {row}
        </strong>
        ?
        <br />
        <table className="list-view__table">
          <thead>
            <tr>
              <th>Rule</th>
              <th>Fields</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{ruleName}</td>
              <td>{ruleFields}</td>
            </tr>
          </tbody>
        </table>
      </ConfirmModal>,
    );
  };

  return (
    <div className="list-view">
      <table className="list-view__table">
        <thead>
          <tr>
            <th>Row</th>
            <th>Fields</th>
            <th>Field names</th>
            <th>Rule</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {(rulesArr || []).map((r, i) => {
            const rowId = `rule_${r.hash}`;
            const ruleName = renderRuleLabel(r.rule);
            const ruleFields = renderFields(r.fields, productFields);
            const ruleText = renderRuleText(r.rule);
            return (
              <tr
                className="list-view__row"
                key={rowId}
                onClick={e => handleRowClick(e, i)}
              >
                <td>
                  <span className="list-view__row-no">{i + 1}</span>
                </td>
                <td>
                  {r.fields && (
                    <div className="list-view__num-fields">
                      {r.fields.length} field
                      {r.fields.length > 1 ? 's' : ''}
                    </div>
                  )}
                </td>
                <td>
                  <div className="list-view__field-names">{ruleFields}</div>
                </td>
                <td>
                  <div className="list-view__sub-heading">{ruleName}</div>
                  {ruleText && (
                    <div className="list-view__rule-text">{ruleText}</div>
                  )}
                </td>
                <td>
                  <button
                    type="button"
                    className="list-view__delete-btn"
                    onClick={e =>
                      handleDeleteClick(e, {
                        row: i + 1,
                        ruleObj: r,
                        ruleName,
                        ruleFields,
                      })
                    }
                  >
                    <FaTrashAlt className="list-view__delete-btn__icon" />
                  </button>
                </td>
              </tr>
            );
          })}
          <tr>
            <td className="list-view__empty" colSpan={5}>
              + <NavLink to={getCreateUrl(productId)}>Add Rule</NavLink>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

ListView.propTypes = {
  productId: PropTypes.string,
  rules: PropTypes.any,
  productFields: PropTypes.object,
  dispatch: PropTypes.object,
};

ListView.defaultProps = {
  productId: null,
  rules: null,
  productFields: null,
  dispatch: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
