import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FaInfoCircle } from 'react-icons/fa';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import './UnlimitedField.scss';

const UnlimitedField = ({
  id,
  className,
  label,
  forwardRef,
  form,
  name,
  value,
  disabled,
  errors,
  tip,
}) => {
  const fieldClass = classNames('unlimited', className, {
    'unlimited-disabled': disabled,
    'form__group--error': !!errors[name],
  });
  const errorText = errors[name] ? errors[name][errors[name].length - 1] : null;
  const fieldId = id || `${name}_${value}`;
  const handleToggle = e => {
    form.handleChange({
      target: {
        value: e.target.checked ? -1 : '',
        name,
        type: 'text',
      },
    });
    if (e.target.checked) {
      // eslint-disable-next-line no-param-reassign
      delete errors[name]; // if checked value is valid
    }
  };

  return (
    <div id={fieldId} className={fieldClass}>
      <div className="form__input-label">
        {label}
        {tip && (
          <>
            &nbsp;
            <FaInfoCircle
              data-tooltip-id="unlimited-field-tooltip"
              data-tooltip-html={tip}
              data-tooltip-place="top"
            />
          </>
        )}{' '}
        {errorText ? (
          <span className="form__input-error">{errorText}</span>
        ) : null}
      </div>
      <div className="form__input-group">
        <label className="toggle" htmlFor={`${name}_toggle`}>
          <input
            id={`${name}_toggle`}
            className="toggle__input"
            name={name}
            type="checkbox"
            checked={value === -1}
            onChange={handleToggle}
          />
          <span className="toggle__box" />
          <span className="toggle__label">
            {value && value === -1 ? 'Unlimited' : 'Limited'}
          </span>
        </label>
        <input
          className="form__input"
          type="text"
          name={name}
          placeholder={value !== -1 ? 'E.g. 1' : 'N/A'}
          value={value && value !== -1 ? value : ''}
          disabled={value === -1}
          onBlur={form.handleBlur}
          onChange={form.handleChange}
          ref={forwardRef}
        />
      </div>
      <ReactTooltip id="unlimited-field-tooltip" className="ReactTooltip" />
    </div>
  );
};

UnlimitedField.propTypes = {
  id: PropTypes.string.isRequired,
  tip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,
  errors: PropTypes.shape({}).isRequired,
  forwardRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  form: PropTypes.shape({
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
  }).isRequired,
};

UnlimitedField.defaultProps = {
  tip: null,
  className: null,
  forwardRef: undefined,
  disabled: false,
};

export default UnlimitedField;
