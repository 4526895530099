import React, { useRef, useEffect, useContext, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useContextAnimation from './hooks/useContextAnimation';
import { AlertBannerContext } from '../AlertBanner/AlertBannerContext';
import './ContextBar.scss';

const ContextBar = ({ children, className }) => {
  const contextRef = useRef(null);
  const enterAnimation = useContextAnimation(contextRef);
  const [alertBanner] = useContext(AlertBannerContext);

  const contextBarClass = classNames(className, 'context-bar', enterAnimation, {
    'alert-banner--exists': alertBanner.showAlert === true,
  });

  return (
    <div className={contextBarClass} ref={contextRef}>
      {children}
    </div>
  );
};

ContextBar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

ContextBar.defaultProps = {
  children: null,
  className: null,
};

export default ContextBar;
