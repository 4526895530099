/* eslint-disable no-unused-vars */
import React, { useEffect, useState, Fragment } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {
  FaRegImage,
  FaInfinity,
  FaExclamationCircle,
  FaCheckCircle,
} from 'react-icons/fa';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import Page, {
  PageBody,
  PageHeader,
  PageInner,
  PageNav,
  PageRow,
  PageLoading,
} from '../../../Page';
import Tile from '../../../UI/Tile';
import Dropdown from '../../../UI/DropdownV2';
import SearchBox from '../SearchBox/SearchBox';
import { Button } from '../../../../library/atoms/Buttons';
import FreeTrialModal from '../FreeTrialModal/FreeTrialModal';
import CheckoutModal from '../CheckoutModal/CheckoutModal';
import Badge from '../../../UI/Badge/Badge';

import {
  fetchProducts,
  refreshProducts,
} from '../../../../reducers/products/actions';
import {
  subscribeTrial,
  subscribePaid,
  upgradeTrial,
  savePaymentMethod,
  clearPaymentResult,
  fetchSubscriptions,
} from '../../../../reducers/subscriptions/actions';
import { trackEvent } from '../../../../reducers/tracking/actions';
import * as Events from '../../tracking/productTracking';
import './ProductList.scss';

const DEFAULT_PAGE_LIMIT = 100;

const SORT_OPTIONS = [
  { value: 'recommended', label: 'Recommended' },
  { value: 'name_asc', label: 'Name - A to Z' },
  { value: 'name_desc', label: 'Name - Z to A' },
  { value: 'published_asc', label: 'Published - Newest first' },
  { value: 'published_desc', label: 'Published - Oldest first' },
  // placeholder: for future sort options
  // { value: 'top_rated', label: 'Top rated' },
  // { value: 'trending', label: 'Trending' },
  // { value: 'top_selling', label: 'Top selling' },
];

const PRODUCT_TYPE_OPTIONS = [
  { value: '', label: 'All' },
  { value: 'capture', label: 'Capture' },
  { value: 'signals', label: 'Signals' },
  { value: 'premium', label: 'Premium' },
];

const filterProductType = (products, productType) => {
  if (!productType) {
    return products;
  }
  return products.filter(p => (p.tags || []).includes(productType));
};

const sortProducts = (products, sortBy) => {
  switch ((sortBy || {}).value) {
    case 'name_asc':
      return products.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
      );

    case 'name_desc':
      return products.sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1,
      );

    case 'published_asc':
      return products.sort((a, b) => (a.created < b.created ? 1 : -1));

    case 'published_desc':
      return products.sort((a, b) => (a.created > b.created ? 1 : -1));

    default:
      return products.sort((a, b) =>
        a.defaultSortIdx > b.defaultSortIdx ? 1 : -1,
      );
  }
};

const mapStateToProps = state => {
  const { auth, products, subscriptions } = state;
  return {
    products: products.data || [],
    subscriptions: subscriptions.data || null,
    user: auth.user && auth.user.data ? auth.user.data : null,
    company: auth.user && auth.user.data ? auth.user.data.company : null,
    paymentResult: subscriptions.paymentResult,
    query: products.query || '',
    isLoading: products.loading,
    isSending: subscriptions.sending || false,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      fetchProducts: (filters, isPublic) =>
        dispatch(fetchProducts(filters, isPublic)),
      refreshProducts: filters => dispatch(refreshProducts()),
      subscribeTrial: productId => dispatch(subscribeTrial(productId)),
      subscribePaid: (productId, paymentMethodId) =>
        dispatch(subscribePaid(productId, paymentMethodId)),
      fetchSubscriptions: () => dispatch(fetchSubscriptions()),
      savePaymentMethod: paymentMethod =>
        dispatch(savePaymentMethod(paymentMethod)),
      upgradeTrial: (productId, subscriptionId, paymentMethodId) =>
        dispatch(upgradeTrial(productId, subscriptionId, paymentMethodId)),
      clearPaymentResult: () => dispatch(clearPaymentResult()),
      trackEvent: ({ name, params }) => dispatch(trackEvent({ name, params })),
    },
  };
};

const ProductList = ({
  products,
  subscriptions,
  user,
  company,
  paymentResult,
  query,
  isLoading,
  isSending,
  dispatch,
  isPublic,
}) => {
  // note: sample filter
  const [productType, setProductType] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [isFreeTrialModalOpen, setFreeTrialModalOpen] = useState(false);
  const [isCheckoutModalOpen, setCheckoutModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isExisting, setExisting] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [freeTrialStep, setFreeTrialStep] = useState(1);

  const isUserVerified = !(user && user.auth0 && !user.auth0.email_verified);

  const filteredProducts = filterProductType(products, productType);

  const sortedProducts = sortProducts(filteredProducts, sortBy);

  // get products on load
  useEffect(() => {
    dispatch.fetchProducts(
      {
        limit: DEFAULT_PAGE_LIMIT,
        offset: 0,
      },
      true, // <-- requirement is to only get public products here, but allow the component to render differently depending on the value of isPublic
    );
    return () => {
      dispatch.refreshProducts();
    };
  }, []);

  useEffect(() => {
    if (user) {
      dispatch.fetchSubscriptions();
    }
  }, [user]);

  useEffect(() => {
    if (selectedProduct) {
      const subscription = subscriptions
        ? subscriptions.find(s => s.productId === selectedProduct.productId)
        : null;
      const hasSubscription = !!subscription;
      setExisting(hasSubscription);
      setSelectedSubscription(subscription);
    }
  }, [selectedProduct]);

  useEffect(() => {
    dispatch.trackEvent(Events.viewProducts(products, productType, query));
  }, [products, query, productType]);

  const handleSubscribeTrial = () => {
    dispatch.trackEvent(Events.confirmActivateFreeTrial(selectedProduct));
    dispatch.subscribeTrial(selectedProduct.productId).then(() => {
      setFreeTrialStep(2);
    });
  };

  const handleUpgradeTrial = paymentMethodId => {
    const subscription = subscriptions.find(
      s => s.productId === selectedProduct.productId,
    );

    dispatch.upgradeTrial(
      selectedProduct.productId,
      subscription.id,
      paymentMethodId,
    );

    dispatch.trackEvent(Events.confirmCheckoutAiProduct(selectedProduct));
  };

  const openFreeTrialModal = product => {
    setSelectedProduct(product);
    setFreeTrialModalOpen(true);
    dispatch.trackEvent(Events.activateFreeTrial(product));
  };

  const cancelFreeTrialModal = () => {
    dispatch.trackEvent(Events.cancelActivateFreeTrial(selectedProduct));
    setFreeTrialModalOpen(false);
    setSelectedProduct(null);
  };

  const closeFreeTrialModal = () => {
    setFreeTrialModalOpen(false);
    setSelectedProduct(null);
  };

  const openCheckoutModal = product => {
    setSelectedProduct(product);
    setCheckoutModalOpen(true);
    dispatch.trackEvent(Events.addAiProductToCart(product)).then(() => {
      dispatch.trackEvent(Events.beginCheckoutAiProduct(product));
    });
  };

  const closeCheckoutModal = () => {
    dispatch.trackEvent(Events.cancelCheckoutAiProduct(selectedProduct));
    dispatch.clearPaymentResult();
    setCheckoutModalOpen(false);
    setSelectedProduct(null);
  };

  // option: { value: 'string', label: 'String' }
  const onSortByChange = option => {
    setSortBy(option);
    dispatch.trackEvent(Events.sortProduct(query, option.value));
  };

  const onProductTypeChange = option => {
    setProductType(option.value);
    dispatch.trackEvent(Events.filterProduct('product_type', option.value));
  };

  const getCta = product => {
    if (isPublic) {
      return <></>;
    }
    const { productId } = product;
    const subscription = subscriptions.find(s => s.productId === productId);
    const isProductUpdated =
      product.stripeMeta &&
      Array.isArray(product.stripeMeta) &&
      product.stripeMeta.length > 0;
    if (subscription) {
      const { endDate, isTrial } = subscription;
      const daysTilExpired = endDate
        ? Math.ceil(moment(endDate).diff(moment(), 'seconds') / 86400)
        : null;
      const isActive = endDate ? moment(endDate) > moment() : true;

      // Free trial active
      if (isTrial && isActive) {
        return (
          <Button disabled className="tile__cta">
            {/* eslint-disable-next-line no-nested-ternary */}
            {!daysTilExpired ? (
              <span className="subs-text subs-text--green">
                <FaInfinity />
                Unlimited Free Trial
              </span>
            ) : daysTilExpired >= 3 ? (
              <span className="subs-text subs-text--green">
                <FaCheckCircle />
                Free Trial Activated,
                <br />
                {daysTilExpired} day
                {daysTilExpired === 1 ? '' : 's'} remaining
              </span>
            ) : (
              <span className="subs-text subs-text--yellow">
                <FaExclamationCircle />
                Free Trial Activated,
                <br />
                {daysTilExpired} day
                {daysTilExpired === 1 ? '' : 's'} remaining
              </span>
            )}
          </Button>
        );
      }

      // If free trial has ended
      if (isTrial && !isActive) {
        return (
          <span className="tile__cta-tooltip" data-tooltip-id="verify-tooltip">
            <Button
              className="tile__cta"
              onClick={() => openCheckoutModal(product)}
              disabled={!isUserVerified || !isProductUpdated}
            >
              <span className="subs-text subs-text">
                Free Trial Ended,
                <br />
                Unlock Full Subscription
              </span>
            </Button>
          </span>
        );
      }

      // Has full subscription
      return (
        <Button disabled className="tile__cta">
          <span className="subs-text subs-text--green">
            <FaCheckCircle />
            Full Subscription Unlocked
          </span>
        </Button>
      );
    }
    // If product is available but not purchased
    return (
      <>
        <Button
          size="sm"
          className="tile__cta activate"
          onClick={() => openFreeTrialModal(product)}
          variant="outline"
        >
          <span className="subs-text">Activate Free Trial</span>
        </Button>

        <span className="tile__cta-tooltip" data-tooltip-id="verify-tooltip">
          <Button
            size="sm"
            className="tile__cta unlock"
            onClick={() => openCheckoutModal(product)}
            disabled={!isUserVerified || !isProductUpdated}
          >
            <span className="subs-text">Unlock Full Subscription</span>
          </Button>
        </span>
      </>
    );
  };

  const tileClickHandler = product => {
    dispatch.trackEvent(Events.selectProduct(product));
  };

  return (
    <Page
      className={classNames('marketplace', { public: isPublic })}
      title="Marketplace"
    >
      <PageInner>
        {(!isPublic && !subscriptions) || !sortedProducts ? (
          <PageLoading />
        ) : (
          <>
            <PageNav
              items={
                isPublic
                  ? [
                      { label: 'Marketplace', to: '/marketplace/catalog' },
                      { label: 'Products', to: '/marketplace/catalog' },
                    ]
                  : [
                      { label: 'Marketplace', to: '/marketplace' },
                      { label: 'Products', to: '/marketplace/products' },
                    ]
              }
            />
            <PageHeader>
              <h1 className="page__heading">Marketplace</h1>
            </PageHeader>
            <PageBody>
              <PageRow className="marketplace__context">
                <div className="marketplace__filters">
                  <div className="marketplace__filters-left">
                    <SearchBox />
                  </div>
                  <div className="marketplace__filters-right">
                    {/* <Dropdown */}
                    {/*   className="product-filter" */}
                    {/*   onChange={onProductTypeChange} */}
                    {/*   options={PRODUCT_TYPE_OPTIONS} */}
                    {/*   placeholder="Product type */}
                    {/* /> */}
                    <Dropdown
                      className="sort-filter"
                      onChange={onSortByChange}
                      options={SORT_OPTIONS}
                      placeholder="Sort by"
                    />
                  </div>
                </div>
              </PageRow>
              <PageRow>
                {isLoading ? (
                  <div className="loading-wrapper">
                    <ReactLoading
                      type="spin"
                      color="#dddddd"
                      height={128}
                      width={128}
                    />
                  </div>
                ) : (
                  <div className="tile-container">
                    {sortedProducts
                      .filter(p => p.publishOnMarketplace)
                      .map(p => (
                        <Tile className="tile__wrapper" key={p.id}>
                          <Link
                            className="tile__inner"
                            to={
                              !isPublic
                                ? `/marketplace/products/${p.productId}`
                                : `/marketplace/catalog/${p.productId}`
                            }
                            onClick={() => tileClickHandler(p)}
                          >
                            <div className="tile__header">
                              {p.tags
                                .filter(
                                  item => item === 'new' || item === 'popular',
                                )
                                .map(item => (
                                  <Badge
                                    key={`${p.id}-${item}`}
                                    text={item}
                                    color={item === 'new' ? 'red' : 'green'}
                                  />
                                ))}
                              <figure className="tile__image">
                                {p.tileImageUrl && (
                                  <img src={p.tileImageUrl} alt={p.name} />
                                )}
                                {!p.tileImageUrl && <FaRegImage />}
                              </figure>
                            </div>
                            <div className="tile__body">
                              <h2 className="tile__heading">{p.name}</h2>
                              <p className="tile__text">{p.description}</p>
                            </div>
                          </Link>
                          <div className="tile__cta-group">{getCta(p)}</div>
                        </Tile>
                      ))}
                  </div>
                )}
              </PageRow>
            </PageBody>
            {selectedProduct && !isPublic && (
              <>
                <FreeTrialModal
                  selectedProduct={selectedProduct}
                  step={freeTrialStep}
                  isLoading={isSending}
                  isOpen={isFreeTrialModalOpen}
                  onCancel={cancelFreeTrialModal}
                  onClose={closeFreeTrialModal}
                  subscribeTrial={handleSubscribeTrial}
                />
                <CheckoutModal
                  product={selectedProduct}
                  subscription={selectedSubscription}
                  isOpen={isCheckoutModalOpen}
                  onCancel={closeCheckoutModal}
                  onClose={closeCheckoutModal}
                  onUpgradeTrial={handleUpgradeTrial}
                />
              </>
            )}
          </>
        )}
      </PageInner>
    </Page>
  );
};

ProductList.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
  /* eslint-disable react/forbid-prop-types */
  company: PropTypes.object,
  paymentResult: PropTypes.any,
  subscriptions: PropTypes.array,
  user: PropTypes.object,
  /* eslint-enable react/forbid-prop-types */
  query: PropTypes.string,
  isLoading: PropTypes.bool,
  isPublic: PropTypes.bool,
  isSending: PropTypes.bool,
  dispatch: PropTypes.shape({
    fetchProducts: PropTypes.func,
    refreshProducts: PropTypes.func,
    subscribeTrial: PropTypes.func,
    subscribePaid: PropTypes.func,
    fetchSubscriptions: PropTypes.func,
    savePaymentMethod: PropTypes.func,
    upgradeTrial: PropTypes.func,
    clearPaymentResult: PropTypes.func,
    trackEvent: PropTypes.func,
  }),
};

ProductList.defaultProps = {
  products: null,
  company: null,
  paymentResult: null,
  query: null,
  subscriptions: null,
  user: null,
  isLoading: false,
  isPublic: false,
  isSending: false,
  dispatch: {
    fetchProducts: () => {},
    refreshProducts: () => {},
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
