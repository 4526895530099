import React, { Fragment } from 'react';

const MissingField = () => {
  return (
    <>
      <div className="rule-picker__col" />
      <div className="rule-picker__col rule-picker__hint">
        <h3>What is missing field?</h3>
        <p>
          This condition checks whether 1 or more of your selected fields were
          not found by Sypht in a particular document.
        </p>
        <p>
          <strong>Tip:</strong> Apply this rule for your highest priority fields
          (e.g. invoice number or total) that can't be blank, to minimise impact
          on downstream systems that rely on those fields for further
          processing.
        </p>
      </div>
    </>
  );
};

export default MissingField;
