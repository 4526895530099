import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { Menu, Billing, TaskCompany } from './components';
import './Analytics.scss';
import ErrorPage from '../ErrorPage/ErrorPage';
import Page, { PageInner } from '../Page';

const mapStateToProps = state => {
  const { router, auth } = state;

  return {
    canReadAnalytics: auth.permissions.canReadAnalytics,
    canReadTaskAnalytics: auth.permissions.canReadTaskAnalytics,
    location: router.location,
  };
};

const Page404 = () => (
  <Page className="analytics" title="Not Found">
    <PageInner>
      <ErrorPage message="Oopssss! Page not found">
        The page you are looking for might have been removed or temporarily
        unavailable.
        <br /> Return to
        <a href="/analytics">Billing Dashboard</a>
      </ErrorPage>
    </PageInner>
  </Page>
);

const Analytics = ({ location, canReadAnalytics, canReadTaskAnalytics }) => {
  const defaultPath = () => <Redirect to="/analytics/billing" />;
  const defaultTaskAnalyticsPath = () => (
    <Redirect to="/analytics/tasks/company" />
  );
  return (
    <>
      <Menu />
      <Switch location={location}>
        {canReadAnalytics && (
          <Route exact path="/analytics" component={defaultPath} />
        )}
        {canReadTaskAnalytics && (
          <Route exact path="/analytics" component={defaultTaskAnalyticsPath} />
        )}
        {canReadAnalytics && (
          <Route
            exact
            path="/analytics/billing"
            component={Billing}
            defaultTo="/analytics"
          />
        )}
        {canReadTaskAnalytics && (
          <Route
            exact
            path="/analytics/tasks/company"
            component={TaskCompany}
            defaultTo="/analytics"
          />
        )}
        <Route component={Page404} />
      </Switch>
    </>
  );
};

Analytics.propTypes = {
  canReadAnalytics: PropTypes.bool.isRequired,
  canReadTaskAnalytics: PropTypes.bool.isRequired,
  location: PropTypes.shape({}),
};

Analytics.defaultProps = {
  location: null,
};

export default connect(mapStateToProps)(Analytics);
