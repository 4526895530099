import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FaSearch } from 'react-icons/fa';
import './TagSearch.scss';

const TagSearch = ({ forwardRef, onChange }) => {
  const handleChange = e => {
    onChange(e.target.value);
  };

  useEffect(() => {
    if (forwardRef.current) {
      forwardRef.current.focus();
    }
  }, []);

  return (
    <div className="tag-search__wrapper">
      <label htmlFor="tagsearch">Tag as</label>
      <div className="tag-search__input-container">
        <input
          className="tag-search__input"
          id="tagsearch"
          type="text"
          name="query"
          onChange={e => handleChange(e)}
          ref={forwardRef}
        />
        <FaSearch color="#bbb" className="tag-search__icon" />
      </div>
    </div>
  );
};

TagSearch.propTypes = {
  forwardRef: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TagSearch;
