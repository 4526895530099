import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { TabsContext } from './TabsContext';
import './Tabs.scss';

import { EventLookup, EventParams } from '../../../common/tracking';

const fixTabSize = (tabRef, children) => {
  if (
    tabRef.current &&
    typeof children === 'string' &&
    !children.includes(' ')
  ) {
    if (tabRef.current.offsetWidth > 0) {
      // eslint-disable-next-line
      tabRef.current.style.width = `${tabRef.current.offsetWidth}px`;
      return true;
    }
  }
  return false;
};

const Tab = ({ children, className, href, tabFor, tracking, ...props }) => {
  const [tabsContext, setTabsContext] = useContext(TabsContext);
  const [isActive, setActive] = useState(false);
  const [isFixed, setFixed] = useState(false);
  const tabContainerRef = useRef(null);
  const tabRef = useRef(null);
  const targetHref = href || `#${tabFor}`;

  useEffect(() => {
    if (!isFixed) {
      setFixed(fixTabSize(tabRef, children));
    }
  }, []);

  useEffect(() => {
    if (tabsContext.active === tabFor) {
      setActive(true);
      setTabsContext({
        ...tabsContext,
        activeElem: tabRef.current,
      });
    } else {
      setActive(false);
    }
  }, [tabsContext.active]);

  const trackTabClick = () => {
    tabsContext.dispatch.trackEvent({
      name: EventLookup.select_content,
      params: {
        ...EventParams,
        page_title: document.title,
        content_type: tabFor,
        ...tracking,
      },
    });
  };

  const handleLinkClick = () => {
    trackTabClick();
    setTabsContext({
      ...tabsContext,
      active: tabFor,
      activeElem: tabRef.current,
    });
  };

  const handleTabClick = e => {
    e.preventDefault();
    trackTabClick();

    setTabsContext({
      ...tabsContext,
      active: tabFor,
      activeElem: tabRef.current,
    });

    // eslint-disable-next-line no-restricted-globals
    history.pushState(null, null, targetHref);
  };

  return (
    <li
      className={classNames(className, 'tabs__item', {
        'tabs__item--active': isActive,
      })}
      ref={tabContainerRef}
      {...props}
    >
      {href ? (
        <Link
          to={href || `#${tabFor}`}
          className="tabs__link tabs__link--navlink"
          onClick={e => handleLinkClick(e)}
        >
          <span ref={tabRef}>{children}</span>
        </Link>
      ) : (
        <a
          href={href || `#${tabFor}`}
          className="tabs__link"
          onClick={e => handleTabClick(e)}
          ref={tabRef}
        >
          {children}
        </a>
      )}
    </li>
  );
};

Tab.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string,
  tabFor: PropTypes.string.isRequired,
  tracking: PropTypes.object,
};

Tab.defaultProps = {
  children: null,
  className: null,
  href: null,
  tracking: {},
};

export default Tab;
