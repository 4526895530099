import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DateSelector.scss';

const DateSelector = ({
  className,
  selected,
  onChange,
  placeholder,
  id,
  name,
  label,
  errors,
  ...props
}) => {
  const inputId = id || name;
  return (
    <div
      className={classNames('form__group', {
        'form__group--error': !!errors[name],
      })}
    >
      {label ? (
        <label className="form__select-label" htmlFor={inputId}>
          {label}
        </label>
      ) : null}
      <DatePicker
        id={inputId}
        selected={selected}
        onChange={onChange}
        showYearDropdown
        placeholderText={placeholder || 'Date'}
        className={classNames('datepicker__input', className)}
        {...props}
      />
    </div>
  );
};

DateSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  errors: PropTypes.object,
};

DateSelector.defaultProps = {
  className: '',
  placeholder: null,
  id: null,
  errors: {},
};

export default DateSelector;
