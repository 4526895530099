/* eslint-disable no-unused-vars,react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { FaCheckCircle, FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';

import { debounce } from '../../../../common/helpers';
import {
  fetchProducts,
  refreshProducts,
} from '../../../../reducers/products/actions';

import history from '../../../../services/historyService';
import Page, { PageInner, PageNav } from '../../../Page';
import { DataTable } from '../../../UI/Table';
import Chip from '../../../UI/Chip/Chip';
import { LinkButton } from '../../../../library/atoms/Buttons';
import SearchBox from '../SearchBox/SearchBox';
import '../../Marketplace.scss';
import './Admin.scss';

const DEFAULT_SORT_IDX = Number.MAX_SAFE_INTEGER;
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_LIMIT = 200;

const PRODUCT_COLUMNS = [
  {
    Header: 'Product Name',
    accessor: 'name',
    width: 200,
  },
  {
    Header: 'Fieldsets',
    accessor: 'fieldsets',
    className: 'hide-md-down',
    headerClassName: 'hide-md-down',
    minWidth: 200,
    Cell: ({ value: fieldsets }) =>
      fieldsets.map(f => (
        <Chip color="yellow" key={`fieldsets-${f.toCamelCase()}`}>
          {f}
        </Chip>
      )),
  },
  {
    Header: 'Created',
    accessor: 'created',
    Cell: ({ original: o }) => moment(o.created).format('MMM DD, YYYY'),
    width: 128,
  },
  {
    Header: 'Updated',
    accessor: 'updated',
    width: 128,
    Cell: ({ original: o }) => moment(o.updated).format('MMM DD, YYYY'),
  },
  {
    Header: 'Sort order',
    accessor: 'defaultSortIdx',
    className: 'align--right hide-lg-down',
    headerClassName: 'hide-lg-down',
    width: 80,
    Cell: ({ value }) =>
      value !== DEFAULT_SORT_IDX ? value : <span className="">-</span>,
  },
  {
    Header: 'Published',
    accessor: 'publishOnMarketplace',
    width: 100,
    Cell: ({ value }) => {
      return value ? (
        <FaCheckCircle className="icon icon--green" />
      ) : (
        <FaMinusCircle className="icon icon--grey" />
      );
    },
  },
];

const mapStateToProps = state => {
  const { products } = state;
  return {
    products: products.data || [],
    isLoading: products.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      fetchProducts: filters => dispatch(fetchProducts(filters)),
      refreshProducts: () => dispatch(refreshProducts()),
    },
  };
};

const ProductList = ({ products, isLoading, dispatch }) => {
  // note: sample filter
  // const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [query, setQuery] = useState(null);

  // get products on load
  useEffect(() => {
    dispatch.fetchProducts({
      limit: DEFAULT_PAGE_LIMIT,
      offset: 0,
    });

    return () => {
      dispatch.refreshProducts();
    };
  }, []);

  // note: sample filter
  // option: { value: 'string', label: 'String' }
  // const onSelectedIndustryChange = option => {
  //   setSelectedIndustry(option);
  // };

  // option: { value: 'string', label: 'String' }
  const onSortByChange = option => {
    setSortBy(option);
  };

  const handleSearchChange = useCallback(
    debounce(input => {
      const { value } = input;
      dispatch.fetchProducts({
        query: value,
        limit: DEFAULT_PAGE_LIMIT,
        offset: 0,
      });
    }, 400),
    [],
  );

  return (
    <Page
      className="marketplace marketplace-list"
      title="My Listings - Marketplace Admin"
    >
      <PageInner>
        <PageNav
          items={[
            { label: 'Marketplace', to: '/marketplace' },
            { label: 'Admin', to: '/marketplace/admin' },
            { label: 'Products', to: '/marketplace/admin/products' },
          ]}
        />
        <div className="page__header">
          <h1 className="page__heading">Products</h1>
        </div>
        <div className="page__body">
          <div className="page__toolbar">
            <div className="page__toolbar-left">
              <SearchBox />
            </div>
            <div className="page__toolbar-right">
              <LinkButton href="/marketplace/admin/products/create">
                <span>Create</span> <FaPlusCircle />
              </LinkButton>
            </div>
          </div>
          <div className="page__row">
            <DataTable
              className="products-table"
              data={products}
              columns={PRODUCT_COLUMNS}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              pageSizeOptions={[20, 50, 100]}
              sortBy="defaultSortIdx"
              getTrProps={(state, { original: o }) => {
                return {
                  onClick: () =>
                    history.push(
                      `/marketplace/admin/products/${o.productId}/edit`,
                    ),
                };
              }}
            />
          </div>
        </div>
      </PageInner>
    </Page>
  );
};

ProductList.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  dispatch: PropTypes.shape({
    fetchProducts: PropTypes.func,
    refreshProducts: PropTypes.func,
  }),
};

ProductList.defaultProps = {
  products: null,
  isLoading: false,
  dispatch: {
    fetchProducts: () => {},
    refreshProducts: () => {},
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
