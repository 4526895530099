import Auth0 from 'auth0-js';

class Auth {
  constructor(options) {
    const defaultOptions = {
      responseType: 'token id_token',
    };

    this.isInit = false;
    this.sessionInit = false;
    this.options = { ...defaultOptions, ...options };
    this.webAuth = new Auth0.WebAuth({ ...defaultOptions, ...options });

    this.handleAuth = this.handleAuth.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.checkSSOSession = this.checkSSOSession.bind(this);
    this.refresh = this.refresh.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
  }

  handleAuth(callback) {
    // Ensure the webAuth event is triggered once only on app load
    if (this.isInit) return;
    this.isInit = true;
    // Initiate Listener
    this.webAuth.parseHash((err, authResult) => {
      if (err) return callback(err, null);
      // If there isn't an error and there is no result ignore call
      if (authResult === null) return null;
      // make call with results
      return callback(null, authResult);
    });
  }

  login(email, password, callback) {
    this.webAuth.login(
      {
        realm: this.options.dbConnectionName,
        email,
        password,
      },
      callback,
    );
  }

  logout() {
    this.webAuth.logout({
      returnTo: this.options.returnTo,
      clientID: this.options.clientID,
    });
  }

  checkSSOSession(callback) {
    if (this.sessionInit) return;
    this.sessionInit = true;
    this.webAuth.checkSession({}, callback);
  }

  refresh(callback) {
    this.webAuth.checkSession({}, callback);
  }

  forgotPassword(email) {
    return new Promise((resolve, reject) => {
      this.webAuth.changePassword(
        {
          email,
          connection: this.options.dbConnectionName,
        },
        error => {
          if (error) {
            reject(error);
          } else {
            resolve();
          }
        },
      );
    });
  }
}
export default Auth;
