const { ANALYTICS_TRACKING_ID } = process.env;
const MAX_TIMEOUT = 5000;

export const EventParams = {
  language: window.navigator.language,
  page_location: window.location.href,
  page_path: window.location.pathname,
  page_referrer: document.referrer,
  page_title: document.title,
  screen_resolution:
    `${Math.round(window.screen.width * window.devicePixelRatio)}x` +
    `${Math.round(window.screen.height * window.devicePixelRatio)}`,
};

export const EventLookup = Object.freeze({
  // auto
  click: 'click',
  file_download: 'file_download',
  page_view: 'page_view',
  user_engagement: 'user_engagement',

  // base
  login: 'login',
  sign_up: 'sign_up',
  tutorial_begin: 'tutorial_begin',
  tutorial_complete: 'tutorial_complete',

  // conversion
  add_to_cart: 'add_to_cart',
  add_payment_info: 'add_payment_info',
  begin_checkout: 'begin_checkout',
  generate_lead: 'generate_lead',
  remove_from_cart: 'remove_from_cart',
  purchase: 'purchase', // complete purchase
  select_item: 'select_item',
  search: 'search',
  select_content: 'select_content',
  select_promotion: 'select_promotion',
  view_item: 'view_item',
  view_item_list: 'view_item_list',
  view_promotion: 'view_promotion',
  view_search_results: 'view_search_results',
});

// waits for tag manager to load, then returns client_id
export const getClientId = async () => {
  return new Promise((resolve, reject) => {
    let timeElapsed = 0;
    const setClientId = setInterval(async () => {
      if (typeof window.gtag === 'function') {
        await window.gtag(
          'get',
          ANALYTICS_TRACKING_ID,
          'client_id',
          clientId => {
            if (clientId) {
              resolve(clientId);
            } else {
              reject(new Error(`Unable to retrieve 'client_id'.`));
            }
          },
        );
      }

      if (timeElapsed > MAX_TIMEOUT) {
        clearInterval(setClientId);
      }

      timeElapsed += 500;
    }, 500);
  });
};
