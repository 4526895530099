import React from 'react';
import PropTypes from 'prop-types';

import { FaInfoCircle } from 'react-icons/fa';
import * as signal from '../../atoms/Signal/Signal';
import './field.scss';

const SignalField = ({
  name,
  tooltip,
  signalMessage,
  signalVariant,
  additionalText,
  children,
}) => {
  return (
    <div className="field2">
      <div className="field2__section">
        <h3 className="field2__header">{name}</h3>
        <FaInfoCircle
          className="field2__info-icon"
          data-tooltip-id="document-tooltip"
          data-tooltip-place="right"
          data-tooltip-html={tooltip}
        />
      </div>
      <div className="field2__section">
        {/* Allow for custom Signal to be passed in so we can set things like a
        badge within the Signal. */}
        {children || (
          <signal.Signal variant={signalVariant} message={signalMessage} />
        )}
      </div>
      {additionalText && (
        <div className="field2__section">
          <p className="field2__text">{additionalText}</p>
        </div>
      )}
    </div>
  );
};

SignalField.propTypes = {
  name: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  signalMessage: PropTypes.string,
  signalVariant: PropTypes.oneOf(['default', 'success', 'warning', 'danger']),
  additionalText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
};

SignalField.defaultProps = {
  tooltip: undefined,
  additionalText: undefined,
  children: undefined,
  signalMessage: undefined,
  signalVariant: 'default',
};

export default SignalField;
