import React from 'react';
import { STYLE } from '../../components/UI/Notification/Notification';

export const createdMessage = name => {
  return {
    style: STYLE.CONFIRMATION,
    message: `User ${name} has been created.`,
  };
};

export const updatedMessage = name => {
  return {
    style: STYLE.CONFIRMATION,
    message: `User ${name} has been updated.`,
  };
};

export const passwordChangedMessage = {
  style: STYLE.CONFIRMATION,
  message: 'Your password has been successfully changed.',
};

export const errorMessage = {
  style: STYLE.ERROR,
  message:
    'Something went wrong, please try again. If this issue persists ' +
    'please contact us at support@sypht.com.',
  timeout: 8000,
};

export const updateUserError = message => ({
  style: STYLE.ERROR,
  message: `Could not update the user.  ${
    message ? `Error: ${message}.  ` : ''
  }If this issue persists please contact us at support@sypht.com.`,
  timeout: 8000,
});

export const passwordError = {
  style: STYLE.ERROR,
  message: 'Current password provided is invalid.',
  timeout: 8000,
};

export const errorRegistration = message => {
  return {
    style: STYLE.ERROR,
    message,
    timeout: 8000,
  };
};

export const forgotPasswordSuccess = email => {
  return {
    style: STYLE.CONFIRMATION,
    message: `An email has been sent to ${email} with instructions for resetting your password`,
  };
};

export const sendInviteEmailSuccess = email => {
  return {
    style: STYLE.CONFIRMATION,
    message: `An invite email has been sent to ${email}.`,
  };
};

export const batchUpdatedMessage = noOfUsers => ({
  style: STYLE.CONFIRMATION,
  message: (
    <>
      Updated <strong>{noOfUsers}</strong> users(s).
    </>
  ),
  timeout: 8000,
});

export const updateTaskTagsMessage = (addTags, removeTags, error) => {
  let message =
    'Unable to determine what to do when adding or removing tags on selected documents/tasks';

  if (addTags.length && removeTags.length) {
    message = `${addTags.length} tags added and ${removeTags.length} tags removed from selected documents/tasks`;
  } else if (addTags.length && addTags.length === 1) {
    message = `${addTags[0]} tag added to selected documents/tasks`;
  } else if (addTags.length && addTags.length > 1) {
    message = `${addTags.length} tags added to selected documents/tasks`;
  } else if (removeTags.length && removeTags.length === 1) {
    message = `${removeTags[0]} tag removed from selected documents/tasks`;
  } else if (removeTags.length && removeTags.length > 1) {
    message = `${removeTags.length} tags removed from selected documents/tasks`;
  }

  const failureMessage =
    'An error occured while trying to add or remove tags for the selected documents/tasks';

  return {
    style: error ? STYLE.ERROR : STYLE.CONFIRMATION,
    message: error ? failureMessage : message,
  };
};
