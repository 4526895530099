import { addError, removeError } from './index';

const validateRequired = ({ elem, errors, setErrors }) => {
  let { value } = elem;
  if (!value) {
    value = elem.getAttribute('value');
  }

  let label = elem.name;
  if (!label) {
    label = elem.getAttribute('label') || 'Field';
  }
  const message = `${label} is required.`;
  if (!value) {
    addError(elem.name, message, errors, setErrors);
    return {
      isValid: false,
    };
  }

  removeError(elem.name, message, errors, setErrors);
  return {
    isValid: true,
  };
};

export default validateRequired;
