import { useEffect } from 'react';

const setAnimate = () => {
  const current = window.location.pathname.split('/')[1];
  const prev = localStorage.getItem('sypht.page.previous');
  return current === 'admin' && prev !== current;
};

const animateIn = elem => {
  if (!elem) return;
  elem.classList.add('context-bar--enter-active');
  setTimeout(() => {
    elem.classList.remove(
      ...['context-bar--enter', 'context-bar--enter-active'],
    );
  }, 500);
};

const useContextAnimation = elemRef => {
  const isAnimate = setAnimate();
  useEffect(() => {
    if (isAnimate) {
      animateIn(elemRef.current);
    }
  }, [elemRef]);

  return isAnimate ? 'context-bar--enter' : null;
};

export default useContextAnimation;
