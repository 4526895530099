/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useTable, usePagination, useSortBy, useRowSelect } from 'react-table';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { SkeletonTable } from '../../../components/Skeleton';

import { Table, Tbody, Td, Th, Thead, Tr } from './components/Table';
import { Pagination } from './components/Pagination';
import {
  isOverrideRowClick,
  isSkipCell,
  setClassName,
  setColSpan,
  setSortIcon,
} from './helpers';
import './_table.scss';

const StaticTable = ({
  className,
  columns,
  data,
  pageSize,
  isPagination,
  handlers,
}) => {
  // Init `react-table`
  const tableProps = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize,
      },
      autoResetPage: true,
      autoResetSelectedRows: true,
      disableMultiSort: true,
      handlers,
    },
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
  } = tableProps;

  useEffect(() => {
    if (!data) return;
    const selectedRows = selectedFlatRows.map(r => ({
      id: r.original.id,
      original: r.original,
      rowIndex: r.index,
    }));
    if (!handlers.onRowSelect) return;
    handlers.onRowSelect(selectedRows);
  }, [data, selectedFlatRows, handlers]);

  const getRowClickHandler = row => {
    if (!handlers.onRowClick) return {};
    return {
      onClick: e => {
        if (!isOverrideRowClick(e.target)) {
          handlers.onRowClick(e, row.original);
        }
      },
    };
  };

  return (
    <div className="table-container">
      {data ? (
        <>
          <Table className={classNames(className)} {...getTableProps()}>
            <Thead>
              {headerGroups.map(headerGroup => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(col => {
                    return (
                      // prettier-ignore
                      <Th
                        className={classNames(setClassName('table__th', col.id), {
                          'table__th--sortable': col.canSort,
                        })}
                        {...col.getHeaderProps()}
                      >
                        <div
                          className={classNames({
                            table__label: col.id !== 'checkbox',
                            table__checkbox: col.id === 'checkbox',
                          })}
                          {...col.getSortByToggleProps()}
                        >
                          {col.render('Header')}
                          {col.id !== 'checkbox' && setSortIcon(col)}
                        </div>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row);
                // prettier-ignore
                return (
                  <Tr
                    className={classNames({ 'table__tr--clickable': !!handlers.onRowClick })}
                    {...row.getRowProps()}
                    {...getRowClickHandler(row)}
                  >
                    {row.cells.map((cell, i) => {
                      if (isSkipCell(row, i)) return null;
                      return (
                        <Td
                          className={setClassName('table__td', cell.column.id)}
                          colSpan={setColSpan(row,i)}
                          {...cell.getCellProps()}
                        >
                          <div className="table__td-inner">
                            {cell.render('Cell', { editable: true })}
                          </div>
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          {isPagination && <Pagination tableProps={tableProps} />}
        </>
      ) : (
        <SkeletonTable noOfRows={10} />
      )}
    </div>
  );
};

StaticTable.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  pageSize: PropTypes.number,
  isPagination: PropTypes.bool,
  handlers: PropTypes.shape({}),
};

StaticTable.defaultProps = {
  className: null,
  columns: null,
  data: null,
  pageSize: 10,
  isPagination: true,
  handlers: {
    onRowClick: undefined,
    onRowSelect: () => {},
  },
};

export default StaticTable;
