import FieldsetService from '../../services/FieldsetService';

export const REQUEST_FIELDSETS = 'sypht/fieldsets/REQUEST_FIELDSETS';
export const RECEIVE_FIELDSETS = 'sypht/fieldsets/RECEIVE_FIELDSETS';
export const REFRESH_FIELDSETS = 'sypht/fieldsets/REFRESH_FIELDSETS';

const requestFieldsets = () => {
  return {
    type: REQUEST_FIELDSETS,
    payload: {},
  };
};

const receiveFieldsets = data => {
  return {
    type: RECEIVE_FIELDSETS,
    payload: {
      data,
      loading: true,
      receivedAt: Date.now(),
    },
  };
};

const shouldFetchFieldsets = fieldsets => {
  if (!fieldsets.data) {
    return true;
  }
  if (fieldsets.loading) {
    return false;
  }
  return fieldsets.refresh;
};

export const fetchFieldsets = () => {
  return async (dispatch, getState) => {
    const { auth, fieldsets: retrievedFieldsets } = getState();

    if (shouldFetchFieldsets(retrievedFieldsets)) {
      dispatch(requestFieldsets());
      const { accessToken } = auth;
      const response = await FieldsetService.getFieldsets(accessToken);
      dispatch(receiveFieldsets(response.data));
    }
  };
};

export const refreshFieldsets = () => {
  return {
    type: REFRESH_FIELDSETS,
    payload: {},
  };
};
