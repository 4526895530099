import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import { BillingCalculator, RateCard } from '../index';
import Checkbox from '../../../UI/Checkbox/Checkbox';
import { setPricing } from '../Product/Product';
import './CheckoutStep.scss';
import LegacyPricing from '../Product/LegacyPricing';

const CheckoutStep1 = ({ product, useTermsAgree }) => {
  const [accessFee, meteredPricing] = setPricing(product.pricing);
  const [isTermsAgree, setTermsAgree] = useTermsAgree;

  const handleTermsChange = (e, isChecked) => {
    setTermsAgree(isChecked);
  };

  return (
    <div className="checkout-step">
      <div className="checkout-step__inner">
        <h3 className="checkout-step__heading">
          <span>{product.name} AI</span>
          <span>Step 1 of 2</span>
        </h3>
        <div className="checkout-step__section">
          {product.pricing && product.pricing.tiers && (
            <>
              <RateCard
                className="rate-card rate-card--small"
                pricing={product.pricing}
                isShowFeatures={false}
              />
              <BillingCalculator pricing={product.pricing} />
            </>
          )}
          {meteredPricing && (
            <>
              <RateCard
                className="rate-card rate-card--small"
                pricing={meteredPricing}
                isShowFeatures={false}
              />
              <BillingCalculator
                accessFee={accessFee}
                pricing={meteredPricing}
              />
            </>
          )}
        </div>
        <div className="checkout-step__section">
          <h3 className="checkout-step__heading">Terms of Service</h3>
          <Checkbox
            id="checkout_terms"
            defaultChecked={isTermsAgree}
            onChange={handleTermsChange}
          >
            <p className="checkout-step__text">
              I agree to the{' '}
              <a
                href="https://www.sypht.com/terms"
                target="_blank"
                rel="noreferrer"
              >
                Sypht Terms of Service
              </a>
              . I have also read and agree to the{' '}
              <a
                href="https://www.sypht.com/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Sypht Privacy Policy
              </a>
              .
            </p>
          </Checkbox>
        </div>
      </div>
    </div>
  );
};

CheckoutStep1.propTypes = {
  product: PropTypes.object.isRequired,
  useTermsAgree: PropTypes.array,
};

CheckoutStep1.defaultProps = {
  useTermsAgree: [false, () => {}],
};

export default CheckoutStep1;
