import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { FaPlusCircle } from 'react-icons/fa';
import { fetchProducts } from '../../../../../reducers/products/actions';
import { fetchSubscriptions } from '../../../../../reducers/admin/actions';
import { PageLoading } from '../../../../Page';
import './Subscriptions.scss';
import SubscriptionMenu from './SubscriptionMenu';
import AddSubscription from './AddSubscription';
import EditSubscription from './EditSubscription';
import PricingTable from './PricingTable';

const mapStateToProps = state => {
  const { admin, auth, products } = state;
  return {
    subscriptions: admin.subscriptions.data,
    isLoading: admin.subscriptions.loading,
    user: auth.user ? auth.user.data : null,
    products: products.data,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      fetchProducts: () => dispatch(fetchProducts()),
      fetchSubscriptions: companyId => dispatch(fetchSubscriptions(companyId)),
    },
  };
};
const Subscriptions = ({
  subscriptions,
  dispatch,
  user,
  companyId,
  isLoading,
}) => {
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [showAddSubscription, setAddSubscription] = useState(false);
  const [showEditSubscription, setEditSubscription] = useState(false);

  useEffect(() => {
    if (user) {
      dispatch.fetchProducts();
      dispatch.fetchSubscriptions(companyId);
    }
  }, [user, companyId]);

  const openEditModal = subscription => {
    setSelectedSubscription(subscription);
    setEditSubscription(true);
  };

  const closeEditModal = () => {
    setEditSubscription(false);
    setSelectedSubscription(null);
  };

  return (
    <div className="subscription-list">
      <div className="subscription-list__actions">
        <button
          type="button"
          className="subscription-list__add-button"
          onClick={() => {
            setAddSubscription(true);
          }}
        >
          <span className="subscription-list__add-text">Add subscription</span>
          <FaPlusCircle />
        </button>
      </div>
      {subscriptions &&
        subscriptions.length > 0 &&
        subscriptions.map(subscription => {
          const {
            endDate,
            startDate,
            name,
            id,
            isTrial,
            freeThreshold,
            stripeSubscriptionItemIds,
            productType,
            licenseCount,
          } = subscription;

          const hasPricing = subscription?.usagePricing?.tiers?.length > 0;
          const hasExpired = endDate && moment(endDate) < moment();
          const daysLeft =
            endDate && !hasExpired
              ? Math.ceil(moment(endDate).diff(moment(), 'seconds') / 86400)
              : null;
          return (
            <div className="subscription" key={id}>
              <div className="subscription__header">
                <h3 className="subscription__heading">
                  {name}
                  <span className="subscription__heading-id">{id}</span>
                </h3>
                <SubscriptionMenu
                  id={subscription}
                  onEditClick={() => openEditModal(subscription)}
                />
              </div>
              <div className="subscription__content">
                <div className="subscription__content-item">
                  Start Date: {moment(startDate).format('D/MM/YYYY')}
                </div>
                {stripeSubscriptionItemIds && (
                  <div className="subscription__content-item">
                    Stripe Details:
                    <span className="subscription__content-item-detail">
                      {' '}
                      subscription
                      {user.company.stripeSubscriptionId}, items [
                      {stripeSubscriptionItemIds.join(', ')}]
                    </span>
                  </div>
                )}
                <div className="subscription__content-item">
                  Subscription Type: {isTrial ? 'Trial' : 'Paid'}
                </div>
                <div className="subscription__content-item">
                  Billing unit: {subscription.billingUnit}
                </div>
                {hasPricing && (
                  <div className="subscription__content-item">
                    <PricingTable tiers={subscription?.usagePricing?.tiers} />
                  </div>
                )}
                {!endDate && (
                  <div className="subscription__content-item">
                    End Date: None
                  </div>
                )}
                {hasExpired && (
                  <div className="subscription__content-item">
                    Expired: {moment(endDate).format('D/MM/YYYY')}
                  </div>
                )}
                {endDate && !hasExpired && (
                  <>
                    <div className="subscription__content-item">
                      Expiry Date: {moment(endDate).format('D/MM/YYYY')}
                    </div>

                    <div className="subscription__content-item">
                      Days Left: {daysLeft}
                    </div>
                  </>
                )}
                {productType === 'capture' && (
                  <div className="subscription__content-item">
                    Free Threshold:{' '}
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {!isTrial
                      ? 'N/A'
                      : freeThreshold === -1
                      ? 'Unlimited'
                      : freeThreshold}
                  </div>
                )}
                {productType === 'platform' && (
                  <div className="subscription__content-item">
                    License Count:{' '}
                    {licenseCount === -1 ? 'Unlimited' : licenseCount}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      {subscriptions && subscriptions.length === 0 && (
        <div className="subscription-list__none">
          Company has no subscriptions
        </div>
      )}
      {isLoading && <PageLoading />}

      {selectedSubscription && (
        <EditSubscription
          isOpen={showEditSubscription}
          onCancel={closeEditModal}
          onClose={closeEditModal}
          subscription={selectedSubscription}
          companyId={companyId}
        />
      )}
      <AddSubscription
        isOpen={showAddSubscription}
        onCancel={() => setAddSubscription(false)}
        onClose={() => setAddSubscription(false)}
        companyId={companyId}
      />
    </div>
  );
};

Subscriptions.propTypes = {
  subscriptions: PropTypes.arrayOf(PropTypes.shape({})),
  dispatch: PropTypes.shape({
    fetchProducts: PropTypes.func,
    fetchSubscriptions: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({
    company: PropTypes.shape({
      stripeSubscriptionId: PropTypes.string,
    }),
  }),
  companyId: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
};

Subscriptions.defaultProps = {
  subscriptions: [],
  user: null,
  companyId: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
