import moment from 'moment';
import {
  REQUEST_TASK_TIME_STATS,
  RECEIVE_TASK_TIME_STATS,
  RECEIVE_TASKS_USAGE_STATS,
  REQUEST_TASKS_USAGE_STATS,
} from './actions';

const initialState = {
  taskTime: {
    loading: false,
    mean: null,
  },
  tasksUsage: {
    data: null,
    loading: true,
  },
};

const taskAnalyticsReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case REQUEST_TASK_TIME_STATS:
      return { ...state, taskTime: { loading: true } };

    case RECEIVE_TASK_TIME_STATS:
      return { ...state, taskTime: { loading: false, mean: payload.mean } };

    case REQUEST_TASKS_USAGE_STATS:
      return {
        ...state,
        tasksUsage: {
          ...state.tasksUsage,
        },
      };

    case RECEIVE_TASKS_USAGE_STATS:
      const { data } = payload;
      return {
        ...state,
        tasksUsage: {
          data,
          loading: false,
        },
      };

    default:
      return state;
  }
};

export default taskAnalyticsReducer;
