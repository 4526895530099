import React from 'react';
import PropTypes from 'prop-types';
import './_captureTable.scss';

const CaptureTable = ({ children }) => {
  return (
    <div className="capture-table__container">
      <table className="capture-table" border="0" cellSpacing="0">
        {children}
      </table>
    </div>
  );
};

CaptureTable.propTypes = {
  children: PropTypes.node,
};

CaptureTable.defaultProps = {
  children: null,
};

export default CaptureTable;
