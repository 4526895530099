import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../../../../../UI/DropdownV2';

const mapSubscriptionsToProducts = subscriptions => {
  return (subscriptions || []).map(s => ({
    id: s.productId,
    value: s.productId,
    label: s.name,
  }));
};

const ProductDropdown = ({
  className,
  defaultProductId,
  subscriptions,
  ...props
}) => {
  const products = mapSubscriptionsToProducts(subscriptions).sort((a, b) =>
    a.label > b.label ? 1 : -1,
  );

  return (
    products && (
      <div className="product-dropdown">
        <Dropdown
          id="selected_product"
          label="AI Product"
          options={products}
          defaultValue={products.find(p => p.value === defaultProductId)}
          {...props}
        />
      </div>
    )
  );
};

ProductDropdown.propTypes = {
  subscriptions: PropTypes.array.isRequired,
};

export default ProductDropdown;
