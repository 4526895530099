/**
 * Transform the values and/or keys in an object.
 *
 * @param {*} obj
 * @param {*} transform
 * @returns
 */
function replaceKeyOrValue(obj, transform) {
  if (!obj) return obj;
  if (typeof obj !== 'object') return obj;
  if (obj instanceof Array) return obj;
  return Object.entries(obj).reduce((acc, [k, v]) => {
    const [newk, newv] = transform(k, v);
    acc[newk] = newv;
    return acc;
  }, {});
}

export default replaceKeyOrValue;
