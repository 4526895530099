/* eslint-disable react/destructuring-assignment */
import React from 'react';
import ReactTable from 'react-table-6';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import DataTableSkeleton from './skeleton/DataTableSkeleton';
import './_dataTable.scss';

const DataTable = ({
  id,
  className,
  data,
  defaultPageSize,
  sortBy,
  onFetchData,
  onRowClick,
  setRowProps,
  tooltip,
  isLoading,
  ...props
}) => {
  if (isLoading) return <DataTableSkeleton />;

  const handleRowClick = (e, row) => {
    if (
      e.target.classList.contains('rt-td') ||
      e.target.classList.contains('-clickable') ||
      e.target.tagName === 'SPAN'
    ) {
      return onRowClick(e, row);
    }
  };

  return (
    <div className="data-table__container">
      {isLoading || !data ? (
        <DataTableSkeleton />
      ) : (
        <ReactTable
          className={classNames('data-table', className, {
            '-scrollable': props.style && props.style.height,
            '-empty': data.length <= 0,
          })}
          data={data}
          defaultSorted={sortBy ? [{ id: sortBy }] : []}
          defaultPageSize={defaultPageSize}
          minRows={0}
          resizable={false}
          showPageSizeOptions={false}
          showPagination={(data || []).length > defaultPageSize}
          sortable={false}
          onFetchData={onFetchData}
          getTheadThProps={(table, row, col) => {
            return {
              className: classNames({
                '-sortable': col.sortable,
              }),
            };
          }}
          getTrProps={(table, { original: row }, col) => {
            const { className: rowClassName, ...otherProps } = setRowProps(
              table,
              row,
              col,
            );
            return {
              className: classNames('data-table__tr', rowClassName),
              onClick: e => handleRowClick(e, row),
              ...otherProps,
            };
          }}
          {...props}
        />
      )}
    </div>
  );
};

DataTable.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  defaultPageSize: PropTypes.number,
  pages: PropTypes.number,
  sortBy: PropTypes.string,
  style: PropTypes.any,
  tooltip: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  isLoading: PropTypes.bool,
  onFetchData: PropTypes.func,
  onRowClick: PropTypes.func,
  setRowProps: PropTypes.func,
};

DataTable.defaultProps = {
  id: undefined,
  className: null,
  data: null,
  defaultPageSize: 10,
  pages: undefined,
  sortBy: null,
  style: undefined,
  tooltip: undefined,
  isLoading: false,
  onFetchData: () => {},
  onRowClick: () => {},
  setRowProps: () => {
    return {};
  },
};

export default DataTable;
