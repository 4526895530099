import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import PermissionLock from '../../assets/images/permission-lock.svg';
import { Button } from '../../library/atoms/Buttons';
import './ErrorPage.scss';

const mapStateToProps = (_state, props) => {
  const { history } = props;
  const { location } = history || {};
  const { state: locationState } = location || {};
  const { returnLabel, returnTo, entity } = locationState || {};
  return {
    history,
    returnLabel,
    returnTo,
    entity,
  };
};

const Page403 = ({ history, entity, returnTo, returnLabel }) => {
  const redirect = !entity || !returnTo || !returnLabel;
  return redirect ? (
    <Redirect to="/404" />
  ) : (
    <div className="row">
      <div className="col-xs-12">
        <div className="page-wrapper-403">
          <img
            className="page-wrapper-403--image"
            src={PermissionLock}
            alt="Permission lock"
          />
          <h1>Uh oh...</h1>
          <p>
            You don’t have permission to view this
            {entity}.
          </p>
          <p>
            Please contact your administrator and have them assign the
            respective access tag to your profile.
          </p>
          <Button onClick={() => history.push(returnTo)} size="lg">
            {returnLabel}
          </Button>
        </div>
      </div>
    </div>
  );
};

Page403.propTypes = {
  history: PropTypes.object.isRequired,
  entity: PropTypes.string,
  returnTo: PropTypes.string,
  returnLabel: PropTypes.string,
};

Page403.defaultProps = {
  entity: null,
  returnTo: null,
  returnLabel: null,
};

export default connect(mapStateToProps)(Page403);
