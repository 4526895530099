import React from 'react';
import ReactLoading from 'react-loading';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './_skeleton.scss';

const Spinner = ({ className, color, height, width }) => {
  return (
    <div
      className={classNames('skeleton__spinner', className)}
      style={{ width: `${width}px` }}
    >
      <ReactLoading type="spin" color={color} height={width} width={height} />
    </div>
  );
};

Spinner.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

Spinner.defaultProps = {
  className: null,
  color: '#c6c6c6',
  height: 64,
  width: 64,
};

export default Spinner;
