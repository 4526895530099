import React from 'react';
import PropTypes from 'prop-types';

import JsonViewer from '../../../UI/JsonViewer/JsonViewer';
import { Tab, TabPanel, Tabs, TabsContainer } from '../../../UI/Tabs';

import CaptureField from '../Field/CaptureField';
import 'jsoneditor-react/es/editor.min.css';
import './_captureData.scss';

const hasValue = value => {
  return value && (Array.isArray(value) ? value.length : true);
};

const CaptureData = ({ doc }) => {
  const extractedFields = Object.values(doc.productFieldMapping).reduce(
    (acc, mapping) => acc.concat(mapping),
    [],
  );
  const fields = extractedFields.filter(f => hasValue(f.value));

  return (
    <TabsContainer className="capture-data" defaultTab="results">
      <div className="capture-data__header">
        <h2 className="capture-data__heading">Extracted data</h2>
        <Tabs className="capture-data__menu">
          <Tab tabFor="results">Results</Tab>
          <Tab tabFor="json">Json</Tab>
        </Tabs>
      </div>
      <div className="capture-data__body">
        <TabPanel id="results" className="capture-data__panel">
          <ul className="capture-data__fieldset">
            {(fields || []).map((f, i) => (
              <CaptureField
                field={f}
                row={i}
                key={`capture-field|${f.fieldset}|${f.id}`}
              />
            ))}
          </ul>
        </TabPanel>
        <TabPanel id="json" className="capture-data__panel">
          <JsonViewer data={doc.json} />
        </TabPanel>
      </div>
    </TabsContainer>
  );
};

CaptureData.propTypes = {
  doc: PropTypes.shape({
    productFieldMapping: PropTypes.shape({}),
    json: PropTypes.shape({}),
  }).isRequired,
};

export default CaptureData;
