import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './Table.scss';

const Table = ({ children, className }) => {
  const tableClass = classNames(className, 'table');
  return <table className={tableClass}>{children}</table>;
};

Table.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Table.defaultProps = {
  children: null,
  className: null,
};

export default Table;
