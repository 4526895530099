/* eslint-disable import/prefer-default-export */

export const createElement = (tag, classNames = [], attributes = {}) => {
  const container = document.createElement(tag);
  classNames.forEach(c => {
    container.classList.add(c);
  });
  Object.entries(attributes).forEach(a => {
    container.setAttribute(a[0], a[1]);
  });
  return container;
};

export const copyToClipboard = text => {
  const textarea = createElement('textarea', ['clip-container']);
  document.body.appendChild(textarea);
  textarea.innerText = text;
  textarea.select();
  document.execCommand('copy');
  textarea.remove();
};

export const getParentByClass = (elem, className) => {
  if (elem.classList && elem.classList.contains(className)) return elem;

  // eslint-disable-next-line no-param-reassign,no-cond-assign
  while ((elem = elem.parentNode)) {
    if (elem.classList && elem.classList.contains(className)) return elem;
  }

  return null;
};

export const hasParentClass = (elem, className) => {
  let isChild = false;
  if (elem.classList && elem.classList.contains(className)) isChild = true;

  // eslint-disable-next-line no-param-reassign,no-cond-assign
  while ((elem = elem.parentNode)) {
    if (elem.classList && elem.classList.contains(className)) isChild = true;
  }
  return isChild;
};
