const validatorType = Object.freeze({
  Abn: 'abn',
  Email: 'email',
  Match: 'match',
  NumberMin: 'number:min',
  NumberMax: 'number:max',
  // todo: NumberRange: 'number:range',
  Length: 'length',
  Number: 'number',
  Password: 'password',
  Phone: 'phone',
  PhoneAu: 'phone:au',
  Required: 'required',
  Decimal: 'decimal',
});

export default validatorType;
