import React, { useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './_button.scss';

const IconButton = ({ children, className, type, onClick, ...props }) => {
  return (
    <button
      type={type}
      className={classNames('icon-btn', className)}
      {...props}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

IconButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
};

IconButton.defaultProps = {
  children: null,
  className: null,
  type: 'button',
  onClick: () => {},
};

export default IconButton;
