import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const initialState = {
  rotation: 0,
  collapsed: false,
};
const DocumentToolbarContext = createContext([{}, () => {}]);

const DocumentToolbarContainer = ({ children }) => {
  const [state, setState] = useState(initialState);

  return (
    <DocumentToolbarContext.Provider value={[state, setState]}>
      {children}
    </DocumentToolbarContext.Provider>
  );
};

DocumentToolbarContainer.propTypes = {
  children: PropTypes.node,
};

DocumentToolbarContainer.defaultProps = {
  children: null,
};

export default DocumentToolbarContainer;
export { DocumentToolbarContext };
