import React from 'react';
import PropTypes from 'prop-types';
import TourContent from '../TourContent';
import Upload from '../../../../../../assets/images/onboarding/introduction-to-sypht/upload.svg';

const UploadDocuments = ({ closeTour }) => (
  <TourContent
    icon={Upload}
    text="Upload your documents for extraction using Sypht AI. Documents can also be emailed to Sypht and uploaded automatically."
    buttonText="Setup email integration"
    buttonUrl="/settings/email"
    closeTour={closeTour}
  />
);

UploadDocuments.propTypes = {
  closeTour: PropTypes.func,
};

UploadDocuments.defaultProps = {
  closeTour: () => {},
};

export default UploadDocuments;
