import React from 'react';
import PropTypes from 'prop-types';

const ContextBarSubHeading = ({ children }) => {
  return <h2 className="context-bar__sub-heading">{children}</h2>;
};

ContextBarSubHeading.propTypes = {
  children: PropTypes.node,
};

ContextBarSubHeading.defaultProps = {
  children: null,
};

export default ContextBarSubHeading;
