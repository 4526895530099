import React from 'react';
import PropTypes from 'prop-types';
import Skeleton, { SkeletonTable } from '../../../Skeleton';

const DataTableSkeleton = ({ noOfRows }) => {
  return (
    <Skeleton>
      <SkeletonTable noOfRows={noOfRows} />
    </Skeleton>
  );
};

DataTableSkeleton.propTypes = {
  noOfRows: PropTypes.number,
};

DataTableSkeleton.defaultProps = {
  noOfRows: 10,
};

export default DataTableSkeleton;
