import { mergeRight as merge } from 'ramda';
import { LOCATION_CHANGE } from 'connected-react-router';
import moment from 'moment';
import { purgeStoredState } from 'redux-persist';
import AuthService from '../services/AuthService';
import NotificationService from '../services/NotificationService';
import UserService from '../services/UserService';
import * as persistConfig from '../config/redux-persist/config';
import { fetchCompany } from './company/actions';
import { ScopeLookup } from '../common/lookups';
import { DEFAULT_LANDING_ROUTE } from '../common/constants';

export const SET_AUTHENTICATED_FAILED = 'sypht/auth/SET_AUTHENTICATED_FAILED';
export const SET_AUTHENTICATED_SUCCESS = 'sypht/auth/SET_AUTHENTICATED_SUCCESS';
export const SET_AUTHENTICATING = 'sypht/auth/SET_AUTHENTICATING';
export const LOGIN_ERROR = 'sypht/auth/LOGIN_ERROR';
export const CLEAR_STATE = 'sypht/auth/CLEAR_STATE';
export const NOTIFY_SESSION_EXPIRY = 'sypht/auth/NOTIFY_SESSION_EXPIRY';
export const REFRESH_AUTH = 'sypht/auth/REFRESH_AUTH';

export const REQUEST_USER = 'sypht/auth/REQUEST_USER';
export const RECEIVE_USER = 'sypht/auth/RECEIVE_USER';
export const RECEIVE_USER_ONBOARDED = 'sypht/auth/RECEIVE_USER_ONBOARDED';

export const OPEN_TOUR = 'sypht/auth/OPEN_TOUR';
export const RESEND_WELCOME_EMAIL = 'sypht/auth/RESEND_WELCOME_EMAIL';

export const OPEN_PLATFORM_PANEL = 'app/auth/OPEN_PLATFORM_PANEL';
export const OPEN_PLATFORM_PANEL_ONBOARD =
  'app/auth/OPEN_PLATFORM_PANEL_ONBOARD';
export const DISMISS_PLATFORM_PANEL = 'app/auth/DISMISS_PLATFORM_PANEL';
export const MINIMISE_PLATFORM_PANEL = 'app/auth/MINIMISE_PLATFORM_PANEL';
export const CAPTURE_TOUR_PROGRESS = 'app/auth/CAPTURE_TOUR_PROGRESS';

const PLATFORM_PANEL_STATES = {
  Dismissed: 'dismissed',
  Minimised: 'minimised',
  Open: 'open',
  OpenOnboard: 'openOnboard',
};

const extractClaims = accessToken => {
  const namespace = 'https://api.sypht.com/';
  const tokens = accessToken.split('.');
  const payload = JSON.parse(atob(tokens[1]));
  const claims = {};
  // eslint-disable-next-line no-restricted-syntax, no-unused-vars
  for (const [key, value] of Object.entries(payload)) {
    if (key.indexOf(namespace) === 0) {
      claims[key.substring(namespace.length)] = value;
    }
  }
  claims.allowedScopes = payload.scope.split(' ');
  claims.userId = payload.sub;
  return claims;
};

const getPermissions = claims => {
  const permissions = {};
  const scopes = claims ? claims.allowedScopes : [];

  permissions.canAdministrateUsers =
    scopes.includes(ScopeLookup.SuperUserWrite.key) ||
    scopes.includes(ScopeLookup.SuperUserRead.key) ||
    scopes.includes(ScopeLookup.WriteUser.key);

  permissions.canAdministrateCompany =
    scopes.includes(ScopeLookup.SuperUserRead.key) ||
    scopes.includes(ScopeLookup.WriteCompany.key);

  permissions.canWriteTag = scopes.includes(ScopeLookup.WriteTag.key);
  permissions.canTagDocument = scopes.includes(ScopeLookup.TagDocument.key);
  permissions.canTagTask = scopes.includes(ScopeLookup.TagTask.key);

  permissions.canShowCompanyFilter =
    scopes.includes(ScopeLookup.SuperUserRead.key) ||
    scopes.includes(ScopeLookup.SuperUserWrite.key);

  permissions.canSuperUserWrite = scopes.includes(
    ScopeLookup.SuperUserWrite.key,
  );
  permissions.canSuperUserRead = scopes.includes(ScopeLookup.SuperUserRead.key);

  permissions.canAdministrateCredentials =
    scopes.includes(ScopeLookup.SuperUserRead.key) ||
    scopes.includes(ScopeLookup.ReadCredential.key) ||
    scopes.includes(ScopeLookup.WriteCredential.key);

  permissions.canAdministrateDocs =
    scopes.includes(ScopeLookup.WriteDocument.key) ||
    scopes.includes(ScopeLookup.ReadDocument.key);

  permissions.canReadAnalytics = scopes.includes(ScopeLookup.ReadAnalytics.key);
  permissions.canReadTaskAnalytics = scopes.includes(
    ScopeLookup.WriteTaskRule.key,
  );
  permissions.canReadAnyAnalytics =
    permissions.canReadAnalytics || permissions.canReadTaskAnalytics;

  permissions.canSuperUserAny =
    permissions.canSuperUserRead || permissions.canSuperUserWrite;

  permissions.canUploadDocument =
    scopes.includes(ScopeLookup.UploadDocument.key) &&
    // Ingest workflow is invoked directly by us:
    scopes.includes(ScopeLookup.InvokeWorkflow.key);

  permissions.canSplitDocument = scopes.includes(ScopeLookup.SplitDocument.key);
  permissions.canExportDocument = scopes.includes(
    ScopeLookup.ExportDocument.key,
  );

  // This is a validate/Reviewer, someone who can push HITL annotations but may not
  // be able to create tasks.
  permissions.canReviewTasks = scopes.includes(ScopeLookup.WriteAnnotation.key);

  // This is a validate/Organiser or higher, someone who can create HITL tasks.
  permissions.canWriteTask = scopes.includes(ScopeLookup.WriteTask.key);

  permissions.canEditTaskRules =
    scopes.includes(ScopeLookup.WriteTaskRule.key) &&
    // Hide this from customers as validation rules are not public:
    scopes.includes(ScopeLookup.SuperUserWrite.key);

  permissions.canViewMarketplace =
    scopes.includes(ScopeLookup.WriteSubscription.key) ||
    scopes.includes(ScopeLookup.ReadAnalytics.key);

  permissions.canDeleteDocument = scopes.includes(
    ScopeLookup.DeleteDocument.key,
  );

  permissions.canExtractDocument = scopes.includes(
    ScopeLookup.ExtractDocument.key,
  );

  return permissions;
};

const extractClaimsWithPermissions = payload => {
  const claims = extractClaims(payload.accessToken);
  return {
    claims,
    permissions: getPermissions(claims),
  };
};

const initialState = {
  isAuthenticated: false,
  isAuthenticating: false,
  sessionExpiry: false,
  accessToken: '',
  idToken: '',
  expiresIn: 0,
  expiresAt: 0,
  error: null,
  user: {
    data: null,
    loading: false,
  },
  claims: {},
  openTour: false,
  permissions: getPermissions(),
};

export default function authReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOCATION_CHANGE:
      return {
        ...state,
      };
    case SET_AUTHENTICATED_FAILED:
      return merge(initialState, {
        isAuthenticated: false,
        isAuthenticating: false,
        sessionExpiry: false,
        error: payload.err,
      });
    case SET_AUTHENTICATED_SUCCESS:
      return merge(state, {
        userId: payload.idTokenPayload.sub,
        accessToken: payload.accessToken,
        idToken: payload.idToken,
        expiresIn: payload.expiresIn,
        expiresAt: moment().unix() + payload.expiresIn,
        isAuthenticated: true,
        isAuthenticating: false,
        sessionExpiry: false,
        openTour: false,
        error: null,
        ...extractClaimsWithPermissions(payload),
      });
    case SET_AUTHENTICATING:
      return merge(state, {
        isAuthenticating: true,
      });
    case LOGIN_ERROR:
      return merge(state, {
        error: payload,
      });
    case CLEAR_STATE:
      return initialState;
    case REQUEST_USER:
      return merge(state, {
        user: merge(state.user, {
          loading: true,
        }),
      });
    case RECEIVE_USER:
      return merge(state, {
        user: merge(state.user, {
          data: action.user,
          loading: false,
        }),
      });
    case RECEIVE_USER_ONBOARDED:
      return merge(state, {
        user: merge(state.user, {
          data: merge(state.user.data, {
            onboarded: action.onboarded,
          }),
        }),
      });
    case NOTIFY_SESSION_EXPIRY:
      return merge(state, {
        sessionExpiry: true,
      });
    case OPEN_TOUR:
      return merge(state, {
        openTour: true,
      });
    case RESEND_WELCOME_EMAIL:
      return merge(state, {
        error: payload,
      });
    case OPEN_PLATFORM_PANEL:
      return merge(state, {
        user: merge(state.user, {
          data: merge(state.user.data, {
            profileData: merge(state.user.data.profileData, {
              tourState: PLATFORM_PANEL_STATES.Open,
            }),
          }),
        }),
      });
    case OPEN_PLATFORM_PANEL_ONBOARD:
      return merge(state, {
        user: merge(state.user, {
          data: merge(state.user.data, {
            profileData: merge(state.user.data.profileData, {
              tourState: PLATFORM_PANEL_STATES.OpenOnboard,
            }),
          }),
        }),
      });
    case DISMISS_PLATFORM_PANEL:
      return merge(state, {
        user: merge(state.user, {
          data: merge(state.user.data, {
            profileData: merge(state.user.data.profileData, {
              tourState: PLATFORM_PANEL_STATES.Dismissed,
            }),
          }),
        }),
      });
    case MINIMISE_PLATFORM_PANEL:
      return merge(state, {
        user: merge(state.user, {
          data: merge(state.user.data, {
            profileData: merge(state.user.data.profileData, {
              tourState: PLATFORM_PANEL_STATES.Minimised,
            }),
          }),
        }),
      });
    case CAPTURE_TOUR_PROGRESS:
      return merge(state, {
        user: merge(state.user, {
          data: merge(state.user.data, {
            profileData: merge(state.user.data.profileData, {
              onboardProgress: merge(
                state.user.data.profileData.onboardProgress,
                {
                  [payload.tourId]: true,
                },
              ),
            }),
          }),
        }),
      });
    default:
      return state;
  }
}

export const setAuthenticatedSuccess = authResult => ({
  type: SET_AUTHENTICATED_SUCCESS,
  payload: { ...authResult },
});

export const requestUser = () => ({ type: REQUEST_USER });
export const receiveUser = user => ({ type: RECEIVE_USER, user });
export const receiveUserOnboarded = onboarded => ({
  type: RECEIVE_USER_ONBOARDED,
  onboarded,
});

export const setAuthenticatedFailed = err => ({
  type: SET_AUTHENTICATED_FAILED,
  payload: { err },
});

export const setAuthenticating = () => ({
  type: SET_AUTHENTICATING,
  payload: {},
});

export const authenticationSuccess = authResult => dispatch => {
  dispatch(setAuthenticatedSuccess(authResult));
  // eslint-disable-next-line no-use-before-define
  dispatch(loadUser(authResult.accessToken)).then(() => {
    const redirectTo = window.sessionStorage.getItem('redirectTo');
    if (redirectTo) {
      sessionStorage.removeItem('redirectTo');
      if (!window.location.href.endsWith(redirectTo)) {
        window.location = redirectTo;
      }
    }
    if (!redirectTo) {
      const url = new URL(window.location);
      if (url.pathname === '/callback') {
        // The user probably came from an external registration or login form
        // and they don't have a redirectTo.
        window.location = DEFAULT_LANDING_ROUTE;
      }
    }
  });
};

export const login = (email, password) => dispatch => {
  AuthService.login(email, password, err => {
    dispatch({ type: LOGIN_ERROR, payload: { ...err } });
  });
};

export const clearState = () => ({ type: CLEAR_STATE, payload: {} });

export const logout = () => dispatch => {
  dispatch(clearState());
  purgeStoredState(persistConfig.auth);
  NotificationService.unsubscribe();
  AuthService.logout();
};

export const loadUser = accessToken => (dispatch, getState) => {
  const { auth } = getState();
  dispatch(requestUser());
  return UserService.getUserById(accessToken || auth.accessToken, auth.userId)
    .then(({ data }) => {
      dispatch(receiveUser(data));
      dispatch(fetchCompany(data.companyId));
      NotificationService.subscribe(dispatch, data.companyId, data.id);
    })
    .catch(e => dispatch(receiveUser(e)));
};

export const updateUserProfile = userProfile => (dispatch, getState) => {
  const { auth } = getState();
  const { accessToken, userId } = auth;
  return UserService.updateUserProfile(accessToken, userId, userProfile)
    .then(res => {
      dispatch(receiveUserOnboarded(res.data.onboarded));
    })
    .catch(e => {
      dispatch(receiveUserOnboarded(e));
    });
};

export const refreshSuccess = authResult => dispatch => {
  const { companyId, userId } = extractClaims(authResult.accessToken);
  dispatch(setAuthenticatedSuccess(authResult));
  NotificationService.subscribe(dispatch, companyId, userId);
};

export const refreshAuth = () => ({ type: REFRESH_AUTH, payload: {} });

export const refreshToken = () => () => {
  AuthService.refresh(() => {});
};

export const notifySessionExpiry = () => ({
  type: NOTIFY_SESSION_EXPIRY,
  payload: {},
});

export const requestOpenTour = () => ({ type: OPEN_TOUR, payload: {} });

export const resendWelcomeEmail = () => ({
  type: RESEND_WELCOME_EMAIL,
  payload: {},
});

export const requestWelcomeEmail = () => (dispatch, getState) => {
  const { auth } = getState();
  const { accessToken, userId } = auth;
  return UserService.resendWelcomeEmail(accessToken, userId)
    .then(res => dispatch(resendWelcomeEmail(res)))
    .catch(e => dispatch(resendWelcomeEmail(e)));
};

const setPlatformTourPanelState = state => ({
  type: state,
});

const setCaptureTourProgress = tourId => ({
  type: CAPTURE_TOUR_PROGRESS,
  payload: { tourId },
});

export const openPlatformTourPanel = () => (dispatch, getState) => {
  dispatch(setPlatformTourPanelState(OPEN_PLATFORM_PANEL));
  const { auth } = getState();
  dispatch(updateUserProfile({ profileData: auth.user.data.profileData }));
};

export const openPlatformTourPanelForOnboarding =
  () => (dispatch, getState) => {
    dispatch(setPlatformTourPanelState(OPEN_PLATFORM_PANEL_ONBOARD));
    const { auth } = getState();
    dispatch(updateUserProfile({ profileData: auth.user.data.profileData }));
  };

export const dismissPlatformTourPanel = () => (dispatch, getState) => {
  dispatch(setPlatformTourPanelState(DISMISS_PLATFORM_PANEL));
  const { auth } = getState();
  dispatch(updateUserProfile({ profileData: auth.user.data.profileData }));
};

export const minimisePlatformTourPanel = () => (dispatch, getState) => {
  dispatch(setPlatformTourPanelState(MINIMISE_PLATFORM_PANEL));
  const { auth } = getState();
  dispatch(updateUserProfile({ profileData: auth.user.data.profileData }));
};

export const captureTourProgress = tourId => (dispatch, getState) => {
  dispatch(setCaptureTourProgress(tourId));
  const { auth } = getState();
  dispatch(updateUserProfile({ profileData: auth.user.data.profileData }));
};
