import { useEffect } from 'react';
import history from '../../../services/historyService';

const useUrlChanges = callback => {
  useEffect(() => {
    history.listen(() => {
      callback();
    });
  });
};

export default useUrlChanges;
