import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Checkbox from '../Checkbox/Checkbox';
import SearchFieldV2 from '../SearchField/SearchFieldV2';
import './_checkbox-list.scss';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const sortByGroupThenValue = (a, b) => {
  if (a.group > b.group) return 1;
  if (a.group < b.group) return -1;
  if (a.value < b.value) return -1;
  if (a.value > b.value) return 1;
  return 0;
};

const getSearchSuggestions = options => {
  const groups = (options || [])
    .map(g => {
      const o = (g.options && g.options[0]) || {};
      const value = o.value || '';
      return {
        name: g.group,
        value: value.substr(0, value.lastIndexOf('.')),
        group: g.group,
        type: 'group',
      };
    })
    .flat(1);

  const tokens = (options || [])
    .map(g =>
      g.options.map(o => {
        return {
          name: o.label,
          value: o.value,
          group: g.group,
          type: 'token',
        };
      }),
    )
    .flat(1);

  return [...groups, ...tokens].sort((a, b) => sortByGroupThenValue(a, b));
};

const getFilteredOptions = (search, options) => {
  if (!search.value) return options;

  if (search.type === 'group')
    return options.filter(g => g.group === search.value);

  const filteredOptions = options
    .map(g => ({
      ...g,
      options: g.options.filter(o =>
        o.label.toLowerCase().includes(search.value.toLowerCase()),
      ),
    }))
    .filter(g => g.options.length);

  return filteredOptions;
};

const DEFAULT_SEARCH = { value: null, type: null };

const CheckboxList = ({
  className,
  defaultValues,
  options,
  tooltips,
  isRequired,
  onChange,
}) => {
  if (!options || !options.length) return null;

  const [search, setSearch] = useState(DEFAULT_SEARCH);
  const [selectedValues, setSelectedValues] = useState(defaultValues); // string[]
  const [searchSuggestions, setSearchSuggestions] = useState(options); // Object[]
  const [isSelectAll, setSelectAll] = useState(false);
  const [isTouched, setTouched] = useState(false);

  const checkboxOptions = useMemo(() => {
    return getFilteredOptions(search, options);
  }, [search, options]);

  const [allOptions, allAvailableOptions] = useMemo(() => {
    const all = (options || []).map(o => o.options).flat(1);
    const available = all.filter(o => !o.disabled).map(o => o.value);
    return [all, available];
  }, [options]);

  useEffect(() => {
    onChange(selectedValues);
  }, [selectedValues]);

  useEffect(() => {
    // on options list change, update search suggestions
    setSearchSuggestions(getSearchSuggestions(options));
  }, [options]);

  const handleSearchChange = result => {
    // on empty search input, reset search
    if (!result) {
      setSearch(DEFAULT_SEARCH);
      return;
    }

    // set the type of search
    // selected.type: categorized result or found item result ('group' or 'token')
    // 'query': partial results
    const { value, method, selected } = result;
    setSearch({
      value,
      type: method !== 'type' && selected ? selected.type : 'query',
    });
  };

  const handleCheckboxChange = e => {
    const values = [...selectedValues];

    if (!e.target.checked) {
      // remove from selectedValues string[]
      values.splice(values.indexOf(e.target.value), 1);
      setSelectedValues(values);
      setSelectAll(false);
      return;
    }

    if (!values.includes(e.target.value)) {
      // add to selectedValues string[]
      values.push(e.target.value);
      setSelectedValues(values.sort((a, b) => (a > b ? 1 : -1)));
    }

    // setting tuched to true enables validation
    if (!isTouched) setTouched(true);
  };

  const handleSelectAllChange = e => {
    if (e.target.checked) {
      setSelectedValues(allAvailableOptions);
      setSelectAll(true);
    } else {
      setSelectedValues([]);
      setSelectAll(false);
    }
  };

  return (
    <div className={classNames('checkbox-list', className)}>
      <div className="checkbox-list__header">
        <div className="checkbox-list__header-left">
          <SearchFieldV2
            data={searchSuggestions}
            placeholder="Search by field name or category"
            onChange={handleSearchChange}
          />
          <Checkbox
            className="checkbox-list__select-all"
            value="select_all"
            label="Select all fields"
            checked={isSelectAll}
            onClick={handleSelectAllChange}
          />
        </div>
        <div className="checkbox-list__header-right">
          <span className="num-selected">
            {selectedValues.length} of
            {allAvailableOptions.length} fields selected
          </span>
        </div>
      </div>
      <div className="checkbox-list__body">
        <ul
          className={classNames('checkbox-list__list', {
            'checkbox-list__list--group':
              checkboxOptions.length < options.length || options.length === 1,
          })}
        >
          {(checkboxOptions || []).map(g => (
            <li
              className="checkbox-list__list-group"
              key={`checkbox_group|${g.group}`}
            >
              {g.group && <h5>{g.group}</h5>}
              <ul className="checkbox-list__list">
                {(g.options || []).map(o => {
                  return (
                    <li
                      className="checkbox-list__list-item"
                      key={`checkbox_group|${g.group}|${o.value}`}
                    >
                      <Checkbox
                        value={o.value}
                        label={o.label}
                        checked={selectedValues.includes(o.value)}
                        disabled={o.disabled}
                        dataTip={o.disabled ? tooltips.disabled : undefined}
                        onChange={handleCheckboxChange}
                      />
                    </li>
                  );
                })}
              </ul>
            </li>
          ))}
        </ul>
        {isRequired && isTouched && selectedValues.length <= 0 && (
          <div className="checkbox-list__error">
            <span>At least one option must be selected.</span>
          </div>
        )}
        <ReactTooltip
          className="checkbox-list__tip"
          place="right"
          variant="dark"
        />
      </div>
    </div>
  );
};

CheckboxList.propTypes = {
  className: PropTypes.string,
  defaultValues: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.any,
  tooltips: PropTypes.object,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
};

CheckboxList.defaultProps = {
  className: null,
  defaultValues: [],
  options: null,
  tooltips: {},
  isRequired: true,
  onChange: () => {},
};

export default CheckboxList;
