import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FaLock } from 'react-icons/fa';
import './_choice-chip.scss';

const ChoiceChip = ({
  name,
  value,
  currentValue,
  onChange,
  heading,
  image,
  children,
  disabled,
  variant,
}) => {
  const ref = useRef();
  const isSelected = value === currentValue;
  const calcProps = disabled ? {} : { tabIndex: 0, role: 'radio' };

  const handleChange = useCallback(
    evt => {
      onChange(evt, value);
    },
    [onChange, value],
  );

  // Handle keyboard interactivity

  useEffect(() => {
    const element = ref.current;
    element.onkeyup = evt => {
      if (evt.key === 'Enter') {
        onChange(evt, value);
      }
    };
    return () => {
      element.onkeyup = undefined;
    };
  }, [onChange, value]);

  if (!name) {
    return null;
  }

  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...calcProps}
      ref={ref}
      aria-disabled={disabled ? 'true' : 'false'}
      aria-checked={String(isSelected)}
      aria-label={heading}
      className={classNames(
        'choice-chip',
        {
          'choice-chip--selected': isSelected,
          'choice-chip--disabled': disabled,
        },
        `chip-${value}`,
      )}
      onClick={handleChange}
    >
      {variant === 'locked' && (
        <div className="choice-chip__padlock">
          <FaLock />
        </div>
      )}
      <div className="choice-chip__img">{image}</div>
      <div className="choice-chip__heading">{heading}</div>
      <div className="choice-chip__text">{children}</div>
    </div>
  );
};

ChoiceChip.propTypes = {
  /**
   * Name should always be set but the prop is optional here because we allow it
   * to be set by a ChoiceChipGroup container via cloneElement.
   */
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  currentValue: PropTypes.string,
  onChange: PropTypes.func,
  heading: PropTypes.string.isRequired,
  image: PropTypes.node.isRequired,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['locked']),
};

ChoiceChip.defaultProps = {
  currentValue: '',
  onChange: () => {},
  name: undefined,
  children: '',
  disabled: false,
  variant: undefined,
};

export default ChoiceChip;
