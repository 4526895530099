import { generateMonths } from '../../common/helpers/billingHelper';
import {
  REQUEST_SUBSCRIPTIONS,
  RECEIVE_BILLING_PERIODS,
  RECEIVE_SUBSCRIPTIONS,
  RECEIVE_PAYMENT_SUCCESS,
  RECEIVE_PAYMENT_FAILURE,
  CLEAR_PAYMENT_RESULT,
  REQUEST_SUBSCRIPTION_BY_ID,
  RECEIVE_SUBSCRIPTION_BY_ID,
  REQUEST_TRIAL_SUBSCRIPTION,
  RECEIVE_TRIAL_SUBSCRIPTION,
  RECEIVE_PAID_SUBSCRIPTION,
  REQUEST_PAID_SUBSCRIPTION,
  REQUEST_SAVE_PAYMENT_METHOD,
  RECEIVE_SAVE_PAYMENT_METHOD,
} from './actions';

import containsProductSubscription from '../../common/helpers/subscriptionHelper';

const initialState = {
  data: null,
  billingPeriods: null,
  invalidate: false,
  loading: true,
  sending: false,
  lastUpdated: null,
  paymentResult: null,
  active: null,
  hasValidateProduct: false,
};

const subscriptionsReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case REQUEST_SUBSCRIPTIONS: {
      return {
        ...state,
        loading: true,
      };
    }

    case REQUEST_TRIAL_SUBSCRIPTION: {
      return {
        ...state,
        sending: true,
      };
    }

    case RECEIVE_TRIAL_SUBSCRIPTION: {
      const { data, loading, receivedAt } = payload;
      return {
        ...state,
        active: data,
        loading,
        lastUpdated: receivedAt,
      };
    }

    case REQUEST_PAID_SUBSCRIPTION: {
      return {
        ...state,
        sending: true,
      };
    }

    case RECEIVE_PAID_SUBSCRIPTION: {
      const { data, loading, receivedAt } = payload;
      return {
        ...state,
        active: data,
        loading,
        sending: false,
        lastUpdated: receivedAt,
      };
    }

    case REQUEST_SAVE_PAYMENT_METHOD: {
      const { loading } = payload;
      return {
        ...state,
        loading,
        sending: true,
      };
    }

    case RECEIVE_SAVE_PAYMENT_METHOD: {
      const { loading } = payload;
      return {
        ...state,
        loading,
        sending: false,
      };
    }

    case RECEIVE_SUBSCRIPTIONS: {
      const { data, receivedAt } = payload;
      const { subscriptions } = data;
      const hasValidateProduct = containsProductSubscription(subscriptions, 'validate');
      return {
        ...state,
        data: subscriptions,
        loading: false,
        sending: false,
        lastUpdated: receivedAt,
        hasValidateProduct,
      };
    }

    case REQUEST_SUBSCRIPTION_BY_ID: {
      return {
        ...state,
        loading: true,
      };
    }

    case RECEIVE_SUBSCRIPTION_BY_ID: {
      const { data, receivedAt } = payload;
      return {
        ...state,
        active: data,
        sending: false,
        loading: false,
        lastUpdated: receivedAt,
      };
    }

    case RECEIVE_BILLING_PERIODS: {
      const { data } = payload;
      const { subscriptions } = data;

      // create a unique list of billing periods and their products
      const billingPeriods = generateMonths(subscriptions);

      // sort descending
      billingPeriods.sort((a, b) => (a.id < b.id ? 1 : -1));

      return {
        ...state,
        billingPeriods,
      };
    }

    case RECEIVE_PAYMENT_SUCCESS:
      return {
        ...state,
        sending: false,
        loading: false,
        paymentResult: {
          isSuccess: true,
        },
      };

    case RECEIVE_PAYMENT_FAILURE:
      return {
        ...state,
        sending: false,
        loading: false,
        paymentResult: {
          isSuccess: false,
          reason: payload.reason,
        },
      };

    case CLEAR_PAYMENT_RESULT:
      return {
        ...state,
        sending: false,
        loading: false,
        paymentResult: null,
      };

    default:
      return state;
  }
};

export default subscriptionsReducer;
