import React from 'react';
import { FaFileDownload } from 'react-icons/fa';
import PropTypes from 'prop-types';

import Modal from '../../../UI/Modal/Modal';
import './_captureModal.scss';

const CaptureDownload = ({ doc, product, isOpen, onClose, onExportClick }) => {
  return (
    <Modal
      className="capture-modal capture-modal--download"
      cancelLabel="Cancel"
      modalLabel={`Download ${product.name}`}
      onCancel={onClose}
      isOpen={isOpen}
    >
      <div className="modal__body">
        <div className="capture-doc-actions">
          <ul className="capture-doc-actions__list">
            <li className="capture-doc-actions__item">
              <a
                href={doc.original}
                className="capture-doc-actions__link"
                download={doc.filename}
                target="_blank"
                rel="noreferrer"
              >
                Download original file
              </a>
              <FaFileDownload className="capture-doc-actions__icon" />
            </li>
            <li className="capture-doc-actions__item">
              <button
                type="button"
                className="capture-doc-actions__link"
                onClick={onExportClick}
              >
                Export to CSV
              </button>
              <FaFileDownload className="capture-doc-actions__icon" />
            </li>
          </ul>
        </div>
      </div>
    </Modal>
  );
};

CaptureDownload.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  doc: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onExportClick: PropTypes.func,
};

CaptureDownload.defaultProps = {
  onClose: () => {},
  onExportClick: () => {},
};

export default CaptureDownload;
