/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FaTable, FaCircle } from 'react-icons/fa';
import Accordion from '../../../../UI/Accordion/Accordion';
import * as docsReducer from '../../../../../reducers/docs';
import { getConfidence } from '../../../../../common/helpers';

import useScrollPredictionIntoView from '../hooks/useScrollPredictionIntoView';

const mapStateToProps = state => {
  return {
    genericData: state.docs.genericData ? state.docs.genericData : null,
    genericEntities:
      state.docs.genericData && state.docs.genericData.entities
        ? state.docs.genericData.entities
        : null,
    genericFields:
      state.docs.genericData && state.docs.genericFields
        ? state.docs.genericFields
        : null,
    genericTables:
      state.docs.genericData && state.docs.genericTables
        ? state.docs.genericTables
        : null,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setGenericFields: data => dispatch(docsReducer.receiveGenericFields(data)),
    setGenericTables: data => dispatch(docsReducer.receiveGenericTables(data)),
  };
};

const mapData = (dataSet, dataEntities) => {
  const results = (dataSet || []).map(val => {
    const label = dataEntities[val.label];
    const value = dataEntities[val.value];
    const valueBounds = value.bounds;

    return {
      uid: `${val.field_key}:${val.label}`,
      label,
      value,
      bounds: valueBounds,
      score: val.score,
    };
  });

  // remove duplicates
  const cleanResults = results.reduce((acc, accObj) => {
    const key = `label_${accObj.label.norm}`;

    if (!acc[key]) acc[key] = [];
    acc[key].push(accObj);

    return acc;
  }, []);

  return Object.entries(cleanResults);
};

const mapDataTable = (dataTable, dataEntities) => {
  const headerKeys = Object.keys(dataTable[0]);
  dataTable.unshift({ ...headerKeys });

  const results = (dataTable || []).map(tblRow => {
    const mapValues = item => {
      return {
        uid: item
          ? `${item.type}.${item.norm}.${item.bounds.pageNum}.${item.bounds.tokenIds[0]}`
          : null,
        label: item ? item.text : null,
        value: item ? item.norm : null,
        type: item ? item.type : null,
        bounds: item ? item.bounds : null,
      };
    };

    const itemObj = Object.values(tblRow).map(tblItem => {
      const item = dataEntities[tblItem];
      return mapValues(item);
    });

    return itemObj;
  });

  return results;
};

const SidebarGeneric = ({
  onClick,
  onTableClick,
  focusPrediction,
  focusGenericTbl,
  genericData,
  genericEntities,
  genericFields,
  genericTables,
  setGenericFields,
  setGenericTables,
  fieldsRef,
  tablesRef,
}) => {
  // assign values of FIELDS from entity dictionary
  const assignGenFields = () => {
    const objFields =
      genericData && genericData.fields ? genericData.fields : null;
    const objTables =
      genericData && genericData.tables ? genericData.tables : null;

    // set field results
    if (genericEntities && objFields) {
      const mappedResults = mapData(objFields, genericEntities);
      setGenericFields(mappedResults);
    }

    // set table results
    if (genericEntities && objTables) {
      const mappedResults = objTables.map(tableItem => {
        return mapDataTable(tableItem, genericEntities);
      });

      setGenericTables(mappedResults);
    }
  };

  useEffect(() => {
    assignGenFields();
  }, [genericData]);

  useEffect(() => {}, [focusPrediction]);

  const { setScrollToElement } = useScrollPredictionIntoView([focusPrediction]);

  return (
    <>
      <div className="generic-fields">
        {genericFields && (
          <Accordion>
            <Accordion.Header forwardRef={fieldsRef}>Fields</Accordion.Header>
            <Accordion.Panel>
              {genericFields.length > 0 ? (
                <>
                  {genericFields.map(fieldGroup => {
                    // get each field value
                    const fieldHeading = fieldGroup[1].map(
                      (fieldItem, index) => {
                        if (index === 0) {
                          return (
                            <h3 key={fieldItem.uid}>{fieldItem.label.text}</h3>
                          );
                        }
                        return null;
                      },
                    );

                    const fields = fieldGroup[1]
                      .filter((item, pos, self) => {
                        let filterFlag;
                        if (pos !== 0) {
                          filterFlag =
                            self[pos - 1].value.text !== item.value.text;
                        } else {
                          filterFlag = pos === 0;
                        }

                        return filterFlag;
                      })
                      .map(fieldItem => {
                        const { label: fieldConfidence, text: tooltipText } =
                          getConfidence(fieldItem.score);
                        const isFocused =
                          focusPrediction &&
                          fieldItem.uid === focusPrediction.uid;

                        return (
                          <button
                            ref={el =>
                              setScrollToElement(fieldItem, focusPrediction, el)
                            }
                            type="button"
                            className={classNames(
                              'field field-g',
                              'generic-entry',
                              {
                                focus: isFocused,
                              },
                            )}
                            key={`${fieldItem.uid}`}
                            onClick={() =>
                              onClick(
                                focusPrediction &&
                                  fieldItem.uid === focusPrediction.uid
                                  ? null
                                  : fieldItem,
                              )
                            }
                          >
                            {fieldItem.value.text}
                            <FaCircle
                              data-tooltip-content={tooltipText}
                              data-tooltip-place="right"
                              data-tooltip-id="document-tooltip"
                              size={10}
                              className={classNames(
                                'field-confidence',
                                fieldConfidence,
                              )}
                            />
                          </button>
                        );
                      });

                    // group fields into one field entry
                    return (
                      <div className="generic-wrapper" key={fieldGroup[0]}>
                        <div
                          className={classNames('field', 'generic-header')}
                          key={fieldGroup[0]}
                        >
                          {fieldHeading}
                        </div>
                        {fields}
                      </div>
                    );
                  })}
                </>
              ) : (
                <span className="field generic-entry-text">
                  No Fields Found
                </span>
              )}
            </Accordion.Panel>
          </Accordion>
        )}
      </div>
      <div className="generic-tables">
        {genericTables && (
          <Accordion>
            <Accordion.Header forwardRef={tablesRef}>Tables</Accordion.Header>
            <Accordion.Panel>
              {genericTables.length > 0 ? (
                <>
                  {genericTables.map((tbl, idx) => {
                    return (
                      <button
                        type="button"
                        className={`field generic-tbl-entry ${
                          focusGenericTbl &&
                          focusGenericTbl === `generic-table-${idx}`
                            ? 'focus'
                            : ''
                        }`}
                        onClick={() => {
                          onTableClick(
                            `generic-table-${idx}` &&
                              focusGenericTbl === `generic-table-${idx}`
                              ? null
                              : `generic-table-${idx}`,
                            `generic-table-${idx}` &&
                              focusGenericTbl === `generic-table-${idx}`
                              ? null
                              : idx,
                          );
                        }}
                        // eslint-disable-next-line react/no-array-index-key
                        key={`generic-table-${idx}`}
                      >
                        <FaTable />
                        <span>
                          Table
                          {idx + 1}
                        </span>
                      </button>
                    );
                  })}
                </>
              ) : (
                <span className="field generic-entry-text">
                  No Tables Found
                </span>
              )}
            </Accordion.Panel>
          </Accordion>
        )}
      </div>
    </>
  );
};

SidebarGeneric.defaultProps = {
  focusPrediction: [],
  focusGenericTbl: null,
  genericFields: null,
  genericTables: null,
  genericData: [],
  genericEntities: [],
  onClick: () => {},
  onTableClick: () => {},
  fieldsRef: null,
  tablesRef: null,
};

SidebarGeneric.propTypes = {
  onClick: PropTypes.func,
  onTableClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  focusPrediction: PropTypes.object,
  focusGenericTbl: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  genericEntities: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  genericData: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  genericFields: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  genericTables: PropTypes.array,
  setGenericFields: PropTypes.func.isRequired,
  setGenericTables: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fieldsRef: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  tablesRef: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarGeneric);
