import React, { useEffect, useRef, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { FaSearch, FaTimes } from 'react-icons/fa';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './SearchField.scss';

const getSuggestions = (data, value) => {
  const escapedValue = value.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  if (escapedValue === '') return [];
  const regex = new RegExp(`^${escapedValue}`, 'i');
  return data.filter(d => regex.test(d.name));
};

const SearchFieldV2 = ({
  className,
  data,
  defaultValue,
  placeholder,
  isClearable,
  onChange,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(defaultValue);
  const [value, setValue] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const inputRef = useRef(null);

  useEffect(() => {
    if (!value) return;
    if (value.value.length <= 0) {
      onChange(null);
    }
  }, [value]);

  const getSuggestionValue = suggestion => {
    return suggestion.name;
  };

  const handleFetchSuggestions = o => {
    setSuggestions(getSuggestions(data, o.value));
  };

  const handleClearSuggestions = () => {
    setSuggestions([]);
  };

  const handleSelectSuggestion = () => {
    onChange(value);
  };

  const handleInputChange = (e, o) => {
    const { newValue, method } = o;
    setInputValue(newValue);
    setValue({
      value: newValue,
      method,
      selected: data.find(d => d.name === newValue) || null,
    });
  };

  const handleKeyUp = e => {
    e.preventDefault();
    if (e.key === 'Enter') {
      // eg. if 'bank', match with exact suggestion find on 'enter key press'
      const match = suggestions.find(
        s => s.name.toLowerCase() === (inputValue || '').trim().toLowerCase(),
      );

      // result is either match or text return value
      const result = !match
        ? value
        : {
            value: match.name,
            method: 'match',
            selected: match,
          };
      onChange(result);
    }
  };

  const handleClearClick = () => {
    setInputValue('');
    onChange(null);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className={classNames('search-field', className)}>
      <FaSearch className="search-field__icon" alt="Search" />
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={handleFetchSuggestions}
        onSuggestionsClearRequested={handleClearSuggestions}
        onSuggestionSelected={handleSelectSuggestion}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={s => s.name}
        inputProps={{
          className: 'search-field__input',
          placeholder,
          value: inputValue,
          onChange: handleInputChange,
          onKeyUp: handleKeyUp,
          ref: inputRef,
        }}
        {...props}
      />
      {isClearable && (
        <button
          type="button"
          className={classNames('search-field__clear-btn', {
            'search-field__clear-btn--visible': inputValue.length > 0,
          })}
          onClick={handleClearClick}
        >
          <FaTimes />
        </button>
      )}
    </div>
  );
};

SearchFieldV2.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  onChange: PropTypes.func,
};

SearchFieldV2.defaultProps = {
  className: null,
  data: [],
  defaultValue: '',
  placeholder: null,
  isClearable: true,
  onChange: PropTypes.func,
};

export default SearchFieldV2;
