import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const TableContext = createContext([{}, () => {}]);

const initialState = {
  activeTableId: null,
  canvas: null,
  columns: [],
  header: '',
  imageBounds: {},
  rows: [],
  tableBounds: null,
  tableData: [],
  lockTable: false,
  disabledRows: [],
  activeTable: null,
  prediction: null,
  annotationIndex: null,
  existingTables: [],
  readOnly: false,
  selectionMode: false,
};

const TableContextProvider = ({ children }) => {
  const [tableContext, setTableContext] = useState(initialState);

  return (
    <TableContext.Provider value={[tableContext, setTableContext]}>
      {children}
    </TableContext.Provider>
  );
};

TableContextProvider.propTypes = {
  children: PropTypes.node,
};
TableContextProvider.defaultProps = {
  children: null,
};

const withTableContext = Child => {
  return props => (
    <TableContext.Consumer>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      {tableContext => <Child {...props} tableContext={tableContext} />}
    </TableContext.Consumer>
  );
};

export default TableContextProvider;
export { TableContext, initialState as InitialTableState, withTableContext };
