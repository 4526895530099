import React from 'react';
import PropTypes from 'prop-types';
import { Grid, GridItem } from '../../templates/Grid';

import './RateCardInfo.scss';

const RateCardInfo = ({ numberOfTiers, isShowFeatures }) => {
  const colSpanFeatures = Math.floor(12 / numberOfTiers);
  const colSpanRest = isShowFeatures ? 12 - colSpanFeatures : 12;
  return (
    <div className="rate-card-info">
      <Grid colGap="primary">
        <GridItem col={colSpanRest}>
          <hr />
          <ul>
            <li>
              One-off free 14 day trial available for your first 500 pages
            </li>
            <li>
              Instantly unlock a full subscription by providing your payment
              details
            </li>
            <li>
              Track your spend, set monthly threshold & manage your billing
            </li>
          </ul>
        </GridItem>
        {isShowFeatures && (
          <GridItem col={colSpanFeatures}>
            <hr />
            <ul>
              <li>Enterprise SLAs</li>
              <li>Enterprise solutioning</li>
              <li>Implementation support</li>
            </ul>
          </GridItem>
        )}
      </Grid>
    </div>
  );
};

RateCardInfo.propTypes = {
  numberOfTiers: PropTypes.number,
  isShowFeatures: PropTypes.bool,
};

RateCardInfo.defaultProps = {
  numberOfTiers: 3,
  isShowFeatures: true,
};

export default RateCardInfo;
