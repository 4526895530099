import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FaInfoCircle } from 'react-icons/fa';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import './TextInput.scss';

const getPlaceHolderText = (placeholder, required) => {
  if (required && placeholder) {
    return `${placeholder} (required)`;
  }

  if (required) {
    return 'Required';
  }

  return placeholder || '';
};

const TextInput = ({
  id,
  type,
  errors,
  placeholder,
  tooltip,
  tooltipClick,
  dataTip,
  forwardRef,
  ...props
}) => {
  const { label, name, required } = props;
  const fieldId = id || name;
  const errorText = errors[name] ? errors[name][errors[name].length - 1] : null;
  const placeholderText = getPlaceHolderText(placeholder || label, required);

  return (
    <div
      className={classNames('form__group', {
        'form__group--error': !!errors[name],
      })}
    >
      <input
        id={fieldId}
        className="form__input"
        type={type}
        placeholder={placeholderText}
        ref={forwardRef}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      {label ? (
        <label className="form__input-label" htmlFor={name}>
          {label}
          {required && <span className="required">*</span>}
          {tooltip && (
            <button
              className="form__tooltip-button"
              type="button"
              data-tooltip-content={dataTip || "What's this?"}
              onClick={tooltipClick}
            >
              <FaInfoCircle />
              <ReactTooltip place="top" />
            </button>
          )}
        </label>
      ) : null}
      {errorText ? (
        <span className="form__input-error">{errorText}</span>
      ) : null}
    </div>
  );
};

TextInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf([
    null,
    'text',
    'password',
    'email',
    'url',
    'tel',
    'number',
  ]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  errors: PropTypes.shape({}),
  tooltip: PropTypes.bool,
  tooltipClick: PropTypes.func,
  dataTip: PropTypes.string,
  forwardRef: PropTypes.oneOfType([
    PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

TextInput.defaultProps = {
  id: null,
  label: null,
  placeholder: null,
  value: '',
  type: 'text',
  required: false,
  disabled: false,
  errors: {},
  tooltip: false,
  tooltipClick: () => {},
  dataTip: null,
  forwardRef: null,
};

export default TextInput;
