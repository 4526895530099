/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Fragment, useState } from 'react';
import { FaRegCopy } from 'react-icons/fa';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { blinkIcon, copyToClipboard } from '../../../../../../common/helpers';
import {
  createProductRules,
  updateProductRules,
} from '../../../../store/rules/actions';

import { Button } from '../../../../../../library/atoms/Buttons';
import JsonEditor from '../../../../../UI/JsonEditor/JsonEditor';
import './_developerView.scss';

const calcHeight = data => {
  try {
    let noOfRows = 6;
    (data.conditions || []).forEach(c => {
      noOfRows += Object.keys(c).length + 2;
    });

    return 18 * noOfRows;
  } catch (err) {
    console.warn(err);
    return 500;
  }
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      createProductRules: (productId, rules) =>
        dispatch(createProductRules(productId, rules)),
      updateProductRules: (productId, rules) =>
        dispatch(updateProductRules(productId, rules)),
    },
  };
};

const DeveloperView = ({ productId, rules, dispatch }) => {
  if (!productId || !rules) return null;
  const isNew = !rules;

  const { id, ...initialRules } = rules;
  const [isReadonly, setReadonly] = useState(false);
  const [updatedRules, setUpdatedRules] = useState(initialRules);

  const handleChange = (updatesRulesJson, o, e) => {
    setUpdatedRules(updatesRulesJson);
  };

  const handleCopyClick = e => {
    e.stopPropagation();
    const json = JSON.stringify(updatedRules, null, 2);
    copyToClipboard(json);
    blinkIcon(e.currentTarget);
  };

  const handleEditCodeClick = () => {
    setReadonly(false);
  };

  const handleCancelClick = () => {
    setReadonly(true);
    setUpdatedRules(initialRules);
  };

  const handleSaveClick = () => {
    setReadonly(true);
    if (isNew) dispatch.createRule(productId, updatedRules);
    else dispatch.updateRule(productId, updatedRules);
  };

  return (
    <div className="dev-view">
      <p>
        See your task generation rules as code, and copy it over into your Sypht
        client for API-based real-time validation workflow. For more details on
        real-time validation workflow, read our documentation here:{' '}
        <a
          href="https://sypht.gitbook.io/sypht/workflows/validation#how-it-works"
          target="_blank"
          rel="noreferrer"
        >
          https://sypht.gitbook.io/sypht/workflows/validation#how-it-works
        </a>
      </p>
      <div
        className={classNames('dev-view__code', {
          'dev-view__code--readonly': isReadonly,
        })}
        onClick={handleEditCodeClick}
      >
        <div className="actions">
          <button className="actions__copy" onClick={handleCopyClick}>
            <FaRegCopy className="actions__icon" />
          </button>
        </div>
        <div className="dev-view__code-overlay" />
        <JsonEditor
          data={initialRules}
          title="invoices"
          mode="code"
          search={false}
          navigationBar={false}
          mainMenuBar={false}
          htmlElementProps={{
            style: {
              height: calcHeight(initialRules),
            },
          }}
          isReadonly={isReadonly}
          onChange={handleChange}
        />
      </div>
      <div className="dev-view__row">
        {isReadonly ? (
          <>
            {/* <Button */}
            {/*   className="dev-view__button" */}
            {/*   color="blue" */}
            {/*   variant="outline" */}
            {/*   onClick={handleEditCodeClick} */}
            {/* > */}
            {/*   Edit code */}
            {/* </Button> */}
          </>
        ) : (
          <>
            <Button
              className="dev-view__button"
              color="blue"
              variant="outline"
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
            <Button
              className="rule-form__button"
              color="blue"
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

DeveloperView.propTypes = {
  productId: PropTypes.string,
  rules: PropTypes.object,
  dispatch: PropTypes.object,
};

DeveloperView.defaultProps = {
  productId: null,
  rules: null,
  dispatch: {},
};

export default connect(null, mapDispatchToProps)(DeveloperView);
