/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { FaBan, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import './Task.scss';

const isEqual = require('react-fast-compare');

export default class Prediction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idx: null,
      order: null,
    };
  }

  componentWillMount() {
    const { instances } = this.props;
    if (this.state.idx == null) {
      const oi = instances.map((i, idx) => ({ instance: i, idx }));
      oi.sort((a, b) =>
        a.instance.bounds === null && b.instance.bounds === null
          ? 0
          : (a.instance.bounds === null) - (b.instance.bounds === null) ||
            a.instance.bounds.pageNum + a.instance.bounds.topLeft.y <=
              b.instance.bounds.pageNum + b.instance.bounds.topLeft.y
          ? -1
          : 1,
      );
      this.state.order = oi.map(si => si.idx);

      const idx = this.state.order
        .map(o => instances[o])
        .map(i => i.confidence)
        .map((x, i) => [x, i])
        .reduce((r, a) => (a[0] > r[0] ? a : r))[1];
      this.setState({ idx });
    }
  }

  componentDidUpdate = () => {
    const { needsFocus } = this.props;
    if (needsFocus && !this.hasGlobalFocus()) {
      this.navigateSelection(0);
    }
  };

  getSelectedPrediction = () => {
    return this.props.instances[this.state.order[this.state.idx]];
  };

  hasGlobalFocus = () => {
    return isEqual(this.getSelectedPrediction(), this.props.focusPrediction);
  };

  navigateSelection = offset => {
    const { setPredictionFocus, instances } = this.props;
    const { idx, order } = this.state;
    const newIdx = idx + offset;
    if (this.canNavigate(offset)) {
      this.setState({ idx: newIdx });
      setPredictionFocus(instances[order[newIdx]]);
    }
  };

  handleKeyDown = e => {
    const { setPredictionFocus } = this.props;
    let handled = true;

    if (e.key == 'ArrowLeft' || e.key == 'ArrowRight') {
      this.navigateSelection(e.key == 'ArrowLeft' ? -1 : 1);
    } else if ((e.key == 'Enter' || e.key == ' ') && this.hasGlobalFocus()) {
      e.preventDefault();
      this.confirmSelection();
    } else if (e.key == 'Escape') {
      setPredictionFocus(null);
    } else {
      handled = false;
    }

    if (handled) {
      e.stopPropagation();
    }
  };

  handleNavClick = (e, offset) => {
    e.stopPropagation();
    this.navigateSelection(offset);
  };

  canNavigate = offset => {
    const { idx, order } = this.state;
    const newIdx = idx + offset;
    return newIdx >= 0 && newIdx < order.length;
  };

  confirmSelection = () => {
    const { selectPrediction } = this.props;
    if (this.hasGlobalFocus()) {
      selectPrediction(this.getSelectedPrediction());
    }
  };

  render() {
    const { instances, focusPrediction } = this.props;
    const { idx } = this.state;
    const { setPredictionFocus } = this.props;
    const prediction = this.getSelectedPrediction();
    const hasFocus = this.hasGlobalFocus();
    const onPredictionClick = () => {
      setPredictionFocus(hasFocus ? null : prediction);
    };
    return (
      <div
        ref={r => (r && hasFocus ? r.focus() : null)}
        className={`prediction ${hasFocus ? ' focus ' : ''}${
          prediction.value == null ? 'null' : ''
        }`}
        onClick={onPredictionClick}
        onKeyDown={this.handleKeyDown}
        tabIndex="0"
      >
        <div className="value">
          {prediction.value == null ? (
            <span>
              <FaBan /> Not present
            </span>
          ) : (
            prediction.value
          )}
        </div>
        <div className="confidence">
          {prediction.confidence_norm == null
            ? ''
            : `${String(Math.round(100 * prediction.confidence_norm))}%`}
        </div>
        {instances.length > 1 ? (
          <div className="selection-nav">
            <FaChevronLeft
              onClick={e => this.handleNavClick(e, -1)}
              className={this.canNavigate(-1) ? '' : 'disabled'}
            />
            <span>{idx + 1}</span>
            <FaChevronRight
              onClick={e => this.handleNavClick(e, 1)}
              className={this.canNavigate(1) ? '' : 'disabled'}
            />
          </div>
        ) : null}
        <button className="select" onClick={() => this.confirmSelection()}>
          Confirm
        </button>
      </div>
    );
  }
}
