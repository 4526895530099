const emptyArray = [];
/**
 * Ensure thing is either null or undefined or an array and not an object, string etc
 *
 * We shouldn't need this.  Intended for subscriptions.data (which should
 * initialise with null in redux) and getSubscriptions endpoint which was
 * causing white screen in document page.  See
 * https://sypht.atlassian.net/browse/PLF-2468 .
 *
 */
export default function mustBeAnArrayIfPresent(thing) {
  if (thing === null || thing === undefined) {
    return thing;
  }
  return Array.isArray(thing) ? thing : emptyArray;
}
