import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import { ValuesLookup } from '../../../../common/lookups';
import {
  isValidAbn,
  isValidAuPostcode,
  hasValue,
} from '../../../../common/helpers/validationHelper';

import TextInput from '../../../Form/components/TextInput/TextInput';
import { SelectInput } from '../../../Form';
import './CheckoutForm.scss';

const INIT_VAL_ERRORS = {
  abn: false,
  line1: false,
  city: false,
  state: false,
  postal_code: false,
  country: false,
};

const INIT_FORM = {
  abn: '',
  line1: '',
  line2: '',
  city: '',
  state: '',
  postal_code: '',
  country: '',
};

const isAddressRequired = country => {
  return country === 'AU';
};

const isValidTaxId = (country, value) => {
  if (country === 'AU') return isValidAbn(value);
  return true;
};

const toShortAddress = address => {
  try {
    const { line1, line2, city, state, postal_code } = address;
    const optionalLine2 = !line2 ? '' : `, ${line2}`;
    return `${line1}${optionalLine2}, ${city}. ${state} ${postal_code}`;
  } catch (error) {
    console.warn(error.message);
    return null;
  }
};

const updatePaymentMethod = (paymentMethod, billingDetails) => {
  return {
    ...paymentMethod,
    billing_details: {
      ...paymentMethod.billing_details,
      address: {
        ...paymentMethod.billing_details.address,
        postal_code: billingDetails.postal_code,
      },
    },
  };
};

const CheckoutForm = ({
  company,
  countries,
  paymentMethods,
  onCountryChange,
  onSavePaymentMethod,
  showSaveButton,
  forwardRef,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState('');
  const [valErrors, setValErrors] = useState(INIT_VAL_ERRORS);
  const [billingDetails, setBillingDetails] = useState({
    ...INIT_FORM,
    abn: company.abn || '',
  });

  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const billingDetailsRef = useRef(null);
  const [isExpanded, setExpanded] = useState(!paymentMethods.length);

  const countryOptions = (countries || []).map(c => ({
    value: c.code,
    label: c.name,
  }));

  useEffect(() => {
    if (Object.values(valErrors).filter(x => x).length > 0) {
      setError('One or more fields are invalid.');
    } else {
      setError(null);
    }
  }, [valErrors]);

  const handleAbnBlur = e => {
    if (
      isValidTaxId(billingDetails.country, e.target.value) ||
      e.target.value === ''
    ) {
      setValErrors({
        ...valErrors,
        [e.target.name]: false,
      });
    } else {
      setValErrors({
        ...valErrors,
        [e.target.name]: true,
      });
    }
  };

  const handleInputBlur = e => {
    if (hasValue(e.target.value)) {
      setValErrors({
        ...valErrors,
        [e.target.name]: true,
      });
    } else {
      setValErrors({
        ...valErrors,
        [e.target.name]: false,
      });
    }
  };

  const handlePostcodeBlur = e => {
    if (isValidAuPostcode(e.target.value)) {
      setValErrors({
        ...valErrors,
        [e.target.name]: false,
      });
    } else {
      setValErrors({
        ...valErrors,
        [e.target.name]: true,
      });
    }
  };

  const handleSelectBlur = e => {
    if (!billingDetails[e.target.name]) {
      setValErrors({
        ...valErrors,
        [e.target.name]: true,
      });
    } else {
      setValErrors({
        ...valErrors,
        [e.target.name]: false,
      });
    }
  };

  const handleCardElementChange = async e => {
    if (e.error && e.error.message) {
      setError(e.error.message);
    } else {
      setError(null);
    }
  };

  const handleInputChange = e => {
    if (e.target.name === 'country') {
      onCountryChange();
    }
    if (e.target.value) {
      setValErrors({
        ...valErrors,
        [e.target.name]: false,
      });
    }
    setBillingDetails({
      ...billingDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddPaymentMethodClick = e => {
    e.preventDefault();
    if (!isExpanded) {
      if (containerRef.current && contentRef.current) {
        containerRef.current.style.height = `${contentRef.current.offsetHeight}px`;
      }
    } else {
      containerRef.current.style.height = 0;
    }
    setExpanded(expanded => !expanded);
  };

  const handleSubmit = async e => {
    if (e) {
      e.preventDefault();
    }
    const cardElement = elements.getElement(CardElement);
    const { line1, line2, city, state, postal_code, country } = billingDetails;

    // Use your card Element with other Stripe.js APIs
    let address = { country };
    if (isAddressRequired(country)) {
      address = {
        ...address,
        line1,
        line2,
        city,
        state,
        postal_code,
      };
    }

    const { paymentError, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        address,
      },
    });

    if (paymentError) {
      setError(paymentError.message);
      return false;
    }

    if (!paymentMethod) {
      const error = 'Invalid card or billing details';
      setError(error);
      return false;
    }

    // save payment method
    onSavePaymentMethod({
      paymentMethod: updatePaymentMethod(paymentMethod, billingDetails),
      companyData: {
        abn: billingDetails.abn,
        address: toShortAddress(address),
      },
    });

    // reset form
    setError(null);
    setTimeout(() => {
      cardElement.clear();
      setBillingDetails(INIT_FORM);
    }, 500);
  };

  useImperativeHandle(forwardRef, () => ({
    submitForm() {
      handleSubmit();
    },
  }));

  return (
    <div className="checkout-form">
      <div id="card_form" className="card-form">
        <div className="card-form__col">
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: '14px',
                  fontWeight: '400',
                  color: '#505050',
                  '::placeholder': {
                    color: '#505050',
                    fontWeight: '300',
                  },
                },
                invalid: {
                  color: '#9e2146',
                },
              },
            }}
            onChange={handleCardElementChange}
          />
        </div>
        <div className="card-form__col">
          <div
            className={classNames('card-form__billing-details', {
              expanded: isAddressRequired(billingDetails.country),
            })}
          >
            <div className="card-form__billing-section">
              <SelectInput
                id="country"
                className="select--creatable"
                label="Country"
                name="country"
                placeholder="Country (required)"
                errors={valErrors}
                value={billingDetails.country}
                onBlur={handleSelectBlur}
                onChange={handleInputChange}
                options={countryOptions}
              />
              {billingDetails.country === 'AU' && !company.abn && (
                <TextInput
                  id="abn"
                  className="card-form__input"
                  label="ABN"
                  name="abn"
                  type="text"
                  placeholder="ABN eg. 16627246039"
                  errors={valErrors}
                  value={billingDetails.abn}
                  onBlur={handleAbnBlur}
                  onChange={handleInputChange}
                />
              )}
            </div>
            {isAddressRequired(billingDetails.country) && (
              <div className="card-form__billing-section">
                <TextInput
                  id="line1"
                  className="card-form__input"
                  label="Address Line 1"
                  name="line1"
                  type="text"
                  placeholder="Address Line 1"
                  errors={valErrors}
                  value={billingDetails.line1}
                  required
                  onBlur={handleInputBlur}
                  onChange={handleInputChange}
                />
                <TextInput
                  id="line2"
                  className="card-form__input"
                  label="Address Line 2"
                  name="line2"
                  type="text"
                  placeholder="Address Line 2"
                  value={billingDetails.line2}
                  onChange={handleInputChange}
                />
                <TextInput
                  id="city"
                  className="card-form__input"
                  label="City"
                  name="city"
                  type="text"
                  placeholder="City"
                  errors={valErrors}
                  value={billingDetails.city}
                  required
                  onBlur={handleInputBlur}
                  onChange={handleInputChange}
                />
                {billingDetails.country === 'AU' ? (
                  <>
                    <SelectInput
                      id="state"
                      className="select--creatable"
                      label="State"
                      name="state"
                      placeholder="State (required)"
                      errors={valErrors}
                      value={billingDetails.state}
                      onBlur={handleSelectBlur}
                      onChange={handleInputChange}
                      options={ValuesLookup.AU_STATES}
                    />
                    <TextInput
                      id="postal_code"
                      className="card-form__input"
                      label="Postcode"
                      name="postal_code"
                      type="text"
                      placeholder="Postcode"
                      pattern="\d*"
                      maxLength="4"
                      errors={valErrors}
                      value={billingDetails.postal_code}
                      required
                      onBlur={handlePostcodeBlur}
                      onChange={handleInputChange}
                    />
                  </>
                ) : (
                  <>
                    <TextInput
                      id="state"
                      className="card-form__input"
                      label="State"
                      name="state"
                      type="text"
                      placeholder="State"
                      errors={valErrors}
                      value={billingDetails.state}
                      required
                      onBlur={handleInputBlur}
                      onChange={handleInputChange}
                    />
                    <TextInput
                      id="postal_code"
                      className="card-form__input"
                      label="Postcode"
                      name="postal_code"
                      type="text"
                      placeholder="Postcode"
                      errors={valErrors}
                      value={billingDetails.postal_code}
                      required
                      onBlur={handleInputBlur}
                      onChange={handleInputChange}
                    />
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="card-form__row">
          {error && <div className="modal-checkout-error">{error}</div>}
          {showSaveButton && (
            <button
              type="submit"
              className={classNames('checkout-form__btn', {
                'checkout-form__btn--disabled': !!error,
              })}
              disabled={!!error}
              onClick={handleSubmit}
            >
              Save
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

CheckoutForm.propTypes = {
  company: PropTypes.object.isRequired,
  countries: PropTypes.array,
  paymentMethods: PropTypes.array,
  onCountryChange: PropTypes.func.isRequired,
  onSavePaymentMethod: PropTypes.func.isRequired,
  showSaveButton: PropTypes.bool,
  forwardRef: PropTypes.object,
};

CheckoutForm.defaultProps = {
  paymentMethods: [],
  countries: [],
  showSaveButton: true,
  forwardRef: null,
};

export default CheckoutForm;
