import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Button.scss';

const Button = ({
  type,
  large,
  small,
  narrow,
  disabled,
  className,
  style,
  children,
  forwardRef,
  ...props
}) => (
  <button
    type={type}
    className={classNames(
      className,
      'button',
      // the below change prevents 'button-button' etc. classes
      type !== ('submit' && 'button' && 'reset') ? `button-${type}` : null,
      large ? 'button-lg' : null,
      small ? 'button-sm' : null,
      disabled && 'disabled',
      narrow && 'button-narrow',
    )}
    ref={forwardRef}
    {...{ disabled, style }}
    {...props}
  >
    {children}
  </button>
);

Button.propTypes = {
  // notes: see notes at the bottom of this file.
  // eslint-disable-next-line react/require-default-props
  type: PropTypes.oneOf([
    'primary',
    'primary-error',
    'secondary-border',
    'text',
    'text-alt',
    'icon',
    'button',
    'submit',
    'reset',
  ]),
  disabled: PropTypes.bool,
  large: PropTypes.bool,
  small: PropTypes.bool,
  narrow: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.any,
  forwardRef: PropTypes.object,
};

Button.defaultProps = {
  type: 'primary',
  disabled: false,
  large: true,
  small: false,
  narrow: false,
  style: {},
  className: '',
  children: null,
  forwardRef: null,
};

export default Button;

/** type:
 * This prop should only have 'button, type, reset' as possible values as the
 * 'type' prop for buttons is reserved. Effort to should be spent to refactor
 * it's current use (eg. type=primary) to something like 'buttonType=primary'.
 * Not doing will lead to some weird submit bugs on click.
 */
