import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card, { CardContent, CardHeader } from '../../../UI/Card';
import { DataTable } from '../../../UI/Table';
import './SubscriptionStats.scss';

const setColumns = (mode = 'usage') => {
  return [
    {
      Header: 'AI Product',
      accessor: 'name',
    },
    {
      Header: mode === 'usage' ? '# of Pages Extracted' : '$ Total Spend',
      headerClassName: '',
      sortable: false,
      // eslint-disable-next-line react/prop-types
      Cell: ({ original: o }) => {
        return (
          <div className="total">
            {mode === 'usage' ? o.totalUsage : `$ ${o.totalSpend.toFixed(2)}`}
          </div>
        );
      },
    },
  ];
};

const mapStateToProps = state => {
  const { analytics } = state;
  return {
    subscriptionStats: analytics.data.stats,
  };
};

const SubscriptionStats = ({ subscriptionStats, title, mode, isVisible }) => {
  if (!subscriptionStats) return null;
  const columns = setColumns(mode);
  return (
    <Card
      className={classNames('subscription-stats', {
        visibility: isVisible,
      })}
    >
      <CardHeader>
        <h2 className="card__heading">{title}</h2>
        <h2 className="card__heading">{subscriptionStats[0].billingPeriod}</h2>
      </CardHeader>
      <CardContent>
        <DataTable
          className="subscription-stats__table"
          data={subscriptionStats}
          columns={columns}
          sortBy="name"
          role="button"
          defaultPageSize={100}
          showPageSizeOptions={false}
          showPagination={false}
        />
      </CardContent>
    </Card>
  );
};

SubscriptionStats.propTypes = {
  subscriptionStats: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

SubscriptionStats.defaultProps = {
  subscriptionStats: null,
  title: 'Active subscriptions',
};

export default connect(mapStateToProps)(SubscriptionStats);
