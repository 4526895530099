import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './_dropdownMenu.scss';

const DropdownMenu = ({ className, children, isOpen, ...props }) => {
  return (
    <div
      className={classNames('dropdown-menu', className, {
        'dropdown-menu--open': isOpen,
      })}
      {...props}
    >
      <div className="dropdown-menu__inner">{children}</div>
    </div>
  );
};

DropdownMenu.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
};

DropdownMenu.defaultProps = {
  children: null,
  className: null,
  isOpen: false,
};

export default DropdownMenu;
