import React from 'react';
import PropTypes from 'prop-types';
import SpendSignal from './SpendSignal';
import FraudSignal from './FraudSignal';
import DuplicateSignal from './DuplicateSignal';

/**
 * Processes incoming signals data to render the Signals component.
 */
const SignalsWrapper = ({ predictions }) => {
  if (!predictions) {
    return null;
  }
  const fieldsArray = Object.values(predictions).flat();
  if (!fieldsArray) {
    return null;
  }
  const byId = fieldsArray.reduce(
    (acc, fieldset) => ({ ...acc, [fieldset.id]: fieldset }),
    {},
  );
  const signalsFields = [];

  if (byId['sypht.signals.spend']) {
    const {
      count: docs,
      percentile_rank: percentileRank,
      min,
      max,
      avg,
    } = byId['sypht.signals.spend'].value_norm;
    signalsFields.push(
      <SpendSignal
        key="signals.spend"
        extractedDocs={docs}
        anomalyPercentileRank={percentileRank}
        averageSignal={avg}
        rangeLowSignal={min}
        rangeHightSignal={max}
      />,
    );
  }
  if (byId['sypht.signals.fraud']) {
    signalsFields.push(
      <FraudSignal
        key="signals.fraud"
        fraudChance={byId['sypht.signals.fraud'].value_norm || 0}
        confidence={byId['sypht.signals.fraud'].confidence_norm || 0}
      />,
    );
  }
  if (byId['sypht.signals.duplicate']) {
    signalsFields.push(
      <DuplicateSignal
        key="signals.duplicate"
        documents={byId['sypht.signals.duplicate'].value_norm}
      />,
    );
  }
  if (byId['sypht.ndis.duplicate']) {
    signalsFields.push(
      <DuplicateSignal
        key="signals.ndis.duplicate"
        documents={byId['sypht.ndis.duplicate'].value_norm}
      />,
    );
  }

  return signalsFields;
};

SignalsWrapper.propTypes = {
  predictions: PropTypes.shape({}),
};

SignalsWrapper.defaultProps = {
  predictions: undefined,
};

export default SignalsWrapper;
