/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { Product, ProductList } from './components';

import '../UI/Tile/Tile.scss';
import './Marketplace.scss';
import Toolbar from '../Toolbar/Toolbar';

const mapStateToProps = state => {
  const { auth, router } = state;

  return {
    location: router.location,
    scopes: auth.claims.allowedScopes || [],
    user: auth.user ? auth.user.data : null,
  };
};

const MarketplaceCatalog = ({ location, scopes, user }) => {
  return (
    <div className="marketplace__public">
      <Toolbar />
      <Switch location={location}>
        <Route
          exact
          path="/marketplace/catalog"
          render={props => <ProductList {...props} isPublic />}
          defaultTo="/marketplace/catalog"
        />
        <Route
          exact
          path="/marketplace/catalog/:id"
          render={props => <Product {...props} isPublic />}
          defaultTo="/marketplace/catalog"
        />
      </Switch>
    </div>
  );
};

MarketplaceCatalog.propTypes = {
  location: PropTypes.object,
  scopes: PropTypes.any,
  user: PropTypes.object,
};

MarketplaceCatalog.defaultProps = {
  location: null,
  scopes: null,
  user: null,
};

export default connect(mapStateToProps)(MarketplaceCatalog);
