import React from 'react';

const getHighlights = ({
  genericObj,
  genericEntities,
  handleHighlightClick,
  doc,
}) => {
  const predictions = [];

  if (doc && doc.productFieldMapping) {
    Object.values(doc.productFieldMapping).forEach(fieldsets => {
      fieldsets.forEach(field => predictions.push(field));
    });
  }

  const genericHighlights = genericObj.flatMap(elem => {
    const arr = [];

    Object.entries(elem).map(([field, value]) => {
      if (field === 'value') {
        // map values first : this might change in the future
        value.fields.map(f => {
          arr.push({
            field: f.label,
            value: f.value,
            bounds: value.entities[f.value].bounds,
            type: 'generic.field',
            uid: `${f.field_key}:${f.label}`,
            // null:field.label.head:product:0:4
            //  null.field.label.head:product:0:4
          });
        });
      }
    });

    return arr;
  });
  const setPaddingByBoundingType = boundingType => {
    return boundingType === 'table-bounds' || boundingType === 'header-bounds'
      ? 0.015
      : 0.005;
  };

  const highlights = genericEntities
    .concat(predictions, genericHighlights)
    .filter(p => p.bounds)
    .map(p => {
      return {
        bounds: p.bounds,
        padding: setPaddingByBoundingType(p.type),
        className: p.boundingType || 'tipped',
        onClick: e => handleHighlightClick(e, p),
        content: (
          <div className="popover-container">
            <div className="popover-content">{p.field || 'null'}</div>
          </div>
        ),
      };
    });

  return highlights;
};

export default getHighlights;
