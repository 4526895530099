import {
  REQUEST_COMPANY_TAGS,
  RECEIVE_COMPANY_TAGS,
  REQUEST_FILTER_TAGS,
  RECEIVE_FILTER_TAGS,
  REQUEST_APPLY_TAGS,
  RECEIVE_APPLY_TAGS,
  REQUEST_SET_DOC_TAGS,
  RECEIVE_SET_DOC_TAGS,
  REQUEST_DOC_TAGS,
  RECEIVE_DOC_TAGS,
  REQUEST_CREATE_COMPANY_TAG,
  RECEIVE_CREATE_COMPANY_TAG,
  REQUEST_UPDATE_TASK_TAGS,
  REQUEST_UPDATE_DOC_TAGS,
  RECEIVE_UPDATE_DOC_TAGS,
} from './action';

const initialState = {
  companyTags: {
    data: null,
    total: 0,
    loading: true,
  },
  filterTags: {
    data: null,
    loading: true,
  },
  applyTags: {
    data: null,
    loading: true,
  },
  docTags: {
    data: null,
    loading: true,
  },
};

const tagReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_COMPANY_TAGS:
      return {
        ...state,
        companyTags: {
          ...state.companyTags,
          loading: true,
        },
      };
    case RECEIVE_COMPANY_TAGS:
      return {
        ...state,
        companyTags: {
          data: payload.data.results,
          total: payload.data.total,
          loading: false,
        },
      };
    case REQUEST_FILTER_TAGS:
      return {
        ...state,
        filterTags: {
          ...state.filterTags,
          loading: true,
        },
      };
    case RECEIVE_FILTER_TAGS:
      return {
        ...state,
        filterTags: {
          data: payload.data.results,
          loading: false,
        },
      };
    case REQUEST_APPLY_TAGS:
      return {
        ...state,
        applyTags: {
          ...state.applyTags,
          loading: true,
        },
      };
    case RECEIVE_APPLY_TAGS:
      return {
        ...state,
        applyTags: {
          data: payload.data.results,
          loading: false,
        },
      };
    case REQUEST_SET_DOC_TAGS:
      return {
        ...state,
        docTags: {
          ...state.docTags,
          loading: true,
        },
      };
    case RECEIVE_SET_DOC_TAGS:
      return {
        ...state,
        docTags: {
          data: payload.data,
          loading: false,
        },
      };
    case REQUEST_UPDATE_DOC_TAGS:
      return {
        ...state,
        docTags: {
          ...state.docTags,
          loading: true,
        },
      };
    case RECEIVE_UPDATE_DOC_TAGS:
      return {
        ...state,
        docTags: {
          data: payload.data,
          loading: false,
        },
      };
    case REQUEST_DOC_TAGS:
      return {
        ...state,
        docTags: {
          ...state.docTags,
          loading: true,
        },
      };
    case RECEIVE_DOC_TAGS:
      return {
        ...state,
        docTags: {
          data: payload.data,
          loading: false,
        },
      };
    case REQUEST_CREATE_COMPANY_TAG:
      return {
        ...state,
        applyTags: {
          ...state.applyTags,
          loading: true,
        },
      };
    case RECEIVE_CREATE_COMPANY_TAG:
      return {
        ...state,
        applyTags: {
          ...state.applyTags,
          loading: false,
        },
      };
    case REQUEST_UPDATE_TASK_TAGS:
      return {
        ...state,
        taskTags: {
          ...state.taskTags,
          loading: true,
        },
      };
    default:
      return state;
  }
};

export default tagReducer;
