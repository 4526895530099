import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Page, { PageBody, PageHeader, PageInner, PageNav } from '../../../Page';
import RuleForm from './components/RuleForm/RuleForm';
import { getQueryStr } from '../../../../common/helpers';

const mapStateToProps = (state, props) => {
  const { history } = props;
  const productId = getQueryStr(history.location.search, 'product');

  return {
    productId,
  };
};

const CreateRule = ({ productId, history }) => {
  useEffect(() => {
    if (history.location.state) {
      history.replace();
    }
  }, []);

  return (
    <Page className="rules" title="Validate - Create Rule">
      <PageInner>
        <PageNav
          items={[
            {
              label: 'Automatic generation of review tasks',
              to: '/validate/rules',
            },
            { label: 'Create rule', to: `/validate/rules/create` },
          ]}
        />
        <PageHeader className="rules__header">
          <h1 className="page__heading">Create a new task generation rule</h1>
        </PageHeader>
        <PageBody>
          <RuleForm productId={productId} rule={null} />
        </PageBody>
      </PageInner>
    </Page>
  );
};

CreateRule.propTypes = {
  productId: PropTypes.string,
  history: PropTypes.object,
};

CreateRule.defaultProps = {
  productId: null,
  history: {
    push: () => {},
  },
};

export default connect(mapStateToProps)(CreateRule);
