/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types,no-restricted-syntax */
import React, { useContext, useMemo, forwardRef } from 'react';
import classNames from 'classnames';
import { FaTable } from 'react-icons/fa';
import { getTable, getTableData } from '../../../utils/getLegacyTableData';
import {
  TableContext,
  InitialTableState,
} from '../../../../DocumentView/TableContext';

const LineItems = forwardRef(
  ({ field, focusAnnotationTbl, annotationTblClicked, openTable }, ref) => {
    const [, setTableContext] = useContext(TableContext);
    const tableData = field?.value;
    const selectedTblKey = (focusAnnotationTbl || {}).key;
    const tableBoundsData = useMemo(
      () => tableData.map(t => getTableData(t)),
      [tableData],
    );

    const handleClick = (key, k) => {
      // close table if it is opened
      if (key === selectedTblKey) {
        setTableContext(InitialTableState);
        annotationTblClicked(null, k);
        return;
      }

      // doc view highlighting
      setTableContext(context => ({
        ...context,
        activeTable: tableBoundsData[k],
        pageId: '1',
        selectionMode: false,
        lockTable: true,
        readOnly: true,
      }));

      // auto-scroll highlight
      openTable(tableBoundsData[k]);

      // transform (legacy) lineitems to lineitems:2
      annotationTblClicked(
        {
          key,
          table: getTable(tableData[k]),
        },
        k,
      );
    };

    const renderLineItemsSidebar = () => {
      const lineItems = field.value;
      const uniquePrefix = `${field.fieldset}-${field.fieldName}-`;
      const sidebarArr = [];

      if (lineItems.length === 0) {
        sidebarArr.push(
          <span
            style={{ padding: '10px 40px' }}
            className="field lineitem-entry"
            key={uniquePrefix}
          >
            No Tables Found
          </span>,
        );
      } else {
        // eslint-disable-next-line no-unused-vars
        for (const [k] of lineItems.entries()) {
          const tblId = uniquePrefix + k;
          const key = `${field.id}_${k}`;

          sidebarArr.push(
            <span
              style={{ padding: '10px 40px' }}
              className={classNames('field', 'lineitem-entry', {
                focus: selectedTblKey === key,
              })}
              onClick={() => handleClick(key, k)}
              key={tblId}
            >
              <FaTable />
              <span>Table {k + 1}</span>
            </span>,
          );
        }
      }

      return [sidebarArr];
    };

    return (
      <>
        <div ref={ref} className={classNames('field', 'lineitem-header')}>
          <h3>{field.fieldName}</h3>
        </div>
        <div className="lineitem-content">{renderLineItemsSidebar()}</div>
      </>
    );
  },
);

export default LineItems;
