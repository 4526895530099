/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Grid.scss';

const Grid = ({ colGap, rowGap, children }) => {
  return (
    <div
      className={classNames('grid-container', {
        'col-gap--primary': colGap === 'primary',
        'col-gap--secondary': colGap === 'secondary',
        'col-gap--none': colGap === 'none',
        'row-gap--primary': rowGap === 'primary',
        'row-gap--secondary': rowGap === 'secondary',
        'row-gap--none': rowGap === 'none',
      })}
    >
      {children}
    </div>
  );
};

Grid.propTypes = {
  colGap: PropTypes.oneOf(['primary', 'secondary', 'none']),
  rowGap: PropTypes.oneOf(['primary', 'secondary', 'none']),
  children: PropTypes.node,
};

Grid.defaultProps = {
  colGap: 'none',
  rowGap: 'none',
  children: null,
};

export default Grid;
