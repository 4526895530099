import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { CancelToken } from 'axios';
import {
  setQueryParams,
  encodeSortQueryParam,
  decodeSortQueryParam,
} from '../../common/helpers';
import { clearTasks, fetchTasks } from '../../reducers/tasks';
import history from '../../services/historyService';
import { DynamicTable } from '../../library/organisms/Table';
import Page, { PageBody, PageHeader, PageInner } from '../../components/Page';

import taskColumns from './columns';
import { getTargetPath } from './helpers';
import useTasksFilters from './hooks/useTasksFilters';
import TasksFilters, { InitialFilters } from './components/Filters';
import TasksMenu from './components/TasksMenu';
import './_tasks.scss';

const PAGE_SIZE = 10;
/**
 * This default sort should be used when fetching requests if no sort is
 * specified in the url.
 */
const DEFAULT_SORT = [{ id: 'created', desc: true }];
const SORTABLE_FIELDS = taskColumns.reduce((acc, col) => {
  if (!col.disableSortBy && col.accessor) {
    acc[col.accessor] = col;
  }
  return acc;
}, {});

/**
 * Parse the query params for task list page.
 *
 * FIXME: use react-router's location object instead of history.
 * However, the react-router location object doesn't set "search" properly if
 * you navigated to tasks page from the LHS navbar.
 * See https://reactrouter.com/web/api/location
 * Maybe this? https://stackoverflow.com/questions/51903725/react-router-props-location-match-not-updating-with-connectedrouter/51903726
 *
 * @param {*} tasksData
 * @param {*} search
 * @returns
 */
const getQueryParams = tasksData => {
  const { search } = history.location;
  const params = new URLSearchParams(search);
  const page = Number(params.get('page')) || 0;
  const sortBy = decodeSortQueryParam(params.get('sortBy'));
  const sortIsValid = sortBy?.id && SORTABLE_FIELDS[sortBy.id];
  return {
    // If the sort is not valid, ignore it and use DEFAULT_SORT when talking to
    // server.
    sortBy: sortIsValid ? [sortBy] : DEFAULT_SORT,
    page:
      (tasksData && tasksData.currentPage) ||
      // Convert to 0-based and map <0 to 0 (first page).
      Math.max(0, page - 1),
  };
};

const mapStateToProps = state => {
  const { auth, tasks } = state;
  const { data: tasksData, loading } = tasks;
  const queryParams = getQueryParams(tasksData);

  return {
    companyId: auth.user.data ? auth.user.data.companyId : null,
    userId: auth.user.data ? auth.user.data.id : null,
    tasks: tasksData?.tasks,
    pageIndex: queryParams.page,
    pageCount: tasksData?.numPages,
    lastRequest: tasks.lastRequest,
    loading,
    initialSortBy: queryParams.sortBy || DEFAULT_SORT,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch: {
    fetchTasks: (filters, cancelToken) => {
      dispatch(fetchTasks(filters, cancelToken));
    },
    clearTasks: () => {
      dispatch(clearTasks());
    },
  },
});

const Tasks = ({
  companyId,
  userId,
  tasks,
  pageIndex,
  pageCount,
  dispatch,
  loading,
  initialSortBy,
}) => {
  const [filters, setFilters] = useTasksFilters(InitialFilters, userId);
  const [selectedTasks, setSelectedTasks] = useState([]);
  // const [isLoading, setLoading] = useState(false);
  const tableRef = useRef();
  const cancelToken = useRef(CancelToken.source());

  /**
   * On mount and unmount...
   */
  useEffect(() => {
    cancelToken.current = CancelToken.source();
    return () => {
      if (cancelToken.current) cancelToken.current.cancel('Cancelled request');
      // Clear out tasks and currentPage, this ensures pageIndex is reset to 0
      // if user comes back in the same browser session. (PLF-1859)
      dispatch.clearTasks();
    };
    // dispatch should be stable
  }, [dispatch]);

  /**
   * Record filters when they're fetched.
   *
   * Temporary hack to fix pagination problem - PLF-1841.
   * FIXME: We need to straighten out filter state on the tasks page.
   */
  const fetchedFilters = useRef(null);

  const handleFetchData = useCallback(
    ({ index, limit, sortBy, filters: newFilters }) => {
      // if (isLoading) return;
      // if (isReady)
      // setLoading(true);
      cancelToken.current.cancel('Cancelled request');
      cancelToken.current = CancelToken.source();
      setQueryParams({
        ...newFilters,
        page: index + 1,
        sortBy: encodeSortQueryParam(sortBy[0]),
      });
      fetchedFilters.current = newFilters;
      dispatch.fetchTasks(
        {
          offset: index * limit,
          limit,
          sortBy,
          filters: newFilters,
        },
        cancelToken.current,
      );
    },
    [dispatch],
  );

  const handleFilterChange = useCallback(
    (newFilters = filters) => {
      if (!isEqual(newFilters, fetchedFilters.current)) {
        tableRef.current.setFilters(newFilters);
        if (fetchedFilters.current) {
          // The first change to filters is the initial load.  It may be for any
          // page which could be specified in the URL.  If the filters change
          // subsequently (just the filters), reset the page index to 0 (page 1).
          tableRef.current.gotoPage(0);
        }
      }
    },
    [filters],
  );

  // runs after modal actions
  const handleActionCompleted = () => {
    tableRef.current.toggleAllRowsSelected(false);
    tableRef.current.gotoPage(0);
    handleFilterChange();
  };

  //runs after tags have been added/removed
  const handleTagDropdownConfirm = () => {
    
    const {page, limit, sortBy, filters: taskFilters} = tableRef.current; 
    dispatch.fetchTasks({
      offset: page * limit,
      limit,
      sortBy,
      filters: taskFilters,
    });
  }

  // e: Event, task: Object{...row.original} }>
  const handleRowClick = (e, task) => {
    const targetPath = getTargetPath(task, userId);
    if (e.metaKey || e.ctrlKey) {
      window.open(targetPath, '_blank');
    } else {
      history.push(targetPath);
    }
  };

  // rows: Array<{ id, original, rowIndex }>
  const handleRowSelect = rows => {
    const taskIds = rows.map(r => r.id);
    setSelectedTasks(taskIds);
  };

  const handleTagClick = useCallback(
    (evt, tag) => {
      evt.stopPropagation();
      const filterQuery = { ...filters, tags: [tag.name] };
      setFilters(filterQuery);
      tableRef.current.setFilters(filterQuery);
      tableRef.current.gotoPage(0);
    },
    [filters, setFilters],
  );

  return (
    companyId && (
      <Page className="tasks" layout="full-width">
        <PageInner>
          <PageHeader className="tasks__header">
            <h1>Tasks</h1>
            <TasksMenu
              companyId={companyId}
              filters={tableRef.current?.filters || filters}
              sortBy={tableRef.current?.sortBy || initialSortBy}
              selectedTasks={selectedTasks}
              onActionCompleted={handleActionCompleted}
              onTagDropdownConfirm={handleTagDropdownConfirm}
            />
          </PageHeader>
          <PageBody>
            <TasksFilters
              filters={filters}
              onFilterChange={handleFilterChange}
            />
            <DynamicTable
              className="tasks-table"
              columns={taskColumns}
              data={tasks}
              initialFilters={filters}
              initialSortBy={initialSortBy}
              disableMultiSort
              disableSortRemove
              pageIndex={pageIndex}
              pageSize={PAGE_SIZE}
              pageCount={pageCount}
              isLoading={loading}
              selectedRows={selectedTasks}
              onFetchData={handleFetchData}
              forwardRef={tableRef}
              handlers={{
                onRowClick: handleRowClick,
                onRowSelect: handleRowSelect,
                onTagClick: handleTagClick,
              }}
            />
          </PageBody>
        </PageInner>
        <ReactTooltip
          id="tasks-tooltip"
          className="tooltip"
          place="bottom"
          variant="dark"
        />
      </Page>
    )
  );
};

Tasks.propTypes = {
  companyId: PropTypes.string,
  userId: PropTypes.string,
  tasks: PropTypes.arrayOf(PropTypes.shape({})),
  pageIndex: PropTypes.number,
  pageCount: PropTypes.number,
  dispatch: PropTypes.shape({
    fetchTasks: PropTypes.func,
    clearTasks: PropTypes.func,
  }),
  loading: PropTypes.bool,
  /**
   * Passed as initial sort state to DynamicTable (react-table (useSortBy
   * plugin)).  Thereafter sortBy state is controlled by useSortBy in
   * DynamicTable.
   *
   * NOTE: this prop may update when the url updates because useSortBy has
   * detected the user changing the sort and this component currently updates
   * the URL *after* such a change is made, but the intention is that it is just
   * there for the initial page load.
   */
  initialSortBy: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      desc: PropTypes.bool.isRequired,
    }),
  ),
};

Tasks.defaultProps = {
  companyId: null,
  userId: null,
  tasks: null,
  pageIndex: 0,
  pageCount: 1,
  dispatch: {
    fetchTasks: () => {},
    clearTasks: () => {},
  },
  loading: false,
  initialSortBy: undefined,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
