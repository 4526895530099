import React from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import PropTypes from 'prop-types';

const DeleteCell = ({ column, row, handlers }) => {
  const handleClick = () => {
    handlers.onDeleteRow(row.original.id, row.original, row.index, column.id);
  };

  return (
    <button type="button" title="Delete row" onClick={handleClick}>
      <FaTrashAlt />
    </button>
  );
};

DeleteCell.propTypes = {
  column: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  handlers: PropTypes.object,
};

DeleteCell.defaultProps = {
  handlers: {
    onDeleteRow: () => {},
  },
};

export default DeleteCell;
