import LookupService from '../../services/LookupService';

export const REQUEST_LOOKUP_VALUES = 'sypht/lookups/REQUEST_LOOKUP_VALUES';
export const RECEIVE_LOOKUP_VALUES = 'sypht/lookups/RECEIVE_LOOKUP_VALUES';

export const LookupTypes = {
  Countries: 'countries',
};

const requestLookupValues = lookupType => {
  return {
    type: REQUEST_LOOKUP_VALUES,
    payload: {
      lookupType,
      loading: true,
    },
  };
};

const receiveLookupValues = (lookupType, data) => {
  return {
    type: RECEIVE_LOOKUP_VALUES,
    payload: {
      lookupType,
      data,
      loading: false,
      receivedAt: Date.now(),
    },
  };
};

export const fetchCountries = () => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;

    dispatch(requestLookupValues(LookupTypes.Countries));
    const response = await LookupService.getCountries(accessToken);
    dispatch(receiveLookupValues(LookupTypes.Countries, response.data));
  };
};
