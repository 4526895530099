import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Dropdown from '../../../../../UI/DropdownV2';
import AccuracySlider from './types/AccuracySlider';
import MinMax from './types/MinMax';
import MissingField from './types/MissingField';
import './_rule-picker.scss';

const DEFAULT_RULE = {
  type: undefined,
};

const RulePicker = ({ defaultRule, options, valCounter, onChange }) => {
  if (!options) return null;

  const [rule, setRule] = useState(defaultRule || DEFAULT_RULE);

  useEffect(() => {
    onChange(rule);
  }, [rule]);

  const handleRuleTypeChange = type => {
    setRule({
      type: (type || {}).value,
    });
  };

  const onValuesChange = newValues => {
    setRule({
      ...rule,
      ...newValues,
    });
  };

  return (
    <div className="rule-picker">
      <div className="rule-picker__row">
        <Dropdown
          id="selected_rule_type"
          className="rule-picker__rule-type"
          label="Rule condition type"
          options={options}
          defaultValue={options.find(o => o.value === rule.type)}
          onChange={handleRuleTypeChange}
          isClearable
        />
      </div>
      <div className="rule-picker__row">
        <>
          {
            {
              'field-confidence-range': (
                <AccuracySlider
                  min={1}
                  max={100}
                  step={1}
                  defaultValue={rule.min ? Math.round(rule.min * 100) : 50}
                  onChange={onValuesChange}
                />
              ),
              'field-present': <MissingField />,
              'field-value-range': (
                <MinMax
                  defaultValue={{
                    min: rule.min,
                    max: rule.max,
                  }}
                  valCounter={valCounter}
                  onChange={onValuesChange}
                />
              ),
            }[rule.type]
          }
        </>
      </div>
    </div>
  );
};

RulePicker.propTypes = {
  defaultRule: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object),
  valCounter: PropTypes.number,
  onChange: PropTypes.func,
};

RulePicker.defaultProps = {
  defaultRule: DEFAULT_RULE,
  options: null,
  valCounter: 0,
  onChange: () => {},
};

export default RulePicker;
