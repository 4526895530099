import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  FaCopy,
  FaTasks,
  FaToolbox,
  FaStore,
  FaChartBar,
  FaUsersCog,
} from 'react-icons/fa';
import { withRouter, NavLink } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PropTypes from 'prop-types';
import './Sidebar.scss';

const mapStateToProps = state => {
  const { auth } = state;
  return {
    isAuthenticated: auth.isAuthenticated,
    user: auth.user.data,
    canReviewTasks: auth.permissions.canReviewTasks,
    canAdministrateDocs: auth.permissions.canAdministrateDocs,
    canSuperUserAny: auth.permissions.canSuperUserAny,
    canReadAnalytics: auth.permissions.canReadAnalytics,
    canReadTaskAnalytics: auth.permissions.canReadTaskAnalytics,
    canViewMarketplace: auth.permissions.canViewMarketplace,
  };
};
const Sidebar = ({
  isAuthenticated,
  user,
  canReviewTasks,
  canAdministrateDocs,
  canSuperUserAny,
  canReadAnalytics,
  canReadTaskAnalytics,
  canViewMarketplace,
}) => {
  const isLocationAtDocumentsSplit = location =>
    canReviewTasks && location.pathname.startsWith('/documents/split');
  const isLocationAtDocuments = location =>
    location.pathname.startsWith('/documents') &&
    !isLocationAtDocumentsSplit(location);
  const isLocationAtTasks = location =>
    location.pathname.startsWith('/tasks') ||
    isLocationAtDocumentsSplit(location);

  return (
    <>
      {isAuthenticated && (
        <div className="sidebar-wrapper-after">
          <div className="sidebar">
            <div className="sidebar-heading" />
            {user ? (
              <>
                <div className="list-group">
                  {(canReadAnalytics || canReadTaskAnalytics) && (
                    <span data-tooltip-id="analytics-button-tooltip">
                      <NavLink
                        className="nav-btn"
                        activeClassName="active"
                        to="/analytics"
                      >
                        <FaChartBar />
                        <span>Analytics</span>
                      </NavLink>
                      <ReactTooltip
                        id="analytics-button-tooltip"
                        content="Analytics"
                        place="right"
                        style={{
                          top: '4',
                          bottom: '4',
                          left: '4',
                          position: 'fixed',
                          zIndex: 9999,
                        }}
                      />
                    </span>
                  )}
                  {canAdministrateDocs ? (
                    <span data-tooltip-id="docs-button-tooltip">
                      <NavLink
                        className="nav-btn"
                        activeClassName="active"
                        to="/documents"
                        isActive={(match, location) =>
                          isLocationAtDocuments(location)
                        }
                        // eslint-disable-next-line react/jsx-props-no-spreading
                      >
                        <FaCopy />
                        <span>Docs</span>
                      </NavLink>
                      <ReactTooltip
                        id="docs-button-tooltip"
                        content="Docs"
                        place="right"
                        style={{
                          top: '4',
                          bottom: '4',
                          left: '4',
                          position: 'fixed',
                          zIndex: 9999,
                        }}
                      />
                    </span>
                  ) : null}
                  {canViewMarketplace && (
                    <span data-tooltip-id="marketplace-button-tooltip">
                      <NavLink
                        className="nav-btn"
                        activeClassName="active"
                        to="/marketplace"
                      >
                        <FaStore />
                        <span>Marketplace</span>
                      </NavLink>
                      <ReactTooltip
                        id="marketplace-button-tooltip"
                        content="Marketplace"
                        place="right"
                        style={{
                          top: '4',
                          bottom: '4',
                          left: '4',
                          position: 'fixed',
                          zIndex: 9999,
                        }}
                      />
                    </span>
                  )}
                  {canReviewTasks ? (
                    <span data-tooltip-id="tasks-button-tooltip">
                      <NavLink
                        className="nav-btn"
                        activeClassName="active"
                        isActive={(match, location) =>
                          isLocationAtTasks(location)
                        }
                        to="/tasks"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                      >
                        <FaTasks />
                        <span>Tasks</span>
                      </NavLink>
                      <ReactTooltip
                        id="tasks-button-tooltip"
                        content="Tasks"
                        place="right"
                        style={{
                          top: '4',
                          bottom: '4',
                          left: '4',
                          position: 'fixed',
                          zIndex: 9999,
                        }}
                      />
                    </span>
                  ) : null}
                  {canSuperUserAny ? (
                    <span data-tooltip-id="workbench-button-tooltip">
                      <NavLink
                        className="nav-btn"
                        activeClassName="active"
                        to="/workbench"
                      >
                        <FaToolbox />
                        <span>Workbench</span>
                      </NavLink>
                      <ReactTooltip
                        id="workbench-button-tooltip"
                        content="Workbench"
                        place="right"
                        style={{
                          top: '4',
                          bottom: '4',
                          left: '4',
                          position: 'fixed',
                          zIndex: 9999,
                        }}
                      />
                    </span>
                  ) : null}
                  {canSuperUserAny ? (
                    <span data-tooltip-id="internaladmin-button-tooltip">
                      <NavLink
                        className="nav-btn"
                        activeClassName="active"
                        to="/admin"
                      >
                        <FaUsersCog />
                      </NavLink>
                      <ReactTooltip
                        id="internaladmin-button-tooltip"
                        content="Internal Admin"
                        place="right"
                        style={{
                          top: '4',
                          bottom: '4',
                          left: '4',
                          position: 'fixed',
                          zIndex: 9999,
                        }}
                      />
                    </span>
                  ) : null}
                </div>
              </>
            ) : null}
          </div>
          <ReactTooltip id="sidebar-tooltip" variant="dark" />
        </div>
      )}
    </>
  );
};

Sidebar.defaultProps = {
  user: {},
};

Sidebar.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.shape({}),
  canReviewTasks: PropTypes.bool.isRequired,
  canAdministrateDocs: PropTypes.bool.isRequired,
  canSuperUserAny: PropTypes.bool.isRequired,
  canReadAnalytics: PropTypes.bool.isRequired,
  canReadTaskAnalytics: PropTypes.bool.isRequired,
  canViewMarketplace: PropTypes.bool.isRequired,
};

export default withRouter(connect(mapStateToProps)(Sidebar));
