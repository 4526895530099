import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Grid, GridItem } from '../../templates/Grid';
import RateCard from '../RateCard/RateCard';
import RateCardInfo from '../RateCardInfo/RateCardInfo';
import BillingCalculator from '../BillingCalculator';

import { setVisibleTiers } from '../../../components/Marketplace/helpers';
import './RateCardList.scss';

const RateCardList = ({ pricing, isShowFeatures, accessFee }) => {
  if (!pricing || !pricing.tiers || pricing.tiers.length <= 0) return null;
  const visibleTiers = setVisibleTiers(pricing.tiers);
  const numberOfTiers = visibleTiers.length + 1;
  const rateCardColspan = Math.floor(12 / numberOfTiers);

  return (
    <>
      <Grid colGap="primary">
        {visibleTiers.map(tier => (
          <GridItem key={tier.from} col={rateCardColspan}>
            <RateCard
              upperBound={tier.to}
              lowerBound={tier.from}
              pricePerPage={tier.unitPrice}
            />
          </GridItem>
        ))}
        <GridItem col={rateCardColspan}>
          <RateCard isCallToAction />
        </GridItem>
      </Grid>
      <RateCardInfo
        numberOfTiers={numberOfTiers}
        isShowFeatures={isShowFeatures}
      />
      <Grid>
        <GridItem col="12">
          <hr className="billing-calculator-separator" />
        </GridItem>
      </Grid>
      <Grid>
        <GridItem col="12">
          <div className="billing-calculator-container">
            <BillingCalculator accessFee={accessFee} pricing={pricing} />
          </div>
        </GridItem>
      </Grid>
    </>
  );
};

RateCardList.propTypes = {
  pricing: PropTypes.shape({
    tiers: PropTypes.arrayOf(PropTypes.object),
  }),
  isShowFeatures: PropTypes.bool,
  accessFee: PropTypes.number,
};

RateCardList.defaultProps = {
  pricing: {
    tiers: [
      { name: 'Base', flatPrice: 9, threshold: 5, unitPrice: 0 },
      { name: 'High', flatPrice: 0, threshold: 'inf', unitPrice: 0.1 },
    ],
  },
  isShowFeatures: true,
  accessFee: 0,
};

export default RateCardList;
