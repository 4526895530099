import React, { forwardRef } from 'react';
import { FaSearch } from 'react-icons/fa';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './SearchField.scss';

const SearchField = forwardRef(({ className, style, ...props }, ref) => (
  <div className={classNames('search-field', className)}>
    <input className="search-field__input" style={style} {...props} ref={ref} />
    <FaSearch className="search-field__icon" alt="Search" />
  </div>
));

SearchField.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

SearchField.defaultProps = {
  className: '',
  style: {},
};

export default SearchField;
