import React, { useState } from 'react';
import { FaTable } from 'react-icons/fa';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CaptureDrawer from '../../Drawer/CaptureDrawer';
import { toggleDrawer } from '../../../store/actions';
import '../../Drawer/_captureDrawer.scss';
import '../_captureField.scss';
import CaptureTable from '../../Table/CaptureTable';
import { isNumber } from '../../../../../common/helpers';

const isLastCol = (rows, i) => {
  const tableRows = Object.keys(rows[0]).filter(key => key !== 'tableIdx');
  return tableRows.length === i + 1;
};

const mapToHeader = (obj, rowIdx, key) => {
  const newObj = { ...obj };
  delete newObj[key];
  newObj.rowIdx = rowIdx;
  return newObj;
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      toggleDrawer: isOpen => dispatch(toggleDrawer(isOpen)),
    },
  };
};

const TablesField = ({ label, rows, dispatch }) => {
  if (!rows || !rows[0]) return null;

  const [isOpen, setOpen] = useState(false);
  const headers = Object.entries(rows[0])
    .filter(([key]) => key !== 'tableIdx')
    .map(([key, value], i) => {
      return {
        text: key,
        className:
          isNumber(value) || isLastCol(rows, i) ? 'align--r' : undefined,
      };
    });

  const handleOpen = () => {
    setOpen(true);
    dispatch.toggleDrawer(true);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch.toggleDrawer(false);
  };

  return (
    <li className="capture-field__field-value-row" data-table={label}>
      <button className="capture-field__field-value-btn" onClick={handleOpen}>
        {label} <FaTable />
      </button>
      <CaptureDrawer isOpen={isOpen} onClose={handleClose}>
        <div className="capture-drawer__header">
          <h2 className="capture-drawer__heading">{label}</h2>
        </div>
        <div className="capture-drawer__body">
          <CaptureTable>
            <thead>
              <tr>
                {headers.map(th => (
                  <th className={th.className} key={`${label}|th|${th.text}`}>
                    {th.text}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows
                .map((r, i) => mapToHeader(r, i, 'tableIdx'))
                .map(tr => {
                  const rowKey = `${label}|tr|${tr.rowIdx}`;
                  return (
                    <tr key={rowKey}>
                      {Object.entries(tr)
                        .filter(([key]) => key !== 'rowIdx')
                        .map(([key, value], i) => (
                          <td
                            className={
                              headers[i] ? headers[i].className : undefined
                            }
                            key={`${rowKey}|${key}`}
                          >
                            {value}
                          </td>
                        ))}
                    </tr>
                  );
                })}
            </tbody>
          </CaptureTable>
        </div>
      </CaptureDrawer>
    </li>
  );
};

TablesField.propTypes = {
  label: PropTypes.string,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  dispatch: PropTypes.shape({
    toggleDrawer: PropTypes.func,
  }),
};

TablesField.defaultProps = {
  label: null,
  dispatch: { toggleDrawer: () => {} },
};

export default connect(null, mapDispatchToProps)(TablesField);
