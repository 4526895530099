import SubscriptionService from '../../services/SubscriptionService';
import { addNotification } from '../notifications';
import { errorMessage } from '../product/messages';
import CompanyService from '../../services/CompanyService';
import { loadUser } from '../authReducer';

export const REQUEST_TRIAL_SUBSCRIPTION =
  'sypht/product/REQUEST_TRIAL_SUBSCRIPTION';
export const RECEIVE_TRIAL_SUBSCRIPTION =
  'sypht/product/RECEIVE_TRIAL_SUBSCRIPTION';
export const RECEIVE_PAID_SUBSCRIPTION =
  'sypht/product/RECEIVE_PAID_SUBSCRIPTION';
export const REQUEST_SAVE_DEFAULT_PAYMENT_METHOD =
  'sypht/product/REQUEST_SAVE_DEFAULT_PAYMENT_METHOD';
export const RECEIVE_SAVE_DEFAULT_PAYMENT_METHOD =
  'sypht/product/RECEIVE_SAVE_DEFAULT_PAYMENT_METHOD';
export const REQUEST_DELETE_PAYMENT_METHOD =
  'sypht/product/REQUEST_DELETE_PAYMENT_METHOD';
export const RECEIVE_DELETE_PAYMENT_METHOD =
  'sypht/product/RECEIVE_DELETE_PAYMENT_METHOD';
export const REQUEST_SAVE_PAYMENT_METHOD =
  'sypht/product/REQUEST_SAVE_PAYMENT_METHOD';
export const RECEIVE_SAVE_PAYMENT_METHOD =
  'sypht/product/RECEIVE_SAVE_PAYMENT_METHOD';
export const REQUEST_PAID_SUBSCRIPTION =
  'sypht/product/REQUEST_PAID_SUBSCRIPTION';
export const RECEIVE_PAYMENT_SUCCESS = 'sypht/product/RECEIVE_PAYMENT_SUCCESS';
export const RECEIVE_PAYMENT_FAILURE = 'sypht/product/RECEIVE_PAYMENT_FAILURE';
export const CLEAR_PAYMENT_RESULT = 'sypht/product/CLEAR_PAYMENT_RESULT';
export const REQUEST_SUBSCRIPTIONS =
  'sypht/subscriptions/REQUEST_SUBSCRIPTIONS';
export const RECEIVE_SUBSCRIPTIONS =
  'sypht/subscriptions/RECEIVE_SUBSCRIPTIONS';
export const RECEIVE_BILLING_PERIODS =
  'sypht/subscriptions/RECEIVE_BILLING_PERIODS';
export const REQUEST_SUBSCRIPTION_BY_ID =
  'sypht/subscriptions/REQUEST_SUBSCRIPTION_BY_ID';
export const RECEIVE_SUBSCRIPTION_BY_ID =
  'sypht/subscriptions/RECEIVE_SUBSCRIPTIONS_BY_ID';

const requestSubscriptions = () => {
  return {
    type: REQUEST_SUBSCRIPTIONS,
    loading: true,
    payload: {},
  };
};

const receiveBillingPeriods = data => {
  return {
    type: RECEIVE_BILLING_PERIODS,
    payload: {
      data,
    },
  };
};

const receiveSubscriptions = data => {
  return {
    type: RECEIVE_SUBSCRIPTIONS,
    payload: {
      data,
      loading: false,
      receivedAt: Date.now(),
    },
  };
};

const requestSubscriptionById = () => {
  return {
    type: REQUEST_SUBSCRIPTION_BY_ID,
    loading: true,
    payload: {},
  };
};

const receiveSubscriptionById = data => {
  return {
    type: RECEIVE_SUBSCRIPTION_BY_ID,
    payload: {
      data,
      loading: false,
      receivedAt: Date.now(),
    },
  };
};

const requestTrial = () => ({
  type: REQUEST_TRIAL_SUBSCRIPTION,
  payload: {
    loading: true,
  },
});

const receiveTrial = (data, isSent = false) => ({
  type: RECEIVE_TRIAL_SUBSCRIPTION,
  payload: { data, loading: false, sent: isSent, receivedAt: Date.now() },
});

const receivePaid = (data, isSent = false) => ({
  type: RECEIVE_PAID_SUBSCRIPTION,
  payload: { data, loading: false, sent: isSent, receivedAt: Date.now() },
});

const requestSaveDefaultPaymentMethod = () => ({
  type: REQUEST_SAVE_DEFAULT_PAYMENT_METHOD,
  payload: {
    loading: true,
  },
});

const receiveSaveDefaultPaymentMethod = data => ({
  type: RECEIVE_SAVE_DEFAULT_PAYMENT_METHOD,
  payload: { data, loading: false },
});

const requestDeletePaymentMethod = () => ({
  type: REQUEST_DELETE_PAYMENT_METHOD,
  payload: {
    loading: true,
  },
});

const receiveDeletePaymentMethod = data => ({
  type: RECEIVE_DELETE_PAYMENT_METHOD,
  payload: { data, loading: false },
});

const requestSavePaymentMethod = () => ({
  type: REQUEST_SAVE_PAYMENT_METHOD,
  payload: {
    loading: true,
  },
});

const receiveSavePaymentMethod = data => ({
  type: RECEIVE_SAVE_PAYMENT_METHOD,
  payload: { data, loading: false },
});

const requestPaidSubscription = () => ({
  type: REQUEST_PAID_SUBSCRIPTION,
  payload: {
    loading: true,
  },
});

const receivePaymentSuccess = () => ({
  type: RECEIVE_PAYMENT_SUCCESS,
});

const receivePaymentFailed = cause => {
  return {
    type: RECEIVE_PAYMENT_FAILURE,
    payload: { cause },
  };
};

export const clearPaymentResult = () => ({
  type: CLEAR_PAYMENT_RESULT,
  payload: {},
});

export const fetchSubscriptionById = productId => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { user, accessToken } = auth;
    const { companyId } = user.data || {};
    if (!companyId) {
      return undefined;
    }
    dispatch(requestSubscriptionById());
    return SubscriptionService.getSubscriptions(accessToken, companyId, {
      productId,
    }).then(({ data }) => {
      if (data.length > 0) {
        dispatch(receiveSubscriptionById(data[0]));
      } else {
        dispatch(receiveSubscriptionById(null));
      }
    });
  };
};

/**
 * Defaults to the auth user's companyId but you can override in params.
 *
 * @param {*} params
 * @param {*} params.companyId Set this especially if you have code that additionally handles a superuser in internal admin looking at another company
 * @returns
 */
export const fetchSubscriptions = (params = {}) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { user, accessToken } = auth;
    const companyId = params?.companyId || user?.data?.companyId;
    if (!companyId) {
      return undefined;
    }

    dispatch(requestSubscriptions());
    return SubscriptionService.getSubscriptions(accessToken, companyId).then(
      ({ data }) => {
        dispatch(
          receiveSubscriptions({
            subscriptions: data || [],
          }),
        );
        dispatch(
          receiveBillingPeriods({
            subscriptions: data || [],
          }),
        );
      },
    );
  };
};

export const subscribeTrial = (productId, isNotify = true) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;
    const user = auth.user && auth.user.data ? auth.user.data : {};
    const { companyId } = user;

    dispatch(requestTrial());
    try {
      const response = await SubscriptionService.subscribeTrial(
        accessToken,
        companyId,
        {
          productId,
          isTrial: true,
        },
      );

      if (response.data) {
        dispatch(receiveTrial(response.data));
        dispatch(fetchSubscriptions());

        if (isNotify) {
          dispatch(
            addNotification({
              style: 'confirmation',
              message: 'Trial Activated!',
            }),
          );
        }
      } else {
        dispatch(receiveTrial(null));
        dispatch(addNotification(errorMessage));
      }
    } catch (error) {
      dispatch(receiveTrial(null));
      dispatch(addNotification({ style: 'error', message: error.message }));
    }
  };
};

export const subscribePaid = (productId, paymentMethodId = null) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;
    const user = auth.user && auth.user.data ? auth.user.data : {};
    const { companyId } = user;

    dispatch(requestPaidSubscription());
    try {
      const response = await SubscriptionService.subscribePaid(
        accessToken,
        companyId,
        {
          productId,
          paymentMethodId,
        },
      );

      if (response.data) {
        dispatch(receivePaid(response.data));
        dispatch(receivePaymentSuccess());
        dispatch(fetchSubscriptions());
      }
    } catch (error) {
      dispatch(receivePaymentFailed());
      if (error.response && error.response.status === 402) {
        dispatch(
          addNotification({
            style: 'error',
            message: error.response.data.message,
          }),
        );
      } else {
        dispatch(addNotification({ style: 'error', message: error.message }));
      }
    }
  };
};

export const upgradeTrial = (productId, subscriptionId, paymentMethodId) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;
    const user = auth.user && auth.user.data ? auth.user.data : {};
    const { companyId } = user;

    dispatch(requestPaidSubscription());
    try {
      const response = await SubscriptionService.upgradeTrialSubscription(
        accessToken,
        companyId,
        {
          productId,
          paymentMethodId,
          subscriptionId,
        },
      );
      if (response.data) {
        dispatch(receivePaid(response.data));
        dispatch(receivePaymentSuccess());
        dispatch(fetchSubscriptions());
      }
    } catch (error) {
      if (error.response && error.response.status === 402) {
        dispatch(receivePaymentFailed());
        dispatch(
          addNotification({
            style: 'error',
            message: error.response.data.message,
          }),
        );
      } else {
        dispatch(addNotification({ style: 'error', message: error.message }));
      }
    }
  };
};

export const savePaymentMethod = paymentMethod => {
  return async (dispatch, getState) => {
    const { auth, company } = getState();
    const { accessToken } = auth;
    const user = auth.user && auth.user.data ? auth.user.data : {};
    const { companyId } = user;
    dispatch(requestSavePaymentMethod());

    try {
      const response = await CompanyService.savePaymentMethod(
        accessToken,
        companyId,
        paymentMethod,
      );
      dispatch(receiveSavePaymentMethod(response.data));
      dispatch(loadUser(accessToken));
      dispatch(
        addNotification({
          style: 'confirmation',
          message: `Payment card for ${company.data.name} has been updated.`,
        }),
      );
    } catch (error) {
      dispatch(receiveSavePaymentMethod(null));
      dispatch(addNotification({ style: 'error', message: error.message }));
    }
  };
};

export const saveDefaultPaymentMethod = paymentMethodId => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;
    const user = auth.user && auth.user.data ? auth.user.data : {};
    const { companyId } = user;

    dispatch(requestSaveDefaultPaymentMethod());

    try {
      const response = await CompanyService.saveDefaultPaymentMethod(
        accessToken,
        companyId,
        {
          paymentMethodId,
        },
      );

      dispatch(receiveSaveDefaultPaymentMethod(response.data));
      dispatch(
        addNotification({
          style: 'confirmation',
          message: 'Default payment method updated',
        }),
      );
    } catch (err) {
      dispatch(receiveSaveDefaultPaymentMethod(null));
      dispatch(addNotification({ style: 'error', message: err.message }));
    }
  };
};

export const deletePaymentMethod = paymentMethodId => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;
    const user = auth.user && auth.user.data ? auth.user.data : {};
    const { companyId } = user;

    dispatch(requestDeletePaymentMethod());

    try {
      const response = await CompanyService.deletePaymentMethod(
        accessToken,
        companyId,
        paymentMethodId,
      );

      dispatch(receiveDeletePaymentMethod(response.data));
      dispatch(
        addNotification({
          style: 'confirmation',
          message: 'Payment method successfully deleted.',
        }),
      );
    } catch (err) {
      dispatch(receiveDeletePaymentMethod(null));
      dispatch(addNotification({ style: 'error', message: err.message }));
    }
  };
};
