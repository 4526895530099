import React, { Fragment } from 'react';
import { STYLE } from './Notification';

export const createdMessage = (entity, name) => ({
  style: STYLE.CONFIRMATION,
  message: (
    <>
      {entity} <strong>{name}</strong> has been created.
    </>
  ),
  timeout: 5000,
});

export const updatedMessage = (entity, name) => ({
  style: STYLE.CONFIRMATION,
  message: (
    <>
      {entity} <strong>{name}</strong> has been updated.
    </>
  ),
  timeout: 5000,
});

export const deletedMessage = (entity, name) => ({
  style: STYLE.CONFIRMATION,
  message: (
    <>
      {entity} <strong>{name}</strong> has been deleted.
    </>
  ),
  timeout: 5000,
});

export const errorMessage = {
  style: STYLE.ERROR,
  message:
    'Something went wrong, please try again. If this issue persists ' +
    'please contact us at support@sypht.com.',
  timeout: 8000,
};

export const validationError = {
  style: STYLE.ERROR,
  message: 'Please complete all required fields.',
  timeout: 8000,
};

export const apiError = error => {
  return {
    style: STYLE.ERROR,
    message: error,
    timeout: 13000,
  };
};
