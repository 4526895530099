import moment from 'moment';
import { RECEIVE_USAGE_STATS, REQUEST_USAGE_STATS } from './actions';

const initialState = {
  loading: true,
  data: {
    query: null,
    stats: null,
    totalSpend: 0,
    totalPages: 0,
    loading: false,
    receivedAt: null,
    subscriptions: null,
  },
};

const analyticsReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case REQUEST_USAGE_STATS: {
      return {
        ...state,
        loading: true,
      };
    }

    case RECEIVE_USAGE_STATS: {
      const { data, receivedAt } = payload;
      const {
        query,
        startDate,
        endDate,
        usage,
        totalSpend,
        totalPages,
        totalDocs,
        subscriptions,
      } = data;

      const getSubscription = productId => {
        if (!subscriptions) return '';
        return subscriptions.find(s => s.productId === productId);
      };

      const billingPeriod =
        `${moment(startDate).format('MMMM DD, YYYY')} - ` +
        `${moment(endDate).format('MMMM DD, YYYY')}`;

      const stats = usage
        ? Object.entries(usage).map(([key, o]) => {
            const {
              pagesDaily,
              totalSpendDaily,
              totalPages,
              spendDaily,
              totalSpend,
              docsDaily,
              totalDocs,
            } = o;
            const subscription = getSubscription(key);
            return {
              code: key,
              name: subscription?.name,
              billingPeriod,
              dailyUsage: pagesDaily,
              totalUsage: totalPages,
              dailySpend: spendDaily,
              totalSpendDaily,
              totalSpend,
              dailyDocs: docsDaily,
              totalDocs,
            };
          })
        : null;

      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          stats,
          totalPages,
          totalSpend,
          totalDocs,
          billingPeriod,
          query,
          loading: false,
          lastUpdated: receivedAt,
          subscriptions,
        },
      };
    }

    default:
      return state;
  }
};

export default analyticsReducer;
