import React from 'react';
import TourContent from '../TourContent';
import Help from '../../../../../../assets/images/onboarding/introduction-to-sypht/help.svg';

const HelpCentre = () => (
  <TourContent
    icon={Help}
    text="For more information on how to get the most out of Sypht, view our help resources."
  />
);

export default HelpCentre;
