import React, { useRef, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FaTags, FaCopy, FaTasks, FaUser } from 'react-icons/fa';
import debounce from 'lodash/debounce';
import { Button } from '../../../library/atoms/Buttons';
import Checkbox from '../Checkbox/Checkbox';
import TagSearch from './TagSearch';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import './TagDropdown.scss';

const DEBOUNCE_INTERVAL = 400;
/**
 * Tag is present in all selected taggables.
 */
const TAG_TYPE_ALL = 1;
/**
 * Tag is NOT present in all selected taggables.
 */
const TAG_TYPE_PARTIAL = 0;
const emptyValue = <>&mdash;</>;

/**
 * Get list of selected tags for dropdown and flag whether they're present from
 * a lookup of selected taggables.
 *
 * @param {*} taggableById Lookup of taggables (docs/tasks/users) by id.  Each taggable (doc/task/user) should have a .tags collection
 */
export function getSelectedTags(taggableById) {
  const tagsById = {};
  Object.values(taggableById).forEach(taggable => {
    taggable.tags.forEach(tag => {
      if (!tagsById[tag.id]) {
        tagsById[tag.id] = {
          ...tag,
        };
      }
    });
  });
  const tagsByName = {};
  Object.values(tagsById).forEach(tag => {
    let tagType = TAG_TYPE_ALL;
    Object.values(taggableById).forEach(taggable => {
      const hasTag = taggable.tags.some(tgblTag => tgblTag.name === tag.name);
      if (!hasTag) {
        tagType = TAG_TYPE_PARTIAL;
      }
    });
    tagsByName[tag.name] = {
      ...tag,
      tagType,
    };
  });
  return tagsByName;
}

const TagDropdown = ({
  className,
  isDisabled,
  enableTagCreation,
  tags,
  onSearch,
  onConfirm,
  onCreateTag,
  selectedTags = {},
}) => {
  // refs
  const tagDropdownRef = useRef(null);
  const searchRef = useRef(null);

  // state
  const [isOpen, setIsOpen] = useState();
  const [filter, setFilter] = useState(null);
  const [tagsList, setTagsList] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [removeTags, setRemoveTags] = useState([]);
  const [addTags, setAddTags] = useState([]);

  const createTag = useMemo(() => filter && tags.every(t => t.name !== filter) ? filter : null, [tags])
  /**
   * ***** Helpers *****
   */

  // Sort helper for selected tags & newly selected tags
  const sortSelectedTags = a => {
    const isSelected = selectedTags ? selectedTags[a.name] : false;

    if ((addTags || []).includes(a.name) || isSelected) {
      return -1;
    }

    return 0;
  };

  // Reset state
  const reset = () => {
    setButtonDisabled(true);
    setRemoveTags([]);
    setAddTags([]);
    setIsOpen(!isOpen);
    setFilter(null);
  };

  // Reset dropdown
  const toggleDropdown = () => {
    reset();
  };

  // check if tag is on added array
  const isTagAdded = tagName => {
    return addTags.includes(tagName);
  };

  // check if tag is on initial selected tags array
  const isTagSelected = tagName => {
    return (
      selectedTags[tagName] !== undefined &&
      selectedTags[tagName].tagType === TAG_TYPE_ALL
    );
  };

  const setTagsListHandler = t => {
    if (t) {
      //t.sort(sortTags);
      t.sort(sortSelectedTags);
      setTagsList(t);
    }
  };

  /**
   * ***** Event Handlers *****
   */

  // Click event for the listener
  const handleClickEvent = e => {
    if (tagDropdownRef.current && !tagDropdownRef.current.contains(e.target)) {
      setIsOpen(false);
      setFilter(null);
    }
  };

  // On tag search
  const handleTagSearch = debounce(text => {
    setFilter(text);
  }, DEBOUNCE_INTERVAL);

  // On select and deselect checkboxes
  const handleOnChange = (e, type) => {
    const tag = e.target.value;
    const isPresent = selectedTags[tag] !== undefined;
    const isPartial = type === TAG_TYPE_PARTIAL;

    if (e.target.checked) {
      setAddTags(!isPresent || isPartial ? [...addTags, tag] : addTags);
      setRemoveTags(
        removeTags.includes(tag)
          ? removeTags.filter(v => v !== tag)
          : removeTags,
      );
    } else {
      setRemoveTags(isPresent ? [...removeTags, tag] : removeTags);
      setAddTags(
        addTags.includes(tag) ? addTags.filter(v => v !== tag) : addTags,
      );
    }
  };

  // Handle on submit event (apply button)
  const handleSubmit = async () => {
    onConfirm(addTags, removeTags);
    setIsOpen(false);
  };

  // Handle on create tag
  const handleCreateTag = async tagName => {
    // if (!tags.find(t => t.name === tagName)) {
    //   onCreateTag(tagName);
    //   setAddTags([...addTags, tagName]);
    //   setTagsListHandler(tags);
    //   searchRef.current.value = '';
    // }
    onCreateTag(tagName);
    setAddTags([...addTags, tagName]);
    searchRef.current.value = '';
    //setCreateTag(null);
  };

  /**
   * ****** UseEffects *****
   */

  // store init tag list
  // useEffect(() => {
  //   setTagsList(tags);
  // }, [tags]);

  // Set click event listener
  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickEvent);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickEvent);
    };
  }, [isOpen]);

  // Sort tags in alphabetical order
  useEffect(() => {
    if (tags) {
      setTagsListHandler(tags);
    }
  }, [tags]);

  // Place the Selected tags on top of the list
  useEffect(() => {
    if (selectedTags && Object.keys(selectedTags).length) {
      setTagsListHandler(tags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTags]);

  // For filtering search input
  useEffect(() => {
    onSearch(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  // Toggle apply button
  useEffect(() => {
    if (addTags.length || removeTags.length) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [addTags, removeTags]);

  /**
   * ***** Components *****
   */
  const CreateTagComponent = () => {
    return (
      <div className="tag-dd__create-tag">
        <span className="tag-text">
          &lsquo;
          {createTag}
          &rsquo;
        </span>
        <button
          type="button"
          className="add-new-btn"
          onClick={() => handleCreateTag(createTag)}
        >
          Add new
        </button>
      </div>
    );
  };

  return (
    <div className={`tag-dd ${className}`} ref={tagDropdownRef}>
      <span data-tooltip-id="tag-button-tooltip">
        <Button
          className={classNames({ open: isOpen })}
          onClick={toggleDropdown}
          size="md"
          disabled={isDisabled}
          isResponsive
        >
          <FaTags />
          <span>Tag</span>
        </Button>
        <ReactTooltip
          id="tag-button-tooltip"
          content="Tag selected document(s) to make them easier to find later"
          place="bottom"
          style={{
            top: '4',
            bottom: '4',
            left: '4',
            position: 'fixed',
            zIndex: 9999,
          }}
        />
      </span>
      {isOpen && (
        <div className="tag-dd__wrapper">
          <TagSearch onChange={handleTagSearch} forwardRef={searchRef} />

          {tagsList.length > 0 && (
            <div className="tag-dd__table">
              <div className="table-header">
                <div>Tag name</div>
                <div>
                  <FaCopy />
                </div>
                <div>
                  <FaTasks />
                </div>
                <div>
                  <FaUser />
                </div>
              </div>
              {enableTagCreation && createTag && <CreateTagComponent />}
              <div className="table-body">
                {tagsList.map(t => (
                  <div className="table-row" key={t.name}>
                    <div className="table-row--checkbox">
                      <Checkbox
                        size="sm"
                        name="checkboxTags"
                        label={t.name}
                        id={t.name}
                        value={t.name}
                        defaultChecked={
                          isTagAdded(t.name) || isTagSelected(t.name)
                        }
                        partialChecked={
                          selectedTags[t.name] !== undefined &&
                          selectedTags[t.name].tagType === TAG_TYPE_PARTIAL
                        }
                        onChange={e =>
                          handleOnChange(
                            e,
                            selectedTags[t.name] !== undefined &&
                              selectedTags[t.name].tagType,
                          )
                        }
                      />
                    </div>
                    <div className="table-row--value">
                      {t.numberOfDocuments > 0
                        ? t.numberOfDocuments
                        : emptyValue}
                    </div>
                    <div className="table-row--value">
                      {t.numberOfTasks > 0 ? t.numberOfTasks : emptyValue}
                    </div>
                    <div className="table-row--value">
                      {t.numberOfUsers > 0 ? t.numberOfUsers : emptyValue}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {!buttonDisabled && tagsList.length > 0 && (
            <Button
              className="tag-dd__apply-button"
              type="secondary-border"
              size="md"
              variant="outline"
              onClick={handleSubmit}
            >
              Apply
            </Button>
          )}

          {tagsList && tagsList.length < 0 && !createTag && (
            <div className="tag-dd__empty-results">
              <span className="icon">
                <FaTags />
              </span>
              <p>
                <b>Get started with your first tag.</b>
              </p>
              <p>
                Type in the name of a new tag in the search bar above to create
                one.
              </p>
            </div>
          )}

          {!tagsList.length && createTag && enableTagCreation && <CreateTagComponent />}
        </div>
      )}
    </div>
  );
};

TagDropdown.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  enableTagCreation: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.shape({})),
  selectedTags: PropTypes.shape({}),
  onConfirm: PropTypes.func,
  onCreateTag: PropTypes.func,
  onSearch: PropTypes.func,
};

TagDropdown.defaultProps = {
  className: '',
  isDisabled: true,
  enableTagCreation: false,
  tags: null,
  selectedTags: {},
  onConfirm: () => {},
  onCreateTag: () => {},
  onSearch: () => {},
};

export default TagDropdown;
