import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { PageLoading } from '../Page';

const mapStateToProps = (state, props) => {
  const { match } = props;
  const { auth } = state;
  const { params } = match;
  const { userId } = auth;
  return {
    userId,
    params,
  };
};
const TaskRedirect = ({ userId, params }) => {
  const [redirectUrl, setRedirectUrl] = useState(null);
  useEffect(() => {
    if (userId && params.taskId && !params.userId) {
      setRedirectUrl(`/tasks/${params.taskId}/user/${userId}`);
    } else if (!params.taskId) {
      setRedirectUrl('/404');
    }
  }, [userId]);
  return redirectUrl ? <Redirect to={redirectUrl} /> : <PageLoading />;
};

export default connect(mapStateToProps)(TaskRedirect);
