import React, { useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { mergeRight as merge } from 'ramda';
import {
  FaRegClipboard,
  FaRedo,
  FaUndo,
  FaCut,
  FaCompress,
  FaExpand,
} from 'react-icons/fa';
import { Button } from '../../../../library/atoms/Buttons';
import { DocumentToolbarContext } from '../../../UI/DocumentToolbar/DocumentToolbarContext';
import './Toolbar.scss';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import NextTaskButton from '../../../NextTaskButton/NextTaskButton';
import { DocumentSplitPending } from '../../../../library/atoms/Icons/index';

const mapStateToProps = state => {
  const { auth } = state;
  const user = auth.user.data;
  return {
    userId: user.id,
  };
};

const Toolbar = ({
  doc,
  noSplitSpecified,
  hasSubmittedSplit,
  enableFitToView,
  variant,
  onSave,
  onDiscard,
}) => {
  const [toolbarContext, setToolbarContext] = useContext(
    DocumentToolbarContext,
  );

  const { rotation, collapsed } = toolbarContext;

  const rotate = degree => {
    const normaliseRotation = (rotation + degree) % 360;
    if (collapsed) {
      setToolbarContext(t => {
        return merge(t, {
          collapsed: false,
        });
      });
    } else {
      setToolbarContext(t => {
        return merge(t, {
          rotation: normaliseRotation,
        });
      });
    }
  };
  const expand = () => {
    setToolbarContext(t => {
      return merge(t, {
        rotation: 0,
        collapsed: false,
      });
    });
  };

  const collapse = () => {
    setToolbarContext(t => {
      return merge(t, {
        collapsed: true,
      });
    });
  };

  return (
    <div className="doc-split__toolbar">
      <div className="doc-split__left-container">
        <div className="doc-split__toolbar-icon-title">
          <div className="doc-split__toolbar-icon">
            <DocumentSplitPending />
          </div>
          <div className="doc-split__toolbar-heading">
            <div className="doc-split__toolbar-heading--title">
              filename {doc.filename}
            </div>

            <div className="doc-split__toolbar-subheading">
              <CopyToClipboard text={doc.id}>
                <div
                  className="uuid"
                  data-tooltip-id="tasktoolsright-button-tooltip"
                  data-tooltip-place="right"
                  data-tooltip-content="Click to copy to clipboard."
                >
                  <span>
                    Doc ID: {doc.id.split('-')[0]} <FaRegClipboard />
                  </span>
                </div>
              </CopyToClipboard>
              <div className="other">
                No. of Pages:
                {doc.received.numOfPages}
              </div>
            </div>
          </div>
          <ReactTooltip
            id="tasktoolsright-button-tooltip"
            place="right"
            style={{
              top: '4',
              bottom: '4',
              left: '4',
              position: 'fixed',
              zIndex: 9999,
            }}
          />
        </div>
      </div>
      <div className="doc-split__right-container">
        <div className="doc-split__controls">
          {enableFitToView &&
            (collapsed ? (
              <FaCompress
                data-tooltip-id="tasktools-button-tooltip"
                data-tooltip-content="Expand"
                onClick={expand}
              />
            ) : (
              <FaExpand
                data-tooltip-id="tasktools-button-tooltip"
                data-tooltip-content="Fit to view"
                onClick={collapse}
              />
            ))}
          <FaUndo
            data-tooltip-id="tasktools-button-tooltip"
            data-tooltip-content="Rotate left"
            onClick={() => rotate(270)}
          />
          <FaRedo
            data-tooltip-id="tasktools-button-tooltip"
            data-tooltip-content="Rotate right"
            onClick={() => rotate(90)}
          />
          {variant === 'HITL' && (
            <NextTaskButton disabled={!hasSubmittedSplit} />
          )}
          {!hasSubmittedSplit && (
            <Button onClick={onDiscard} variant="outline">
              Discard
            </Button>
          )}
          {!hasSubmittedSplit && (
            <Button onClick={onSave} disabled={noSplitSpecified}>
              <FaCut /> Save
            </Button>
          )}
          {hasSubmittedSplit && <Button disabled>Submitted</Button>}
          <ReactTooltip
            id="tasktools-button-tooltip"
            place="bottom"
            style={{
              top: '4',
              bottom: '4',
              left: '4',
              position: 'fixed',
              zIndex: 9999,
            }}
          />
        </div>
      </div>
    </div>
  );
};

Toolbar.defaultProps = {
  doc: null,
  noSplitSpecified: true,
  hasSubmittedSplit: false,
  enableFitToView: true,
};

Toolbar.propTypes = {
  doc: PropTypes.shape({
    id: PropTypes.string,
    filename: PropTypes.string,
    received: PropTypes.shape({
      numOfPages: PropTypes.number,
    }),
  }),
  onSave: PropTypes.func.isRequired,
  onDiscard: PropTypes.func.isRequired,
  noSplitSpecified: PropTypes.bool,
  hasSubmittedSplit: PropTypes.bool,
  enableFitToView: PropTypes.bool,
  variant: PropTypes.oneOf(['manual', 'HITL']).isRequired,
};

export default connect(mapStateToProps)(Toolbar);
