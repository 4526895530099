import React from 'react';
import PropTypes from 'prop-types';
import './_modal-body.scss';

const ModalBody = ({ children }) => (
  <div className="m-modal__body">{children}</div>
);

ModalBody.propTypes = {
  children: PropTypes.node,
};

ModalBody.defaultProps = {
  children: null,
};

export default ModalBody;
