import React from 'react';
import PropTypes from 'prop-types';
import './_choice-chip-group.scss';

const ChoiceChipGroup = ({ name, value, onChange, children }) => {
  return (
    <div className="choice-chip-group" role="radiogroup" aria-label={name}>
      {React.Children.map(children, child =>
        React.cloneElement(child, {
          currentValue: value,
          onChange,
          name,
        }),
      )}
    </div>
  );
};

ChoiceChipGroup.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
};

ChoiceChipGroup.defaultProps = {
  children: undefined,
  value: undefined,
};

export default ChoiceChipGroup;
