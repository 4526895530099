/* eslint-disable func-names */
import { mergeRight as merge, mergeDeepRight } from 'ramda';
import { isCancel } from 'axios';
import TasksService from '../services/TasksService';
import DocsService from '../services/DocsService';
import { fetchNextTaskUsingStoredFilters } from './nextTask';
import { transformSortBy } from '../pages/Tasks/helpers';

export const CLEAR_TASKS = 'sypht/tasks/CLEAR_TASKS';
export const REQUEST_TASKS = 'sypht/tasks/REQUEST_TASKS';
export const RECEIVE_TASKS = 'sypht/tasks/RECEIVE_TASKS';
export const REQUEST_TASK = 'sypht/tasks/REQUEST_TASK';
export const RECEIVE_TASK = 'sypht/tasks/RECEIVE_TASK';

export const REQUEST_DELETE_TASKS = 'sypht/tasks/REQUEST_DELETE_TASKS';
export const RECEIVE_DELETE_TASKS = 'sypht/tasks/RECEIVE_DELETE_TASKS';
export const REQUEST_EDIT_TASKS = 'sypht/tasks/REQUEST_EDIT_TASKS';
export const RECEIVE_EDIT_TASKS = 'sypht/tasks/RECEIVE_EDIT_TASKS';

export const REQUEST_SPECS = 'sypht/tasks/REQUEST_SPECS';
export const RECEIVE_SPECS = 'sypht/tasks/RECEIVE_SPECS';

export const REQUEST_SAVE_ANNOTATION = 'sypht/tasks/REQUEST_SAVE_ANNOTATION';
export const RECEIVE_SAVE_ANNOTATION = 'sypht/tasks/RECEIVE_SAVE_ANNOTATION';

const initial = {
  data: null,
  loading: false,
  active: {
    data: null,
    numPages: null,
    currentPage: null,
    loading: false,
    loadingData: false,
    error: null,
  },
};

export default function tasksReducer(state = initial, action) {
  switch (action.type) {
    case CLEAR_TASKS:
      return merge(state, {
        data: {
          ...state.data,
          tasks: [],
          currentPage: undefined,
          numPages: undefined,
          numTasks: undefined,
        },
        loading: false,
      });

    case REQUEST_TASKS:
      return merge(state, {
        loading: true,
      });

    case RECEIVE_TASKS:
      if (isCancel(action.error)) {
        return state;
      }

      return merge(state, {
        data: action.data,
        loading: false,
      });

    case REQUEST_TASK:
      return merge(state, {
        active: {
          loading: true,
        },
      });

    case RECEIVE_TASK:
      return merge(state, {
        active: {
          data: {
            task: action.task || action.error,
            annotators: action.annotators,
            doc: action.doc,
            predictions: action.predictions,
            candidates: action.candidates,
            receivedTime: new Date().getTime(),
          },
          error: action.error,
          loading: false,
        },
      });
    case REQUEST_SAVE_ANNOTATION:
      return mergeDeepRight(state, {
        active: {
          loadingData: true,
        },
      });
    case RECEIVE_SAVE_ANNOTATION:
      // eslint-disable-next-line no-case-declarations
      let existing = false;
      if (action.annotation && action.annotation.state !== null) {
        // eslint-disable-next-line no-param-reassign
        action.annotation.undoable = true;
      }
      // eslint-disable-next-line no-case-declarations
      const annotations = state.active.data.task.annotations.map(a => {
        if (a.submitterId === action.userId) {
          existing = true;
          return merge(a, action.annotation);
        }
        return a;
      });
      if (!existing) {
        annotations.push(action.annotation);
      }
      return mergeDeepRight(state, {
        active: {
          data: {
            task: {
              annotations,
            },
          },
          loadingData: false,
        },
      });

    default:
      return state;
  }
}

export const requestTask = () => ({ type: REQUEST_TASK });
export const receiveTask = (
  task,
  annotators,
  doc,
  predictions,
  candidates,
  error,
) => ({
  type: RECEIVE_TASK,
  task,
  annotators,
  doc,
  predictions,
  candidates,
  error,
});

function fetchTaskBase(taskId, { onFetchTask = undefined } = {}) {
  return function (dispatch, getState) {
    const state = getState();
    const token = state.auth.accessToken;
    dispatch(requestTask());
    return TasksService.getTask(token, taskId)
      .then(r =>
        Promise.all([
          r,
          DocsService.getDoc(token, r.data.task.docId),
          DocsService.getDocPredictions(
            token,
            r.data.task.docId,
            r.data.task.specification
              ? r.data.task.specification.field_versions.map(
                  f => f.specification.id,
                )
              : [],
          ),
        ]),
      )
      .then(rs => {
        dispatch(
          receiveTask(
            rs[0].data.task,
            rs[0].data.annotators,
            rs[1].data,
            rs[2].data.predictions.fields,
            rs[2].data.candidates.candidates,
            null,
          ),
        );
        if (onFetchTask) {
          onFetchTask({ task: rs[0].data.task });
        }
      })
      .catch(e => dispatch(receiveTask(null, null, null, null, null, e)));
  };
}

export function fetchTask(taskId) {
  return fetchTaskBase(taskId);
}

/**
 * Allocate user to a task without them having to do a fix / annotation.
 *
 * Motivation is to do this early (eg task page loading) so that other users
 * calling the "get next task" endpoint in the near future will get another task
 * instead.
 */
function preAllocateUserToTask(task) {
  return function (dispatch, getState) {
    const state = getState();
    const token = state.auth.accessToken;
    const { userId } = state.auth;
    const userAnnotation = task.annotations?.find(
      a => a.submitterId === userId,
    );
    if (userAnnotation) {
      // We're already allocated, nothing to do.
      return;
    }
    if (!task.completed) {
      // Pre-allocate the user to try to reduce duplicate reviewers on tasks.
      TasksService.saveUserAnnotationState(token, task.id, userId, 'null');
    } else {
      // TODO: do something in the UI to tell the user this task doesn't need to be reviewed?
      // At the moment the user will probably still work on this task which isn't great.
    }
  };
}

export function fetchTaskAndPreAllocateUser(taskId) {
  return function (dispatch) {
    dispatch(
      fetchTaskBase(taskId, {
        onFetchTask: ({ task }) => {
          dispatch(preAllocateUserToTask(task));
        },
      }),
    );
  };
}

export const clearTasks = () => ({
  type: CLEAR_TASKS,
});

export const requestTasks = () => ({ type: REQUEST_TASKS });
export const receiveTasks = (data, error) => ({
  type: RECEIVE_TASKS,
  data,
  error,
});

export function fetchTasks(payload, cancelToken) {
  let finalPayload = payload;
  if (payload.sortBy) {
    finalPayload = {
      ...payload,
      sortBy: transformSortBy(payload.sortBy),
    };
  }
  return function (dispatch, getState) {
    dispatch(requestTasks());

    return TasksService.getTasks(
      getState().auth.accessToken,
      finalPayload,
      r => dispatch(receiveTasks(r.data, null)),
      e => dispatch(receiveTasks(null, e)),
      cancelToken,
    );
  };
}

export const requestDeleteTasks = () => ({ type: REQUEST_DELETE_TASKS });
export const receiveDeleteTasks = (data, error) => ({
  type: RECEIVE_DELETE_TASKS,
  data,
  error,
});

export function deleteTasks(tasks) {
  return function (dispatch, getState) {
    const token = getState().auth.accessToken;
    dispatch(requestDeleteTasks());
    return Promise.all(
      tasks.map(taskId => TasksService.deleteTask(token, taskId)),
    )
      .then(res => dispatch(receiveDeleteTasks(res)))
      .catch(e => dispatch(receiveDeleteTasks(e)));
  };
}

export const requestEditTasks = () => ({ type: REQUEST_EDIT_TASKS });
export const receiveEditTasks = (data, error) => ({
  type: RECEIVE_EDIT_TASKS,
  data,
  error,
});

export function editTasks(tasks, replication, priority) {
  return function (dispatch, getState) {
    const token = getState().auth.accessToken;
    dispatch(requestEditTasks());
    return Promise.all(
      tasks.map(task =>
        TasksService.editTask(token, task, replication, priority),
      ),
    )
      .then(res => dispatch(receiveEditTasks(res)))
      .catch(e => dispatch(receiveEditTasks(e)));
  };
}

export const requestSaveAnnotation = () => ({ type: REQUEST_SAVE_ANNOTATION });
export const receiveSaveAnnotation = (taskId, userId, annotation, error) => ({
  type: RECEIVE_SAVE_ANNOTATION,
  taskId,
  userId,
  annotation,
  error,
});

export function saveUserAnnotationState(taskId, userId, state, flaggedReason) {
  return function (dispatch, getState) {
    dispatch(requestSaveAnnotation());
    return TasksService.saveUserAnnotationState(
      getState().auth.accessToken,
      taskId,
      userId,
      state,
      flaggedReason,
    )
      .then(r => {
        dispatch(
          receiveSaveAnnotation(taskId, userId, r.data.annotation, null),
        );
        // This will update the numRemaining count for next tasks.
        dispatch(fetchNextTaskUsingStoredFilters());
      })
      .catch(e => dispatch(receiveSaveAnnotation(taskId, userId, null, e)));
  };
}

export function saveUserAnnotationData(taskId, userId, data, annotationState) {
  return function (dispatch, getState) {
    dispatch(requestSaveAnnotation());

    const state = getState();
    return TasksService.saveUserAnnotations(
      state.auth.accessToken,
      taskId,
      userId,
      data,
    )
      .then(r => {
        dispatch(
          receiveSaveAnnotation(taskId, userId, r.data.annotation, null),
        );
        if (annotationState) {
          return dispatch(
            saveUserAnnotationState(taskId, userId, annotationState),
          );
        }
        return null;
      })
      .catch(e => dispatch(receiveSaveAnnotation(taskId, userId, null, e)));
  };
}
