import React from 'react';
import PropTypes from 'prop-types';

import './_modal-container.scss';

const ModalContainer = ({ children }) => (
  <div className="m-modal-container" role="dialog">
    {children}
  </div>
);

ModalContainer.propTypes = {
  children: PropTypes.node,
};

ModalContainer.defaultProps = {
  children: null,
};

export default ModalContainer;
