import React, { useLayoutEffect, useRef } from 'react';
import { FaCheck, FaMinus } from 'react-icons/fa';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './_checkbox.scss';

const Checkbox = React.forwardRef(
  (
    {
      id,
      className,
      checked,
      disabled,
      indeterminate,
      variant,
      label,
      ...props
    },
    ref,
  ) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const inputRef = ref || useRef();

    useLayoutEffect(() => {
      inputRef.current.indeterminate = indeterminate;
    }, [inputRef, indeterminate]);

    const isUnpadded = () => {
      return variant === 'unpadded';
    };

    return (
      <label
        className={classNames('checkbox', className, {
          'checkbox--disabled': disabled,
        })}
        htmlFor={id}
      >
        <input
          type="checkbox"
          id={id}
          checked={checked}
          disabled={disabled}
          ref={inputRef}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
        <span
          className={classNames('checkbox__box', {
            checkbox__variant__unpadded: isUnpadded(),
          })}
        >
          {!indeterminate ? (
            <FaCheck className="fa-check" />
          ) : (
            <FaMinus className="fa-minus" />
          )}
        </span>
        {label && <span className="checkbox__label">{label}</span>}
      </label>
    );
  },
);

Checkbox.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  // defaultChecked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  variant: PropTypes.string,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  id: undefined,
  className: null,
  checked: undefined,
  disabled: undefined,
  // defaultChecked: false,
  indeterminate: false,
  label: null,
  name: undefined,
  variant: '',
  onChange: () => {},
};

export default Checkbox;
