/* eslint-disable no-param-reassign */
import React from 'react';
import { render } from 'react-dom';
import Modal from './Modal';
import { createElement } from '../../../common/helpers/domHelper';

export const showModal = modal => {
  const container = createElement('div', ['modal__container'], {
    role: 'presentation',
  });
  document.body.appendChild(container);
  const m = React.cloneElement(modal, { container });
  render(m, container);
};

export { default as ConfirmModal } from './ConfirmModal';
export default Modal;
