import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Page, { PageLoading } from '../Page';
import BackButton from '../UI/Button/BackButton';
import EditUserForm from './EditUserForm';
import { fetchUser, updateUser } from '../../reducers/user/actions';
import './User.scss';
import { getFullName } from '../../common/helpers/userHelper';

const mapStateToProps = (state, props) => {
  const { match } = props;
  const { user, auth, router } = state;
  const { location } = router;

  return {
    user: user.data || null,
    userId: match.params.id,
    editorId: auth.userId,
    editor: auth.user ? auth.user.data : null,
    loading: user.loading,
    error: user.error,
    pathname: location.pathname,
    canAdministrateUsers: auth.permissions.canAdministrateUsers,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      fetchUser: id => dispatch(fetchUser(id)),
      updateUser: (id, userData, companyId) =>
        dispatch(updateUser(id, userData, companyId)),
    },
  };
};

const User = ({
  editorId,
  editor,
  userId,
  user,
  loading,
  error,
  canAdministrateUsers,
  dispatch,
  pathname,
}) => {
  const isInternalAdmin = pathname.includes(`/admin/users/${userId}`);
  const editorUserId = editor?.id;

  useEffect(() => {
    if (editorUserId) {
      dispatch.fetchUser(userId);
    }
  }, [dispatch, editorUserId, userId]);

  const handleSubmit = (e, userData) => {
    e.preventDefault();
    dispatch.updateUser(userId, userData, user.company.id);
  };

  return (
    <Page className="admin admin-user">
      {error && <Redirect to="/404" />}
      {loading && <PageLoading />}
      {!loading && user && (
        <div className="admin__inner">
          <div className="admin__header">
            <BackButton
              text={
                !isInternalAdmin
                  ? 'Back to my users'
                  : `Back to ${user.company.name}'s users`
              }
              to={
                !isInternalAdmin
                  ? '/users'
                  : `/admin/companies/${user.company.id}#users`
              }
            />
            {!user ? null : <h1>{getFullName(user)}</h1>}
          </div>
          <div className="admin__section">
            {!user ? null : (
              <EditUserForm
                editorId={editorId}
                user={user}
                onSubmit={handleSubmit}
                isUserAdmin={canAdministrateUsers}
                isInternalAdmin={isInternalAdmin}
              />
            )}
          </div>
        </div>
      )}
    </Page>
  );
};

User.propTypes = {
  editorId: PropTypes.string,
  userId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
  loading: PropTypes.bool,
  canAdministrateUsers: PropTypes.bool.isRequired,
  dispatch: PropTypes.shape({
    fetchUser: PropTypes.func,
    updateUser: PropTypes.func,
  }),
  pathname: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  editor: PropTypes.object.isRequired,
};

User.defaultProps = {
  editorId: null,
  user: [],
  loading: false,
  dispatch: {
    fetchUser: () => {},
    updateUser: () => {},
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
