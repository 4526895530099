import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import TextField from './TextField';
import TablesField from './TablesField';

const GenericStructure = ({ field }) => {
  const { fields, entities, tables } = field.value;

  const genericFields = useMemo(() => {
    return fields.map((f, i) => {
      const entity = entities[f.label];
      const value = entities[f.value];
      return {
        id: entity.norm,
        type: 'generic.field',
        fieldName: entity.text.toTitleCase(),
        value: value.text,
        confidence: f.score,
        tokens: value.tokens,
        pageIdx: entity.page_idx,
        rowIdx: i,
      };
    });
  }, [field]);

  const genericTables = useMemo(() => {
    return (tables || []).map((table, j) => {
      return table.map(row => {
        const rowObj = {};
        Object.entries(row).forEach(([key, value]) => {
          rowObj[entities[key].text] = entities[value] && entities[value].text;
        });
        rowObj.tableIdx = j + 1;
        return rowObj;
      });
    });
  }, [field]);

  return (
    <>
      {genericFields.map((f, i) => (
        <TextField
          field={f}
          data-row={i}
          key={`${field.fieldset}|${f.id}|${f.rowIdx}`}
        />
      ))}
      {genericTables && genericTables.length > 0 && (
        <li className="capture-field" data-table={genericFields.length}>
          <div className="capture-field__inner">
            <div className="capture-field__field">
              <div className="capture-field__field-row">
                <span className="capture-field__field-label">Tables</span>
                <ul className="capture-field__field-value">
                  {(genericTables || []).map((t, i) => {
                    return (
                      <TablesField
                        label={`Table${
                          genericTables.length > 1 ? ` ${i + 1}` : ''
                        }`}
                        rows={t}
                        key={`${field.fieldset}|generic|${t[0].tableIdx}`}
                      />
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </li>
      )}
    </>
  );
};

GenericStructure.propTypes = {
  field: PropTypes.object.isRequired,
};

export default GenericStructure;
