import moment from 'moment';
import TasksService from '../../services/TasksService';

export const REQUEST_TASK_STATS = 'sypht/taskAnalytics/REQUEST_TASK_STATS';
export const RECEIVE_TASK_STATS = 'sypht/taskAnalytics/RECEIVE_TASK_STATS';
export const REQUEST_TASK_TIME_STATS =
  'sypht/taskAnalytics/REQUEST_TASK_TIME_STATS';
export const RECEIVE_TASK_TIME_STATS =
  'sypht/taskAnalytics/RECEIVE_TASK_TIME_STATS';
export const RECEIVE_TASKS_USAGE_STATS =
  'sypht/taskAnalytics/RECEIVE_TASKS_USAGE_STATS';
export const REQUEST_TASKS_USAGE_STATS =
  'sypht/taskAnalytics/REQUEST_TASKS_USAGE_STATS';

const TASK_TIME_CUTOFF = 60 * 60; // 60 minutes

const requestTaskStats = () => {
  return {
    type: REQUEST_TASK_STATS,
    loading: true,
    payload: {},
  };
};

const receiveTaskStats = data => {
  return {
    type: RECEIVE_TASK_STATS,
    payload: {
      data,
      loading: false,
      receivedAt: Date.now(),
    },
  };
};

const requestTaskTimeStats = () => {
  return {
    type: REQUEST_TASK_TIME_STATS,
    loading: true,
    payload: {},
  };
};

const receiveTaskTimeStats = ({ mean }) => {
  return {
    type: RECEIVE_TASK_TIME_STATS,
    payload: {
      mean,
      loading: false,
      receivedAt: Date.now(),
    },
  };
};

const requestTasksUsageStats = () => {
  return {
    type: REQUEST_TASKS_USAGE_STATS,
    payload: {},
  };
};

const receiveTasksUsageStats = data => {
  return {
    type: RECEIVE_TASKS_USAGE_STATS,
    payload: {
      data,
    },
  };
};

export const fetchTaskTimeStats = ({ startDate, endDate }) => {
  return async (dispatch, getState) => {
    const {
      auth: { accessToken, user },
    } = getState();
    dispatch(requestTaskTimeStats());
    let {
      data: { annotations },
    } = await TasksService.listAnnotations({ startDate, endDate, accessToken });

    // filter out incomplete annotations, calculate duration and sort ascending
    annotations = annotations
      .filter(a => a.received !== null && a.state === 'submitted')
      .map(a => ({
        ...a,
        duration: moment(a.updated).diff(moment(a.received)),
      }))
      .filter(a => a.duration > 0 && a.duration < TASK_TIME_CUTOFF)
      .sort((a, b) => a.duration - b.duration);

    // slice out the bottom 95%
    annotations = annotations.slice(
      0,
      Math.floor((annotations.length - 1) * 0.95),
    );
    const sum = annotations.reduce((a, b) => (a += b.duration), 0);
    const mean = Math.round(sum / annotations.length);
    dispatch(receiveTaskTimeStats({ mean }));
  };
};

export const fetchTasksUsage = filters => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken, user } = auth;
    const { companyId } = user.data || {};
    if (!companyId) return;
    dispatch(requestTasksUsageStats());
    const usages = await Promise.all([
      TasksService.getTasksUsage(accessToken, companyId, {
        ...filters,
        state: 'created',
      }),
      TasksService.getTasksUsage(accessToken, companyId, {
        ...filters,
        state: 'in_progress',
      }),
      TasksService.getTasksUsage(accessToken, companyId, {
        ...filters,
        state: 'completed',
      }),
    ]);
    const response = {
      Created: usages[0] && usages[0].data ? usages[0].data : [],
      'In progress': usages[1] && usages[1].data ? usages[1].data : [],
      Completed: usages[2] && usages[2].data ? usages[2].data : [],
    };
    dispatch(receiveTasksUsageStats(response));
  };
};
