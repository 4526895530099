import ingest, {
  manualSplit,
  smartSplit,
} from '../../services/workflowService';

export const REQUEST_INGEST = 'sypht/workflows/REQUEST_INGEST';
export const RECEIVE_INGEST_RESULTS = 'sypht/workflows/RECEIVE_INGEST_RESULTS';

const requestIngestStart = () => {
  return {
    type: REQUEST_INGEST,
    payload: {
      ingesting: true,
    },
  };
};

const receiveIngestResults = () => {
  return {
    type: RECEIVE_INGEST_RESULTS,
    payload: {
      ingesting: false,
    },
  };
};

export const requestIngest = (fileId, region, isManualSplit, isSmartSplit) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;

    dispatch(requestIngestStart());

    await ingest(accessToken, fileId, region);
    dispatch(receiveIngestResults());

    if (isManualSplit) {
      await manualSplit(accessToken, fileId, region);
    } else if (isSmartSplit) {
      await smartSplit(accessToken, fileId, region);
    }
  };
};
