import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './Card.scss';

const Card = ({ children, className }) => {
  const cardClass = classNames(className, 'card');
  return <div className={cardClass}>{children}</div>;
};

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Card.defaultProps = {
  children: null,
  className: null,
};

export default Card;
