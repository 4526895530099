import React from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'react-inlinesvg';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import Button from '../../../../../library/atoms/Buttons/Button';

import './TourContent.scss';

const TourContent = ({
  icon,
  text,
  buttonText,
  buttonHidden,
  buttonUrl,
  navigate,
  closeTour,
}) => {
  let paragraphs = <p>{text}</p>;

  if (Array.isArray(text)) {
    paragraphs = text.map(item => <p>{item}</p>);
  }

  const navigateToUrl = () => {
    if (buttonUrl) {
      if (closeTour) {
        closeTour();
      } else {
        console.error(
          'Tried to navigate away from the tour and the close tour callback is not defined. Please pass it through the config.',
        );
      }
      navigate(buttonUrl);
    }
  };

  return (
    <>
      <InlineSVG src={icon} className="step-content-image" />
      <div className="step-content-container">
        {paragraphs}
        {buttonHidden === false && buttonText && (
          <Button onClick={navigateToUrl}>{buttonText}</Button>
        )}
      </div>
    </>
  );
};

TourContent.propTypes = {
  icon: PropTypes.node.isRequired,
  text: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  buttonText: PropTypes.string,
  buttonHidden: PropTypes.bool,
  buttonUrl: PropTypes.string,
  navigate: PropTypes.func,
  closeTour: PropTypes.func,
};

TourContent.defaultProps = {
  buttonText: '',
  buttonHidden: false,
  buttonUrl: '',
  navigate: () => {},
  closeTour: () => {},
};

const mapDispatchToProps = dispatch => ({
  navigate: path => dispatch(push(path)),
});

export default connect(null, mapDispatchToProps)(TourContent);
