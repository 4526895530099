import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Page, {
  PageInner,
  PageHeader,
  PageNav,
  PageBody,
  PageLoading,
} from '../../Page';
import { Page404 } from '../../ErrorPage';
import './Company.scss';
import { fetchCompany } from '../../../reducers/admin/actions';
import { sendInviteEmail } from '../../../reducers/user/actions';
import { ModalContextProvider } from '../../UI/Modal/context/ModalContext';
import { TabsContainer, Tabs, Tab, TabPanel } from '../../UI/Tabs';
import { Overview, Subscriptions, Billing } from './components';
import Users from '../../Users/Users';
import Credentials from '../../Credentials/Credentials';

const mapStateToProps = (state, props) => {
  const { match } = props;
  const { admin, products } = state;
  return {
    companyId: match.params.id,
    company: admin.company.data,
    isLoading: admin.company.loading,
    products: products.data || null,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      fetchCompany: companyId => dispatch(fetchCompany(companyId)),
      sendInviteEmail: user => dispatch(sendInviteEmail(user)),
    },
  };
};

const Company = ({ companyId, company, isLoading, dispatch }) => {
  useEffect(() => {
    dispatch.fetchCompany(companyId);
  }, []);

  const resendInviteEmail = () => {
    const accountOwner = company.users.find(u => u.accountOwner);
    dispatch.sendInviteEmail(accountOwner);
  };

  return (
    <ModalContextProvider>
      <ReactTooltip />
      <Page className="admin admin-company" title="Internal Admin">
        <PageInner>
          {!company && !isLoading && <Page404 />}
          {isLoading && <PageLoading />}
          {company && !isLoading && (
            <>
              <PageNav
                items={[
                  { label: 'Internal Admin', to: '/admin' },
                  { label: company.name, to: `admin/companies/${company.id}` },
                ]}
              />
              <PageHeader>
                <h1 className="page__heading">{company.name}</h1>
              </PageHeader>
              <PageBody>
                {company.status === 'pending' && (
                  <button
                    type="button"
                    className="button button-secondary-border button--invite"
                    onClick={resendInviteEmail}
                  >
                    Send Invite Email to Account Owner
                  </button>
                )}
                <TabsContainer defaultTab="overview">
                  <Tabs defaultTab="overview">
                    <Tab tabFor="overview">Overview</Tab>
                    <Tab tabFor="users">Users</Tab>
                    <Tab tabFor="subscriptions">Subscriptions</Tab>
                    <Tab tabFor="credentials">Credentials</Tab>
                    <Tab tabFor="billing">Billing</Tab>
                  </Tabs>
                  <TabPanel id="users">
                    <Users admin companyId={companyId} />
                  </TabPanel>
                  <TabPanel id="subscriptions">
                    <Subscriptions companyId={companyId} />
                  </TabPanel>
                  <TabPanel id="credentials">
                    <Credentials admin companyId={companyId} />
                  </TabPanel>
                  <TabPanel id="overview">
                    <Overview company={company} />
                  </TabPanel>
                  <TabPanel id="billing">
                    <Billing companyId={companyId} />
                  </TabPanel>
                </TabsContainer>
              </PageBody>
            </>
          )}
        </PageInner>
      </Page>
    </ModalContextProvider>
  );
};

Company.propTypes = {
  companyId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  dispatch: PropTypes.shape({}).isRequired,
  company: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    name: PropTypes.string,
    users: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

Company.defaultProps = {
  company: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Company);
