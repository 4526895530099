import * as utils from './utils';

const apiBaseUrl = process.env.APP_API_HOST_NAME;

class EntityService {
  getEntity = (accessToken, companyId, entityType, entityId) => {
    companyId = encodeURIComponent(companyId);
    entityType = encodeURIComponent(entityType);
    entityId = encodeURIComponent(entityId);
    return utils.getData(
      `${apiBaseUrl}/storage/${companyId}/entity/${entityType}/${entityId}`,
      {
        accessToken,
      },
    );
  };

  getEntities = (accessToken, companyId, entityType, entityIds) => {
    companyId = encodeURIComponent(companyId);
    entityType = encodeURIComponent(entityType);
    return utils.postData(
      `${apiBaseUrl}/storage/${companyId}/entity/${entityType}/`,
      {
        accessToken,
        data: entityIds.map(eid => ({
          entity_id: eid,
        })),
      },
    );
  };

  listEntities = (accessToken, companyId, entityType) => {
    companyId = encodeURIComponent(companyId);
    entityType = encodeURIComponent(entityType);
    return utils.getData(
      `${apiBaseUrl}/storage/${companyId}/entitysearch/${entityType}/`,
      {
        accessToken,
      },
    );
  };

  searchEntities = (accessToken, companyId, entityType, fuzzy, exact) => {
    companyId = encodeURIComponent(companyId);
    entityType = encodeURIComponent(entityType);
    return utils.postData(
      `${apiBaseUrl}/storage/${companyId}/entitysearch/${entityType}/`,
      {
        accessToken,
        data: {
          fuzzy: fuzzy,
          exact: exact,
        },
      },
    );
  };
}

export default new EntityService();
