// eslint-disable-next-line no-extend-native
Object.defineProperty(String.prototype, 'unCamelCase', {
  value: function unCamelCase() {
    return (
      this.charAt(0).toUpperCase() +
      this.substr(1)
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
        .replace(/([a-z])([0-9])/gi, '$1 $2')
        .replace(/([0-9])([a-z])/gi, '$1 $2')
    );
  },
  writable: true,
  configurable: true,
});
