import axios from 'axios';

const apiBaseUrl = process.env.APP_API_HOST_NAME;
const demoServiceDomain = process.env.DEMO_SERVICE_HOST_NAME || apiBaseUrl;

export async function retrieveDoc(docId) {
  return axios.get(`${demoServiceDomain}/demo/docs/${docId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export async function retrieveResults(docId) {
  try {
    return await axios.get(`${demoServiceDomain}/demo/result/${docId}/final`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (err) {
    console.error(err);
    return {
      data: {
        error: 'Data not available.',
      },
    };
  }
}

export async function downloadDoc(docSrc) {
  const docUri = !docSrc.startsWith('http')
    ? `${demoServiceDomain}/demo/result/${docSrc}/image`
    : docSrc;

  const response = await fetch(docUri);
  return response.blob();
}

export async function uploadDoc(fileUpload) {
  try {
    const response = await fetch(`${apiBaseUrl}/demo/fileupload`, {
      method: 'POST',
      body: JSON.stringify(fileUpload),
    });

    return response.json();
  } catch (err) {
    console.error(err); // TypeError: failed to fetch
    return undefined;
  }
}
