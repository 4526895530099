import React from 'react';
import PropTypes from 'prop-types';

import './FieldGroup.scss';

const FieldGroup = ({ children }) => {
  return <div className="field-group">{children}</div>;
};

FieldGroup.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FieldGroup;
