import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Page, { PageBody, PageHeader, PageInner, PageNav } from '../../../Page';
import RuleForm from './components/RuleForm/RuleForm';

const mapStateToProps = (state, props) => {
  const { match, history } = props;
  return {
    productId: match.params.productId,
    history,
  };
};

const EditRule = ({ productId, history }) => {
  const rule = history.location.state;

  return (
    <Page className="rules" title="Validate - Edit Rule">
      <PageInner>
        <PageNav
          items={[
            {
              label: 'Automatic generation of review tasks',
              to: '/validate/rules',
            },
            { label: 'Edit rule', to: `/validate/rules/edit` },
          ]}
        />
        <PageHeader className="rules__header">
          <h1 className="page__heading">Edit task generation rule</h1>
        </PageHeader>
        <PageBody>
          <RuleForm productId={productId} rule={rule} />
        </PageBody>
      </PageInner>
    </Page>
  );
};

EditRule.propTypes = {
  productId: PropTypes.string,
  history: PropTypes.object,
};

EditRule.defaultProps = {
  productId: null,
  history: {
    push: () => {},
  },
};

export default connect(mapStateToProps)(EditRule);
