import React from 'react';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import './_select.scss';

const Select = ({ id, className, label, ...props }) => {
  const select = (
    <ReactSelect
      inputId={id}
      classNamePrefix="select"
      className={classNames('select', className)}
      components={makeAnimated()}
      {...props}
    />
  );

  return label ? (
    <div className="select__container">
      {label && (
        <label className="select__label" htmlFor={id}>
          {label}
        </label>
      )}
      {select}
    </div>
  ) : (
    select
  );
};

Select.propTypes = {
  id: PropTypes.string,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  isClearable: PropTypes.bool,
};

Select.defaultProps = {
  id: null,
  className: null,
  label: null,
  placeholder: null,
  value: undefined,
  defaultValue: null,
  isClearable: true,
};

export default Select;
