import React from 'react';
import PropTypes from 'prop-types';
import { FaUpload } from 'react-icons/fa';
import { Button } from '../../../../library/atoms/Buttons';

const UploadButton = ({ onClick, ...props }) => {
  return (
    <Button
      className="docs__action-button"
      onClick={onClick}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <FaUpload />
      <span>Upload</span>
    </Button>
  );
};

UploadButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default UploadButton;
