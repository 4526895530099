import React, { useContext, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { mergeRight as merge } from 'ramda';
import ReactLoading from 'react-loading';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import {
  FaFileInvoice,
  FaUndo,
  FaRedo,
  FaCompress,
  FaExpand,
  FaDownload,
  FaTrashAlt,
  FaCircleNotch,
  FaAngleDoubleRight,
  FaRegCopy,
  FaExclamationCircle,
  FaCut,
} from 'react-icons/fa';
import './DocumentToolbar.scss';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as docsReducer from '../../../reducers/docs';
import { DocumentToolbarContext } from './DocumentToolbarContext';
import NextTaskButton from '../../NextTaskButton/NextTaskButton';
import { Button as ButtonUI } from '../../../library/atoms/Buttons';
import TagDropDown, { getSelectedTags } from '../TagDropdown/TagDropdown';
import DocViewFixButton from '../../../pages/Documents/components/FixButton/DocViewFixButton';
import mustBeAnArrayIfPresent from '../../../common/helpers/mustBeAnArrayIfPresent';
import { TaskTypeLookup } from '../../../common/lookups';
import * as tagsAction from '../../../reducers/tags/action';

const initialApplyTagParams = {
  limit:100,
  includeCounts:true,
  sortDirection:'asc',
  sortColumn:'name',
  selections:null,
  name:null,
}

const mapStateToProps = state => {
  const task = state.tasks.active.data ? state.tasks.active.data.task : null;
  return {
    subscriptions: mustBeAnArrayIfPresent(state.subscriptions.data),
    applyTags: state.tags.applyTags.data,
    loadingData: state.tasks.active.loadingData,
    isFixTask: task && task.taskType === TaskTypeLookup.FIX,
    hasValidateProduct: state.subscriptions.hasValidateProduct,
    canSplitDocument: state.auth.permissions.canSplitDocument,
    canExportDocument: state.auth.permissions.canExportDocument,
    canDeleteDocument: state.auth.permissions.canExportDocument,
    canExtractDocument: state.auth.permissions.canExtractDocument,
    canWriteTag: state.auth.permissions.canWriteTag,
    canTagDocument: state.auth.permissions.canTagDocument,
    companyId: state.auth.user.data ? state.auth.user.data.companyId : null,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchDoc: docId => dispatch(docsReducer.fetchDoc(docId)),
  exportDoc: (docId, hasLineItems) =>
    dispatch(
      docsReducer.exportDocs({ docs: [docId], isLineItems: hasLineItems }),
    ),
  downloadDoc: (docId, filename) =>
    dispatch(docsReducer.downloadDoc(docId, filename)),
  exportNDIS: data => dispatch(docsReducer.exportNDIS(data)), // noOfDocs is 1
  navigate: path => dispatch(push(path)),
  batchUpdateDocumentTags: data =>
    dispatch(tagsAction.batchUpdateDocumentTags(data)),
  createCompanyTag: data => dispatch(tagsAction.createCompanyTag(data)),
  fetchApplyTags: (companyId, params) =>
    dispatch(tagsAction.fetchApplyTags(companyId, params)),
});

const DocumentToolbar = ({
  doc,
  openTaskModal,
  openDeleteModal,
  openExportModal,
  openSplitModal,
  openExtractModal,
  extracting,
  hasLineItems,
  subscriptions,
  companyId,
  applyTags,
  fetchDoc,
  exportDoc,
  downloadDoc,
  exportNDIS,
  batchUpdateDocumentTags,
  createCompanyTag,
  fetchApplyTags,
  isTask,
  task,
  loadingData,
  annotationState,
  editable,
  annotator,
  annotatorOptions,
  navigate,
  saveAnnotationState,
  state,
  isFixTask,
  hasValidateProduct,
  canSplitDocument,
  canExportDocument,
  canDeleteDocument,
  canExtractDocument,
  canWriteTag,
  canTagDocument,
}) => {
  const [toolbarContext, setToolbarContext] = useContext(
    DocumentToolbarContext,
  );
  const { rotation, collapsed } = toolbarContext;
  const { isPending } = doc || {};
  const docNumPages = doc.numOfPages ?? doc.received.numOfPages;
  const [tagSearch, setTagSearch] = useState(null);

  const selectedTags = useMemo(
    () => getSelectedTags([doc]),
    [doc],
  );
  const selections = useMemo(
    () => Object.keys(selectedTags).length > 0 ?
      Object.keys(selectedTags).sort().join(',') :
      null,
    [selectedTags],
  );
  const applyTagParams = useMemo(
    () => {
      let tagName = null;
      if(tagSearch && tagSearch !== ''){
        tagName = tagSearch;
      }
      return {...initialApplyTagParams, selections, name: tagName}
    },
    [selections, tagSearch],
  );

  useEffect(() => {
    if(companyId){
      fetchApplyTags(companyId, applyTagParams);
    }
  }, [companyId, applyTagParams]);

  const handleTagDropDownConfirm = (addTags, removeTags) => {
    const docIds = [doc.id];
    batchUpdateDocumentTags({
      docIds,
      addTags,
      removeTags,
      companyId: doc.companyId,
    }).then(() => {
      fetchDoc(doc.id);
      fetchApplyTags(companyId, applyTagParams);
    });
  };
  const onCreateTag = tagName => {
    createCompanyTag({ name: tagName, companyId }).then(() => {
      fetchApplyTags(companyId, applyTagParams);
    });
  };
  const onTagDropdownSearch = (tagName) => {
    setTagSearch(tagName);
  }
  // #endregion

  const annotatorName = annotator
    ? `${annotator.firstName} ${annotator.lastName || ''}`.trim()
    : '';

  const exportMenuData = [
    <button type="button" onClick={() => exportDoc(doc.docId, hasLineItems)}>
      Predictions
    </button>,
    <button type="button" onClick={() => downloadDoc(doc.docId, doc.fileName)}>
      Original file
    </button>,
  ];

  if (subscriptions && subscriptions.find(s => s.productId.includes('ndis'))) {
    exportMenuData.unshift(
      <button
        type="button"
        onClick={() =>
          exportNDIS({
            docs: [doc.docId],
            total: 1,
            isSelectAll: false,
          })
        }
      >
        NDIS Predictions
      </button>,
    );
  }

  const rotate = degree => {
    const normaliseRotation = (rotation + degree) % 360;
    if (collapsed) {
      setToolbarContext(t =>
        merge(t, {
          collapsed: false,
        }),
      );
    } else {
      setToolbarContext(t =>
        merge(t, {
          rotation: normaliseRotation,
        }),
      );
    }
  };

  const expand = () => {
    setToolbarContext(t =>
      merge(t, {
        rotation: 0,
        collapsed: false,
      }),
    );
  };

  const collapse = () => {
    setToolbarContext(t =>
      merge(t, {
        collapsed: true,
      }),
    );
  };

  const renderLoadingSpinner = () => {
    return loadingData ? (
      <ReactLoading type="spin" color="#5c5c5c" className="loading-spinner" />
    ) : null;
  };

  const renderTaskPageControls = () => {
    if (!isFixTask) {
      return (
        <>
          <div className="seperator">|</div>
          <div className="annotator-label">Annotated by:</div>
          <div style={{ display: 'flex', width: '200px' }}>
            <Select
              isDisabled={editable && !annotationState}
              value={annotator.id}
              placeholder={annotatorName}
              onChange={v => navigate(`/tasks/${task.id}/user/${v.value}`)}
              options={annotatorOptions}
              isClearable={false}
              classNamePrefix="annotator-dropdown"
              className="annotator-select-container"
            />
          </div>
          {state !== 'flagged' && (
            <ButtonUI
              className="btn-flag"
              variant="outline-warning"
              size="sm"
              onClick={() => saveAnnotationState('flagged', 'document')}
            >
              <FaExclamationCircle />
              <span>Flag issue with document</span>
            </ButtonUI>
          )}

          {renderLoadingSpinner()}
        </>
      );
    }
    return <>{renderLoadingSpinner()}</>;
  };

  return (
    <div className="document-toolbar-container">
      {doc && doc.docId && (
        <>
          <div className="push-left">
            <div className="document-toolbar-header">
              {/* For Task Page */}
              {isTask && !isFixTask ? (
                <div className="breadcrumb">
                  <Link
                    data-tooltip-id="toolbar-button-tooltip"
                    data-tooltip-content={`View all ${task.specification.description} tasks`}
                    data-tooltip-place="right"
                    to={{
                      pathname: '/tasks',
                      search: `?specification=${
                        task.specification ? task.specification.name : ''
                      }`,
                    }}
                  >
                    {task && task.specification
                      ? task.specification.description
                      : 'Annotation'}
                  </Link>
                  <FaAngleDoubleRight className="chevron" />
                  <div className="name">{doc.filename}</div>
                </div>
              ) : (
                <div className="name">{doc.filename}</div>
              )}

              <div className="document-toolbar-second-header">
                {/* For Task Page */}
                {isTask && !isFixTask && (
                  <>
                    <CopyToClipboard text={task.id}>
                      <div
                        className="uuid"
                        data-tooltip-id="toolbarright-button-tooltip"
                        data-tooltip-content="Click to copy to clipboard."
                      >
                        <span>
                          Task ID: {task.id.split('-')[0]} <FaRegCopy />
                        </span>
                      </div>
                    </CopyToClipboard>
                    <div className="seperator">|</div>
                  </>
                )}

                <CopyToClipboard text={doc.docId}>
                  <div
                    className="uuid"
                    data-tooltip-id="toolbarright-button-tooltip"
                    data-tooltip-content="Click to copy to clipboard."
                  >
                    <span>
                      Doc ID: {doc.docId.split('-')[0]} <FaRegCopy />
                    </span>
                  </div>
                </CopyToClipboard>
                <div className="seperator">|</div>
                <div className="other">{`No. of Pages: ${docNumPages}`}</div>

                {/* For Task Page */}
                {isTask && renderTaskPageControls()}
              </div>
              <ReactTooltip
                id="toolbarright-button-tooltip"
                place="right"
                style={{
                  top: '4',
                  bottom: '4',
                  left: '4',
                  position: 'fixed',
                  zIndex: 9999,
                }}
              />
            </div>
          </div>

          <div className="push-right">
            {!isTask && (
              <>
                {extracting && canExtractDocument && (
                  <FaCircleNotch className="icon-spin" />
                )}
                {canExtractDocument && (
                  <span
                    data-tooltip-id="toolbar-button-tooltip"
                    data-tooltip-content={
                      isPending ? 'Cannot extract pending documents' : 'Extract'
                    }
                  >
                    <ButtonUI
                      onClick={() => openExtractModal()}
                      disabled={isPending || extracting}
                      isResponsive
                    >
                      <FaFileInvoice />
                      <span>Extract</span>
                    </ButtonUI>
                  </span>
                )}
                {canSplitDocument && (
                  <span
                    className="doc-toolbar-btn--split-doc"
                    data-tooltip-id="toolbar-button-tooltip"
                    data-tooltip-content="Split"
                  >
                    <ButtonUI
                      onClick={() => openSplitModal()}
                      disabled={isPending || extracting}
                      isResponsive
                    >
                      <FaCut />
                      <span>Split</span>
                    </ButtonUI>
                  </span>
                )}
                <span className="doc-toolbar-btn--create-task">
                  <DocViewFixButton
                    doc={doc}
                    isDisabled={!!isPending}
                    showModal={openTaskModal}
                    hasValidateProduct={hasValidateProduct}
                  />
                </span>
                {canTagDocument && (
                  <span>
                    <TagDropDown
                      isDisabled={!doc || !applyTags}
                      enableTagCreation={canWriteTag}
                      tags={applyTags}
                      onConfirm={handleTagDropDownConfirm}
                      onCreateTag={onCreateTag}
                      onSearch={onTagDropdownSearch}
                      selectedTags={selectedTags}
                    />
                  </span>
                )}
                {canExportDocument && (
                  <span
                    className="doc-toolbar-btn--export-doc"
                    data-tooltip-id="toolbar-button-tooltip"
                    data-tooltip-content="Export"
                  >
                    <ButtonUI
                      onClick={() => openExportModal()}
                      disabled={isPending || extracting}
                      isResponsive
                    >
                      <FaDownload />
                      <span>Export</span>
                    </ButtonUI>
                  </span>
                )}
                {canDeleteDocument && (
                  <span
                    data-tooltip-id="toolbar-button-tooltip"
                    data-tooltip-content="Delete document"
                  >
                    <ButtonUI
                      onClick={() => openDeleteModal()}
                      disabled={isPending}
                      isResponsive
                    >
                      <FaTrashAlt />
                      <span>Delete</span>
                    </ButtonUI>
                  </span>
                )}
              </>
            )}
            {collapsed ? (
              <FaCompress
                data-tooltip-id="toolbar-button-tooltip"
                data-tooltip-content="Expand"
                onClick={expand}
              />
            ) : (
              <FaExpand
                data-tooltip-id="toolbar-button-tooltip"
                data-tooltip-content="Fit to view"
                onClick={collapse}
              />
            )}
            <FaUndo
              data-tooltip-id="toolbar-button-tooltip"
              data-tooltip-content="Rotate left"
              onClick={() => rotate(270)}
            />

            <FaRedo
              data-tooltip-id="toolbar-button-tooltip"
              data-tooltip-content="Rotate right"
              onClick={() => rotate(90)}
            />

            {/* For Task Page */}
            {isTask && !isFixTask && (
              <>
                <div
                  style={{
                    display: 'block',
                    marginLeft: '1em',
                    justifyContent: 'flex-end',
                  }}
                >
                  <NextTaskButton disabled={!annotationState} />
                </div>
              </>
            )}
          </div>
        </>
      )}
      <ReactTooltip
        id="toolbar-button-tooltip"
        place="bottom"
        style={{
          top: '4',
          bottom: '4',
          left: '4',
          position: 'fixed',
          zIndex: 9999,
        }}
      />
    </div>
  );
};

DocumentToolbar.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  doc: PropTypes.object.isRequired,
  openTaskModal: PropTypes.func,
  openSplitModal: PropTypes.func.isRequired,
  openDeleteModal: PropTypes.func,
  hasLineItems: PropTypes.bool,
  subscriptions: PropTypes.array,
  companyTags: PropTypes.arrayOf(PropTypes.shape({})),
  exportDoc: PropTypes.func,
  downloadDoc: PropTypes.func,
  fetchDoc: PropTypes.func,
  batchUpdateDocumentTags: PropTypes.func,
  createCompanyTag: PropTypes.func,
  fetchCompanyTags: PropTypes.func,
  isTask: PropTypes.bool,
  task: PropTypes.object,
  loadingData: PropTypes.bool,
  annotationState: PropTypes.string,
  annotatorOptions: PropTypes.array,
  navigate: PropTypes.func,
  editable: PropTypes.bool,
  annotator: PropTypes.object,
  /* eslint-enable react/forbid-prop-types */
  saveAnnotationState: PropTypes.func,
  state: PropTypes.string,
  openExportModal: PropTypes.func,
  openExtractModal: PropTypes.func,
  extracting: PropTypes.bool,
  exportNDIS: PropTypes.func,
  isFixTask: PropTypes.bool,
  hasValidateProduct: PropTypes.bool,
  canSplitDocument: PropTypes.bool.isRequired,
  canExportDocument: PropTypes.bool.isRequired,
  canDeleteDocument: PropTypes.bool.isRequired,
  canExtractDocument: PropTypes.bool.isRequired,
  canWriteTag: PropTypes.bool.isRequired,
  canTagDocument: PropTypes.bool.isRequired,
};

DocumentToolbar.defaultProps = {
  openTaskModal: () => {},
  openExportModal: () => {},
  openExtractModal: () => {},
  openDeleteModal: () => {},
  extracting: false,
  hasLineItems: false,
  subscriptions: [],
  companyTags: [],
  exportDoc: () => {},
  downloadDoc: () => {},
  fetchDoc: () => {},
  batchUpdateDocumentTags: () => {},
  createCompanyTag: () => {},
  fetchCompanyTags: () => {},
  exportNDIS: () => {},
  isTask: false,
  task: null,
  loadingData: false,
  annotationState: null,
  annotatorOptions: [],
  navigate: () => {},
  annotator: null,
  editable: false,
  saveAnnotationState: () => {},
  state: null,
  isFixTask: false,
  hasValidateProduct: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentToolbar);
export { default as DocumentToolbarContext } from './DocumentToolbarContext';
