import React from 'react';
import PropTypes from 'prop-types';
import './DropdownListItem.scss';
import classNames from 'classnames';

const DropdownListItem = ({ children, onClick, selected }) => {
  return (
    <button
      type="button"
      className={classNames(
        'dropdown-list-item',
        selected && 'dropdown-list-item--selected',
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

DropdownListItem.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

DropdownListItem.defaultProps = {
  selected: false,
};

export default DropdownListItem;
