/* eslint-disable react/prop-types */
import React from 'react';
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleLeft,
  FaAngleRight,
} from 'react-icons/fa';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './_pagination.scss';
import Button from '../../../../atoms/Buttons/Button';

const Pagination = ({ className, tableProps }) => {
  const {
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    pageCount,
    pageOptions,
    previousPage,
    state: { pageIndex },
  } = tableProps;
  return (
    <div className={classNames('pagination', className)}>
      <div className="pagination__left">
        <Button
          className="pagination__btn"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          <FaAngleDoubleLeft />
        </Button>
        <Button
          className="pagination__btn"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <FaAngleLeft />
        </Button>
      </div>
      <div className="pagination__center">
        Page
        <input
          className="pagination__input"
          type="number"
          value={pageIndex + 1}
          onChange={e => {
            gotoPage(Number(e.target.value) - 1);
          }}
        />
        of {pageOptions.length || 1}
      </div>
      <div className="pagination__right">
        <Button
          className="pagination__btn"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          <FaAngleRight />
        </Button>
        <Button
          className="pagination__btn"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          <FaAngleDoubleRight />
        </Button>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  className: PropTypes.string,
  tableProps: PropTypes.shape({}).isRequired,
};

Pagination.defaultProps = {
  className: null,
};

export default Pagination;
