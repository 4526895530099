import { mergeRight as merge } from 'ramda';
import { SpecificationLookup } from '../common/lookups';
import { transformSortBy } from '../pages/Tasks/helpers';
import TasksService from '../services/TasksService';
import history from '../services/historyService';
import { addNotification, errorNotification } from './notifications';
import { STYLE } from '../components/UI/Notification/Notification';

export const CLEAR_NEXT_TASK = 'sypht/tasks/CLEAR_NEXT_TASK';
/**
 * Put page into loading state and record the filters and sortBy settings used
 * in the request.
 */
export const REQUEST_NEXT_TASK = 'sypht/tasks/REQUEST_NEXT_TASK';
export const RECEIVE_NEXT_TASK = 'sypht/tasks/RECEIVE_NEXT_TASK';
export const SET_NEXT_TASK_FILTERS = 'sypht/tasks/SET_NEXT_TASK_FILTERS';

const initial = {
  data: null,
  filters: null,
  sortBy: null,
  loading: false,
};

export default function nextTaskReducer(state = initial, action) {
  switch (action.type) {
    case CLEAR_NEXT_TASK:
      return merge(state, {
        data: null,
      });

    case REQUEST_NEXT_TASK:
      return merge(state, {
        filters: action.payload.filters,
        sortBy: action.payload.sortBy,
        loading: true,
      });

    case RECEIVE_NEXT_TASK:
      return merge(state, {
        data: action.data,
        loading: false,
      });

    case SET_NEXT_TASK_FILTERS:
      return merge(state, {
        filters: action.payload.filters,
        sortBy: action.payload.sortBy,
      });

    default:
      return state;
  }
}

export const clearNextTask = () => ({
  type: CLEAR_NEXT_TASK,
});

export const requestNextTask = payload => ({
  type: REQUEST_NEXT_TASK,
  payload,
});

export const receiveNextTask = (data, error) => ({
  type: RECEIVE_NEXT_TASK,
  data,
  error,
});

/**
 * Fetch next task using latest filters which are assumed to have been set by
 * the user or their context.  (Filters are also persisted.)
 *
 * This should run when the Tasks page filters change, storing the latest
 * filters used by the user.
 */
export const fetchNextTask = ({ filters, sortBy }) => {
  return async (dispatch, getState) => {
    const state = getState();
    if (state.nextTask.loading) {
      return undefined;
    }
    dispatch(requestNextTask({ filters, sortBy }));
    return TasksService.getNextTask(getState().auth.accessToken, {
      filters,
      sortBy: transformSortBy(sortBy),
    })
      .then(rs => dispatch(receiveNextTask(rs.data, null)))
      .catch(e => dispatch(receiveNextTask(null, e)));
  };
};

export const fetchNextTaskUsingStoredFilters = () => {
  return async (dispatch, getState) => {
    const { nextTask } = getState();
    const { filters, sortBy } = nextTask || {};
    dispatch(fetchNextTask({ filters, sortBy }));
  };
};

/**
 * Take user to their next task using latest filters which are assumed to have
 * been set by the user or their context.  The filters will be persisted.
 */
export const goToNextTask = ({ filters, sortBy }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const { auth } = state;
    const user = auth.user.data;
    try {
      // Save the filters to redux and set loading state(!)
      await dispatch(requestNextTask({ filters, sortBy }));

      // FIXME: Race condition with other users:
      // If 2 users get to here at the same time, they may end up on the same
      // next task even if that task only needs one more reviewer.
      const { data } = await TasksService.getNextTask(auth.accessToken, {
        filters,
        sortBy: transformSortBy(sortBy),
      });
      const { nextTaskId, nextTask } = data;

      // Unset loading state.  Update the next task button.
      // Edge case: If it turns out there are no tasks to do, the button will
      // become disabled.
      dispatch(receiveNextTask(data, null));

      if (!nextTaskId) {
        dispatch(
          addNotification({
            style: STYLE.INFORMATION,
            message: 'No more tasks!',
            timeout: 10000,
          }),
        );

        return;
      }

      // Check if the task is a splitting task, if so, we go to the split
      // page...

      const isDocSplit =
        nextTask.specification?.name === SpecificationLookup.DOCUMENT_SPLIT;
      if (isDocSplit) {
        history.push(`/documents/split/${nextTaskId}/user/${user.id}`);
        return;
      }

      // Go to standard task page...

      history.push(`/tasks/${nextTaskId}/user/${user.id}`);
    } catch (err) {
      dispatch(receiveNextTask(null, err));
      dispatch(
        errorNotification(
          'Something went wrong, please try reloading the page.',
        ),
      );
    }
  };
};

/**
 * Set next task filters to filter for just doc splits.
 *
 * This action exists only to support users on the pending docs tab when they
 * start processing doc split tasks.
 */
export const setNextDocSplitTaskFilters = () => {
  return (dispatch, getState) => {
    const state = getState();
    const { auth } = state;
    const user = auth.user.data;
    // Omit sortBy, means we will use the default sort from the api.
    const sortBy = undefined;
    // Set sensible defaults + splitting task:
    const filters = {
      state: ['created', 'in_progress'],
      reviewerId: [user.id],
      reviewState: ['not_started', 'started'],
      specification: SpecificationLookup.DOCUMENT_SPLIT,
    };
    dispatch({ type: SET_NEXT_TASK_FILTERS, payload: { filters, sortBy } });
  };
};

/**
 * Find the user's next task using next task filters that were previously
 * persisted.
 */
export const goToNextTaskUsingStoredFilters = () => {
  return async (dispatch, getState) => {
    const { nextTask } = getState();
    const { filters, sortBy } = nextTask || {};
    dispatch(goToNextTask({ filters, sortBy }));
  };
};

export const deleteTaskAnnotations = taskId => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { userId, accessToken } = auth;

    await TasksService.deleteTaskAnnotations(accessToken, taskId, userId);
  };
};
