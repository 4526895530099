import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FaSearch } from 'react-icons/fa';
import colours from '../../styles/colours.scss';
import './Input.scss';

const Input = ({ onChange, isOpen, initial }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current && isOpen) {
      inputRef.current.focus();
    }

    if (inputRef.current && !isOpen) {
      if (inputRef.current.value !== initial) {
        inputRef.current.value = initial;
        onChange('');
      }
    }
  }, [inputRef, isOpen, initial]);

  const onTextChanged = () => {
    if (inputRef.current) {
      onChange(inputRef.current.value);
    }
  };

  return (
    <div className="input-container">
      <FaSearch color={colours['dark-200']} />
      <input ref={inputRef} type="text" onChange={onTextChanged} />
    </div>
  );
};

Input.propTypes = {
  onChange: PropTypes.func,
  isOpen: PropTypes.bool,
  initial: PropTypes.string,
};

Input.defaultProps = {
  isOpen: false,
  initial: '',
  onChange: () => {},
};

export default Input;
