import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FaLink, FaCircle } from 'react-icons/fa';
import { getConfidence } from '../../../../../common/helpers';

const EntityValue = ({ field }) => {
  const confidence = getConfidence(field.confidence_norm);

  return (
    <div className={classNames('field', 'entity')}>
      <h3>{field.fieldName}</h3>
      <div className="entity-type">
        <FaLink className="field-type" /> {field.value_norm.type} &raquo; <br />
        {field.value_norm.id}
        <FaCircle
          data-tooltip-content={confidence.text}
          data-tooltip-place="right"
          data-tooltip-id="document-tooltip"
          size={10}
          className={classNames('field-confidence', confidence.label)}
        />
      </div>
      <div />
      <div className="entity-data">
        {Object.keys(field.value_norm.data).map(k => (
          <div key={k} className="entity-data-item">
            <div className="entity-data-item-key">{k}</div>
            <div className="entity-data-item-value">
              {JSON.stringify(field.value_norm.data[k]).replace(
                // eslint-disable-next-line no-useless-escape
                /^\"+|\"+$/g,
                '',
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

EntityValue.propTypes = {
  field: PropTypes.shape({
    confidence_norm: PropTypes.number,
    fieldName: PropTypes.string,
    value_norm: PropTypes.shape({
      type: PropTypes.string,
      id: PropTypes.string,
      data: PropTypes.shape({}),
    }),
  }),
};

EntityValue.defaultProps = {
  field: null,
};

export default EntityValue;
