import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './Dropdown.scss';

const animatedComponents = makeAnimated();

const Dropdown = ({ id, className, icon, label, ...props }) => {
  const dropdown = (
    <Select
      inputId={id}
      classNamePrefix="dropdown"
      className={classNames('dropdown', className, {
        'dropdown--icon': icon,
      })}
      components={animatedComponents}
      {...props}
    />
  );

  return label || icon ? (
    <div className="dropdownV2__container">
      {label && (
        <label className="dropdownV2__label" htmlFor={id}>
          {label}
        </label>
      )}
      {icon && <i className="dropdownV2__icon">{icon}</i>}
      {dropdown}
    </div>
  ) : (
    dropdown
  );
};

Dropdown.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.node,
  label: PropTypes.string,
};

Dropdown.defaultProps = {
  id: null,
  className: null,
  icon: null,
  label: null,
};

export default Dropdown;
