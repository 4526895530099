import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const PageHelmet = ({ canonical, title, suffix, ...props }) => {
  return (
    <Helmet {...props}>
      <title>
        {title}
        {suffix}
      </title>
      {canonical ? <link rel="canonical" href={canonical} /> : null}
    </Helmet>
  );
};

PageHelmet.propTypes = {
  canonical: PropTypes.string,
  title: PropTypes.string.isRequired,
  suffix: PropTypes.string,
};

PageHelmet.defaultProps = {
  canonical: null,
  suffix: ' - Sypht App',
};

export default PageHelmet;
