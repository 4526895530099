import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import './_modal.scss';

const Modal = ({ children, isOpen }) => {
  return ReactDOM.createPortal(
    isOpen && <div className="m-modal">{children}</div>,
    document.getElementById('portal'),
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
};

Modal.defaultProps = {
  children: null,
  isOpen: false,
};

export default Modal;
