import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './_dropdownMenu.scss';

const DropdownMenuElem = ({ children, className, elemName }) => {
  return (
    <div className={classNames(`dropdown-menu__${elemName}`, className)}>
      {children}
    </div>
  );
};

DropdownMenuElem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  elemName: PropTypes.string.isRequired,
};

DropdownMenuElem.defaultProps = {
  children: null,
  className: null,
};

export default DropdownMenuElem;
