export default Object.freeze({
  uploaded_as_is: 'uploaded_as_is',
  split_complete: 'split_complete',
  created_by_split: 'created_by_split',
  split_pending: 'split_pending',
});

export const SplitStateLabelMap = Object.freeze({
  uploaded_as_is: 'Not Split',
  created_by_split: 'Split Document',
  split_complete: 'Source Document',
});
