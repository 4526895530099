import React, { useEffect, useImperativeHandle, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import CheckboxTag from '../../../../../components/UI/Checkbox/CheckboxTag';
import {
  DropdownMenu,
  DropdownMenuBody,
  DropdownMenuHeading,
} from '../../../../../library/molecules/DropdownMenu';
import './_stateFilterMenu.scss';

const StateFilterMenu = ({
  className,
  options,
  defaultSelected,
  forwardRef,
  heading,
  isOpen,
  onChange,
  active,
  hasSelectAll,
}) => {
  const [selectedStates, setSelectedStates] = useState(defaultSelected || []);
  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedAllPartial, setSelectedAllPartial] = useState(false);

  const checkSelectAllState = () => {
    // controls selectAll checkbox
    if (!selectedStates.length) {
      setSelectedAll(false);
      setSelectedAllPartial(false);
    } else if (selectedStates.length === options.length) {
      setSelectedAll(true);
      setSelectedAllPartial(false);
    } else {
      setSelectedAll(false);
      setSelectedAllPartial(true);
    }
  };

  useEffect(() => {
    if (!active) {
      setSelectedStates([]);
    }
  }, [active]);

  useEffect(() => {
    onChange(selectedStates);
    checkSelectAllState();
  }, [selectedStates]);

  useEffect(() => {
    checkSelectAllState();
  }, [options]);

  useImperativeHandle(forwardRef, () => ({
    handleClear: () => {
      setSelectedStates([]);
    },
  }));

  const handleCheckboxChange = e => {
    const updatedStates = [...selectedStates];

    // add to selectedTags string[]
    if (!updatedStates.includes(e.target.value)) {
      updatedStates.push(e.target.value);
      setSelectedStates(updatedStates);
    }

    // remove from selectedTags string[]
    if (!e.target.checked) {
      updatedStates.splice(updatedStates.indexOf(e.target.value), 1);
      setSelectedStates(updatedStates);
    }
  };

  const handleSelectAllChange = () => {
    const newSelectedAll = !selectedAll;
    setSelectedAll(newSelectedAll);

    if (newSelectedAll) {
      const all = options.map(o => o.value);
      setSelectedStates(all);
    } else {
      setSelectedStates([]);
    }
  };

  return (
    <DropdownMenu
      className={classNames('state-filter-menu', className)}
      isOpen={isOpen}
    >
      <DropdownMenuBody>
        <DropdownMenuHeading>{heading}</DropdownMenuHeading>
        {hasSelectAll && (
          <>
            <CheckboxTag
              id="checkbox_tag_all"
              value="all"
              label="Select all"
              checked={selectedAll}
              key="checkbox_tag_all"
              onChange={handleSelectAllChange}
              partialChecked={selectedAllPartial}
            />
            <hr />
          </>
        )}

        {(options || []).map(o => (
          <CheckboxTag
            id={`checkbox_tag_${o.value}`}
            value={o.value}
            label={o.label}
            checked={selectedStates.includes(o.value)}
            key={`checkbox_tag_${o.value}`}
            onChange={handleCheckboxChange}
          />
        ))}
      </DropdownMenuBody>
    </DropdownMenu>
  );
};

StateFilterMenu.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array,
  defaultSelected: PropTypes.array,
  forwardRef: PropTypes.object,
  heading: PropTypes.string,
  isOpen: PropTypes.bool,
  onChange: PropTypes.func,
  active: PropTypes.bool,
  hasSelectAll: PropTypes.bool,
};

StateFilterMenu.defaultProps = {
  className: undefined,
  options: null,
  defaultSelected: undefined,
  forwardRef: {},
  heading: 'Please select at least one option',
  isOpen: false,
  onChange: () => {},
  active: true,
  hasSelectAll: false,
};

export default StateFilterMenu;
