const interceptor = (
  store,
  authService,
  setAuthenticating,
  setAuthenticatedSuccess,
  setAuthenticatedFailed,
) => {
  return config => {
    const { dispatch, subscribe, getState } = store;
    const { auth } = getState();
    const loginProvider = process.env.REACT_APP_OAUTH_PROVIDER;

    if (
      auth.isAuthenticated &&
      auth.expiresAt - 10 < Math.floor(Date.now() / 1000)
    ) {
      const originalRequest = config;

      return new Promise((resolve, reject) => {
        if (!auth.isAuthenticating) {
          dispatch(setAuthenticating());
          authService.refresh((err, authResult) => {
            if (!err) {
              dispatch(setAuthenticatedSuccess(authResult));
              originalRequest.headers.Authorization = `Bearer ${authResult.accessToken}`;
              resolve(originalRequest);
            } else if (err && err.error === 'login_required' && loginProvider) {
              dispatch(setAuthenticatedFailed(err));
              if (loginProvider) {
                const referrer = new URL(window.location.href);
                referrer.pathname = window.location.pathname;
                window.location = `${loginProvider}?referrer=${encodeURIComponent(
                  referrer.href,
                )}`;
              }
              reject(err);
            }
          });
        } else {
          const unsubscribe = subscribe(() => {
            const { auth: newAuth } = getState();
            if (newAuth.accessToken !== auth.accessToken) {
              unsubscribe();
              originalRequest.headers.Authorization = `Bearer ${newAuth.accessToken}`;
              resolve(originalRequest);
            }
          });
        }
      });
    }

    if (config.headers.Authorization !== `Bearer ${auth.accessToken}`) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${auth.accessToken}`;
    }
    return config;
  };
};

export default interceptor;
