import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Highlight from './Highlight';

const Candidate = ({
  candidate,
  isSelected,
  isAnnotated,
  isContext,
  disabled,
  onSelect,
  onDeselect,
}) => {
  const highlightClass = classNames('candidate', {
    annotated: isAnnotated,
    selected: isSelected,
    context: isContext,
  });

  return (
    <Highlight
      bounds={candidate.bounds}
      className={highlightClass}
      disabled={disabled}
      onClick={() => (isSelected ? onDeselect(candidate) : onSelect(candidate))}
    />
  );
};

Candidate.propTypes = {
  candidate: PropTypes.object,
  isSelected: PropTypes.bool,
  isContext: PropTypes.bool,
  isAnnotated: PropTypes.bool,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
  onDeselect: PropTypes.func,
};

Candidate.defaultProps = {
  candidate: false,
  isSelected: false,
  isContext: false,
  isAnnotated: false,
  disabled: false,
  onSelect: () => {},
  onDeselect: () => {},
};

export default Candidate;
