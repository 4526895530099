import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const initialState = {
  showAlert: false,
};

const AlertBannerContext = createContext([{}, () => {}]);

const AlertBannerContainer = ({ children }) => {
  const [state, setState] = useState(initialState);

  return (
    <AlertBannerContext.Provider value={[state, setState]}>
      {children}
    </AlertBannerContext.Provider>
  );
};

AlertBannerContainer.propTypes = {
  children: PropTypes.node,
};

AlertBannerContainer.defaultProps = {
  children: null,
};

export default AlertBannerContainer;
export { AlertBannerContext };
