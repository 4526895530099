export default Object.freeze({
  SuperUserRead: {
    key: 'superuser:read',
    label: 'Super user read access',
  },
  SuperUserWrite: {
    key: 'superuser:write',
    label: 'Super user write access',
  },
  ExportDocument: { key: 'export:document', label: 'export documents' },
  ExtractDocument: { key: 'extract:document', label: 'extract documents' },
  DeleteDocument: { key: 'delete:document', label: 'delete documents' },
  ReadAnalytics: { key: 'read:analytics', label: 'read analytics' },
  ReadAnnotation: { key: 'read:annotation', label: 'read annotations' },
  ReadCompany: { key: 'read:company', label: 'read company' },
  ReadCredential: { key: 'read:credential', label: 'read credentials' },
  ReadDocument: { key: 'read:document', label: 'read documents' },
  ReadEmailSetting: { key: 'read:emailsetting', label: 'read email settings' },
  ReadFieldSet: { key: 'read:fieldset', label: 'read fieldsets' },
  ReadJob: { key: 'read:job', label: 'read jobs' },
  ReadProduct: { key: 'read:product', label: 'read products' },
  ReadProfile: { key: 'read:profile', label: 'read profiles' },
  ReadRole: { key: 'read:role', label: 'read roles' },
  ReadSubscription: { key: 'read:subscription', label: 'read subscriptions' },
  ReadTag: { key: 'read:tag', label: 'read tags' },
  ReadTask: { key: 'read:task', label: 'read tasks' },
  ReadTaskRule: { key: 'read:taskrule', label: 'read taskrules' },
  ReadUser: { key: 'read:user', label: 'read users' },
  ReadEntity: { key: 'read:entity', label: 'read entity' },
  TagDocument: { key: 'tag:document', label: 'tag document' },
  TagTask: { key: 'tag:task', label: 'tag task' },
  WriteAnnotation: { key: 'write:annotation', label: 'write annotations' },
  WriteCompany: { key: 'write:company', label: 'write company' },
  WriteCredential: { key: 'write:credential', label: 'write credential' },
  WriteDocument: { key: 'write:document', label: 'write documents' },
  WriteEmailSetting: {
    key: 'write:emailsetting',
    label: 'write email settings',
  },
  WriteFieldSet: { key: 'write:fieldset', label: 'write fieldsets' },
  WriteProfile: { key: 'write:profile', label: 'write profiles' },
  WriteRole: { key: 'write:role', label: 'write roles' },
  WriteSubscription: {
    key: 'write:subscription',
    label: 'write subscriptions',
  },
  WriteTag: { key: 'write:tag', label: 'write tags' },
  WriteTask: { key: 'write:task', label: 'write tasks' },
  WriteTaskRule: { key: 'write:taskrule', label: 'write taskrules' },
  WriteUser: { key: 'write:user', label: 'write users' },
  WriteEntity: { key: 'write:entity', label: 'write entity' },
  UploadDocument: { key: 'upload:document', label: 'upload document' },
  SplitDocument: {
    key: 'split:document',
    label: 'splitting documents',
  },
  InvokeWorkflow: { key: 'invoke:workflow', label: 'invoke workflow' },
});
