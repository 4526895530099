/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Page, { PageBody, PageHeader, PageInner, PageNav } from '../../../Page';
import LineChart from '../../../UI/Charts/LineChart';
import DashboardDropdown from '../DashboardDropdown/DashboardDropdown';
import TotalTasksPanel from './Panels/TotalTasksPanel';
import { fetchSubscriptions } from '../../../../reducers/subscriptions/actions';
import {
  fetchTaskTimeStats,
  fetchTasksUsage,
} from '../../../../reducers/taskAnalytics/actions';
import { fetchDocs } from '../../../../reducers/docs';
import { fetchTasks } from '../../../../reducers/tasks';
import './TaskCompany.scss';
import AvgTimePanel from './Panels/AvgTimePanel';
import StpPanel from './Panels/StpPanel';
import Summary from './Summary';

const TASKS_FILTER = {
  limit: 1,
  offset: 0,
  filters: {},
};

const mapStateToProps = state => {
  const { subscriptions, auth, taskAnalytics, tasks, docs } = state;
  return {
    billingPeriods: subscriptions.billingPeriods,
    user: auth.user.data,
    subscriptions: subscriptions.data,
    tasks: {
      ...tasks?.data,
      isLoading: tasks.loading,
    },
    docs: {
      ...docs?.data,
      isLoading: docs.loading,
    },
    taskTime: taskAnalytics.taskTime,
    tasksUsage: taskAnalytics.tasksUsage,
    docsCount: docs?.data?.count,
    numTasks: tasks?.data?.numTasks,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      fetchSubscriptions: () => dispatch(fetchSubscriptions()),
      fetchTaskTimeStats: dateRange => dispatch(fetchTaskTimeStats(dateRange)),
      fetchDocs: data => dispatch(fetchDocs(data)),
      fetchTasks: filters => dispatch(fetchTasks(filters)),
      fetchTasksUsage: filters => dispatch(fetchTasksUsage(filters)),
    },
  };
};

const TaskCompany = ({
  billingPeriods,
  dispatch,
  user,
  subscriptions,
  tasksUsage,
}) => {
  const [dateRange, setDateRange] = useState(null);

  const dateRangeOptions = (billingPeriods || []).map(b => ({
    label: `${b.startLabel} - ${b.endLabel}`,
    value: b.id,
    products: b.products,
    startDate: b.startDate,
    endDate: b.endDate,
  }));

  const handleBillingPeriodChange = fieldSetValue => {
    setDateRange(fieldSetValue);
  };

  useEffect(() => {
    if (!subscriptions && user) {
      dispatch.fetchSubscriptions();
    }
  }, [subscriptions, user]);

  // USE EFFECT: BILLING PERIODS LOADED
  useEffect(() => {
    setDateRange(dateRangeOptions[0]);
  }, [billingPeriods]);

  useEffect(() => {
    if (dateRange) {
      dispatch.fetchTaskTimeStats(dateRange);
      dispatch.fetchTasks({
        ...TASKS_FILTER,
        filters: {
          completed: {
            fromDate: dateRange.startDate,
            toDate: dateRange.endDate,
          },
        },
      });
      dispatch.fetchDocs({
        fromDate: dateRange?.startDate,
        toDate: dateRange?.endDate,
      });
      dispatch.fetchTasksUsage({
        state: 'completed',
        fromDate: dateRange.startDate,
        toDate: dateRange.endDate,
      });
    }
  }, [dateRange]);

  const totalUsageSummary = {
    Created: Object.values(tasksUsage?.data?.Created?.usage || {}),
    'In progress': Object.values(
      tasksUsage?.data?.['In progress']?.usage || {},
    ),
    Completed: Object.values(tasksUsage?.data?.Completed?.usage || {}),
  };

  return (
    <Page className="analytics task__analytics" title="Tasks Dashboard">
      <PageInner>
        <PageNav
          items={[
            { label: 'Analytics', to: '/analytics' },
            { label: 'Tasks Dashboard', to: '/analytics/tasks/dashboard' },
          ]}
        />
        <PageHeader>
          <h1 className="page__heading">Company Task Performance</h1>
        </PageHeader>
        <PageBody>
          <div className="row end-xs">
            <div className="col-xs-4">
              <DashboardDropdown
                className="dashboard__dropdown"
                label="Select date range"
                value={dateRange}
                options={dateRangeOptions}
                onChange={handleBillingPeriodChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-4">
              <TotalTasksPanel />
            </div>
            <div className="col-xs-4">
              <AvgTimePanel />
            </div>
            <div className="col-xs-4">
              <StpPanel />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-gutter-b">
              <LineChart
                id="tasks_usage"
                title="Tasks processed over time"
                xLabel="Date"
                yLabel="Tasks processed"
                data={tasksUsage.data}
                isLoading={tasksUsage.loading}
              />
            </div>
          </div>
          <div className="row col-gutter-b task__analytics__summary-section">
            <div className="col-xs-6 task__analytics__summary-gutter-r">
              <Summary title="Total tasks processed in the above time period by:">
                <Summary.List title="Task status" data={totalUsageSummary} />
              </Summary>
            </div>
            <div className="col-xs-6">
              <Summary title="Legend:">
                <div
                  id="task__analytics-legend"
                  className="task__analytics__summary-legend"
                />
              </Summary>
            </div>
          </div>
        </PageBody>
      </PageInner>
      <ReactTooltip
        place="bottom"
        variant="dark"
        className="ReactTooltip"
        id="task-company-tooltip"
      />
    </Page>
  );
};

TaskCompany.defaultProps = {
  billingPeriods: null,
  dispatch: {
    fetchSubscriptions: () => {},
  },
  user: null,
  subscriptions: null,
  taskTime: null,
  docsCount: null,
  numTasks: null,
  docs: null,
  tasks: null,
  tasksUsage: null,
};

TaskCompany.propTypes = {
  billingPeriods: PropTypes.arrayOf(PropTypes.object),
  dispatch: PropTypes.shape({
    fetchSubscriptions: PropTypes.func,
  }),
  user: PropTypes.object,
  subscriptions: PropTypes.arrayOf(PropTypes.object),
  taskTime: PropTypes.object,
  docsCount: PropTypes.number,
  numTasks: PropTypes.number,
  docs: PropTypes.object,
  tasks: PropTypes.object,
  tasksUsage: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskCompany);
