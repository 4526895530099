import React from 'react';
import ReactDom from 'react-dom';
import PageLoading from '../Page/PageLoading';
import './Callback.scss';

const portalRoot = document.getElementById('portal');

const Callback = () => {
  return ReactDom.createPortal(
    <div className="loading-container">
      <PageLoading />
    </div>,
    portalRoot,
  );
};

export default Callback;
