import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FaRegSadTear } from 'react-icons/fa';
import './ErrorPage.scss';

const ErrorPage = ({ message, children }) => {
  return (
    <>
      <div className="row">
        <div className="col-xs-12">
          <div className="error-page-wrapper">
            <div className="404">
              <span className="error-heading">4</span>
              <FaRegSadTear size="80" />
              <span className="error-heading">4</span>
            </div>
            <h2>{message}</h2>
            {children && <span className="error-caption">{children}</span>}
          </div>
        </div>
      </div>
    </>
  );
};

ErrorPage.defaultProps = {
  message: 'Page not found',
  children: null,
};

ErrorPage.propTypes = {
  message: PropTypes.string,
  children: PropTypes.node,
};

export default ErrorPage;
