/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import './Task.scss';
import Prediction from './Prediction';

export default class SuggestionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 3,
      values: null,
      predictionsByValue: null,
      focusTargetValue: null,
    };
  }

  componentWillMount() {
    this.setPredictionsByValue(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.setPredictionsByValue(nextProps);
  }

  setPredictionsByValue = props => {
    const { predictions, needsFocus, hasFocus } = props;
    const { limit } = this.state;
    const predictionsByValue = {};

    predictions.map(p => {
      const key = this.getValueKey(p.value_norm);
      if (key in predictionsByValue) {
        predictionsByValue[key].push(p);
      } else if (
        p.value_norm == null ||
        Object.keys(predictionsByValue).length < limit
      ) {
        predictionsByValue[key] = [p];
      }
    });

    const values = Object.keys(predictionsByValue).sort((a, b) => {
      // always sort nulls to the bottom
      if (a != '\0' && b == '\0') {
        return -1;
      }
      if (a == '\0' && b != '\0') {
        return 1;
      }
      if (a == '\0' && b == '\0') {
        return 0;
      }

      const aMax = Math.max(...predictionsByValue[a].map(p => p.confidence));
      const bMax = Math.max(...predictionsByValue[b].map(p => p.confidence));
      if (aMax > bMax) {
        return -1;
      }
      if (aMax < bMax) {
        return 1;
      }
      return 0;
    });

    this.setState({ predictionsByValue, values });

    if (needsFocus && !hasFocus) {
      this.changeTargetFocusValue(0);
    } else {
      this.setState({ focusTargetValue: null });
    }
  };

  getValueKey = v => {
    return v == null ? '\0' : v;
  };

  changeTargetFocusValue = (offset, props) => {
    props = props || this.props;
    const { focusPrediction } = props;
    const { predictionsByValue, values } = this.state;
    if (values) {
      const selectedIdx = this.hasGlobalFocus()
        ? values.indexOf(this.getValueKey(focusPrediction.value_norm))
        : 0;
      const newIdx = selectedIdx + offset;
      if (values != null && (newIdx < 0 || newIdx >= values.length)) {
        this.setState({ focusTargetValue: null });
      } else {
        this.setState({ focusTargetValue: values[newIdx] });
      }
    }
  };

  onSetPredictionFocus = p => {
    const { setPredictionFocus } = this.props;
    if (p) {
      p.field = this.props.field.id;
    }
    this.setState({ focusTargetValue: null });
    setPredictionFocus(p);
  };

  hasGlobalFocus = props => {
    const { hasFocus, focusPrediction } = props || this.props;
    const { predictionsByValue } = this.state;
    return (
      hasFocus &&
      focusPrediction &&
      this.getValueKey(focusPrediction.value_norm) in predictionsByValue
    );
  };

  handleKeyDown = e => {
    if ((e.key == 'ArrowUp' || e.key == 'ArrowDown') && this.hasGlobalFocus()) {
      e.stopPropagation();
      e.preventDefault();
      this.changeTargetFocusValue(e.key == 'ArrowUp' ? -1 : 1);
    }
  };

  render() {
    const { predictions, focusPrediction, selectPrediction } = this.props;
    const { values, predictionsByValue, focusTargetValue, limit } = this.state;
    return (
      <div className="prediction-list" onKeyDown={this.handleKeyDown}>
        {values.map((v, i) => (
          <Prediction
            key={i}
            instances={predictionsByValue[v]}
            focusPrediction={focusPrediction}
            selectPrediction={selectPrediction}
            setPredictionFocus={this.onSetPredictionFocus}
            needsFocus={v == focusTargetValue}
          />
        ))}
      </div>
    );
  }
}
