import React, { useContext } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Modal from '../../../UI/Modal/Modal';
import AppContext from '../../../App/AppContext';
import './FreeTrialModal.scss';

const FreeTrialModal = ({
  selectedProduct,
  step,
  isLoading,
  isOpen,
  onCancel,
  onClose,
  subscribeTrial,
}) => {
  const [, setAppState] = useContext(AppContext);

  const handleTourClick = e => {
    e.preventDefault();
    setAppState(x => ({ ...x, isTourOpen: true }));
    onClose();
  };

  return (
    <Modal
      className="modal-free-trial"
      cancelLabel="Cancel"
      noCancel={step === 2} // bool
      confirmLabel={step === 1 ? 'Begin Trial' : 'Close'}
      modalLabel={
        step === 1
          ? `You are about to begin a 14-day trial of ${selectedProduct.name} AI`
          : `Your 14-day trial of ${selectedProduct.name} AI is activated`
      }
      showCloseBtn // bool
      isLoading={isLoading}
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onCancel}
      onConfirm={step === 1 ? subscribeTrial : onClose}
    >
      {step === 1 && (
        <div className="modal-free-trial-content">
          <p className="modal-free-trial-info">
            Are you sure you want to continue?
          </p>
        </div>
      )}

      {step === 2 && (
        <div className="modal-free-trial-content">
          <div className="modal-free-trial__confirmation">
            <h3 className="modal-free-trial__heading">Free trial activated!</h3>
            <div className="modal-free-trial__confirmation-icon">
              <FaCheckCircle />
            </div>
            <p>
              This trial will allow you to upload and extract up to 500 pages
              and will expire 14 days from the time of activation.
            </p>
            <p>
              New user? Our{' '}
              <a href="#virtual_tour" onClick={e => handleTourClick(e)}>
                virtual tour
              </a>{' '}
              will guide you through the process of uploading your document and
              running a data extraction.
            </p>
            <p>
              Already a user? Go directly to your{' '}
              <Link to="/documents">documents</Link> to begin uploading and
              extracting
              {selectedProduct.name}.
            </p>
          </div>
        </div>
      )}
    </Modal>
  );
};

FreeTrialModal.propTypes = {
  selectedProduct: PropTypes.object.isRequired,
  step: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  subscribeTrial: PropTypes.func.isRequired,
};

export default FreeTrialModal;
