import React, { Fragment } from 'react';
import classNames from 'classnames';
import { FaFile, FaExternalLinkAlt } from 'react-icons/fa';

const DocumentReferenceValue = ({ field }) => {
  return (
    <div className={classNames('field', 'document-refs')}>
      <h3>{field.fieldName}</h3>
      <ul className="document-links">
        {field.value_norm.map(doc => (
          <li>
            <a href={`/documents/${doc.file_id}`}>
              <FaFile />
              {doc.file_id.substring(0, 13)}
              <FaExternalLinkAlt />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DocumentReferenceValue;
