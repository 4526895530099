import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FaInfoCircle } from 'react-icons/fa';
import Radio from '../../atoms/Radio';
import './_radio-list.scss';

const RadioList = ({
  className,
  id,
  label,
  name,
  options,
  value,
  defaultValue,
  onChange,
  info,
  tooltipId,
}) => {
  const cid = id || name;
  const [selectedOption, setSelectedOption] = useState(defaultValue || {});

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  const handleChange = option => {
    if (!value) {
      setSelectedOption(option);
    }
    onChange(option);
  };

  return (
    <div className={classNames('radio-list', className)}>
      {label && (
        <label className="radio-list__label" htmlFor={cid}>
          {label}
          {info && (
            <FaInfoCircle
              className="radio-list__info"
              data-tooltip-id={tooltipId}
              data-tooltip-place="right"
              data-tooltip-html={info}
              size={16}
            />
          )}
        </label>
      )}
      <ul id={cid} className="radio-list__list">
        {options.map(o => (
          <li className="radio-list__list-item" key={`${cid}|${o.value}`}>
            <Radio
              id={`${cid}|${o.value}`}
              label={o.label}
              name={name}
              value={o.value}
              checked={o.value === selectedOption?.value}
              onChange={() => handleChange(o)}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

RadioList.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  value: PropTypes.any,
  defaultValue: PropTypes.object,
  info: PropTypes.string,
  onChange: PropTypes.func,
  tooltipId: PropTypes.string,
};

RadioList.defaultProps = {
  className: undefined,
  id: undefined,
  label: undefined,
  options: [],
  value: undefined,
  defaultValue: undefined,
  info: undefined,
  onChange: () => {},
  tooltipId: undefined,
};

export default RadioList;
