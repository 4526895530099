export const CLEAR_STATE = 'sypht/auth/CLEAR_STATE';

const clearState = () => ({ type: CLEAR_STATE, payload: {} });

const auth = options => store => next => action => {
  const { dispatch, getState } = store;
  const {
    router: { location },
  } = getState();

  const {
    AuthService,
    loginProvider = null,
    authEvents = [],
    authenticationSuccess,
    authenticationFailed,
    setAuthenticating,
  } = options;

  if (authEvents.includes(action.type)) {
    if (!AuthService.sessionInit) {
      dispatch(setAuthenticating());
      AuthService.checkSSOSession((err, authResult) => {
        if (loginProvider) {
          if (!err) {
            dispatch(authenticationSuccess(authResult));
          } else if (err && err.error === 'login_required') {
            const referrer = new URL(window.location.href);
            referrer.pathname = location.pathname;
            window.location = `${loginProvider}?referrer=${encodeURIComponent(
              referrer.href,
            )}`;
          }
        } else if (err) {
          dispatch(clearState());
        } else {
          dispatch(authenticationSuccess(authResult));
        }
      });
    }

    if (!loginProvider && !AuthService.isInit) {
      // check hash - run once, will catch all

      dispatch(setAuthenticating());
      AuthService.handleAuth((err, authResult) => {
        if (err) {
          dispatch(authenticationFailed(err));
        } else {
          dispatch(authenticationSuccess(authResult));
        }
      });
    }
  }

  return next(action);
};

export default auth;
