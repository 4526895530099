import axios from 'axios';

const apiBaseUrl =
  process.env.VALIDATE_SERVICE_HOST_NAME || process.env.APP_API_HOST_NAME;
const fileploadServiceUrl =
  process.env.FILEUPLOAD_SERVICE_HOST_NAME || process.env.APP_API_HOST_NAME;

class DocsService {
  getDoc = (accessToken, docId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    };
    return axios.get(`${apiBaseUrl}/app/docs/${docId}`, config);
  };

  getDocs = (accessToken, params, cancelToken) => {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
      Accept: 'application/json',
    };
    return axios({
      url: `${apiBaseUrl}/app/docs`,
      method: 'GET',
      accessToken,
      params,
      headers,
      cancelToken: (cancelToken || {}).token,
    });
  };

  getDocTasks = (accessToken, docId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    };
    return axios.get(`${apiBaseUrl}/app/docs/${docId}/tasks`, config);
  };

  getDocResults = (accessToken, docId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    };
    return axios.get(`${apiBaseUrl}/app/docs/${docId}/results`, config);
  };

  getDocPredictions = (accessToken, docId, fields) => {
    return axios.post(
      `${apiBaseUrl}/app/docs/${docId}/predictions`,
      { fields },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'content-type': 'application/json',
          Accept: 'application/json',
        },
      },
    );
  };

  uploadDoc = (accessToken, fileObject) => {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    return axios.post(
      `${fileploadServiceUrl}/fileupload/json`,
      fileObject,
      config,
    );
  };

  downloadDoc = (docUrl, contentType) => {
    const config = {
      responseType: 'blob',
    };
    const axiosInstance = axios.create();
    axiosInstance.interceptors.response.use(res => {
      res.headers = { ...res.headers, 'content-type': contentType };
      return res;
    });

    return axiosInstance.get(docUrl, config);
  };

  exportDocs = (accessToken, params, format) => {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'content-type': 'application/json',
      },
    };

    return axios.post(
      `${apiBaseUrl}/app/docs/export`,
      {
        ...params,
        format,
      },
      config,
    );
  };

  exportNDISDocs = (accessToken, params, format = 'csv') => {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'content-type': 'application/json',
      },
      responseType: 'blob',
    };

    return axios.post(
      `${apiBaseUrl}/app/docs/export/ndis`,
      {
        ...params,
        format,
      },
      config,
    );
  };

  deleteDocs = (accessToken, docs) => {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'content-type': 'application/json',
        Accept: 'application/json',
      },
      data: {
        docs,
      },
    };
    return axios.delete(`${apiBaseUrl}/app/docs`, config);
  };

  extract = (accessToken, docs, products, companyId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    };
    return axios.post(
      `${apiBaseUrl}/app/docs/extract`,
      { docs, products, companyId },
      config,
    );
  };

  split = (accessToken, docs, workflowOptions, companyId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    };
    return axios.post(
      `${apiBaseUrl}/app/docs/split`,
      { docs, workflowOptions, companyId },
      config,
    );
  };

  manualSplit = (accessToken, docId) => {
    return axios.post(
      `${apiBaseUrl}/app/docs/manualsplit`,
      {
        docId,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'content-type': 'application/json',
          Accept: 'application/json',
        },
      },
    );
  };

  getDocsPredictions = (accessToken, docs) => {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    };
    return axios.post(`${apiBaseUrl}/app/docs/predictions`, { docs }, config);
  };

  getDocMeta = (accessToken, docId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    };
    return axios.get(`${apiBaseUrl}/app/docs/${docId}/meta`, config);
  };
}

export default new DocsService();
