import { mergeRight as merge } from 'ramda';
import FieldsService from '../services/FieldsService';

export const REQUEST_FIELDS = 'sypht/tasks/REQUEST_FIELDS';
export const RECEIVE_FIELDS = 'sypht/tasks/RECEIVE_FIELDS';

const initial = {
  data: null,
  loading: false,
};

export const requestFields = () => ({ type: REQUEST_FIELDS });
export const receiveFields = data => ({ type: RECEIVE_FIELDS, data });

export default function fieldsReducer(state = initial, action) {
  switch (action.type) {
    case REQUEST_FIELDS:
      return merge(state, {
        loading: true,
      });
    case RECEIVE_FIELDS:
      return merge(state, {
        data: action.data,
        loading: false,
      });
    default:
      return state;
  }
}

export function fetchFields() {
  return function (dispatch, getState) {
    const token = getState().auth.accessToken;
    dispatch(requestFields());
    return FieldsService.getFields(token)
      .then(({ data }) => dispatch(receiveFields(data)))
      .catch(e => dispatch(receiveFields(e)));
  };
}
