import React from 'react';
import PropTypes from 'prop-types';
import { getConfidence } from '../../../../../common/helpers';

const MultiValueField = ({ field, row }) => {
  if (!field.value) {
    const fieldType = (field.id || 'undefined').toTitleCase();
    console.warn(
      `${fieldType} field "[${row}]${field.id}" not processed. ` +
        `Invalid value of "${field.value}".`,
    );
  }

  return (
    field.value && (
      <li
        className="capture-field"
        data-row={row}
        data-confidence={getConfidence(field.confidence_norm).label}
      >
        <div className="capture-field__inner">
          <div className="capture-field__field">
            <div className="capture-field__field-row">
              <span className="capture-field__field-label">
                {field.fieldName.unCamelCase()}
              </span>
              <ul className="capture-field__field-value">
                {Object.entries(field.value)
                  .filter(([, o]) => o.value)
                  .map(([k]) => (
                    <li
                      className="capture-field__field-value-item"
                      key={`capture-field|${field.id}|${k}`}
                    >
                      {k.replace(`${field.field}.`, '').unCamelCase()}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </li>
    )
  );
};

MultiValueField.propTypes = {
  field: PropTypes.object.isRequired,
  row: PropTypes.number,
};

MultiValueField.defaultProps = {
  row: null,
};

export default MultiValueField;
// Object.entries(field.value).map(([field, value]) => {  }
