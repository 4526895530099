import moment from 'moment';

/**
 * Return all days for given billing period
 * @param {*} billingPeriod
 */
export const getDays = billingPeriod => {
  const days = [];
  const { startDate, endDate } = billingPeriod;
  const currDate = moment(startDate);
  const stopDate = moment(endDate);
  while (currDate <= stopDate) {
    days.push(currDate.clone().format('DD/MM'));
    currDate.add(1, 'days');
  }
  return days;
};
/**
 * Return all weeks for a given billing period
 * @param {*} billingPeriod
 */
export const getWeeks = billingPeriod => {
  const weeks = [];
  const { startDate, endDate } = billingPeriod;
  const currDate = moment(startDate);
  const stopDate = moment(endDate);
  while (currDate <= stopDate) {
    const endOfWeek = currDate.clone().add(6, 'days');
    if (endOfWeek > stopDate) {
      weeks.push(
        `${currDate.clone().format('DD/MM')} - ${stopDate.format('DD/MM')}`,
      );
    } else {
      weeks.push(
        `${currDate.clone().format('DD/MM')} - ${endOfWeek.format('DD/MM')}`,
      );
    }

    currDate.add(7, 'days');
  }
  return weeks;
};

/**
 * Return months for a given billing period,
 * currently assume biling period only returns
 * 1 month.
 * @param {*} billingPeriod
 */
export const getMonths = billingPeriod => {
  const months = [];
  const { startDate, endDate } = billingPeriod;
  const currDate = moment(startDate);
  const stopDate = moment(endDate);
  months.push(`${currDate.format('DD/MM')} - ${stopDate.format('DD/MM')}`);
  return months;
};

/**
 * Transform daily usage/spend array to weekly usage/spend array
 * @param {*} dataset
 * @param {*} mode
 */
export const getWeeklyUsage = (dataset, mode = 'spend') => {
  const weekly = [];
  let totalSum = 0;
  const noOfDays = dataset.length;
  const weekLength = 7;
  const noOfWeeks = Math.ceil(noOfDays / weekLength);

  for (let week = 0; week < noOfWeeks; week++) {
    let weekSum = 0;
    const endOfWeek = Math.min((week + 1) * weekLength, noOfDays);
    for (let day = week * weekLength; day < endOfWeek; day++) {
      const usageToday = parseFloat(dataset[day]);
      const usageYesterday = day == 0 ? 0 : parseFloat(dataset[day - 1]);
      // If spend mode, only increase sum if increased from previous day
      // else increase sum for usage mode.
      if (usageToday !== usageYesterday || mode !== 'spend') {
        weekSum += usageToday;
      }
    }
    totalSum += weekSum;
    weekly.push(totalSum);
    if (mode !== 'spend') totalSum = 0;
  }

  return weekly;
};
