import React from 'react';
import PropTypes from 'prop-types';

import './_modal-header.scss';

const ModalHeader = ({ children }) => (
  <div className="m-modal__header">{children}</div>
);

ModalHeader.propTypes = {
  children: PropTypes.node,
};

ModalHeader.defaultProps = {
  children: null,
};

export default ModalHeader;
