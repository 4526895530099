import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { fetchCompanies } from '../../../reducers/admin/actions';
import { debounce } from '../../../common/helpers';
import Page, {
  PageBody,
  PageHeader,
  PageInner,
  PageNav,
  PageRow,
  PageLoading,
} from '../../Page';
import SearchField from '../../UI/SearchField/SearchField';
import { DataTable, DataCol } from '../../UI/Table';
import { CompanyStatusType } from '../../../common/types';
import Chip from '../../UI/Chip/Chip';
import history from '../../../services/historyService';
import { Button } from '../../../library/atoms/Buttons';
import './Companies.scss';

const DEBOUNCE_INTERVAL = 400;
const mapStateToProps = ({ admin, auth }) => {
  return {
    companies: admin.companies.data,
    isLoading: admin.companies.isLoading,
    pages: admin.companies.pages,
    user: auth.user ? auth.user.data : null,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      fetchCompanies: params => dispatch(fetchCompanies(params)),
    },
  };
};

const setStatus = value => {
  if (!value)
    return (
      <Chip className="users-table__status" color="orange">
        Pending
      </Chip>
    );

  const firstLetter = value.substr(0, 1);
  const returnValue = value.replace(firstLetter, firstLetter.toUpperCase());
  if (value === CompanyStatusType.Active) {
    return (
      <Chip className="users-table__status" color="green">
        {returnValue}
      </Chip>
    );
  }
  if (value === CompanyStatusType.Deactivated) {
    return (
      <Chip className="users-table__status" color="red">
        Inactive
      </Chip>
    );
  }
  if (value === CompanyStatusType.Pending) {
    return (
      <Chip className="users-table__status" color="orange">
        {returnValue}
      </Chip>
    );
  }

  return null;
};

const setColumns = () => {
  return [
    new DataCol('Name', 'name', {
      Cell: ({ original: o }) => o.name,
    }),
    new DataCol('ID', 'id', {
      Cell: ({ original: o }) => o.id,
    }),
    new DataCol('Created', 'created', {
      Cell: ({ original: o }) => moment(o.created).format('MMM DD, YYYY'),
    }),
    new DataCol('Status', 'status', {
      className: 'align--center',
      Cell: ({ value }) => setStatus(value),
    }),
  ];
};

const Companies = ({ companies, dispatch, pages, isLoading }) => {
  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    sortColumn: 'created',
    sortDirection: 'asc',
  });

  useEffect(() => {
    dispatch.fetchCompanies(filters);
  }, [filters]);

  const handleQueryChange = useCallback(
    debounce((filter, newFilters) => {
      const { value } = filter;
      setFilters({
        ...newFilters,
        query: value === '' ? null : value,
        offset: 0,
        limit: 10,
      });
    }, DEBOUNCE_INTERVAL),
    [],
  );
  const handleChange = e => {
    handleQueryChange(e.target, filters);
  };
  const handlePageChange = offset => {
    const newFilters = {
      ...filters,
      offset,
    };
    window.scrollTo(0, 0);
    setFilters(newFilters);
  };
  const columns = setColumns();
  const onSortByChange = option => {
    if (option && option.length > 0) {
      setFilters(f => ({
        ...f,
        sortColumn: option[0].id,
        sortDirection: option[0].desc ? 'desc' : 'asc',
      }));
    }
  };
  return (
    <Page className="admin admin-companies" title="Companies - Internal Admin">
      <PageInner>
        <PageNav items={[{ label: 'Internal Admin', to: '/admin' }]} />
        <PageHeader>
          <h1 className="page__heading">Companies</h1>
        </PageHeader>
        {companies ? (
          <PageBody>
            <PageRow className="admin-companies__actions">
              <SearchField
                onChange={e => handleChange(e)}
                placeholder="Search name or id"
              />
              <Button
                size="sm"
                onClick={() => history.push('/admin/companies/add')}
              >
                Add company
              </Button>
            </PageRow>
            <PageRow>
              <DataTable
                className="admin-table companies-table"
                data={companies}
                manual // important
                pages={pages}
                columns={columns}
                manualSortBy
                onSortedChange={onSortByChange}
                page={filters.offset}
                loading={isLoading}
                defaultSorted={[{ id: 'created', desc: false }]}
                role="button"
                defaultPageSize={filters.limit}
                showPageSizeOptions={false}
                onPageChange={handlePageChange}
                getTrProps={(_state, { original: o }) => {
                  return {
                    onClick: () => history.push(`/admin/companies/${o.id}`),
                  };
                }}
              />
            </PageRow>
          </PageBody>
        ) : (
          <PageLoading />
        )}
      </PageInner>
    </Page>
  );
};

Companies.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object),
  dispatch: PropTypes.shape({
    fetchCompanies: PropTypes.func,
  }),
  user: PropTypes.object,
};

Companies.defaultProps = {
  companies: null,
  dispatch: {
    fetchCompanies: () => {},
  },
  user: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
