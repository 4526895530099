/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './RangeSlider.scss';

const getPercentage = (current, max) => (100 * current) / max;

const getValue = (percentage, max) => (max / 100) * percentage;

const getLeftPos = percentage => `calc(${percentage}% - 5px)`;

const normaliseValue = (value, increments) =>
  Math.ceil(value / increments) * increments;

const setAxisArray = (min, max, increments) => {
  const axisArray = [];
  let n = min;
  while (n <= max - 1) {
    axisArray.push(n);
    n += increments;
  }
  return axisArray;
};

const RangeSlider = ({
  className,
  defaultValue,
  increments,
  min,
  max,
  onChange,
}) => {
  const initialPct = getPercentage(defaultValue - min, max - min);
  const sliderRef = React.useRef();
  const thumbRef = React.useRef();
  const diff = React.useRef();

  const axisArray = setAxisArray(min, max, increments);
  const incPct = max / ((max - min) / increments);
  let currPct = initialPct;

  const handleMouseMove = e => {
    const start = 0;
    const end = sliderRef.current.offsetWidth - thumbRef.current.offsetWidth;

    let newX =
      e.clientX - diff.current - sliderRef.current.getBoundingClientRect().left;
    if (newX < start) newX = 0;
    if (newX > end) newX = end;

    const newPct = getPercentage(newX, end);
    const newValue =
      min +
      Math.round(
        normaliseValue(getValue(newPct, max), increments) / (max - min),
      );

    thumbRef.current.style.left = getLeftPos(newPct);
    currPct = newPct;
    onChange(newValue < min ? min : newValue);
  };

  const handleMouseUp = () => {
    setTimeout(() => {
      thumbRef.current.style.left = getLeftPos(
        Math.round(currPct / incPct) * incPct,
      );
    }, 100);
    document.removeEventListener('mouseup', handleMouseUp);
    document.removeEventListener('mousemove', handleMouseMove);
  };

  const handleMouseDown = e => {
    diff.current = e.clientX - thumbRef.current.getBoundingClientRect().left;
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <div className={classNames('range-slider', className)}>
      <div className="range-slider__bar" ref={sliderRef}>
        <span
          className="range-slider__thumb"
          style={{ left: getLeftPos(initialPct) }}
          ref={thumbRef}
          onMouseDown={handleMouseDown}
          tabIndex="0"
        />
      </div>
      <ul className="range-slider__axis" ref={sliderRef}>
        {axisArray.map(n => (
          <li
            className="range-slider__axis-item"
            key={`slider_axis|${n}`}
            data-max={n + increments}
          >
            <span className="range-slider__axis-label">{n}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

RangeSlider.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.number,
  increments: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
};

RangeSlider.defaultProps = {
  className: null,
  defaultValue: null,
  increments: 1,
  min: 0,
  max: 100,
  onChange: () => {},
};

export default RangeSlider;
