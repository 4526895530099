import React from 'react';
import PropTypes from 'prop-types';
import './_emptyTable.scss';

const EmptyTable = ({ children }) => {
  return (
    <div className="empty-table">
      <div className="empty-table__inner">{children}</div>
    </div>
  );
};

EmptyTable.propTypes = {
  children: PropTypes.node,
};

EmptyTable.defaultProps = {
  children: null,
};

export default EmptyTable;
