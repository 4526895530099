import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FaCircle } from 'react-icons/fa';
import { getConfidence } from '../../../../../common/helpers';
import Accordion from '../../../../UI/Accordion/Accordion';

import './AbsentFields.scss';

const AbsentFields = ({ fields }) => {
  const absentFields = fields.map(field => {
    const confidence = getConfidence(field.confidence_norm);
    return (
      <div
        key={field.fieldSpec.id}
        className={classNames('field', 'field-disable-hover')}
        data-test-id="absent-field-visible-test"
      >
        <h3>{field.fieldSpec.specification.name}</h3>
        <span>Field not found</span>
        <FaCircle
          data-tooltip-id="document-tooltip"
          data-tooltip-content={confidence.text}
          data-tooltip-place="right"
          size={10}
          className={classNames(
            'absent-field__confidence',
            'field-confidence',
            confidence.label,
          )}
        />
      </div>
    );
  });

  return (
    <Accordion className="absent-field-accordion" key="absent-field-accordion">
      <Accordion.Header>{`${fields.length} fields not found`}</Accordion.Header>
      <Accordion.Panel>{absentFields}</Accordion.Panel>
    </Accordion>
  );
};

AbsentFields.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      confidence_norm: PropTypes.number,
      fieldSpec: PropTypes.shape({ name: PropTypes.string }),
    }),
  ).isRequired,
};

AbsentFields.defaultProps = {};

export default AbsentFields;
