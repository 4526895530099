import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import moment from 'moment';
import Page from '../../Page/Page';

import ReactTable from 'react-table';
import 'react-table-6/react-table.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { fetchSpecs } from '../../../reducers/specs';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';

import './Specs.scss';

const mapStateToProps = (state, props) => {
  const { match } = props;
  return {
    specsData: state.specs.data ? state.specs.data.specifications : null,
    loading: state.specs.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      fetchSpecs: () => dispatch(fetchSpecs()),
      navigate: path => dispatch(push(path)),
    },
  };
};

const Specs = ({ specsData, loading, navigate, dispatch }) => {
  const [filteredSpecs, setFilteredSpecs] = useState(specsData);
  const [filterName, setFilterName] = useState(null);
  const handleNameFilterInputChange = e => {
    setFilterName(e.target.value);
  };
  useEffect(() => {
    dispatch.fetchSpecs();
  }, []);
  useEffect(() => {
    setFilteredSpecs(
      specsData
        ? specsData.filter(s =>
            filterName ? s.name.includes(filterName) : true,
          )
        : [],
    );
  }, [specsData, filterName]);

  return loading ? (
    <LoadingSpinner className="admin-form__spinner" />
  ) : (
    <Page className="specs" title="Specs - Workbench">
      <div className="page__body">
        <div className="page__row">
          <div className="page__header">
            <h1 className="page__heading">Specs</h1>
          </div>
        </div>
        <div className="page__row">
          <input
            className="search__query"
            type="text"
            name="query"
            defaultValue={filterName}
            onChange={e => handleNameFilterInputChange(e)}
            placeholder="Search by name"
            autoComplete="off"
          />
        </div>
        <div className="page__row">
          <div className="spec-list">
            <ReactTooltip place="bottom" variant="dark" />
            <ReactTable
              style={{ flexGrow: 1, width: '100%', background: 'white' }}
              className="-highlight"
              data={filteredSpecs || []}
              defaultPageSize={(filteredSpecs || []).length}
              showPaginationBottom
              showPageSizeOptions={false}
              pages={1}
              minRows={0}
              pageText=""
              previousText=""
              nextText=""
              onPageChange={() => window.scrollTo(0, 0)}
              manual
              loading={loading}
              columns={[
                {
                  Header: 'Name',
                  accessor: 'name',
                },
                {
                  Header: 'Created',
                  accessor: 'created',
                  Cell: c => moment(c.original.created).format('MMM Do YYYY'),
                },
                {
                  Header: 'Fields',
                  Cell: row => {
                    return row.original.data.fields.length;
                  },
                  maxWidth: 100,
                },
              ]}
              getTdProps={(state, rowInfo, column, instance) => ({
                onClick: e => {
                  const specUrl = `specs/${rowInfo.original.name}`;
                  if (e.metaKey || e.ctrlKey) {
                    window.open(specUrl, '_blank');
                  } else {
                    dispatch.navigate(specUrl);
                  }
                },
              })}
              noDataText={
                filteredSpecs && filteredSpecs.length > 0
                  ? 'No matching tasks'
                  : filteredSpecs && filteredSpecs.length == 0
                  ? 'No specs'
                  : 'No data'
              }
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

Specs.propTypes = {
  specs: PropTypes.array,
  loading: PropTypes.bool,
  dispatch: PropTypes.shape({
    fetchSpecs: PropTypes.func,
  }),
};

Specs.defaultProps = {
  specs: null,
  loading: false,
  dispatch: {
    fetchSpecs: () => {},
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(Specs);
