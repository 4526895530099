/**
 * Takes into account both size of image element and parent container to provide
 * more accurate and adjusted bounds.
 * @param {*} bounds
 * @param {*} pageId
 * @returns
 */
export const getAdjustedBounds = (bounds, pageId) => {
  const containerElem = document.getElementById(`pageContainer-${pageId}`);
  const imageElem = document.getElementById(`imageElement-${pageId}`);
  if (containerElem && imageElem) {
    const containerBounds = containerElem.getBoundingClientRect();
    const imageBounds = imageElem.getBoundingClientRect();
    const minX = imageBounds.left - containerBounds.left;
    const minY = imageBounds.top - containerBounds.top;
    return {
      topLeft: {
        x:
          (bounds.topLeft.x * containerBounds.width - minX) / imageBounds.width,
        y:
          (bounds.topLeft.y * containerBounds.height - minY) /
          imageBounds.height,
      },
      bottomRight: {
        x:
          (bounds.bottomRight.x * containerBounds.width - minX) /
          imageBounds.width,
        y:
          (bounds.bottomRight.y * containerBounds.height - minY) /
          imageBounds.height,
      },
      pageNum: bounds.pageNum,
    };
  }
  return bounds;
};

/**
 * Provides the bounds for a token for a given 90 degree rotated context
 * @param {*} bounds
 * @param {*} rotation
 * @returns
 */
export const getRotatedBounds = (bounds, rotation) => {
  switch (rotation) {
    case 0:
      return bounds;
    case 90:
      return {
        topLeft: {
          x: bounds.topLeft.y,
          y: 1 - bounds.bottomRight.x,
        },
        bottomRight: {
          x: bounds.bottomRight.y,
          y: 1 - bounds.topLeft.x,
        },
        pageNum: bounds.pageNum,
      };
    case 180:
      return {
        topLeft: {
          x: 1 - bounds.bottomRight.x,
          y: 1 - bounds.bottomRight.y,
        },
        bottomRight: {
          x: 1 - bounds.topLeft.x,
          y: 1 - bounds.topLeft.y,
        },
        pageNum: bounds.pageNum,
      };
    case 270:
      return {
        topLeft: {
          x: 1 - bounds.bottomRight.y,
          y: bounds.topLeft.x,
        },
        bottomRight: {
          x: 1 - bounds.topLeft.y,
          y: bounds.bottomRight.x,
        },
        pageNum: bounds.pageNum,
      };
    default:
      return bounds;
  }
};

/**
 * Scroll Page in DocumentView into view.
 */
export const scrollPageIntoView = pageId => {
  const element = document.getElementById(`pageContainer-${pageId}`);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};

/**
 * Get the pageId for a page in a document.
 *
 * @param {*} index Array index
 * @returns
 */
export const getPageId = index => {
  return String(index + 1);
};

/**
 * Build array of pageIds for a document, falls back to no pages.
 *
 * @param {*} doc A doc object representing a document
 * @returns
 */
export const getPageIds = doc => {
  return Array.from(
    {
      length: doc && doc.received ? doc.received.numOfPages : 0,
    },
    (v, k) => getPageId(k),
  );
};
