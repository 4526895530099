/* eslint-disable import/prefer-default-export */
export const getConfidence = score => {
  if (Number.isNaN(score)) {
    return {
      label: undefined,
      text: undefined,
    };
  }

  const scorePct = Math.round(score * 100);
  switch (true) {
    case score >= 0.9:
      return {
        label: 'high',
        text: `High confidence level (${scorePct}%) of prediction`,
      };
    case score >= 0.6:
      return {
        label: 'medium',
        text: `Medium confidence level (${scorePct}%) of prediction`,
      };
    case Number.isNaN(Number(scorePct)): // <-- added this as im not sure the impact of doing a proper isNaN check in the beginning.
      // this is to explicitly catch the case where the confidence is not present on a field. eg: absent fields
      // as a field not found on a document might not have a confidence value by design.
      return {
        label: 'low',
        text: `Low confidence level (0%) of prediction`,
      };
    default:
      return {
        label: 'low',
        text: `Low confidence level (${scorePct}%) of prediction`,
      };
  }
};
