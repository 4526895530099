/* eslint-disable no-restricted-globals */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactDataGrid from 'react-data-grid';
import { FaInfinity, FaTrashAlt, FaPlusCircle } from 'react-icons/fa';
import { compareSortByProp } from '../../../../common/helpers/arrayHelper';
import Button from '../../../../library/atoms/Buttons/Button';

import './PricingTable.scss';

const DEFAULT_NEXT_THRESHOLD = 5000;
/**
 * The unit (per doc or per page) price for a given tier.
 */
const MINIMUM_UNIT_PRICE = 0.01;
/**
 * The flat price for a given tier.
 */
const MINIMUM_FLAT_PRICE = 0;

const EmptyRowsView = () => {
  const message = 'No data to show';
  return (
    <div className="no-data">
      <h4>{message}</h4>
    </div>
  );
};

const thresholdFormatter = ({ value }) => {
  if (value === 'inf' || value === null) {
    return <FaInfinity />;
  }

  return value;
};

const priceFormatter = ({ value }) => {
  const price = `$${parseFloat(value).toFixed(2)}`;
  return price;
};

// prettier-ignore
const PRICING_COLS = [
  // { key: 'name', name: 'Name', editable: true },
  { key: 'firstUnit', name: 'First Unit'},
  { key: 'threshold', name: 'Last Unit', editable: true, formatter: thresholdFormatter },
  { key: 'unitPrice', name: 'Per Unit', editable: true, formatter: priceFormatter } ,
  // { key: 'flatPrice', name: 'Flat Fee', editable: true, formatter: priceFormatter },
  { key: 'action', name: 'Action', width: 60 },
];

const PricingTable = ({ rows, onUpdate }, props) => {
  const sortTableData = dataRows => {
    // auto sorting by threshold
    dataRows.sort(compareSortByProp('threshold'));

    const updatedRows = dataRows.map((current, idx, array) => {
      const arr = { ...current };

      // 2nd row up
      if (idx) {
        const firstUnit = array[idx - 1].threshold + 1;
        arr.firstUnit = firstUnit;
        return arr;
      }

      // 1st row
      arr.firstUnit = 0;
      return arr;
    });

    return updatedRows;
  };

  const handlePricingUpdate = ({ fromRow, toRow, updated, cellKey }) => {
    onUpdate(state => {
      const targetCell = {
        // Make sure to set a min flatPrice because we currently don't show the
        // flatPrice column.  Some marketplace prices may have been updated with
        // a non-zero flatPrice mistakenly prior to Jul-2023 (a very small
        // amount of $0.01) probably around the time the flatPrice column was
        // removed.
        flatPrice: MINIMUM_FLAT_PRICE,
        ...updated,
      };

      // for formatting cell values
      const cellVal = targetCell[cellKey];
      switch (cellKey) {
        case 'threshold':
          if (cellVal === '' || isNaN(cellVal)) {
            targetCell[cellKey] = 'inf';
          } else {
            targetCell[cellKey] = parseInt(cellVal, 10);
          }
          break;

        case 'unitPrice':
          if (isNaN(cellVal) || cellVal <= 0) {
            targetCell[cellKey] = MINIMUM_UNIT_PRICE;
          } else {
            targetCell[cellKey] = parseFloat(cellVal);
          }
          break;

        // NOTE: the flatPrice column has been disabled so this may not be called:
        case 'flatPrice':
          if (isNaN(cellVal) || cellVal <= 0) {
            targetCell[cellKey] = MINIMUM_FLAT_PRICE;
          } else {
            targetCell[cellKey] = parseFloat(cellVal);
          }
          break;

        default:
          break;
      }

      // insert new cell value
      const sRow = state.slice();
      // eslint-disable-next-line no-plusplus
      for (let i = fromRow; i <= toRow; i++) {
        sRow[i] = { ...sRow[i], ...targetCell };
      }

      return sortTableData(sRow);
    });
  };

  const handleAddPricing = () => {
    onUpdate(state => {
      const newThreshold = state.length
        ? Math.max(
            ...[...state]
              .filter(v => typeof v.threshold !== 'string')
              .map(o => o.threshold),
          ) + 2
        : null;
      let arr;
      // if pricing is newly
      if (newThreshold === null) {
        arr = [
          {
            name: 'For the first',
            threshold: 30,
            unitPrice: MINIMUM_UNIT_PRICE,
            flatPrice: MINIMUM_FLAT_PRICE,
          },
          ...state,
          {
            name: 'For the last',
            threshold: 'inf',
            unitPrice: MINIMUM_UNIT_PRICE,
            flatPrice: MINIMUM_FLAT_PRICE,
          },
        ];
      } else {
        arr = [
          ...state,
          {
            name: 'For the next',
            threshold:
              newThreshold < DEFAULT_NEXT_THRESHOLD
                ? DEFAULT_NEXT_THRESHOLD
                : newThreshold,
            unitPrice: MINIMUM_UNIT_PRICE,
            flatPrice: MINIMUM_FLAT_PRICE,
          },
        ];
      }

      return sortTableData(arr);
    });
  };

  // eslint-disable-next-line consistent-return
  const getCellActions = (column, row) => {
    if (row.threshold !== 'inf') {
      const cellActions = [
        {
          icon: <FaTrashAlt />,
          callback: () => {
            let arr = rows.slice();
            arr = arr.filter(r => r !== row);
            arr.forEach((r, i, a) => {
              if (i === 0) {
                // eslint-disable-next-line no-param-reassign
                r.firstUnit = 0;
              } else {
                // eslint-disable-next-line no-param-reassign
                r.firstUnit = parseInt(a[i - 1].threshold, 10) + 1;
              }
            });
            onUpdate(arr);
          },
        },
      ];
      return column.key === 'action' ? cellActions : null;
    }
  };

  return (
    <>
      <div className="pricing-grid-wrapper">
        <ReactDataGrid
          enableCellSelect
          columns={PRICING_COLS}
          rowGetter={i => rows[i]}
          rowsCount={rows.length}
          minHeight={(rows.length + 1) * 37}
          onGridRowsUpdated={handlePricingUpdate}
          getCellActions={getCellActions}
          emptyRowsView={EmptyRowsView}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </div>
      <div className="btn-add-container">
        <Button type="button" onClick={handleAddPricing} size="sm">
          <span>Add pricing</span>
          <FaPlusCircle />
        </Button>
      </div>
    </>
  );
};

PricingTable.propTypes = {
  // Cant work out the data structure for each row here.
  // eslint-disable-next-line react/forbid-prop-types
  rows: PropTypes.array,
  onUpdate: PropTypes.func,
};

PricingTable.defaultProps = {
  rows: [],
  onUpdate: () => {},
};

export default PricingTable;
