import { addNotification } from '../../../reducers/notifications';
import {
  createdMessage,
  updatedMessage,
  deletedMessage,
  batchDeletedMessage,
  batchUpdatedMessage,
  duplicateErrorMessage,
  errorMessage,
} from '../../../reducers/tags/messages';
import TagService from '../../../services/TagService';

export const CREATE_ROW = 'sypht/manageTags/ADD_TAG';
export const UPDATE_ROW = 'sypht/manageTags/UPDATE_ROW';
export const DELETE_ROW = 'sypht/manageTags/REMOVE_TAG';

export const RECEIVE_TAG = 'sypht/manageTags/RECEIVE_TAG';
export const RECEIVE_TAGS = 'sypht/manageTags/RECEIVE_TAGS';
export const RECEIVE_DELETED_TAGS = 'sypht/manageTags/RECEIVE_DELETED_TAGS';
export const RECEIVE_UPDATED_TAGS = 'sypht/manageTags/RECEIVE_UPDATED_TAGS';

const receiveTag = (tag, tagId) => {
  return {
    type: RECEIVE_TAG,
    payload: {
      data: tag,
      tagId,
    },
  };
};

const receiveTags = tags => {
  return {
    type: RECEIVE_TAGS,
    payload: {
      data: tags,
    },
  };
};

const receiveDeletedTags = tags => {
  return {
    type: RECEIVE_DELETED_TAGS,
    payload: {
      data: tags,
    },
  };
};

const receiveUpdatedTags = tags => {
  return {
    type: RECEIVE_UPDATED_TAGS,
    payload: {
      data: tags,
    },
  };
};

// UI: ADD NEW TAG ROW
export const createRow = () => {
  return async dispatch => {
    dispatch({
      type: CREATE_ROW,
      payload: {},
    });
  };
};

// UI: REMOVE ROW
export const updateRow = (tagId, params) => {
  return async dispatch => {
    dispatch({
      type: UPDATE_ROW,
      payload: { tagId, ...params },
    });
  };
};

// UI: REMOVE ROW
export const deleteRow = tagId => {
  return async dispatch => {
    dispatch({
      type: DELETE_ROW,
      payload: { tagId },
    });
  };
};

// ERROR HANDLER
const handleError = (err, tagName, dispatch) => {
  const error = (err.response || {}).data || {};
  if (
    error.code === 'Conflict' &&
    (error.message || '').includes('already exists')
  ) {
    dispatch(addNotification(duplicateErrorMessage(tagName)));
  } else {
    dispatch(addNotification(errorMessage));
  }
};

// API: CREATE TAG
export const createTag = (companyId, tagId, tag) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;

    try {
      const response = await TagService.createCompanyTag(
        accessToken,
        companyId,
        tag,
      );

      if (response.data) {
        dispatch(receiveTag(response.data, tagId));
        dispatch(addNotification(createdMessage(tag.name)));
      } else {
        dispatch(addNotification(errorMessage));
      }
    } catch (err) {
      handleError(err, tag.name, dispatch);
    }
  };
};

// API: UPDATE TAG
export const updateTag = (companyId, tagName, params) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;

    try {
      const response = await TagService.updateCompanyTag(
        accessToken,
        companyId,
        encodeURIComponent(tagName),
        {
          name: tagName,
          ...params,
          includeCounts: true,
        },
      );

      if (response.data) {
        dispatch(receiveTag(response.data));
        dispatch(addNotification(updatedMessage(tagName, params.name)));
      } else {
        dispatch(addNotification(errorMessage));
      }
    } catch (err) {
      handleError(err, params.name || tagName, dispatch);
    }
  };
};

// API: DELETE TAGS
export const deleteTags = (companyId, tagIds) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;

    const response = await TagService.deleteCompanyTags(
      accessToken,
      companyId,
      tagIds,
    );

    if (response.data) {
      dispatch(receiveDeletedTags(response.data));
      dispatch(
        addNotification(
          response.data.length === 1
            ? deletedMessage(response.data[0].name)
            : batchDeletedMessage(response.data.length),
        ),
      );
    } else {
      dispatch(addNotification(errorMessage));
    }
  };
};

// API: LIST TAGS (SEARCH)
export const listTags = (companyId, params) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;

    const response = await TagService.getCompanyTags(
      accessToken,
      companyId,
      params,
    );

    if (response.data) {
      dispatch(receiveTags(response.data));
    } else {
      dispatch(addNotification(errorMessage));
    }
  };
};

// API: UPDATE TAG
export const updateUsersTags = (companyId, userIds, params) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;
    const { add, remove } = params;

    const response = await TagService.updateUsersTags(accessToken, companyId, {
      userIds,
      add,
      remove,
    });

    if (response.data) {
      dispatch(receiveUpdatedTags(response.data));
      dispatch(
        addNotification(
          batchUpdatedMessage((add || []).length + (remove || []).length),
        ),
      );
    } else {
      dispatch(addNotification(errorMessage));
    }
  };
};
