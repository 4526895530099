import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactLoading from 'react-loading';
import moment from 'moment';
import Card, {
  CardHeader,
  CardContent,
  CardTooltip,
} from '../../../../UI/Card';

const mapStateToProps = state => {
  const { taskAnalytics } = state;
  return {
    taskTime: taskAnalytics.taskTime,
  };
};

const AvgTimePanel = ({ taskTime }) => {
  const displayTaskTime = t => {
    const time = moment.duration(t, 'seconds');
    const display = [];
    if (time.hours() > 0) {
      display.push(
        <React.Fragment key="h">
          {time.hours()}
          <span className="unit">h</span>
        </React.Fragment>,
      );
    }
    if (time.minutes() > 0) {
      display.push(
        <React.Fragment key="m">
          {time.minutes()}
          <span className="unit">m</span>
        </React.Fragment>,
      );
    }
    if (time.seconds() > 0) {
      display.push(
        <React.Fragment key="s">
          {time.seconds()}
          <span className="unit">s</span>
        </React.Fragment>,
      );
    }
    return display;
  };

  return (
    <Card>
      <CardHeader>Average time per review</CardHeader>
      <CardContent>
        {taskTime === null ||
          (taskTime.loading && (
            <ReactLoading color="#3C3C3B" type="spin" height={38} width={38} />
          ))}
        {taskTime.mean !== null &&
          !taskTime.loading &&
          (taskTime.mean > 0 ? (
            displayTaskTime(taskTime.mean)
          ) : (
            <React.Fragment key="s">N/A</React.Fragment>
          ))}
      </CardContent>
      <CardTooltip tooltipId="task-company-tooltip">
        The average time taken to complete a single annotation. Note: Some tasks
        may require multiple annotations
      </CardTooltip>
    </Card>
  );
};

AvgTimePanel.propTypes = {
  taskTime: PropTypes.object,
};

AvgTimePanel.defaultProps = {
  taskTime: null,
};

export default connect(mapStateToProps)(AvgTimePanel);
