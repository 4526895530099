import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const InputCell = ({
  value: initialValue,
  column,
  row,
  editable: isEditable,
  handlers,
}) => {
  const [value, setValue] = React.useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = e => {
    setValue(e.target.value);
  };

  // callback: (value, original, rowIndex, column)
  const handleBlur = () => {
    handlers.onInputBlur(value, row.original, row.index, column.id);
  };

  if (!isEditable) {
    return <span className="table__value">${initialValue}</span>;
  }

  return <input value={value} onChange={handleChange} onBlur={handleBlur} />;
};

InputCell.propTypes = {
  column: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  value: PropTypes.any,
  editable: PropTypes.bool,
  handlers: PropTypes.object,
};

InputCell.defaultProps = {
  value: undefined,
  editable: false,
  handlers: {
    onInputBlur: () => {},
  },
};

export default InputCell;
