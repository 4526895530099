import * as DemoService from '../../../services/DemoService';
import ProductService from '../../../services/ProductService';
import { sleep } from '../../../common/helpers/timingHelper';
import { getCsvFromDoc } from '../helpers/csvHelper';

export const REQUEST_DOC = 'sypht/capture/REQUEST_DOC';
export const RECEIVE_DOC = 'sypht/capture/RECEIVE_DOC';
export const RECEIVE_ERROR = 'sypht/capture/RECEIVE_ERROR';
export const REQUEST_PRODUCTS = 'sypht/capture/REQUEST_PRODUCT';
export const RECEIVE_PRODUCTS = 'sypht/capture/RECEIVE_PRODUCT';
export const TOGGLE_DRAWER = 'sypht/capture/TOGGLE_DRAWER';

// DISPATCHERS
const requestDoc = () => {
  return {
    type: REQUEST_DOC,
    payload: {},
  };
};

const receiveDoc = data => {
  return {
    type: RECEIVE_DOC,
    payload: {
      data,
    },
  };
};

const receiveError = () => {
  return {
    type: RECEIVE_ERROR,
  };
};

const requestProducts = () => {
  return {
    type: REQUEST_PRODUCTS,
    payload: {},
  };
};

const receiveProducts = data => {
  return {
    type: RECEIVE_PRODUCTS,
    payload: {
      data,
    },
  };
};

export const toggleDrawer = isOpen => {
  return {
    type: TOGGLE_DRAWER,
    payload: {
      data: {
        isOpen,
      },
    },
  };
};

// DOWNLOAD DOC
export const downloadDoc = (docSrc, filename) => {
  return async () => {
    try {
      const response = await DemoService.downloadDoc(docSrc);

      if (response) {
        const file = new Blob([response], { type: response.type });
        const url = window.URL.createObjectURL(file);
        const anchor = document.createElement('a');

        anchor.href = url;
        anchor.download = filename;
        anchor.click();
        window.URL.revokeObjectURL(url);
      } else {
        console.error(`Error downloading doc '${docSrc}'`, response);
      }
    } catch (err) {
      console.error(err);
    }
  };
};

// EXPORT DOC DATA
export const exportDocData = (docId, filename) => {
  return async () => {
    try {
      const response = await DemoService.retrieveDoc(docId);

      if (response) {
        const csvStr = getCsvFromDoc(response.data);
        const file = new Blob([csvStr], { type: 'text/csv;charset=utf-8' });

        const anchor = document.createElement('a');
        anchor.href = window.URL.createObjectURL(file);
        anchor.download = filename;
        anchor.click();

        window.URL.revokeObjectURL(anchor.href);
      } else {
        console.error(`Error downloading doc '${docId}'`, response);
      }
    } catch (err) {
      console.error(err);
    }
  };
};

// LIST
export const listProducts = () => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;

    dispatch(requestProducts());
    const response = await ProductService.getProducts(accessToken);
    dispatch(receiveProducts(response.data.products));
  };
};

// RETRIEVE DOC
export const retrieveDoc = docId => {
  return async dispatch => {
    try {
      dispatch(requestDoc());
      const [doc, results] = await Promise.all([
        DemoService.retrieveDoc(docId),
        DemoService.retrieveResults(docId),
      ]);
      if (doc.data && results.data) {
        const data = {
          ...doc.data,
          json: results.data,
        };
        dispatch(receiveDoc(data));
      } else {
        dispatch(receiveError());
        console.error(`Error retrieving doc '${docId}'`, doc);
      }
    } catch (err) {
      dispatch(receiveError());
      console.error(err);
    }
  };
};

// RETRIEVE RESULTS
export const retrieveResults = docId => {
  return async dispatch => {
    try {
      dispatch(requestDoc());

      const results = await DemoService.retrieveResults(docId);
      await sleep(1000);
      const doc = await DemoService.retrieveDoc(docId);

      if (doc.data && results.data) {
        const data = {
          ...doc.data,
          json: results.data,
        };
        dispatch(receiveDoc(data));
      } else {
        dispatch(receiveDoc(null));
        console.error(`Error retrieving doc '${docId}'`, doc);
      }

      // }, 500);
    } catch (err) {
      dispatch(receiveDoc(null));
      console.error(err);
    }
  };
};
