import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import Notification from '../../containers/NotificationContainer';
import Sidebar from '../Sidebar/Sidebar';
import Toolbar from '../Toolbar/Toolbar';
import Timeout from '../Timeout/Timeout';
import AlertBannerContainer from '../UI/AlertBanner/AlertBannerContext';
import AlertBanner from '../UI/AlertBanner/AlertBanner';
import AppContext from './AppContext';
import './App.scss';

const initialAppState = {
  isScrollLock: false,
  isTourOpen: false,
};

const App = ({ children }) => {
  const [app, setApp] = useState(initialAppState);

  return (
    <AppContext.Provider value={[app, setApp]}>
      <div className="wrapper">
        <Sidebar />
        <div className="app">
          <AlertBannerContainer>
            <AlertBanner />
            <Toolbar />
            <div
              className={classNames('page-content-wrapper', {
                'page-content-wrapper--lock': app.isScrollLock,
              })}
            >
              {children}
              <Notification />
            </div>
            <Timeout />
          </AlertBannerContainer>
        </div>
      </div>
    </AppContext.Provider>
  );
};

App.defaultProps = {
  children: null,
};

App.propTypes = {
  children: PropTypes.node,
};

export default withRouter(App);
