export const colours = {
  grey: '#3C3C3B',
  blue: '#00b6ec',
};

export const controlsHidden = {
  mt: false,
  mb: false,
  ml: false,
  mr: false,
  bl: false,
  br: false,
  tl: false,
  tr: false,
};

export const controlsLocked = {
  lockMovementX: true,
  lockMovementY: true,
  lockScalingX: true,
  lockScalingY: true,
};

export const rowLineConfig = {
  stroke: colours.blue,
  strokeWidth: 2,
  lockMovementX: true,
  hasControls: false,
  padding: 10,
  hoverCursor: 'ns-resize',
  selectable: false,
};

export const colLineConfig = {
  stroke: colours.blue,
  strokeWidth: 2,
  lockMovementY: true,
  hasControls: false,
  padding: 10,
  hoverCursor: 'ew-resize',
  selectable: false,
};

export const indicatorConfig = {
  stroke: colours.blue,
  opacity: 0.15,
  strokeWidth: 2,
  visible: false,
  selectable: false,
  left: 0,
  top: 0,
  width: 1,
  height: 1,
};

export const headerHighlightConfig = {
  fill: colours.blue,
  stroke: colours.blue,
  opacity: 0.15,
  visible: false,
  selectable: false,
};

export const headerSelectionConfig = {
  fill: colours.blue,
  stroke: colours.blue,
  strokeWidth: 8,
  strokeLineCap: 'round',
  opacity: 0.3,
  visible: false,
  selectable: false,
};

export const tableBorderConfig = {
  originX: 'left',
  originY: 'top',
  fill: 'transparent',
  strokeWidth: 2,
  hasRotatingPoint: false,
  objectCaching: false,
  stroke: colours.blue,
  strokeUniform: true,
  noScaleCache: false,
  selectable: false,
};

export const maskConfig = {
  fill: colours.grey,
  stroke: colours.grey,
  opacity: 0.25,
  selectable: false,
};

export const existingTable = {
  strokeWidth: 2,
  stroke: '#00b6ec',
  fill: 'rgba(0,182,236,0.15)',
  opacity: 0.5,
  selectable: false,
};

export const existingTableLabelText = {
  selectable: false,
  fontSize: 14,
  fontFamily: 'Roboto',
  fill: '#ffffff',
};

export const existingTableLabelBg = {
  fill: '#00b6ec',
  stroke: '#00b6ec',
  opacity: 0.5,
  selectable: false,
};
