import React from 'react';
import TourContent from '../TourContent';
import Split from '../../../../../../assets/images/onboarding/introduction-to-sypht/split.svg';

const SplitDocuments = () => (
  <TourContent
    icon={Split}
    text="Use Sypht AI to convert a multi-document file into its individual documents."
    buttonHidden
  />
);

export default SplitDocuments;
