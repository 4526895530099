import classNames from 'classnames';

class DataCol {
  constructor(Header, accessor, props = {}) {
    const { Cell, headerClassName, className, sortable, width } = props;
    const headerClassNames = classNames(headerClassName, 'table__th', {
      'table__th--no-sort': sortable === false,
    });
    const cellClassNames = classNames(className, 'table__td');
    this.Header = Header;
    this.accessor = accessor;
    this.Cell = Cell;
    this.headerClassName = headerClassNames;
    this.className = cellClassNames;
    if (sortable) this.sortable = sortable;
    if (width) this.width = width;
  }
}

export default DataCol;
