// eslint-disable-next-line no-extend-native
Object.defineProperty(String.prototype, 'toCamelCase', {
  value: function toCamelCase() {
    return this.replace(/^([A-Z])|[\s-_]+(\w)/g, (match, x, y) => {
      if (y) return y.toUpperCase();
      return x.toLowerCase();
    });
  },
  writable: true,
  configurable: true,
});
