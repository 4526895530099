import React from 'react';
import PropTypes from 'prop-types';
import './Badge.scss';

const Badge = ({ text, color }) => {
  return (
    <div className="ribbon">
      <span className={color}>{text}</span>
    </div>
  );
};

Badge.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
};

Badge.defaultProps = {
  color: 'default', // OPTIONS: default, green, purple, red
};

export default Badge;
