/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable one-var */
import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  Fragment,
} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { v4 as uuid } from 'uuid';
import { range, clone } from 'ramda';
import { FaLink } from 'react-icons/fa';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { TableContext } from '../../DocumentView/TableContext';
import { DocumentToolbarContext } from '../../UI/DocumentToolbar/DocumentToolbarContext';
import { Button } from '../../../library/atoms/Buttons';
import Dropdown from '../../UI/DropdownV2';
import './TableView.scss';
import EntityAnnotation from '../EntityAnnotation/EntityAnnotation';
import {
  getCategoryOptionsFromSpec,
  getCategoryFromAnnotation,
  getCellDataFromAnnotation,
  getEntityFromAnnotation,
  selectCategoryInAnnotation,
  buildAnnotationFromPrediction,
  buildAnnotation,
  saveTokenSelectionToAnnotation,
  saveManualEditToAnnotaion,
  saveEntitySelectionInAnnotation,
  isInSection,
  uncategorisedColumns,
  getEntitiesFromSpec,
  emptyEntityRows,
} from './tableViewHelper';

const TableView = ({
  candidateSelection,
  onCancelTableView,
  onSaveTableView,
  savedAnnotation,
  field,
}) => {
  const [tableTokens, setTableTokens] = useState([]);
  const [tableContext, setTableContext] = useContext(TableContext);
  const [preSortedTokens, setPreSortedTokens] = useState({});
  const [selectedCell, setSelectedCell] = useState({});
  const [selectedEntityCells, setSelectedEntityCells] = useState({
    entity: null,
    cells: [],
  });
  const [editText, setEditText] = useState('');
  const [cursorPosition, setCursorPosition] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [selectMode, setSelectMode] = useState(false);
  const [annotation, setAnnotation] = useState(null);
  const [entityContainerPos, setEntityContainerPos] = useState(null);

  const scrollElementRef = useRef(null);
  const scrollPositionRef = useRef(0);
  const scrollContainerRef = useRef(null);
  const editRef = useRef(null);
  const tableContainerRef = useRef(null);

  const [documentContext] = useContext(DocumentToolbarContext);

  const { rotation } = documentContext;

  const { columns, header, rows, pageId, disabledRows, readOnly } =
    tableContext;

  const categoryOptions = getCategoryOptionsFromSpec(field);

  const selectCell = (row, column) => {
    if (
      !readOnly &&
      !(selectedCell.row === row && selectedCell.column === column) &&
      !editMode &&
      !selectMode
    ) {
      setSelectedCell({ row, column });
      setSelectedEntityCells({ enity: null, cells: [] });
    }
  };

  const handleEditChange = e => {
    setCursorPosition(e.target.selectionStart);
    setEditText(e.target.value);
  };

  const handleEditFocus = e => {
    if (cursorPosition !== null) {
      e.target.selectionStart = cursorPosition;
      e.target.selectionEnd = cursorPosition;
    }
  };

  const Cell = (cellItem, index, row, column, isHeader = false) => (
    <div
      className={classnames({
        'table-cell': true,
        'table-cell-header': isHeader,
        'table-cell-selected':
          !readOnly &&
          selectedCell.row === row &&
          selectedCell.column === column,
      })}
      key={index}
      onClick={() => {
        selectCell(row, column);
      }}
    >
      {cellItem}
      {editMode &&
        selectedCell.row === row &&
        selectedCell.column === column && (
          <input
            type="text"
            ref={editRef}
            className="table-cell-edit-input"
            value={editText}
            onChange={handleEditChange}
            onFocus={handleEditFocus}
            onKeyDown={onInputKeyDown}
          />
        )}
    </div>
  );

  const CellManual = (cellText, index, row, column, isHeader = false) => (
    <div
      className={classnames({
        'table-cell': true,
        'table-cell-header': isHeader,
        'table-cell-selected':
          selectedCell.row === row && selectedCell.column === column,
      })}
      key={index}
      onClick={() => {
        selectCell(row, column);
      }}
    >
      <span key={index} className="table-cell__raw">
        {cellText}
      </span>
      {editMode &&
        selectedCell.row === row &&
        selectedCell.column === column && (
          <input
            type="text"
            ref={editRef}
            className="table-cell-edit-input"
            value={editText}
            onChange={handleEditChange}
            onFocus={handleEditFocus}
            onKeyDown={onInputKeyDown}
          />
        )}
    </div>
  );
  const onInputKeyDown = e => {
    e.stopPropagation();
  };

  const CellRaw = (candidate, index, candidates) => {
    const newRow =
      !!candidates &&
      !!candidates[index + 1] &&
      (rotation === 0 || rotation === 180
        ? Math.abs(
            candidates[index + 1].bounds.topLeft.y - candidate.bounds.topLeft.y,
          ) > 0.01
        : Math.abs(
            candidates[index + 1].bounds.topLeft.x - candidate.bounds.topLeft.x,
          ) > 0.01);
    return (
      <span key={index} className="table-cell__raw">
        {candidate.token || candidate.text}
        {newRow && <br />}
      </span>
    );
  };

  const CategoryDropDown = index => (
    <div
      className={classnames([
        'table-cell',
        'table-cell-header',
        'table-cell-category',
      ])}
      key={index}
    >
      <Dropdown
        isDisabled={readOnly}
        className="table-cell-category-dropdown"
        onChange={e => {
          selectCategory(e, index);
        }}
        options={categoryOptions}
        value={getCategoryFromAnnotation(
          field,
          annotation,
          categoryOptions,
          index,
        )}
        placeholder="Category"
        styles={{
          menuList: (provided, state) => ({
            ...provided,
            maxHeight: '146px',
            zIndex: 3,
          }),
          menu: (provided, state) => ({
            ...provided,
            zIndex: 3,
          }),
        }}
        menuPosition="fixed"
      />
    </div>
  );

  const getCategoryRow = () => {
    const categoryColumns = [];
    const entities = getEntitiesFromSpec(field);

    if (columns) {
      categoryColumns.push(...columns.map((c, i) => CategoryDropDown(i)));
    }
    const entityLabels = entities.map(entity => (
      <div
        className={classnames(['table-cell', 'table-cell-header'])}
        key={uuid()}
      >
        {entity.description}
      </div>
    ));

    return (
      <div className="table-row" key={uuid()}>
        {entityLabels}
        {categoryColumns}
      </div>
    );
  };

  const selectCategory = ({ value }, index) => {
    const newAnnotation = selectCategoryInAnnotation(
      field,
      annotation,
      value,
      index,
    );
    setAnnotation(newAnnotation);
  };

  const getColumns = rowIndex => {
    const cell = [];
    const isHeader = header === 'row' && rowIndex === 0;

    columns.forEach((column, colIndex) => {
      let cellData = getCellDataFromAnnotation(
        field,
        annotation,
        header,
        rowIndex,
        colIndex,
        columns,
      );
      if (
        selectMode &&
        rowIndex === selectedCell.row &&
        colIndex === selectedCell.column
      ) {
        cellData = Object.values(candidateSelection.selectedCandidates);
      }

      if (Array.isArray(cellData)) {
        const cellItem = cellData.map((c, i, cArr) => CellRaw(c, i, cArr));
        cell.push(
          Cell(
            cellItem,
            uuid(),
            rowIndex,
            colIndex,
            (header === 'first-column' && colIndex === 0) ||
              (header === 'last-column' && colIndex === columns.length - 1) ||
              isHeader,
          ),
        );
      } else {
        cell.push(
          CellManual(
            cellData,
            uuid(),
            rowIndex,
            colIndex,
            (header === 'first-column' && colIndex === 0) ||
              (header === 'last-column' && colIndex === columns.length - 1) ||
              isHeader,
          ),
        );
      }

      if (header === 'first-column' && colIndex === 0) {
        cell.push(CategoryDropDown(rowIndex));
      }

      if (header === 'last-column' && colIndex === columns.length - 1) {
        cell.push(CategoryDropDown(rowIndex));
      }
    });

    return cell.length > 0 && <Fragment key={uuid()}>{cell}</Fragment>;
  };

  const getEntityColumns = i => {
    const cells = [];
    const entites = getEntitiesFromSpec(field);
    for (const entity of entites) {
      if (i === 0 && header === 'row') {
        cells.push(
          <div className="table-cell table-cell-header" key={uuid()}>
            {' '}
          </div>,
        );
      } else {
        const selected =
          selectedEntityCells.cells.includes(i) &&
          selectedEntityCells.entityId === entity.id;

        const value = getEntityValue(entity.id, i);

        cells.push(
          <div
            className={classnames({
              'table-cell': true,
              'table-cell-entity': true,
              'table-cell-entity-selected': selected,
              'select-start':
                selected && !selectedEntityCells.cells.includes(i - 1),
              'select-end':
                selected && !selectedEntityCells.cells.includes(i + 1),
            })}
            onClick={e => selectEntityCell(e, i, entity.id)}
            key={uuid()}
          >
            {value}
          </div>,
        );
      }
    }
    return cells;
  };

  const getEntityValue = (entityId, i) => {
    const entites = getEntitiesFromSpec(field);
    const entityField = entites.find(e => e.id === entityId);
    const { model } = entityField;
    const { referent } = model;
    const { company_id, entity_type } = referent;
    const entityColumn = annotation.columns.find(c => c.id === entityId);

    if (!entityColumn.cells[i - (header === 'row' ? 1 : 0)]) {
      return '';
    }
    if (
      entityColumn.cells[i - (header === 'row' ? 1 : 0)] &&
      !entityColumn.cells[i - (header === 'row' ? 1 : 0)].data.value
    ) {
      return 'Not Present';
    }
    return (
      <>
        <FaLink /> {entity_type} /{' '}
        {entityColumn.cells[i - (header === 'row' ? 1 : 0)].data.value.id}
      </>
    );
  };

  const selectEntityCell = (e, i /* e, i, o */, entityId) => {
    if (readOnly) {
      return;
    }

    document.getSelection().removeAllRanges();

    const tableViewRect = tableContainerRef.current.getBoundingClientRect();
    setEntityContainerPos({
      bottom: `${tableViewRect.bottom - e.pageY + 10}px`,
      left: `${e.pageX - tableViewRect.left - 20}px`,
    });

    e.stopPropagation();
    setSelectedEntityCells(selectedCells => {
      if (selectedCells.entityId !== entityId) {
        return { entityId, cells: [i] };
      }

      if (e.metaKey || e.ctrlKey) {
        return {
          entityId,
          cells: selectedCells.cells.includes(i)
            ? [...selectedCells.cells.filter(c => c != i)]
            : [...selectedCells.cells, i],
        };
      }
      if (e.shiftKey) {
        if (selectedCells.cells.length === 0) {
          return { entityId, cells: [i] };
        }
        if (i > selectedCells.cells.sort((a, b) => a - b)[0]) {
          return {
            entityId,
            cells: range(selectedCells.cells.sort((a, b) => a - b)[0], i + 1),
          };
        }

        return {
          entityId,
          cells: range(i, selectedCells.cells.sort((a, b) => a - b)[0] + 1),
        };
      }

      return { entityId, cells: [i] };
    });
    setSelectedCell({});
  };

  const saveEntityAnnotation = value => {
    const { entityId, cells } = selectedEntityCells;

    setAnnotation(
      saveEntitySelectionInAnnotation(annotation, entityId, cells, header, {
        ...value,
        id: 'cells[*]',
      }),
    );

    setSelectedEntityCells({ entity: null, cells: [] });
  };

  const getRow = i => (
    <div className="table-row" key={uuid()}>
      {getEntityColumns(i)}
      {getColumns(i)}
    </div>
  );

  const preSortTokens = () => {
    const { candidates } = candidateSelection;
    const preSorted = {};
    if (candidates) {
      for (const candidate of candidates) {
        const { pageNum } = candidate.bounds;
        if (!preSorted[`page-${pageNum}`]) {
          preSorted[`page-${pageNum}`] = [];
        }
        preSorted[`page-${pageNum}`].push(candidate);
      }
    }
    setPreSortedTokens(preSorted);
  };

  const buildTable = () => {
    const tableResult = [];
    if (header === '' || header === 'row') {
      tableResult.push(getCategoryRow());
    }
    tableResult.push(
      ...rows
        .filter((r, i) => !disabledRows.includes(i))
        .map((row, i) => getRow(i)),
    );
    setTableTokens(tableResult);
  };

  useEffect(() => {
    const {
      tableBounds,
      lockTable,
      header,
      columns,
      rows,
      tableData,
      prediction,
      activeTable,
    } = tableContext;
    if (
      annotation === null &&
      tableBounds !== null &&
      (savedAnnotation || prediction)
    ) {
      if (savedAnnotation) {
        if (!savedAnnotation.tableData) {
          setAnnotation({ ...clone(savedAnnotation), tableData: activeTable });
        } else {
          setAnnotation(clone(savedAnnotation));
        }
      } else if (prediction) {
        setAnnotation(
          buildAnnotationFromPrediction(
            prediction,
            activeTable,
            candidateSelection,
            field,
          ),
        );
      }
    } else if (tableBounds !== null && !lockTable) {
      const list = (preSortedTokens[`page-${pageId}`] || []).filter(c =>
        isInSection(tableBounds.tl, tableBounds.br, c, { pageId, rotation }),
      );
      setAnnotation(
        buildAnnotation(field, list, {
          tableBounds,
          rows,
          columns,
          header,
          pageId,
          lockTable,
          disabledRows,
          annotation,
          rotation,
        }),
      );
    }
  }, [tableContext, rotation]);

  useEffect(() => {
    if (annotation !== null) {
      buildTable();
    }
  }, [
    annotation,
    editMode,
    selectMode,
    selectedCell,
    editText,
    selectedEntityCells,
  ]);

  useEffect(() => {
    let existingCandidateCount = 0;
    Object.values(preSortedTokens).forEach(c => {
      existingCandidateCount += c.length;
    });
    if (existingCandidateCount !== candidateSelection.candidates.length) {
      preSortTokens();
    }
    if (selectMode) {
      buildTable();
    }
  }, [candidateSelection]);

  const handleScroll = ({ target }) => {
    // target = the thing with scrollbars
    // scrollElementRef = the thing that gets scrolled (inside target that exceeds the viewing pane)
    //
    // I think this code is trying to record the current scroll position using
    // bounding rects (!) after each scroll plus some adjustment factor related
    // to the bounding rects calc.

    scrollPositionRef.current =
      target.getBoundingClientRect().top -
      scrollElementRef.current.getBoundingClientRect().top +
      40;
  };

  useEffect(() => {
    if (scrollContainerRef.current !== null && scrollPositionRef.current > 0) {
      // This appears to be trying to restore the position of the scrolled
      // element maybe because seelcting a dropdown item within the scrolled
      // content was messing with the scroll position?
      //
      // Disabling it to test with annotators.  Should be safe to delete this
      // code after Aug-2023 if it's still disabled.
      // scrollContainerRef.current.scrollTo({ top: scrollPositionRef.current });
    }
    if (editMode && editRef.current) {
      editRef.current.focus();
    }
  }, [tableTokens]);

  const showEditCell = () => {
    const { row, column } = selectedCell;
    const cellData = getCellDataFromAnnotation(
      field,
      annotation,
      header,
      row,
      column,
      columns,
    );

    if (Array.isArray(cellData)) {
      setEditText(cellData.map(t => t.token).join(' '));
    } else {
      setEditText(cellData);
    }
    setEditMode(true);
    setTableContext(state => ({
      ...state,
      lockTable: true,
    }));
  };
  const hideEditCell = () => {
    setEditMode(false);
  };
  const confirmEditCell = () => {
    const newAnnotation = saveManualEditToAnnotaion(
      field,
      annotation,
      editText,
      selectedCell,
      header,
      rows,
      columns,
    );
    newAnnotation.tableData.lockTable = true;
    setAnnotation(newAnnotation);
    setEditMode(false);
  };

  const selectTokens = () => {
    const { row, column } = selectedCell;
    let cellData = getCellDataFromAnnotation(
      field,
      annotation,
      header,
      row,
      column,
      columns,
    );
    if (!Array.isArray(cellData)) {
      cellData = [];
    }

    candidateSelection.annotatedCandidates = cellData;
    candidateSelection.setFocus(field, []);
    candidateSelection.onEdit(cellData);

    setSelectMode(true);
    setTableContext(state => ({
      ...state,
      lockTable: true,
      selectionMode: true,
    }));
  };
  const cancelSelection = () => {
    candidateSelection.onClear(true);
    candidateSelection.setFocus(null);
    setSelectMode(false);
    setTableContext(state => ({
      ...state,
      selectionMode: false,
    }));
  };
  const confirmSelection = () => {
    const newAnnotation = saveTokenSelectionToAnnotation(
      field,
      annotation,
      candidateSelection.selectedCandidates,
      selectedCell,
      header,
      rows,
      columns,
    );
    newAnnotation.tableData.lockTable = true;
    setSelectMode(false);
    setAnnotation(newAnnotation);
    setTableContext(state => ({
      ...state,
      selectionMode: false,
    }));
    candidateSelection.onClear(true);
    candidateSelection.setFocus(null);
  };

  const disableSave = () => {
    if (tableContext.readOnly) {
      return true;
    }
    if (annotation === null) {
      return true;
    }
    if (uncategorisedColumns(field, annotation)) {
      return true;
    }
    if (emptyEntityRows(field, annotation)) {
      return true;
    }
    return false;
  };

  const disabledSaveTooltip = () => {
    const fieldEntities = getEntitiesFromSpec(field);

    if (fieldEntities.length === 0) {
      return 'Please categorise each column header';
    }
    return "Please categorise each column header, and populate all entity matching cells (if any exist). If none of the matching options apply to a cell, please select 'Not Present'";
  };

  const showEntitySelection =
    selectedEntityCells.enityId !== null &&
    selectedEntityCells.cells.length > 0;
  const entities = getEntitiesFromSpec(field);

  return ReactDOM.createPortal(
    <div className="tableview-container" key={uuid()} ref={tableContainerRef}>
      <div
        className="tableview-scroll"
        onScroll={handleScroll}
        ref={scrollContainerRef}
      >
        <div className="data-container" ref={scrollElementRef}>
          {annotation !== null && (
            <div className="table-data-container">
              <div className="table-data">{tableTokens}</div>
            </div>
          )}
          {annotation === null && (
            <p>Drag and drop the outside border of the table to begin.</p>
          )}
        </div>
      </div>
      {showEntitySelection && (
        <div className="entity-container" style={entityContainerPos}>
          <EntityAnnotation
            field={entities.find(e => e.id === selectedEntityCells.entityId)}
            setPredictionFocus={() => {}}
            editable
            inline
            saveAnnotation={saveEntityAnnotation}
            annotation={getEntityFromAnnotation(
              selectedEntityCells.entityId,
              selectedEntityCells.cells[0] - (header === 'row' ? 1 : 0),
              annotation,
            )}
          />
        </div>
      )}
      <div className="controls">
        <div className="inner-wrapper">
          <div className="left-buttons">
            {!readOnly &&
              !editMode &&
              !selectMode &&
              selectedCell.column > -1 &&
              selectedCell.row > -1 && (
                <>
                  <Button variant="outline" onClick={showEditCell}>
                    Edit manually
                  </Button>
                  <Button variant="outline" onClick={selectTokens}>
                    Select values
                  </Button>
                </>
              )}
            {editMode && (
              <>
                <Button variant="outline" onClick={hideEditCell}>
                  Cancel
                </Button>
                <Button onClick={confirmEditCell}>Confirm</Button>
              </>
            )}
            {selectMode && (
              <>
                <Button variant="outline" onClick={cancelSelection}>
                  Cancel
                </Button>
                <Button onClick={confirmSelection}>Confirm</Button>
              </>
            )}
          </div>
          <div className="right-buttons">
            {!editMode && !selectMode && (
              <>
                <Button variant="outline" onClick={onCancelTableView}>
                  Cancel
                </Button>
                {!readOnly && disableSave() && (
                  <span
                    data-tooltip-id="table-view-tooltip"
                    data-tooltip-content={disabledSaveTooltip()}
                  >
                    <Button disabled>Save Table</Button>
                  </span>
                )}
                {!readOnly && !disableSave() && (
                  <Button onClick={() => onSaveTableView(annotation)}>
                    Save Table
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <ReactTooltip
        id="table-view-tooltip"
        place="bottom"
        variant="dark"
        className="ReactTooltip"
      />
    </div>,
    document.getElementById('annotation-tray'),
  );
};

TableView.propTypes = {
  candidateSelection: PropTypes.object.isRequired,
  onCancelTableView: PropTypes.func.isRequired,
  onSaveTableView: PropTypes.func.isRequired,
};

export default TableView;
