const AU_STATES = [
  { value: 'ACT', label: 'Australian Capital Territory' },
  { value: 'NSW', label: 'New South Wales' },
  { value: 'NT', label: 'Northern Territory' },
  { value: 'QLD', label: 'Queensland' },
  { value: 'SA', label: 'South Australia' },
  { value: 'TAS', label: 'Tasmania' },
  { value: 'VIC', label: 'Victoria' },
  { value: 'WA', label: 'Western Australia' },
];

const JOB_LEVELS = [
  { value: 'Individual', label: 'Individual' },
  { value: 'Manager', label: 'Manager' },
  { value: 'Executive', label: 'Executive' },
  { value: 'Owner / Founder', label: 'Owner / Founder' },
];

const FIELD_OF_EXPERTISE = [
  { value: 'Finance', label: 'Finance' },
  { value: 'IT / Engineering', label: 'IT / Engineering' },
  { value: 'Consultant', label: 'Consultant' },
  { value: 'Operations', label: 'Operations' },
  { value: 'Product', label: 'Product' },
  { value: 'Automation', label: 'Automation' },
  { value: 'Other', label: 'Other' },
];

const INDUSTRY = [
  {
    value: 'Agriculture, Forestry and Fishing',
    label: 'Agriculture, Forestry and Fishing',
  },
  { value: 'Mining', label: 'Mining' },
  { value: 'Manufacturing', label: 'Manufacturing' },
  {
    value: 'Electricity, Gas, Water and Waste Services',
    label: 'Electricity, Gas, Water and Waste Services',
  },
  { value: 'Construction', label: 'Construction' },
  { value: 'Wholesale Trade', label: 'Wholesale Trade' },
  { value: 'Retail Trade', label: 'Retail Trade' },
  {
    value: 'Accommodation and Food Services',
    label: 'Accommodation and Food Services',
  },
  {
    value: 'Transport, Postal and Warehousing',
    label: 'Transport, Postal and Warehousing',
  },
  {
    value: 'Information Media and Telecommunications',
    label: 'Information Media and Telecommunications',
  },
  {
    value: 'Financial and Insurance Services',
    label: 'Financial and Insurance Services',
  },
  {
    value: 'Rental, Hiring and Real Estate Services',
    label: 'Rental, Hiring and Real Estate Services',
  },
  {
    value: 'Professional, Scientific and Technical Services',
    label: 'Professional, Scientific and Technical Services',
  },
  {
    value: 'Administrative and Support Services',
    label: 'Administrative and Support Services',
  },
  {
    value: 'Public Administration and Safety',
    label: 'Public Administration and Safety',
  },
  {
    value: 'Education and Training',
    label: 'Education and Training',
  },
  {
    value: 'Health Care and Social Assistance',
    label: 'Health Care and Social Assistance',
  },
  {
    value: 'Arts and Recreation Services',
    label: 'Arts and Recreation Services',
  },
  { value: 'Other Services.', label: 'Other Services' },
];

const MONTHLY_DOC_VOLUME = [
  { value: '< 500', label: '< 500' },
  { value: '< 10000', label: '< 10,000' },
  { value: '< 50000', label: '< 50,000' },
  { value: '> 50000', label: '> 50,000' },
  { value: "Don't know", label: "Don't know" },
];

export default Object.freeze({
  AU_STATES,
  JOB_LEVELS,
  FIELD_OF_EXPERTISE,
  INDUSTRY,
  MONTHLY_DOC_VOLUME,
});
