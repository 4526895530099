import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  FaSave,
  FaClone,
  FaClipboardCheck,
  FaChevronLeft,
} from 'react-icons/fa';
import ReactDiffViewer from 'react-diff-viewer';
import moment from 'moment';
import loadable from '@loadable/component';
import Ajv from 'ajv';
import Page from '../../Page/Page';
import Button from '../../UI/Button/Button';

import AddSpecModal from './AddSpecModal';

import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import { fetchSpec, pushSpec } from '../../../reducers/specs';
import { SpecSchema } from './SpecSchema';
import './Spec.scss';

import 'jsoneditor-react/es/editor.min.css';

const ajv = new Ajv({ allErrors: true, verbose: true });

const JsonEditorReact = loadable.lib(() => import('jsoneditor-react')); // Potentially causes css overrides on filepond component

const mapStateToProps = (state, props) => {
  const { match } = props;
  return {
    specData: state.specs.active || null,
    specId: match.params.id,
    loading: state.specs.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      fetchSpec: id => dispatch(fetchSpec(id)),
      pushSpec: data => dispatch(pushSpec(data)),
    },
  };
};

const Spec = ({ specId, specData, loading, dispatch }) => {
  const [spec, setSpec] = useState(specData);
  const [showDiff, setShowDiff] = useState(false);
  const [showAddSpecModal, setShowAddSpecModal] = useState(showAddSpecModal);
  useEffect(() => {
    setSpec(null);
    if (specId) {
      dispatch.fetchSpec(specId);
    }
  }, [specId]);

  useEffect(() => {
    setSpec(specData);
  }, [specId, specData]);

  const normaliseSpec = s => {
    const norm = {};
    Object.keys(SpecSchema.properties).map(
      k => (norm[k] = JSON.parse(JSON.stringify(s[k]))),
    );
    return norm;
  };
  const oldSpec = specData ? normaliseSpec(specData) : null;
  const newSpec = spec ? normaliseSpec(spec) : null;
  const oldSpecFormatted = oldSpec ? JSON.stringify(oldSpec, null, 1) : null;
  const newSpecFormatted = newSpec ? JSON.stringify(newSpec, null, 1) : null;
  const specChanged = oldSpecFormatted != newSpecFormatted;
  const validator = ajv.compile(SpecSchema);
  const isValid = newSpec ? validator(newSpec) : null;
  const onClickSaveSpec = () => {
    if (newSpec && isValid && !loading) {
      setShowDiff(false);
      dispatch.pushSpec({ name: specId, ...newSpec });
    }
  };
  const lastUpdate = specData ? moment(specData.updated) : null;

  return loading || !newSpec ? (
    <LoadingSpinner className="admin-form__spinner" />
  ) : (
    <>
      <AddSpecModal
        isOpen={showAddSpecModal}
        onCancelModal={() => setShowAddSpecModal(false)}
        onCloseModal={() => setShowAddSpecModal(false)}
        sourceData={newSpec}
      />
      <Page
        className="spec"
        title={`${(specId || '').toTitleCase()} - Specs - Workbench`}
      >
        <div className="page__body">
          <div className="page__row">
            <div className="page__header">
              <h1 className="page__heading">{specId}</h1>
              <div className="actions">
                {showDiff ? (
                  <>
                    <Button onClick={() => setShowDiff(!showDiff)}>
                      <FaChevronLeft /> Back
                    </Button>
                    <Button onClick={onClickSaveSpec}>
                      <FaSave /> Save
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() => setShowAddSpecModal(true)}
                      disabled={!isValid}
                    >
                      <FaClone /> Save as New
                    </Button>
                    <Button
                      onClick={() => setShowDiff(!showDiff)}
                      disabled={!specChanged || !isValid}
                    >
                      <FaClipboardCheck />{' '}
                      {isValid ? 'Review Changes' : 'Invalid specification'}
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
          {showDiff ? (
            <>
              <div className="page__row" style={{ flexGrow: 1 }}>
                <ReactDiffViewer
                  oldValue={oldSpecFormatted}
                  newValue={newSpecFormatted}
                  compareMethod="diffWords"
                  splitView
                />
              </div>
            </>
          ) : (
            <>
              <div className="page__row details">
                <span className="last-update">
                  Updated
                  {lastUpdate.calendar()}.{lastUpdate.fromNow()}
                </span>
                <span className="hint">
                  {specChanged
                    ? 'Changes pending review'
                    : `version: ${specData.id}`}
                </span>
              </div>
              <div className="page__row editor">
                <JsonEditorReact>
                  {({ JsonEditor }) => (
                    <JsonEditor
                      value={newSpec}
                      ajv={ajv}
                      onChange={setSpec}
                      schema={SpecSchema}
                    />
                  )}
                </JsonEditorReact>
              </div>
            </>
          )}
        </div>
      </Page>
    </>
  );
};

Spec.propTypes = {
  specId: PropTypes.string.isRequired,
  spec: PropTypes.object,
  loading: PropTypes.bool,
  dispatch: PropTypes.shape({
    fetchUser: PropTypes.func,
  }),
};

Spec.defaultProps = {
  spec: null,
  loading: false,
  dispatch: {
    fetchUser: () => {},
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(Spec);
