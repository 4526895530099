import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './_Toggle.scss';

const Toggle = ({
  id,
  className,
  label,
  labelPosition,
  defaultChecked,
  checked,
  onChange,
  size,
  ...props
}) => {
  const { name, value, disabled } = props;
  const checkboxId = id || `${name}_${value}`;
  const toggleClass = classNames(
    'toggle',
    className,
    size ? `toggle--${size}` : null,
    label ? `toggle--label-${labelPosition}` : null,
    {
      'toggle--disabled': disabled,
    },
  );

  const onCheckChanged = e => {
    onChange(e.target.checked);
  };

  return (
    <label className={toggleClass} htmlFor={checkboxId}>
      <input
        id={checkboxId}
        className="toggle__input"
        type="checkbox"
        defaultChecked={defaultChecked}
        checked={checked}
        disabled={disabled}
        onChange={onCheckChanged}
      />
      <span className="toggle__box" />
      {label ? <span className="toggle__label">{label}</span> : null}
    </label>
  );
};

Toggle.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  checked: PropTypes.bool,
  label: PropTypes.string,
  labelPosition: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

Toggle.defaultProps = {
  id: '',
  className: '',
  defaultChecked: false,
  checked: false,
  disabled: false,
  label: '',
  labelPosition: 'right',
  name: null,
  value: '',
  size: 'md',
  onChange: () => {},
};

export default Toggle;
