import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { mergeRight as merge } from 'ramda';
import { connect } from 'react-redux';
import { FaCog, FaQuestionCircle } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { push } from 'connected-react-router';
import ProfileWidget from '../ProfileWidget/ProfileWidget';
import './Toolbar.scss';
import { logout, openPlatformTourPanel } from '../../reducers/authReducer';
import Dropdown from '../UI/DropdownV3/Dropdown';

const mapStateToProps = state => {
  const { auth } = state;
  const { data: userData } = auth.user;
  return {
    user: auth.user && auth.user.data ? auth.user.data : null,
    auth,
    onboarded: userData && userData.onboarded !== false,
    canSuperUserWrite: auth.permissions.canSuperUserWrite,
    canAdministrateCompany: auth.permissions.canAdministrateCompany,
    canAdministrateUsers: auth.permissions.canAdministrateUsers,
    canAdministrateCredentials: auth.permissions.canAdministrateCredentials,
    canEditTaskRules: auth.permissions.canEditTaskRules,
  };
};

const mapDispatchToProps = dispatch => ({
  handleLogout: () => dispatch(logout()),
  handleOpenPlatformTour: () => dispatch(openPlatformTourPanel()),
  navigate: path => dispatch(push(path)),
});

const ToolbarButtons = ({
  user,
  handleLogout,
  auth,
  handleOpenPlatformTour,
  navigate,
  canSuperUserWrite,
  canAdministrateCompany,
  canAdministrateUsers,
  canAdministrateCredentials,
  canEditTaskRules,
}) => {

  const openTour = () => {
    handleOpenPlatformTour();
    if (window.location.pathname !== '/documents') navigate('/documents');
  };

  const helpMenuData = [
    <button type="button" onClick={openTour}>
      Platform Tour
    </button>,
    <a href="https://docs.sypht.com" target="_blank" rel="noopener noreferrer">
      Developer Documentation
    </a>,
    <a href="https://help.sypht.com" target="_blank" rel="noopener noreferrer">
      Help Center
    </a>,
    <a
      href="https://www.sypht.com/contact"
      target="_blank"
      rel="noopener noreferrer"
    >
      Contact Us
    </a>,
  ];

  const settingsMenuData = [];

  if (canAdministrateCompany) {
    settingsMenuData.push(
      <NavLink to="/marketplace/subscriptions">My Subscriptions</NavLink>,
    );
  }

  if (canAdministrateUsers) {
    settingsMenuData.push(<NavLink to="/users">My Users</NavLink>);
  }

  if (canAdministrateCredentials) {
    settingsMenuData.push(
      <NavLink to="/credentials">My API Credentials</NavLink>,
    );
  }

  if (canAdministrateCompany || canSuperUserWrite) {
    settingsMenuData.push(
      <NavLink to="/settings/email">My Email Integrations</NavLink>,
    );
  }

  if (canAdministrateCompany) {
    settingsMenuData.push(<NavLink to="/tags">My Tags</NavLink>);
  }

  if (canEditTaskRules) {
    settingsMenuData.push(
      <NavLink to="/validate/rules">My Tasks Settings</NavLink>,
    );
  }

  return (
    <div className="dropdown__buttons-group">
      <Dropdown
        title="Help"
        name="help"
        icon={<FaQuestionCircle />}
        data={helpMenuData}
      />
      {(canAdministrateCompany ||
        canAdministrateUsers ||
        canAdministrateCredentials) && (
        <Dropdown
          title="Settings"
          name="settings"
          icon={<FaCog />}
          data={settingsMenuData}
        />
      )}
      <ProfileWidget
        user={user}
        scopes={auth.claims.allowedScopes}
        urls={{
          validate: process.env.URL_VALIDATE,
          admin: process.env.URL_ADMIN,
          www: process.env.URL_WWW,
        }}
        logout={handleLogout}
        theme="validate"
      />
    </div>
  );
};

ToolbarButtons.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  auth: PropTypes.object.isRequired,
  handleLogout: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  handleOpenPlatformTour: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  canSuperUserWrite: PropTypes.bool.isRequired,
  canAdministrateCompany: PropTypes.bool.isRequired,
  canAdministrateUsers: PropTypes.bool.isRequired,
  canAdministrateCredentials: PropTypes.bool.isRequired,
  canEditTaskRules: PropTypes.bool.isRequired,
};

ToolbarButtons.defaultProps = {
  handleLogout: () => {},
  user: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(ToolbarButtons);
