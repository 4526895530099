import * as utils from './utils';

const apiBaseUrl = process.env.APP_API_HOST_NAME;

class CompanyService {
  // COMPANY SECTION
  getCompany = (accessToken, companyId) => {
    return utils.getData(`${apiBaseUrl}/app/company/${companyId}`, {
      accessToken,
    });
  };

  createCompany = (accessToken, data) => {
    return utils.postData(`${apiBaseUrl}/app/register/developer`, {
      accessToken,
      data,
    });
  };

  getCompanies = (accessToken, params) => {
    return utils.getData(`${apiBaseUrl}/app/companies`, {
      accessToken,
      ...params,
    });
  };

  // COMPANY SECTION
  updateCompany = (accessToken, companyId, companyData) => {
    return utils.putData(`${apiBaseUrl}/app/company/${companyId}`, {
      accessToken,
      data: companyData,
    });
  };

  deleteCompany = (accessToken, companyId) => {
    return utils.deleteData(`${apiBaseUrl}/app/company/${companyId}`, {
      accessToken,
    });
  };

  // FIELDSETS SECTION
  getFieldsets = accessToken => {
    return utils.getData(`${apiBaseUrl}/app/fieldsets`, {
      accessToken,
    });
  };

  getSubscriptions = (accessToken, companyId) => {
    return utils.getData(
      `${apiBaseUrl}/app/company/${companyId}/subscriptions`,
      {
        accessToken,
      },
    );
  };

  // PAYMENT METHODS SECTION
  savePaymentMethod = (accessToken, companyId, data) => {
    return utils.postData(
      `${apiBaseUrl}/app/company/${companyId}/paymentmethod`,
      {
        accessToken,
        data,
      },
    );
  };

  deletePaymentMethod = (accessToken, companyId, paymentMethodId) => {
    return utils.deleteData(
      `${apiBaseUrl}/app/company/${companyId}/paymentmethod/${paymentMethodId}`,
      {
        accessToken,
      },
    );
  };

  saveDefaultPaymentMethod = (accessToken, companyId, paymentMethodId) => {
    return utils.postData(
      `${apiBaseUrl}/app/company/${companyId}/defaultpaymentmethod`,
      {
        accessToken,
        data: paymentMethodId,
      },
    );
  };

  getCompanyRoles = (accessToken, companyId) => {
    return utils.getData(`${apiBaseUrl}/app/company/${companyId}/roles`, {
      accessToken,
    });
  };

  // SETTINGS SECTION
  // settings: string[] eg. ['p1', 'p2']
  retrieveSettings = (accessToken, companyId, settings = []) => {
    const keysCsv = settings.join(',');
    const url = settings
      ? `${apiBaseUrl}/app/company/${companyId}/settings?keys=${keysCsv}`
      : `${apiBaseUrl}/app/company/${companyId}/settings`;

    return utils.getData(url, {
      accessToken,
    });
  };

  // settings: object[] eg. [{ key: value }]
  updateSettings = (accessToken, companyId, settings) => {
    return utils.putData(`${apiBaseUrl}/app/company/${companyId}/settings`, {
      accessToken,
      data: settings,
    });
  };
}

export default new CompanyService();
