import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FaRegCopy, FaDownload } from 'react-icons/fa';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './DeveloperView.scss';

const mapStateToProps = state => {
  const { docs } = state;
  return {
    doc: docs.active.data,
  };
};

const DeveloperView = ({ doc }) => {
  delete docco.hljs.background;

  if (doc && doc.predicted && doc.predicted.results) {
    const results = doc.predicted.results.map(r => {
      const prediction = r.predictions ? r.predictions[0] : '';
      return {
        field: r.field,
        value: {
          ...prediction,
        },
        type: r.type,
      };
    });
    const json = JSON.stringify(results, null, ' ');
    const filename = `${doc.filename}-predictions.json`;

    return (
      <article className="developer-view">
        <section>
          <div className="floating-toolbar">
            <header>
              <CopyToClipboard text={json}>
                <FaRegCopy data-tooltip-content="Copy to Clipboard" />
              </CopyToClipboard>
              <a
                href={`data:text/json;charset=utf-8,${encodeURIComponent(
                  json,
                )}`}
                download={filename}
              >
                <FaDownload data-tooltip-content="Download" />
              </a>
            </header>
          </div>
          <SyntaxHighlighter language="json" style={docco}>
            {json}
          </SyntaxHighlighter>
        </section>
      </article>
    );
  }
  return (
    <article className="developer-view">
      <section>
        <p>no results</p>
      </section>
    </article>
  );
};

DeveloperView.propTypes = {
  doc: PropTypes.shape({
    filename: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.any),
    predicted: PropTypes.shape(PropTypes.any),
  }).isRequired,
};

DeveloperView.defaultProps = {};

export default connect(mapStateToProps, null)(DeveloperView);
