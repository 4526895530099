import { mergeRight as merge } from 'ramda';
import { REQUEST_USERS, RECEIVE_USERS, REFRESH_USERS } from './actions';

const initialState = {
  data: null,
  refresh: false,
  loading: false,
  lastUpdated: null,
};

const usersReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case REQUEST_USERS: {
      return merge(state, {
        loading: true,
      });
    }

    case RECEIVE_USERS: {
      const { data, receivedAt } = payload;
      return merge(state, {
        data,
        loading: false,
        refresh: false,
        lastUpdated: receivedAt,
      });
    }

    case REFRESH_USERS: {
      return merge(state, {
        refresh: true,
      });
    }

    default:
      return state;
  }
};

export default usersReducer;
