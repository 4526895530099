import { Component } from 'react';
import './Task.scss';
import TaskContext from './TaskContext';

export default class FieldAnnotationBase extends Component {
  static contextType = TaskContext;

  constructor(props) {
    super(props);
    this.state = {
      annotation: null,
      edit: false,
      dropdownvalue: null,
    };
    this.ref = null;
  }

  saveAnnotation = a => {
    const { field, saveAnnotation } = this.props;
    const { toggleEditMode } = this.context;
    a.id = field.id;
    saveAnnotation(a);
    this.setState({
      annotation: null,
      edit: false,
    });
    this.props.setPredictionFocus(null);
    toggleEditMode(false);
  };

  isEditMode = () => {
    return this.props.editable && (this.state.edit || !this.props.annotation);
  };

  onSelectPrediction = p => {
    const { field } = this.props;
    this.saveAnnotation({
      id: field.id,
      source: {
        type: 'prediction',
        data: p,
      },
      data: {
        value: p.value,
        bounds: p.bounds,
      },
    });
  };

  onSelectNotPresent = () => {
    this.saveAnnotation({
      source: {
        type: 'manual',
      },
      data: {
        value: null,
      },
    });
  };

  componentDidMount() {
    if (
      this.ref &&
      this.props.takeFocus &&
      !this.ref.contains(document.activeElement)
    ) {
      this.ref.focus();
    }
  }

  componentDidUpdate = () => {
    if (
      this.ref &&
      this.props.takeFocus &&
      !this.ref.contains(document.activeElement)
    ) {
      this.ref.focus();
    }
  };

  editAnnotation = () => {
    const { toggleEditMode } = this.context;
    toggleEditMode(true);

    this.setState({
      annotation: this.props.annotation,
      edit: true,
    });

    if (
      this.props.annotation &&
      this.props.annotation.source.type == 'prediction'
    ) {
      this.props.setPredictionFocus(this.props.annotation.source.data);
    }
  };

  onCancelEdit = () => {
    const { toggleEditMode } = this.context;
    toggleEditMode(false);

    this.setState({
      annotation: null,
      edit: false,
    });
    this.props.setPredictionFocus(null);
  };
}
