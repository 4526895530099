import startCase from 'lodash/startCase';
import { v4 as uuid } from 'uuid';

export const getTableColumns = (table, field) => {
  return table.columns.filter(c =>
    field.specification.columns.find(
      fc => (fc.id === c.id || fc.id === c.category) && fc.type === 'column',
    ),
  );
};

export const getColumnTokens = column => {
  return [
    ...(column.header !== null ? column.header.tokens : []),
    ...column.cells
      .filter(c => c !== null)
      .map(c => c.tokens)
      .flat(),
  ];
};
export const getRowsFromColumn = column => {
  if (column.cells) {
    return [column.header, ...column.cells];
  }
  return [];
};

export const getTableBounds = (table, field) => {
  let tl = { x: 1.0, y: 1.0 };
  let br = { x: 0.0, y: 0.0 };

  const columns = getTableColumns(table, field);

  columns.forEach(column => {
    const tokens = getColumnTokens(column, field);
    tokens.forEach(token => {
      if (token) {
        tl = {
          x: Math.min(token.bounds.topLeft.x, tl.x),
          y: Math.min(token.bounds.topLeft.y, tl.y),
        };
        br = {
          x: Math.max(token.bounds.bottomRight.x, br.x),
          y: Math.max(token.bounds.bottomRight.y, br.y),
        };
      }
    });
  });
  return { tl, br };
};

export const getRows = (tableBounds, table, field) => {
  const rows = [];
  let rowBounds = [];

  const columns = getTableColumns(table, field);

  columns.forEach((column, i) => {
    const rowsFromCol = getRowsFromColumn(column);
    if (columns[i - 1]) {
      rowsFromCol.forEach((row, j) => {
        rowBounds[j] = {
          top: Math.min(
            row !== null && row.bounds !== null ? row.bounds.topLeft.y : 1.0,
            rowBounds[j].top,
          ),
          bottom: Math.max(
            row !== null && row.bounds !== null
              ? row.bounds.bottomRight.y
              : 0.0,
            rowBounds[j].bottom,
          ),
        };
      });
    } else {
      rowBounds = rowsFromCol.map(e =>
        e !== null && e.bounds !== null
          ? {
              top: e.bounds.topLeft.y,
              bottom: e.bounds.bottomRight.y,
            }
          : {
              top: 1.0,
              bottom: 0.0,
            },
      );
    }
  });
  rowBounds.forEach((row, i) => {
    if (rowBounds[i + 1]) {
      const rowY = (row.bottom + rowBounds[i + 1].top) / 2;
      rows.push({
        tl: {
          x: tableBounds.tl.x,
          y: rowY,
        },
        br: {
          x: tableBounds.br.x,
          y: rowY,
        },
      });
    }
  });
  return rows;
};
export const getColumns = (tableBounds, table, field) => {
  const columns = [];

  const tableColumns = getTableColumns(table, field);
  tableColumns.forEach((column, i) => {
    if (tableColumns[i + 1]) {
      const maxXA = getColumnTokens(column, field)
        .filter(e => e !== null)
        .reduce((a, b) => Math.max(a, b.bounds.bottomRight.x), 0);

      const minXB = getColumnTokens(tableColumns[i + 1], field)
        .filter(e => e !== null)
        .reduce((a, b) => Math.min(a, b.bounds.topLeft.x), 1.0);
      const colX = (maxXA + minXB) / 2;
      columns.push({
        tl: {
          x: colX,
          y: tableBounds.tl.y,
        },
        br: {
          x: colX,
          y: tableBounds.br.y,
        },
      });
    }
  });
  return columns;
};

export const getPage = (table, field) => {
  const column = getTableColumns(table, field)[0];

  return getColumnTokens(column, field).find(token => token).bounds.pageNum;
};

export const getHeaderText = column => {
  let headerText = '';
  if (column.type === 'derived-column') {
    headerText = startCase(column.category);
  }
  return headerText;
};

const makeHashedKey = label => `${label}|${uuid().substring(0, 8)}`;

export const getTable = (table, field) => {
  if (!table) return {};
  const { columns } = table;
  if (!columns) return {};
  const headers = columns.map(c => ({
    key: makeHashedKey(`header|${c.header?.text || getHeaderText(c)}|`),
    value: c.header?.text || getHeaderText(c),
  }));

  const rows = [];
  const categories = columns
    .map(c => c.category)
    .map(c => field.specification.columns.find(fc => fc.id === c)?.name);

  columns.forEach((c, i) => {
    c.cells.forEach((item, j) => {
      if (!rows[j]) {
        rows[j] = {
          key: makeHashedKey(`row_${j}|`),
          cells: [],
        };
      }
      rows[j].cells = [
        ...(rows[j].cells || []),
        {
          key: makeHashedKey(`cell_${i}_${j}|`),
          value: item?.type === 'entity' ? item?.id : item?.text,
        },
      ];
    });
  });

  return {
    headers,
    rows,
    categories,
  };
};

export const getHeaderConfig = table => {
  const { columns } = table;
  let headerConfig = '';
  for (const column of columns) {
    if (column.header) {
      headerConfig = 'row';
      break;
    }
  }
  return headerConfig;
};

export const getTableData = (table, field) => {
  const tableBounds = getTableBounds(table, field);

  return {
    tableBounds,
    rows: getRows(tableBounds, table, field),
    columns: getColumns(tableBounds, table, field),
    header: getHeaderConfig(table),
    pageId: getPage(table, field),
    lockTable: false,
    disabledRows: [],
  };
};
