export const BILLING_AND_PAYMENT = `
The billing for AI products is based on usage incurred within a calendar month,
and includes:

- A fixed monthly access fee of $9 (charged pro-rata for the first month based
on the date you first unlock your subscription)
- A variable monthly usage fee, calculated based on the amount of pages you
process within the calendar month and the "per page" tiered pricing to the left

Your provided payment method will be automatically charged at the end of each
month, and you will be sent an email confirmation receipt.

All prices are in AUD and do not include any sales or applicable taxes.`;
