import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import Modal from '../../../UI/Modal/Modal';
import CheckoutForm from '../CheckoutModal/CheckoutForm';

import { PageLoading } from '../../../Page';
import stripeBadge from '../../../../assets/images/badge-stripe.svg';

import {
  updateCompany,
  fetchCompany,
} from '../../../../reducers/company/actions';

import { fetchCountries } from '../../../../reducers/lookups/actions';
import {
  savePaymentMethod,
  saveDefaultPaymentMethod,
  deletePaymentMethod,
} from '../../../../reducers/subscriptions/actions';

import './AddCardModal.scss';

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);

const mapStateToProps = state => {
  const { auth, company, lookups, subscriptions } = state;
  const user = auth.user.data || null;
  const { countries } = lookups;

  return {
    user,
    company: company && company.data ? company.data : null,
    countries: countries && countries.data ? countries.data : null,
    paymentMethods: company.data ? company.data.paymentMethods : [],
    sending:
      subscriptions && subscriptions.sending ? subscriptions.sending : null,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      fetchCountries: () => dispatch(fetchCountries()),
      fetchCompany: companyId => dispatch(fetchCompany(companyId)),
      updateCompany: (companyId, updateDetails) =>
        dispatch(updateCompany(companyId, updateDetails, false)),
      savePaymentMethod: paymentMethod =>
        dispatch(savePaymentMethod(paymentMethod)),
      saveDefaultPaymentMethod: paymentMethodId =>
        dispatch(saveDefaultPaymentMethod(paymentMethodId)),
      deletePaymentMethod: paymentMethodId =>
        dispatch(deletePaymentMethod(paymentMethodId)),
    },
  };
};

const AddCardModal = ({
  isOpen,
  onCancelModal,
  onCloseModal,
  company,
  countries,
  dispatch,
  sending,
}) => {
  const formRef = useRef(null);

  useEffect(() => {
    if (!countries) dispatch.fetchCountries();
  }, []);

  const onClose = () => {
    onCloseModal();
  };

  const onCancel = () => {
    onCancelModal();
  };

  const handleAddPaymentMethod = payload => {
    const { paymentMethod, companyData } = payload;
    dispatch.savePaymentMethod(paymentMethod).then(() => {
      dispatch.updateCompany(company.id, companyData);
      onCloseModal();
    });
  };

  const onSubmit = () => {
    formRef.current.submitForm();
  };

  return (
    <Modal
      showCloseBtn
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onCancel}
      onConfirm={onSubmit}
      className="modal-add-card"
      cancelLabel="Cancel"
      modalLabel={
        sending ? 'Updating Payment Card Details' : 'Change Payment Card'
      }
      confirmLabel="Save"
    >
      <div className="modal-add-card-content">
        <div className="modal-add-card-info">
          {sending ? (
            <PageLoading />
          ) : (
            <Elements stripe={stripePromise} options={{ hidePostalCode: true }}>
              <CheckoutForm
                countries={countries}
                onSavePaymentMethod={handleAddPaymentMethod}
                onCountryChange={() => {}}
                company={company}
                showSaveButton={false}
                forwardRef={formRef}
              />
              <img className="stripe-logo" src={stripeBadge} alt="Stripe" />
            </Elements>
          )}
        </div>
      </div>
    </Modal>
  );
};

AddCardModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancelModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  company: PropTypes.object,
  countries: PropTypes.array,
  dispatch: PropTypes.object.isRequired,
  sending: PropTypes.bool,
};

AddCardModal.defaultProps = {
  company: {},
  countries: [],
  sending: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCardModal);
