/* eslint-disable no-underscore-dangle */
import { getClientId } from '../../common/tracking';
import TrackingService from '../../services/TrackingService';

export const SET_TRACKER = 'sypht/tracker/SET_TRACKER';

const setTracker = lookupType => {
  return {
    type: SET_TRACKER,
    payload: {
      lookupType,
      loading: true,
    },
  };
};

export const initTracker = () => {
  return async (dispatch, getState) => {
    const { tracking } = getState();
    const clientId = tracking.clientId || (await getClientId());
    dispatch(setTracker(clientId));
  };
};

export const trackEvent = ({ name: eventName, params }) => {
  return async (dispatch, getState) => {
    const { auth, tracking } = getState();
    const { userId, user } = auth;
    if (!user) return;

    const clientId = tracking.clientId || (await getClientId());
    const companyId = (user.data && user.data.companyId) || undefined;
    const userType =
      (user.data && user.data.profileData && user.data.profileData.userType) ||
      undefined;

    const userProps = {};
    if (companyId) userProps.company_id = { value: companyId };
    if (userType) userProps.userType = { value: userType };

    TrackingService.trackEvent({
      clientId,
      userId,
      nonPersonalizedAds: false,
      userProperties: {
        ...userProps,
        is_public: {
          value: (!userId).toString(),
        },
      },
      event: {
        name: eventName,
        params: {
          page_path: window.location.pathname,
          ...params,
        },
      },
    });
  };
};

export const trackEvents = events => {
  return async dispatch => {
    (events || []).forEach(event => {
      dispatch(trackEvent(event));
    });
  };
};
