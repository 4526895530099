import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { mergeRight as merge } from 'ramda';

import Form, { TextInput } from '../Form';
import Modal from '../UI/Modal/Modal';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

import { ModalContext } from '../UI/Modal/context/ModalContext';
import {
  createCredential,
  updateCredential,
} from '../../reducers/credentials/actions';
import './CredentialForm.scss';

const renderTooltip = fields => {
  const listItems = fields.map(f => `<li>${f}</li>`).join('');
  return (
    '<h5 class="tooltip__heading">Extractable fields</h5>' +
    `<ul class="tooltip__list">${listItems}</ul>`
  );
};

const closeModal = setModal => {
  setModal(p =>
    merge(p, {
      isOpen: false,
    }),
  );
};

const mapStateToProps = state => {
  const { credentials, subscriptions } = state;
  const { sending, sent } = credentials;

  return {
    subscriptions: subscriptions.data || [],
    sending,
    sent,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      createCredential: (data, companyId) =>
        dispatch(createCredential(data, companyId)),
      updateCredential: (id, data, companyId) =>
        dispatch(updateCredential(id, data, companyId)),
    },
  };
};

const CredentialForm = ({ sending, sent, dispatch, companyId }) => {
  const checkboxRefs = useRef([]);
  const [modal, setModal] = useContext(ModalContext);
  const { credential: cred, isOpen } = modal;

  const [clientId, setClientId] = useState(cred ? cred.clientId : '');
  const [clientName, setClientName] = useState(cred ? cred.clientName : '');
  const [isValid, setValid] = useState(false);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    if (clientName.length && /^[\w\s+=,.@-]+$/.test(clientName)) {
      setValid(true);
      setErrors({});
      return;
    }
    const error =
      clientName.length === 0
        ? 'cannot be empty'
        : !/^[\w\s+=,.@-]+$/.test(clientName)
        ? 'may only include +=,.@- characters'
        : null;
    setValid(false);

    setErrors({ name: [error] });
  };

  // Set initial values on credential change
  useEffect(() => {
    if (cred) {
      setClientId(cred.clientId);
      setClientName(cred.clientName);
      validateForm();
    }
  }, [cred]);

  // Validate form on value change
  useEffect(() => {
    validateForm();
  }, [clientName]);

  // Check / uncheck boxes on modal open
  useEffect(() => {
    if (isOpen) {
      (checkboxRefs.current || []).forEach(el => {
        // eslint-disable-next-line no-param-reassign
        el.checked = fieldSet.includes(el.id);
      });
    }
  });

  // Close modal after credential is sent
  useEffect(() => {
    if (sent && !sending) {
      closeModal(setModal);
    }
  }, [sending, sent]);

  const handleCloseModal = () => {
    closeModal(setModal);
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (cred.isNew && isValid) {
      dispatch.createCredential(
        {
          clientName,
        },
        companyId,
      );
    } else if (isValid) {
      dispatch.updateCredential(
        clientId,
        {
          clientName,
        },
        companyId,
      );
    }
  };

  const handleTextChange = e => {
    setClientName(e.target.value);
    validateForm();
  };

  return (
    <Modal
      className="credential-modal"
      onClose={handleCloseModal}
      onCancel={handleCloseModal}
      modalLabel={`${cred && cred.isNew ? 'Create new' : 'Edit'} credentials`}
      isOpen={isOpen}
      disableBgClick={false}
      onConfirm={handleSubmit}
      confirmLabel="Submit"
      disabledConfirm={!isValid}
    >
      <Form className="credential-form">
        <TextInput
          label="Credential name"
          name="name"
          placeholder="Credential name"
          value={clientName}
          errors={errors}
          onChange={handleTextChange}
        />
        {sending ? <LoadingSpinner className="admin-form__spinner" /> : null}
      </Form>
    </Modal>
  );
};

CredentialForm.propTypes = {
  subscriptions: PropTypes.arrayOf(PropTypes.object),
  sending: PropTypes.bool,
  sent: PropTypes.bool,
  dispatch: PropTypes.shape({
    createCredential: PropTypes.func,
    updateCredential: PropTypes.func,
  }),
};

CredentialForm.defaultProps = {
  subscriptions: null,
  sending: false,
  sent: false,
  dispatch: {
    createCredential: () => {},
    updateCredential: () => {},
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(CredentialForm);
