import * as React from 'react';
import './_icons.scss';

function DocumentSplitChild(props) {
  const svg = (
    <svg
      width={36}
      height={36}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={18}
        cy={18}
        r={17}
        fill="#fff"
        stroke="#60DBFF"
        strokeWidth={2}
      />
      <g clipPath="url(#a)" fill="#3C3C3B">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m20.938 10.438 2.621 2.621c.282.282.441.666.441 1.063V16.5h-1.5v-1h-3.25a.748.748 0 0 1-.75-.75V11.5h-5v5H12v-5c0-.828.672-1.5 1.5-1.503h6.378a1.5 1.5 0 0 1 1.06.44ZM12 21.5v3a1.5 1.5 0 0 0 1.5 1.5h9a1.5 1.5 0 0 0 1.5-1.5v-3h-1.5v3h-9v-3H12Zm8-7.5h2.378L20 11.622V14Z"
        />
        <rect x={10} y={18} width={4} height={2} rx={0.5} />
        <rect x={15.5} y={18} width={5} height={2} rx={0.5} />
        <rect x={22} y={18} width={4} height={2} rx={0.5} />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(10 10)" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );

  return <div className="sypht-icon__container">{svg}</div>;
}

export default DocumentSplitChild;
