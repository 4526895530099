import React from 'react';
import PropTypes from 'prop-types';

const AccordionHeader = ({ children }) => {
  return <div className="accordion__header">{children}</div>;
};

AccordionHeader.propTypes = {
  children: PropTypes.node,
};

AccordionHeader.defaultProps = {
  children: null,
};

export default AccordionHeader;
