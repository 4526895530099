import React from 'react';
import PropTypes from 'prop-types';
import { FaFileInvoice } from 'react-icons/fa';
import { Button } from '../../../../library/atoms/Buttons';

const ExtractButton = ({ isDisabled, onClick, ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button disabled={isDisabled} onClick={onClick} {...props}>
      <FaFileInvoice />
      <span>Extract</span>
    </Button>
  );
};

ExtractButton.propTypes = {
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

ExtractButton.defaultProps = {
  isDisabled: false,
};

export default ExtractButton;
