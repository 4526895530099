import sessionStorage from 'redux-persist/lib/storage/session';
import storage from 'redux-persist/lib/storage';
import { cleanNextTask } from './transforms';

// Configs for persisting redux app state.

/**
 * Persist auth into session storage.
 */
export const auth = { key: 'auth', storage: sessionStorage };

/**
 * Persist next task filters and sortBy into local storage so that the next task
 * button or next task status message is consistent across refreshes and
 * new windows when user is on a task.
 */
export const nextTask = {
  key: 'nextTask',
  storage,
  whitelist: ['filters', 'sortBy'],
  transforms: [cleanNextTask],
};
