import {
  REQUEST_DOC,
  RECEIVE_DOC,
  RECEIVE_ERROR,
  REQUEST_PRODUCTS,
  RECEIVE_PRODUCTS,
  TOGGLE_DRAWER,
} from './actions';

const initialState = {
  capture: {
    isDrawerOpen: false,
  },
  docs: {
    loading: true,
  },
  products: {
    data: null,
    receivedAt: null,
    loading: true,
  },
};

const productReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_DOC: {
      return {
        ...state,
        docs: {
          ...state.docs,
          loading: true,
        },
      };
    }

    case RECEIVE_DOC: {
      const { data } = payload;
      return {
        ...state,
        docs: {
          ...state.docs,
          [data.id]: {
            ...data,
            receivedAt: Date.now(),
            isRefresh: false,
          },
          loading: false,
        },
      };
    }

    case RECEIVE_ERROR: {
      return {
        docs: {
          ...state.docs,
          loading: false,
        },
        products: {
          ...state.products,
          loading: false,
        },
      };
    }

    case REQUEST_PRODUCTS: {
      return {
        ...state,
        products: {
          ...state.products,
          loading: true,
        },
      };
    }

    case RECEIVE_PRODUCTS: {
      const { data } = payload;
      return {
        ...state,
        products: {
          ...state.products,
          data,
          receivedAt: Date.now(),
          loading: false,
        },
      };
    }

    case TOGGLE_DRAWER: {
      const { data } = payload;
      return {
        ...state,
        capture: {
          ...state.capture,
          isDrawerOpen: data.isOpen,
        },
      };
    }

    default:
      return state;
  }
};

export default productReducer;
