/* eslint-disable no-param-reassign */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FaCaretRight } from 'react-icons/fa';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { scrollX } from '../../../common/helpers';
import { TabsContext } from './TabsContext';
import './Tabs.scss';

const calcSetMore = (innerRef, outerRef, setMore) => {
  const inner = innerRef.current;
  const outer = outerRef.current;
  if (inner && outer) {
    setMore(inner.offsetWidth > outer.offsetWidth);
  }
};

const moveMarker = (marker, activeTab) => {
  if (marker && activeTab) {
    const diffX = Math.round(activeTab.offsetWidth / 2) - 1;
    marker.style.transform = `translateX(${
      activeTab.offsetLeft + diffX
    }px) scaleX(${activeTab.offsetWidth})`;
  }
};

const Tabs = ({ children, className, theme }) => {
  const [isScrollable, setScrollable] = useState(false);
  const [tabsContext] = useContext(TabsContext);
  const { activeElem } = tabsContext;
  const innerRef = useRef(null);
  const outerRef = useRef(null);
  const markerRef = useRef(null);

  useEffect(() => {
    calcSetMore(innerRef, outerRef, setScrollable);
    window.addEventListener('resize', () =>
      calcSetMore(innerRef, outerRef, setScrollable),
    );
    return () => {
      window.removeEventListener('resize', calcSetMore);
    };
  }, []);

  useEffect(() => {
    if (activeElem) moveMarker(markerRef.current, activeElem);
    window.addEventListener('resize', () =>
      moveMarker(markerRef.current, activeElem),
    );
    return () => {
      window.removeEventListener('resize', moveMarker);
    };
  }, [activeElem]);

  const handleMoreClick = e => {
    e.preventDefault();
    scrollX(outerRef.current, outerRef.current.offsetWidth, 'right');
  };

  return (
    <nav
      className={classNames('tabs', className, {
        'tabs--scrollable': isScrollable,
        'secondary-theme': theme === 'secondary-theme',
      })}
    >
      <div className="tabs__inner" ref={outerRef}>
        <ul className="tabs__list" ref={innerRef}>
          {children}
        </ul>
      </div>
      {isScrollable && (
        <button type="button" className="tabs__more" onClick={handleMoreClick}>
          <FaCaretRight />
        </button>
      )}
      <span className="tabs__marker" ref={markerRef} />
    </nav>
  );
};

Tabs.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  theme: PropTypes.string,
};

Tabs.defaultProps = {
  children: null,
  className: null,
  theme: null,
};

export default Tabs;
