import UserService from '../../services/UserService';
import AuthService from '../../services/AuthService';
import { addNotification } from '../notifications';
import { refreshUsers } from '../users/actions';
import { fetchSubscriptions } from '../subscriptions/actions';
import {
  createdMessage,
  errorMessage,
  errorRegistration,
  passwordChangedMessage,
  passwordError,
  updatedMessage,
  forgotPasswordSuccess,
  sendInviteEmailSuccess,
  updateUserError,
} from './messages';
import { getFullName } from '../../common/helpers/userHelper';
import { receiveUser as updateAuth, login } from '../authReducer';

export const SEND_USER = 'sypht/user/SEND_USER';
export const REQUEST_USER = 'sypht/user/REQUEST_USER';
export const RECEIVE_USER = 'sypht/user/RECEIVE_USER';
export const REQUEST_PASSWORD_RESULT = 'sypht/user/REQUEST_PASSWORD_RESULT';
export const RECEIVE_PASSWORD_RESULT = 'sypht/user/RECEIVE_PASSWORD_RESULT';
export const TRANSMIT_DATA = 'sypht/user/TRANSMIT_DATA';
export const RECEIVE_DATA = 'sypht/user/RECEIVE_DATA';

const transmitData = () => {
  return {
    type: TRANSMIT_DATA,
    payload: {
      loading: true,
      sent: false,
    },
  };
};

const receiveData = () => {
  return {
    type: RECEIVE_DATA,
    payload: {
      loading: false,
      sent: true,
    },
  };
};

const sendUser = () => {
  return {
    type: SEND_USER,
    payload: {
      loading: true,
      sending: true,
      sent: false,
    },
  };
};

const cancelSendUser = () => {
  return {
    type: SEND_USER,
    payload: {
      loading: false,
      sending: false,
      sent: true,
    },
  };
};

const requestUser = () => {
  return {
    type: REQUEST_USER,
    payload: {
      loading: true,
    },
  };
};

const receiveUser = (data, error, sent = true) => {
  return {
    type: RECEIVE_USER,
    payload: {
      data,
      error,
      loading: false,
      sending: false,
      sent,
      receivedAt: Date.now(),
    },
  };
};

const requestPasswordResult = () => {
  return {
    type: REQUEST_PASSWORD_RESULT,
    payload: {
      loading: true,
    },
  };
};

const receivePasswordResult = data => {
  return {
    type: RECEIVE_PASSWORD_RESULT,
    payload: {
      data,
      receivedAt: Date.now(),
      loading: false,
      sending: false,
      sent: true,
    },
  };
};

export const createUser = (userData, companyId = undefined) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken, user: { data: user }} = auth; // prettier-ignore

    dispatch(sendUser());
    try {
      const response = await UserService.addUser(
        accessToken,
        companyId || user.companyId,
        userData,
      );

      if (response.data && response.data.created) {
        const name = getFullName(response.data);
        dispatch(receiveUser(response.data));
        dispatch(refreshUsers());
        // Update subscriptions to refresh license counts (eg for HITL)
        dispatch(
          fetchSubscriptions({ companyId: companyId || user.companyId }),
        );
        dispatch(addNotification(createdMessage(name)));
      } else {
        dispatch(receiveUser(null));
        dispatch(addNotification(errorMessage));
      }
    } catch (err) {
      const status = err.response?.status;
      const { message } = err.response?.data ?? {};
      dispatch(cancelSendUser());
      dispatch(addNotification(updateUserError(status < 500 ? message : null)));
    }
  };
};

export const fetchUser = userId => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    dispatch(requestUser());
    const { accessToken } = auth;
    return UserService.getUserById(accessToken, userId)
      .then(({ data }) => dispatch(receiveUser(data)))
      .catch(error => {
        dispatch(receiveUser(null, error));
      });
  };
};

export const updateUser = (userId, userData, companyId) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;

    dispatch(sendUser());
    try {
      const response = await UserService.updateUser(
        accessToken,
        userId,
        userData,
      );

      if (response.data && response.data.updated) {
        if (auth.userId === userId) {
          dispatch(updateAuth(response.data));
        }
        const name = getFullName(response.data);
        dispatch(receiveUser(response.data));
        dispatch(refreshUsers());
        // Update subscriptions to refresh license counts (eg for HITL)
        dispatch(fetchSubscriptions({ companyId }));
        dispatch(addNotification(updatedMessage(name)));
      } else {
        dispatch(receiveUser(null));
        dispatch(addNotification(errorMessage));
      }
    } catch (err) {
      const status = err.response?.status;
      const { message } = err.response?.data ?? {};
      dispatch(cancelSendUser());
      dispatch(addNotification(updateUserError(status < 500 ? message : null)));
    }
  };
};

export const updatePassword = (oldPassword, newPassword) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;
    dispatch(requestPasswordResult());
    return UserService.changePassword(accessToken, oldPassword, newPassword)
      .then(({ data }) => {
        dispatch(receivePasswordResult(data));
        dispatch(addNotification(passwordChangedMessage));
      })
      .catch(error => {
        dispatch(receivePasswordResult(error));
        if (error.response.status === 403) {
          dispatch(addNotification(passwordError));
        } else {
          dispatch(addNotification(errorMessage));
        }
      });
  };
};

export const registerUser = userData => {
  return async dispatch => {
    dispatch(transmitData());

    try {
      const response = await UserService.registerUser(userData);

      if (response.data && response.data.created) {
        dispatch(login(userData.email, userData.password));
      } else {
        dispatch(addNotification(errorMessage));
        dispatch(receiveData());
      }
    } catch (err) {
      const { response } = err;
      dispatch(receiveData());
      dispatch(addNotification(errorRegistration(response.data.message)));
    }
  };
};

export const registerInviteUser = userData => {
  return async dispatch => {
    dispatch(transmitData());

    try {
      const response = await UserService.registerInviteUser(userData);

      if (response.data && response.data.created) {
        dispatch(login(userData.email, userData.password));
      } else {
        dispatch(addNotification(errorMessage));
        dispatch(receiveData());
      }
    } catch (err) {
      const { response } = err;
      dispatch(receiveData());
      dispatch(errorRegistration(response.data.message));
    }
  };
};

export const forgotPassword = email => dispatch => {
  return AuthService.forgotPassword(email).then(
    () => {
      dispatch(addNotification(forgotPasswordSuccess(email)));
    },
    () => {
      dispatch(addNotification(errorMessage));
    },
  );
};

export const sendInviteEmail = user => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;
    if (!user) {
      dispatch(addNotification(errorMessage));
      return;
    }
    await UserService.resendInviteEmail(accessToken, user.id)
      .then(() => {
        dispatch(addNotification(sendInviteEmailSuccess(user.email)));
      })
      .catch(() => {
        dispatch(addNotification(errorMessage));
      });
  };
};
