import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal from './Modal';
import Button from '../../../library/atoms/Buttons/Button';

const ConfirmModal = ({
  children,
  className,
  heading,
  description,
  confirmText,
  cancelText,
  isConfirmDisabled,
  onBgClick,
  onConfirmClick,
  onCancelClick,
  ...props
}) => {
  const modalClass = classNames('modal--confirm', className);
  const [open, setOpen] = useState(true);

  const handleCancelClick = e => {
    onCancelClick(e);
    setOpen(false);
  };

  const handleConfirmClick = e => {
    onConfirmClick(e);
    setOpen(false);
  };

  return (
    <Modal
      className={modalClass}
      heading={heading}
      open={open}
      onBgClick={onBgClick}
      onCloseClick={onCancelClick}
      {...props}
    >
      <div className="modal__body">
        {!description ? null : <div className="modal__text">{description}</div>}
        {children}
      </div>
      <div className="modal__footer modal__footer--cta">
        <Button
          className="modal__button"
          variant="outline"
          onClick={handleCancelClick}
        >
          {cancelText || 'Cancel'}
        </Button>
        <Button
          className="modal__button modal__button--delete"
          onClick={handleConfirmClick}
          disabled={isConfirmDisabled}
        >
          {confirmText || 'OK'}
        </Button>
      </div>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.any,
  confirmText: PropTypes.any,
  cancelText: PropTypes.any,
  isConfirmDisabled: PropTypes.bool,
  onBgClick: PropTypes.func,
  onConfirmClick: PropTypes.func,
  onCancelClick: PropTypes.func,
};

ConfirmModal.defaultProps = {
  children: null,
  className: null,
  heading: null,
  description: null,
  confirmText: null,
  cancelText: null,
  isConfirmDisabled: undefined,
  onBgClick: () => {},
  onConfirmClick: () => {},
  onCancelClick: () => {},
};

export default ConfirmModal;
