import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from './Checkbox';
import './Checkbox.scss';

const CheckboxTag = ({ tag, ...props }) => {
  return (
    <div className="checkbox-tag">
      {/* <!-- todo: placeholder for future functionality --> */}
      {/* <span className="checkbox-tag__btn"> */}
      {/*  {(tag || 'S').substr(0, 1).toUpperCase()} */}
      {/* </span> */}
      <Checkbox {...props} />
    </div>
  );
};

CheckboxTag.propTypes = {
  tag: PropTypes.string,
  // inherited props
  children: PropTypes.node,
  className: PropTypes.string,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  forwardRef: PropTypes.object,
  label: PropTypes.string,
  labelPosition: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  name: PropTypes.string,
  value: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  onChange: PropTypes.func,
  partialChecked: PropTypes.bool,
};

CheckboxTag.defaultProps = {
  tag: 'S',
  // inherited props
  children: null,
  className: '',
  defaultChecked: undefined,
  disabled: false,
  id: '',
  forwardRef: null,
  label: '',
  labelPosition: 'right',
  name: '',
  value: '',
  size: 'md',
  onChange: () => {},
  partialChecked: false,
};

export default CheckboxTag;
