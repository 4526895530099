import {
  RECEIVE_RULES,
  RECEIVE_PRODUCT_RULES,
  RECEIVE_PRODUCT_FIELDS,
} from './actions';

const initialState = {
  fields: null,
  fieldsets: null,
  products: null,
  rules: null,
};

const validateRulesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RECEIVE_RULES: {
      const { rule } = payload;
      const { id, productId, rules } = rule;
      const { conditions } = rules;

      return {
        ...state,
        rules: {
          ...state.rules,
          [productId]: {
            id,
            conditions,
          },
        },
      };
    }

    case RECEIVE_PRODUCT_RULES: {
      const { productRules } = payload;
      const groupedProductRules = {};

      productRules.forEach(r => {
        const { id, productId, rules } = r;
        groupedProductRules[productId] = {
          id,
          ...rules,
        };
      });

      return {
        ...state,
        rules: groupedProductRules,
      };
    }

    case RECEIVE_PRODUCT_FIELDS: {
      const { product } = payload;
      const fields = {};
      product.fieldsets
        .map(fs => fs.data)
        .map(d => d.fields)
        .flat(1)
        .forEach(f => {
          fields[f.id] = f;
        });

      const fieldsets = product.fieldsets.map(fs => ({
        ...fs.data,
        name: fs.name,
        description: fs.description,
      }));

      return {
        ...state,
        fields: {
          ...state.fields,
          [product.productId]: fields,
        },
        fieldsets: {
          ...state.fieldsets,
          [product.productId]: fieldsets,
        },
      };
    }

    default:
      return state;
  }
};

export default validateRulesReducer;
