import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './_userList.scss';

const UserList = ({ className, children }) => {
  const users = (children || []).flat();
  return (
    <div className="user-list">
      <ul className={classNames('user-list__list', className)}>
        {users.slice(0, 3).map(userNode => {
          return (
            <li
              className="user-list__item"
              key={`userListItem|${userNode.key}`}
            >
              {userNode}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

UserList.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

UserList.defaultProps = {
  className: null,
  children: null,
};

export default UserList;
