import { STYLE } from '../../components/UI/Notification/Notification';

export const createdMessage = name => {
  return {
    style: STYLE.CONFIRMATION,
    message: `Credential ${name} has been created.`,
  };
};

export const updatedMessage = name => {
  return {
    style: STYLE.CONFIRMATION,
    message: `Credential ${name} has been updated.`,
  };
};

export const deletedMessage = name => {
  return {
    style: STYLE.CONFIRMATION,
    message: `Credential ${name} has been deleted.`,
  };
};

export const errorMessage = {
  style: STYLE.ERROR,
  message:
    'Something went wrong, please try again. If this issue persists ' +
    'please contact us at support@sypht.com.',
  timeout: 8000,
};
