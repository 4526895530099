export const SpecSchema = {
  definitions: {
    field: {
      type: 'object',
      required: ['id', 'type', 'annotated', 'name', 'description'],
      properties: {
        id: {
          type: 'string',
        },
        type: {
          type: 'string',
        },
        annotated: {
          type: 'boolean',
        },
        multiple: {
          type: 'boolean',
        },
        name: {
          type: 'string',
        },
        description: {
          type: 'string',
        },
        components: {
          type: 'array',
          items: {
            $ref: '#/definitions/field',
          },
        },
        options: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              id: {
                type: 'string',
              },
              title: {
                type: 'string',
              },
              description: {
                type: 'string',
              },
            },
            additionalProperties: false,
            required: ['id', 'title', 'description'],
          },
        },
      },
      additionalProperties: false,
    },
  },
  title: 'Specification',
  type: 'object',
  required: [
    'description',
    'longDescription',
    'isPublic',
    'isAnnotated',
    'isDefault',
  ],
  additionalProperties: false,
  properties: {
    description: {
      type: 'string',
      description: 'A human friendly name.',
    },
    longDescription: {
      type: 'string',
      description: 'A long-form description.',
    },
    isPublic: {
      type: 'boolean',
      description:
        'Determines whether this specification is accessible to other companies.',
    },
    isAnnotated: {
      type: 'boolean',
      description:
        'Determines whether validate users can create annotation tasks based on this specification. Almost always should be true.',
    },
    isDefault: {
      type: 'boolean',
      description:
        'Determines whether this specificatino should be included in the default or free-tier. Almost always should be false.',
    },
    data: {
      type: 'object',
      required: ['fields'],
      additionalProperties: false,
      properties: {
        fields: {
          type: 'array',
          items: {
            $ref: '#/definitions/field',
          },
        },
      },
    },
  },
};
