import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const TableElem = ({ children, className, tagName, ...props }) => {
  const Elem = tagName;
  return (
    <Elem
      className={classNames(
        tagName !== 'table' ? `table__${tagName}` : 'table',
        className,
      )}
      {...props}
    >
      {children}
    </Elem>
  );
};

TableElem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  tagName: PropTypes.string.isRequired,
};

TableElem.defaultProps = {
  children: null,
  className: null,
};

export const Table = props => TableElem({ ...props, tagName: 'table' });
export const Thead = props => TableElem({ ...props, tagName: 'thead' });
export const Tbody = props => TableElem({ ...props, tagName: 'tbody' });
export const Tr = props => TableElem({ ...props, tagName: 'tr' });
export const Td = props => TableElem({ ...props, tagName: 'td' });
export const Th = props => TableElem({ ...props, tagName: 'th' });
export const Tfoot = props => TableElem({ ...props, tagName: 'tfoot' });
