import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { FaUserCircle } from 'react-icons/fa';
import './ProfileWidget.scss';

class ProfileWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
    };

    this.menuRef = React.createRef();
    this.buttonRef = React.createRef();

    this.buttonClick = this.buttonClick.bind(this);
    this.outsideClick = this.outsideClick.bind(this);
  }

  buttonClick() {
    const { showMenu } = this.state;

    if (!showMenu) {
      document.addEventListener('mousedown', this.outsideClick);
    } else {
      document.removeEventListener('mousedown', this.outsideClick);
    }

    this.setState({
      showMenu: !showMenu,
    });
  }

  outsideClick(e) {
    // if target of the click is not (the menu or a child of the menu)
    if (
      !(
        this.menuRef.current === e.target ||
        this.menuRef.current.contains(e.target)
      ) &&
      !(
        this.buttonRef.current === e.target ||
        this.buttonRef.current.contains(e.target)
      )
    ) {
      document.removeEventListener('mousedown', this.outsideClick);
      window.scroll({ top: 0, behavior: 'smooth' });
      this.setState({
        showMenu: false,
      });
    }
  }

  computeName() {
    const { user } = this.props;
    if (user && user.firstName) {
      if (!user.lastName) {
        return user.firstName;
      }

      return `${user.firstName} ${user.lastName}`;
    }
    return '';
  }

  render() {
    const { showMenu } = this.state;
    const { user, urls, logout, theme } = this.props;
    const isValidate = window.location.href.indexOf(urls.validate) >= 0;
    const loading = !(user && user.id);
    const name = this.computeName();

    return (
      <div className={classNames('profile__wrapper', { [`${theme}`]: theme })}>
        {!loading && (
          <>
            <button
              type="button"
              className={classNames('profile__button', { active: showMenu })}
              onClick={this.buttonClick}
              ref={this.buttonRef}
            >
              <img
                alt="avatar"
                src={user.auth0.picture}
                className="profile__avatar"
              />
            </button>
            <div
              className={classNames('profile__menu', { visible: showMenu })}
              ref={this.menuRef}
            >
              <div className="profile__menu-details">
                <div className="profile__menu-name">{name}</div>
                {isValidate ? (
                  <NavLink onClick={this.buttonClick} to="/profile">
                    My Profile
                  </NavLink>
                ) : (
                  <a href={`${urls.validate}/profile`}>My Profile</a>
                )}
                {!isValidate && <a href={urls.validate}>Platform</a>}
              </div>
              <div className="profile__menu-footer">
                <button
                  type="button"
                  className="profile__menu-logout"
                  onClick={logout}
                >
                  Log out
                </button>
              </div>
            </div>
          </>
        )}
        {loading && (
          <FaUserCircle style={{ fontSize: '30px', color: '#d8d8d8' }} />
        )}
      </div>
    );
  }
}

ProfileWidget.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    auth0: PropTypes.shape({
      picture: PropTypes.string,
    }),
  }).isRequired,
  urls: PropTypes.shape({
    validate: PropTypes.string.isRequired,
    admin: PropTypes.string.isRequired,
    www: PropTypes.string.isRequired,
  }).isRequired,
  logout: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
};

ProfileWidget.defaultProps = {};

export default ProfileWidget;
