/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MdErrorOutline } from 'react-icons/md';
import Page from './Page';
import { getPageIds } from '../../common/helpers';

const DocumentContentView = ({
  doc,
  companyId,
  focusPrediction,
  highlights,
  candidateSelection,
  onLoad,
  // isTableView,
  // tableSelectionMode,
  processingLimit,
}) => {
  const [pagesLoaded, setPagesLoaded] = useState(1);
  const onPageLoad = () => {
    if (doc) {
      setPagesLoaded(pagesLoaded + 1);
      if (pagesLoaded === parseInt(doc.received.numOfPages, 10)) {
        onLoad();
      }
    }
  };
  const pageIds = getPageIds(doc);

  return (
    <div className="document-container">
      {pageIds.map((pageId, i) => (
        <React.Fragment key={i}>
          {i === processingLimit && (
            <div className="document-container__divider">
              <div className="document-container__divider-icon">
                <MdErrorOutline />
              </div>
              <span className="document-container__divider-text">
                Processing limit reached: pages are not extracted after this
                point.
              </span>
            </div>
          )}
          <Page
            docId={doc.docId}
            companyId={companyId}
            pageId={pageId}
            focusPrediction={focusPrediction}
            candidateSelection={candidateSelection}
            highlights={
              highlights
                ? highlights.filter(h => String(h.bounds.pageNum) === pageId)
                : []
            }
            imageUrl={doc.images && doc.images[i]}
            onLoad={onPageLoad}
            // isTableView={isTableView}
            // tableSelectionMode={tableSelectionMode}
            disabled={processingLimit && processingLimit <= i}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

DocumentContentView.propTypes = {
  doc: PropTypes.shape({
    docId: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.string),
    received: PropTypes.shape({
      numOfPages: PropTypes.number,
    }),
  }),
  companyId: PropTypes.string,
  focusPrediction: PropTypes.shape({}),
  highlights: PropTypes.arrayOf(PropTypes.shape({})),
  candidateSelection: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onLoad: PropTypes.func,
  processingLimit: PropTypes.number,
};

DocumentContentView.defaultProps = {
  doc: null,
  companyId: null,
  focusPrediction: null,
  highlights: null,
  candidateSelection: null,
  onLoad: () => {},
  processingLimit: null,
};

export default DocumentContentView;
