import React, { useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './Form.scss';

const Form = ({ children, className, onSubmit }) => {
  const formRef = useRef(null);

  const handleSubmit = e => {
    onSubmit(e);
  };

  const formClass = classNames(className, 'form');

  return (
    <form
      className={formClass}
      onSubmit={handleSubmit}
      noValidate="novalidate"
      ref={formRef}
    >
      {children}
    </form>
  );
};

Form.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onSubmit: PropTypes.func,
};

Form.defaultProps = {
  children: null,
  className: null,
  onSubmit: () => {},
};

export default Form;
