import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import classNames from 'classnames';
import moment from 'moment';
import history from '../../services/historyService';
import * as docsReducer from '../../reducers/docs';
import * as uploadReducer from '../../reducers/upload';
import * as specsReducer from '../../reducers/specs';
import * as subscriptionActions from '../../reducers/subscriptions/actions';
import AddTaskModal from '../AddTaskModal/AddTaskModal';
import ExtractModal from '../ExtractModal/ExtractModal';
import DeleteDocsModal from '../DeleteDocsModal/DeleteDocsModal';
import SplitModal from '../SplitModal/SplitModal';
import * as modal from '../../library/molecules/Modal';
import DocumentView from '../DocumentView/DocumentView';
import Sidebar from './components/Sidebar/Sidebar';
import DocumentToolbar from '../UI/DocumentToolbar/DocumentToolbar';
import DocumentToolbarContainer from '../UI/DocumentToolbar/DocumentToolbarContext';
import ErrorPage from '../ErrorPage/ErrorPage';
import usePrevious from '../../common/hooks/usePrevious';
import './Document.scss';
import GenericTable from './components/Tables/GenericTable';
import getHighlights from './utils/getHighlights';
import TableContextProvider from '../DocumentView/TableContext';
import TableData from '../DocumentView/TableData';
import ExportModal from '../ExportModal/ExportModal';
import * as productActions from '../../reducers/products/actions';
import mustBeAnArrayIfPresent from '../../common/helpers/mustBeAnArrayIfPresent';

const Page404 = () => (
  <ErrorPage message="Oopssss! Page not found">
    The page you are looking for might have been removed or temporarily
    unavailable.
    <br /> Return to
    <a href="/"> homepage</a>
  </ErrorPage>
);

const mapStateToProps = state => {
  const { subscriptions } = state;
  const scopes = state.auth.claims ? state.auth.claims.allowedScopes : [];
  const user = state.auth.user.data;

  return {
    scopes,
    specs: state.specs.data ? state.specs.data.specifications : null,
    doc: state.docs.active.data,
    docsExtracted: state.docs.docsExtracted,
    docsRequested: state.docs.docsRequested,
    exportLoading: state.docs.exportLoading,
    companyId: user ? user.companyId : null,
    processingLimit: user ? user?.company?.processingLimit : null,
    genericTables: state.docs.genericTables,
    products: mustBeAnArrayIfPresent(subscriptions.data) || [],
    hasValidateProduct: state.subscriptions.hasValidateProduct,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchDoc: docId => dispatch(docsReducer.fetchDoc(docId)),
    addTask: (docId, specification, replication, priority, companyId) =>
      dispatch(
        uploadReducer?.addTask(
          docId,
          specification,
          replication,
          priority,
          companyId,
        ),
      ),
    autoSplitOrManualSplit: (splitType, docs) =>
      dispatch(docsReducer.autoSplitOrManualSplit(splitType, docs)),
    fetchSpecs: filters => dispatch(specsReducer.fetchSpecs(filters)),
    getDocsPredictions: docs => dispatch(docsReducer.getDocsPredictions(docs)),
    extract: (docId, products, companyId) =>
      dispatch(docsReducer.extract(docId, products, companyId)),
    deleteDocs: docIds => dispatch(docsReducer.deleteDocs(docIds)),
    setGenericData: genericData =>
      dispatch(docsReducer.receiveGenericData(genericData)),
    fetchProducts: () => dispatch(productActions.fetchProducts()),
    fetchSubscriptions: () =>
      dispatch(subscriptionActions.fetchSubscriptions()),
  };
};

const Document = ({
  doc,
  specs,
  fetchDoc,
  addTask,
  autoSplitOrManualSplit,
  fetchSpecs,
  products,
  getDocsPredictions,
  extract,
  deleteDocs,
  docsExtracted,
  docsRequested,
  companyId,
  processingLimit,
  match: {
    params: { docId },
  },
  setGenericData,
  genericTables,
  fetchSubscriptions,
  fetchProducts,
  hasValidateProduct,
}) => {
  const [focusPrediction, setFocusPrediction] = useState(null);
  const [isTaskModalOpen, setTaskModalOpen] = useState(false);
  const [isExportModalOpen, setExportModalOpen] = useState(false);
  const [isSplitModalOpen, setSplitModalOpen] = useState(false);
  const [isExtractModalOpen, setExtractModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [extractDocs, setExtractDocs] = useState([]);
  const [, setFocusLineItemTbl] = useState(null); // FIXME: this only ever sets null
  const [focusGenericTbl, setFocusGenericTbl] = useState(null);
  const [focusAnnotationTbl, setFocusAnnotationTbl] = useState(null);
  const [extracting, setExtracting] = useState(false);

  const [selectedTable, setSelectedTable] = useState(null); // string
  const refPanelLineItems = useRef([]);
  const refPanelGenericTables = useRef([]);

  // track generic accordion open elements and state
  const [isFieldsAccordionOpen, setFieldsAccordionOpen] = useState(null);
  const refSidebarGenericFields = useRef(null);
  const [isTablesAccordionOpen, setTablesAccordionOpen] = useState(null);
  const refSidebarGenericTables = useRef(null);

  const prevProps = usePrevious({ docId, docsExtracted, docsRequested });
  const [selected, setSelected] = useState({
    [docId]: {
      id: docId,
      filename: '',
    },
  });

  const [lineItemsObj] = useState([]); // FIXME: this always has length 0!
  const [genericObj, setGenericObj] = useState([]);
  const [genericEntities, setGenericEntities] = useState([]);

  const fieldsetOptions = products
    .filter(p => !p.endDate || moment(p.endDate) > moment())
    .map(p => ({
      label: p.name,
      value: p.productId,
      fieldsets: p.fieldsets,
      tags: p.tags,
    }));

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  useEffect(() => {
    fetchDoc(docId);
    getDocsPredictions([docId]).then(res => setExtractDocs(res.data));
  }, [docId, fetchDoc, getDocsPredictions]);

  useEffect(() => {
    if (doc && doc.received) {
      const docsCompany = doc.received.submittedByCompanyId;
      const { fileName } = doc.received;
      const { numOfPages } = doc.received;
      const updateDoc = {
        companyId: docsCompany,
        id: docId,
        fileName,
        filename: fileName,
        docId,
        numOfPages,
        tags: doc.tags,
        isPending: doc.isPending,
        canQuickFix: doc.canQuickFix,
        original: doc.original,
      };

      setSelected(s => ({
        ...s,
        [docId]: {
          ...updateDoc,
          products: extractDocs.find(d => d.docId === docId)?.products,
        },
      }));
    }
  }, [doc, docId, extractDocs]);

  // Fetch subscriptions which is based on companyId.
  //
  // FIXME: companyId may be a proxy for being logged in.
  // FIXME: Several other things apparently need to trigger on companyId change.

  useEffect(() => {
    setFocusPrediction(null);
    if (!specs) {
      fetchSpecs({ limit: 0, offset: 0, companyId: '', isPublic: false });
    }
    fetchSubscriptions();
    return () => {
      setTaskModalOpen(false);
      setExportModalOpen(false);
      setExtractModalOpen(false);
      setDeleteModalOpen(false);
    };
  }, [companyId, fetchSpecs, fetchSubscriptions, specs]);

  // Handle doc extraction (make predictions)...

  useEffect(() => {
    if (prevProps) {
      if (prevProps.docsExtracted.length !== docsExtracted.length) {
        if (docsExtracted.find(o => o === docId)) {
          setFocusPrediction(null);
          setExtracting(false);
          fetchDoc(docId);
          getDocsPredictions([docId]).then(res => setExtractDocs(res.data));
        }
      }
      if (
        prevProps.docsRequested !== docsRequested &&
        !!docsRequested.find(o => o === docId)
      ) {
        setExtracting(true);
      }
    }
  }, [
    docId,
    docsExtracted,
    docsRequested,
    fetchDoc,
    getDocsPredictions,
    prevProps,
  ]);

  const closeTaskModal = () => {
    setTaskModalOpen(false);
  };

  const openTaskModal = () => {
    setTaskModalOpen(true);
  };

  const closeExportModal = () => {
    setExportModalOpen(false);
  };

  const openExportModal = () => {
    setExportModalOpen(true);
  };

  const openExtractModal = () => {
    setExtractModalOpen(true);
  };

  const closeExtractModal = () => {
    setExtractModalOpen(false);
  };

  const openSplitModal = () => {
    setSplitModalOpen(true);
  };

  const closeSplitModal = () => {
    setSplitModalOpen(false);
  };

  const openDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
    history.push('/documents');
  };

  const cancelDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  // params: { selIndex: int, targetPanel: reactRef, hideAll: bool }
  const togglePanel = (selIndex, targetPanel, hideAll = null) => {
    // array of table panels elems
    const elemArr = targetPanel.current;

    // hide all or no selected table index, remove 'show'
    if (hideAll || selIndex === null) {
      elemArr.forEach(elem => {
        elem.classList.remove('show');
      });
    }

    // this gets executed in elemArr.forEach
    const showActivePanel = itemClass => {
      const selectedTableId = targetPanel.current[selIndex].id;
      if (itemClass.contains('show') && selectedTableId !== selectedTable) {
        setSelectedTable(null);
        itemClass.remove('show');
      } else {
        setSelectedTable(selectedTableId);
        itemClass.add('show');
      }
    };

    elemArr.forEach((item, i) => {
      const itemClass = item ? item.classList : null;
      if (i === selIndex) {
        showActivePanel(itemClass);
      } else if (itemClass) {
        itemClass.remove('show');
      }
    });
  };

  const predictionClicked = (p, isCell = null) => {
    const prediction = p || {};
    setFocusPrediction(p);
    if (!isCell) {
      setFocusLineItemTbl(null);
      setFocusGenericTbl(null);
      setFocusAnnotationTbl(null);
      togglePanel(prediction.key || null, refPanelLineItems, true);
      togglePanel(null, refPanelGenericTables, true);
    }
  };

  const genericFieldTokenClicked = p => {
    setFocusPrediction(p);
    setFocusLineItemTbl(null);
    setFocusGenericTbl(null);
    togglePanel(null, refPanelLineItems, true);
    togglePanel(null, refPanelGenericTables, true);
  };

  const genericTblClicked = (tbl, index) => {
    setFocusGenericTbl(tbl);
    setFocusLineItemTbl(null);
    setFocusPrediction(null);
    setFocusAnnotationTbl(null);
    togglePanel(null, refPanelLineItems, true);
    togglePanel(index, refPanelGenericTables);
  };

  // Auto-scroll feature in canvas view (lineitems)
  const openTable = tbl => {
    const { pageId, tableBounds } = tbl;
    let scrollTo = 0;
    console.log(tbl);
    for (let i = 1; i < pageId; i += 1) {
      scrollTo +=
        document.getElementById(`pageContainer-${i}`).offsetHeight + 40;
    }
    if (tableBounds) {
      scrollTo +=
        tableBounds.tl.y *
          document.getElementById(`pageContainer-${pageId}`).offsetHeight -
        30;
    }

    document
      .getElementById('scrollContent')
      .scrollTo({ top: scrollTo, behavior: 'smooth' });
  };

  const annotationTblClicked = tbl => {
    setFocusPrediction(null);
    setFocusLineItemTbl(null);
    setFocusGenericTbl(null);
    setFocusAnnotationTbl(tbl);
    togglePanel(null, refPanelLineItems, true);
    togglePanel(null, refPanelGenericTables, true);
  };

  const genericTblTokenClicked = (tbl, p) => {
    // note: comments are intentional
    // setFocusGenericTbl(tbl); // need tbl value
    setFocusLineItemTbl(null);
    setFocusPrediction(p);
    setFocusAnnotationTbl(null);
    togglePanel(null, refPanelLineItems, true);
    // togglePanel({ index }, refPanelGenericTables); // need index
  };

  // works as a switch method for each token click - routes the token click to
  // the appropriate handler.
  // params: {e: Event, p: Prediction}
  const handleHighlightClick = (e, p) => {
    if (p.type === 'generic.field') {
      // using a ref to access and 'click' the accordion field header
      // and a state to track where it should be open or not
      if (!isFieldsAccordionOpen && refSidebarGenericFields.current) {
        setFieldsAccordionOpen(true);
        refSidebarGenericFields.current.click();
      }
      return genericFieldTokenClicked(p);
    }
    // workaround: accordion is closed and cannot be controlled, this
    // allows us to hijack it - todo: modify accordion
    setFieldsAccordionOpen(false);
    if (isFieldsAccordionOpen && refSidebarGenericFields.current) {
      refSidebarGenericFields.current.click();
    }

    // generic table token click handler
    if (p.type === 'table.value' || p.type === 'table.label') {
      if (!isTablesAccordionOpen && refSidebarGenericTables.current) {
        setTablesAccordionOpen(true);
        refSidebarGenericTables.current.click();
      }
      return genericTblTokenClicked(null, p);
    }
    setTablesAccordionOpen(false);
    if (isTablesAccordionOpen && refSidebarGenericTables.current) {
      refSidebarGenericTables.current.click();
    }

    // default field
    return predictionClicked(p);
  };

  useEffect(() => {
    if (doc && doc.productFieldMapping) {
      const genericItemFilteredObj = [];

      Object.values(doc.productFieldMapping).forEach(fieldset => {
        const genericItemFields = fieldset.filter(
          field => field.fieldset === 'sypht.generic',
        );
        genericItemFilteredObj.push(...genericItemFields);
      });
      if (genericItemFilteredObj.length === 0) {
        setGenericData(null);
      } else {
        genericItemFilteredObj.forEach(item => {
          setGenericData(item.value || []);
        });
        setGenericEntities(
          Object.values(genericItemFilteredObj[0].value.entities),
        );
      }
      setGenericObj(genericItemFilteredObj);
    }
  }, [doc, setGenericData]);

  useEffect(() => {
    refPanelGenericTables.current = refPanelGenericTables.current.slice(
      0,
      genericTables.length,
    );
  }, [genericTables]);

  const highlights = getHighlights({
    genericObj,
    genericEntities,
    doc,
    handleHighlightClick,
  });

  // eslint-disable-next-line no-nested-ternary
  return doc && doc.response && doc.response.status === 404 ? (
    <Page404 />
  ) : !doc || !selected[docId].companyId || doc.docId !== docId ? (
    <div className="loading">
      <ReactLoading type="spin" color="#dddddd" height={128} width={128} />
    </div>
  ) : (
    <DocumentToolbarContainer>
      <div className="document-page-container">
        <DocumentToolbar
          doc={selected[docId]}
          openTaskModal={openTaskModal}
          openDeleteModal={openDeleteModal}
          openExportModal={openExportModal}
          openExtractModal={openExtractModal}
          openSplitModal={openSplitModal}
          hasLineItems={lineItemsObj.length > 0}
          extracting={extracting}
        />
        <div className="content">
          <TableContextProvider>
            <Sidebar
              focusPrediction={focusPrediction}
              predictionClicked={predictionClicked}
              genericTblClicked={genericTblClicked}
              annotationTblClicked={annotationTblClicked}
              focusGenericTbl={focusGenericTbl}
              refSidebarGenericTables={refSidebarGenericTables}
              refSidebarGenericFields={refSidebarGenericFields}
              focusAnnotationTbl={focusAnnotationTbl}
              openTable={openTable}
            />
            <div className="document-preview">
              {doc && (
                <DocumentView
                  doc={doc}
                  highlights={highlights}
                  focusPrediction={focusPrediction}
                  openExtractModal={openExtractModal}
                  extracting={extracting}
                  isShowTagList
                  processingLimit={processingLimit}
                />
              )}
              {genericTables &&
                genericTables.map((table, idx) => {
                  return (
                    <GenericTable
                      id={`generic-table-${idx}`}
                      // eslint-disable-next-line react/no-array-index-key
                      key={`generic-table-${idx}`}
                      focusPrediction={focusPrediction}
                      refPanelGenericTables={refPanelGenericTables}
                      idx={idx}
                      table={table}
                      predictionClicked={predictionClicked}
                    />
                  );
                })}

              <div
                className={classNames('tableview-container', {
                  show: !!focusAnnotationTbl,
                })}
              >
                <div className="tableview-scroll">
                  <div className="data-container">
                    <TableData
                      id={focusAnnotationTbl?.key}
                      table={focusAnnotationTbl?.table}
                    />
                  </div>
                </div>
              </div>
            </div>
          </TableContextProvider>
        </div>
        <>
          {isTaskModalOpen && (
            <AddTaskModal
              onCloseModal={closeTaskModal}
              onCancelModal={closeTaskModal}
              specs={specs}
              isOpen={isTaskModalOpen}
              selectedDocs={selected}
              addTask={addTask}
              companyId={selected[docId].companyId}
            />
          )}
          {isExportModalOpen && (
            <ExportModal
              docs={selected}
              isOpen={isExportModalOpen}
              onCancelModal={closeExportModal}
              onCloseModal={closeExportModal}
            />
          )}
          {isSplitModalOpen && (
            <modal.Modal isOpen>
              <SplitModal
                numDocs={1}
                onCancel={closeSplitModal}
                disableManualSplit={!hasValidateProduct}
                onContinue={(_, splitType) => {
                  autoSplitOrManualSplit(
                    splitType,
                    selected,
                    selected[docId].companyId,
                  ).then(() => {
                    closeSplitModal();
                  });
                }}
              />
            </modal.Modal>
          )}
          {isExtractModalOpen && (
            <ExtractModal
              onCloseModal={closeExtractModal}
              onCancelModal={closeExtractModal}
              isOpen={isExtractModalOpen}
              selectedDocs={extractDocs}
              fieldsetOptions={fieldsetOptions}
              extract={extract}
              companyId={selected[docId].companyId}
            />
          )}
          {isDeleteModalOpen && (
            <DeleteDocsModal
              onCloseModal={closeDeleteModal}
              onCancelModal={cancelDeleteModal}
              deleteDocs={deleteDocs}
              isOpen={isDeleteModalOpen}
              selectedDocs={selected}
              companyId={selected[docId].companyId}
            />
          )}
        </>
        <ReactTooltip
          id="document-tooltip"
          place="bottom"
          variant="dark"
          className="ReactTooltip"
        />
      </div>
    </DocumentToolbarContainer>
  );
};

Document.defaultProps = {
  specs: [],
  addTask: () => {},
  doc: {},
  products: [],
  docsExtracted: null,
  companyId: null,
  genericTables: [],
  processingLimit: null,
};

Document.propTypes = {
  fetchDoc: PropTypes.func.isRequired,
  fetchSubscriptions: PropTypes.func.isRequired,
  autoSplitOrManualSplit: PropTypes.func.isRequired,
  addTask: PropTypes.func,
  doc: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  specs: PropTypes.arrayOf(PropTypes.shape({})),
  fetchSpecs: PropTypes.func.isRequired,
  fetchProducts: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({ docId: PropTypes.string }).isRequired,
  }).isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      productId: PropTypes.string,
      endDate: PropTypes.string,
      fieldsets: PropTypes.arrayOf(PropTypes.shape({})),
      tags: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  getDocsPredictions: PropTypes.func.isRequired,
  extract: PropTypes.func.isRequired,
  docsExtracted: PropTypes.arrayOf(PropTypes.shape({})),
  docsRequested: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteDocs: PropTypes.func.isRequired,
  companyId: PropTypes.string,
  setGenericData: PropTypes.func.isRequired,
  genericTables: PropTypes.arrayOf(PropTypes.shape({})),
  hasValidateProduct: PropTypes.bool.isRequired,
  processingLimit: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(Document);
