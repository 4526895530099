/* eslint-disable no-param-reassign */

export const toggleAccordionPanel = (outerElem, innerElem, isOpen) => {
  if (!outerElem || !innerElem) return;

  if (isOpen) {
    outerElem.style.height = `${innerElem.offsetHeight}px`;
    outerElem.style.opacity = '1';
  } else {
    outerElem.style.opacity = '0';
    outerElem.style.height = '0';
  }
};
