import * as React from 'react';
import { DocumentSplitParent } from '../../atoms/Icons/index';

function DocumentListIconSplitParent() {
  return (
    <span
      data-tooltip-id="documents-tooltip"
      data-tooltip-content="Source Document"
      data-tooltip-place="top"
    >
      <DocumentSplitParent />
    </span>
  );
}

export default DocumentListIconSplitParent;
