import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const TaskStateCell = ({ row }) => {
  const state = row?.original?.state || 'not_found';
  return (
    <span
      className={classNames({
        'color-green': state === 'complete',
      })}
    >
      {{
        pending: 'Created',
        in_progress: 'In progress',
        complete: 'Completed',
      }[state] || ''}
    </span>
  );
};

TaskStateCell.propTypes = {
  row: PropTypes.object.isRequired,
};

export default TaskStateCell;
