import React from 'react';

const CaptureUploadSpinner = () => {
  return (
    <svg>
      <path
        strokeWidth="2"
        strokeLinecap="round"
        d="M 10 18 A 8 8 0 0 0 10 2"
        strokeOpacity="1"
      />
    </svg>
  );
};

CaptureUploadSpinner.propTypes = {};

export default CaptureUploadSpinner;
