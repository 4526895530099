import React, { Fragment } from 'react';
import { FaTimesCircle } from 'react-icons/fa';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const DocsOptions = ({
  filters,
  numDocs,
  batchSelect,
  maxDocs,
  isHidden,
  onChange,
  selectedDocuments,
  onClear,
}) => {
  const { isSelectAllResults, isSelectAll } = batchSelect;

  return (
    <div
      className={classNames('docs-options', {
        'docs-options--show': !isHidden && isSelectAll,
      })}
    >
      <span className="docs-options__text">
        {!isSelectAllResults && isSelectAll ? (
          <>
            {}
            All <strong>{Object.entries(selectedDocuments).length}</strong>{' '}
            documents on this page are selected.
          </>
        ) : (
          <>
            {numDocs && numDocs < maxDocs ? (
              <>
                All <strong>{numDocs}</strong> documents are selected.
              </>
            ) : (
              <>
                The first <strong>{maxDocs}</strong> documents are selected.
              </>
            )}
          </>
        )}
      </span>
      <span
        className={classNames('docs-options__text-button')}
        role="button"
        tabIndex="0"
        onClick={isSelectAllResults ? onClear : onChange}
      >
        {!isSelectAllResults ? (
          <>
            {numDocs && numDocs < maxDocs ? (
              <>
                Select all <strong>{numDocs}</strong> documents.
              </>
            ) : (
              <>
                Select the first <strong>{maxDocs}</strong> documents.
              </>
            )}
          </>
        ) : (
          <>Clear selection</>
        )}
      </span>
    </div>
  );
};

DocsOptions.propTypes = {
  numDocs: PropTypes.number,
  batchSelect: PropTypes.object.isRequired,
  maxDocs: PropTypes.number,
  isHidden: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

DocsOptions.defaultProps = {
  numDocs: 0,
  maxDocs: 1000,
  isHidden: false,
};

export default DocsOptions;
