/* eslint-disable no-shadow */
/* eslint-disable one-var */
import React, { useEffect, useState, useRef, useContext } from 'react';
import { fabric } from 'fabric';
import { TableContext } from './TableContext';
import { DocumentToolbarContext } from '../UI/DocumentToolbar/DocumentToolbarContext';
import CanvasTableUI from './CanvasUI/canvasTableUI';

const DocumentCanvas = ({ pageId, fetched, containerRef }) => {
  const canvasRef = useRef();

  const [tableContext, setTableContext] = useContext(TableContext);
  const [toolbarContext] = useContext(DocumentToolbarContext);
  const [baseCanvas, setBaseCanvas] = useState(null);
  const [offset, setOffset] = useState();

  const { activeTable, existingTables, readOnly } = tableContext;
  const { rotation, collapsed } = toolbarContext;

  const [tableUI, setTableUI] = useState(null);

  // When canvas is being updated trigger this function to update the context
  const onCanvasUpdate = ({
    tableBorder,
    rows,
    cols,
    header,
    lockTable,
    disabledRows,
  }) => {
    rows = rows.map(o => pxToPercent(o)).sort((a, b) => a.tl.y - b.tl.y);

    cols = cols.map(o => pxToPercent(o)).sort((a, b) => a.tl.x - b.tl.x);

    setTableContext(context => ({
      ...context,
      tableBounds: tableBorder ? pxToPercent(tableBorder) : null,
      rows,
      columns: cols,
      header,
      pageId,
      lockTable,
      disabledRows,
    }));
  };

  // Clears rect and lines from canvas
  const clearCanvas = () => {
    baseCanvas.remove(...baseCanvas.getObjects());
  };

  const pxToPercent = ({ tl, br }) => {
    const image = document.getElementById(`imageElement-${pageId}`);
    const imageRect = image.getBoundingClientRect();

    return {
      tl: {
        x: tl.x / imageRect.width,
        y: tl.y / imageRect.height,
      },
      br: {
        x: br.x / imageRect.width,
        y: br.y / imageRect.height,
      },
    };
  };

  const percentToPx = ({ tl, br }) => {
    const image = document.getElementById(`imageElement-${pageId}`);
    const imageRect = image.getBoundingClientRect();

    return {
      tl: {
        x: parseInt(tl.x * imageRect.width),
        y: parseInt(tl.y * imageRect.height),
      },
      br: {
        x: parseInt(br.x * imageRect.width),
        y: parseInt(br.y * imageRect.height),
      },
    };
  };
  const offsetPoint = ({ x, y }, o) => ({ x: x + o.left, y: y + o.top });
  const offsetRect = ({ tl, br }, o) => ({
    tl: offsetPoint(tl, o),
    br: offsetPoint(br, o),
  });

  /**
   * Creates handlers for click and mouse hover
   * on edges of the rectangle
   */

  // On Mount
  useEffect(() => {
    // init canvas
    if (fetched) {
      const image = document.getElementById(`imageElement-${pageId}`);
      const imageRect = image.getBoundingClientRect();
      const containerRect = containerRef.current.getBoundingClientRect();

      const pageContainer = document.getElementById(`pageContainer-${pageId}`);
      const pageContainerRect = pageContainer.getBoundingClientRect();

      const offsetTop = imageRect.y - containerRect.y;
      const offsetLeft = imageRect.x - containerRect.x;
      setOffset({
        top: offsetTop,
        left: offsetLeft,
      });

      const marginTop = pageContainerRect.y - containerRect.y;
      const marginLeft = pageContainerRect.x - containerRect.x;
      canvasRef.current.parentElement.style.top = `-${marginTop}px`;
      canvasRef.current.parentElement.style.left = `-${marginLeft}px`;

      const canvas = new fabric.Canvas(canvasRef.current, {
        selectionColor: 'rgba(0, 0, 0, 0.0)',
        selectionBorderColor: 'rgba(0, 0, 0, 0.0)',
        selection: false,
        hoverCursor: 'default',
        moveCursor: 'default',
      });
      setBaseCanvas(canvas);

      canvas.setDimensions({
        width: Math.ceil(containerRect.width),
        height: Math.ceil(containerRect.height),
      });
    }
  }, [fetched]);

  useEffect(() => {
    if (activeTable !== null && activeTable !== undefined) {
      clearCanvas();
      let activeTableOnPage = null;

      if (
        activeTable.tableBounds &&
        parseInt(activeTable.pageId) === parseInt(pageId)
      ) {
        activeTableOnPage = {
          tableBounds: percentToPx(activeTable.tableBounds),
          rows: activeTable.rows.map(r => percentToPx(r)),
          columns: activeTable.columns.map(c => percentToPx(c)),
          header: activeTable.header,
          lockTable: !!activeTable.lockTable,
          disabledRows: activeTable.disabledRows || [],
        };
      }

      const existingTablesOnPage = existingTables
        .filter(t => parseInt(t.pageId) === parseInt(pageId))
        .map(t => ({
          tableBounds: percentToPx(t.tableBounds),
          index: t.index,
        }));

      const imageRect = document
        .getElementById(`imageElement-${pageId}`)
        .getBoundingClientRect();

      const canvasTableUI = new CanvasTableUI({
        canvas: baseCanvas,
        offset,
        imageRect,
      });
      // let readOnly = !!activeTable.tableBounds;
      canvasTableUI
        .initialise(
          activeTableOnPage,
          existingTablesOnPage,
          onCanvasUpdate,
          readOnly,
        )
        .then(() => {});
      setTableUI(canvasTableUI);
    }
  }, [activeTable]);

  const handleResize = () => {
    const containerRect = containerRef.current.getBoundingClientRect();
    const image = document.getElementById(`imageElement-${pageId}`);
    const imageRect = image.getBoundingClientRect();

    const offsetTop = imageRect.y - containerRect.y;
    const offsetLeft = imageRect.x - containerRect.x;
    setOffset({
      top: offsetTop,
      left: offsetLeft,
    });
    if(baseCanvas !== null){
      baseCanvas.setDimensions({
        width: Math.ceil(containerRect.width),
        height: Math.ceil(containerRect.height),
      });
    }
  
    if (tableUI !== null) {
      tableUI.onResize(offset, imageRect);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  useEffect(() => {
    if (baseCanvas !== null) {
      handleResize();
    }
  }, [toolbarContext]);

  useEffect(() => {
    const { lockTable, readOnly } = tableContext;
    if (
      !!baseCanvas &&
      parseInt(tableContext.pageId) === parseInt(pageId) &&
      !readOnly
    ) {
      tableUI.lockTable(lockTable);
      baseCanvas.requestRenderAll();
    }
  }, [tableContext]);

  // Render canvas
  return (
    <canvas id={`canvas-${pageId}`} ref={canvasRef} style={{ width: '100%' }} />
  );
};

export default DocumentCanvas;
