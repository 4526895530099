import React, { useState, useEffect, Fragment } from 'react';
import {
  FaInfinity,
  FaExclamationCircle,
  FaCheckCircle,
  FaRegComments,
  FaRegImage,
} from 'react-icons/fa';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import moment from 'moment';
import PropTypes, { string } from 'prop-types';

import {
  subscribePaid,
  upgradeTrial,
  savePaymentMethod,
  clearPaymentResult,
} from '../../../../reducers/subscriptions/actions';

import { Button } from '../../../../library/atoms/Buttons';
import CheckoutModal from '../CheckoutModal/CheckoutModal';
import './SubscriptionList.scss';
import * as Events from '../../tracking/productTracking';
import { trackEvent } from '../../../../reducers/tracking/actions';

const STATUS = {
  EXPIRING: 'expiring',
  EXPIRED: 'expired',
  RENEWING: 'renewing',
};

const mapStateToProps = state => {
  const { auth } = state;
  return {
    user: auth.user ? auth.user.data : null,
    company: auth.user ? auth.user.data.company : null,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      subscribePaid: (productId, paymentMethodId) =>
        dispatch(subscribePaid(productId, paymentMethodId)),
      savePaymentMethod: paymentMethod =>
        dispatch(savePaymentMethod(paymentMethod)),
      upgradeTrial: (productId, subscriptionId, paymentMethodId) =>
        dispatch(upgradeTrial(productId, subscriptionId, paymentMethodId)),
      clearPaymentResult: () => dispatch(clearPaymentResult()),
      trackEvent: ({ name, params }) => dispatch(trackEvent({ name, params })),
    },
  };
};

const renderLicenseInfo = subscription => {
  const { status } = subscription;
  if (!status) {
    return null;
  }
  switch (status.type) {
    case 'LIMITED': {
      return (
        <>
          <span>
            <strong>Total Seats:</strong> {status.totalSeats}
          </span>
          <span>
            <strong>Seats Available:</strong>{' '}
            {Math.max(status.totalSeats - status.usedSeats, 0)}{' '}
          </span>
          {!status.seatsAvailable && (
            <span className="subscription-card__contact-us">
              Please{' '}
              <a
                target="_blank"
                href="https://www.sypht.com/contact"
                rel="noreferrer"
              >
                contact us
              </a>{' '}
              to purchase more seats
            </span>
          )}
        </>
      );
    }
    default: {
      return null;
    }
  }
};

const Subscription = ({ product, subscription, dispatch, user }) => {
  const { productId, endDate, startDate, name, tileImageUrl } = subscription;
  const isUserVerified = !(user && user.auth0 && !user.auth0.email_verified);
  const [status, setStatus] = useState(null);
  const [isCheckoutModalOpen, setCheckoutModalOpen] = useState(false);

  // Get status of subscription
  useEffect(() => {
    let newStatus = null;
    const startFormatted = moment(startDate).format('D/MM/YYYY');
    const endFormatted = endDate ? moment(endDate).format('D/MM/YYYY') : null;
    const daysLeft = Math.ceil(
      moment(endDate).diff(moment(), 'seconds') / 86400,
    );

    if (!endDate) {
      newStatus = {
        message: (
          <p className="subscription-card__details-status">
            <span>
              <strong>Subscription date:</strong> {startFormatted}
            </span>
            {renderLicenseInfo(subscription)}
          </p>
        ),
        type: STATUS.RENEWING,
      };
    } else if (daysLeft > 0) {
      newStatus = {
        message: (
          <>
            <p className="subscription-card__details-status">
              <span>
                <strong>Subscription date:</strong> {startFormatted}
              </span>
              <span>
                <strong>Days left:</strong> {daysLeft} days
              </span>
              <span>
                <strong>Expiry date:</strong> {endFormatted}
              </span>
              {renderLicenseInfo(subscription)}
            </p>
          </>
        ),
        type: STATUS.EXPIRING,
      };
    }

    setStatus(newStatus);
  }, [endDate, startDate, subscription]);

  const talkSalesHandler = () => {
    dispatch.trackEvent(Events.talkToSalesClick(product));
  };

  const upgradeTrialHandler = paymentMethodId => {
    dispatch.upgradeTrial(productId, subscription.id, paymentMethodId);
    dispatch.trackEvent(Events.confirmCheckoutAiProduct(product));
  };

  const openCheckoutModal = () => {
    setCheckoutModalOpen(true);
    dispatch.trackEvent(Events.addAiProductToCart(product)).then(() => {
      dispatch.trackEvent(Events.beginCheckoutAiProduct(product));
    });
  };

  const closeCheckoutModal = () => {
    dispatch.clearPaymentResult();
    dispatch.trackEvent(Events.cancelCheckoutAiProduct(product));
    setCheckoutModalOpen(false);
  };

  const trialButton = () => {
    if (!subscription.isTrial) {
      return null;
    }

    // Trial infinite
    if (!endDate) {
      return (
        <span className="subs-text subs-text--green">
          <FaInfinity />
          Unlimited Free Trial
        </span>
      );
    }
    // Trial expired
    if (moment(endDate) < moment()) {
      return (
        <span className="subs-text subs-text--red">
          <FaExclamationCircle />
          Trial subscription expired
        </span>
      );
    }

    // Trial active
    const numberOfDays = Math.ceil(
      moment(endDate).diff(moment(), 'seconds') / 86400,
    );
    // 3 - 14 days
    if (numberOfDays >= 3) {
      return (
        <span className="subs-text subs-text--green">
          <FaCheckCircle />
          Free Trial Activated. {numberOfDays} days remaining
        </span>
      );
    }

    // less 3 days left
    return (
      <span className="subs-text subs-text--yellow">
        <FaExclamationCircle />
        Free Trial Activated. {numberOfDays} days remaining
      </span>
    );
  };

  const checkoutButton = () => {
    if (subscription.isTrial) {
      const numberOfDays = Math.ceil(
        moment(endDate).diff(moment(), 'seconds') / 86400,
      );

      // show talk to sales link if < 3 days
      if (numberOfDays < 3) {
        return (
          <>
            <span data-tooltip-id="verify-tooltip" data-tooltip-place="bottom">
              <Button
                type="button"
                onClick={openCheckoutModal}
                disabled={!isUserVerified}
              >
                Unlock Full Subscription
              </Button>
            </span>
            <a
              className="subs-call"
              href="https://www.sypht.com/contact"
              target="_blank"
              onClick={talkSalesHandler}
              rel="noreferrer"
            >
              <FaRegComments />
              Talk to sales
            </a>
          </>
        );
      }

      return null;
    }

    return (
      <span className="subs-text subs-text--green">
        <FaCheckCircle />
        Full Subscription Unlocked
      </span>
    );
  };

  const cardClickHandler = () => {
    dispatch.trackEvent(Events.selectProduct(product));
  };

  return (
    <div className="subscription-wrapper">
      <div className={`subscription-card ${status ? status.type : null}`}>
        <Link
          className="subscription-card__link-container"
          to={`/marketplace/products/${productId}`}
          onClick={() => cardClickHandler()}
        >
          <div className="subscription-card__image-container">
            <figure className="subscription-card__thumbnail">
              {tileImageUrl && <img src={tileImageUrl} alt={name} />}
              {!tileImageUrl && <FaRegImage />}
            </figure>
          </div>

          <div className="subscription-card__details-container">
            {status ? (
              <>
                {/* TODO: To be added once workbench is available */}
                {/* <h3 className="subscription-card__details-subtitle">Sypht</h3> */}
                <h1 className="subscription-card__details-title">{name}</h1>
                {status.message}
              </>
            ) : null}
          </div>
        </Link>
        {status ? (
          <div className="cta-container">
            {trialButton()}
            {checkoutButton()}
          </div>
        ) : null}
      </div>
      {product && (
        <CheckoutModal
          product={product}
          subscription={subscription}
          isOpen={isCheckoutModalOpen}
          onCancel={closeCheckoutModal}
          onClose={closeCheckoutModal}
          onUpgradeTrial={upgradeTrialHandler}
        />
      )}
    </div>
  );
};

Subscription.propTypes = {
  product: PropTypes.shape({}),
  subscription: PropTypes.shape({
    id: PropTypes.string,
    productId: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    name: PropTypes.string,
    tileImageUrl: PropTypes.string,
    isTrial: PropTypes.bool,
    status: PropTypes.oneOfType([
      PropTypes.shape({
        type: 'LIMITED',
        usedSeats: PropTypes.number,
        totalSeats: PropTypes.number,
      }),
      PropTypes.shape({
        type: string,
      }),
    ]),
  }).isRequired,
  company: PropTypes.shape({}).isRequired,
  dispatch: PropTypes.shape({
    upgradeTrial: PropTypes.func,
    trackEvent: PropTypes.func,
    clearPaymentResult: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({
    auth0: PropTypes.shape({
      email_verified: PropTypes.bool,
    }),
  }),
};

Subscription.defaultProps = {
  product: null,
  user: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);
