import { useEffect, useState } from 'react';
import { fetchCompanyRoles } from '../../reducers/company/actions';
import { fetchSubscriptions } from '../../reducers/subscriptions/actions';
import {
  CORE_PRODUCT_ID,
  VALIDATE_PRODUCT_ID,
  VALIDATE_ROLENAME,
} from '../../common/constants';

/**
 * Ensure dropdowns are displayed in the correct order.
 *
 * productId => num
 */
const roleDisplayOrder = new Map([
  [CORE_PRODUCT_ID, 0],
  [VALIDATE_PRODUCT_ID, 1],
]);
const DEFAULT_ORDER = 100;

const sortRoleDataEntries = roleDataEntries => {
  return roleDataEntries.sort(
    (a, b) =>
      (roleDisplayOrder.get(a.productId) ?? DEFAULT_ORDER) -
      (roleDisplayOrder.get(b.productId) ?? DEFAULT_ORDER),
  );
};

/**
 * Format userRoles state to the format app-service expects when submitting.
 */
const formatUserRoles = roleData => {
  if (!roleData.data) {
    return undefined;
  }
  return roleData.data.reduce((acc, { currentRole, productId }) => {
    acc[productId] = currentRole.id;
    return acc;
  }, {});
};

/**
 * Calculate user roles based on the available company roles for use in
 * dropdowns.
 */
const initialiseRoleData = (rolesByProduct, initialUserRoles = []) => {
  const entries = Object.entries(rolesByProduct || {});
  if (entries.length === 0) {
    return undefined;
  }
  return sortRoleDataEntries(
    entries.map(([productId, roles]) => {
      const leastPrivilegedRole = roles[0] || null;
      const userRole = initialUserRoles.find(u => u.productId === productId);
      const currentRole = userRole || leastPrivilegedRole;
      return { productId, currentRole, roles };
    }),
  );
};

/**
 * Decide whether to show role in dropdown.
 *
 * For HITL: This is to give an indicator to the user about what roles are
 * available based on seats available and will reduce authorization errors in
 * the backend as a result.
 *
 * @param {*} role one of the roles for a product (which we may suppress here)
 * @param {*} productId
 * @param {*} initialRole the initial role of existing user if they have one, blank otherwise
 * @param {*} selectedRole the currently selected role of the user in the dropdown
 * @param {*} subscription the subscription for the product that defines these roles (eg HITL/Validate product)
 * @returns
 */
function canDisplayRole(
  role,
  productId,
  initialRole,
  selectedRole,
  subscription,
) {
  if (productId === VALIDATE_PRODUCT_ID) {
    const isNoRole = role.roleName === VALIDATE_ROLENAME.NO_ROLE;
    const isCurrentRole = role.id === selectedRole?.id;
    const isInitialRole = role.id === initialRole?.id;
    const showRestrictedRoles = isNoRole || isCurrentRole || isInitialRole;
    if (!subscription) {
      // Show NO_ROLE and user's current role whilst we're still getting license info...
      return showRestrictedRoles;
    }
    const { status } = subscription;
    if (!status) {
      return showRestrictedRoles;
    }
    const seatsNotAvailable =
      status.type === 'UNLICENSED' ||
      (status.type === 'LIMITED' && !status.seatsAvailable);
    if (seatsNotAvailable) {
      const userHasSeat =
        initialRole && initialRole?.roleName !== VALIDATE_ROLENAME.NO_ROLE;
      if (status.totalSeats === status.usedSeats) {
        // Allow an existing seated user to switch to any other role.
        return userHasSeat ? true : showRestrictedRoles;
      }
      // Show the existing seated user's role to avoid confusion, but otherwise
      // restrict to NO_ROLE.
      return showRestrictedRoles;
    }
    return true;
  }
  return true;
}

/**
 * Filter roles to reduce choices that may cause validation errors on the
 * backend.
 *
 * @param {*} selectedRole The currently selected role in the dropdown
 * @param {*} productId
 * @param {*} roles The roles for the product
 * @param {*} initialUserRoles The initial roles of existing user if they have one, blank otherwise
 * @param {*} subscriptions
 * @returns
 */
function filterRoles(
  selectedRole,
  productId,
  roles,
  initialUserRoles,
  subscriptions = null,
) {
  const initialRole = initialUserRoles?.find(
    initial => initial.productId === productId,
  );
  const subscription = subscriptions?.find(sub => sub.productId === productId);
  return roles.filter(role => {
    return canDisplayRole(
      role,
      productId,
      initialRole,
      selectedRole,
      subscription,
    );
  });
}

function filterRoleEntries(
  roleEntries,
  initialUserRoles,
  subscriptions = null,
) {
  return roleEntries.map(roleEntry => {
    const { currentRole, productId, roles } = roleEntry;
    return {
      ...roleEntry,
      roles: filterRoles(
        currentRole,
        productId,
        roles,
        initialUserRoles,
        subscriptions,
      ),
    };
  });
}

/**
 * Detect if a user's roles mean it has a seat for productId.
 *
 * @param {*} productId
 * @param {*} userRoles
 * @returns
 */
function hasSeat(productId, userRoles) {
  const role = userRoles?.find(r => r.productId === productId);
  if (!role) {
    return false;
  }
  if (productId !== VALIDATE_PRODUCT_ID) {
    // Only Validate product has seats/license atm
    return false;
  }
  return role.roleName !== VALIDATE_ROLENAME.NO_ROLE;
}

/**
 * Handle the fetching and updating of user roles.
 *
 * TODO: react-redux@7 will give us useDispatch and useConnect so we can get
 * state from redux directly.
 */
function useUserRoles({
  enable = true,
  dispatch,
  initialUserRoles = undefined,
  companyRoles,
  subscriptions,
  companyId,
}) {
  const [roleData, setRoleData] = useState(() => {
    return companyRoles.error
      ? { error: companyRoles.error }
      : { data: undefined };
  });
  const [seatData, setSeatData] = useState(undefined);

  // Load companyRoles into redux...
  useEffect(() => {
    if (enable && companyId) {
      dispatch(fetchCompanyRoles(companyId));
    }
  }, [companyId, dispatch, enable]);

  // Load subscriptions into redux if not there...
  useEffect(() => {
    if (enable && companyId) {
      dispatch(fetchSubscriptions({ companyId }));
    }
  }, [companyId, dispatch, enable]);

  // Initialise roleData once companyRoles is loaded...
  //
  // We'll initialise a 2nd time once subscriptions comes in with license
  // information.  This will allow us to maybe show more options in the dropdown
  // (for licensed products like HITL).
  useEffect(() => {
    if (companyRoles.data) {
      setRoleData(
        companyRoles.error
          ? { error: companyRoles.error }
          : {
              data: filterRoleEntries(
                initialiseRoleData(companyRoles.data, initialUserRoles),
                initialUserRoles,
                subscriptions.data,
              ),
            },
      );
    }
  }, [
    companyRoles.data,
    companyRoles.error,
    initialUserRoles,
    subscriptions.data,
  ]);

  // Initialise seatData once subscriptions are loaded...
  useEffect(() => {
    if (subscriptions.data && subscriptions.data.length > 0) {
      setSeatData(
        subscriptions.data.reduce((acc, sub) => {
          const showSeats = sub.status?.type === 'LIMITED';
          if (showSeats) {
            const currentUserHasSeat = hasSeat(sub.productId, initialUserRoles);
            const canStillAssignSeat =
              currentUserHasSeat &&
              sub.status.usedSeats <= sub.status.totalSeats;
            acc[sub.productId] = {
              seatsAvailable: sub.status.seatsAvailable,
              usedSeats: sub.status.usedSeats,
              seats: sub.status.totalSeats,
              currentUserHasSeat,
              canStillAssignSeat,
            };
          }
          return acc;
        }, {}),
      );
    }
  }, [initialUserRoles, subscriptions.data]);

  const handleRoleChange = (evt, role, productId) => {
    setRoleData(state => {
      return {
        ...state,
        data: sortRoleDataEntries(
          state.data
            .filter(s => s.productId !== productId)
            .concat({
              currentRole: role,
              productId,
              roles: filterRoles(
                role,
                productId,
                companyRoles.data[productId],
                initialUserRoles,
                subscriptions.data,
              ),
            }),
        ),
      };
    });
  };

  return {
    userRoles: formatUserRoles(roleData),
    roleData,
    seatData,
    handleRoleChange,
  };
}

export default useUserRoles;
