import { validatePassword } from '../validators';

const usePasswordValidator = password => {
  return validatePassword(
    {
      elem: {
        value: password,
      },
    },
    false,
  );
};

export default usePasswordValidator;
