import axios from 'axios';

const apiBaseUrl =
  process.env.WORKFLOW_SERVICE_HOST_NAME || process.env.APP_API_HOST_NAME;

export default async function ingest(accessToken, fileId, region) {
  return axios({
    method: 'post',
    url: `${apiBaseUrl}/workflows/sypht.ingest/invoke`,
    data: {
      inputs: {
        file_id: `${region}:${fileId}`,
        region,
      },
      step_id: 'start-with-region',
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
    },
  });
}

export async function smartSplit(accessToken, fileId, region) {
  return axios({
    method: 'post',
    url: `${apiBaseUrl}/workflows/sypht.split/jobs`,
    data: {
      inputs: {
        file_id: `${region}:${fileId}`,
      },
      step_id: 'start',
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
    },
  });
}

export async function manualSplit(accessToken, fileId, region) {
  return axios({
    method: 'post',
    url: `${apiBaseUrl}/workflows/sypht.manual_split/jobs`,
    data: {
      inputs: {
        file_id: `${region}:${fileId}`,
      },
      step_id: 'start',
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
    },
  });
}
