import React from 'react';
import PropTypes from 'prop-types';
import './GridItem.scss';

const GridItem = ({ col, colStart, colEnd, rowStart, rowEnd, children }) => {
  const styles = {
    gridColumnStart: colStart,
    gridColumnEnd: colEnd,
    gridRowStart: rowStart,
    gridRowEnd: rowEnd,
    gridColumn: col ? `span ${col}` : 'default',
  };

  return (
    <div className="grid-item" style={styles}>
      {children}
    </div>
  );
};

GridItem.propTypes = {
  col: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colStart: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colEnd: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rowStart: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rowEnd: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node,
};

GridItem.defaultProps = {
  col: null,
  colStart: null,
  colEnd: null,
  rowStart: null,
  rowEnd: null,
  children: null,
};

export default GridItem;
