import React from 'react';
import { MdWarning, MdCheck } from 'react-icons/md';
import { FaAngleDoubleUp } from 'react-icons/fa';
import JsonViewer from '../../UI/JsonViewer/JsonViewer';
import { Tab, TabPanel, Tabs, TabsContainer } from '../../UI/Tabs';
import data from './data';
import './_signalsData.scss';

const SignalsData = () => {
  const fields = [
    {
      label: 'Duplicate Invoices',
      icon: <MdWarning className="icon--orange" />,
      value: 10,
      description: 'Duplicates detected',
      field: 'signalsdemo.documentMatch',
    },
    {
      label: 'Invoice Fraud',
      icon: <MdCheck className="icon--green" />,
      value: '15%',
      description: 'Likelihood of fraud',
      field: 'signalsdemo.bankDetailCorrelation',
    },
    {
      label: 'Invoice Spend',
      icon: <FaAngleDoubleUp className="icon--red" />,
      value: '70.1%',
      description: 'Top quartile',
      field: 'signalsdemo.spend',
    },
  ];

  return (
    <TabsContainer className="signals-data" defaultTab="results">
      <div className="signals-data__header">
        <h2 className="signals-data__heading">Extracted signals</h2>
        <Tabs className="signals-data__menu">
          <Tab tabFor="results">Results</Tab>
          <Tab tabFor="json">Json</Tab>
        </Tabs>
      </div>
      <div className="signals-data__body">
        <TabPanel id="results" className="signals-data__panel">
          <ul className="signals-data__fieldset">
            {fields.map((f, i) => (
              <li
                key={`signals-field|${f.field}|${i}`}
                className="signals-field"
              >
                <div className="signals-field__inner">
                  <div className="signals-field__field">
                    <div className="signals-field__field-row">
                      <div className="signals-field__field-label-wrapper">
                        <span className="signals-field__field-label">
                          {f.label}
                        </span>
                        <span className="signals-field__field-sub-label">
                          New!
                        </span>
                      </div>

                      <div className="signals-field__field-value-wrapper">
                        <div className="signals-field__field-value">
                          <div className="signals-field__field-icon">
                            {f.icon}
                          </div>
                          <span> {f.value}</span>
                        </div>
                        <span className="signals-field__field-sub-value">
                          {f.description}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </TabPanel>
        <TabPanel id="json" className="signals-data__panel">
          <JsonViewer data={data} />
        </TabPanel>
      </div>
    </TabsContainer>
  );
};

export default SignalsData;
