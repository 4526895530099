import React, { Component } from 'react';
import classnames from 'classnames';
import './LoadingSpinner.scss';

const LoadingSpinner = ({ text, fullscreen, className }) => {
  return (
    <div
      className={classnames('spinner-overlay', className, {
        fullscreen: fullscreen,
      })}
    >
      <div className="spinner-outer-wrapper">
        <div className="spinner-wrapper">
          <div className="spinner" />
        </div>
        {text && <h2 className="text-center">{text}</h2>}
      </div>
    </div>
  );
};

export default LoadingSpinner;
