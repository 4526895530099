import CredentialService from '../../services/CredentialService';
import { addNotification } from '../notifications';
import {
  createdMessage,
  updatedMessage,
  deletedMessage,
  errorMessage,
} from './messages';

export const REQUEST_CREDENTIALS = 'sypht/credentials/REQUEST_CREDS';
export const RECEIVE_CREDENTIALS = 'sypht/credentials/RECEIVE_CREDS';
export const TRANSMIT_DATA = 'sypht/credentials/TRANSMIT_DATA';
export const RECEIVE_NEW_CREDENTIAL = 'sypht/credentials/RECEIVE_NEW_CRED';
export const RECEIVE_UPDATED_CREDENTIAL =
  'sypht/credentials/RECEIVE_UPDATED_CRED';
export const RECEIVE_DELETED_CREDENTIAL =
  'sypht/credentials/RECEIVE_DELETED_CRED';
export const RECEIVE_SECRET = 'sypht/credentials/RECEIVE_SECRET';
export const RECEIVE_ROTATED_SECRET =
  'sypht/credentials/RECEIVE_ROTATED_SECRET';
export const RECEIVE_DEFAULT_CREDENTIAL =
  'sypht/credentials/RECEIVE_DEFAULT_CREDENTIAL';
export const RECEIVE_BEARER_TOKEN = 'sypht/credentials/RECEIVE_BEARER_TOKEN';

const transmitData = () => {
  return {
    type: TRANSMIT_DATA,
    payload: {
      loading: true,
      sending: true,
      sent: false,
    },
  };
};

const requestCredentials = () => {
  return {
    type: REQUEST_CREDENTIALS,
    payload: {
      loading: true,
    },
  };
};

const receiveCredentials = data => {
  return {
    type: RECEIVE_CREDENTIALS,
    payload: {
      data,
      loading: false,
      receivedAt: Date.now(),
    },
  };
};

const receiveNewCredential = data => {
  return {
    type: RECEIVE_NEW_CREDENTIAL,
    payload: {
      data,
      loading: false,
      sending: false,
      sent: true,
      receivedAt: Date.now(),
    },
  };
};

const receiveUpdatedCredential = (data, clientId) => {
  return {
    type: RECEIVE_UPDATED_CREDENTIAL,
    payload: {
      data: {
        ...data,
        clientId,
      },
      loading: false,
      sending: false,
      sent: true,
      receivedAt: Date.now(),
    },
  };
};

const receiveDeletedCredential = data => {
  return {
    type: RECEIVE_DELETED_CREDENTIAL,
    payload: {
      data,
      loading: false,
      sending: false,
      sent: true,
      receivedAt: Date.now(),
    },
  };
};

const receiveSecret = data => {
  return {
    type: RECEIVE_SECRET,
    payload: {
      data,
    },
  };
};

const receiveRotatedSecret = data => {
  return {
    type: RECEIVE_ROTATED_SECRET,
    payload: {
      data,
    },
  };
};

const receiveDefaultCredential = data => {
  return {
    type: RECEIVE_DEFAULT_CREDENTIAL,
    payload: {
      data,
    },
  };
};

const receiveBearerToken = data => {
  return {
    type: RECEIVE_BEARER_TOKEN,
    payload: {
      data,
    },
  };
};

export const createCredential = (credential, companyId) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken, user } = auth;
    dispatch(transmitData());

    const response = await CredentialService.createCredential(
      accessToken,
      credential,
      companyId || user.data.companyId,
    );

    if (response.data && response.data.clientId) {
      dispatch(receiveNewCredential(response.data));
      dispatch(addNotification(createdMessage(response.data.clientName)));
    } else {
      dispatch(receiveNewCredential(null));
      dispatch(addNotification(errorMessage));
    }
  };
};

export const fetchCredentials = companyId => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    dispatch(requestCredentials());
    const { accessToken, user } = auth;
    const response = await CredentialService.getCredentials(
      accessToken,
      companyId || user.data.companyId,
    );
    dispatch(receiveCredentials(response.data));
  };
};

export const updateCredential = (clientId, credential, companyId) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken, user } = auth;
    dispatch(transmitData());
    const response = await CredentialService.updateCredential(
      accessToken,
      clientId,
      credential,
      companyId || user.data.companyId,
    );

    if (response.data && response.data.clientName) {
      dispatch(receiveUpdatedCredential(response.data, clientId));
      dispatch(addNotification(updatedMessage(response.data.clientName)));
    } else {
      dispatch(receiveUpdatedCredential(null, null));
      dispatch(addNotification(errorMessage));
    }
  };
};

export const deleteCredential = (clientId, companyId) => {
  return async (dispatch, getState) => {
    const { auth, credentials } = getState();
    const { accessToken, user } = auth;
    dispatch(transmitData());
    const [client] = credentials.data
      ? credentials.data.filter(c => c.clientId === clientId)
      : null;

    return await CredentialService.deleteCredential(
      accessToken,
      clientId,
      companyId || user.data.companyId,
    )
      .then(({ data }) => {
        dispatch(receiveDeletedCredential(data));
        dispatch(
          addNotification(deletedMessage(client.clientName || clientId)),
        );
      })
      .catch(error => {
        dispatch(receiveDeletedCredential(null));
        dispatch(addNotification(errorMessage));
      });
  };
};

export const fetchSecret = (clientId, companyId) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken, user } = auth;
    const response = await CredentialService.getSecret(
      accessToken,
      clientId,
      companyId || user.data.companyId,
    );
    dispatch(receiveSecret(response.data));
  };
};

export const setDefaultCredential = credential => {
  return async dispatch => {
    dispatch(receiveDefaultCredential(credential));
  };
};
