// import { v4 as uuid } from 'uuid';

export const BU_DOCUMENT = 'document';
export const BU_PAGE = 'page';
export const DEFAULT_BILLING_UNIT = BU_DOCUMENT;
const INCREMENT_BY = 1000; // pages, docs

export function createTier(params = {}) {
  return {
    upTo: Infinity, // documents or pages
    flatPrice: 0, // $
    unitPrice: 0.01, // $
    ...params,
  };
}

export function createInitial(params = {}) {
  return [createTier(params)];
}

export function startFrom(tiers, index) {
  const prevtier = tiers[index - 1];
  return prevtier ? prevtier.upTo + 1 : 0;
}

export function validate(tiers) {
  if (tiers.length === 0) {
    return { ok: true };
  }
  let result = { ok: true };
  const last = tiers[tiers.length - 1];
  if (last.upTo !== Infinity) {
    result = {
      ...result,
      ok: false,
      index: tiers.length - 1,
      upTo: 'Last tier upTo must be Infinity',
    };
  }
  for (let index = 0; index < tiers.length; index += 1) {
    const tier = tiers[index];
    const prev = tiers[index - 1];
    if (!(Math.round(tier.upTo) === tier.upTo) || tier.upTo < 0) {
      result = {
        ...result,
        ok: false,
        index,
        upTo: 'Threshold must be positive integer',
      };
    }
    if (typeof tier.flatPrice !== 'number') {
      result = {
        ...result,
        ok: false,
        index,
        flatPrice: 'Flat price must be a number',
      };
    }
    if (typeof tier.unitPrice !== 'number') {
      result = {
        ...result,
        ok: false,
        index,
        unitPrice: 'Unit price must be a number',
      };
    }
    if (prev && tier.upTo <= prev.upTo) {
      result = {
        ...result,
        ok: false,
        index,
        upTo: 'Threshold must be greater than previous tier',
      };
    }
  }
  return result;
}

export function updateTier(tiers, index, tier) {
  return tiers.map((curr, idx) => (idx === index ? tier : curr));
}

export function deleteTier(tiers, index) {
  const result = tiers.filter((curr, idx) => idx !== index);
  const last = result[result.length - 1];
  if (last) {
    result[result.length - 1] = { ...last, upTo: Infinity };
  }
  return result;
}

export function appendTier(tiers) {
  const lastIndex = tiers.length - 1;
  const last = tiers[lastIndex];
  const last2 = tiers[lastIndex - 1];
  const tier = createTier();
  const lastThreshold = last2 ? last2.upTo + INCREMENT_BY : INCREMENT_BY;
  const result = [...tiers];
  result[lastIndex] = { ...last, upTo: lastThreshold };
  result.push(tier);
  return result;
}

/**
 * JSON.stringify will convert Infinity to null.  This does the reverse.
 *
 * @param {*} num
 * @returns
 */
export function nullToInf(tiers) {
  return tiers.map(tier =>
    tier.upTo === null ? { ...tier, upTo: Infinity } : tier,
  );
}
