import React from 'react';
import Ajv from 'ajv';
import classNames from 'classnames';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';

import useRefresh from '../../../common/hooks/useRefresh';
import 'jsoneditor-react/es/editor.min.css';
import './jsonViewer.scss';

const LoadableJsonEditor = loadable.lib(() => import('jsoneditor-react'));
const ajvConfig = new Ajv({ allErrors: true, verbose: true });

const JsonViewer = ({ className, data, ...props }) => {
  const isRefresh = useRefresh([data]);
  return (
    !isRefresh && (
      <div className={classNames('json-viewer', className)}>
        <LoadableJsonEditor>
          {({ JsonEditor }) => (
            <JsonEditor ajv={ajvConfig} value={data} {...props} />
          )}
        </LoadableJsonEditor>
      </div>
    )
  );
};

JsonViewer.propTypes = {
  className: PropTypes.string,
  data: PropTypes.any,
};

JsonViewer.defaultProps = {
  className: null,
  data: null,
};

export default JsonViewer;
