import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { triggerValidation } from '../../../../../../../common/helpers';
import Input from '../../../../../../UI/Input/Input';

const DEFAULT_MIN_MAX = {
  min: undefined,
  max: undefined,
};

const MinMax = ({ defaultValue, valCounter, onChange }) => {
  const [minMax, setMinMax] = useState(defaultValue);
  const [errors, setErrors] = useState(DEFAULT_MIN_MAX);
  const minInputRef = useRef(null);
  const maxInputRef = useRef(null);

  useEffect(() => {
    onChange(minMax);
  }, [minMax]);

  useEffect(() => {
    if (valCounter <= 0) return;
    triggerValidation(minInputRef.current);
    triggerValidation(maxInputRef.current);
  }, [valCounter]);

  const handleChange = (e, prop) => {
    setMinMax({
      ...minMax,
      [prop]: e.target.value,
    });

    // remove errors if has value
    if (e.target.value && e.target.value.length > 0) {
      setErrors({
        ...errors,
        [prop]: null,
      });
    }
  };

  const handleBlur = (e, prop) => {
    if (prop === 'max' && minMax.min > minMax.max) {
      setErrors({
        ...errors,
        max: `Must be > than minimum value`,
      });
      return;
    }

    setErrors({
      ...errors,
      [prop]: e.target.value ? null : 'Field is required.',
    });
  };

  return (
    <>
      <div className="rule-picker__col rule-picker__min-max">
        <Input
          label="from"
          id="rule_min_input"
          defaultValue={minMax.min}
          error={errors.min}
          onBlur={e => handleBlur(e, 'min')}
          onChange={e => handleChange(e, 'min')}
          forwardRef={minInputRef}
        />
        <Input
          label="to"
          id="rule_max_input"
          defaultValue={minMax.max}
          error={errors.max}
          onBlur={e => handleBlur(e, 'max')}
          onChange={e => handleChange(e, 'max')}
          forwardRef={maxInputRef}
        />
      </div>
      <div className="rule-picker__col rule-picker__hint">
        <h3>What is field value out of range?</h3>
        <p>
          This condition checks whether the value of a selected field falls
          within a specified range.
        </p>
        <p>
          <strong>Tip:</strong> Apply this rule to numerical fields (e.g.
          invoice total - to ensure you don't accidentally over-pay) or date
          fields (e.g. invoice due date - to help you manage early or late
          payments).
        </p>
      </div>
    </>
  );
};

MinMax.propTypes = {
  defaultValue: PropTypes.object,
  valCounter: PropTypes.number,
  onChange: PropTypes.func,
};

MinMax.defaultProps = {
  defaultValue: DEFAULT_MIN_MAX,
  valCounter: 0,
  onChange: () => {},
};

export default MinMax;
