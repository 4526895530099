/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import { FaTrashAlt } from 'react-icons/fa';

class DeleteColumn {
  constructor({ onClick, ...props }) {
    this.headerClassName = '-icon';
    this.className = '-delete';
    this.sortable = false;
    this.width = 40;

    this.Header = () => <span className="rt-hide">Delete</span>;
    this.Cell = ({ original: tag, viewIndex }) => {
      return (
        <button
          type="button"
          className="rt-delete-btn"
          onClick={e => onClick(e, tag, viewIndex)}
        >
          <FaTrashAlt />
        </button>
      );
    };

    Object.entries(props).forEach(([key, value]) => {
      this[key] = value;
    });
  }
}

DeleteColumn.propTypes = {
  onClick: PropTypes.func,
};

DeleteColumn.defaultProps = {
  onClick: () => {},
};

export default DeleteColumn;
