/* eslint-disable no-unused-vars */
import ValidateRulesService from '../../../../services/ValidateRulesService';
import ProductService from '../../../../services/ProductService';
import { addNotification } from '../../../../reducers/notifications';
import {
  createdMessage,
  deletedMessage,
  errorMessage,
  updatedMessage,
} from './messages';

export const RECEIVE_RULES = 'sypht/validate/RECEIVE_RULES';
export const RECEIVE_PRODUCT_RULES = 'sypht/validate/RECEIVE_PRODUCT_RULES';
export const RECEIVE_PRODUCT_FIELDS = 'sypht/validate/RECEIVE_PRODUCT';

// HELPERS
const getCredentials = auth => {
  const { accessToken, user } = auth;
  const { companyId } = user.data || auth.claims;

  return {
    accessToken,
    companyId,
    user,
  };
};

// DISPATCHERS
const receiveRules = rule => {
  // rule = <TaskRule>
  return {
    type: RECEIVE_RULES,
    payload: {
      rule,
    },
  };
};

const receiveProductRules = productRules => {
  // productRules = [...{ [name]: [ruleset] }]
  return {
    type: RECEIVE_PRODUCT_RULES,
    payload: {
      productRules,
    },
  };
};

const receiveProductFields = product => {
  // product => Product
  return {
    type: RECEIVE_PRODUCT_FIELDS,
    payload: {
      product,
    },
  };
};

// RULE: CREATE
export const createRule = (productId, rule) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken, companyId } = getCredentials(auth);

    const response = await ValidateRulesService.createRule(
      accessToken,
      companyId,
      productId,
      rule,
    );

    if (response.data && response.data.id) {
      dispatch(receiveRules(response.data));
      dispatch(addNotification(createdMessage));
    } else {
      dispatch(addNotification(errorMessage));
    }
  };
};

// RULE: RETRIEVE
export const retrieveRule = ruleId => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;

    const response = await ValidateRulesService.retrieveRule(
      accessToken,
      ruleId,
    );

    if (response.data && response.data.id) {
      dispatch(receiveRules(response.data));
    } else {
      dispatch(addNotification(errorMessage));
    }
  };
};

// RULE: UPDATE
export const updateRule = (ruleId, productId, ruleType, rule) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken, companyId } = getCredentials(auth);

    const response = await ValidateRulesService.updateRule(
      accessToken,
      ruleId,
      productId,
      ruleType,
      rule,
    );

    if (response.data && response.data.id) {
      dispatch(receiveRules(response.data));
      dispatch(addNotification(updatedMessage));
      // const customMessage = message && { ...updatedMessage, message };
      // dispatch(addNotification(customMessage || updatedMessage));
    } else {
      dispatch(addNotification(errorMessage));
    }
  };
};

// RULE: DELETE
export const deleteRule = (ruleId, ruleType) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = getCredentials(auth);

    const response = await ValidateRulesService.deleteRule(
      accessToken,
      ruleId,
      ruleType,
    );

    if (response.data && response.data.id) {
      dispatch(receiveRules(response.data));
      dispatch(addNotification(deletedMessage));
    } else {
      dispatch(addNotification(errorMessage));
    }
  };
};

// PRODUCT_RULES: LIST
export const listProductRules = productId => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = getCredentials(auth);

    const response = await ValidateRulesService.listProductRules(accessToken);

    if (response.data) {
      dispatch(receiveProductRules(response.data));
    } else {
      dispatch(addNotification(errorMessage));
    }
  };
};

// PRODUCT_RULES: CREATE
export const createProductRules = (productId, rules) => {
  // productId: string
  // rules: rule[]
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken, companyId } = getCredentials(auth);

    const response = await ValidateRulesService.createProductRules(
      accessToken,
      companyId,
      productId,
      rules,
    );

    if (response.data) {
      dispatch(receiveRules(response.data));
      dispatch(
        addNotification({
          ...createdMessage,
          message: 'Rules have been created.',
        }),
      );
    } else {
      dispatch(addNotification(errorMessage));
    }
  };
};

// PRODUCT_RULES: UPDATE
export const updateProductRules = (productId, rules) => {
  // productId: string
  // rules: rule[]
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken, companyId } = getCredentials(auth);

    const response = await ValidateRulesService.updateProductRules(
      accessToken,
      companyId,
      productId,
      rules,
    );

    if (response.data) {
      dispatch(receiveRules(response.data));
      dispatch(
        addNotification({
          ...createdMessage,
          message: `Rules for '${productId}' have been updated.`,
        }),
      );
    } else {
      dispatch(addNotification(errorMessage));
    }
  };
};

// PRODUCT_FIELDS: RETRIEVE
export const retrieveProductFields = productId => {
  // productId: string
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;

    const product = await ProductService.getProduct(accessToken, productId);
    dispatch(receiveProductFields(product.data));
  };
};
