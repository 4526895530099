/* eslint-disable no-shadow */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
import { fabric } from 'fabric';
import { v4 as uuid } from 'uuid';
import moment from 'moment';

import {
  rowLineConfig,
  colLineConfig,
  tableBorderConfig,
  indicatorConfig,
  headerHighlightConfig,
  headerSelectionConfig,
  colours,
  maskConfig,
  existingTable,
  existingTableLabelText,
  existingTableLabelBg,
} from './canvasTableConfig';

import {
  addRowColSvg,
  moveSvg,
  resizeSvg,
  tableLockedSvg,
  tableUnlockedSvg,
  removeRowColSvg,
  checkboxSvg,
} from './canvasTableSvgs';

const offsetPoint = ({ x, y }, o) => ({ x: x + o.left, y: y + o.top });
const offsetRect = ({ tl, br }, o) => ({
  tl: offsetPoint(tl, o),
  br: offsetPoint(br, o),
});
const invertOffset = ({ top, left }) => ({ top: 0 - top, left: 0 - left });
const sortRows = (a, b) => a.top - b.top;
const sortCols = (a, b) => a.left - b.left;

class CanvasTableUI {
  constructor({ canvas, offset, imageRect }) {
    this.canvas = canvas;
    this.UIControls = {
      tableBorder: null,
      rows: [],
      cols: [],
      moveHandle: null,
      resizeHandle: null,
      addRowHandle: null,
      addRowIndicator: null,
      addColHandle: null,
      addColIndicator: null,
      removeRowHandle: null,
      removeColHandle: null,
      rowHeaderControl: null,
      columnHeaderControl: null,
      lastColumnHeaderControl: null,
      rowHeaderHighlight: null,
      columnHeaderHighlight: null,
      lastColumnHeaderHighlight: null,
      existingTables: [],
      mask: null,
      lockTableControl: null,
      unlockTableControl: null,
      checkbox: null,
      rowCheckboxes: [],
      disabledRowMasks: [],
    };
    this.UIState = {
      addingTable: false,
      resizingTable: false,
      movingTable: false,
      movingRow: null,
      movingCol: null,
      removeHover: false,
      header: '',
      lockTable: false,
      prevPoint: null,
      isInit: false,
      now: null,
      lastUpdate: null,
      disabledRows: [],
      offset,
      imageRect,
    };
  }

  async initialise(activeTable, existingTables, onUpdate, ro = false) {
    try {
      await this._loadSvgs.bind(this)();
      this.onUpdate = onUpdate;
      this.initExistingTables(existingTables);
      if (!activeTable && !ro) {
        this._initAddTable();
      } else if (activeTable) {
        this._initActiveTable(activeTable, ro);
      }
      this.initMask();
      this.UIState.isInit = true;
      if (activeTable) {
        this.updateContext(false);
      }
    } catch (e) {
      console.error(e);
    }
  }

  updateContext(throttle = true) {
    const {
      UIState: { isInit },
    } = this;

    if (isInit) {
      const throttledUpdate = this.throttle(
        () => {
          const {
            UIControls: { tableBorder, rows, cols },
            UIState: { header, lockTable, offset, disabledRows },
          } = this;
          const updateRows = [...rows.sort(sortRows).map(r => r.aCoords)];
          const updateCols = [...cols.sort(sortCols).map(c => c.aCoords)];
          if (tableBorder) {
            updateRows.push({
              tl: {
                x: tableBorder.aCoords.tl.x,
                y: tableBorder.aCoords.br.y,
              },
              br: tableBorder.aCoords.br,
            });
            updateCols.push({
              tl: {
                x: tableBorder.aCoords.br.x,
                y: tableBorder.aCoords.tl.y,
              },
              br: tableBorder.aCoords.br,
            });
          }
          this.onUpdate({
            tableBorder: tableBorder
              ? offsetRect(tableBorder.aCoords, invertOffset(offset))
              : null,
            rows: updateRows.map(r => offsetRect(r, invertOffset(offset))),
            cols: updateCols.map(c => offsetRect(c, invertOffset(offset))),
            header,
            lockTable,
            disabledRows: disabledRows.map(
              rId => rows.findIndex(r => r.id === rId) + 1,
            ),
          });
        },
        throttle ? 500 : 0,
      );
      throttledUpdate();
    }
  }

  throttle(f, delay = 500) {
    return () => {
      const { UIState } = this;
      UIState.now = moment().valueOf();
      if (!UIState.lastUpdate || UIState.lastUpdate + delay < UIState.now) {
        UIState.lastUpdate = UIState.now;
        f.apply(this);
      }
    };
  }

  /*
   * Initialisation functions:
   */

  _initAddTable() {
    const { canvas } = this;
    canvas.off('mouse:down');
    canvas.off('mouse:up');
    canvas.off('mouse:move');

    canvas.on('mouse:down', e => this.addTableBorder(e));
    canvas.on('mouse:move', e => this.canvasMouseMove(e));
    canvas.on('mouse:up', () => this.addTableMouseUp());
    canvas.hoverCursor = 'crosshair';
    canvas.moveCursor = 'crosshair';
  }

  _initActiveTable(activeTable, readOnly) {
    const {
      canvas,
      UIControls,
      UIState: { offset },
    } = this;
    const { rows, columns, header, lockTable, disabledRows } = activeTable;
    let { tableBounds } = activeTable;
    tableBounds = offsetRect(tableBounds, offset);

    UIControls.tableBorder = new fabric.Rect({
      left: tableBounds.tl.x,
      top: tableBounds.tl.y,
      width: tableBounds.br.x - tableBounds.tl.x,
      height: tableBounds.br.y - tableBounds.tl.y,
      selectable: false,
      ...tableBorderConfig,
    });
    canvas.add(UIControls.tableBorder);
    this.initControls();

    // eslint-disable-next-line no-unused-vars
    for (const row of rows) {
      this.addRow({ pointer: offsetPoint(row.tl, offset), readOnly });
    }
    // eslint-disable-next-line no-unused-vars
    for (const column of columns) {
      this.addColumn({ pointer: offsetPoint(column.tl, offset), readOnly });
    }
    this.setHeader(header);
    this.initDisabledRows(disabledRows);
    this.lockTable(lockTable);
    if (readOnly) {
      this.hideControls();
    }
    canvas.requestRenderAll();

    canvas.off('mouse:down');
    canvas.off('mouse:up');
    canvas.off('mouse:move');
    if (!readOnly) {
      canvas.on('mouse:move', e => this.canvasMouseMove(e));
      canvas.on('mouse:up', () => this.canvasMouseUp());
    }
  }

  initMask() {
    const {
      canvas,
      UIControls,
      UIControls: { tableBorder },
    } = this;

    UIControls.mask = new fabric.Rect({
      ...maskConfig,
      top: 0,
      left: 0,
      width: canvas.width,
      height: canvas.height,
      clipPath: new fabric.Rect({
        left: tableBorder ? tableBorder.left : 0,
        top: tableBorder ? tableBorder.top : 0,
        width: tableBorder ? tableBorder.width : 0,
        height: tableBorder ? tableBorder.height : 0,
        inverted: true,
        absolutePositioned: true,
      }),
    });
    canvas.add(UIControls.mask);
    UIControls.mask.sendToBack();
  }

  initControls() {
    this.initMoveHandle();
    this.initResizeHandle();
    this.initAddRowColHandles();
    this.initRemoveRowColHandles();
    this.initLockTableHandles();
    this.initHeaderControls();
  }

  initDisabledRows(disabledRows) {
    const {
      UIControls: { rows, rowCheckboxes },
    } = this;
    rows.sort(sortRows);
    rows.forEach((row, i) => {
      if (disabledRows.includes(i + 1)) {
        const check = rowCheckboxes.find(o => o.id === `${row.id}-toggle`);
        this.toggleRow(
          {
            isClick: true,
            target: check,
          },
          row.id,
        );
      }
    });
  }

  initMoveHandle() {
    const { canvas } = this;
    const { tableBorder, moveHandle } = this.UIControls;
    moveHandle.set({
      selectable: false,
      top: tableBorder.top - 19,
      left: tableBorder.left - 19,
    });
    moveHandle.hoverCursor = 'pointer';
    moveHandle.on('mousedown', e => {
      const { UIState } = this;
      if (!UIState.lockTable) {
        UIState.movingTable = true;
        UIState.prevPoint = e.pointer;
      }
    });
    canvas.add(moveHandle);
  }

  initResizeHandle() {
    const { canvas } = this;
    const { tableBorder, resizeHandle } = this.UIControls;
    resizeHandle.set({
      selectable: false,
      top: tableBorder.top + tableBorder.height - 12,
      left: tableBorder.left + tableBorder.width - 12,
    });
    resizeHandle.hoverCursor = 'nwse-resize';
    resizeHandle.on('mousedown', e => {
      const { UIState } = this;
      if (!UIState.lockTable) {
        UIState.resizingTable = true;
        UIState.prevPoint = e.pointer;
      }
    });
    canvas.add(resizeHandle);
  }

  initAddRowColHandles() {
    const { canvas, UIControls } = this;
    const { addColHandle, addRowHandle } = this.UIControls;
    addColHandle.set({
      selectable: false,
      visible: false,
      id: 'addRow',
      hoverCursor: 'pointer',
    });
    addColHandle.on('mouseup', e => {
      const { canvas } = this;
      if (e.isClick) {
        this.addColumn(e);
        canvas.requestRenderAll();
        this.updateContext();
      }
    });
    addRowHandle.set({
      selectable: false,
      visible: false,
      id: 'addRow',
      hoverCursor: 'pointer',
    });
    addRowHandle.on('mouseup', e => {
      if (e.isClick) {
        const { canvas } = this;
        this.addRow(e);
        canvas.requestRenderAll();
        this.updateContext();
      }
    });
    canvas.add(addColHandle);
    canvas.add(addRowHandle);

    UIControls.addRowIndicator = new fabric.Line([0, 0, 0, 0], indicatorConfig);
    UIControls.addColIndicator = new fabric.Line([0, 0, 0, 0], indicatorConfig);
    canvas.add(UIControls.addRowIndicator);
    canvas.add(UIControls.addColIndicator);
  }

  initRemoveRowColHandles() {
    const { UIState, canvas } = this;
    const { removeColHandle, removeRowHandle, addColHandle, addRowHandle } =
      this.UIControls;
    removeColHandle.set({
      selectable: false,
      visible: false,
      id: 'removeCol',
      hoverCursor: 'pointer',
    });
    removeColHandle.on('mouseover', () => {
      UIState.removeHover = true;
      addColHandle.set({ visible: false });
    });
    removeColHandle.on('mouseout', () => {
      UIState.removeHover = false;
      removeColHandle.set({ visible: false });
    });

    removeRowHandle.set({
      selectable: false,
      visible: false,
      id: 'removeRow',
      hoverCursor: 'pointer',
    });
    removeRowHandle.on('mouseover', () => {
      UIState.removeHover = true;
      addRowHandle.set({ visible: false });
    });
    removeRowHandle.on('mouseout', () => {
      UIState.removeHover = false;
      removeRowHandle.set({ visible: false });
    });

    canvas.add(removeColHandle);
    canvas.add(removeRowHandle);
  }

  initLockTableHandles() {
    const {
      canvas,
      UIControls: { unlockTableControl, lockTableControl, tableBorder },
    } = this;
    unlockTableControl.set({
      selectable: false,
      visible: false,
      top: tableBorder.top - 19,
      left: tableBorder.left + tableBorder.width + 7,
    });
    unlockTableControl.on('mouseup', e => {
      if (e.isClick) {
        this.lockTable(false);
      }
    });
    unlockTableControl.hoverCursor = 'pointer';

    lockTableControl.set({
      selectable: false,
      top: tableBorder.top - 19,
      left: tableBorder.left + tableBorder.width + 8,
    });
    lockTableControl.on('mouseup', e => {
      if (e.isClick) {
        this.lockTable(true);
      }
    });
    lockTableControl.hoverCursor = 'pointer';

    canvas.add(unlockTableControl);
    canvas.add(lockTableControl);
  }

  initHeaderControls() {
    const {
      canvas,
      UIControls,
      UIControls: { tableBorder },
    } = this;

    UIControls.rowHeaderControl = new fabric.Line(
      [tableBorder.width / 2, 0, tableBorder.width / 2, tableBorder.height / 4],
      {
        ...headerSelectionConfig,
        left: tableBorder.left - 14,
        top: tableBorder.top + 2,
        hoverCursor: 'pointer',
      },
    );

    UIControls.rowHeaderControl.on('mouseup', e => {
      const { UIState } = this;
      if (e.isClick && !UIState.lockTable) {
        this.setHeader(UIState.header !== 'row' ? 'row' : '');
      }
    });

    UIControls.columnHeaderControl = new fabric.Line(
      [
        0,
        tableBorder.height / 2,
        tableBorder.width / 4,
        tableBorder.height / 2,
      ],
      {
        ...headerSelectionConfig,
        left: tableBorder.left + 2,
        top: tableBorder.top - 14,
        hoverCursor: 'pointer',
      },
    );

    UIControls.columnHeaderControl.on('mouseup', e => {
      const { UIState } = this;
      if (e.isClick && !UIState.lockTable) {
        this.setHeader(UIState.header !== 'first-column' ? 'first-column' : '');
      }
    });

    UIControls.lastColumnHeaderControl = new fabric.Line(
      [
        0,
        tableBorder.height / 2,
        tableBorder.width / 4,
        tableBorder.height / 2,
      ],
      {
        ...headerSelectionConfig,
        left: tableBorder.aCoords.br.x - 2 - tableBorder.getScaledWidth() / 4,
        top: tableBorder.top - 14,
        hoverCursor: 'pointer',
      },
    );

    UIControls.lastColumnHeaderControl.on('mouseup', e => {
      if (e.isClick && !UIControls.lockTable) {
        this.setHeader(
          UIControls.header !== 'last-column' ? 'last-column' : '',
        );
      }
    });

    UIControls.rowHeaderHighlight = new fabric.Rect(headerHighlightConfig);
    UIControls.columnHeaderHighlight = new fabric.Rect(headerHighlightConfig);
    UIControls.lastColumnHeaderHighlight = new fabric.Rect(
      headerHighlightConfig,
    );

    canvas.add(UIControls.rowHeaderControl);
    canvas.add(UIControls.columnHeaderControl);
    canvas.add(UIControls.lastColumnHeaderControl);

    canvas.add(UIControls.rowHeaderHighlight);
    canvas.add(UIControls.columnHeaderHighlight);
    canvas.add(UIControls.lastColumnHeaderHighlight);
    UIControls.rowHeaderHighlight.sendToBack();
    UIControls.columnHeaderHighlight.sendToBack();
    UIControls.lastColumnHeaderHighlight.sendToBack();
  }

  initExistingTables(tables) {
    const {
      canvas,
      UIControls: { existingTables },
      UIState: { offset },
    } = this;

    // eslint-disable-next-line no-unused-vars
    for (const table of tables) {
      const bounds = offsetRect(table.tableBounds, offset);

      const border = new fabric.Rect({
        top: bounds.tl.y,
        left: bounds.tl.x,
        width: bounds.br.x - bounds.tl.x,
        height: bounds.br.y - bounds.tl.y,
        ...existingTable,
      });
      const label = new fabric.Text(`Table ${table.index + 1}`, {
        top: bounds.tl.y + 5,
        left: bounds.tl.x + 12,
        ...existingTableLabelText,
      });
      const labelBg = new fabric.Rect({
        top: bounds.tl.y + 2,
        left: bounds.tl.x + 2,
        width: label.width + 24,
        height: label.height + 10,
        ...existingTableLabelBg,
      });

      canvas.add(border);
      canvas.add(labelBg);
      canvas.add(label);
      existingTables.push({
        border,
        label,
        labelBg,
      });
    }
  }

  _loadSvgs() {
    return new Promise(resolve => {
      const svgsLoaded = () =>
        !!this.UIControls.moveHandle &&
        !!this.UIControls.resizeHandle &&
        !!this.UIControls.addColHandle &&
        !!this.UIControls.addRowHandle &&
        !!this.UIControls.removeRowHandle &&
        !!this.UIControls.removeColHandle &&
        !!this.UIControls.lockTableControl &&
        !!this.UIControls.unlockTableControl &&
        !!this.UIControls.checkbox;

      const { UIControls } = this;

      const loadSvg = (svg, target) => {
        fabric.loadSVGFromString(svg, (obj, opt) => {
          UIControls[target] = fabric.util.groupSVGElements(obj, opt);
          if (svgsLoaded()) {
            resolve();
          }
        });
      };

      const loadSvgAndClone = (svg, targets) => {
        fabric.loadSVGFromString(svg, (obj, opt) => {
          // eslint-disable-next-line no-param-reassign
          obj = fabric.util.groupSVGElements(obj, opt);
          // eslint-disable-next-line no-unused-vars
          for (const target of targets) {
            obj.clone(clone => {
              UIControls[target] = clone;
              if (svgsLoaded()) {
                resolve();
              }
            });
          }
        });
      };

      loadSvg(moveSvg, 'moveHandle');
      loadSvg(resizeSvg, 'resizeHandle');
      loadSvg(tableLockedSvg, 'unlockTableControl');
      loadSvg(tableUnlockedSvg, 'lockTableControl');
      loadSvg(checkboxSvg, 'checkbox');
      loadSvgAndClone(addRowColSvg, ['addColHandle', 'addRowHandle']);
      loadSvgAndClone(removeRowColSvg, ['removeColHandle', 'removeRowHandle']);
    });
  }

  hideControls() {
    const {
      UIControls: {
        moveHandle,
        resizeHandle,
        rowCheckboxes,
        lockTableControl,
        unlockTableControl,
        columnHeaderControl,
        rowHeaderControl,
        lastColumnHeaderControl,
      },
    } = this;
    const controls = [
      ...rowCheckboxes,
      lockTableControl,
      unlockTableControl,
      moveHandle,
      resizeHandle,
      columnHeaderControl,
      rowHeaderControl,
      lastColumnHeaderControl,
    ];
    // eslint-disable-next-line no-unused-vars
    for (const control of controls) {
      control
        .set({
          visible: false,
        })
        .setCoords();
    }
  }

  /*
   * Event handler and interaction functions:
   */

  addTableBorder(e) {
    const { UIState, UIControls, canvas } = this;

    UIState.addingTable = true;
    const { pointer } = e;
    UIState.prevPoint = pointer;

    UIControls.tableBorder = new fabric.Rect({
      left: pointer.x,
      top: pointer.y,
      width: 0,
      height: 0,
      // id: `table-rect-${activeTableId}`,
      selectable: false,
      ...tableBorderConfig,
    });

    canvas.add(UIControls.tableBorder);
    UIControls.tableBorder.sendToBack();
    UIControls.mask.clipPath
      .set({
        left: pointer.x,
        top: pointer.y,
        width: 0,
        height: 0,
      })
      .setCoords();
    canvas.requestRenderAll();
    this.updateContext();
  }

  addTableMouseUp() {
    const { UIState, canvas } = this;
    if (UIState.addingTable) {
      const {
        tableBorder,
        moveHandle,
        resizeHandle,
        lockTableControl,
        unlockTableControl,
      } = this.UIControls;

      UIState.addingTable = false;

      this.initControls();

      moveHandle.set({
        top: tableBorder.top - 19,
        left: tableBorder.left - 19,
      });

      canvas.add(moveHandle);
      canvas.add(resizeHandle);
      canvas.add(lockTableControl);
      canvas.add(unlockTableControl);

      canvas.requestRenderAll();
      this.updateContext();
      canvas.off('mouse:up');
      canvas.off('mouse:down');
      canvas.on('mouse:up', () => this.canvasMouseUp());
      canvas.hoverCursor = 'default';
    }
    UIState.prevPoint = null;
  }

  canvasMouseUp() {
    const { UIState } = this;
    if (UIState.resizingTable) {
      UIState.resizingTable = false;
      this.updateContext();
    }
    if (UIState.movingTable) {
      UIState.movingTable = false;
      this.updateContext();
    }
    UIState.prevPoint = null;
  }

  canvasMouseMove(e) {
    const {
      UIState,
      UIControls: { tableBorder },
    } = this;
    if (!UIState.prevPoint) {
      UIState.prevPoint = e.pointer;
    }
    if (UIState.addingTable || UIState.resizingTable) {
      this.resizeTableBorder(e);
    }
    if (UIState.movingTable) {
      this.moveTable(e);
    }

    if (UIState.movingRow) {
      this.moveRow(e);
    }
    if (UIState.movingCol) {
      this.moveColumn(e);
    }

    if (
      tableBorder &&
      !(
        UIState.addingTable ||
        UIState.resizingTable ||
        UIState.movingTable ||
        UIState.lockTable
      )
    ) {
      this.displayRowColHandles(e);
    }

    UIState.prevPoint = e.pointer;
    if (
      UIState.addingTable ||
      UIState.resizingTable ||
      UIState.movingTable ||
      UIState.movingCol ||
      UIState.movingRow
    ) {
      this.updateContext();
    }
  }

  resizeTableBorder(e) {
    const { pointer } = e;
    const {
      UIState: { prevPoint, offset, imageRect, addingTable },
      canvas,
      UIControls: { tableBorder },
    } = this;

    const diff = {
      x: pointer.x - prevPoint.x,
      y: pointer.y - prevPoint.y,
    };

    if (
      tableBorder.top + tableBorder.height + diff.y <
        offset.top + imageRect.height &&
      tableBorder.left + tableBorder.width + diff.x <
        offset.left + imageRect.width
    ) {
      tableBorder
        .set({
          width:
            tableBorder.width + diff.x < 14 ? 14 : tableBorder.width + diff.x,
          height:
            tableBorder.height + diff.y < 14 ? 14 : tableBorder.height + diff.y,
        })
        .setCoords();
      this.updateControls(e);
      this.resizeRowsAndColumns();
      if (!addingTable) {
        this.updateHeaderControls();
        this.updateRowMasks();
      }
    }

    canvas.requestRenderAll();
  }

  resizeRowsAndColumns() {
    const {
      canvas,
      UIControls,
      UIControls: {
        tableBorder,
        rows,
        cols,
        rowCheckboxes,
        disabledRowMasks,
        removeRowHandle,
      },
    } = this;
    // eslint-disable-next-line no-unused-vars
    for (const row of rows) {
      const checkbox = rowCheckboxes.find(o => o.id === `${row.id}-toggle`);
      const rowMask = disabledRowMasks.find(o => o.id === `${row.id}-mask`);
      if (tableBorder.aCoords.br.y < row.top) {
        canvas.remove(row);
        canvas.remove(checkbox);
        UIControls.rows = rows.filter(r => r.id !== row.id);
        UIControls.rowCheckboxes = rowCheckboxes.filter(
          o => o.id !== `${row.id}-toggle`,
        );
        if (rowMask) {
          canvas.remove(rowMask);
          UIControls.disabledRowMasks = disabledRowMasks.filter(
            o => o.id !== `${row.id}-mask`,
          );
        }
        removeRowHandle.set({ visible: false });
      } else {
        row.set({ width: tableBorder.width });
        checkbox.set({
          left: tableBorder.left + tableBorder.width + 7,
        });
      }
    }
    // eslint-disable-next-line no-unused-vars
    for (const col of cols) {
      if (tableBorder.aCoords.br.x < col.left) {
        canvas.remove(col);
        UIControls.cols = cols.filter(c => c.id !== col.id);
      } else {
        col.set({ height: tableBorder.height });
      }
    }
  }

  moveTable(e) {
    const { pointer } = e;
    const {
      UIState: { prevPoint, offset, imageRect },
      canvas,
      UIControls: { tableBorder },
    } = this;

    const diff = {
      x: pointer.x - prevPoint.x,
      y: pointer.y - prevPoint.y,
    };

    if (
      tableBorder.top + diff.y > offset.top &&
      tableBorder.left + diff.x > offset.left &&
      tableBorder.top + tableBorder.height + diff.y <
        offset.top + imageRect.height &&
      tableBorder.left + tableBorder.width + diff.x <
        offset.left + imageRect.width
    ) {
      tableBorder
        .set({
          top: tableBorder.top + diff.y,
          left: tableBorder.left + diff.x,
        })
        .setCoords();
      this.moveRowsAndColumns(diff);
    }

    this.updateControls(e);
    this.updateHeaderControls();
    this.updateRowMasks();
    canvas.requestRenderAll();
    this.updateContext();
  }

  moveRowsAndColumns(diff) {
    const {
      UIControls: { rows, cols, rowCheckboxes },
    } = this;
    // eslint-disable-next-line no-unused-vars
    for (const row of rows) {
      row
        .set({
          top: row.top + diff.y,
          left: row.left + diff.x,
        })
        .setCoords();
    }
    // eslint-disable-next-line no-unused-vars
    for (const col of cols) {
      col
        .set({
          top: col.top + diff.y,
          left: col.left + diff.x,
        })
        .setCoords();
    }
    // eslint-disable-next-line no-unused-vars
    for (const checkbox of rowCheckboxes) {
      checkbox
        .set({
          top: checkbox.top + diff.y,
          left: checkbox.left + diff.x,
        })
        .setCoords();
    }
  }

  updateControls() {
    const {
      UIControls: {
        tableBorder,
        moveHandle,
        resizeHandle,
        lockTableControl,
        unlockTableControl,
        mask,
      },
    } = this;

    mask.clipPath
      .set({
        top: tableBorder.top,
        left: tableBorder.left,
        width: tableBorder.width,
        height: tableBorder.height,
      })
      .setCoords();
    moveHandle
      .set({
        top: tableBorder.top - 19,
        left: tableBorder.left - 19,
      })
      .setCoords();
    resizeHandle
      .set({
        top: tableBorder.top + tableBorder.height - 12,
        left: tableBorder.left + tableBorder.width - 12,
      })
      .setCoords();
    lockTableControl
      .set({
        top: tableBorder.top - 19,
        left: tableBorder.left + tableBorder.width + 8,
      })
      .setCoords();
    unlockTableControl
      .set({
        top: tableBorder.top - 19,
        left: tableBorder.left + tableBorder.width + 7,
      })
      .setCoords();
  }

  displayRowColHandles(e) {
    const { x, y } = e.pointer;
    const {
      canvas,
      UIState: { removeHover },
      UIControls: {
        tableBorder,
        addColHandle,
        addRowHandle,
        addColIndicator,
        addRowIndicator,
      },
    } = this;
    const { tl, br } = tableBorder.aCoords;
    const precision = 7;
    const hidden = {
      visible: false,
    };

    const top = tl.y - precision <= y && tl.y + precision >= y;
    const left = tl.x - precision <= x && tl.x + precision >= x;
    const inTable =
      y <= br.y + precision &&
      y >= tl.y - precision &&
      x <= br.x + precision &&
      x >= tl.x - precision;

    if (top && inTable && !removeHover) {
      addColHandle
        .set({
          top: tl.y - 7,
          left:
            // eslint-disable-next-line no-nested-ternary
            x < br.x && x > tl.x
              ? x - addColHandle.width / 2
              : x > br.x
              ? br.x - addColHandle.width / 2
              : tl.x - addColHandle.width / 2,
          visible: true,
        })
        .setCoords();
      addColIndicator
        .set({
          visible: true,
          // eslint-disable-next-line no-nested-ternary
          left: x < br.x && x > tl.x ? x : x > br.x ? br.x : tl.x,
          height: tableBorder.height,
          top: tl.y,
        })
        .setCoords();
      addRowHandle.set(hidden).setCoords();
      addRowIndicator.set(hidden).setCoords();
    } else if (left && inTable && !removeHover) {
      addRowHandle
        .set({
          top:
            // eslint-disable-next-line no-nested-ternary
            y < br.y && y > tl.y
              ? y - addRowHandle.height / 2
              : y > br.y
              ? br.y - addRowHandle.height / 2
              : tl.y - addRowHandle.height / 2,
          left: tl.x - 7,
          visible: true,
        })
        .setCoords();
      addRowIndicator
        .set({
          visible: true,
          // eslint-disable-next-line no-nested-ternary
          top: y < br.y && y > tl.y ? y : y > br.y ? br.y : tl.y,
          left: tl.x,
          width: tableBorder.width,
        })
        .setCoords();
      addColHandle.set(hidden).setCoords();
      addColIndicator.set(hidden).setCoords();
    } else {
      addColHandle.set(hidden).setCoords();
      addColIndicator.set(hidden).setCoords();
      addRowHandle.set(hidden).setCoords();
      addRowIndicator.set(hidden).setCoords();
    }
    canvas.requestRenderAll();
  }

  lockTable(locked) {
    const {
      UIState,
      canvas,
      UIControls: { unlockTableControl, lockTableControl },
    } = this;
    unlockTableControl.set({ visible: locked }).setCoords();
    lockTableControl.set({ visible: !locked }).setCoords();
    // setFabricContext(state => ({...state, lockTable:true}));
    UIState.lockTable = locked;
    this.setBaseColour(locked ? colours.grey : colours.blue);
    canvas.requestRenderAll();
  }

  setBaseColour(colour) {
    const {
      UIControls: {
        tableBorder,
        rows,
        cols,
        moveHandle,
        resizeHandle,
        rowHeaderControl,
        columnHeaderControl,
        rowHeaderHighlight,
        columnHeaderHighlight,
        lastColumnHeaderControl,
        lastColumnHeaderHighlight,
        rowCheckboxes,
      },
    } = this;
    const UIElements = [
      tableBorder,
      ...rows,
      ...cols,
      moveHandle,
      resizeHandle,
      rowHeaderControl,
      columnHeaderControl,
      rowHeaderHighlight,
      columnHeaderHighlight,
      lastColumnHeaderControl,
      lastColumnHeaderHighlight,
    ];

    // eslint-disable-next-line no-unused-vars
    for (const element of UIElements) {
      if (element !== null) {
        if (element.fill && element.fill !== 'transparent') {
          element.set({ fill: colour });
        }
        if (element.stroke && element.stroke !== 'transparent') {
          element.set({ stroke: colour });
        }
        if (element._objects) {
          element._objects.forEach(o => {
            o.set({ fill: colour });
          });
        }
      }
    }
    // eslint-disable-next-line no-unused-vars
    for (const checkbox of rowCheckboxes) {
      checkbox._objects.forEach(o => {
        if (o.type === 'rect') {
          o.set({ stroke: colour });
        } else {
          o.set({ fill: colour });
        }
      });
    }
  }

  addRow(e) {
    const {
      canvas,
      UIControls: { tableBorder, rows, checkbox },
      UIState: { header },
    } = this;
    const { tl, br } = tableBorder.aCoords;
    // eslint-disable-next-line no-unused-vars
    const { x, y } = e.pointer;

    if (y > tl.y && y < br.y) {
      const rowId = uuid();
      const row = new fabric.Line([tl.x, y, br.x, y], {
        left: tableBorder.left,
        top: y,
        ...rowLineConfig,
        selectable: false,
        id: rowId,
      });

      checkbox.clone(
        (rowId => {
          return clone => {
            const {
              canvas,
              UIControls: { tableBorder, rowCheckboxes },
            } = this;
            clone
              .set({
                id: `${rowId}-toggle`,
                top: y + 1,
                left: tableBorder.left + tableBorder.width + 7,
                selectable: false,
                hoverCursor: 'pointer',
              })
              .setCoords();
            clone.on('mouseup', e => this.toggleRow(e, rowId));
            canvas.add(clone);
            rowCheckboxes.push(clone);
          };
        })(rowId),
      );

      if (!e.readOnly) {
        row.on('mouseover', e => {
          const {
            canvas,
            UIState: { lockTable },
            UIControls: { tableBorder, removeRowHandle },
          } = this;
          if (!lockTable) {
            // eslint-disable-next-line no-unused-vars
            const { tl, br } = tableBorder.aCoords;
            const targetRow = e.target;
            removeRowHandle.on('mouseup', e => {
              const {
                canvas,
                UIState,
                UIState: { disabledRows },
                UIControls,
                UIControls: {
                  removeRowHandle,
                  rows,
                  rowCheckboxes,
                  disabledRowMasks,
                },
              } = this;
              if (e.isClick) {
                const check = rowCheckboxes.find(
                  o => o.id === `${targetRow.id}-toggle`,
                );
                const mask = disabledRowMasks.find(
                  o => o.id === `${targetRow.id}-mask`,
                );
                canvas.remove(targetRow);
                canvas.remove(check);
                canvas.remove(mask);
                removeRowHandle.off('mouseup');
                removeRowHandle.set({ visible: false });
                UIControls.rows = rows.filter(r => r.id !== targetRow.id);
                UIControls.rowCheckboxes = rowCheckboxes.filter(
                  o => o.id !== `${targetRow.id}-toggle`,
                );
                UIControls.disabledRowMasks = disabledRowMasks.filter(
                  o => o.id !== `${targetRow.id}-mask`,
                );
                UIState.disabledRows = disabledRows.filter(
                  r => r !== targetRow.id,
                );
                this.updateHeaderControls();
                this.updateContext();
              }
            });

            removeRowHandle
              .set({
                top: targetRow.top - 7,
                left: tl.x - 7,
                visible: true,
              })
              .setCoords();
            removeRowHandle.bringToFront();
            canvas.requestRenderAll();
          }
        });

        row.on('mouseout', e => {
          const {
            canvas,
            UIControls: { removeRowHandle },
          } = this;
          if (
            (e.nextTarget && e.nextTarget.id !== `removeRow`) ||
            e.nextTarget === null
          ) {
            // need a better way to detect hitting icon
            removeRowHandle.set({
              visible: false,
            });
            removeRowHandle.off('mouseup');
            removeRowHandle.setCoords();
            canvas.requestRenderAll();
          }
        });

        row.on('mousedown', e => {
          const { UIState } = this;
          if (!UIState.lockTable) {
            UIState.movingRow = e.target;
          }
        });
        row.on('mouseup', () => {
          const { UIState } = this;
          UIState.movingRow = null;
          this.updateContext();
        });
      }

      rows.push(row);
      canvas.add(row);
      canvas.bringToFront(row);
      this.updateRowMasks();
      this.updateHeaderControls();
      if (rows.length === 1 && header === '') {
        this.setHeader('row');
      }
    }
  }

  toggleRow(e, rowId) {
    const {
      canvas,
      UIState,
      UIState: { disabledRows, lockTable },
      UIControls,
      UIControls: { rows, disabledRowMasks, tableBorder },
    } = this;
    if (e.isClick && !lockTable) {
      const check = e.target._objects.find(o => o.type === 'path');
      if (disabledRows.includes(rowId)) {
        const mask = disabledRowMasks.find(o => o.id === `${rowId}-mask`);
        check.set({
          visible: true,
        });
        canvas.remove(mask);
        UIState.disabledRows = disabledRows.filter(r => r !== rowId);
        UIControls.disabledRowMasks = disabledRowMasks.filter(
          o => o.id !== `${rowId}-mask`,
        );
      } else {
        check.set({
          visible: false,
        });
        UIState.disabledRows = [...disabledRows, rowId];
        rows.sort(sortRows);
        const i = rows.findIndex(r => r.id === rowId);
        const mask = new fabric.Rect({
          id: `${rowId}-mask`,
          ...maskConfig,
          top: rows[i].top,
          left: tableBorder.left,
          width: tableBorder.width,
          height: rows[i + 1]
            ? rows[i + 1].top - rows[i].top
            : tableBorder.top + tableBorder.height - rows[i].top,
        });
        disabledRowMasks.push(mask);
        canvas.add(mask);
        mask.sendToBack();
      }
      canvas.requestRenderAll();
      this.updateContext(false);
    }
  }

  addColumn(e) {
    const {
      canvas,
      UIControls: { tableBorder, cols },
    } = this;
    const { tl, br } = tableBorder.aCoords;
    // eslint-disable-next-line no-unused-vars
    const { x, y } = e.pointer;

    if (x > tl.x && x < br.x) {
      const column = new fabric.Line([x, tl.y, x, br.y], {
        left: x,
        top: tableBorder.top,
        ...colLineConfig,
        selectable: false,
        id: uuid(),
      });
      if (!e.readOnly) {
        column.on('mouseover', e => {
          const {
            canvas,
            UIState: { lockTable },
            UIControls: { tableBorder, removeColHandle },
          } = this;
          if (!lockTable) {
            // eslint-disable-next-line no-unused-vars
            const { tl, br } = tableBorder.aCoords;
            const targetCol = e.target;
            removeColHandle.on('mouseup', e => {
              const {
                canvas,
                UIControls,
                UIControls: { removeColHandle, cols },
              } = this;
              if (e.isClick) {
                canvas.remove(targetCol);
                removeColHandle.off('mouseup');
                removeColHandle.set({ visible: false });
                UIControls.cols = cols.filter(c => c.id !== targetCol.id);
                this.updateHeaderControls();
                this.updateContext();
              }
            });

            removeColHandle
              .set({
                top: tl.y - 7,
                left: targetCol.left - 7,
                visible: true,
              })
              .setCoords();
            removeColHandle.bringToFront();
            canvas.requestRenderAll();
          }
        });

        column.on('mouseout', e => {
          const {
            canvas,
            UIControls: { removeColHandle },
          } = this;
          if (
            (e.nextTarget && e.nextTarget.id !== `removeCol`) ||
            e.nextTarget === null
          ) {
            // need a better way to detect hitting icon
            removeColHandle.set({
              visible: false,
            });
            removeColHandle.off('mouseup');
            removeColHandle.setCoords();
            canvas.requestRenderAll();
          }
        });

        column.on('mousedown', e => {
          const { UIState } = this;
          if (!UIState.lockTable) {
            UIState.movingCol = e.target;
          }
        });
        column.on('mouseup', () => {
          const { UIState } = this;
          UIState.movingCol = null;
          this.updateContext();
        });
      }

      cols.push(column);

      canvas.add(column);
      canvas.bringToFront(column);
      this.updateHeaderControls();
    }
  }

  moveRow(e) {
    const { pointer } = e;
    const {
      canvas,
      UIState,
      UIControls: { removeRowHandle, rowCheckboxes },
    } = this;
    const diff = {
      x: pointer.x - UIState.prevPoint.x,
      y: pointer.y - UIState.prevPoint.y,
    };
    const rowId = UIState.movingRow.id;
    const checkbox = rowCheckboxes.find(o => o.id === `${rowId}-toggle`);

    UIState.movingRow
      .set({
        top: UIState.movingRow.top + diff.y,
      })
      .setCoords();
    removeRowHandle
      .set({
        top: removeRowHandle.top + diff.y,
      })
      .setCoords();
    checkbox
      .set({
        top: checkbox.top + diff.y,
      })
      .setCoords();
    this.updateRowMasks();
    this.updateHeaderControls();
    canvas.requestRenderAll();
  }

  updateRowMasks() {
    const {
      UIControls: { disabledRowMasks, rows, tableBorder },
    } = this;
    rows.sort(sortRows);
    // eslint-disable-next-line no-unused-vars
    for (const rowMask of disabledRowMasks) {
      const rowId = rowMask.id.substring(0, 36);
      const i = rows.findIndex(r => r.id === rowId);
      rowMask
        .set({
          top: rows[i].top,
          left: tableBorder.left,
          width: tableBorder.width,
          height: rows[i + 1]
            ? rows[i + 1].top - rows[i].top
            : tableBorder.top + tableBorder.height - rows[i].top,
        })
        .setCoords();
    }
  }

  moveColumn(e) {
    const { pointer } = e;
    const {
      canvas,
      UIState,
      UIControls: { removeColHandle },
    } = this;
    const diff = {
      x: pointer.x - UIState.prevPoint.x,
      y: pointer.y - UIState.prevPoint.y,
    };
    UIState.movingCol
      .set({
        left: UIState.movingCol.left + diff.x,
      })
      .setCoords();
    removeColHandle
      .set({
        left: removeColHandle.left + diff.x,
      })
      .setCoords();
    this.updateHeaderControls();
    canvas.requestRenderAll();
  }

  setHeader(header) {
    const {
      canvas,
      UIState,
      UIControls: {
        rowHeaderControl,
        rowHeaderHighlight,
        columnHeaderControl,
        columnHeaderHighlight,
        lastColumnHeaderControl,
        lastColumnHeaderHighlight,
      },
    } = this;
    UIState.header = header;
    rowHeaderControl.set({ opacity: header === 'row' ? 1 : 0.3 });
    rowHeaderHighlight.set({ visible: header === 'row' });
    columnHeaderControl.set({ opacity: header === 'first-column' ? 1 : 0.3 });
    columnHeaderHighlight.set({ visible: header === 'first-column' });
    lastColumnHeaderControl.set({
      opacity: header === 'last-column' ? 1 : 0.3,
    });
    lastColumnHeaderHighlight.set({ visible: header === 'last-column' });
    canvas.requestRenderAll();
    this.updateContext(false);
  }

  updateHeaderControls() {
    const {
      UIState: { header },
      UIControls: {
        tableBorder,
        rows,
        cols,
        rowHeaderHighlight,
        rowHeaderControl,
        columnHeaderHighlight,
        columnHeaderControl,
        lastColumnHeaderHighlight,
        lastColumnHeaderControl,
      },
    } = this;
    rows.sort(sortRows);
    cols.sort(sortCols);

    const visible = { visible: true };
    const hidden = { visible: false };

    if (rows.length === 0) {
      rowHeaderControl.set(hidden);
      if (header === 'row') {
        this.setHeader('');
      }
    } else {
      rowHeaderControl
        .set({
          height: rows[0].top - tableBorder.top - 10,
          left: tableBorder.left - 14,
          top: tableBorder.top + 2,
          ...visible,
        })
        .setCoords();

      rowHeaderHighlight
        .set({
          left: tableBorder.left,
          top: tableBorder.top,
          width: tableBorder.width,
          height: rows[0].top - tableBorder.top,
        })
        .setCoords();
    }
    if (cols.length === 0) {
      columnHeaderControl.set(hidden);
      lastColumnHeaderControl.set(hidden);
      if (header === 'last-column' || header === 'first-column') {
        this.setHeader('');
      }
    } else {
      columnHeaderControl
        .set({
          width: cols[0].left - tableBorder.left - 10,
          left: tableBorder.left + 2,
          top: tableBorder.top - 14,
          ...visible,
        })
        .setCoords();

      lastColumnHeaderControl
        .set({
          left: cols[cols.length - 1].left + 2,
          top: tableBorder.top - 14,
          width: tableBorder.aCoords.br.x - cols[cols.length - 1].left - 10,
          ...visible,
        })
        .setCoords();

      columnHeaderHighlight
        .set({
          left: tableBorder.left,
          top: tableBorder.top,
          width: cols[0].left - tableBorder.left,
          height: tableBorder.height,
        })
        .setCoords();
      lastColumnHeaderHighlight
        .set({
          left: cols[cols.length - 1].left,
          top: tableBorder.top,
          width: tableBorder.aCoords.br.x - cols[cols.length - 1].left,
          height: tableBorder.height,
        })
        .setCoords();
    }
  }

  onResize(offset, imageRect) {
    const {
      canvas,
      UIControls: {
        tableBorder,
        rows,
        cols,
        mask,
        existingTables,
        rowCheckboxes,
      },
      UIState,
    } = this;
    let render = false;

    const scaleX = imageRect.width / UIState.imageRect.width;
    const scaleY = imageRect.height / UIState.imageRect.height;

    if (tableBorder) {
      const coords = offsetRect(
        tableBorder.aCoords,
        invertOffset(UIState.offset),
      );
      tableBorder
        .set({
          top: coords.tl.y * scaleY + offset.top,
          left: coords.tl.x * scaleX + offset.left,
          width: tableBorder.width * scaleX,
          height: tableBorder.height * scaleY,
        })
        .setCoords();
      render = true;
      // eslint-disable-next-line no-unused-vars
      for (const row of rows) {
        const coords = offsetRect(row.aCoords, invertOffset(UIState.offset));
        const checkbox = rowCheckboxes.find(o => o.id === `${row.id}-toggle`);
        row
          .set({
            top: coords.tl.y * scaleY + offset.top,
            left: coords.tl.x * scaleX + offset.left,
            width: row.width * scaleX,
          })
          .setCoords();
        checkbox
          .set({
            top: row.top + 1,
            left: row.left + row.width + 7,
          })
          .setCoords();
      }
      // eslint-disable-next-line no-unused-vars
      for (const col of cols) {
        const coords = offsetRect(col.aCoords, invertOffset(UIState.offset));
        col
          .set({
            top: coords.tl.y * scaleY + offset.top,
            left: coords.tl.x * scaleX + offset.left,
            height: col.height * scaleY,
          })
          .setCoords();
      }
    }
    // eslint-disable-next-line no-unused-vars
    for (const existingTable of existingTables) {
      const { border, label, labelBg } = existingTable;
      const coords = offsetRect(border.aCoords, invertOffset(UIState.offset));
      border
        .set({
          top: coords.tl.y * scaleY + offset.top,
          left: coords.tl.x * scaleX + offset.left,
          width: border.width * scaleX,
          height: border.height * scaleY,
        })
        .setCoords();
      label
        .set({
          top: border.top + 5,
          left: border.left + 12,
        })
        .setCoords();
      labelBg
        .set({
          top: border.top + 2,
          left: border.left + 2,
        })
        .setCoords();
      render = true;
    }
    if (tableBorder !== null) {
      this.updateControls();
      this.updateHeaderControls();
      render = true;
    }
    if (mask !== null) {
      mask.set({
        width: canvas.width,
        height: canvas.height,
      });
      render = true;
    }
    if (render) {
      canvas.requestRenderAll();
      this.updateContext();
    }
    UIState.offset = offset;
    UIState.imageRect = imageRect;
  }
}

export default CanvasTableUI;
