import React from 'react';
import PropTypes from 'prop-types';
import DocumentView from '../../../DocumentView/DocumentView';
import './DocView.scss';

const DocView = ({ companyId, doc }) => {
  return (
    <div className="doc-view__container">
      <DocumentView doc={doc} companyId={companyId} />
    </div>
  );
};

DocView.propTypes = {
  companyId: PropTypes.string.isRequired,
  doc: PropTypes.object.isRequired,
};

export default DocView;
