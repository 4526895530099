/* eslint-disable import/prefer-default-export */
import { createTransform } from 'redux-persist';

export const cleanNextTask = createTransform(
  // Serialize / persist
  null,
  // Rehydrate
  (outboundState, key) => {
    switch (key) {
      /**
       * Clean sortBy
       *
       * It's possible invalid sortBy foramt (empty object "{}") has been persisted
       * into localstorage from old code.  We reset here. We probably don't need this
       * function - just not sure when the "{}" crept in and if it's in production on
       * some people's browsers.
       *
       * sortBy should be an array of objects in react-table useSortBy format or else
       * null or undefined.  app-service should use a default sort if the sort state
       * is null/undefined or empty array.
       */
      case 'sortBy': {
        const sortBy = outboundState;
        if (!sortBy) {
          return sortBy;
        }
        if (sortBy instanceof Array) {
          return sortBy;
        }
        // Now we fix sortBy
        return null;
      }
      default:
        return outboundState;
    }
  },
);
