import React from 'react';
import Ajv from 'ajv';
import ace from 'brace';
import classNames from 'classnames';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';

import useRefresh from '../../../common/hooks/useRefresh';

import 'brace/mode/json';
import 'brace/theme/github';
import './_jsonEditor.scss';

const LoadableJsonEditor = loadable.lib(() => import('jsoneditor-react'));
const ajvConfig = new Ajv({ allErrors: true, verbose: true });

const JsonEditor = ({
  className,
  data,
  schema,
  title,
  isReadonly,
  ...props
}) => {
  const isRefresh = useRefresh([data]);
  return (
    !isRefresh && (
      <div
        className={classNames('json-editor', className, {
          'json-editor--readonly': isReadonly,
        })}
      >
        <LoadableJsonEditor>
          {({ JsonEditor: Editor }) => (
            <Editor
              ajv={ajvConfig}
              value={data}
              ace={ace}
              theme="ace/theme/github"
              indentation={2}
              schema={{ ...schema, title }}
              {...props}
            />
          )}
        </LoadableJsonEditor>
      </div>
    )
  );
};

JsonEditor.propTypes = {
  className: PropTypes.string,
  data: PropTypes.any,
  schema: PropTypes.object,
  title: PropTypes.string,
};

JsonEditor.defaultProps = {
  className: null,
  data: null,
  schema: null,
  title: null,
};

export default JsonEditor;
