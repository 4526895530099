export const rulesLookup = Object.freeze({
  'field-confidence-range': {
    label: 'Field % prediction confidence below threshold',
    description: 'Minimum ≥{min}%',
  },
  'field-present': {
    label: 'Field is missing',
  },
  'field-value-range': {
    label: 'Field value outside of range',
    description: '{min} - {max}',
  },
});

export const allowedFields = Object.freeze([
  'numeric',
  'currency',
  'multitokenstring',
  'referenceno',
  'date',
  'string',
  'boolean',
  'multilabel',
  'multiclass',
]);
