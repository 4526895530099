import React, { useEffect, useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { mergeRight as merge } from 'ramda';
import { ToggleContext } from './ToggleGroupContext';
import './ToggleGroup.scss';

const ToggleGroup = ({ children, className, defaultActive, disabled }) => {
  const [buttons, setButtons] = useContext(ToggleContext);

  useEffect(() => {
    setButtons(
      merge(buttons, {
        active: defaultActive,
      }),
    );
  }, []);

  return (
    <div
      role="group"
      className={classNames('ToggleGroup', className, {
        disabled,
      })}
    >
      <ul className="ToggleGroup__list">{children}</ul>
    </div>
  );
};

export default ToggleGroup;
