import React from 'react';
import PropTypes from 'prop-types';

const User = ({ user }) => {
  const { firstName, lastName, picture } = user.auth0;
  const name = `${firstName} ${lastName}`.trim();
  return (
    <div className="avatar">
      <img src={picture} className="avatar" alt={name} />
    </div>
  );
};

User.propTypes = {};

export default User;
