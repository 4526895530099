import { mergeRight as merge } from 'ramda';
import {
  REQUEST_CREDENTIALS,
  RECEIVE_CREDENTIALS,
  TRANSMIT_DATA,
  RECEIVE_NEW_CREDENTIAL,
  RECEIVE_UPDATED_CREDENTIAL,
  RECEIVE_DELETED_CREDENTIAL,
  RECEIVE_SECRET,
  RECEIVE_ROTATED_SECRET,
  RECEIVE_DEFAULT_CREDENTIAL,
  RECEIVE_BEARER_TOKEN,
} from './actions';

const initialState = {
  data: null,
  defaultCredential: null,
  loading: false,
  sending: false,
  sent: false,
};

const credentialsReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case TRANSMIT_DATA: {
      return merge(state, payload);
    }

    case REQUEST_CREDENTIALS: {
      return {
        ...state,
        loading: true,
      };
    }

    case RECEIVE_CREDENTIALS: {
      const { data } = payload;
      return merge(state, {
        data,
        sending: false,
        sent: false,
      });
    }

    case RECEIVE_NEW_CREDENTIAL: {
      const { data } = payload;
      return {
        ...state,
        data: [...state.data, data],
        sending: false,
        sent: true,
      };
    }

    case RECEIVE_UPDATED_CREDENTIAL: {
      const { data } = payload;
      const { fieldSet, clientId, clientName } = data;

      return {
        ...state,
        // prettier-ignore
        data: state.data.map(credential =>
          credential.clientId === clientId
            ? { ...credential, fieldSet, clientName }
            : { ...credential },
        ),
        sending: false,
        sent: true,
      };
    }

    case RECEIVE_DELETED_CREDENTIAL: {
      const { data } = payload;
      return {
        ...state,
        data: state.data.filter(k => k.clientId !== data.clientId),
        sending: false,
        sent: true,
      };
    }

    case RECEIVE_SECRET: {
      const { data } = payload;
      const { client_id: clientId, client_secret: clientSecret } = data;
      return {
        ...state,
        data: state.data.map(credential =>
          credential.clientId === clientId
            ? { ...credential, clientSecret }
            : credential,
        ),
        sending: false,
        sent: true,
      };
    }

    case RECEIVE_ROTATED_SECRET: {
      const { data } = payload;
      const { clientId, clientSecret } = data;
      return {
        ...state,
        data: state.data.map(credential =>
          credential.clientId === clientId
            ? { ...credential, clientSecret }
            : credential,
        ),
        sending: false,
        sent: true,
      };
    }

    case RECEIVE_DEFAULT_CREDENTIAL: {
      const { data } = payload;
      return {
        ...state,
        defaultCredential: data,
      };
    }

    case RECEIVE_BEARER_TOKEN: {
      const { data } = payload;
      return {
        ...state,
        defaultCredential: {
          ...state.defaultCredential,
          accessToken: data.access_token,
        },
      };
    }

    default:
      return state;
  }
};

export default credentialsReducer;
