import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaHammer } from 'react-icons/fa';
import DropdownList from '../../../../library/atoms/DropdownList/DropdownList';
import DropdownListItem from '../../../../library/atoms/DropdownListItem/DropdownListItem';
import { Button } from '../../../../library/atoms/Buttons';
import MenuButton from '../../../../library/molecules/MenuButton/MenuButton';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import './FixButton.scss';

/**
 * The Fix button handles both Task creation (HITL) and the "quick fix".
 *
 * Replaces the old "validate" button.
 */
const FixButton = ({
  disablePopover,
  onClick,
  onAction,
  tooltip,
  tooltipOffsetTop,
  ...buttonProps
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <MenuButton
      isOpen={isOpen}
      disablePopover={disablePopover}
      onOutsideClick={() => {
        setIsOpen(false);
      }}
      onClick={onClick}
      content={
        <DropdownList>
          <DropdownListItem
            onClick={event => {
              setIsOpen(false);
              onAction(event, 'addQuickFix');
            }}
          >
            Quick Fix
          </DropdownListItem>
          <DropdownListItem
            onClick={event => {
              setIsOpen(false);
              onAction(event, 'startTask');
            }}
          >
            Create a Fixing Task
          </DropdownListItem>
        </DropdownList>
      }
    >
      {/* We have to make tooltip external to button to display it when button is disabled. */}
      <span
        data-tooltip-id="fix-button-tooltip"
        className="fix-button_tooltip-anchor"
      >
        <Button
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...buttonProps}
          onClick={evt => {
            setIsOpen(state => (disablePopover ? false : !state));
            onClick(evt);
          }}
        >
          <>
            <FaHammer />
            <span>Fix</span>
          </>
        </Button>
        <ReactTooltip
          id="fix-button-tooltip"
          place="bottom"
          style={{
            top: '4',
            bottom: '4',
            left: '4',
            position: 'fixed',
            zIndex: 9999,
            maxWidth: '300px',
          }}
          hidden={isOpen}
        >
          Quickly fix any errors in the extracted data yourself, or create a
          fixing task for someone in your company (only available with Human
          Review add-on)
        </ReactTooltip>
      </span>
    </MenuButton>
  );
};

FixButton.propTypes = {
  disablePopover: PropTypes.bool.isRequired,
  onAction: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  tooltip: PropTypes.string.isRequired,
  tooltipOffsetTop: PropTypes.number,
};

FixButton.defaultProps = {
  tooltipOffsetTop: 0,
};

export default FixButton;
