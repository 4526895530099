import React from 'react';
import PropTypes from 'prop-types';
import { FaTrashAlt } from 'react-icons/fa';
import { Button } from '../../../../library/atoms/Buttons';

const DeleteButton = ({ isDisabled, onClick, ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button disabled={isDisabled} onClick={onClick} {...props}>
      <FaTrashAlt />
      <span>Delete</span>
    </Button>
  );
};

DeleteButton.propTypes = {
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

DeleteButton.defaultProps = {
  isDisabled: false,
};

export default DeleteButton;
