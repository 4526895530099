import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import Page, { PageLoading } from '../Page';
import DocumentToolbarContainer from '../UI/DocumentToolbar/DocumentToolbarContext';
import { Toolbar, DocView, SplitView, ConfirmModal } from './components';
import {
  fetchTask,
  saveUserAnnotationData,
  deleteTasks,
} from '../../reducers/tasks';
import {
  goToNextTaskUsingStoredFilters,
  deleteTaskAnnotations,
} from '../../reducers/nextTask';
import './DocSplit.scss';
import {
  getAnnotationStructure,
  getInitialData,
  getChildDocName,
} from './helper';
import { TaskTypeLookup } from '../../common/lookups';

const mapStateToProps = state => {
  const { tasks, auth, upload } = state;
  const user = auth.user.data;
  const { doc, predictions, task } =
    tasks.active && tasks.active.data ? tasks.active.data : {};
  const { error } = tasks.active || {};
  const { filename, received, images } = doc || {};
  const { numOfPages } = received || {};
  return {
    companyId: user ? user.companyId : null,
    doc,
    predictions,
    task,
    filename,
    numOfPages,
    userId: user ? user.id : null,
    images,
    error,
    upload,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      fetchTask: taskId => dispatch(fetchTask(taskId)),
      saveUserAnnotationData: (taskId, userId, data, annotationState) =>
        dispatch(saveUserAnnotationData(taskId, userId, data, annotationState)),
      deleteTasks: tasks => dispatch(deleteTasks(tasks)),
      deleteTaskAnnotations: taskId => dispatch(deleteTaskAnnotations(taskId)),
      goToNextTaskUsingStoredFilters: () =>
        dispatch(goToNextTaskUsingStoredFilters()),
    },
  };
};

const SAVE_SPLIT = 'SAVE_SPLIT';
const DISCARD_SPLIT = 'DISCARD_SPLIT';

const DocSplit = ({
  doc,
  images,
  predictions,
  task,
  filename,
  numOfPages,
  companyId,
  userId,
  dispatch,
  match: {
    params: { taskId },
  },
  error,
  upload,
}) => {
  const [state, setState] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [splitAction, setSplitAction] = useState(null);
  const noSplitSpecified = state ? !!state.noSplitSpecified : true;
  /**
   * Race condition occurs when user has just uploaded the document and
   * clicks 'Review now'. The document could still be processing.
   *
   * There's probably a better solution but for a quick fix we hold some redux state
   * which updates processed flag to true when results-service notifies when docId has
   * been finalised via pusher notificaiton.
   *
   */
  const docHasProcessed =
    taskId !== upload.active.taskId || upload.active.processed;
  const isManualSplit = task?.taskType === TaskTypeLookup.FIX;
  const userAnnotation =
    task && userId
      ? task.annotations.find(a => a.submitterId === userId)
      : null;
  const hasSubmittedSplit =
    userAnnotation && userAnnotation.state === 'submitted';

  useEffect(() => {
    if (taskId && task && !error) {
      const annotation = (task.annotations || []).find(
        a => a.submitterId === userId,
      );
      setState(
        getInitialData({
          predictions,
          numOfPages,
          filename,
          annotation,
          images,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId, task]);

  useEffect(() => {
    if (companyId && taskId && docHasProcessed) {
      dispatch.fetchTask(taskId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, taskId, docHasProcessed]);

  const addSplit = (childDoc, index) => {
    const pagesToStay = [...childDoc.pages].splice(0, index + 1);
    const pagesToMove = [...childDoc.pages].filter(
      t => !pagesToStay.includes(t),
    );
    const newChildDocId = `childDoc_${uuid()}`;

    const newChildDoc = {
      id: newChildDocId,
      pages: pagesToMove,
    };

    const prevDoc = {
      ...childDoc,
      pages: pagesToStay,
    };

    const childDocs = {
      ...state.childDocs,
      [newChildDocId]: newChildDoc,
      [childDoc.id]: prevDoc,
    };

    const order = [...state.order];
    const currIndex = order.findIndex(c => c === childDoc.id);

    // Insert new child document in list after current childDoc
    order.splice(currIndex + 1, 0, newChildDocId);

    // Rename all the child pages according to their updated index
    // eslint-disable-next-line no-shadow
    order.forEach((docId, index) => {
      const docTitle = getChildDocName(filename, index + 1);
      childDocs[docId] = {
        ...childDocs[docId],
        title: docTitle,
      };
    });

    setState(s => ({
      ...s,
      childDocs,
      order,
      noSplitSpecified: Object.keys(childDocs).length <= 1,
    }));
  };

  const removeSplit = (prevDoc, docToDelete) => {
    const element = document.getElementById(docToDelete.id);
    element.classList.add('container-removed'); // add transition

    setTimeout(() => {
      const pagesToMove = [...docToDelete.pages];
      const newPages = [...prevDoc.pages, ...pagesToMove];
      const newDoc = {
        ...prevDoc,
        pages: newPages,
      };
      const childDocs = { ...state.childDocs };
      childDocs[prevDoc.id] = newDoc;
      delete childDocs[docToDelete.id];
      Object.keys(childDocs).forEach((docId, index) => {
        const docTitle = getChildDocName(filename, index + 1);
        childDocs[docId] = {
          ...childDocs[docId],
          title: docTitle,
        };
      });
      const order = Object.keys(childDocs);
      setState(s => ({
        ...s,
        order,
        childDocs,
        noSplitSpecified: Object.keys(childDocs).length <= 1,
      }));
    }, 500); // delay deletion to display transition effect
  };

  /**
   * Update arrangement of pages for doc splitting when user
   * changes position of a child-doc-page.
   * @param {*} result
   */
  const onDragEnd = result => {
    const { source, destination, draggableId } = result;

    // If user dragged a page out of bounds
    if (!destination) {
      return;
    }

    // If user dropped page in same place as before
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = state.childDocs[source.droppableId];
    const finish = state.childDocs[destination.droppableId];

    // Starting position and finishing position are on the same child doc
    if (start.id === finish.id) {
      const startPages = [...start.pages];
      startPages.splice(source.index, 1);
      startPages.splice(destination.index, 0, draggableId);
      const childDocs = {
        ...state.childDocs,
        [start.id]: {
          ...start,
          pages: startPages,
        },
      };
      setState(s => ({
        ...s,
        childDocs,
      }));
      return;
    }

    const startPages = [...start.pages];
    startPages.splice(source.index, 1);
    const newStart = {
      ...start,
      pages: startPages,
    };
    const finishPages = [...finish.pages];
    finishPages.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      pages: finishPages,
    };

    const childDocs = {
      ...state.childDocs,
      [newStart.id]: newStart,
      [newFinish.id]: newFinish,
    };
    let order = [...state.order];

    if (newStart.pages.filter(p => !finishPages.includes(p)).length === 0) {
      delete childDocs[newStart.id];

      Object.keys(childDocs).forEach((docId, index) => {
        const docTitle = getChildDocName(filename, index + 1);
        childDocs[docId] = {
          ...childDocs[docId],
          title: docTitle,
        };
      });
      order = Object.keys(childDocs);
    }
    setState(s => ({
      ...s,
      childDocs,
      order,
      noSplitSpecified: Object.keys(childDocs).length <= 1,
    }));
  };

  const onDiscardSplit = () => {
    dispatch.deleteTaskAnnotations(taskId).then(() => {
      dispatch.deleteTasks([taskId]).then(() => {
        setModalOpen(false);
        if (isManualSplit) {
          window.location = '/documents';
        } else {
          dispatch.goToNextTaskUsingStoredFilters();
        }
      });
    });
  };

  const onSaveSplit = () => {
    const annotation = getAnnotationStructure({
      ...state,
      task,
      userId,
      companyId,
      predictions,
    });

    dispatch
      .saveUserAnnotationData(taskId, userId, annotation, 'submitted')
      .then(() => {
        setModalOpen(false);
        if (isManualSplit) {
          window.location = '/documents';
        } else {
          dispatch.goToNextTaskUsingStoredFilters();
        }
      });
  };

  const onCancelModal = () => {
    setModalOpen(false);
  };

  return (
    <DocumentToolbarContainer>
      <Page>
        {/* eslint-disable no-nested-ternary */}
        {state && doc && docHasProcessed && task ? (
          <>
            <Toolbar
              doc={doc}
              noSplitSpecified={noSplitSpecified}
              hasSubmittedSplit={hasSubmittedSplit}
              enableFitToView={false}
              variant={isManualSplit ? 'manual' : 'HITL'}
              onDiscard={() => {
                setSplitAction(DISCARD_SPLIT);
                setModalOpen(true);
              }}
              onSave={() => {
                setSplitAction(SAVE_SPLIT);
                setModalOpen(true);
              }}
            />
            <div className="doc-split-view__wrapper">
              <SplitView
                childDocs={state.childDocs}
                order={state.order}
                pages={state.pages}
                onDragEnd={onDragEnd}
                removeSplit={removeSplit}
                addSplit={addSplit}
              />
              <DocView doc={doc} companyId={companyId} />
            </div>
          </>
        ) : error ? (
          <Redirect to="/404" />
        ) : (
          <PageLoading />
        )}
        {isModalOpen && (
          <ConfirmModal
            isOpen={isModalOpen}
            filename={filename}
            onCancel={onCancelModal}
            onConfirm={() => {
              if (splitAction === SAVE_SPLIT) {
                onSaveSplit();
                return;
              }
              onDiscardSplit();
            }}
            isDiscard={splitAction === DISCARD_SPLIT}
            numDocs={Object.keys(state.childDocs).length}
          />
        )}
      </Page>
    </DocumentToolbarContainer>
  );
};

DocSplit.defaultProps = {
  doc: null,
  predictions: null,
  task: null,
  filename: null,
  numOfPages: null,
  companyId: null,
  userId: null,
  dispatch: {
    fetchTask: () => {},
    saveUserAnnotationData: () => {},
    deleteTasks: () => {},
    deleteTaskAnnotations: () => {},
    goToNextTaskUsingStoredFilters: () => {},
  },
  match: null,
  images: [],
  error: null,
  upload: {
    active: {
      docId: null,
      taskId: null,
      processed: false,
    },
  },
};

DocSplit.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  doc: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  predictions: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  task: PropTypes.object,
  filename: PropTypes.string,
  numOfPages: PropTypes.string,
  companyId: PropTypes.string,
  userId: PropTypes.string,
  dispatch: PropTypes.shape({
    fetchTask: PropTypes.func,
    saveUserAnnotationData: PropTypes.func,
    deleteTasks: PropTypes.func,
    deleteTaskAnnotations: PropTypes.func,
    goToNextTaskUsingStoredFilters: PropTypes.func,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  images: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  upload: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocSplit);
