/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Fragment } from 'react';
import { FaTags } from 'react-icons/fa';
import './Task.scss';
import Radio from '../UI/Radio/Radio';
import FieldAnnotationBase from './FieldAnnotationBase';
import Dropdown from '../UI/Dropdown/Dropdown';

export default class OptionFieldAnnotation extends FieldAnnotationBase {
  getDefaultAnnotationValue = () => {
    const { field } = this.props;
    const value = {};
    (field.options || []).forEach(o => (value[o.id] = false));
    return value;
  };

  getAnnotationStateValue = () => {
    const { annotation } = this.state;
    const value = this.getDefaultAnnotationValue();
    if (annotation) {
      return Object.assign(value, annotation.data.value);
    }
    return value;
  };

  getAnnotationSource = () => {
    const { field } = this.props;
    return {
      type: 'option',
      fieldType: field.type,
      inputType: this.useDropdownControl() ? 'dropdown' : 'button',
    };
  };

  getPredictionConfidence = k => {
    const { prediction } = this.props;
    const p = prediction ? Object.values(prediction)[0] : null;
    const predVal = p && p.value && p.value[k] ? p.value[k] : null;
    const confidence = predVal ? Math.floor(predVal.confidence * 100) : null;
    return confidence;
  };

  useDropdownControl = () => {
    const { field } = this.props;
    return field.options && field.options.length > 5;
  };

  onChangeOption = (opt, val) => {
    const { field } = this.props;

    const value =
      field.type == 'multilabel'
        ? this.getAnnotationStateValue()
        : this.getDefaultAnnotationValue();

    value[opt] = val;
    this.setState({
      annotation: {
        source: this.getAnnotationSource(),
        data: {
          value,
        },
      },
    });

    // reset prediction focus
    this.props.setPredictionFocus(null);
  };

  clearDropdown = () => {
    this.setState({
      annotation: {
        source: this.getAnnotationSource(),
        data: {
          value: this.getDefaultAnnotationValue(),
        },
      },
    });
  };

  handleDropdown = (selected, event) => {
    const { field } = this.props;
    const { annotation } = this.state;
    const { action } = event;

    if (action === 'clear') {
      this.clearDropdown();
      return;
    }

    if (action === 'remove-value') {
      const newState = this.getAnnotationStateValue();
      newState[event.removedValue.value] = false;
      this.setState({
        annotation: Object.assign(annotation, {
          data: {
            value: newState,
          },
        }),
      });
      return;
    }

    const { value } = selected;
    const newState = this.getDefaultAnnotationValue();
    if (field.type !== 'multilabel') {
      newState[value] = true;
      this.setState({
        annotation: {
          source: this.getAnnotationSource(),
          data: {
            value: newState,
          },
        },
      });
    } else if (field.type === 'multilabel') {
      selected.forEach(option => {
        newState[option.value] = true;
      });
      this.setState({
        annotation: {
          source: this.getAnnotationSource(),
          data: {
            value: newState,
          },
        },
      });
    }

    // reset prediction focus
    this.props.setPredictionFocus(null);
  };

  onClearManualSelection = e => {
    this.setState({
      annotation: {
        source: this.getAnnotationSource(),
        data: {
          value: {},
        },
      },
    });
  };

  onConfirmManualSelection = e => {
    if (e) {
      e.preventDefault();
    }
    this.saveAnnotation(this.state.annotation);
  };

  // custom dropdown for field confidence
  customDropdownLabel = ({ label, confidence }) => {
    return (
      <div className="label-category">
        <span className="label-title">{label}</span>
        {confidence !== null && (
          <span className="label-confidence">{confidence}%</span>
        )}
      </div>
    );
  };

  render() {
    const { field, editable, prediction } = this.props;
    const enableMultipleSelect = field.type == 'multilabel';
    const annotation =
      !this.props.annotation || this.state.edit
        ? this.state.annotation
        : this.props.annotation;
    const editMode = this.isEditMode();
    const manualSelectionMode = editMode;
    const hasPredictions = !!prediction;

    const enableSubmit =
      annotation &&
      (enableMultipleSelect ||
        Object.values(annotation.data.value).some(v => v));

    const optionNamesById = {};
    (field.options || []).map(o => (optionNamesById[o.id] = o.title));

    const selectedValues = annotation
      ? Object.keys(annotation.data.value).filter(k => annotation.data.value[k])
      : null;

    const dropdownvalue = Object.entries(this.getAnnotationStateValue())
      .filter(kv => kv[1])
      .map(kv => ({
        label: (field.options.find(o => o.id == kv[0]) || {}).title || kv[0],
        value: kv[0],
        confidence: hasPredictions ? this.getPredictionConfidence(kv[0]) : null,
      }));

    const sortConfidence = (a, b) => {
      if (hasPredictions) {
        return a.confidence < b.confidence
          ? 1
          : b.confidence < a.confidence
          ? -1
          : 0;
      }
    };

    const options = this.useDropdownControl()
      ? field.options
          .map(o => {
            return {
              label: o.title,
              value: o.id,
              confidence: hasPredictions
                ? this.getPredictionConfidence(o.id)
                : null,
            };
          })
          .sort(sortConfidence)
      : [];

    return (
      <div
        ref={r => (this.ref = r)}
        className="field-value-instance"
        tabIndex="0"
      >
        {!editMode && annotation ? (
          <div className="field-value">
            <div className="selected-options">
              <FaTags />
              {selectedValues.length > 0
                ? selectedValues
                    .map(k => (k in optionNamesById ? optionNamesById[k] : k))
                    .map((title, i) => <span key={i}>{title}</span>)
                : 'N/A'}
            </div>
            {editable ? (
              <div className="button-div edit" onClick={this.editAnnotation}>
                <span>Edit</span>
              </div>
            ) : null}
          </div>
        ) : null}
        {manualSelectionMode ? (
          <div
            ref={r => setTimeout(() => (r ? r.focus() : null), 0)}
            className="field-input option-select"
          >
            {this.useDropdownControl() ? (
              <Dropdown
                formatOptionLabel={this.customDropdownLabel}
                className="annotator-select-container"
                classNamePrefix="react-select"
                isClearable={false}
                isMulti={enableMultipleSelect}
                closeMenuOnSelect={!enableMultipleSelect}
                options={options}
                onChange={this.handleDropdown}
                value={dropdownvalue}
                placeholder="Select an option"
              />
            ) : (
              <>
                <span className="label">
                  {enableMultipleSelect
                    ? 'Select all applicable options'
                    : 'Select an option'}
                </span>
                <ul>
                  {(field.options || []).map((o, i) => (
                    <li
                      key={i}
                      data-tooltip-html={`<div class="field-hint">${o.description}</div>`}
                      data-tooltip-place="right"
                      data-tooltip-id="task-tooltip"
                    >
                      <Radio
                        label={o.title}
                        onChange={v => this.onChangeOption(o.id, v)}
                        value={
                          annotation &&
                          annotation.data &&
                          o.id in annotation.data.value
                            ? annotation.data.value[o.id]
                            : false
                        }
                      />
                    </li>
                  ))}
                </ul>
              </>
            )}
            <form onSubmit={this.onConfirmManualSelection}>
              <div
                className="button-div reset"
                onClick={
                  field.options && field.options.length > 5
                    ? this.clearDropdown
                    : this.onClearManualSelection
                }
              >
                <span>Clear selections</span>
              </div>
              <button
                type="submit"
                className={enableSubmit ? '' : 'disabled'}
                disabled={!enableSubmit}
              >
                Confirm
              </button>
            </form>
          </div>
        ) : null}
      </div>
    );
  }
}
