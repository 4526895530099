import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import history from '../../services/historyService';
import {
  updateUserProfile,
  requestOpenTour,
  openPlatformTourPanelForOnboarding,
} from '../../reducers/authReducer';

import OnboardingForm from './OnboardingForm';
import { ONBOARDING_TYPE, API_DOCS_URL } from '../../common/constants';
import './Onboarding.scss';

const mapStateToProps = state => {
  const { auth } = state;
  const userData = auth.user.data;

  return {
    isOnboarded: userData ? userData.onboarded : null,
    history,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      updateUserProfile: userProfile =>
        dispatch(updateUserProfile(userProfile)),
      openTour: () => {
        dispatch(requestOpenTour());
        dispatch(openPlatformTourPanelForOnboarding());
      },
    },
  };
};

const Onboarding = ({ isOnboarded, dispatch }) => {
  const [isModalOpen, setModalOpen] = useState(!isOnboarded);

  const handleCancel = () => {
    setModalOpen(false);
    dispatch.updateUserProfile({
      profileData: {
        firstUse: 'Skip / cancelled',
      },
      onboarded: true,
    });
  };

  const handleSubmit = (e, selectedOption) => {
    e.preventDefault();
    setModalOpen(false);

    dispatch.updateUserProfile({
      profileData: {
        firstUse: selectedOption,
      },
      onboarded: true,
    });

    if (selectedOption === ONBOARDING_TYPE.TOUR) {
      dispatch.openTour();
      return;
    }
    if (selectedOption === ONBOARDING_TYPE.API) {
      window.open(API_DOCS_URL, '_blank', 'noopener');
    }
  };

  return (
    isOnboarded === false && (
      <OnboardingForm
        onCancel={handleCancel}
        isOpen={isModalOpen}
        onSubmit={handleSubmit}
      />
    )
  );
};

Onboarding.propTypes = {
  isOnboarded: PropTypes.bool,
  dispatch: PropTypes.shape({
    openTour: PropTypes.func,
    updateUserProfile: PropTypes.func,
  }),
};

Onboarding.defaultProps = {
  isOnboarded: true,
  dispatch: {
    openTour: () => {},
    updateUserProfile: () => {},
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
