import React, { useState, useEffect, useRef } from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import PropTypes from 'prop-types';
import Modal from '../UI/Modal/Modal';
import Dropdown from '../UI/Dropdown/Dropdown';
import schema from './validate';
import { addTask, addBatchTask } from '../../reducers/tasks/actions';
import { SpecificationLookup } from '../../common/lookups';
import './AddTaskModal.scss';

export const ADD_TASK_MODES = {
  /**
   * Create a new task.
   */
  CREATE: 'create',
  /**
   * I think this is for when we edit an existing task.
   */
  EDIT: 'edit',
  /**
   * Create a sypht.split (aka "splitting") task.
   */
  CREATE_SPLIT: 'create:split',
};

const DROPDOWN_STYLE = {
  container: base => ({
    ...base,
    fontSize: '14px',
  }),
  control: base => ({
    ...base,
    marginTop: '10px',
    border: '1px solid #d1d5da',
    borderRadius: '4px',
    padding: '2px',
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none',
  }),
  input: base => ({
    ...base,
    padding: '0px',
  }),
  placeholder: base => ({
    ...base,
    fontFamily: 'Roboto, sans-serif',
    color: '#757575',
  }),
};

const REPLICATION_OPTIONS = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
];

const PRIORITY_OPTIONS = [
  { value: -1, label: 'Low' },
  { value: 0, label: 'Medium' },
  { value: 1, label: 'High' },
];

const mapStateToProps = state => {
  const { auth, docs } = state;
  const user = auth.user.data;
  return {
    userId: auth.userId,
    userCompanyId: user ? user.companyId : null,
    numDocs: docs.data ? docs.data.count : null,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    navigate: path => dispatch(replace(path)),
    dispatch: {
      addTask: t => dispatch(addTask(t)),
      addBatchTask: t => dispatch(addBatchTask(t)),
    },
  };
};

const AddTaskModal = ({
  mode,
  companyId,
  editTasks,
  navigate,
  selectedDocs,
  selectedTasks,
  specs,
  userId,
  filters,
  maxDocs,
  numDocs,
  isOpen,
  isSelectAll,
  onCancelModal,
  onCloseModal,
  dispatch,
}) => {
  const isEditMode = mode === ADD_TASK_MODES.EDIT;
  const isSplitMode = mode === ADD_TASK_MODES.CREATE_SPLIT;
  const [form, setForm] = useState({
    specification: isSplitMode
      ? {
          value: SpecificationLookup.DOCUMENT_SPLIT,
          label: SpecificationLookup.DOCUMENT_SPLIT,
        }
      : '',
    replication: { value: 1, label: '1' },
    priority: { value: 0, label: 'Medium' },
  });
  const [errorMessages, setErrorMessages] = useState({});
  const [selectedItems] = useState(
    isEditMode ? Object.keys(selectedTasks) : Object.keys(selectedDocs),
  );
  const specDropdownRef = useRef(null);

  const values = {
    specification: form.specification.value,
    replication: form.replication.value,
    priority: form.priority.value,
  };

  useEffect(() => {
    if (specDropdownRef.current) {
      specDropdownRef.current.select?.focus();
    }
  }, []);

  // eslint-disable-next-line react/prop-types
  const customLabel = ({ value, label }) => {
    return (
      <div>
        {label}
        <span className="label-description"> |{value}</span>
      </div>
    );
  };

  const onChange = (selected, event) => {
    const errors = { ...errorMessages };
    const newForm = { ...form };
    newForm[event.name] = { value: selected.value, label: selected.label };

    if (errors[`form.${event.name}`]) {
      errors[`form.${event.name}`] = '';
    }

    setErrorMessages(errors);
    setForm(newForm);
  };

  const onClose = () => {
    const isManualSplit =
      form.specification.value === SpecificationLookup.DOCUMENT_SPLIT &&
      isEditMode;
    onCloseModal(
      isEditMode ? 'isEditTasksModalOpen' : 'isTaskModalOpen',
      isManualSplit,
    );
  };

  const onCancel = () => {
    onCancelModal(isEditMode ? 'isEditTasksModalOpen' : 'isTaskModalOpen');
  };

  const addAllTasks = () => {
    schema
      .validate(values, { abortEarly: false })
      .then(() => {
        dispatch.addBatchTask({
          filters,
          specification: form.specification.value,
          replication: form.replication.value,
          priority: form.priority.value,
          companyId,
        });
      })
      .then(() => onClose())
      .catch(error => {
        const err = {};
        error.inner.forEach(e => {
          if (!err[e.path]) {
            err[e.path] = e.message;
          }
        });
        setErrorMessages(err);
      });
  };

  const addSelectedTasks = () => {
    schema
      .validate(values, { abortEarly: false })
      .then(() => {
        selectedItems.forEach(docId => {
          dispatch.addTask({
            docId,
            specification: form.specification.value,
            replication: form.replication.value,
            priority: form.priority.value,
            companyId,
          });
        });
      })
      .then(() => onClose())
      .catch(error => {
        const err = {};
        error.inner.forEach(e => {
          if (!err[e.path]) {
            err[e.path] = e.message;
          }
        });
        setErrorMessages(err);
      });
  };

  const onConfirm = () => {
    if (isEditMode) {
      editTasks(
        selectedItems,
        form.replication.value,
        form.priority.value,
      ).then(() => {
        onClose();
      });
    } else if (isSelectAll && filters) {
      addAllTasks();
    } else {
      addSelectedTasks();
    }
  };

  const onLaunch = () => {
    schema
      .validate(values, { abortEarly: false })
      .then(() => {
        const [docId] = selectedItems;
        dispatch
          .addTask({
            docId,
            specification: form.specification.value,
            replication: form.replication.value,
            priority: form.priority.value,
            companyId,
          })
          .then(res => {
            if (res.data && res.data.task) {
              const { id, specificationName } = res.data.task;
              let taskURL = `/tasks/${id}/user/${userId}`;
              if (specificationName === SpecificationLookup.DOCUMENT_SPLIT) {
                taskURL = `/documents/split/${id}/user/${userId}`;
              }
              navigate(taskURL);
            } else {
              onClose();
            }
          });
      })
      .catch(error => {
        const err = {};
        error.inner.forEach(e => {
          if (!err[e.path]) {
            err[e.path] = e.message;
          }
        });
        setErrorMessages(err);
      });
  };

  let noOfSelectedDocs = !isSelectAll ? selectedItems.length : numDocs;
  noOfSelectedDocs = noOfSelectedDocs < maxDocs ? noOfSelectedDocs : maxDocs;

  const calcModalLabel = () => {
    if (isSplitMode) {
      return `Add split task to ${noOfSelectedDocs} selected documents`;
    }
    if (isEditMode) {
      return `Modify ${noOfSelectedDocs} selected tasks`;
    }
    return `Add task to ${noOfSelectedDocs} selected documents`;
  };

  const calcLaunchTip = () => {
    if (isEditMode) {
      return null;
    }
    return selectedItems.length === 1
      ? 'Submit and Open task.'
      : 'Submit and open first task';
  };

  return (
    <Modal
      showCloseBtn
      isOpen={isOpen}
      includeLaunchBtn={!isEditMode && !isSelectAll}
      launchTip={calcLaunchTip()}
      onClose={onCancel}
      onCancel={onCancel}
      onLaunch={!isEditMode ? onLaunch : null}
      onConfirm={onConfirm}
      className="modal-task"
      confirmLabel={isEditMode ? 'Save' : 'Submit'}
      cancelLabel="Cancel"
      modalLabel={calcModalLabel()}
    >
      <div className="modal-task-content">
        <div className="modal-task-section">
          {isSplitMode || isEditMode ? (
            ''
          ) : (
            <Dropdown
              styles={DROPDOWN_STYLE}
              name="specification"
              placeholder="Select a Specification"
              label="Specification"
              value={form.specification}
              onChange={onChange}
              defaultValue={null}
              isClearable={false}
              formatOptionLabel={customLabel}
              options={
                specs
                  ? specs.map(s => ({
                      value: s.id,
                      label: s.fieldset_version.description,
                    }))
                  : []
              }
              errorMessage={errorMessages.specification}
              forwardRef={specDropdownRef}
            />
          )}
        </div>
        <div className="modal-task-section">
          <Dropdown
            placeholder="Select replication number"
            styles={DROPDOWN_STYLE}
            name="replication"
            label={
              isEditMode ? (
                <span className="task-label">
                  Replication{' '}
                  <FaExclamationTriangle
                    data-tooltip-content="Tasks with annotations greater than replication will not be reduced."
                    data-tooltip-id="modal"
                  />
                </span>
              ) : (
                'Replication'
              )
            }
            value={form.replication}
            defaultValue={1}
            onChange={onChange}
            options={REPLICATION_OPTIONS}
            errorMessage={errorMessages.replication}
          />
        </div>
        <div className="modal-task-section">
          <Dropdown
            placeholder="Select priority"
            styles={DROPDOWN_STYLE}
            isClearable={false}
            name="priority"
            label="Priority"
            value={form.priority}
            onChange={onChange}
            options={PRIORITY_OPTIONS}
            errorMessage={errorMessages.priority}
          />
        </div>
      </div>
    </Modal>
  );
};

AddTaskModal.propTypes = {
  mode: PropTypes.oneOf([
    ADD_TASK_MODES.CREATE,
    ADD_TASK_MODES.EDIT,
    ADD_TASK_MODES.CREATE_SPLIT,
  ]),
  companyId: PropTypes.string,
  editTasks: PropTypes.func,
  navigate: PropTypes.func,
  maxDocs: PropTypes.number,
  numDocs: PropTypes.number,
  selectedDocs: PropTypes.shape({}),
  selectedTasks: PropTypes.shape({}),
  specs: PropTypes.arrayOf(PropTypes.shape({})),
  userId: PropTypes.string,
  filters: PropTypes.shape({}),
  isOpen: PropTypes.bool.isRequired,
  isSelectAll: PropTypes.bool,
  onCancelModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  dispatch: PropTypes.shape({
    addTask: PropTypes.func,
    addBatchTask: PropTypes.func,
  }),
};

AddTaskModal.defaultProps = {
  mode: ADD_TASK_MODES.CREATE,
  companyId: null,
  editTasks: () => {},
  maxDocs: 1000,
  numDocs: 0,
  selectedDocs: {},
  selectedTasks: {},
  specs: [],
  userId: null,
  navigate: () => {},
  filters: null,
  isSelectAll: false,
  dispatch: {
    addTask: () => {},
    addBatchTask: () => {},
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTaskModal);
