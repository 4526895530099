import React, { useRef, useState } from 'react';
import { FilePond } from 'react-filepond';
import { FaCheck } from 'react-icons/fa';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { convertToBase64 } from '../../../../common/helpers';
import * as DemoService from '../../../../services/DemoService';

import Modal from '../../../UI/Modal/Modal';
import CaptureUploadSpinner from './CaptureUploadSpinner';
import 'filepond/dist/filepond.css';
import './_captureModal.scss';

export const uploadStatus = {
  IDLE: 'idle',
  UPLOADING: 'uploading',
  EXTRACTING: 'extracting',
  EXTRACTED: 'extracted',
};

const CaptureUpload = ({ product, status, isOpen, onClose, onUploaded }) => {
  const [isUploaded, setUploaded] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const filePondRef = useRef(null);
  const heading = 'Upload your document';
  const labelIdle =
    'Drag & drop to start uploading, or ' +
    '<span class="filepond--label-action">browse</span> ' +
    'to select files.';

  const handleFileUpload = (fieldName, file, metadata, load, error) => {
    setUploadedFile(file);
    convertToBase64(file).then(async result => {
      const fileUpload = {
        products: product.products,
        file: {
          name: file.name,
          base64: result.split(',')[1],
        },
        workflow: {
          id: 'prediction',
        },
      };

      // response: { fileId, uploadedAt, status }
      DemoService.uploadDoc(fileUpload)
        .then(response => {
          load(response);
          if (response.status === 'RECEIVED') {
            onUploaded(response);
            setUploaded(true);
          }
        })
        .catch(err => {
          console.error(err);
          error(err);
        });
    }); // end base64
  };

  const handleInit = () => {
    if (window.screen.width <= 1024) {
      filePondRef.current.browse();
    }
  };

  return (
    <Modal
      className={classNames('capture-modal', `capture-modal--${status}`)}
      cancelLabel={!isUploaded ? 'Cancel' : 'Done'}
      modalLabel={heading}
      onCancel={onClose}
      isOpen={isOpen}
    >
      <div className="modal__body">
        <div className="capture-modal__file">
          <FilePond
            allowMultiple={false}
            labelIdle={labelIdle}
            maxFiles={1}
            server={{
              timeout: 10000,
              process: handleFileUpload,
            }}
            ref={filePondRef}
            oninit={handleInit}
            onupdatefiles={() => {}}
          />
          <div className="extract-status">
            <div className="extract-status__inner">
              <div className="extract-status__text">
                {uploadedFile && uploadedFile.name}
                &nbsp;
                <div className="extract-status__text-sub">
                  {uploadedFile &&
                    `${Math.round(
                      (uploadedFile ? uploadedFile.size : 0) / 1024,
                    )} KB`}
                  &nbsp;
                </div>
              </div>
              {
                {
                  [uploadStatus.EXTRACTING]: (
                    <div className="extract-status__info">
                      <div className="extract-status__info-text">
                        {status.toTitleCase()}
                        ...
                        <div className="extract-status__info-text-sub">
                          Processing {product.name.toLowerCase()}
                        </div>
                      </div>
                      <div className="extract-status__info-circle">
                        <CaptureUploadSpinner />
                      </div>
                    </div>
                  ),
                  [uploadStatus.EXTRACTED]: (
                    <div className="extract-status__info">
                      <div className="extract-status__info-text">
                        Extraction complete
                        <div className="extract-status__info-text-sub">
                          Loading document...
                        </div>
                      </div>
                      <div className="extract-status__info-circle">
                        <FaCheck />
                      </div>
                    </div>
                  ),
                }[status]
              }
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

CaptureUpload.propTypes = {
  product: PropTypes.shape({
    products: PropTypes.arrayOf(PropTypes.string),
    productType: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  status: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onUploaded: PropTypes.func,
};

CaptureUpload.defaultProps = {
  onClose: () => {},
  onUploaded: () => {},
};

export default CaptureUpload;
