import { addError, removeError } from './index';

const validateAbn = ({ elem, errors, setErrors }) => {
  const { name: key, value } = elem;
  const message = `Invalid email address.`;

  if (value.length === 0) {
    removeError(key, message, errors, setErrors);
    return {
      isValid: true,
    };
  }

  // test: 53 004 085 616
  const isValidAbn = abnStr => {
    const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
    const abn = abnStr.replace(/[^\d]/, '');
    if (abn.length !== 11) return false;

    let sum = 0;
    for (let i = 0; i < weights.length; i++) {
      sum += (Number(abn[i]) - (i === 0 ? 1 : 0)) * weights[i];
    }
    return sum % 89 === 0;
  };

  if (isValidAbn(value)) {
    addError(key, message, errors, setErrors);
    return {
      isValid: false,
    };
  }

  removeError(key, message, errors, setErrors);
  return {
    isValid: true,
  };
};

export default validateAbn;
