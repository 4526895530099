import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FaCheckCircle, FaMinusCircle } from 'react-icons/fa';
import { usePasswordValidator } from '../Form';

const PasswordFormCheck = ({ password }) => {
  const validator = usePasswordValidator(password);

  const validLengthClass = classNames('password-check__item', {
    'password-check__item--valid': validator.hasValidLength,
  });

  const numericClass = classNames('password-check__item', {
    'password-check__item--valid': validator.hasNumeric,
  });

  const uppercaseClass = classNames('password-check__item', {
    'password-check__item--valid': validator.hasUppercase,
  });

  const lowercaseClass = classNames('password-check__item', {
    'password-check__item--valid': validator.hasLowercase,
  });

  return (
    <div className="password-check">
      To ensure your account is secure, a password must:
      <ul className="password-check__list">
        <li className={validLengthClass}>
          {!validator.hasValidLength ? <FaMinusCircle /> : <FaCheckCircle />}
          Include 8 or more characters
        </li>
        <li className={numericClass}>
          {!validator.hasNumeric ? <FaMinusCircle /> : <FaCheckCircle />}
          Contain at least 1 numeric character
        </li>
        <li className={uppercaseClass}>
          {!validator.hasUppercase ? <FaMinusCircle /> : <FaCheckCircle />}
          Contain at least 1 uppercase letter
        </li>
        <li className={lowercaseClass}>
          {!validator.hasLowercase ? <FaMinusCircle /> : <FaCheckCircle />}
          Contain at least 1 lowercase letter
        </li>
      </ul>
    </div>
  );
};

PasswordFormCheck.propTypes = {
  password: PropTypes.string.isRequired,
};

export default PasswordFormCheck;
