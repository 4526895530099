const addError = (key, message, errors, setErrors) => {
  if (!errors) return;
  const errorsList = (errors[key] || []).slice();
  const errorIndex = errorsList.indexOf(message);
  if (errorIndex === -1) {
    errorsList.push(message);
    setErrors({
      ...errors,
      [key]: errorsList,
    });
  }
};

export default addError;
