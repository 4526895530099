import AuthService from './AuthServiceInternal';
import AUTH_CONFIG from './auth0-variables';
import { ScopeLookup } from '../common/lookups';

// Configuration and initiate Singleton
export default new AuthService({
  domain: AUTH_CONFIG.domain,
  clientID: AUTH_CONFIG.clientId,
  redirectUri: AUTH_CONFIG.callbackUrl,
  dbConnectionName: AUTH_CONFIG.dbConnectionName,
  returnTo: AUTH_CONFIG.returnTo,
  audience: AUTH_CONFIG.audience,
  scope: Object.values(ScopeLookup)
    .map(scope => scope.key)
    .join(' '),
});
