export const DelimiterOptions = [
  {
    label: 'Comma ( , )',
    value: 'comma',
  },
  {
    label: 'Colon ( : )',
    value: 'colon',
  },
  {
    label: 'Tab',
    value: 'tab',
  },
];

export const HeaderLayoutOptions = [
  {
    label: 'Collapsed',
    value: 'collapsed',
  },
  {
    label: 'Expanded',
    value: 'expanded',
  },
];

export const LineItemsLayoutOptions = [
  {
    label: 'Collapsed',
    value: 'collapsed',
  },
  {
    label: 'Expanded',
    value: 'expanded',
  },
  {
    label: 'Separated',
    value: 'separated',
  },
];
