import React from 'react';
import PropTypes from 'prop-types';
import { getConfidence } from '../../../../../common/helpers';

const TextField = ({ field, row }) => {
  if (typeof field.value !== 'string') {
    console.warn(
      `Field: "[${row}]${field.id}" of type: "${field.type}" not ` +
        `processed. Value "${field.value}" is not a string.'`,
    );
  }

  return typeof field.value === 'string' ? (
    <li
      className="capture-field"
      data-row={row}
      data-confidence={getConfidence(field.confidence_norm).label}
    >
      <div className="capture-field__inner">
        <div className="capture-field__field">
          <div className="capture-field__field-row">
            <span className="capture-field__field-label">
              {field.fieldName.unCamelCase()}
            </span>
            {field.value.length <= 32 ? (
              <span className="capture-field__field-value">{field.value}</span>
            ) : (
              <span
                className="capture-field__field-long-value"
                title={field.value}
              >
                {field.value}
              </span>
            )}
          </div>
        </div>
      </div>
    </li>
  ) : null;
};

TextField.propTypes = {
  field: PropTypes.object.isRequired,
  row: PropTypes.number,
};

TextField.defaultProps = {
  row: null,
};

export default TextField;
