import React from 'react';
import ReactSelect from 'react-select';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FaInfoCircle } from 'react-icons/fa';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import './SelectInput.scss';

const SelectInput = ({
  id,
  tip,
  label,
  options,
  errors,
  name,
  value,
  onBlur,
  onChange,
  ...props
}) => {
  const inputId = id || name;
  const errorText = errors[name] ? errors[name][errors[name].length - 1] : null;

  const handleBlur = (e, x, fieldName) => {
    e.target.setAttribute('name', fieldName);
    e.target.setAttribute('value', value);
    onBlur(e);
  };

  const handleChange = selectedOption => {
    onChange({
      target: {
        name,
        value: selectedOption.value,
        type: 'text',
        checked: false,
      },
    });
  };

  return (
    <div
      className={classNames('form__group', 'form__group--select', {
        'form__group--error': !!errors[name],
      })}
    >
      {label ? (
        <label className="form__select-label" htmlFor={inputId}>
          {label}
          &nbsp;
          {tip && (
            <FaInfoCircle
              data-tooltip-id="select-input-tooltip"
              data-tooltip-place="top"
              data-tooltip-html={tip}
            />
          )}
        </label>
      ) : null}
      <ReactSelect
        inputId={inputId}
        name={name}
        classNamePrefix="select"
        className="form__select select"
        options={options}
        value={options.find(c => c.value === value)}
        onBlur={(e, x) => handleBlur(e, x, name)}
        onChange={handleChange}
        clearable
        // menuIsOpen
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      {errorText ? (
        <span className="form__input-error">{errorText}</span>
      ) : null}
      <ReactTooltip id="select-input-tooltip" className="ReactTooltip" />
    </div>
  );
};

SelectInput.propTypes = {
  id: PropTypes.string,
  tip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  errors: PropTypes.shape({}),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

SelectInput.defaultProps = {
  id: null,
  tip: null,
  errors: {},
  label: PropTypes.string,
  options: [],
  value: null,
  onBlur: () => {},
  onChange: () => {},
};

export default SelectInput;
