import React from 'react';
import PropTypes from 'prop-types';
import iconWarning from '../../assets/icon-warning.svg';

const CredentialInfo = ({ legacy }) => {
  return (
    <div className="credential__info">
      {legacy && (
        <div className="credential__info-legacy">
          <img
            className="notification-icon"
            src={iconWarning}
            alt="notification icon"
          />
          <p className="credential__info-message">
            Legacy credential. Please update your code to migrate to the new
            credentials and authentication endpoint. For more information please
            refer to our{' '}
            <a
              href="https://docs.sypht.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Developer documentation
            </a>
          </p>
        </div>
      )}
    </div>
  );
};

CredentialInfo.propTypes = {
  legacy: PropTypes.bool.isRequired,
};

export default CredentialInfo;
