import { addError, removeError } from './index';

const validatePhone = ({ elem, errors, setErrors }) => {
  const { name: key, value } = elem;
  const message = `Invalid phone number.`;

  if (value.length === 0) {
    removeError(key, message, errors, setErrors);
    return {
      isValid: true,
    };
  }

  // prettier-ignore
  const isValid = phoneNumber => {
    // check australian phone number
    if (/^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/.test(phoneNumber)) {
      return true;
    }

    // check international number
    if (/^(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s.]?[(]?[0-9]{1,3}[)]?([-\s.]?[0-9]{3})([-\s.]?[0-9]{3,4})$/g.test(phoneNumber)) {
      return true;
    }

    // check usa number
    return /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s-]?[\0-9]{3}[\s-]?[0-9]{4}$/g.test(phoneNumber);
  };

  if (!isValid(value)) {
    addError(key, message, errors, setErrors);
    return {
      isValid: false,
    };
  }

  removeError(key, message, errors, setErrors);
  return {
    isValid: true,
  };
};

export default validatePhone;
