import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FaChevronDown } from 'react-icons/fa';
import classNames from 'classnames';
import './StateFilter.scss';
import Menu from './Menu';

const StateFilter = ({ applyStateFilters, filters }) => {
  const [open, setOpen] = useState(false);
  const dropRef = useRef(null);
  const offset = useRef(null);
  const btnRef = useRef(null);
  const [input, setInput] = useState(
    <span className="state__filter--input__text">Document State</span>,
  );
  useEffect(() => {
    if (dropRef.current)
      offset.current = dropRef.current.getBoundingClientRect();
    window.addEventListener('resize', () => {
      if (dropRef.current)
        offset.current = dropRef.current.getBoundingClientRect();
    });
  }, []);

  return (
    <div className="state__filter--container" ref={dropRef}>
      <div
        ref={btnRef}
        className={classNames('state__filter--input', {
          active: open,
        })}
        onClick={() => setOpen(!open)}
      >
        {input}
        <FaChevronDown className="state__filter--input__icon" />
      </div>
      <Menu
        isOpen={open}
        offset={offset.current}
        setOpen={setOpen}
        btnRef={btnRef}
        disableItemClose
        setInput={setInput}
        filters={filters}
        applyStateFilters={applyStateFilters}
      />
    </div>
  );
};

StateFilter.propTypes = {
  applyStateFilters: PropTypes.func,
  filters: PropTypes.object,
};

StateFilter.defaultProps = {
  applyStateFilters: () => {},
  filters: {},
};

export default StateFilter;
