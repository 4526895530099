import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import InvoiceImage from '../../assets/images/demo-insights/invoice.png';
import Chart from '../../assets/images/demo-insights/chart.png';
import SpendGraph from '../../assets/images/demo-insights/spend-graph.png';
import SpendGraphLegend from '../../assets/images/demo-insights/spend-graph-legend.png';
import './Insights.scss';
import { getQueryStr } from '../../common/helpers';

const mapStateToProps = (state, props) => {
  const { history } = props;
  const bgColour = getQueryStr(
    decodeURIComponent(history.location.search),
    'bgColour',
  );
  return {
    bgColour,
  };
};

const Insights = ({ bgColour }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    document.body.classList.add('demo-bg');
    return () => {
      document.body.classList.remove('demo-bg');
    };
  }, []);
  const onLoad = () => {
    if (containerRef && containerRef.current) {
      window.parent.postMessage(
        { iframe: 'insights', height: containerRef.current.scrollHeight },
        '*',
      );
    }
  };
  window.addEventListener('message', event => {
    if (event.data === 'getHeight') {
      window.parent.postMessage(
        { iframe: 'insights', height: containerRef.current.scrollHeight },
        '*',
      );
    }
  });
  return (
    <div ref={containerRef} className="insights__ref">
      <Helmet>
        <title>Insights AI Demo - Sypht App</title>
        <link rel="canonical" href={`${process.env.URL_WWW}/ai-products`} />
      </Helmet>
      <div className="insights insights--demo">
        <div className="insights__header">
          <ul className="insights__nav">
            <li className="insights__nav-item insights__nav-item--active">
              <span className="insights__nav-item__subtitle">Coming Soon</span>
              <span className="insights__nav-item__title">Spend Insights</span>
            </li>
            <li className="insights__nav-item">
              <span className="insights__nav-item__subtitle">Coming Soon</span>
              <span className="insights__nav-item__title">Fraud Insights</span>
            </li>
          </ul>
        </div>
        <div className="insights__body">
          <div
            className={classNames('insights__main', {
              'white-bg': bgColour === 'white',
            })}
          >
            <img src={InvoiceImage} alt="invoice" onLoad={onLoad} />
            <p className="heading-3">Evaluating 4 Invoices</p>
          </div>
          <div className="insights__aside">
            <div className="insights-data">
              <div className="insights-data__header">
                <h2 className="insights-data__heading">Extracted insights</h2>
              </div>
              <div className="insights-data__body">
                <div className="insights-data__panel">
                  <p className="insights-data__panel-heading">
                    Spend Categorisation
                  </p>
                  <img
                    src={SpendGraph}
                    alt="spend-graph"
                    width="387"
                    onLoad={onLoad}
                  />
                  <img
                    src={SpendGraphLegend}
                    alt="spend-graph-legend"
                    width="381"
                    onLoad={onLoad}
                  />
                  <p className="insights-data__panel-heading">Spend Trends</p>
                  <img src={Chart} alt="chart" width="385" onLoad={onLoad} />
                  <p className="caption">Illustrative example</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Insights.propTypes = {};

Insights.defaultProps = {};

export default connect(mapStateToProps)(Insights);
