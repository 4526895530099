import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FaPlay } from 'react-icons/fa';
import PropTypes from 'prop-types';

import { fetchNextTask, goToNextTask } from '../../../../reducers/nextTask';
import { Button } from '../../../../library/atoms/Buttons';
import './_nextTaskButton.scss';

const mapStateToProps = state => {
  const { auth, nextTask } = state;
  return {
    nextTask: nextTask?.data,
    user: auth.user.data,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch: {
    fetchNextTask: payload => dispatch(fetchNextTask(payload)),
    goToNextTask: payload => dispatch(goToNextTask(payload)),
  },
});

const NextTaskButton = ({ filters, nextTask, dispatch, sortBy }) => {
  const hasNextTask = nextTask && Number(nextTask.numRemaining);

  // Update nrxt task data as the user changes filters...
  useEffect(() => {
    dispatch.fetchNextTask({ filters, sortBy });
  }, [dispatch, filters, sortBy]);

  const handleClick = () => {
    dispatch.goToNextTask({ filters, sortBy });
  };

  return (
    <Button
      type="button"
      className="next-task-button"
      disabled={!hasNextTask}
      onClick={handleClick}
    >
      {hasNextTask ? (
        <>
          <FaPlay />
          <span>{nextTask.numRemaining} tasks to do</span>
        </>
      ) : (
        <span>No pending tasks</span>
      )}
    </Button>
  );
};

NextTaskButton.propTypes = {
  filters: PropTypes.shape({}),
  sortBy: PropTypes.arrayOf(
    PropTypes.shape({
      column: PropTypes.string,
      order: PropTypes.string,
    }),
  ),
  nextTask: PropTypes.shape({
    numRemaining: PropTypes.number,
    nextTaskId: PropTypes.string,
  }),
  user: PropTypes.shape({
    id: PropTypes.string,
  }),
  dispatch: PropTypes.shape({
    fetchNextTask: PropTypes.func,
    goToNextTask: PropTypes.func,
  }),
};

NextTaskButton.defaultProps = {
  filters: {},
  sortBy: undefined,
  nextTask: {},
  user: {},
  dispatch: {
    fetchNextTask: () => {},
    goToNextTask: () => {},
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(NextTaskButton);
