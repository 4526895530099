// eslint-disable-next-line no-extend-native
Object.defineProperty(String.prototype, 'replaceLast', {
  value: function replaceLast(str, newStr) {
    const strTokens = this.split(str);
    const lastToken = strTokens.pop();
    return strTokens.join(str) + newStr + lastToken;
  },
  writable: true,
  configurable: true,
});
