import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { isValidEmail } from '../../../../../common/helpers';
import {
  createEmailAddress,
  getEmailAddresses,
  deleteEmailAddress,
} from '../../../../../reducers/mailbox/actions';

import Input from '../../../../../components/UI/Input/Input';
import { showModal } from '../../../../../components/UI/ModalV2';
import { Button, IconButton } from '../../../../../library/atoms/Buttons';
import EmailListSkeleton from './EmailListSkeleton';
import DeleteModal from '../DeleteModal/DeleteModal';
import './_emailList.scss';

const mapStateToProps = state => {
  const { auth, mailbox } = state;
  const { user } = auth;
  return {
    companyId: user.data?.companyId,
    emailAddresses: mailbox.emailAddresses?.data,
    errors: mailbox.errors,
    isLoading: mailbox.emailAddresses?.isLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      createEmailAddress: (companyId, emailAddress) =>
        dispatch(createEmailAddress(companyId, emailAddress)),
      getEmailAddresses: companyId => dispatch(getEmailAddresses(companyId)),
      deleteEmailAddress: (companyId, id) =>
        dispatch(deleteEmailAddress(companyId, id)),
    },
  };
};

const EmailList = ({
  companyId,
  emailAddresses,
  errors,
  isLoading,
  dispatch,
}) => {
  const inputRef = useRef();
  const [formErrors, setFormErrors] = useState(errors);

  useEffect(() => {
    if (!companyId) return;
    dispatch.getEmailAddresses(companyId);
  }, [companyId]);

  useEffect(() => {
    setFormErrors(errors);
  }, [errors]);

  const handleDeleteClick = (ev, emailData) => {
    ev.stopPropagation();
    showModal(
      <DeleteModal
        value={emailData.email}
        onConfirmClick={() => {
          dispatch.deleteEmailAddress(companyId, emailData.id);
        }}
      />,
    );
  };

  const handleSubmit = e => {
    e.preventDefault();

    const email = inputRef.current?.value;
    if (!isValidEmail(email)) {
      setFormErrors({ input: 'Invalid email address.' });
      return false;
    }

    dispatch.createEmailAddress(companyId, email).then(isSuccess => {
      if (isSuccess) inputRef.current.value = '';
    });
  };

  return (
    <form className={classNames('email-list')} onSubmit={handleSubmit}>
      <div className="email-list__body">
        {!isLoading ? (
          <>
            {emailAddresses.length > 0 && (
              <ul className="email-list__list">
                {emailAddresses
                  .sort((a, b) => (a.email > b.email ? 1 : -1))
                  .map(e => {
                    const emailToken = e.email.replace(/[^a-zA-Z0-9]/g, '_');
                    return (
                      <li
                        className="email-list__list-item"
                        key={`email_${e.id}`}
                      >
                        <Input
                          id={`email_input_${emailToken}`}
                          label="Source email address"
                          value={e.email}
                          disabled
                        />
                        <IconButton
                          id={`email_delete_${emailToken}`}
                          className="email-list__delete-btn"
                          onClick={ev => handleDeleteClick(ev, e)}
                        >
                          <FaTrashAlt />
                        </IconButton>
                      </li>
                    );
                  })}
              </ul>
            )}
          </>
        ) : (
          <EmailListSkeleton />
        )}
        <hr />
      </div>
      <div className="email-list__footer">
        <Input
          id="src_email_input"
          label="Source email address"
          error={formErrors?.input}
          forwardRef={inputRef}
        />
        <Button type="submit" id="src_email_submit" variant="outline">
          Add
        </Button>
      </div>
    </form>
  );
};

EmailList.propTypes = {
  companyId: PropTypes.string,
  emailAddresses: PropTypes.array,
  errors: PropTypes.object,
  isLoading: PropTypes.bool,
  dispatch: PropTypes.shape({
    getEmailAddresses: PropTypes.func,
    createEmailAddress: PropTypes.func,
    deleteEmailAddress: PropTypes.func,
  }),
};

EmailList.defaultProps = {
  companyId: null,
  emailAddresses: [],
  errors: PropTypes.object,
  isLoading: false,
  dispatch: {
    getEmailAddresses: () => {},
    createEmailAddress: () => {},
    deleteEmailAddress: () => {},
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailList);
