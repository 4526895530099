import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactDom from 'react-dom';
import Drawer from '../../../UI/Drawer/Drawer';
import './_captureDrawer.scss';

const CaptureDrawer = ({ children, isOpen, onClose }) => {
  const asideRoot = document.getElementById('capture_aside');
  const [offsetTop, setOffsetTop] = useState(0);

  useEffect(() => {
    setOffsetTop(asideRoot.scrollTop);
  }, [isOpen]);

  return ReactDom.createPortal(
    <Drawer
      className="capture-drawer"
      isOpen={isOpen}
      onClose={onClose}
      style={{ top: `${offsetTop}px` }}
    >
      {children}
    </Drawer>,
    asideRoot,
  );
};

CaptureDrawer.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

CaptureDrawer.defaultProps = {
  children: null,
};

export default CaptureDrawer;
