import React from 'react';
import ReactDOM from 'react-dom';
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaExclamationTriangle,
  FaInfoCircle,
  FaTimes,
} from 'react-icons/fa';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './Notification.scss';

const portalRoot = document.getElementById('portal');

export const STYLE = {
  WARNING: 'warning',
  ERROR: 'error',
  INFORMATION: 'information',
  CONFIRMATION: 'confirmation',
  TOAST: 'toast',
  TOAST_ERROR: 'toast_error',
};

/**
 *
 * @param notification - notification object, contains style and message
 * @param removeNotification - handler for closing the notification
 * @param children - if the box needs buttons etc then use the children parameter
 * @returns {*}
 * @constructor
 */
export default class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  /**
   * Lifecycle method called after the element is added to the DOM
   */
  componentDidMount() {
    window.scroll(0, 0);
    portalRoot.appendChild(this.el);
  }

  /**
   * Lifecycle method called after the element is re-rendered
   */
  componentDidUpdate() {
    window.scroll(0, 0);
  }

  componentWillUnmount() {
    portalRoot.removeChild(this.el);
  }

  render() {
    const { notification } = this.props;
    if (!notification) return null;

    const { removeNotification, children, isAuthenticated } = this.props;
    const { style, message } = notification;
    if (style === 'toast' || style === 'toast_error')
      return (
        <div className={style}>
          <div className="toast-header">
            <strong>Notification Alert</strong>
            <FaTimes onClick={removeNotification} />
          </div>
          <div className="toast-body">{message}</div>
        </div>
      );

    const iconProps = {
      className: 'notification-icon',
      alt: 'notification icon',
    };

    return ReactDOM.createPortal(
      <div
        className={classnames(`notification notification-style-${style}`, {
          center: !isAuthenticated,
        })}
      >
        {{
          [STYLE.CONFIRMATION]: <FaCheckCircle {...iconProps} />,
          [STYLE.WARNING]: <FaExclamationTriangle {...iconProps} />,
          [STYLE.ERROR]: <FaExclamationCircle {...iconProps} />,
          [STYLE.INFORMATION]: <FaInfoCircle {...iconProps} />,
        }[style] || <FaInfoCircle {...iconProps} />}
        {message && <p className="notification__message">{message}</p>}
        {children}
      </div>,
      portalRoot,
    );
  }
}

Notification.propTypes = {
  notification: PropTypes.object,
  removeNotification: PropTypes.func,
  children: PropTypes.any,
  isAuthenticated: PropTypes.bool.isRequired,
};

Notification.defaultProps = {
  notification: null,
  removeNotification: () => {},
  children: null,
};
