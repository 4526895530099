module.exports = {
  fileId: process.env.DEMO_INVOICES_DOCID,
  status: 'FINALISED',
  results: {
    timestamp: '2020-10-30T04:52:16.843Z',
    fields: [
      {
        name: 'signalsdemo.spend',
        value: {
          min: -594,
          max: 275474.78125,
          avg: 1960.9507178240096,
          variance: 144911630.32551542,
          percentile_rank: 70.14242913444598,
        },
        confidence: 0.9985632448658539,
        boundingBox: null,
      },
      {
        name: 'signalsdemo.bankDetailCorrelation',
        value: 0.15,
        confidence: 0.9864792791244347,
        boundingBox: null,
      },
      {
        name: 'signalsdemo.documentMatch',
        value: [
          {
            file_id: '8d36d532-54dc-4d2b-91ac-d36dd85db3f1',
          },
          {
            file_id: '50b81926-96dc-4b82-9cc0-8ba1e3d75d43',
          },
          {
            file_id: '285be112-f528-4f4a-9857-6546f2b0170f',
          },
          {
            file_id: 'a97fcf72-f666-4fb4-9c15-d48f096949a8',
          },
          {
            file_id: '5a88f5cb-b6a7-42c0-8f7b-87cea7df6cae',
          },
          {
            file_id: '0d82e49f-80fd-4946-be96-11fe0eb4c86c',
          },
          {
            file_id: 'a0a7f285-cba6-4f7c-a863-0145e33ccace',
          },
          {
            file_id: '2e10998b-cc56-4f28-b031-bf2b6183783b',
          },
          {
            file_id: '6f603d1e-9c81-4ae3-9e34-4bd5b6be2098',
          },
          {
            file_id: '961702bf-747f-42c5-875a-8f0c953f1a92',
          },
        ],
        confidence: 0.994972107708522,
        boundingBox: null,
      },
    ],
    numPages: 1,
  },
};
