import React from 'react';
import PropTypes from 'prop-types';

const ProductSummary = ({ product }) => {
  return (
    <div className="product-body__summary">
      <h3 className="product-body__summary-heading">Fields extracted</h3>
      <ul className="fieldsets__list">
        <table className="fields__table">
          <thead>
            <tr className="fields__item">
              <th width="10%">ID</th>
              <th width="20%">Field name</th>
              <th width="70%">Description</th>
            </tr>
          </thead>
          <tbody>
            {(product.fieldsets || [])
              .map(fieldset => fieldset.field_versions)
              .flat()
              .sort((a, b) =>
                a.specification.name > b.specification.name ? 1 : -1,
              )
              .filter(
                a =>
                  a.specification.published === true ||
                  a.specification.published === undefined,
              )
              .map(f => {
                let fieldName = f.specification.name;

                if (!fieldName) {
                  const normaliseId = f.specification.id
                    .replace('sypht.', '')
                    .replace('.', ' ');
                  fieldName = normaliseId.toTitleCase();
                }
                return (
                  <tr
                    className="fields__item"
                    key={`fieldset_${f.specification.id}`}
                  >
                    <td>{f.specification.id}</td>
                    <td>{fieldName}</td>
                    <td>{f.specification.description}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </ul>
    </div>
  );
};

ProductSummary.propTypes = {
  product: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    fieldsets: PropTypes.object,
  }).isRequired,
};

export default ProductSummary;
