import React from 'react';
import './_manual-split-icon.scss';

const ManualSplitIcon = () => {
  return (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="61" y="42" width="12" height="3" rx="1.5" fill="#A6A6A5" />
      <rect x="76" y="42" width="12" height="3" rx="1.5" fill="#A6A6A5" />
      <path
        d="M67.9238 17.5084C73.2238 13.6084 80.7238 14.7084 84.7238 20.0084L53.0237 43.5084L84.7238 67.0084C80.7238 72.4084 73.2238 73.5084 67.9238 69.5084L40.4238 49.1083L31.9238 55.4084C31.0238 56.1084 30.5237 57.3084 30.8237 58.4084C31.3237 60.2084 31.4237 62.2084 31.1237 64.2084C30.1237 70.4084 25.0237 75.3084 18.8237 76.0084C9.62373 77.0084 1.92372 69.3083 3.12372 60.1083C3.92372 54.0084 8.62372 49.1084 14.6237 48.0084C18.2237 47.4084 21.6238 48.1084 24.4238 49.8084C25.4238 50.4084 26.7237 50.3083 27.6237 49.6083L35.8237 43.5084L27.6237 37.4084C26.7237 36.7084 25.4238 36.6084 24.4238 37.2084C22.2238 38.5084 19.8237 39.2084 17.1237 39.2084C9.32372 39.2084 3.02374 32.9083 3.02374 25.1083C3.02374 16.5083 10.7237 9.70838 19.5237 11.2084C25.1237 12.1084 29.7238 16.5084 30.9238 22.0084C31.4238 24.3084 31.3237 26.5083 30.8237 28.6083C30.5237 29.7083 31.0238 30.9083 31.9238 31.6083L40.4238 37.9084L67.9238 17.5084Z"
        fill="white"
        stroke="#A6A6A5"
        strokeWidth="2"
      />
      <circle cx="45.5" cy="43.5" r="2.5" fill="#A6A6A5" />
      <circle cx="17" cy="25" r="6" stroke="#A6A6A5" strokeWidth="2" />
      <circle cx="17" cy="62" r="6" stroke="#A6A6A5" strokeWidth="2" />
    </svg>
  );
};

export default ManualSplitIcon;
