/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { FaUserLock } from 'react-icons/fa';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  addToList,
  hasParentClass,
  removeFromList,
} from '../../common/helpers';
import { UserStatusType } from '../../common/types';
import history from '../../services/historyService';

import Chip from '../UI/Chip/Chip';
import { DataTable, DataCol } from '../UI/Table';
import Checkbox from '../UI/Checkbox/Checkbox';
import './UsersTable.scss';
import TagsColumm from '../UI/DataTable/columns/TagsColumn';
import TagList from '../../library/molecules/TagList';
import Tag from '../../library/atoms/Tag';

const setStatus = value => {
  if (!value) return <Chip color="orange">Pending</Chip>;

  const firstLetter = value.substr(0, 1);
  const returnValue = value.replace(firstLetter, firstLetter.toUpperCase());
  if (value === UserStatusType.Active) {
    return (
      <Chip className="users-table__status" color="green">
        {returnValue}
      </Chip>
    );
  }
  if (value === UserStatusType.Deactivated) {
    return (
      <Chip className="users-table__status" color="red">
        Inactive
      </Chip>
    );
  }
  if (value === UserStatusType.Pending) {
    return (
      <Chip className="users-table__status" color="orange">
        {returnValue}
      </Chip>
    );
  }

  return null;
};

const filterUsers = (users, filter) => {
  if (!filter || !users) return users;
  return users.filter(u => {
    const name = `${u.firstName} ${u.lastName}`.trim();
    return (
      name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
      u.email.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  });
};

const UsersTable = ({
  users,
  filter,
  admin = false,
  onSelectedChange,
  page,
  onPageChange,
}) => {
  const [selectedUsers, setSelectedUsers] = useState({});
  const filteredUsers = filterUsers(users, filter);

  useEffect(() => {
    onSelectedChange(selectedUsers);
  }, [selectedUsers]);

  const handleCheckboxClick = (e, user) => {
    e.stopPropagation();
    const newUsers = { ...selectedUsers };
    const userId = user.id;

    // remove from selectedUsers
    if (selectedUsers[userId]) {
      delete newUsers[userId];
      setSelectedUsers(newUsers);
      return;
    }

    // add to selectedUsers
    newUsers[userId] = user;
    setSelectedUsers(newUsers);
  };

  const handleRowClick = (e, user) => {
    if (!user) return;

    // if element is under a .rt-td--checkbox, go to url
    if (hasParentClass(e.target, 'rt-td--checkbox')) {
      return;
    }

    return admin
      ? history.push(`/admin/users/${user.id}`)
      : history.push(`/users/${user.id}`);
  };

  return (
    <DataTable
      id="users_table"
      className="admin-table users-table"
      data={filteredUsers}
      columns={[
        new DataCol('', 'id', {
          Cell: ({ original: o }) => {
            return (
              <Checkbox
                className="rt-checkbox"
                id={`user_${o.email}`}
                name={`user_${o.email}`}
                value={o.id}
                checked={!!selectedUsers[o.id]}
                onClick={e => handleCheckboxClick(e, o)}
              />
            );
          },
          className: 'rt-td--checkbox',
          sortable: false,
          width: 40,
        }),
        new DataCol('Name', 'firstName', {
          Cell: ({ original: o }) =>
            `${o.firstName} ${o.lastName || ''}`.trim(),
          width: 256,
        }),
        // new DataCol('Name', 'name'),
        new DataCol('Email', 'email', {
          width: 256,
        }),
        new DataCol('Created', 'created', {
          Cell: ({ original: o }) => moment(o.created).format('MMM DD, YYYY'),
          width: 120,
        }),
        new TagsColumm({
          Header: 'Access Tags',
          Cell: ({ original: user }) => {
            return (
              <TagList>
                {(user.tags || []).map(t => (
                  <Tag
                    id={`user_${user.email}_${t.name}`}
                    iconSize={14}
                    key={`tagItem|${user.id}|${t.id}`}
                    title={t.name}
                  >
                    <FaUserLock />
                    {t.name}
                  </Tag>
                ))}
              </TagList>
            );
          },
          sortable: false,
        }),
        new DataCol('Status', 'status', {
          className: 'align--center',
          Cell: ({ value }) => setStatus(value),
          width: 120,
        }),
      ]}
      sortBy="firstName"
      role="button"
      defaultPageSize={10}
      showPageSizeOptions={false}
      pageSizeOptions={[20, 50, 100]}
      getTrProps={(state, { original: o }) => {
        return {
          onClick: e => handleRowClick(e, o),
        };
      }}
      page={page}
      onPageChange={onPageChange}
    />
  );
};

UsersTable.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  filter: PropTypes.string,
};

UsersTable.defaultProps = {
  filter: null,
};

export default UsersTable;
