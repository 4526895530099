/* eslint-disable no-unused-vars */
import { LOCATION_CHANGE } from 'connected-react-router';
import { EventLookup, EventParams } from '../common/tracking';
import { initTracker, trackEvent } from '../reducers/tracking/actions';

const trackingMiddleware = store => next => action => {
  const { dispatch } = store;
  const { type, payload } = action;

  if (type === LOCATION_CHANGE) {
    const { location } = payload;
    try {
      dispatch(initTracker());
      setTimeout(() => {
        // timeout required for react-helmet to update <title>
        dispatch(
          trackEvent({
            name: EventLookup.page_view,
            params: {
              ...EventParams,
              page_title: document.title,
            },
          }),
        );
      }, 500);
    } catch (err) {
      // silently fail
    }
  }
  return next(action);
};

export default trackingMiddleware;
