/* eslint-disable react/no-array-index-key */
import React, { Fragment, forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Accordion from '../../../../UI/Accordion/Accordion';
import replaceKeyOrValue from '../../../utils/replaceKeyOrValue';

const renderNonObject = (predictions, key, value) => {
  if (!predictions.value?.matched) {
    return value;
  }
  try {
    switch (predictions.type) {
      case 'abrlookup': {
        switch (key) {
          case 'names': {
            // Intention is to replace the null in "legal_name: { full_name:
            // null ... }" for ABR Lookup field(s).
            const obj = JSON.parse(value);
            const newObj = replaceKeyOrValue(obj, (k, v) => {
              switch (k) {
                case 'main_trading_names':
                case 'other_trading_names':
                case 'organization_names':
                  return [k, v && v.length > 0 ? v : 'N/A'];
                case 'legal_name':
                  return [
                    k,
                    replaceKeyOrValue(v, (k2, v2) => [k2, v2 || 'N/A']),
                  ];
                default:
                  return [k, v];
              }
            });
            return (
              <code style={{ whiteSpace: 'pre-wrap', fontFamily: 'inherit' }}>
                {JSON.stringify(newObj, null, 4)}
              </code>
            );
          }
          default: {
            return value;
          }
        }
      }
      default: {
        return value;
      }
    }
  } catch (err) {
    console.error('renderNonObject failed:', err);
    return value;
  }
};

const SidebarStructured = forwardRef(
  ({ predictions, onClick, focusPrediction }, ref) => {
    /**
     * Get list of fieldsets and their prediction outputs
     */
    const fieldsetList = [];
    const predictionResults = predictions.value;
    // Special case for multi-value structured groups e.g. infringement.group
    if (Array.isArray(predictionResults) && predictionResults.length > 0) {
      predictionResults.forEach(group => {
        fieldsetList.push(Object.entries(group));
      });
    } else if (typeof predictionResults === 'object') {
      const list = {};
      Object.keys(predictionResults).forEach(key => {
        let fieldName = key;

        // Special case for nested structured outputs e.g. fieldset.something.something
        if (fieldName.includes('.')) {
          fieldName = fieldName.split('.');
          fieldName.shift();
          fieldName = fieldName.join(' ');
        }
        list[fieldName] = null;
        let predictionValue = 'N/A';

        if (typeof predictionResults[key] === 'string') {
          predictionValue = predictionResults[key];
        } else if (typeof predictionResults[key] === 'number') {
          predictionValue = (
            Math.round(predictionResults[key] * 100) / 100
          ).toString();
        } else if (typeof predictionResults[key] === 'boolean') {
          predictionValue = predictionResults[key].toString();
        } else if (
          typeof predictionResults[key] === 'object' &&
          predictionResults[key] != null
        ) {
          predictionValue = JSON.stringify(
            predictionResults[key],
            undefined,
            4,
          );
        }
        list[fieldName] = predictionValue;
      });
      fieldsetList.push(Object.entries(list));
    }
    /**
     * Converts a camelCase string to be separated by spaces and each word
     * uppercased.
     * @param {*} string
     */
    const insertSpaces = string => {
      let result = string.replace(/([a-z])([A-Z])/g, '$1 $2');
      result = result.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
      return result;
    };

    /**
     * TO-DO: This is currently very specific to infringement.group multi-label
     * and multi-value fieldsets. This needs to be generalised for future
     * fieldsets that have similar structure.
     *
     * What makes this not generalised is the assumption of "fieldset=fieldset."
     * in fieldset name.
     *
     * @param {*} fieldset
     * @param {*} obj
     */
    const renderObject = (fieldset, obj) => {
      const values = [];
      Object.entries(obj).forEach(entry => {
        if (entry[1] && entry[0].includes(`${fieldset}=${fieldset}.`)) {
          // This is very specific to infringement.group
          const value = entry[0].split(`${fieldset}=${fieldset}.`)[1];
          values.push(<li key={`${value}`}>{value}</li>);
        }
      });
      if (values.length === 0) {
        values.push(<li key={`${fieldset}`}>N/A</li>);
      }
      return <ul className={classNames('field', 'multi-value')}>{values}</ul>;
    };

    return (
      <div ref={ref} className="structured-fields">
        {fieldsetList.map((fieldset, index) => (
          <Accordion key={`structured-field-${index}`}>
            <Accordion.Header>
              {`${predictions.fieldName} ${
                fieldsetList.length > 1 ? index + 1 : ''
              }`}
            </Accordion.Header>
            <Accordion.Panel>
              {fieldset.map(field => {
                let fieldName = field[0].split('.');
                fieldName = insertSpaces(
                  fieldName.length > 1
                    ? fieldName[fieldName.length - 1]
                    : fieldName[0],
                );
                const predObj = {
                  uid: `${field[0]}-${index}`,
                  bounds:
                    predictions.bounds && predictions.bounds.length > 0
                      ? predictions.bounds[index][field[0]]
                      : null,
                };
                return field[1] ? (
                  <Fragment key={field[0]}>
                    <div className={classNames('field', 'structured-header')}>
                      <h3>{fieldName}</h3>
                    </div>
                    {typeof field[1] === 'object' ? (
                      renderObject(field[0], field[1])
                    ) : (
                      <button
                        type="button"
                        className={classNames('field', 'structured-entry', {
                          focus:
                            focusPrediction &&
                            predObj.bounds &&
                            predObj.uid === focusPrediction.uid,
                        })}
                        onClick={() =>
                          onClick(
                            focusPrediction &&
                              predObj.uid === focusPrediction.uid &&
                              predObj.bounds
                              ? null
                              : predObj,
                          )
                        }
                      >
                        {renderNonObject(predictions, field[0], field[1])}
                      </button>
                    )}
                  </Fragment>
                ) : null;
              })}
            </Accordion.Panel>
          </Accordion>
        ))}
      </div>
    );
  },
);

SidebarStructured.defaultProps = {
  focusPrediction: [],
  onClick: () => {},
};

SidebarStructured.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  predictions: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  focusPrediction: PropTypes.object,
};

export default SidebarStructured;
