import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Accordion.scss';
import { FaAngleRight } from 'react-icons/fa';

export const AccordionContext = React.createContext({
  expanded: false,
  toggleExpanded: () => {},
});

const AccordionHeader = ({ children, text, forwardRef }) => (
  <AccordionContext.Consumer>
    {({ expanded, toggleExpanded, iconPos, className }) => (
      <button
        onClick={toggleExpanded}
        className={classNames('accordion-header', `${className}-header`)}
        ref={forwardRef}
      >
        {iconPos === 'left' && (
          <FaAngleRight
            className={classNames(
              `indicator-${iconPos}`,
              expanded ? 'expanded' : '',
            )}
          />
        )}
        <span className="text">{children}</span>
        {iconPos === 'right' && (
          <FaAngleRight
            className={classNames(
              `indicator-${iconPos}`,
              expanded ? 'expanded' : '',
            )}
          />
        )}
      </button>
    )}
  </AccordionContext.Consumer>
);

const AccordionPanel = ({ children }) => (
  <AccordionContext.Consumer>
    {({ expanded, className }) => (
      <div
        className={classNames(
          'accordion-panel',
          `${className}-panel`,
          expanded ? 'expanded' : '',
        )}
      >
        {children}
      </div>
    )}
  </AccordionContext.Consumer>
);

const Accordion = ({
  children,
  className,
  defaultExpanded = false,
  iconPos,
  name,
  toggleExpandedExternal,
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded);

  useEffect(() => {
    if (expanded !== defaultExpanded && toggleExpandedExternal) {
      setExpanded(defaultExpanded);
    }
  }, [defaultExpanded]);

  const toggleExpanded = () => {
    if (toggleExpandedExternal) {
      toggleExpandedExternal({ name, expanded: !expanded });
    } else {
      setExpanded(!expanded);
    }
  };

  return (
    <AccordionContext.Provider
      value={{
        className,
        expanded,
        toggleExpanded,
        iconPos,
        name,
      }}
    >
      <div
        className={classNames(
          'accordion-item',
          `${className}-wrapper`,
          expanded ? 'expanded' : '',
        )}
      >
        {children}
      </div>
    </AccordionContext.Provider>
  );
};

Accordion.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  defaultExpanded: PropTypes.bool,
  iconPos: PropTypes.oneOf(['left', 'right']),
  name: PropTypes.string,
  toggleExpandedExternal: PropTypes.func,
};

Accordion.defaultProps = {
  className: 'accordion-wrapper',
  defaultExpanded: false,
  iconPos: 'right',
  name: 'default-accordion-name',
  toggleExpandedExternal: null,
};

AccordionHeader.propTypes = {
  children: PropTypes.node.isRequired,
  forwardRef: PropTypes.object,
};

AccordionHeader.defaultProps = {
  forwardRef: null,
};

AccordionPanel.propTypes = {
  children: PropTypes.node.isRequired,
};

AccordionPanel.defaultProps = {};

Accordion.Header = AccordionHeader;
Accordion.Panel = AccordionPanel;

export default Accordion;
