import React from 'react';
import ReactLoading from 'react-loading';
import './Page.scss';

const PageLoading = () => {
  return (
    <div className="page__loading">
      <ReactLoading
        className="page__loading-icon"
        type="spin"
        color="#dddddd"
        height={96}
        width={96}
      />
    </div>
  );
};

export default PageLoading;
