import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FaEdit, FaEllipsisV, FaTrashAlt } from 'react-icons/fa';

const handleInputEvents = eventHandler => {
  document.addEventListener('click', eventHandler, true);
  document.addEventListener('touchstart', eventHandler, true);
};

const cleanInputEvents = eventHandler => {
  document.removeEventListener('click', eventHandler, true);
  document.removeEventListener('touchstart', eventHandler, true);
};

const CredentialMenu = ({ onEditClick, onDeleteClick, onSecretChange }) => {
  const [isOpen, setOpen] = useState(false);
  const menuRef = useRef(null);

  const closeDialog = () => {
    // note: potentially use e.target and check if contains .class
    setTimeout(() => {
      setOpen(false);
    }, 0);
  };

  const handleEditClick = (e, clientId) => {
    e.stopPropagation();
    onEditClick(e, clientId);
  };

  const handleDeleteClick = (e, clientId, clientName) => {
    e.stopPropagation();
    // from: CredentialsList.jsx
    onDeleteClick(e, clientId, clientName);
  };

  const handleMoreButtonClick = () => {
    setOpen(o => !o);
  };

  useEffect(() => {
    if (isOpen) {
      handleInputEvents(closeDialog);
    }

    // clean up
    return () => {
      cleanInputEvents(closeDialog);
    };
  }, [isOpen]);

  return (
    <>
      <button
        className="credential__more-button"
        type="button"
        onClick={handleMoreButtonClick}
      >
        <FaEllipsisV />
      </button>
      {!isOpen ? null : (
        <nav className="credential-menu" ref={menuRef}>
          <h4 className="credential-menu__heading">More options</h4>
          <ul className="credential-menu__items">
            <li className="credential-menu__item">
              <label
                className="credential-menu__label"
                htmlFor="credential_edit_button"
              >
                <button
                  id="credential_edit_button"
                  type="button"
                  className="credential-menu__button"
                  onClick={handleEditClick}
                >
                  <span className="credential-menu__button-text">
                    Edit credential
                  </span>
                  <FaEdit className="edit-icon" />
                </button>
              </label>
            </li>
            <li className="credential-menu__item">
              <label
                className="credential-menu__label"
                htmlFor="credential_delete_button"
              >
                <button
                  id="credential_delete_button"
                  type="button"
                  className="credential-menu__button"
                  onClick={handleDeleteClick}
                >
                  <span className="credential-menu__button-text">
                    Delete credential
                  </span>
                  <FaTrashAlt />
                </button>
              </label>
            </li>
          </ul>
        </nav>
      )}
    </>
  );
};

CredentialMenu.propTypes = {
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};

export default CredentialMenu;
