import React from 'react';
import Skeleton, { SkeletonDoc, SkeletonSpinner } from '../../../Skeleton';

const CaptureDocLoading = () => {
  return (
    <div className="capture-doc">
      <Skeleton className="capture-doc__skeleton">
        <SkeletonDoc>
          <SkeletonSpinner color="#c6c6c6" height={128} width={128} />
        </SkeletonDoc>
      </Skeleton>
    </div>
  );
};

CaptureDocLoading.propTypes = {};

export default CaptureDocLoading;
