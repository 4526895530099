import { STYLE } from '../../components/UI/Notification/Notification';

export const updatedMessage = name => {
  return {
    style: STYLE.CONFIRMATION,
    message: `${name} has been updated.`,
  };
};

export const errorMessage = {
  style: STYLE.ERROR,
  message:
    'Something went wrong, please try again. If this issue persists ' +
    'please contact us at support@sypht.com.',
  timeout: 8000,
};

export const rolesByProductNoCompanyText =
  'Could not fetch roles for your company - no companyId detected. Please try re-logging in to ensure your user permissions are updated.';

export const rolesByProductNoCompany = () => {
  return {
    style: STYLE.ERROR,
    message: rolesByProductNoCompanyText,
  };
};

export const rolesByProductFailedFetchText =
  'Could not fetch roles for your company. Please try re-logging in to ensure your user permissions are updated.';

export const rolesByProductFailedFetch = () => {
  return {
    style: STYLE.ERROR,
    message: rolesByProductFailedFetchText,
  };
};

export const roleDataNotAvailable =
  'Role data is not available for your user, you cannot assign any roles.';
