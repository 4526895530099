/* eslint-disable class-methods-use-this */
import axios, { isCancel } from 'axios';
import * as utils from './utils';

const apiBaseUrl =
  process.env.VALIDATE_SERVICE_HOST_NAME || process.env.APP_API_HOST_NAME;

class TasksService {
  getSpecs(accessToken, successCallback, errorCallback) {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    };
    return axios
      .get(`${apiBaseUrl}/app/specifications`, config)
      .then(response => {
        successCallback(response);
        return response;
      })
      .catch(error => {
        errorCallback(error.response);
      });
  }

  getTasks(accessToken, filters, successCallback, errorCallback, cancelToken) {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'content-type': 'application/json',
        Accept: 'application/json',
      },
      cancelToken: cancelToken?.token,
    };
    return axios
      .post(`${apiBaseUrl}/app/search`, filters, config)
      .then(response => {
        successCallback(response);
      })
      .catch(error => {
        if (isCancel(error)) {
          errorCallback(error);
        }
        errorCallback(error.response);
      });
  }

  getTask(accessToken, taskId) {
    return axios.get(`${apiBaseUrl}/app/tasks/${taskId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    });
  }

  getTaskByDocId = (accessToken, docId, companyId) => {
    return utils.getData(`${apiBaseUrl}/app/docs/${docId}/tasks`, {
      accessToken,
      companyId,
    });
  };

  getNextTask(accessToken, payload) {
    return axios.post(`${apiBaseUrl}/app/nexttask`, payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    });
  }

  saveUserAnnotations(accessToken, taskId, userId, data) {
    return axios.put(
      `${apiBaseUrl}/app/tasks/${taskId}/userannotations/${userId}/data`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'content-type': 'application/json',
          Accept: 'application/json',
        },
      },
    );
  }

  saveUserAnnotationState(accessToken, taskId, userId, state, flaggedReason) {
    return axios.put(
      `${apiBaseUrl}/app/tasks/${taskId}/userannotations/${userId}/state`,
      { state, flaggedReason },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'content-type': 'application/json',
          Accept: 'application/json',
        },
      },
    );
  }

  listAnnotations({ accessToken, startDate, endDate }) {
    return axios.get(
      `${apiBaseUrl}/app/annotations?fromDate=${encodeURIComponent(
        startDate,
      )}&toDate=${encodeURIComponent(endDate)}&includeData=false`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'content-type': 'application/json',
          Accept: 'application/json',
        },
      },
    );
  }

  addTask(
    accessToken,
    docId,
    specification,
    companyId,
    region,
    replication,
    priority,
  ) {
    return axios.post(
      `${apiBaseUrl}/app/tasks`,
      {
        docId,
        companyId,
        specification,
        replication,
        priority,
        region,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'content-type': 'application/json',
          Accept: 'application/json',
        },
      },
    );
  }

  addBatchTask = ({
    accessToken,
    companyId,
    filters,
    specification,
    replication,
    priority,
    region,
  }) => {
    return utils.postData(`${apiBaseUrl}/app/tasks/batch`, {
      accessToken,
      data: {
        companyId,
        filters,
        specification,
        replication,
        priority,
        region,
      },
    });
  };

  editTask(accessToken, taskId, replication, priority) {
    return axios.put(
      `${apiBaseUrl}/app/tasks/${taskId}`,
      {
        replication,
        priority,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'content-type': 'application/json',
          Accept: 'application/json',
        },
      },
    );
  }

  deleteTaskAnnotations(accessToken, taskId, userId) {
    return axios.delete(
      `${apiBaseUrl}/app/tasks/${taskId}/userannotations/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'content-type': 'application/json',
          Accept: 'application/json',
        },
      },
    );
  }

  deleteTask(accessToken, taskId) {
    return axios.delete(`${apiBaseUrl}/app/tasks/${taskId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    });
  }

  getTasksUsage(accessToken, companyId, filters) {
    return utils.getData(`${apiBaseUrl}/app/company/${companyId}/tasks/usage`, {
      accessToken,
      ...filters,
    });
  }

  /**
   * Start a document quick fix which is similar to addTask.
   */
  addQuickFix(accessToken, docId) {
    return axios.post(
      `${apiBaseUrl}/app/fix`,
      {
        docId,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'content-type': 'application/json',
          Accept: 'application/json',
        },
      },
    );
  }
}

export default new TasksService();
