import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import RateCard from '../RateCard/RateCard';
import BillingCalculator from '../BillingCalculator/BillingCalculator';

const LegacyPricing = ({ product }) => {
  return (
    <>
      <div className="product-body__description product-body__description--pricing">
        <RateCard pricing={product.pricing} />
        <BillingCalculator pricing={product.pricing} />
      </div>
      <div className="product-body__sidenote">
        <div className="disclaimer">
          <h2 className="disclaimer__heading">Billing & Payment</h2>
          <p className="disclaimer__text">
            The billing for this product is based on the{' '}
            <strong>volume of pages</strong> you process within a monthly
            billing period, based on the rate card tiers to the left and a
            minimum fee of $9 / month.
          </p>
          <p className="disclaimer__text">
            The monthly billing period is set as{' '}
            <strong>the date you unlock your subscription</strong>. Your
            provided payment method will be automatically charged on the same
            subscription date each month, based on the usage incurred during the
            billing period.
          </p>
          <p className="disclaimer__text">
            All prices are in AUD and do not include any sales or applicable
            taxes.
          </p>
        </div>
      </div>
    </>
  );
};

LegacyPricing.propTypes = {
  product: PropTypes.object.isRequired,
};

export default LegacyPricing;
