/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ColorTypes } from './types';
import './Chip.scss';

const Chip = ({ children, className, color, value, onClick }) => {
  const chipClass = classNames(
    className,
    'chip',
    color ? `chip--${color}` : '',
  );
  return (
    <span onClick={onClick} className={chipClass}>
      {children || value}
    </span>
  );
};

Chip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(Object.values(ColorTypes)),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
};

Chip.defaultProps = {
  children: '',
  className: '',
  color: ColorTypes.Secondary,
  value: '',
  onClick: () => {},
};

export default Chip;
