import { addError, removeError } from './index';

const validateLength = ({ elem, errors, setErrors }, length) => {
  const { name: key, value } = elem;
  const message = `Invalid number value.`;

  if (value.length === length) {
    addError(key, message, errors, setErrors);
    return {
      isValid: false,
    };
  }

  removeError(key, message, errors, setErrors);
  return {
    isValid: true,
  };
};

export default validateLength;
