import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { Menu, Product, ProductList, Billing } from './components';
import { ListProducts, CreateProduct, EditProduct } from './components/Admin';
import '../UI/Tile/Tile.scss';
import './Marketplace.scss';
import SubscriptionList from './components/SubscriptionList/SubscriptionList';

const mapStateToProps = state => {
  const { auth, router } = state;

  return {
    location: router.location,
    user: auth.user ? auth.user.data : null,
    canAdministrateCompany: state?.auth?.permissions?.canAdministrateCompany,
    canSuperUserRead: state?.auth?.permissions?.canSuperUserRead,
    canSuperUserWrite: state?.auth?.permissions?.canSuperUserWrite,
  };
};

const Marketplace = ({
  location,
  user,
  canAdministrateCompany,
  canSuperUserRead,
  canSuperUserWrite,
}) => {
  const defaultPath = () => <Redirect to="/marketplace/products" />;
  const defaultAdminPath = () => <Redirect to="/marketplace/admin/products" />;
  // eslint-disable-next-line no-unused-vars
  const adminProductPath = () => (
    <Redirect
      to={{
        pathname: '/marketplace/admin/products/:id/edit',
        state: { id: 123 },
      }}
    />
  );

  const isUserVerified = !(user && user.auth0 && !user.auth0.email_verified);

  return (
    <>
      <Menu />
      <Switch location={location}>
        <Route exact path="/marketplace" component={defaultPath} />
        <Route
          exact
          path="/marketplace/products"
          component={ProductList}
          defaultTo="/marketplace"
        />
        <Route
          exact
          path="/marketplace/products/:id"
          component={Product}
          defaultTo="/marketplace"
        />
        {canAdministrateCompany && (
          <Route
            exact
            path="/marketplace/subscriptions"
            component={SubscriptionList}
          />
        )}
        {canAdministrateCompany && (
          <Route exact path="/marketplace/billing" component={Billing} />
        )}
        <Route exact path="/marketplace/admin" component={defaultAdminPath} />
        {canSuperUserRead && (
          <Route
            exact
            path="/marketplace/admin/products"
            component={ListProducts}
          />
        )}
        {canSuperUserWrite && (
          <Route
            exact
            path="/marketplace/admin/products/create"
            component={CreateProduct}
          />
        )}
        {canSuperUserWrite && (
          <Route
            exact
            path="/marketplace/admin/products/:id/edit"
            component={EditProduct}
          />
        )}
      </Switch>
      <ReactTooltip
        id="verify-tooltip"
        place="right"
        delayHide={1500}
        clickable
        hidden={isUserVerified}
      >
        <Link to="/profile">Verify your account</Link> to enable this feature
      </ReactTooltip>
    </>
  );
};

Marketplace.propTypes = {
  location: PropTypes.shape({}),
  user: PropTypes.shape({
    auth0: PropTypes.shape({
      email_verified: PropTypes.bool,
    }),
  }),
  canAdministrateCompany: PropTypes.bool,
  canSuperUserRead: PropTypes.bool,
  canSuperUserWrite: PropTypes.bool,
};

Marketplace.defaultProps = {
  location: null,
  user: null,
  canAdministrateCompany: false,
  canSuperUserRead: false,
  canSuperUserWrite: false,
};

export default connect(mapStateToProps)(Marketplace);
