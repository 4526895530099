import TrackingService from '../../services/TrackingService';

export const TRANSMIT_PAGEVIEW = 'sypht/events/TRANSMIT_PAGEVIEW';
export const TRANSMIT_EVENT = 'sypht/events/TRANSMIT_EVENT';

const transmitPageView = pageViewData => {
  return {
    type: TRANSMIT_PAGEVIEW,
    payload: pageViewData,
  };
};

const transmitEvent = eventData => {
  return {
    type: TRANSMIT_EVENT,
    payload: eventData,
  };
};

// PAGEVIEW
export const pushPageView = pageViewData => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;

    let response = null;
    try {
      response = await TrackingService.pushPageView(accessToken, pageViewData);
      if (response.data) dispatch(transmitPageView(pageViewData));
    } catch (err) {
      response = err.response;
      console.error(response);
    }
  };
};

// EVENT
export const pushEvent = eventData => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;

    let response = null;
    try {
      response = await TrackingService.pushEvent(accessToken, eventData);
      if (response.data) dispatch(transmitEvent(eventData));
    } catch (err) {
      response = err.response.data;
      console.error(response);
    }
  };
};
