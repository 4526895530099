import React from 'react';
import PropTypes from 'prop-types';
import Tag from '../../../../atoms/Tag';
import TagList from '../../../../molecules/TagList';

const TagsListCell = ({ row, handlers, tooltipId }) => {
  const task = row.original;

  const handleTagClick = (e, tag) => {
    handlers.onTagClick(e, tag);
  };

  return (
    <TagList tags={task.tags} handleTagClick={handleTagClick}>
      {(task.tags || [])
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map(tag => (
          <Tag
            key={`tag|${task.id}|${tag.id}`}
            onClick={e => handleTagClick(e, tag)}
            role="button"
            tabIndex={0}
            data-tooltip-id={tooltipId}
            data-tooltip-content={tag.name}
            data-test-id={tag.name}
          >
            {tag.name}
          </Tag>
        ))}
    </TagList>
  );
};

TagsListCell.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  row: PropTypes.object.isRequired,
  tooltipId: PropTypes.string,
  handlers: PropTypes.shape({
    handleTagListItemClick: PropTypes.func,
    onTagClick: PropTypes.func,
  }),
};

TagsListCell.defaultProps = {
  tooltipId: undefined,
  handlers: {
    handleTagListItemClick: () => {},
  },
};

export default TagsListCell;
