import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '../UI/Modal/Modal';
import Button from '../UI/Button/Button';
import { logout, refreshToken } from '../../reducers/authReducer';
import './Timeout.scss';

// Note: TIMEOUT_EXPIRY should be set to minimum possible expiry
const EXPIRY_SECONDS = 1800; // 1800: 30 mins
const WARNING_SECONDS = 180; // 180: 3 mins
const WARNING_MESSAGE = 'Your session is about to expire due to inactivity.';
const EXPIRED_MESSAGE = 'You have been logged out due to inactivity.';
const { OAUTH_DOMAIN } = process.env; // UA: REACT_APP_OAUTH_DOMAIN
const { APP_DOMAIN } = process.env;

const getCookie = name => {
  const cookieStr = decodeURIComponent(document.cookie);
  const cookieItems = cookieStr.split(';');

  const n = `${name}=`;
  for (let i = 0; i < cookieItems.length; i++) {
    let c = cookieItems[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(n) === 0) {
      return c.substring(n.length, c.length);
    }
  }
  return '';
};

const isActiveTab = () => {
  return !document.hidden;
};

const startAnimation = (elem, expiryTime, warningTime) => {
  if (!elem || elem.classList.contains('animate')) return;
  const duration = Math.round(expiryTime - warningTime);
  const elapsed = Math.round((expiryTime - Date.now()) / 1000) * 1000;

  elem.setAttribute(
    'style',
    `animation-duration: ${duration}ms;
     animation-delay: ${elapsed - duration}ms;`,
  );

  elem.classList.add('animate');
};

const timeRemaining = expiresAt => {
  let tr = Math.round((expiresAt - Date.now()) / 1000);
  if (tr <= 0) tr = 0;
  tr = Math.abs(tr);

  const mm = Math.floor(tr / 60);
  let ss = tr - mm * 60;
  ss = ss.toString().length <= 1 ? `0${ss}` : ss;
  return `${mm}:${ss}`;
};

// CUSTOM HOOKS
const useExpiryCookies = () => {
  const [, setCookie] = useCookies([]);
  const setExpiry = (expiresIn, warningDuration) => {
    const warningIn = expiresIn - warningDuration;
    const expiryTime = Date.now() + expiresIn * 1000;
    const warningTime = Date.now() + warningIn * 1000;
    const config = {
      path: '/',
      sameSite: 'strict',
      // prettier-ignore
      ...(APP_DOMAIN && APP_DOMAIN !== 'localhost' ? {
        domain: `.${APP_DOMAIN}`,
      }: {
        domain: `${APP_DOMAIN}`,
      }),
    };

    setCookie('sypht.expiry', expiryTime, config);
    setCookie('sypht.warning', warningTime, config);
  };

  return [
    Number(getCookie('sypht.expiry')),
    Number(getCookie('sypht.warning')),
    setExpiry,
  ];
};

// EVENT HANDLERS
const handleInputEvents = eventHandler => {
  document.addEventListener('mousemove', eventHandler, true);
  document.addEventListener('mousedown', eventHandler, true);
  document.addEventListener('touchstart', eventHandler, true);
  document.addEventListener('click', eventHandler, true);
  document.addEventListener('keypress', eventHandler, true);
  document.addEventListener('scroll', eventHandler, true);
};

const cleanInputEvents = eventHandler => {
  document.removeEventListener('mousemove', eventHandler, true);
  document.removeEventListener('mousedown', eventHandler, true);
  document.removeEventListener('touchstart', eventHandler, true);
  document.removeEventListener('click', eventHandler, true);
  document.removeEventListener('keypress', eventHandler, true);
  document.removeEventListener('scroll', eventHandler, true);
};

// MAP STATE
const mapStateToProps = ({ auth }) => {
  let { expiresIn } = auth;
  if (!expiresIn) expiresIn = EXPIRY_SECONDS;

  let warningDuration = WARNING_SECONDS;
  if (expiresIn <= WARNING_SECONDS) {
    warningDuration = Math.floor(Math.floor(expiresIn / 4) / 30) * 30;
    warningDuration = warningDuration > 0 ? warningDuration : 30;
  }

  return {
    expiresIn,
    warningDuration,
    loggedIn: auth.isAuthenticated,
  };
};

// MAP DISPATCH
const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      logout: () => dispatch(logout()),
      refreshToken: () => dispatch(refreshToken()),
    },
  };
};

// COMPONENT
const Timeout = ({ loggedIn, expiresIn, warningDuration, dispatch }) => {
  if (!loggedIn) return null;

  // state
  const circleRef = useRef(null);
  const [time, setTime] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [isExpired, setExpired] = useState(false);
  const [expiresAt, warningAt, setExpiry] = useExpiryCookies(
    expiresIn,
    warningDuration,
  );

  const runTimer = () => {
    setTime(t => t + 1);
    setTimeout(runTimer, 1000);
  };

  const extendTimeout = () => {
    setTime(0);
    setExpiry(expiresIn, warningDuration);
  };

  const handleContinueClick = () => {
    if (!isExpired) {
      dispatch.refreshToken();
    }
    setOpen(false);
    extendTimeout();
  };

  const handleLoginClick = () => {
    // logout eventually redirects you back to login but also cleans the cookies
    dispatch.logout();
  };

  const handleLogoutClick = () => {
    dispatch.logout();
  };

  useEffect(() => {
    extendTimeout();
    runTimer();
    setExpired(false);
  }, []);

  useEffect(() => {
    const now = Date.now();

    if (time > 1 && now > warningAt) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    setExpired(now > expiresAt);

    // handle input events
    if (!isOpen) {
      handleInputEvents(extendTimeout);
    }

    // clean up
    return () => {
      cleanInputEvents(extendTimeout);
    };
  }, [time, isOpen]);

  useEffect(() => {
    if (isOpen && isActiveTab) {
      startAnimation(circleRef.current, expiresAt, warningAt);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isActiveTab()) {
      startAnimation(circleRef.current, expiresAt, warningAt);
    }
  }, [isActiveTab()]);

  // Note: Intentionally left here for debugging
  // prettier-ignore
  // const info =  `${time}/${expiresIn - warningDuration}/${expiresIn} ${isExpired ? 'EXPIRED' : ' NOT_EXPIRED'}`;
  // const w = { n: warningAt, x: expiresAt };
  // const dn = new Date(Date.now()).toLocaleString().substr(12, 8);
  // const dw = new Date(w.n).toLocaleString().substr(12, 8);
  // const dx = new Date(w.x).toLocaleString().substr(12, 8);
  // console.info(`nfo: ${info}\nnow: ${dn}\nwrn: ${dw}\nexp: ${dx}\n\n`);

  return (
    <Modal
      className="timeout-modal"
      onCancel={handleLogoutClick}
      onConfirm={isExpired ? handleLoginClick : handleContinueClick}
      confirmLabel={isExpired ? "Login" : "Continue"}
      cancelLabel="Log out"
      isOpen={isOpen}
      noCancel={isExpired}
    >
      <div className="timeout-modal__content">
        <figure className="timeout-modal__timer">
          <svg width="160px" height="160px">
            {/* prettier-ignore */}
            <circle className="circle outline" cx="80" cy="80" r="70" transform="rotate(-90, 80, 80)" />
            <circle
              className="circle progress"
              cx="80"
              cy="80"
              r="70"
              transform="rotate(-90, 80, 80)"
              ref={circleRef}
            />
          </svg>
          <div className="time">
            <span className="time__sec">{timeRemaining(expiresAt)}</span>
          </div>
        </figure>
        <h1 className="timeout-modal__heading">
          Session timeout
          {isExpired}
        </h1>
        <p className="timeout-modal__text">
          {!isExpired ? WARNING_MESSAGE : EXPIRED_MESSAGE}
        </p>
        <div className="timeout-modal__button-container">
          {isExpired && (
            <iframe
              src={`https://${OAUTH_DOMAIN}/v2/logout`}
              className="logout__iframe"
              height="0"
              width="0"
              title="logout"
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

// PROPS
Timeout.propTypes = {
  loggedIn: PropTypes.bool,
  expiresIn: PropTypes.number,
  warningDuration: PropTypes.number,
  dispatch: PropTypes.shape({
    logout: PropTypes.func,
    refreshToken: PropTypes.func,
  }),
};

Timeout.defaultProps = {
  loggedIn: false,
  expiresIn: EXPIRY_SECONDS,
  warningDuration: WARNING_SECONDS,
  dispatch: {
    logout: () => {},
    refreshToken: () => {},
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(Timeout);
