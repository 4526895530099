import DocsService from '../../services/DocsService';
import TasksService from '../../services/TasksService';

export const REQUEST_DOC_TASKS = 'sypht/docs/REQUEST_DOC_TASKS';
export const RECEIVE_DOC_TASKS = 'sypht/docs/RECEIVE_DOC_TASKS';

export const REQUEST_DOC_META = 'sypht/docs/REQUEST_DOC_META';
export const RECEIVE_DOC_META = 'sypht/docs/RECEIVE_DOC_META';

const requestDocTasks = docId => {
  return {
    type: REQUEST_DOC_TASKS,
    payload: {
      data: { docId },
    },
  };
};

const receiveDocTasks = (data, error) => ({
  type: RECEIVE_DOC_TASKS,
  payload: {
    data,
    error,
    lastUpdated: Date.now(),
  },
});

const requestDocMeta = docId => {
  return {
    type: REQUEST_DOC_META,
    payload: {
      data: { docId },
    },
  };
};

const receiveDocMeta = (data, error) => ({
  type: RECEIVE_DOC_META,
  payload: {
    data,
    error,
    lastUpdated: Date.now(),
  },
});

// Get number of tasks a document has
export const fetchDocTasks = (docId, companyId) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;

    dispatch(requestDocTasks(docId));

    const response = await TasksService.getTaskByDocId(
      accessToken,
      docId,
      companyId,
    );

    if (response && response.data) {
      dispatch(
        receiveDocTasks({
          docId,
          noOfTasks: response.data,
        }),
      );
    }
  };
};

export const fetchDocMeta = docId => {
  return async (dispatch, getState) => {
    dispatch(requestDocMeta(docId));
    const { auth } = getState();
    const { accessToken } = auth;
    return await DocsService.getDocMeta(accessToken, docId)
      .then(({ data }) => {
        dispatch(receiveDocMeta(data));
      })
      .catch(error => dispatch(receiveDocMeta(null, error)));
  };
};
