import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getQueryStr } from '../../common/helpers';
import { TabsContainer } from '../UI/Tabs';

import {
  downloadDoc,
  exportDocData,
  listProducts,
  retrieveDoc,
  retrieveResults,
} from './store/actions';
import {
  CaptureData,
  CaptureDataLoading,
  CaptureDoc,
  CaptureDocActions,
  CaptureDocLoading,
  CaptureDownload,
  CaptureMenu,
  CaptureUpload,
} from './components';
import { uploadStatus } from './components/Modal/CaptureUpload';
import './_capture.scss';
import { trackEvent } from '../../reducers/tracking/actions';

const AI_PRODUCTS = [
  {
    name: 'Invoices',
    productType: 'invoices',
    products: ['invoices:2'],
    docId: process.env.DEMO_INVOICES_DOCID,
  },
  {
    name: 'Receipts',
    productType: 'receipts',
    products: ['receipts:2'],
    docId: process.env.DEMO_RECEIPTS_DOCID,
  },
];

const mapStateToProps = (state, props) => {
  const { capture, docs } = state.capture;
  const { history, match } = props;
  const isDrawerOpen = capture ? capture.isDrawerOpen : false;
  const product = AI_PRODUCTS.find(
    p => p.productType === match.params.productType,
  );

  const docId =
    getQueryStr(decodeURIComponent(history.location.search), 'doc') ||
    product.docId;

  const bgColour = getQueryStr(
    decodeURIComponent(history.location.search),
    'bgColour',
  );

  return {
    docId,
    productType: match.params.productType,
    doc: docs[docId],
    product,
    match,
    bgColour,
    isDrawerOpen,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      exportDocData: (docId, filename) =>
        dispatch(exportDocData(docId, filename)),
      downloadDoc: (docSrc, filename) =>
        dispatch(downloadDoc(docSrc, filename)),
      listProducts: () => dispatch(listProducts()),
      retrieveResults: docId => dispatch(retrieveResults(docId)),
      retrieveDoc: docId => dispatch(retrieveDoc(docId)),
      trackEvent: ({ name, params }) => dispatch(trackEvent({ name, params })),
    },
  };
};

/**
 * Represents an interface that can be displayed in an iframe that allows
 * anonymous users to view, upload and extract sample documents.
 */
const CaptureDemo = ({
  docId,
  productType,
  doc,
  product,
  history,
  match,
  dispatch,
  bgColour,
  isDrawerOpen,
}) => {
  const [isDownloadOpen, setDownloadOpen] = useState(false);
  const [isUploadOpen, setUploadOpen] = useState(false);
  const [status, setStatus] = useState(uploadStatus.IDLE);
  const containerRef = useRef(null);

  useEffect(() => {
    if (!doc || doc.isRefresh) {
      setStatus(uploadStatus.IDLE);
      dispatch.retrieveDoc(docId);
    }
  }, [dispatch, doc, docId]);

  useEffect(() => {
    document.body.classList.add('demo-bg');
    return () => {
      document.body.classList.remove('demo-bg');
    };
  }, []);

  window.addEventListener('message', event => {
    if (event.data === 'getHeight') {
      window.parent.postMessage(
        { iframe: 'capture', height: containerRef.current.scrollHeight },
        '*',
      );
    }
  });

  const onImageLoad = () => {
    if (containerRef && containerRef.current) {
      window.parent.postMessage(
        { iframe: 'capture', height: containerRef.current.scrollHeight },
        '*',
      );
    }
  };

  const handleDownloadClick = e => {
    e.preventDefault();
    setDownloadOpen(true);
    dispatch.trackEvent({
      name: 'file_download',
      params: {
        doc_id: docId,
        product_id: productType,
        file_extension: doc.filename.substr(doc.filename.lastIndexOf('.') + 1),
        file_name: doc.filename,
        link_url: doc.received.filePath,
        link_text: `${productType}: ${docId}`,
      },
    });
  };

  const handleExportClick = () => {
    const filename = `${doc.filename.substr(
      0,
      doc.filename.lastIndexOf('.'),
    )}.csv`;
    dispatch.exportDocData(doc.id, filename);
  };

  const handleUploadClick = e => {
    e.preventDefault();
    setUploadOpen(true);
    dispatch.trackEvent({
      name: 'event',
      params: {
        click_action: 'upload_file',
        product_id: productType,
      },
    });
  };

  const handleUploaded = uploaded => {
    setStatus(uploadStatus.EXTRACTING);
    dispatch.retrieveResults(uploaded.fileId).then(() => {
      setStatus(uploadStatus.EXTRACTED);
      setTimeout(() => {
        setUploadOpen(false);
        setStatus(uploadStatus.IDLE);
        const queryStr = bgColour ? `&bgColour=${bgColour}` : '';
        history.push(
          `${history.location.pathname}?doc=${uploaded.fileId}${queryStr}`,
        );
      }, 1500);
    });
  };

  return (
    <div className="capture__ref" ref={containerRef}>
      <Helmet defer={false}>
        <title>Demo -{product.name} - Sypht App</title>
        <link rel="canonical" href={`${process.env.URL_WWW}/ai-products`} />
      </Helmet>
      <TabsContainer
        className="capture capture--demo"
        defaultTab={productType}
        isNav
      >
        <div className="capture__header">
          <CaptureMenu
            match={match}
            products={AI_PRODUCTS}
            bgColour={bgColour}
          />
        </div>
        <div className="capture__body">
          <div
            className={classNames('capture__main', {
              'white-bg': bgColour === 'white',
            })}
          >
            {!doc ? (
              <CaptureDocLoading />
            ) : (
              <>
                <CaptureDoc doc={doc} product={product} onLoad={onImageLoad} />
                <CaptureDocActions
                  doc={doc}
                  product={product}
                  onDownloadClick={handleDownloadClick}
                  onUploadClick={handleUploadClick}
                />
              </>
            )}
          </div>
          <div
            className={classNames('capture__aside', {
              'capture__aside--drawer': isDrawerOpen,
            })}
            id="capture_aside"
          >
            {!doc || !product ? (
              <CaptureDataLoading />
            ) : (
              <CaptureData doc={doc} product={product} />
            )}
          </div>
        </div>
        {doc && product && (
          <>
            <CaptureDownload
              doc={doc}
              product={product}
              isOpen={isDownloadOpen}
              onClose={() => setDownloadOpen(false)}
              onExportClick={handleExportClick}
            />
            <CaptureUpload
              product={product}
              status={status}
              isOpen={isUploadOpen}
              onClose={() => setUploadOpen(false)}
              onUploaded={handleUploaded}
            />
          </>
        )}
      </TabsContainer>
    </div>
  );
};

CaptureDemo.propTypes = {
  docId: PropTypes.string,
  productType: PropTypes.string,
  bgColour: PropTypes.string,
  doc: PropTypes.shape({
    id: PropTypes.string,
    filename: PropTypes.string,
    isRefresh: PropTypes.bool,
    received: PropTypes.shape({
      filePath: PropTypes.string,
    }),
  }),
  product: PropTypes.shape({
    name: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }),
  match: PropTypes.shape({}),
  isDrawerOpen: PropTypes.bool,
  dispatch: PropTypes.shape({
    exportDocData: PropTypes.func,
    downloadDoc: PropTypes.func,
    listProducts: PropTypes.func,
    retrieveDoc: PropTypes.func,
    retrieveResults: PropTypes.func,
    trackEvent: PropTypes.func,
  }),
};

CaptureDemo.defaultProps = {
  docId: null,
  productType: null,
  bgColour: null,
  doc: null,
  product: null,
  history: {},
  match: {},
  isDrawerOpen: false,
  dispatch: {
    exportDocData: () => {},
    downloadDoc: () => {},
    listProducts: () => {},
    retrieveDoc: () => {},
    retrieveResults: () => {},
    trackEvent: () => {},
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(CaptureDemo);
