// eslint-disable-next-line no-extend-native
Object.defineProperty(String.prototype, 'toSentenceCase', {
  value: function toSentenceCase(isLowerCase = false) {
    let str = this.replace(/([A-Z])/g, ' $1');
    str = isLowerCase ? str.toLowerCase() : str;
    str = str.charAt(0).toUpperCase() + str.slice(1);
    return str;
  },
  writable: true,
  configurable: true,
});
