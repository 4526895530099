import { EventLookup, EventParams } from '../../../common/tracking';

const BASE_PRODUCT = {
  discount: 0,
  affiliation: 'Sypht Marketplace',
  item_brand: 'Sypht',
  currency: 'AUD',
  quantity: 1,
};

// PAGE VIEW
export const pageView = query => {
  return {
    name: EventLookup.page_view,
    params: {
      ...EventParams,
      page_title: document.title,
    },
  };
};

// SEARCH / SORT / FILTER
export const searchProduct = query => {
  return {
    name: EventLookup.search,
    params: {
      search_term: query,
    },
  };
};

export const sortProduct = (query, orderBy) => {
  return {
    name: EventLookup.search,
    params: {
      search_term: `${query} ordered by ${orderBy}`,
    },
  };
};

export const filterProduct = (key, value) => {
  return {
    name: EventLookup.search,
    params: {
      search_term: `${key}: ${value}`,
    },
  };
};

export const selectProduct = product => {
  return {
    name: EventLookup.select_item,
    params: {
      items: [
        {
          item_id: product.productId,
          item_name: product.name,
          affiliation: 'Sypht Marketplace',
          item_brand: 'Sypht',
          item_category: 'AI Product',
        },
      ],
    },
  };
};

export const viewProducts = (products, productType = null, query = null) => {
  if (query) {
    return {
      name: EventLookup.view_search_results,
      params: {
        items: products.map(p => ({
          item_id: p.productId,
          item_name: p.name,
        })),
        search_term: query,
      },
    };
  }

  return {
    name: EventLookup.view_item_list,
    params: {
      items: products.map(p => ({
        item_id: p.productId,
        item_name: p.name,
      })),
      item_list_name: !productType
        ? 'all_products'
        : `${productType.replaceAll('_', '').toLowerCase()}_products`,
    },
  };
};

export const viewProduct = product => {
  return {
    name: EventLookup.view_item,
    params: {
      currency: 'AUD',
      items: [
        {
          item_id: product.productId,
          item_name: product.name,
          affiliation: 'Sypht Marketplace',
          item_brand: 'Sypht',
          item_category: 'AI Product',
        },
      ],
    },
  };
};

// FREE TRIAL (ALL)
const baseFreeTrial = (eventName, product) => {
  return {
    name: eventName,
    params: {
      currency: 'AUD',
      items: [
        {
          item_id: product.productId,
          item_name: product.name,
          ...BASE_PRODUCT,
          item_category: 'AI Product',
          item_variant: 'Free Trial',
          price: 0,
        },
      ],
      value: 0,
    },
  };
};

export const activateFreeTrial = product => {
  return baseFreeTrial(EventLookup.add_to_cart, product);
};

export const cancelActivateFreeTrial = product => {
  return baseFreeTrial(EventLookup.remove_from_cart, product);
};

export const confirmActivateFreeTrial = product => {
  return baseFreeTrial(EventLookup.purchase, product);
};

// SUBSCRIPTION (AI PRODUCTS)
const baseAiProduct = (eventName, product) => {
  const accessFee = product.pricing.find(p => p.type === 'access_fee');
  const meteredPrice = product.pricing.find(p => p.type === 'metered');
  return {
    name: eventName,
    params: {
      currency: 'AUD',
      items: [
        {
          item_id: product.productId,
          item_name: product.name,
          ...BASE_PRODUCT,
          item_category: 'AI Product',
          item_variant: 'Subscription',
          price:
            meteredPrice && meteredPrice.tiers && meteredPrice.tiers[0]
              ? meteredPrice.tiers[0].unitPrice
              : undefined,
        },
      ],
      value: accessFee && accessFee.accessFee ? Number(accessFee.accessFee) : 0,
    },
  };
};

export const addAiProductToCart = product => {
  return baseAiProduct(EventLookup.add_to_cart, product);
};

export const beginCheckoutAiProduct = product => {
  return baseAiProduct(EventLookup.begin_checkout, product);
};

export const cancelCheckoutAiProduct = product => {
  return baseAiProduct(EventLookup.remove_from_cart, product);
};

export const confirmCheckoutAiProduct = product => {
  return baseAiProduct(EventLookup.purchase, product);
};

// LEADS
export const talkToSalesClick = product => {
  const accessFee = product.pricing.find(p => p.type === 'access_fee');
  return {
    name: EventLookup.generate_lead,
    params: {
      currency: 'AUD',
      value: accessFee && accessFee.accessFee ? Number(accessFee.accessFee) : 0,
      item_id: product.productId,
      item_name: product.name,
      engagement_type: 'talk_to_sales',
    },
  };
};
