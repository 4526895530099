import React, { useEffect, useRef, useState } from 'react';
import Modal from '../UI/ModalV2';
import Button from '../UI/Button/Button';
import AppStoreBadge from './assets/app-store-badge.png';
import GooglePlayBadge from './assets/google-play-badge.png';
import '../UI/ModalV2/Modal.scss';
import './MfaForm.scss';

const MfaModal = props => {
  const focusRef = useRef(null);
  const [open, setOpen] = useState(true);

  const handleConfirmClick = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (focusRef.current) focusRef.current.focus();
  }, [focusRef.current]);

  return (
    <Modal
      className="modal--mfa"
      heading="Multi-factor authentication on Sypht"
      open={open}
      {...props}
    >
      <div className="modal__body">
        <p className="modal__text">
          You can now enable multi-factor authentication on your Sypht account
          using Google Authenticator. Multi-factor authentication gives you an
          extra layer of security by adding a step to the login flow. You will
          need an Apple or Android smartphone with Google Authenticator
          installed to use multi-factor authentication.
        </p>
        <ul className="modal__app-list">
          <li className="modal__app-item">
            <a
              className="modal__app-item-link"
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={GooglePlayBadge}
                className="modal__app-item-badge"
                alt="Google Play"
              />
            </a>
          </li>
          <li className="modal__app-item">
            <a
              href="https://itunes.apple.com/app/google-authenticator/id388497605#?platform=iphone"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={AppStoreBadge}
                className="modal__app-item-badge"
                alt="Apple App Store"
              />
            </a>
          </li>
        </ul>
        <p className="modal__text">
          Once you have Google Authenticator installed on your smartphone you
          can enable MFA here by doing the following:
        </p>
        <ol className="modal__list">
          <li className="modal__list-item">Click 'Edit' on your proile page</li>
          <li className="modal__list-item">
            Toggle the switch next to 'Enable MFA'
          </li>
          <li className="modal__list-item">Click 'Save'</li>
        </ol>
        <p className="modal__text">
          Once MFA is enabled for your account you will be prompted to configure
          Google Authenticator on your next login attempt. After submitting your
          username and password, a screen will be displayed with a QR code that
          you scan with the Google Authenticator app. Once you have scanned your
          QR code, your account will be linked to your smartphone.
        </p>
        <p className="modal__text modal__text--tcs">
          Google Play and the Google Play logo are trademarks of Google LLC.
          <br />
          Apple, the Apple logo, are trademarks of Apple Inc., registered in the
          U.S. and other countries and regions. App Store is a service mark of
          Apple Inc.
        </p>
      </div>
      <div className="modal__footer modal__footer--cta">
        <Button className="modal__button" onClick={handleConfirmClick}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default MfaModal;
