import React, { Fragment } from 'react';
import { STYLE } from '../../components/UI/Notification/Notification';

export const createdMessage = name => ({
  style: STYLE.CONFIRMATION,
  message: (
    <>
      Tag <strong>{name}</strong> has been created.
    </>
  ),
  timeout: 8000,
});

export const updatedMessage = (name, newName) => ({
  style: STYLE.CONFIRMATION,
  message:
    newName && newName !== name ? (
      <>
        Tag <strong>{name}</strong> has been renamed to{' '}
        <strong>{newName}</strong>.
      </>
    ) : (
      <>
        Tag <strong>{name}</strong> has been updated.
      </>
    ),
  timeout: 8000,
});

export const deletedMessage = name => ({
  style: STYLE.CONFIRMATION,
  message: (
    <>
      Tag <strong>{name}</strong> has been deleted.
    </>
  ),
  timeout: 8000,
});

export const batchUpdatedMessage = noOfTags => ({
  style: STYLE.CONFIRMATION,
  message: (
    <>
      Updated <strong>{noOfTags}</strong> tag(s).
    </>
  ),
  timeout: 8000,
});

export const batchDeletedMessage = noOfTags => ({
  style: STYLE.CONFIRMATION,
  message: (
    <>
      Deleted <strong>{noOfTags}</strong> tag(s).
    </>
  ),
  timeout: 8000,
});

export const duplicateErrorMessage = name => ({
  style: STYLE.ERROR,
  message: (
    <>
      Tag <strong>{name}</strong> already exists.
    </>
  ),
  timeout: 13000,
});

export const errorMessage = {
  style: STYLE.ERROR,
  message:
    'Something went wrong, please try again. If this issue persists ' +
    'please contact us at support@sypht.com.',
  timeout: 8000,
};

export const validationError = {
  style: STYLE.ERROR,
  message: 'Please complete all required fields.',
  timeout: 8000,
};

export const apiError = error => {
  return {
    style: STYLE.ERROR,
    message: error,
    timeout: 13000,
  };
};
