import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './Indicator.scss';

const Indicator = ({ variant }) => {
  return <div className={classNames('indicator', `indicator--${variant}`)} />;
};

Indicator.propTypes = {
  variant: PropTypes.oneOf(['default', 'success', 'warning', 'danger']),
};

Indicator.defaultProps = {
  variant: 'default',
};

export default Indicator;
