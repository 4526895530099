import React from 'react';
import PropTypes from 'prop-types';
import ConfirmModal from '../../../../../components/UI/ModalV2/ConfirmModal';

const DeleteModal = ({ value, onConfirmClick, ...props }) => {
  if (value) {
    return (
      <ConfirmModal
        className="email-integration__delete-modal"
        heading="Delete email address"
        confirmText="Continue"
        onConfirmClick={onConfirmClick}
        {...props}
      >
        Are you sure you want to delete <strong>{value}</strong>?
      </ConfirmModal>
    );
  }
};

DeleteModal.propTypes = {
  tag: PropTypes.object,
  onConfirmClick: PropTypes.func,
};

DeleteModal.defaultProps = {
  tag: null,
  onConfirmClick: () => {},
};

export default DeleteModal;
