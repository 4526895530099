import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './_skeleton.scss';

const SkeletonTable = ({ className, noOfRows }) => {
  const rows = [...Array(noOfRows)].map((o, i) => i);
  return (
    <div className={classNames('skeleton__table', className)}>
      <div className="skeleton__table-header">
        <div className="skeleton__checkbox" />
      </div>
      <div className="skeleton__table-body">
        {rows.map(o => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="skeleton__table-row" key={`skeletonRow|${o}`}>
            <div className="skeleton__checkbox" />
            <div className="skeleton__text" />
          </div>
        ))}
      </div>
      <div className="skeleton__header" />
    </div>
  );
};

SkeletonTable.propTypes = {
  className: PropTypes.string,
  noOfRows: PropTypes.number,
};

SkeletonTable.defaultProps = {
  className: null,
  noOfRows: 5,
};

export default SkeletonTable;
