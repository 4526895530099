import { addNotification } from '../reducers/notifications';
import { STYLE } from '../components/UI/Notification/Notification';

import { RECEIVE_UPLOAD_DOC } from '../reducers/upload';
import { RECEIVE_TASK } from '../reducers/tasks';
import {
  RECEIVE_EXTRACT,
  RECEIVE_DOC_EXPORT,
  RECEIVE_DOCS_DELETED,
  RECEIVE_DOC_DOWNLOAD,
} from '../reducers/docs';

/**
 * This manages notifications for the whole site.
 * To add a notification, listen here for the respective event and dispatch the corresponding action
 * @param store
 * @returns {function(*): function(*=): *}
 */
const notificationManager = store => next => action => {
  const { dispatch } = store;

  if (action.type === RECEIVE_UPLOAD_DOC) {
    if (action.error) {
      dispatch(
        addNotification({
          style: STYLE.ERROR,
          message: action.error.response.data.message,
        }),
      );
    }
  } else if (action.type === RECEIVE_TASK) {
    const candidateError = !action.candidates;
    const predictionError = !action.predictions || action.predictions.error;
    if (candidateError && predictionError) {
      dispatch(
        addNotification({
          style: STYLE.WARNING,
          message:
            'Suggestions and token selection are not available for this document.',
        }),
      );
    } else if (predictionError) {
      dispatch(
        addNotification({
          style: STYLE.WARNING,
          message: 'Suggestions are not available for this document.',
        }),
      );
    } else if (candidateError) {
      dispatch(
        addNotification({
          style: STYLE.WARNING,
          message: 'Token selection is not available for this document.',
        }),
      );
    }
  } else if (action.type === RECEIVE_EXTRACT) {
    if (action.data.response && action.data.response.status === 403) {
      dispatch(
        addNotification({
          style: STYLE.ERROR,
          message: action.data.response.data.message,
        }),
      );
    } else if (
      !(action.data.docsExtracted && action.data.docsExtracted.length > 0)
    ) {
      dispatch(
        addNotification({
          style: STYLE.ERROR,
          message: `An error occured with extracting. Please try again later.`,
        }),
      );
    }
  } else if (action.type === RECEIVE_DOC_EXPORT) {
    if (action.data) {
      dispatch(
        addNotification({
          style: STYLE.CONFIRMATION,
          message: 'Exported csv file ready.',
        }),
      );
    } else if (action.error) {
      dispatch(
        addNotification({
          style: STYLE.ERROR,
          message: 'An error occured exporting, please try again later..',
        }),
      );
    }
  } else if (action.type === RECEIVE_DOCS_DELETED) {
    if (action.data) {
      if (action.data.length > 1) {
        dispatch(
          addNotification({
            style: STYLE.CONFIRMATION,
            message: `${action.data.length} documents deleted.`,
          }),
        );
      } else {
        dispatch(
          addNotification({
            style: STYLE.CONFIRMATION,
            message: `${action.data[0].filename} deleted.`,
          }),
        );
      }
    } else if (action.error) {
      dispatch(
        addNotification({
          style: STYLE.ERROR,
          message: 'An error occured, please try again later.',
        }),
      );
    }
  } else if (action.type === RECEIVE_DOC_DOWNLOAD) {
    if (action.data) {
      dispatch(
        addNotification({
          style: STYLE.CONFIRMATION,
          message: `${action.data.filename} downloaded`,
        }),
      );
    } else if (action.error) {
      dispatch(
        addNotification({
          style: STYLE.ERROR,
          message: 'An error occured downloading file.',
        }),
      );
    }
  }
  return next(action);
};

export default notificationManager;
