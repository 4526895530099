import * as utils from './utils';

const apiBaseUrl = process.env.APP_API_HOST_NAME;

class CredentialService {
  createCredential = (accessToken, credential, companyId) => {
    return utils.postData(`${apiBaseUrl}/app/company/${companyId}/keys`, {
      accessToken,
      data: credential,
    });
  };

  getCredentials = (accessToken, companyId) => {
    return utils.getData(`${apiBaseUrl}/app/company/${companyId}/keys`, {
      accessToken,
    });
  };

  updateCredential = (accessToken, clientId, credential, companyId) => {
    return utils.putData(
      `${apiBaseUrl}/app/company/${companyId}/keys/${clientId}`,
      {
        accessToken,
        data: credential,
      },
    );
  };

  deleteCredential = (accessToken, clientId, companyId) => {
    return utils.deleteData(
      `${apiBaseUrl}/app/company/${companyId}/keys/${clientId}`,
      {
        accessToken,
      },
    );
  };

  getSecret = (accessToken, clientId, companyId) => {
    return utils.getData(
      `${apiBaseUrl}/app/company/${companyId}/keys/${clientId}/secret`,
      {
        accessToken,
      },
    );
  };
}

export default new CredentialService();
