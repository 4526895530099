import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './_accordion.scss';

const Accordion = ({ children, variant, isOpen, instantlyOpen }) => {
  const accordionRef = useRef(null);

  useEffect(() => {
    if (accordionRef.current && isOpen) {
      if (instantlyOpen) {
        accordionRef.current.classList.add('accordion--open');
        return;
      }
      setTimeout(() => {
        accordionRef.current.classList.add('accordion--open');
      }, 300);
      return;
    }
    if (accordionRef.current && !isOpen) {
      accordionRef.current.classList.remove('accordion--open');
    }
  }, [instantlyOpen, isOpen]);

  return (
    <div className="accordion" ref={accordionRef}>
      {React.Children.map(children, elem =>
        React.cloneElement(elem, { isOpen, variant }),
      )}
    </div>
  );
};

Accordion.propTypes = {
  variant: PropTypes.oneOf(['default', 'padded-with-line']),
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  instantlyOpen: PropTypes.bool,
};

Accordion.defaultProps = {
  variant: 'default',
  children: null,
  isOpen: false,
  instantlyOpen: false,
};

export default Accordion;
