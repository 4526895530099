import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FaInfoCircle, FaChevronRight } from 'react-icons/fa';
import Accordion from '../../organisms/Accordion';
import './field.scss';

const AccordionField = ({
  name,
  tooltip,
  additionalText,
  onClick,
  isOpen,
  content,
}) => {
  return (
    <Accordion isOpen={isOpen} instantlyOpen>
      <Accordion.Header>
        <button className="field2__button" onClick={onClick} type="button">
          <div className="field2">
            <div className="field2__section">
              <h3 className="field2__header">{name}</h3>
              <FaInfoCircle
                className="field2__info-icon"
                data-tooltip-id="document-tooltip"
                data-tooltip-html={tooltip}
                data-tooltip-place="right"
              />
            </div>
            {additionalText && (
              <div className="field2__section">
                <p className="field2__text">{additionalText}</p>
              </div>
            )}
          </div>
          <FaChevronRight
            className={classNames('field2__acc-button', isOpen && 'is-open')}
          />
        </button>
      </Accordion.Header>
      <Accordion.Body>
        <div className="field2 field2__bottom">{content}</div>
      </Accordion.Body>
    </Accordion>
  );
};

AccordionField.propTypes = {
  name: PropTypes.string.isRequired,
  tooltip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  additionalText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  content: PropTypes.node.isRequired,
};

AccordionField.defaultProps = {
  tooltip: undefined,
  additionalText: undefined,
};

export default AccordionField;
