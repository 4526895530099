import { REQUEST_LOOKUP_VALUES, RECEIVE_LOOKUP_VALUES } from './actions';

const lookupsReducer = (state = {}, action) => {
  const { payload, type } = action;

  switch (type) {
    case REQUEST_LOOKUP_VALUES: {
      const { lookupType, loading } = payload;
      return {
        ...state,
        [lookupType]: {
          loading,
        },
      };
    }

    case RECEIVE_LOOKUP_VALUES: {
      const { lookupType, data, loading, receivedAt } = payload;
      return {
        ...state,
        [lookupType]: {
          loading,
          data,
          lastUpdated: receivedAt,
        },
      };
    }

    default:
      return state;
  }
};

export default lookupsReducer;
