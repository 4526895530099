/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

const showHeaderRow = headers => {
  for (const header of headers) {
    if (header.value !== '') {
      return true;
    }
  }
  return false;
};

const TableData = ({ id, table }) => {
  if (!table) return null;
  const { headers, rows, categories } = table;

  return (
    <div className="table-data-container">
      <div className="table-data">
        {categories && (
          <div className="table-row">
            {(categories || []).map(c => {
              return (
                <div className="table-cell table-cell-header" key={uuid()}>
                  {c}
                </div>
              );
            })}
          </div>
        )}
        {showHeaderRow(headers) && (
          <div className="table-row">
            {(headers || []).map(h => {
              return (
                <div className="table-cell table-cell-header" key={h.key}>
                  {h.value}
                </div>
              );
            })}
          </div>
        )}

        {(rows || []).map((r, i) => {
          return (
            <div className="table-row" key={r.key}>
              {(r.cells || []).map(c => (
                <div className="table-cell" key={c.key}>
                  {c.value || ''}
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

TableData.propTypes = {
  id: PropTypes.string,
  table: PropTypes.object,
};

TableData.defaultProps = {
  id: null,
  table: null,
};

export default TableData;
