import {
  RECEIVE_INBOUND_EMAIL,
  REQUEST_EMAIL_LIST,
  RECEIVE_EMAIL_LIST,
  RECEIVE_NEW_EMAIL,
  RECEIVE_DELETED_EMAIL,
  CLEAR_ERROR,
  RECEIVE_ERROR,
} from './actions';

const initialState = {
  inboundEmail: null,
  emailAddresses: {
    data: [],
    isLoading: false,
  },
  errors: null,
};

const emailReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case RECEIVE_INBOUND_EMAIL: {
      const { emailAddress } = payload;
      return {
        ...state,
        inboundEmail: emailAddress,
      };
    }

    case REQUEST_EMAIL_LIST: {
      return {
        ...state,
        emailAddresses: {
          ...state.emailAddresses,
          isLoading: true,
        },
      };
    }

    case RECEIVE_EMAIL_LIST: {
      const { emailAddresses } = payload;
      return {
        ...state,
        emailAddresses: {
          data: emailAddresses,
          isLoading: false,
        },
      };
    }

    case RECEIVE_NEW_EMAIL: {
      const { emailAddress } = payload;
      const existingEmailAddresses = state.emailAddresses.data;
      return {
        ...state,
        emailAddresses: {
          data: [emailAddress, ...existingEmailAddresses],
          isLoading: false,
        },
      };
    }

    case RECEIVE_DELETED_EMAIL: {
      const { id } = payload;
      const updatedEmailAddresses = [...state.emailAddresses.data];
      updatedEmailAddresses.splice(
        state.emailAddresses.data.findIndex(e => e.id === id),
        1,
      );

      return {
        ...state,
        emailAddresses: {
          data: updatedEmailAddresses,
          isLoading: false,
        },
      };
    }

    case CLEAR_ERROR: {
      return {
        ...state,
        errors: null,
      };
    }

    case RECEIVE_ERROR: {
      const { error } = payload;
      return {
        ...state,
        errors: { ...error },
      };
    }

    default:
      return state;
  }
};

export default emailReducer;
