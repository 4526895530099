const getValue = prediction => {
  let value = prediction.value_norm ? prediction.value_norm : prediction.norm;
  if (!value) return value;

  value = (
    value instanceof Object
      ? Object.keys(value)
          .filter(k => value[k].value === true)
          .join(';')
      : String(value)
  ).replace(/"/g, '""');

  if (value.search(/("|,|\n)/g) >= 0) return `"${value}"`;
  return value;
};

const genericData = genStructure => {
  const { fields, entities } = genStructure;
  const [header, row] = [[], []];

  fields.forEach(f => {
    const entity = (entities[f.label].text || '').toTitleCase();
    const value = entities[f.value].text;
    header.push(entity);
    row.push(value.search(/("|,|\n)/g) >= 0 ? `"${value}"` : value);
  });

  return [header, row];
};

export const getCsvFromDoc = doc => {
  const predictions = doc.predicted.results;
  const baseData = {
    docId: doc.docId,
    fileName: doc.filename,
    numOfPages: doc.received.numOfPages,
    timestamp: doc.received.timestamp,
  };

  const header = Object.keys(baseData)
    .concat(predictions.map(p => p.field))
    .join(',');

  const row = `\r\n${Object.values(baseData)
    .concat(predictions.map(p => getValue(p.predictions[0])))
    .join(',')}`;

  const genStructure = predictions.find(p => p.field === 'generic.structure');
  if (genStructure) {
    const [genHeader, genRow] = genericData(genStructure.predictions[0].value);
    return header + genHeader + row + genRow;
  }

  return header + row;
};
