import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './RadioInput.scss';

const RadioInput = ({ id, errors, dataTip, forwardRef, ...props }) => {
  const { label, name } = props;
  const fieldId = id || name;

  return (
    <div
      className={classNames('input-radio', {
        'form__group--error': !!errors[name],
      })}
    >
      <input id={fieldId} type="radio" ref={forwardRef} {...props} />
      {label ? (
        <label className="form__input-label radio-label" htmlFor={fieldId}>
          {label}
        </label>
      ) : null}
    </div>
  );
};

RadioInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  dataTip: PropTypes.string,
  forwardRef: PropTypes.object,
};

RadioInput.defaultProps = {
  id: null,
  label: PropTypes.string,
  value: '',
  disabled: false,
  errors: {},
  dataTip: null,
  forwardRef: null,
};

export default RadioInput;
