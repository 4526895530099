/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import MdEditor from 'react-markdown-editor-lite';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import MarkdownIt from 'markdown-it';
import PropTypes from 'prop-types';
import { ProductType } from '../../../../common/types';

import { normalisePricingTiers } from '../../../../common/helpers/billingHelper';
import generateFriendlyString from '../../../../common/helpers/generateFriendlyString';
import { fetchCompanies } from '../../../../reducers/docs';
import { fetchFieldsets } from '../../../../reducers/fieldsets/actions';
import { createProduct } from '../../../../reducers/product/actions';

import Form, {
  CreatableSelect,
  SelectInput,
  TextArea,
  TextInput,
  Toggle,
  ToggleGroup,
  useForm,
} from '../../../Form';
import Page, {
  PageBody,
  PageHeader,
  PageInner,
  PageLoading,
  PageNav,
} from '../../../Page';
import Button from '../../../UI/Button/Button';
import PricingTable from '../PricingTable/PricingTable';

import 'react-markdown-editor-lite/lib/index.css';
import '../../../Form/components/Markdown/Markdown.scss';
import './Admin.scss';

const mdParser = new MarkdownIt();

const INITIAL_STATE = {
  companyId: {
    value: '',
    required: true,
  },
  name: {
    value: '',
    required: true,
  },
  productId: {
    value: '',
  },
  description: {
    value: '',
  },
  'fieldsets[]': {
    value: [],
  },
  'tags[]': {
    value: [],
  },
  tileImageUrl: {
    value: '',
  },
  imageUrl: {
    value: '',
  },
  defaultSortIdx: {
    value: undefined,
  },
  content: {
    value: '',
  },
  documentation: {
    value: '',
  },
  publishOnMarketplace: {
    value: false,
  },
  accessFee: {
    value: undefined,
  },
  pricing: {
    value: undefined,
  },
  // 'userScopes[]': {
  //   value: [],
  // },
  // 'apiScopes[]': {
  //   value: [],
  // },
  productType: {
    value: ProductType.Capture,
  },
};

const mapStateToProps = (state, props) => {
  const { auth, docs, fieldsets, product } = state;
  const { companies } = docs;
  const { history } = props;

  return {
    companyId: auth.claims.companyId,
    companies: companies.data,
    fieldsets: fieldsets.data || [],
    fieldsetsLoading: fieldsets.loading,
    history,
    isLoading: product.loading,
    isSent: product.sent,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      fetchCompanies: () => dispatch(fetchCompanies()),
      fetchFieldsets: () => dispatch(fetchFieldsets()),
      createProduct: product => dispatch(createProduct(product)),
    },
  };
};

const CreateProduct = ({
  companyId,
  companies,
  fieldsets,
  fieldsetsLoading,
  history,
  isLoading,
  isSent,
  dispatch,
}) => {
  const [form] = useForm({
    ...INITIAL_STATE,
    companyId: {
      value: companyId,
      required: true,
      isValid: !!companyId,
    },
  });
  const { fields, errors } = form;
  const [fieldsetError, setFieldsetError] = useState({});
  const fieldsetRef = useRef(null);
  const productNameRef = useRef(null);
  const fieldsetOptions = (fieldsets || []).map(f => ({
    value: f.id,
    label: f.id,
  }));

  const companyOptions = (companies || []).map(company => ({
    label: company.name,
    value: company.id,
  }));
  const [pricingRows, setPricingRows] = useState([]);

  const productTypeOptions = [
    {
      label: 'Capture AI',
      value: ProductType.Capture,
    },
    {
      label: 'Signals AI',
      value: ProductType.Signals,
    },
    {
      label: 'Tools',
      value: ProductType.Platform,
    },
  ];

  const setProductId = id => {
    const suggestedProductId = generateFriendlyString(id);
    form.handleChange({
      target: {
        name: 'productId',
        value: suggestedProductId,
      },
    });
  };

  useEffect(() => {
    if (!fieldsetsLoading && !(fieldsets || []).length) {
      dispatch.fetchFieldsets();
    }
    if (!(companies || []).length) {
      dispatch.fetchCompanies();
    }
  }, [companies, dispatch, fieldsets, fieldsetsLoading]);

  useEffect(() => {
    const pricing = [];
    if (
      (fields.productType.value === ProductType.Capture ||
        fields.productType.value === ProductType.Signals) &&
      fields.accessFee.value
    ) {
      pricing.push({
        type: 'access_fee',
        accessFee: fields.accessFee.value,
      });
    }

    if (
      (fields.productType.value === ProductType.Capture ||
        fields.productType.value === ProductType.Signals) &&
      pricingRows.length > 0
    ) {
      pricing.push({ type: 'metered', tiers: pricingRows });
    }

    if (pricing.length > 0) {
      form.handleChange({
        target: {
          name: 'pricing',
          value: pricing,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricingRows, fields.accessFee, fields.productType]);

  useEffect(() => {
    if (fields.name.value) {
      setProductId(fields.name.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.name]);

  const handleSubmit = e => {
    e.preventDefault();

    const productData = {
      companyId: fields.companyId.value, // uuid
      name: fields.name.value, // string
      productId:
        fields.productId.value || generateFriendlyString(fields.name.value), // string
      description: fields.description.value, // string
      fieldsets: fields['fieldsets[]'].value || null, // string[]
      tags: fields['tags[]'].value || null, // string[]
      tileImageUrl: fields.tileImageUrl.value || null, // string,
      imageUrl: fields.imageUrl.value || null, // string,
      content: fields.content.value || null, // string,
      documentation: fields.documentation.value || null, // string
      defaultSortIdx: fields.defaultSortIdx.value
        ? Number(fields.defaultSortIdx.value)
        : null, // number,
      publishOnMarketplace: fields.publishOnMarketplace.value || false, // bool
      productType: fields.productType.value,
    };

    const pricing = fields.pricing.value;
    if (pricing && pricing.length > 0) {
      productData.pricing = pricing.map(p =>
        p.type === 'metered'
          ? { ...p, tiers: normalisePricingTiers(p.tiers) }
          : p,
      );
    }

    // Focus on product name if failed validation
    if (productData.name.length <= 0) {
      productNameRef.current.focus();
      return;
    }

    // Validate fieldsets on submit
    if (
      (productData.productType === ProductType.Capture ||
        productData.productType === ProductType.Signals) &&
      productData.fieldsets.length <= 0
    ) {
      setFieldsetError({
        'fieldsets[]': 'Please add at least one fieldset.',
      });
      fieldsetRef.current.focus();
      return;
    }

    setFieldsetError({});

    dispatch.createProduct(productData);
  };

  const handleProductIdChange = e => {
    setProductId(e.target.value);
  };

  const handleEditorChange = ({ text }, name) => {
    form.handleChange({
      target: { name, value: text },
    });
  };

  const handleSelectChange = (option, name) => {
    form.handleChange({
      target: { name, value: (option || []).map(o => o.value) },
    });
  };

  if (isSent) {
    history.push('/marketplace/admin/products');
  }

  return (
    <Page
      className="marketplace marketplace-form"
      title="Create Product - Marketplace Admin"
    >
      <PageInner>
        <PageNav
          items={[
            { label: 'Marketplace', to: '/marketplace' },
            { label: 'Admin', to: '/marketplace/admin' },
            { label: 'Products', to: '/marketplace/admin/products' },
            {
              label: 'Create product',
              to: `/marketplace/admin/products/create`,
            },
          ]}
        />
        <PageHeader>
          <h1 className="page__heading">
            <span className="text--grey">Create product</span>
          </h1>
        </PageHeader>
        <PageBody>
          <Form onSubmit={handleSubmit}>
            <SelectInput
              name="productType"
              className="select"
              label="Product Type"
              value={fields.productType.value}
              errors={errors}
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              options={productTypeOptions}
            />
            <SelectInput
              className="select--creatable"
              label="Company Id *"
              name="companyId"
              placeholder="Company Id"
              value={fields.companyId.value}
              errors={errors}
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              options={companyOptions}
            />
            <TextInput
              label="Product name"
              name="name"
              value={fields.name.value}
              errors={errors}
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              forwardRef={productNameRef}
              required
            />
            <TextInput
              label="Product Id"
              name="productId"
              value={fields.productId.value}
              errors={errors}
              onBlur={e => handleProductIdChange(e)}
              onChange={e => handleProductIdChange(e)}
            />
            <TextArea
              label="Description"
              name="description"
              value={fields.description.value}
              errors={errors}
              onBlur={form.handleBlur}
              onChange={form.handleChange}
            />
            {(fields.productType.value === ProductType.Capture ||
              fields.productType.value === ProductType.Signals) && (
              <CreatableSelect
                label="Fieldsets *"
                name="fieldsets[]"
                value={fields['fieldsets[]'].value.map(f => ({
                  value: f,
                  label: f,
                }))}
                options={fieldsetOptions}
                errors={fieldsetError}
                forwardRef={fieldsetRef}
                isClearable={false}
                onChange={e => handleSelectChange(e, 'fieldsets[]')}
              />
            )}
            <CreatableSelect
              label="Tags"
              name="tags[]"
              value={fields['tags[]'].value.map(f => ({
                value: f,
                label: f,
              }))}
              isClearable={false}
              onChange={e => handleSelectChange(e, 'tags[]')}
            />
            <TextInput
              label="Tile image url"
              name="tileImageUrl"
              value={fields.tileImageUrl.value}
              errors={errors}
              onBlur={form.handleBlur}
              onChange={form.handleChange}
            />
            <TextInput
              label="Product image url"
              name="imageUrl"
              value={fields.imageUrl.value}
              errors={errors}
              onBlur={form.handleBlur}
              onChange={form.handleChange}
            />
            <div className="form__group">
              <span className="form__input-label form__input-label--md">
                Content
              </span>
              <MdEditor
                value={fields.content.value}
                renderHTML={text => mdParser.render(text)}
                onChange={e => handleEditorChange(e, 'content')}
              />
            </div>
            <div className="form__group">
              <span className="form__input-label form__input-label--md">
                Documentation
              </span>
              <MdEditor
                value={fields.documentation.value}
                renderHTML={text => mdParser.render(text)}
                onChange={e => handleEditorChange(e, 'documentation')}
              />
            </div>
            <TextInput
              label="Sort order"
              name="defaultSortIdx"
              value={fields.defaultSortIdx.value}
              errors={errors}
              onBlur={form.handleBlur}
              onChange={form.handleChange}
            />
            <ToggleGroup
              label="Published on marketplace"
              labelFor="publishOnMarketplace"
              type="single"
            >
              <Toggle
                id="publishOnMarketplace"
                labelPosition="right"
                name="publishOnMarketplace"
                checked={fields.publishOnMarketplace.value}
                onBlur={form.handleBlur}
                onChange={e => form.handleChange(e, e.target.checked)}
              />
            </ToggleGroup>
            {(fields.productType.value === ProductType.Capture ||
              fields.productType.value === ProductType.Signals) && (
              <>
                <TextInput
                  label="Monthly Access Fee"
                  name="accessFee"
                  type="number"
                  value={fields.accessFee.value}
                  errors={errors}
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                />
                <div className="form__group">
                  <span className="form__input-label form__input-label--md">
                    Metered Usage Pricing
                  </span>
                  <PricingTable rows={pricingRows} onUpdate={setPricingRows} />
                </div>
              </>
            )}

            <div className="button-group">
              <NavLink
                to="/marketplace/admin/products"
                className="button button-secondary-border"
              >
                Cancel
              </NavLink>
              <Button
                type="submit"
                onClick={handleSubmit}
                disabled={!form.isValid}
              >
                Save
              </Button>
            </div>
          </Form>
          {isLoading && <PageLoading />}
        </PageBody>
      </PageInner>
    </Page>
  );
};

CreateProduct.propTypes = {
  companyId: PropTypes.string,
  companies: PropTypes.arrayOf(PropTypes.shape({})),
  fieldsets: PropTypes.arrayOf(PropTypes.shape({})),
  fieldsetsLoading: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  isLoading: PropTypes.bool,
  isSent: PropTypes.bool,
  dispatch: PropTypes.shape({
    createProduct: PropTypes.func,
    fetchFieldsets: PropTypes.func,
    fetchCompanies: PropTypes.func,
  }),
};

CreateProduct.defaultProps = {
  companyId: null,
  companies: null,
  fieldsets: null,
  history: {
    push: () => {},
  },
  isLoading: false,
  isSent: false,
  dispatch: {
    createProduct: () => {},
    fetchFieldsets: () => {},
    fetchCompanies: () => {},
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateProduct);
