import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import DocumentUpload from '../../molecules/DocumentUpload/DocumentUpload';
import Modal from '../../molecules/Modal/Modal';
import ModalContainer from '../../molecules/Modal/ModalContainer';
import ModalHeader from '../../molecules/Modal/ModalHeader';
import ModalFooter from '../../molecules/Modal/ModalFooter';
import ModalBody from '../../molecules/Modal/ModalBody';
import mustBeAnArrayIfPresent from '../../../common/helpers/mustBeAnArrayIfPresent';
import { Button } from '../../atoms/Buttons';

const mapStateToProps = state => {
  const { subscriptions } = state;
  return {
    products: mustBeAnArrayIfPresent(subscriptions.data) || [],
  };
};

export const DocumentUploadModal = ({
  isOpen,
  onClose,
  onCancel,
  defaultSmartSplitEnabled,
  onProcessFile,
  MaximumDocuments,
  products,
  onFilesAdded,
}) => {
  const [selectableProducts, SetSelectableProducts] = useState([]);
  const [hasFilesSelected, setHasFilesSelected] = useState(false);
  const uploadRef = useRef();

  useEffect(() => {
    const allSelectableProducts = products
      ? products
          .filter(p => !p.endDate || moment(p.endDate) > moment())
          .map(p => ({
            label: p.name,
            value: p.productId,
            selected: false,
          }))
      : [];

    SetSelectableProducts(allSelectableProducts);
  }, [products]);

  const SelectedProducts = () => selectableProducts.filter(f => f.selected);

  const onFilesAddedToModal = files => {
    setHasFilesSelected(files.length > 0);
    onFilesAdded(files);
  };

  const onFileUpload = (file, load, error, progress, isSmartSplit) => {
    onProcessFile(
      file,
      load,
      error,
      progress,
      isSmartSplit,
      isSmartSplit ? [] : SelectedProducts(),
    );
  };

  const onComplete = isSmartSplit => {
    onClose(isSmartSplit, SelectedProducts().length > 0);
  };

  const onModalConfirm = () => {
    if (hasFilesSelected) {
      if (uploadRef.current) {
        uploadRef.current.processFiles();
      }
    } else {
      onClose();
    }
  };

  const onModalCancel = () => {
    onCancel();
  };

  const onProductSelected = e => {
    const product = selectableProducts.find(f => f.value === e.value);
    if (product) {
      product.selected = !product.selected;
      SetSelectableProducts([...selectableProducts]);
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalContainer>
        <ModalHeader>Upload documents for Auto Extraction</ModalHeader>
        <ModalBody>
          <DocumentUpload
            ref={uploadRef}
            onProcessFile={onFileUpload}
            onFilesAdded={onFilesAddedToModal}
            defaultSmartSplitEnabled={defaultSmartSplitEnabled}
            onComplete={onComplete}
            MaximumDocuments={MaximumDocuments}
            products={selectableProducts}
            onProductSelected={onProductSelected}
          />
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" onClick={onModalCancel}>
            Cancel
          </Button>
          <Button disabled={!hasFilesSelected} onClick={onModalConfirm}>
            Upload
          </Button>
        </ModalFooter>
      </ModalContainer>
    </Modal>
  );
};

DocumentUploadModal.propTypes = {
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  onProcessFile: PropTypes.func,
  isOpen: PropTypes.bool,
  defaultSmartSplitEnabled: PropTypes.bool,
  MaximumDocuments: PropTypes.number,
  products: PropTypes.arrayOf(PropTypes.shape({})),
  onFilesAdded: PropTypes.func,
};

DocumentUploadModal.defaultProps = {
  onClose: () => {},
  onCancel: () => {},
  onProcessFile: () => {},
  isOpen: false,
  defaultSmartSplitEnabled: false,
  MaximumDocuments: 100,
  products: [],
  onFilesAdded: () => {},
};

export default connect(mapStateToProps, null)(DocumentUploadModal);
