import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const DateCell = ({ value: date, tooltipId }) => {
  const tooltip = date ? moment(date).format('YYYY-MM-DD hh:mm a') : undefined;
  return (
    <span data-tooltip-id={tooltipId} data-tooltip-content={tooltip}>
      {date && moment(date).format('DD MMM YY')}
    </span>
  );
};

DateCell.propTypes = {
  value: PropTypes.string,
  tooltipId: PropTypes.string,
};

DateCell.defaultProps = {
  value: undefined,
  tooltipId: undefined,
};

export default DateCell;
