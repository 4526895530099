import PropTypes from 'prop-types';

class TagsColumm {
  constructor({ Header, accessor, Cell, ...props }) {
    this.sortable = false;
    this.className = '-tags';
    this.headerClassName = '-tags';

    this.Header = Header;
    this.Cell = Cell;

    Object.entries(props).forEach(([key, value]) => {
      this[key] = value;
    });
  }
}

TagsColumm.propTypes = {
  Header: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  Cell: PropTypes.func,
};

TagsColumm.defaultProps = {
  Cell: undefined,
};

export default TagsColumm;
