import React from 'react';
import isEqual from 'react-fast-compare';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Dropdown from '../../../../components/UI/Dropdown/Dropdown';

const FilterStyles = {
  container: (base, state) => ({
    ...base,
    fontSize: '14px',
    minWidth: '150px',
  }),
  clearIndicator: base => ({
    ...base,
    color: '#5c666f',
  }),
  dropdownIndicator: base => ({
    ...base,
    color: '#5c666f',
  }),
  control: base => ({
    ...base,
    margin: '0',
    border: '2px solid #ccc',
    borderRadius: '4px',
    padding: '0',
    cursor: 'pointer',
  }),
  indicatorSeparator: base => ({
    ...base,
    width: '0',
    display: 'none',
  }),
  input: base => ({
    ...base,
    padding: '0px',
  }),
  placeholder: base => ({
    ...base,
    padding: '10px 8px',
    fontFamily: 'Roboto, sans-serif',
    color: '#5c666f',
  }),
};

const CompanyFilter = ({
  className,
  label,
  value,
  defaultValue,
  options,
  onChange: onFilterChange,
}) => {
  const filterClass = classNames(className, 'filter');
  return (
    <div className={filterClass}>
      <Dropdown
        placeholder={label}
        isClearable
        options={options}
        styles={FilterStyles}
        value={options.find(o => isEqual(o.value, value)) || null}
        onChange={filter =>
          onFilterChange(filter === null ? { value: defaultValue } : filter)
        }
      />
    </div>
  );
};

CompanyFilter.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  defaultValue: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
};

CompanyFilter.defaultProps = {
  className: '',
  onChange: () => {},
};

export default CompanyFilter;
