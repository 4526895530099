import AnalyticsService from '../../services/AnalyticsService';
import { getDailySpend } from '../../common/helpers/billingHelper';

export const RECEIVE_USAGE_STATS = 'sypht/analytics/RECEIVE_USAGE_STATS';
export const REQUEST_USAGE_STATS = 'sypht/analytics/REQUEST_USAGE_STATS';

const requestUsageStats = payload => {
  return {
    type: REQUEST_USAGE_STATS,
    loading: true,
    payload,
  };
};

const receiveUsageStats = data => {
  return {
    type: RECEIVE_USAGE_STATS,
    payload: {
      data,
      loading: false,
      receivedAt: Date.now(),
    },
  };
};

export const fetchSubscriptionStats = ({
  startDate,
  endDate,
  products,
  companyId,
}) => {
  return async (dispatch, getState) => {
    const { auth, subscriptions } = getState();
    const productsCsv = products.join(',');
    const query =
      `startDate=${startDate}&endDate=${endDate}&fieldsets=${productsCsv}`.replace(
        / /g,
        '',
      );
    const { accessToken, user } = auth;
    const reqCompanyId = user.data.companyId;

    dispatch(
      requestUsageStats({
        startDate,
        endDate,
        products,
        companyId: companyId || reqCompanyId,
      }),
    );

    let usage = null;

    if (products.length > 0) {
      usage = await AnalyticsService.getFieldsetsUsage(
        accessToken,
        companyId || reqCompanyId,
        {
          startDate,
          endDate,
          fieldSets: productsCsv,
        },
      ).then(({ data }) => {
        if (data && data.usage) {
          return getDailySpend(data.usage, subscriptions, {
            startDate,
            endDate,
          });
        }
        return undefined;
      });
    }

    dispatch(
      receiveUsageStats({
        query,
        startDate,
        endDate,
        subscriptions: subscriptions.data,
        usage: usage ? usage.dailySpend : null,
        totalSpend: usage ? usage.totalSpend : 0,
        totalPages: usage ? usage.totalPages : 0,
        totalDocs: usage ? usage.totalDocs : 0,
        companyId: companyId || reqCompanyId,
      }),
    );
  };
};
