import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { addToList, removeFromList } from '../../../common/helpers';
import { Button } from '../../../library/atoms/Buttons';
import Checkbox from '../../../library/atoms/Checkbox/Checkbox';
import Dropdown from '../../../library/molecules/Dropdown';
import {
  DropdownMenu,
  DropdownMenuBody,
  DropdownMenuFooter,
  DropdownMenuHeader,
} from '../../../library/molecules/DropdownMenu';
import './_fields-dropdown.scss';

const mapFieldRowIds = fieldset => {
  return {
    ...fieldset,
    data: {
      ...fieldset.field_versions,
      fields: fieldset.field_versions.map((field, i) => ({
        ...field,
        rowId: i,
      })),
    },
  };
};

const FieldsDropdown = ({
  id,
  className,
  fieldsets,
  defaultValues,
  onChange,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [selectedFields, setSelectedFields] = useState(defaultValues);
  const [labelCount, setLabelCount] = useState(0);
  const [availableFields, sortedFieldsets] = useMemo(() => {
    return [
      (fieldsets || [])
        .map(f => f?.field_versions)
        .flat()
        .map(f => f.field_id),
      (fieldsets || [])
        .map(f => mapFieldRowIds(f))
        .sort((a, b) =>
          a.fieldset_version.description > b.fieldset_version.description
            ? 1
            : -1,
        ),
    ];
  }, [fieldsets]);

  useEffect(() => {
    if (!availableFields.length) return;

    if (defaultValues.length) {
      setLabelCount(defaultValues.length);
      setSelectedFields(defaultValues);
      onChange(defaultValues);
      return;
    }

    setLabelCount(availableFields.length);
    setSelectedFields(availableFields);
    onChange(availableFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableFields, defaultValues]);

  const handleClick = () => {
    if (!fieldsets.length) return;

    if (!isOpen) {
      setSelectedFields(defaultValues);
    }

    setOpen(!isOpen);
  };

  const handleSelectionChange = field => {
    if (selectedFields.includes(field.field_id)) {
      setSelectedFields(removeFromList(selectedFields, field.field_id));
      return;
    }
    setSelectedFields(addToList(selectedFields, field.field_id));
  };

  const handleSelectAllClick = () => {
    if (selectedFields.length < availableFields.length) {
      setSelectedFields(availableFields);
      return;
    }
    setSelectedFields([]);
  };

  const handleSaveClick = () => {
    onChange(selectedFields);
    setLabelCount(selectedFields.length);
    setOpen(false);
  };

  return (
    fieldsets && (
      <Dropdown
        id={id}
        className={classNames('fields-dropdown', className)}
        label={`${labelCount} fields`}
        placeholder="Select extracted fields"
        type="select"
        isOpen={isOpen}
        hasValue={labelCount > 0}
        onClick={handleClick}
        // NOTE: onClear is not set, so clear button 'x' will not show
      >
        <DropdownMenu isOpen={isOpen}>
          <DropdownMenuHeader>
            <Checkbox
              label="Select all"
              checked={selectedFields.length >= availableFields.length}
              indeterminate={
                selectedFields.length > 0 &&
                selectedFields.length < availableFields.length
              }
              onChange={handleSelectAllClick}
            />
            <hr />
          </DropdownMenuHeader>
          <DropdownMenuBody>
            {(sortedFieldsets || []).map(fieldset => (
              <div className="fields-dropdown__group" key={fieldset.id}>
                <h5 className="fields-dropdown__group-label">
                  {fieldset.fieldset_version.description}
                </h5>
                <ul className="fields-dropdown__options">
                  {(fieldset?.data?.fields || []).map(field => (
                    <li
                      className="fields-dropdown__options-item"
                      key={`${fieldset.field_id}[${field.field_id}:${field.rowId}]`}
                    >
                      <Checkbox
                        checked={selectedFields.includes(field.field_id)}
                        label={field.specification.name}
                        onChange={() =>
                          handleSelectionChange(field, field.rowId)
                        }
                      />
                    </li>
                  ))}
                </ul>
              </div>
            ))}
            {!(sortedFieldsets || []).length && (
              <p className="fields-dropdown__no-options">
                No extracted fields.
              </p>
            )}
          </DropdownMenuBody>
          <DropdownMenuFooter>
            <Button
              type="button"
              variant="outline"
              disabled={selectedFields.length < 1}
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </DropdownMenuFooter>
        </DropdownMenu>
      </Dropdown>
    )
  );
};

FieldsDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  fieldsets: PropTypes.arrayOf(PropTypes.any),
  defaultValues: PropTypes.arrayOf(PropTypes.any),
  onChange: PropTypes.func,
};

FieldsDropdown.defaultProps = {
  className: undefined,
  fieldsets: null,
  defaultValues: [],
  onChange: () => {},
};

export default FieldsDropdown;
