/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState, useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import axios from 'axios';
import Highlight from './Highlight';
import Candidate from './Candidate';
import GenericConnectors from './GenericConnectors';
import './DocumentView.scss';
import { DocumentToolbarContext } from '../UI/DocumentToolbar/DocumentToolbarContext';
import usePrevious from '../../common/hooks/usePrevious';
import { TableContext } from './TableContext';
import { getAdjustedBounds, getRotatedBounds } from '../../common/helpers';
import DocumentCanvas from './DocumentCanvas';

const objectHash = require('object-hash');

const apiBaseUrl = process.env.APP_API_HOST_NAME;
const PADDING = 0.005;

const mapStateToProps = state => {
  return {
    accessToken: state.auth.accessToken,
  };
};

const Page = ({
  accessToken,
  docId,
  pageId,
  focusPrediction,
  candidateSelection,
  highlights,
  imageUrl,
  onLoad,
  disabled,
  // isTableView,
  // tableSelectionMode,
}) => {
  const [toolbarContext] = useContext(DocumentToolbarContext);
  const [tableContext] = useContext(TableContext);
  const { rotation, collapsed } = toolbarContext;
  const [fetched, setFetched] = useState(false);
  const cancelToken = useRef(null);
  const [currentMode, setCurrentMode] = useState(null);
  const [selectionBox, setSelectionBox] = useState(null);
  const selectionMode = useRef(null);
  const pageImage = useRef(null);
  const container = useRef(null);
  const [isInside, setIsInside] = useState(false);
  const containerRef = useRef(null);

  const [predictionContent, setPredictionContent] = useState({
    clipPath: null,
    sentinelStyle: {
      dispay: 'none',
    },
  });

  const drag = useRef({
    start: null,
    stop: null,
    current: null,
  });
  const predictionSentinel = useRef(null);

  const prevProps = usePrevious({ focusPrediction });

  const getBounds = () => {
    return container.current.getBoundingClientRect();
  };

  const onMouseDown = e => {
    const containerBounds = getBounds();
    const imageBounds = pageImage.current.getBoundingClientRect();

    const x1 = e.pageX - containerBounds.left;
    const y1 = e.pageY - containerBounds.top;
    const minX = imageBounds.left - containerBounds.left;
    const maxX = minX + imageBounds.width;
    const minY = imageBounds.top - containerBounds.top;
    const maxY = minY + imageBounds.height;

    if (minX <= x1 && maxX >= x1 && minY <= y1 && maxY >= y1) {
      if (e.button === 0) {
        const pos = getBounds();
        drag.current = {
          start: {
            x: (e.pageX - pos.left) / pos.width,
            y: (e.pageY - pos.top) / pos.height,
          },
          stop: null,
          current: null,
        };
      }
    }
  };

  const getSelectionBounds = () => {
    const { start, stop, current } = drag.current;
    if (start && (stop || current)) {
      const end = stop || current;

      return {
        topLeft: {
          x: Math.min(start.x, end.x),
          y: Math.min(start.y, end.y),
        },
        bottomRight: {
          x: Math.max(start.x, end.x),
          y: Math.max(start.y, end.y),
        },
        pageNum: pageId,
      };
    }
    return null;
  };

  const onMouseMove = e => {
    const containerBounds = getBounds();
    const imageBounds = pageImage.current.getBoundingClientRect();

    const x1 = e.pageX - containerBounds.left;
    const y1 = e.pageY - containerBounds.top;
    const minX = imageBounds.left - containerBounds.left;
    const maxX = minX + imageBounds.width;
    const minY = imageBounds.top - containerBounds.top;
    const maxY = minY + imageBounds.height;

    if (minX <= x1 && maxX >= x1 && minY <= y1 && maxY >= y1) {
      setIsInside(true);
      const { start, stop, current } = drag.current;
      if (start && !stop) {
        const pos = getBounds();
        drag.current = {
          start,
          stop: null,
          current: {
            x: (e.pageX - pos.left) / pos.width,
            y: (e.pageY - pos.top) / pos.height,
          },
        };
        e.stopPropagation();
        e.preventDefault();
      }
      if (start && current) {
        const dragBounds = getSelectionBounds();
        setSelectionBox({
          display: 'block',
          top: `${(dragBounds.topLeft.y * 100).toPrecision(5)}%`,
          left: `${(dragBounds.topLeft.x * 100).toPrecision(5)}%`,
          right: `${(100 - dragBounds.bottomRight.x * 100).toPrecision(5)}%`,
          bottom: `${(100 - dragBounds.bottomRight.y * 100).toPrecision(5)}%`,
        });
      }
    } else {
      setIsInside(false);
    }
  };

  const getSelectionMode = () => {
    const { focusField } = candidateSelection;
    if (focusField && focusField.field) {
      selectionMode.current = focusField.field.type;
    } else {
      selectionMode.current = null;
    }
    setCurrentMode(selectionMode.current);
  };

  useEffect(() => {
    getSelectionMode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateSelection]);

  const getAnnotatedTokenIds = () => {
    const { annotatedCandidates } = candidateSelection;
    return annotatedCandidates
      ? new Set(annotatedCandidates.map(c => c.id))
      : new Set();
  };

  const getEditingTokenIds = () => {
    const { editingCandidates } = candidateSelection;
    return candidateSelection
      ? new Set((editingCandidates || []).map(c => c.id))
      : new Set();
  };

  const getSelectableTokens = () => {
    const { candidates } = candidateSelection;
    const editingTokenIds = getEditingTokenIds();
    const annotatedTokenIds = getAnnotatedTokenIds();
    return candidates
      .filter(c => c.bounds.pageNum == pageId)
      .filter(c => editingTokenIds.has(c.id) || !annotatedTokenIds.has(c.id));
  };

  const onResolveSelection = bounds => {
    const { onAdd, onSelect } = candidateSelection;
    const adjustedBounds = getAdjustedBounds(bounds, pageId);
    const rotatedBounds = getRotatedBounds(adjustedBounds, rotation);
    if (selectionMode.current && selectionMode.current === 'bounds') {
      onAdd({
        id: objectHash(rotatedBounds),
        type: selectionMode.current,
        bounds: rotatedBounds,
      });
    } else if (selectionMode.current) {
      getSelectableTokens()
        .filter(c => {
          if (
            c.bounds.bottomRight.x < rotatedBounds.topLeft.x ||
            c.bounds.topLeft.x > rotatedBounds.bottomRight.x ||
            c.bounds.bottomRight.y < rotatedBounds.topLeft.y ||
            c.bounds.topLeft.y > rotatedBounds.bottomRight.y
          ) {
            return false;
          }
          return true;
        })
        .forEach(onSelect);
    }
  };

  const onMouseUp = e => {
    const { start, stop, current } = drag.current;
    let handled = true;

    if (start && !current) {
      drag.current = {
        start: null,
        stop: null,
        current: null,
      };
    } else if (start && !stop && current) {
      drag.current = {
        start,
        stop: current,
        current: null,
      };

      /**
       * Check user was not selecting a token to prevent
       * overriding selections. toFixed used since user's mouse
       * can be a bit off from the x and y coordinates captured
       * in drag.current.
       */
      if (
        start.x.toFixed(1) !== current.x.toFixed(1) ||
        start.y.toFixed(1) !== current.y.toFixed(1)
      ) {
        onResolveSelection(getSelectionBounds());
      }
    } else {
      handled = false;
    }
    setSelectionBox(null);

    if (handled) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  const onMouseLeave = e => {
    const { start, stop, current } = drag.current;
    if (start && !stop && current) {
      drag.current = {
        start: null,
        stop: null,
        current: null,
      };
      setSelectionBox(null);
      e.stopPropagation();
    }
  };

  const getContextTokenIds = () => {
    const { focusField } = candidateSelection;
    return new Set(
      focusField && focusField.context ? focusField.context.map(c => c.id) : [],
    );
  };

  // eslint-disable-next-line no-unused-vars
  const [height, setHeight] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [width, setWidth] = useState(null);

  const loadImageWhenReady = (pageImage, imageUrl) => {
    const MAX_RETRY_COUNT = 15;
    const MAX_RETRY_WAIT_TIME = 2000;
    const imageLoader = new Image();
    imageLoader.onload = () => {
      if (pageImage.current) {
        // eslint-disable-next-line no-param-reassign
        pageImage.current.src = imageLoader.src;
      }
    };
    imageLoader.retryCount = 1;
    imageLoader.onerror = () => {
      if (imageLoader.retryCount < MAX_RETRY_COUNT) {
        setTimeout(() => {
          imageLoader.src = imageUrl;
          imageLoader.retryCount += 1;
        }, MAX_RETRY_WAIT_TIME);
      }
    };
    imageLoader.src = imageUrl;
  };

  const initImage = element => {
    pageImage.current = element;

    // todo: page urls should be returned via doc info to facilitate CDN signed urls
    const url = `${apiBaseUrl}/app/docs/${docId}/pages/${pageId}/image`;
    cancelToken.current = axios.CancelToken.source();

    if (element && !fetched) {
      if (imageUrl && pageImage.current) {
        loadImageWhenReady(pageImage, imageUrl);
        pageImage.current.onload = () => {
          setFetched(true);
          onLoad();
        };
      } else {
        axios
          .get(url, {
            headers: { Authorization: `Bearer ${accessToken}` },
            responseType: 'blob',
            cancelToken: cancelToken.current.token,
          })
          .then(r => r.data)
          .then(imageBlob => {
            pageImage.current.src = URL.createObjectURL(imageBlob);
            setFetched(true);
            onLoad();
          });
      }

      // Keep knowledge about image height and width up to date.
    } else if (element) {
      setHeight(element.getBoundingClientRect().height);
      setWidth(element.getBoundingClientRect().width);
    }
  };

  useEffect(() => {
    if (prevProps && prevProps.focusPrediction !== focusPrediction) {
      if (
        focusPrediction &&
        focusPrediction.bounds &&
        focusPrediction.bounds.pageNum == pageId
      ) {
        const topLeftX = (
          (focusPrediction.bounds.topLeft.x - PADDING) *
          100
        ).toPrecision(5);
        const topLeftY = (
          (focusPrediction.bounds.topLeft.y - PADDING) *
          100
        ).toPrecision(5);
        const bottomRightX = (
          (focusPrediction.bounds.bottomRight.x + PADDING) *
          100
        ).toPrecision(5);
        const bottomRightY = (
          (focusPrediction.bounds.bottomRight.y + PADDING) *
          100
        ).toPrecision(5);

        setPredictionContent({
          clipPath: `polygon(
            0% 0%, 0% 100%,
            ${topLeftX}% 100%, ${topLeftX}% ${topLeftY}%,
            ${bottomRightX}% ${topLeftY}%, ${bottomRightX}% ${bottomRightY}%,
            ${topLeftX}% ${bottomRightY}%, ${topLeftX}% 100%,
            100% 100%, 100% 0)`,
          sentinelStyle: {
            display: 'block',
            top: `${topLeftY}%`,
            left: `${topLeftX}%`,
            right: `${100 - bottomRightX}%`,
            bottom: `${100 - bottomRightY}%`,
          },
        });

        // todo: avoid this
        setTimeout(
          () =>
            // eslint-disable-next-line react/no-find-dom-node
            ReactDOM.findDOMNode(predictionSentinel.current).scrollIntoView({
              block: 'center',
              behavior: 'smooth',
            }),
          0,
        );
      }
    }
  }, [focusPrediction, pageId, prevProps]);

  const showPrediction =
    focusPrediction &&
    focusPrediction.bounds &&
    focusPrediction.bounds.pageNum == pageId;

  const showOverlay =
    focusPrediction && focusPrediction.bounds && !showPrediction;
  const { candidates, selectedCandidates } = candidateSelection;
  const editingTokenIds = getEditingTokenIds();
  const annotatedTokenIds = new Set(
    [...getAnnotatedTokenIds()].filter(x => !editingTokenIds.has(x)),
  );

  const contextTokenIds = getContextTokenIds();
  const { clipPath, sentinelStyle } = predictionContent;
  const isTaskView = !highlights || highlights.length === 0;

  /**
   * Code below is to ensure image is not cut off from parent container
   * when rotating or in fit-to-view mode and selection tokens and
   * prediction overlay element are always aligned.
   */
  const image = document.getElementById(`imageElement-${pageId}`);
  const imageWidth = image ? image.width : 0;
  const imageHeight = image ? image.height : 0;

  // Makes sure max width and max height of image fits respects parent container.
  // This is to ensure image does not cut off during rotations and fits in
  // fit-to-view mode.
  let maxWidth = collapsed ? '100%' : null;
  let maxHeight = collapsed ? '100%' : null;
  const pageContainer = document.getElementById(`pageContainer-${pageId}`);
  if (pageContainer && !collapsed) {
    const pageContainerSize = pageContainer.getBoundingClientRect();
    const { width } = pageContainerSize;
    maxWidth = rotation === 0 ? '100%' : `${width}px`;
    maxHeight = rotation === 0 ? '' : maxWidth;
  }

  // When image rotated we need to recalculate margin required to fit in parent
  // container so the image is not cut off. Overlay margin also needs to be
  // recalculated to be correctly aligned with image.
  const margin = imageWidth > imageHeight ? (imageWidth - imageHeight) / 2 : 0;
  const pageMargin = rotation === 0 ? 0 : margin;
  const overlayMargin =
    (rotation !== 0 && rotation !== 180 && width > height) ||
    (rotation === 180 && height > width)
      ? `${Math.abs(width - height) / 2}px`
      : 'auto';

  // Allows tokens and overlay to match image width and height for alignment.
  const wrapperHeight =
    collapsed || rotation === 180
      ? `${height}px`
      : rotation !== 0
      ? `${width}px`
      : '100%';
  const wrapperWidth =
    rotation !== 0 && rotation !== 180 ? `${height}px` : `${width}px`;

  const overlayStyle = {
    clipPath: showPrediction ? clipPath : '',
    width: wrapperWidth,
    height: wrapperHeight,
    marginLeft: overlayMargin,
    marginRight: overlayMargin,
  };

  const showCanvas = tableContext.activeTable !== null;
  const showSelection =
    tableContext.activeTable === null || tableContext.selectionMode;

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={classNames(
        'page-container',
        disabled && 'page-container--disabled',
      )}
      ref={containerRef}
    >
      <div
        id={`pageContainer-${pageId}`}
        style={{
          position: 'relative',
          width: '100%',
          marginTop: pageMargin,
          marginBottom: pageMargin,
          height: '100%',
        }}
      >
        <div
          className={classNames(
            'page-container__inner',
            disabled && 'page-container__inner--disabled',
          )}
          style={{
            transform: `rotate(${rotation}deg`,
            transformOrigin: 'center',
            textAlign: 'center',
          }}
        >
          {!fetched && (
            <div className="loading">
              <ReactLoading
                type="spin"
                color="#dddddd"
                height={128}
                width={128}
              />
            </div>
          )}

          {/* Image view */}

          <div className={classNames('doc-image-container')}>
            <img
              id={`imageElement-${pageId}`}
              ref={initImage}
              style={{ maxWidth, maxHeight }}
            />

            {showPrediction ? (
              <div className="prediction-overlay" style={overlayStyle} />
            ) : showOverlay ? (
              <div className="prediction-overlay" style={overlayStyle} />
            ) : null}
            <div
              ref={predictionSentinel}
              className="prediction-sentinel"
              style={sentinelStyle}
            />
          </div>
        </div>

        <div className="page-container__footer">{pageId}</div>

        {/* Additional page layers: */}

        <div
          className={classNames('doc-canvas-container', {
            hide: !showCanvas,
          })}
        >
          <DocumentCanvas
            docId={docId}
            pageId={pageId}
            fetched={fetched}
            imageUrl={imageUrl}
            containerRef={containerRef}
          />
        </div>

        <div
          className={classNames('select-and-drag', {
            selecting: currentMode && isInside,
            hide: !showSelection,
          })}
          ref={container}
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          onMouseUp={onMouseUp}
          onMouseLeave={onMouseLeave}
        >
          {currentMode && selectionBox && (
            <div key={1} className="selection" style={selectionBox} />
          )}

          <div
            className="select-and-drag__inner"
            id="select-and-drag-wrapper"
            style={{
              transform: `rotate(${rotation}deg)`,
              height: wrapperHeight,
              width: wrapperWidth === '0px' ? 'auto' : wrapperWidth,
              margin: 'auto',
              transformOrigin: 'center',
            }}
          >
            {currentMode
              ? [
                  candidates
                    .filter(c =>
                      currentMode === 'bounds' ? c.type === 'bounds' : true,
                    )
                    .filter(c => c.bounds.pageNum == pageId)
                    .map((c, i) => (
                      <Candidate
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                        style={{ zIndex: '1' }}
                        candidate={c}
                        disabled={selectionBox != null}
                        isAnnotated={annotatedTokenIds.has(c.id)}
                        isContext={contextTokenIds.has(c.id)}
                        isSelected={c.id in selectedCandidates}
                        onSelect={c => candidateSelection.onSelect(c)}
                        onDeselect={c => candidateSelection.onDeselect(c)}
                      />
                    )),
                ]
              : null}
            {!isTaskView && fetched && (
              <GenericConnectors
                padding={PADDING}
                pageNum={pageId ? parseInt(pageId, 10) : 1}
              />
            )}
            {!isTaskView && fetched
              ? highlights.map((h, i) => {
                  return (
                    // eslint-disable-next-line react/no-array-index-key,react/jsx-props-no-spreading
                    <Highlight key={i} {...h}>
                      {h.content || null}
                    </Highlight>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    </div>
  );
};

Page.propTypes = {
  accessToken: PropTypes.string,
  docId: PropTypes.string,
  pageId: PropTypes.string,
  focusPrediction: PropTypes.shape({
    bounds: PropTypes.shape({
      pageNum: PropTypes.number,
      topLeft: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
      }),
      bottomRight: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
      }),
    }),
  }),
  candidateSelection: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  highlights: PropTypes.arrayOf(PropTypes.any),
  imageUrl: PropTypes.string,
  onLoad: PropTypes.func,
  disabled: PropTypes.bool,
  // isTableView: PropTypes.bool,
};
Page.defaultProps = {
  accessToken: null,
  docId: '',
  pageId: '',
  focusPrediction: null,
  highlights: null,
  candidateSelection: null,
  imageUrl: null,
  onLoad: () => {},
  disabled: true,
  // isTableView: false,
};

export default connect(mapStateToProps)(Page);
