import React from 'react';
import DocumentListLoader from '../../../../molecules/DocumentListLoader/DocumentListLoader';

const StatusCell = () => {
  return (
    <div>
      <DocumentListLoader label="Splitting Document" />
    </div>
  );
};

StatusCell.propTypes = {};

StatusCell.defaultProps = {};

export default StatusCell;
