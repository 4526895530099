import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../UI/Modal/Modal';
import './DeleteDocsModal.scss';

const DeleteDocsModal = ({
  deleteDocs,
  selectedDocs,
  isOpen,
  onCancelModal,
  onCloseModal,
}) => {
  const docs = selectedDocs ? Object.keys(selectedDocs) : [];

  const onClose = () => {
    onCloseModal('isDeleteModalOpen', true); // refresh doc list
  };

  const onCancel = () => {
    onCancelModal('isDeleteModalOpen');
  };

  const onConfirm = () => {
    return deleteDocs(docs).then(() => {
      onClose();
    });
  };

  return (
    <Modal
      showCloseBtn
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onCancel}
      onConfirm={onConfirm}
      className="modal-delete"
      confirmLabel="Delete"
      cancelLabel="Cancel"
      modalLabel={`You are deleting ${docs.length} ${
        docs.length > 1 ? 'documents' : 'document.'
      }`}
    >
      <div className="modal-delete-content">
        <p className="modal-delete-info">
          Are you sure you want to delete the selected documents?
        </p>
      </div>
    </Modal>
  );
};

DeleteDocsModal.propTypes = {
  deleteDocs: PropTypes.func.isRequired,
  selectedDocs: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCancelModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default DeleteDocsModal;
