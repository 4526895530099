import React, { useRef, useImperativeHandle } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { showRipple } from '../../../common/helpers';
import './_button.scss';

const Button = React.forwardRef(
  (
    {
      children,
      className,
      color,
      size,
      type,
      variant,
      isResponsive,
      onClick,
      fluid,
      ...props
    },
    ref,
  ) => {
    const buttonRef = useRef(null);
    useImperativeHandle(ref, () => buttonRef.current);

    const handleClick = e => {
      showRipple(buttonRef.current, e);
      onClick(e);
    };

    return (
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        className={classNames(
          'btn',
          className,
          size && `btn--${size}`,
          isResponsive && 'btn--responsive',
          color && `btn--${color}${variant && `-${variant}`}`,
          fluid && 'btn--fluid',
        )}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        ref={buttonRef}
        onClick={handleClick}
      >
        {children}
      </button>
    );
  },
);

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  type: PropTypes.string,
  // This is a WIP and probably needs to map closer to the types of button in Figma
  // Which is more of a change to handle in this merge.
  variant: PropTypes.oneOf([
    '', // Primary Button - FIXME: use null or udnefined instead
    'outline', // Secondary Button
    'inverted', // Primary Button inverted
    'outline-inverted', // Secondary Button inverted
    'outline-warning', // Secondary Warning button
  ]),
  isResponsive: PropTypes.bool,
  onClick: PropTypes.func,
  fluid: PropTypes.bool,
};

Button.defaultProps = {
  children: null,
  className: null,
  color: 'blue',
  size: 'md',
  type: 'button',
  variant: '',
  isResponsive: false,
  onClick: () => {},
  fluid: false,
};

export default Button;
