import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { FaCheck as IconCheck, FaMinus as IconPartial } from 'react-icons/fa';
import PropTypes from 'prop-types';
import './Checkbox.scss';

const setCheckboxId = (name, value) => {
  if (!name) return undefined;
  if (!value) return name.replace('[]', '');
  return `${name.replace('[]', '')}_value`;
};

const Checkbox = ({
  className,
  children,
  id,
  label,
  labelPosition,
  name,
  size,
  value,
  onChange,
  forwardRef,
  partialChecked,
  ...props
}) => {
  const { disabled } = props;
  const [isPartial, setIsPartial] = useState(partialChecked);
  const checkboxId = id || setCheckboxId(name, value);

  useEffect(() => {
    setIsPartial(partialChecked);
  }, [partialChecked]);

  const handleCheckboxChange = e => {
    setIsPartial(false);
    onChange(e, e.target.checked);
  };

  return (
    <label
      className={classNames(
        'checkbox',
        className,
        size ? `checkbox--${size}` : null,
        label ? `checkbox--label-${labelPosition}` : null,
        {
          'checkbox--disabled': disabled,
        },
      )}
      htmlFor={checkboxId}
    >
      <input
        className="checkbox__input"
        type="checkbox"
        id={checkboxId}
        value={value}
        name={name}
        onChange={handleCheckboxChange}
        ref={forwardRef}
        {...props}
      />
      <span
        className={classNames(
          'checkbox__box',
          isPartial ? 'checkbox__partial' : null,
        )}
      >
        {!isPartial && <IconCheck className="checkbox__icon" />}
        {isPartial && <IconPartial className="checkbox__icon" />}
      </span>
      {children || label ? (
        <span className="checkbox__label">{children || label}</span>
      ) : null}
    </label>
  );
};

Checkbox.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  forwardRef: PropTypes.object,
  label: PropTypes.string,
  labelPosition: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  name: PropTypes.string,
  value: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  onChange: PropTypes.func,
  partialChecked: PropTypes.bool,
};

Checkbox.defaultProps = {
  children: null,
  className: '',
  defaultChecked: undefined,
  disabled: false,
  id: '',
  forwardRef: null,
  label: '',
  labelPosition: 'right',
  name: '',
  value: '',
  size: 'md',
  onChange: () => {},
  partialChecked: false,
};

export default Checkbox;
