import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as modal from '../../../../library/molecules/Modal';
import AddTaskModal, {
  ADD_TASK_MODES,
} from '../../../../components/AddTaskModal/AddTaskModal';
import DeleteDocsModal from '../../../../components/DeleteDocsModal/DeleteDocsModal';
import ExportModal from '../../../../components/ExportModal/ExportModal';
import ExtractModal from '../../../../components/ExtractModal/ExtractModal';
import SplitModal, {
  SMART_SPLIT,
} from '../../../../components/SplitModal/SplitModal';
import UploadDocsModal from '../../../../components/UploadDocsModal/UploadDocsModal';
import * as docsReducer from '../../../../reducers/docs';
import { fetchSpecs } from '../../../../reducers/specs';
import { fetchSubscriptions } from '../../../../reducers/subscriptions/actions';

const MAX_DOCS = 1000;

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      // (d, t, c, a) = docs, tags, companyId, isSelectAll
      deleteDocs: p => dispatch(docsReducer.deleteDocs(p)),
      exportDocs: p => dispatch(docsReducer.exportDocs(p)),
      extract: (docs, products, companyId) =>
        dispatch(docsReducer.extract(docs, products, companyId)),
      autoSplitOrManualSplit: (splitType, docs) =>
        dispatch(docsReducer.autoSplitOrManualSplit(splitType, docs)),
      fetchDocs: p => dispatch(docsReducer.fetchDocs(p)),
      fetchCompanies: () => dispatch(docsReducer.fetchCompanies()),
      fetchSpecs: filters => dispatch(fetchSpecs(filters)),
      getDocsPredictions: docs =>
        dispatch(docsReducer.getDocsPredictions(docs)),
      fetchSubscriptions: () => dispatch(fetchSubscriptions()),
    },
  };
};

const Modals = ({
  activeModal,
  cancelModal,
  closeModal,
  extractedDocs,
  fieldsetOptions,
  specs,
  selectedDocs,
  filters,
  batchSelect,
  dispatch,
  hasValidateProduct,
}) => {
  const checkModalOpen = modalName => activeModal === modalName;
  const numDocs = Object.values(selectedDocs).length;

  return (
    <>
      {activeModal === 'isExtractModalOpen' && (
        <ExtractModal
          selectedDocs={extractedDocs}
          fieldsetOptions={fieldsetOptions}
          extract={dispatch.extract}
          companyId={filters.companyId}
          isOpen={checkModalOpen('isExtractModalOpen')}
          onCancelModal={cancelModal}
          onCloseModal={closeModal}
        />
      )}
      {activeModal === 'isTaskModalOpen' && (
        <AddTaskModal
          companyId={filters.companyId}
          specs={specs}
          selectedDocs={selectedDocs}
          filters={filters}
          maxDocs={MAX_DOCS}
          isOpen={checkModalOpen('isTaskModalOpen')}
          isSelectAll={batchSelect.isSelectAllResults}
          onCancelModal={cancelModal}
          onCloseModal={closeModal}
        />
      )}
      {activeModal === 'isSplitTaskModalOpen' && (
        <AddTaskModal
          mode={ADD_TASK_MODES.CREATE_SPLIT}
          companyId={filters.companyId}
          specs={specs}
          selectedDocs={selectedDocs}
          filters={filters}
          maxDocs={MAX_DOCS}
          isOpen={checkModalOpen('isSplitTaskModalOpen')}
          isSelectAll={batchSelect.isSelectAllResults}
          onCancelModal={cancelModal}
          onCloseModal={closeModal}
        />
      )}
      {activeModal === 'isDeleteModalOpen' && (
        <DeleteDocsModal
          deleteDocs={dispatch.deleteDocs}
          selectedDocs={selectedDocs}
          isOpen={checkModalOpen('isDeleteModalOpen')}
          isSelectAll={batchSelect.isSelectAllResults}
          onCancelModal={cancelModal}
          onCloseModal={closeModal}
        />
      )}
      {activeModal === 'isUploadModalOpen' && (
        <UploadDocsModal
          companyId={filters.companyId}
          isOpen={checkModalOpen('isUploadModalOpen')}
          onCancelModal={cancelModal}
          onCloseModal={closeModal}
        />
      )}
      {activeModal === 'isExportModalOpen' && (
        <ExportModal
          docs={selectedDocs}
          filters={filters}
          isOpen={checkModalOpen('isExportModalOpen')}
          isSelectAll={batchSelect.isSelectAllResults}
          onCancelModal={cancelModal}
          onCloseModal={closeModal}
        />
      )}
      {activeModal === 'isSmartSplitModalOpen' && (
        <modal.Modal isOpen>
          <SplitModal
            numDocs={numDocs}
            onCancel={cancelModal}
            disableManualSplit={numDocs > 1 && !hasValidateProduct}
            onContinue={(_, splitType) => {
              dispatch
                .autoSplitOrManualSplit(
                  splitType,
                  selectedDocs,
                  filters.companyId,
                )
                .then(() => {
                  if (splitType === SMART_SPLIT) {
                    closeModal('isSmartSplitModalOpen', true);
                  } else {
                    // Should redirect to manual split task page.
                    cancelModal();
                  }
                });
            }}
          />
        </modal.Modal>
      )}
    </>
  );
};

Modals.propTypes = {
  activeModal: PropTypes.string,
  cancelModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  extractedDocs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fieldsetOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  specs: PropTypes.arrayOf(PropTypes.shape({})),
  selectedDocs: PropTypes.shape({}).isRequired,
  filters: PropTypes.shape({
    companyId: PropTypes.string,
  }).isRequired,
  batchSelect: PropTypes.shape({
    isSelectAllResults: PropTypes.bool,
  }).isRequired,
  dispatch: PropTypes.shape({
    extract: PropTypes.func,
    deleteDocs: PropTypes.func,
    autoSplitOrManualSplit: PropTypes.func,
  }).isRequired,
  hasValidateProduct: PropTypes.bool.isRequired,
};

Modals.defaultProps = {
  activeModal: null,
  specs: null,
};

export default connect(undefined, mapDispatchToProps)(Modals);
