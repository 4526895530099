/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { FaCircle, FaCheck, FaBan } from 'react-icons/fa';
import { getConfidence } from '../../../../../common/helpers';

const SidebarPredictions = forwardRef(
  ({ p, focusPrediction, predictionClicked }, ref) => {
    const { label: fieldConfidence, text: tooltipText } = getConfidence(
      p.confidence_norm,
    );
    return (
      <div
        ref={ref}
        className={classNames(
          'field',
          {
            focus: focusPrediction && p.field === focusPrediction.field,
          },
          { null: p.value === null },
        )}
        onClick={() => {
          predictionClicked(
            focusPrediction && p.field === focusPrediction.field ? null : p,
          );
        }}
      >
        <h3>{p.fieldSpec.specification.name}</h3>
        <span>{typeof p.value === 'string' && p.value}
          {typeof p.value === 'boolean' && (p.value ? (<><FaCheck/>{' '}Yes</>) : (<><FaBan/>{' '}No</>))}
          {typeof p.value !== 'string' && typeof p.value !== 'boolean' && '(object)'}
        </span>
        <FaCircle
          data-tooltip-place="right"
          data-tooltip-id="document-tooltip"
          data-tooltip-content={tooltipText}
          size={10}
          className={classNames('field-confidence', fieldConfidence)}
        />
      </div>
    );
  },
);

export default SidebarPredictions;
