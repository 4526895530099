import Page from './Page';
import PageElem from './PageElem';

export const PageBody = props => PageElem({ ...props, elemName: 'body' });
export const PageContent = props => PageElem({ ...props, elemName: 'content' });
export const PageHeader = props => PageElem({ ...props, elemName: 'header' });
export const PageFooter = props => PageElem({ ...props, elemName: 'footer' });
export const PageInner = props => PageElem({ ...props, elemName: 'inner' });
export const PageCol = props => PageElem({ ...props, elemName: 'col' });
export const PageRow = props => PageElem({ ...props, elemName: 'row' });
export const PageCta = props => PageElem({ ...props, elemName: 'cta' });

export { default as PageHelmet } from './PageHelmet';
export { default as PageLoading } from './PageLoading';
export { default as PageNav } from './PageNav';
export default Page;
