import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FaInfoCircle, FaChartLine } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Page, {
  PageBody,
  PageHeader,
  PageInner,
  PageNav,
  PageRow,
  PageLoading,
} from '../../../Page';
import { Button } from '../../../../library/atoms/Buttons';
import AddCardModal from './AddCardModal';
import Form, {
  TextInput,
  useForm,
  validatorType,
  ToggleGroup,
  Toggle,
} from '../../../Form';
import './Billing.scss';
import {
  updateCompany,
  fetchCompany,
} from '../../../../reducers/company/actions';

const getInitialState = company => {
  const initialState = {
    billingThreshold: {
      value:
        company && company.billingThreshold ? company.billingThreshold : '',
      required: true,
      validators: [validatorType.Decimal],
    },
    billingEmail: {
      value: company && company.stripeEmail ? company.stripeEmail : '',
    },
    enableAlert: {
      value: !(!company || (company && company.billingThreshold === null)),
    },
    inSync: { value: !!company },
  };
  return initialState;
};

const mapStateToProps = state => {
  const { auth, company } = state;
  const user = auth.user.data || null;
  return {
    user,
    company: company.data,
    paymentMethods: company && company.data ? company.data.paymentMethods : [],
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      fetchCompany: companyId => dispatch(fetchCompany(companyId)),
      updateCompany: (companyId, updateDetails) =>
        dispatch(updateCompany(companyId, updateDetails)),
    },
  };
};

const Billing = ({ user, company, dispatch, paymentMethods }) => {
  const [form] = useForm(getInitialState(company));
  const { fields, errors } = form;

  // Error handling.
  if (fields.enableAlert.value === false) {
    delete errors.billingThreshold;
    delete errors.billingEmail;
  }

  if (fields.enableAlert.value === true) {
    if (errors.billingThreshold && fields.billingThreshold.value !== '') {
      errors.billingThreshold = errors.billingThreshold.filter(
        e => e !== 'Required.',
      );
      // empty array
      if (errors.billingThreshold.length < 1) {
        delete errors.billingThreshold;
      }
    }
    if (!errors.billingThreshold && fields.billingThreshold.value === '') {
      errors.billingThreshold = ['Required.'];
    }
    if (fields.billingEmail.value === '') {
      errors.billingEmail = ['Required.'];
    }
  }

  const hasErrors = Object.keys(errors || {}).length > 0;

  const handleSubmit = () => {
    const billingThreshold = fields.billingThreshold.value;
    let updatedDetails = {};
    if (billingThreshold !== company.billingThreshold) {
      updatedDetails = {
        currBillingRange: billingThreshold === '' ? null : 0.5,
        alertDate: null,
        ackDate: null,
        billingThreshold:
          billingThreshold === '' ? null : parseFloat(billingThreshold),
      };
      dispatch.updateCompany(company.id, updatedDetails);
    }
  };

  const handleAlertChange = e => {
    form.handleChange(e, e.target.checked);
  };

  // If form falls out of sync, reset form
  if (
    company &&
    company.stripeEmail &&
    company.stripeEmail !== fields.billingEmail.value
  ) {
    form.reset();
  }

  useEffect(() => {
    const enableAlert = fields.enableAlert.value;
    if (enableAlert === true) {
      if (fields.billingThreshold.value === '' && company.billingThreshold) {
        form.handleChange({
          target: {
            name: 'billingThreshold',
            value: company.billingThreshold,
            type: 'text',
          },
        });
      }
    } else if (enableAlert === false && fields.billingThreshold.value !== '') {
      form.handleChange({
        target: {
          name: 'billingThreshold',
          value: '',
          type: 'text',
        },
      });
    }
  }, [fields.enableAlert.value]);

  useEffect(() => {
    if (user) {
      dispatch.fetchCompany(user.companyId);
    }
  }, [user]);

  /**
   * Manage Payment Cards
   */
  const [showAddCardModal, setShowAddCardModal] = useState(false);

  const handleAddCard = () => {
    setShowAddCardModal(true);
  };

  return (
    <Page className="marketplace billing" title="My Billing - Marketplace">
      <PageInner>
        <PageNav
          items={[
            { label: 'Marketplace', to: '/marketplace' },
            { label: 'My Billing', to: '/marketplace/billing' },
          ]}
        />
        <PageHeader>
          <h1 className="page__heading">My Billing</h1>
        </PageHeader>
        <PageBody>
          {!company ? (
            <PageLoading />
          ) : (
            <>
              <PageRow>
                <Link to="/analytics/billing">
                  <Button type="button" size="sm">
                    <span>View Billing Dashboard</span>
                    <FaChartLine />
                  </Button>
                </Link>
              </PageRow>
              <PageRow>
                <Form className="billing-form" onSubmit={handleSubmit}>
                  {fields.enableAlert.value === true &&
                    fields.billingEmail.value === '' && (
                      <div className="billing-form__error-message">
                        * Something went wrong, we couldn&#39;t find an email
                        that matched your billing detials. You may have no
                        subscriptions. Head over to{' '}
                        <Link to="/marketplace">Marketplace</Link> to unlock a
                        full subscription. If the issue still persists please
                        contact{' '}
                        <a
                          target="_blank"
                          href="mailto:support@sypht.com"
                          rel="noreferrer"
                        >
                          support@sypht.com
                        </a>
                      </div>
                    )}
                  <ToggleGroup
                    label="Monthly Billing Threshold Alert"
                    labelFor="enableAlert"
                    type="single"
                  >
                    <Toggle
                      id="enableAlert"
                      label={fields.enableAlert.value ? 'Enabled' : 'Disabled'}
                      labelPosition="right"
                      name="enableAlert"
                      defaultChecked={fields.enableAlert.value}
                      onBlur={form.handleBlur}
                      onChange={handleAlertChange}
                    />
                  </ToggleGroup>
                  <TextInput
                    label="Monthly Billing Threshold Amount ($)"
                    name="billingThreshold"
                    placeholder={
                      fields.enableAlert.value === true ? 'E.g. 123.45' : 'N/A'
                    }
                    value={fields.billingThreshold.value}
                    errors={errors}
                    onBlur={form.handleBlur}
                    onChange={form.handleChange}
                    disabled={!fields.enableAlert.value}
                  />
                  <TextInput
                    label="Alerts sent to email"
                    name="billingEmail"
                    placeholder="N/A"
                    errors={errors}
                    value={
                      fields.enableAlert.value === true
                        ? fields.billingEmail.value
                        : ''
                    }
                    disabled
                  />
                  <Button onClick={handleSubmit} disabled={hasErrors}>
                    Save
                  </Button>
                </Form>
              </PageRow>
              <PageRow>
                <div className="payment-card">
                  <div className="section-divider" />
                  <h1 className="section-title">Manage Payment Card</h1>
                  {paymentMethods.length > 0 ? (
                    <>
                      <TextInput
                        label="Default Payment Card"
                        name="paymentCard"
                        placeholder="N/A"
                        value={`${paymentMethods[0].card.brand.toTitleCase()} ending in ${
                          paymentMethods[0].card.last4
                        }`}
                        disabled
                      />
                      <Button onClick={handleAddCard}>
                        Change Payment Card
                      </Button>
                    </>
                  ) : (
                    <>
                      <p>No available payment method</p>
                      <Button onClick={handleAddCard}>Add Payment Card</Button>
                    </>
                  )}
                </div>
                <AddCardModal
                  isOpen={showAddCardModal}
                  onCancelModal={() => setShowAddCardModal(false)}
                  onCloseModal={() => setShowAddCardModal(false)}
                />
              </PageRow>
              <PageRow>
                <div className="footer-advice">
                  <div>
                    <span className="footer-advice__icon">
                      <FaInfoCircle />
                    </span>
                    <span className="footer-advice__text">
                      The monthly billing threshold is aligned to calendar month
                      time period. This time period is likely to be different to
                      the billing time periods for each of your subscriptions,
                      as these are set to the specific day of the month that the
                      full subscription was unlocked.
                    </span>
                  </div>
                  <div>
                    <span className="footer-advice__icon">
                      <FaInfoCircle />
                    </span>
                    <span className="footer-advice__text">
                      If you have any questions regarding your billing, or if
                      you&#39;d like to cancel an active subscription, please
                      email our Customer Success team at{' '}
                      <span>
                        <a
                          className="link"
                          target="_blank"
                          href="mailto:customersuccess@sypht.com"
                          rel="noreferrer"
                        >
                          customersuccess@sypht.com
                        </a>
                      </span>
                    </span>
                  </div>
                </div>
              </PageRow>
            </>
          )}
        </PageBody>
      </PageInner>
    </Page>
  );
};

Billing.propTypes = {
  company: PropTypes.shape({
    billingThreshold: PropTypes.string,
    stripeEmail: PropTypes.string,
    id: PropTypes.string,
  }),
  user: PropTypes.shape({
    companyId: PropTypes.string,
  }),
  // Unsure what payment methods we support.
  // eslint-disable-next-line react/forbid-prop-types
  paymentMethods: PropTypes.array,
  dispatch: PropTypes.shape({
    fetchCompany: PropTypes.func,
    updateCompany: PropTypes.func,
  }).isRequired,
};

Billing.defaultProps = {
  company: {},
  user: {},
  paymentMethods: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
