import React from 'react';
import PropTypes from 'prop-types';
import { FaExclamationTriangle } from 'react-icons/fa';
import Modal from '../UI/Modal/Modal';
import { WorkflowEventsLookup } from '../../common/lookups';
import './DeleteTasksModal.scss';

const { OnComplete, OnDelete, OnGroupComplete } = WorkflowEventsLookup;
const DeleteTasksModal = ({
  deleteTasks,
  selectedTasks,
  onCancelModal,
  onCloseModal,
  isOpen,
}) => {
  const tasks = Object.keys(selectedTasks);

  const tasksWithWorkflowEvents = Object.values(selectedTasks).filter(task => {
    let hasWorkflow = false;
    if (task.parameters && task.parameters.events) {
      const { events } = task.parameters;
      hasWorkflow = !!Object.keys(events).find(
        e => e === OnComplete || e === OnDelete || e === OnGroupComplete,
      );
    }
    return hasWorkflow;
  }).length;
  const tasksWithAnnotations = Object.values(selectedTasks).filter(task => {
    return task.annotators && task.annotators.length > 0;
  }).length;

  const onConfirm = () => {
    deleteTasks(tasks).then(() => onClose());
  };

  const onClose = () => {
    onCloseModal('isDeleteTasksModalOpen');
  };

  const onCancel = () => {
    onCancelModal('isDeleteTasksModalOpen');
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      onCancel={onCancel}
      onConfirm={onConfirm}
      className="modal-delete"
      confirmLabel="Delete tasks"
      cancelLabel="Cancel"
      modalLabel={`Deleting ${tasks.length} ${
        tasks.length > 1 ? 'tasks?' : 'task?'
      }`}
    >
      <div className="modal-delete-content">
        {(tasksWithWorkflowEvents > 0 || tasksWithAnnotations > 0) && (
          <div className="modal-delete-warning--container">
            <div className="modal-delete-warning--header">
              <FaExclamationTriangle />
              Warning
            </div>
            <div className="modal-delete-warning--body">
              <ul className="modal-delete-warning--list">
                {tasksWithWorkflowEvents > 0 && (
                  <li className="modal-delete-warning--item">
                    Detected{' '}
                    {tasksWithWorkflowEvents > 1
                      ? `${tasksWithWorkflowEvents} tasks that have`
                      : '1 task that has'}{' '}
                    workflow events configured. There may be side effects to the
                    delete.
                  </li>
                )}
                {tasksWithAnnotations > 0 && (
                  <li className="modal-delete-warning--item">
                    Detected{' '}
                    {tasksWithAnnotations > 1
                      ? `${tasksWithAnnotations} tasks that have`
                      : '1 task that has'}{' '}
                    annotations. These tasks cannot be deleted and instead will
                    be closed and set to completed.
                  </li>
                )}
              </ul>
            </div>
          </div>
        )}
        <div className="modal-delete-info">
          Are you sure you want to delete the selected tasks?
        </div>
      </div>
    </Modal>
  );
};

DeleteTasksModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  deleteTasks: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onCancelModal: PropTypes.func.isRequired,
  selectedTasks: PropTypes.object.isRequired,
};

export default DeleteTasksModal;
