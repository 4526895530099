import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './_radio.scss';

const Radio = ({
  className,
  label,
  name,
  value,
  checked,
  defaultChecked,
  onChange,
}) => {
  const [isChecked, setChecked] = useState(defaultChecked || checked);
  const id = `${name}|option_${value}`;

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  return (
    <label className={classNames('radio', className)} htmlFor={id}>
      <input
        id={id}
        type="radio"
        className="radio__input"
        checked={isChecked}
        name={name}
        value={value}
        onChange={onChange}
      />
      <span className="radio__border">
        <span className="radio__dot" />
      </span>
      {label && <span className="radio__label">{label}</span>}
    </label>
  );
};

Radio.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
};

Radio.defaultProps = {
  className: undefined,
  label: undefined,
  value: undefined,
  checked: undefined,
  defaultChecked: undefined,
  onChange: () => {},
};

export default Radio;
