import React from 'react';
import PropTypes from 'prop-types';
import { FaCut } from 'react-icons/fa';
import { Button } from '../../../../library/atoms/Buttons';

const SmartSplitButton = ({ isDisabled, onClick, ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button disabled={isDisabled} onClick={onClick} {...props}>
      <FaCut />
      <span>Smart Split</span>
    </Button>
  );
};

SmartSplitButton.propTypes = {
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

SmartSplitButton.defaultProps = {
  isDisabled: false,
};

export default SmartSplitButton;
