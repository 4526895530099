export default Object.freeze({
  /**
   * Document "quick fix"
   */
  FIX: 'fix',
  /**
   * HITL tasks (requires Validate product)
   */
  REVIEW: 'review',
});
