import React from 'react';
import PropTypes from 'prop-types';
import SplitStatesLookup from '../../../../common/lookups/splitStatesLookup';
import {
  DocumentListIconSplitChild,
  DocumentListIconSplitParent,
  DocumentListIconSplitPending,
} from '../../../../library/molecules/DocumentListIcons/index';

const DocumentNameAndIdCell = ({ row }) => {
  const hasDocumentIcon = () => {
    return (
      row.original.splitState &&
      row.original.splitState !== SplitStatesLookup.uploaded_as_is
    );
  };

  const renderSplitIcon = () => {
    switch (row.original.splitState) {
      case SplitStatesLookup.split_pending:
        return <DocumentListIconSplitPending />;
      case SplitStatesLookup.created_by_split:
        return <DocumentListIconSplitChild />;
      case SplitStatesLookup.split_complete:
        return <DocumentListIconSplitParent />;
      default:
        return null;
    }
  };

  const renderDocumentIcon = () => {
    if (hasDocumentIcon()) {
      return <div className="document-type-icon">{renderSplitIcon()}</div>;
    }
    return null;
  };

  return (
    <>
      <div className="document-filename-cell">
        {renderDocumentIcon()}
        <div>
          <div className="document-filename">{row.original.filename}</div>
          <div className="document-id">{row.original.id}</div>
        </div>
      </div>
    </>
  );
};

DocumentNameAndIdCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      filename: PropTypes.string,
      splitState: PropTypes.string,
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default DocumentNameAndIdCell;
