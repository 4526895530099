import React from 'react';
import PropTypes from 'prop-types';
import TagList from '../../library/molecules/TagList';
import Tag from '../../library/atoms/Tag';
import history from '../../services/historyService';

import './DocumentTagList.scss';

const combineTagsFrom = (doc, task) => {
  const union = new Set(doc?.tags || []);
  (task?.tags || []).map(tag => union.add(tag));
  return [...union];
};

const DocumentTagList = ({ doc, task }) => {
  const handleTagClick = (e, tag) => {
    e.stopPropagation();
    if (task) {
      history.push(`/tasks?tags=${tag.name}`);
    } else {
      history.push(`/documents?tags=${tag.name}`);
    }
  };
  const tags = combineTagsFrom(doc, task);
  return (
    <>
      {tags.length > 0 && (
        <div className="document-tag-list">
          <TagList
            tags={tags}
            handleTagClick={handleTagClick}
            variant="right-aligned"
          >
            {(tags || [])
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map(tag => (
                <Tag
                  key={`tag|${doc.id || task.id}|${tag.id}`}
                  onClick={e => handleTagClick(e, tag)}
                  role="button"
                  tabIndex={0}
                  data-tooltip-content={tag.name}
                  data-tooltip-id="document-tooltip"
                  data-test-id={tag.name}
                >
                  {tag.name}
                </Tag>
              ))}
          </TagList>
        </div>
      )}
    </>
  );
};

DocumentTagList.propTypes = {
  doc: PropTypes.shape({
    id: PropTypes.string,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
  }),
  task: PropTypes.shape({
    id: PropTypes.string,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
  }),
};

DocumentTagList.defaultProps = {
  doc: null,
  task: null,
};

export default DocumentTagList;
