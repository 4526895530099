/* eslint-disable no-plusplus */
import React, { useEffect, useRef, useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PropTypes from 'prop-types';
import { flatten, uniq } from 'ramda';
import sortProducts from '../../common/helpers/sortProducts';
import Modal from '../UI/Modal/Modal';
import FieldsetChip from './components/FieldsetChip';
import '../Form/Form.scss';
import './ExtractModal.scss';

const setFieldsetOptions = (
  fieldsetOptions,
  selectedDocs,
  extractedFieldsets,
) => {
  // Create unique list of suggested fieldsets
  const suggestedFieldsets = uniq(
    flatten(selectedDocs.map(doc => doc.suggestions)),
  );

  // Create list of already extracted fieldset options
  const extractedFieldsetOptions = fieldsetOptions
    .filter(f => extractedFieldsets.includes(f.value))
    .map(p => ({
      label: p.label,
      value: p.value,
      isExtracted: true,
    }));

  // ** keep this for debugging **
  // console.info('extractedFieldsets', extractedFieldsets); // sypht.document
  // console.info('fieldsetOptions', fieldsetOptions);
  // console.info('selectedDocs', selectedDocs);
  // console.info('suggestedFieldsets', suggestedFieldsets);

  // Create list of suggested fieldset options
  let suggestedFieldsetOptions = fieldsetOptions
    .filter(f => !extractedFieldsets.includes(f.value))
    .filter(f =>
      // array of fieldsets
      f.fieldsets.find(fs => {
        // suggestedFieldsets ['issuer', 'invoice']
        return suggestedFieldsets.includes(
          fs.id.substr(fs.id.indexOf('.') + 1, fs.id.length),
        );
      }),
    )
    .map(f => ({
      ...f,
      isExtracted: false,
    }))
    .sort(sortProducts);

  // Prepend extracted fieldset options to suggested fieldset options
  suggestedFieldsetOptions = [
    ...extractedFieldsetOptions,
    ...suggestedFieldsetOptions,
  ];

  // Create list of other fieldset options
  const otherFieldsetOptions = fieldsetOptions
    .filter(f => !suggestedFieldsetOptions.find(s => s.value === f.value))
    .map(f => ({ ...f, isExtracted: false }))
    .sort(sortProducts);

  return [suggestedFieldsetOptions, otherFieldsetOptions];
};

const getExtractedFieldsets = selectedDocs => {
  const extractedFieldsets = [];
  const extractionsByFieldset = {};
  selectedDocs.forEach(doc => {
    doc.products.forEach(f => {
      if (extractionsByFieldset[f]) {
        extractionsByFieldset[f] += 1;
      } else {
        extractionsByFieldset[f] = 1;
      }
      if (extractionsByFieldset[f] === selectedDocs.length)
        extractedFieldsets.push(f);
    });
  });

  return extractedFieldsets;
};

const ExtractModal = ({
  companyId,
  fieldsetOptions,
  selectedDocs,
  isOpen,
  extract, // extract function
  onCancelModal, // close and cancel functions
  onCloseModal, // close and confirm functions
}) => {
  const [selectedFieldsets, setSelectedFieldsets] = useState([]);
  const extractedFieldsets = getExtractedFieldsets(selectedDocs);
  const [suggestedFieldsetOptions, otherFieldsetOptions] = setFieldsetOptions(
    fieldsetOptions,
    selectedDocs,
    extractedFieldsets,
  );

  const summaryRef = useRef(null);
  const heightRef = useRef(null);
  const calculate = {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const content = [];
  let numOfPages = 0;

  useEffect(() => {
    if (heightRef.current) {
      summaryRef.current.style.height = `${heightRef.current.offsetHeight}px`;
    }
  }, [content]);

  if (selectedDocs.length > 0) {
    selectedDocs.forEach(doc => {
      // get total page count
      numOfPages += doc.numOfPages;

      selectedFieldsets.forEach(fieldset => {
        if (!doc.products.includes(fieldset)) {
          if (!calculate[fieldset]) {
            calculate[fieldset] = 0;
          }
          // eslint-disable-next-line no-plusplus
          calculate[fieldset]++;
        }
      });
    });
  }

  let count = 0;
  if (Object.keys(calculate).length > 0) {
    Object.entries(calculate).forEach(calc => {
      count++;
      if (calc[1] > 0) {
        content.push(
          <div className="modal-extract-groupItem" key={calc[0]}>
            <div className="modal-extract-info">{calc[0]}</div>
          </div>,
        );
      }
    });

    if (count > 0) {
      content.push(
        <div className="total" key="count">
          Total number of pages = {numOfPages}
        </div>,
      );
    }
  }
  const handleConfirmClick = () => {
    const docs = selectedDocs.map(d => d.docId);
    if (selectedFieldsets) {
      extract(docs, selectedFieldsets, companyId);
      setSelectedFieldsets([]);
      onCloseModal('isExtractModalOpen');
    }
  };

  const handleModalClose = () => {
    setSelectedFieldsets([]);
    onCancelModal('isExtractModalOpen');
  };

  const handleFieldsetClick = (e, value) => {
    if (!selectedFieldsets.includes(value)) {
      // add to selectedFieldsets if not exists
      setSelectedFieldsets([...selectedFieldsets, value]);
    } else {
      // remove from selectedFieldsets if not exists
      setSelectedFieldsets(selectedFieldsets.filter(x => x !== value));
    }
  };

  return (
    <Modal
      showCloseBtn
      isOpen={isOpen}
      disabledConfirm={!(count > 0)}
      onClose={handleModalClose}
      onCancel={handleModalClose}
      onConfirm={handleConfirmClick}
      confirmLabel="Queue"
      cancelLabel="Cancel"
      className="extract-modal"
      modalLabel={`You selected ${selectedDocs.length} ${
        selectedDocs.length > 1 ? 'documents' : 'document'
      } to queue for extraction`}
    >
      <div className="extract-modal__inner">
        <div className="form__group">
          {suggestedFieldsetOptions && suggestedFieldsetOptions.length > 0 ? (
            <div className="extract-model__suggested">
              <h3 className="form__group-label">
                Suggested AI Products{' '}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className="form__tooltip-link"
                  data-tooltip-id="sadFace"
                  data-tooltip-content="What's this?"
                >
                  ?
                </a>
              </h3>
              <ul className="extract-modal__fieldsets">
                {suggestedFieldsetOptions.map((option, i) => {
                  const key = `${option.value}_option_${i}`;
                  return (
                    <li className="extract-modal__fieldsets-item" key={key}>
                      <FieldsetChip
                        name={option.label}
                        value={option.value}
                        isExtracted={option.isExtracted}
                        onClick={handleFieldsetClick}
                      />
                    </li>
                  );
                })}
              </ul>
              <br />
            </div>
          ) : null}
          <h3 className="form__group-label">Available AI products</h3>
          <ul className="extract-modal__fieldsets">
            {(otherFieldsetOptions || []).map((option, i) => {
              const key = `${option.value}_option_${i}`;
              return (
                <li className="extract-modal__fieldsets-item" key={key}>
                  <FieldsetChip
                    name={option.label}
                    value={option.value}
                    isExtracted={option.isExtracted}
                    onClick={handleFieldsetClick}
                  />
                </li>
              );
            })}
          </ul>
          <div className="extract-modal__footer">
            Visit the <a href="/marketplace/products">Marketplace</a> to review
            and activate AI products on your account.
          </div>
        </div>
        <div className="extract-modal__summary form__group" ref={summaryRef}>
          {content.length > 0 && (
            <div className="extract-modal__summary-inner" ref={heightRef}>
              <h3 className="form__group-label">Summary</h3>
              <div className="modal-extract-group">{content}</div>
            </div>
          )}
        </div>
      </div>
      <ReactTooltip className="form__tooltip" id="sadFace" place="right">
        <span>
          Suggested AI products are a handy shortcut intended to save you time
          selecting the most appropriate AI product to use. We identify
          suggestions by taking a quick look inside the documents you&apos;re
          looking to process, applying our AI to classify them, and presenting
          you the most common AI products our customers use with this type of
          document
        </span>
      </ReactTooltip>
    </Modal>
  );
};

ExtractModal.propTypes = {
  companyId: PropTypes.string,
  fieldsetOptions: PropTypes.arrayOf(PropTypes.shape({})),
  selectedDocs: PropTypes.arrayOf(PropTypes.shape({})),
  isOpen: PropTypes.bool,
  extract: PropTypes.func,
  onCloseModal: PropTypes.func,
  onCancelModal: PropTypes.func,
};

ExtractModal.defaultProps = {
  companyId: null,
  fieldsetOptions: [],
  selectedDocs: [],
  isOpen: false,
  extract: () => {},
  onCancelModal: () => {},
  onCloseModal: () => {},
};

export default ExtractModal;
