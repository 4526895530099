import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { FaFileDownload } from 'react-icons/fa';
import {
  CaptureDataLoading,
  CaptureDocLoading,
  CaptureDoc,
} from '../Capture/components';
import SignalsData from './Data/SignalsData';
import { getQueryStr } from '../../common/helpers';
import './_signals.scss';
import { retrieveDoc } from './store/actions';

const mapStateToProps = (state, props) => {
  const { history } = props;
  const { docs } = state.signals;
  const bgColour = getQueryStr(
    decodeURIComponent(history.location.search),
    'bgColour',
  );
  const docId = process.env.DEMO_INVOICES_DOCID;
  return {
    docId,
    bgColour,
    doc: docs[docId],
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      retrieveDoc: docId => dispatch(retrieveDoc(docId)),
    },
  };
};

const Signals = ({ bgColour, dispatch, doc, docId }) => {
  const containerRef = useRef(null);
  useEffect(() => {
    if (!doc || doc.isRefresh) {
      dispatch.retrieveDoc(docId);
    }
  }, [doc]);

  useEffect(() => {
    document.body.classList.add('demo-bg');
    return () => {
      document.body.classList.remove('demo-bg');
    };
  }, []);

  window.addEventListener('message', event => {
    if (event.data === 'getHeight') {
      window.parent.postMessage(
        { iframe: 'signals', height: containerRef.current.scrollHeight },
        '*',
      );
    }
  });

  const onImageLoad = () => {
    if (containerRef && containerRef.current) {
      window.parent.postMessage(
        { iframe: 'signals', height: containerRef.current.scrollHeight },
        '*',
      );
    }
  };

  const product = {
    name: 'Signals Demo',
    productId: 'signals-demo',
    docId,
  };
  return (
    <div ref={containerRef} className="signals__ref">
      <Helmet>
        <title>Signals AI Demo - Sypht App</title>
        <link rel="canonical" href={`${process.env.URL_WWW}/ai-products`} />
      </Helmet>
      <div className="signals signals--demo">
        <div className="signals__header">
          <ul className="signals__nav">
            <li className="signals__nav-item signals__nav-item--active">
              <span className="signals__nav-item__title">Invoice Signals</span>
            </li>
            <li className="signals__nav-item">
              <span className="signals__nav-item__subtitle">Coming Soon</span>
              <span className="signals__nav-item__title">Identity Signals</span>
            </li>
          </ul>
        </div>

        <div className="signals__body">
          <div
            className={classNames('signals__main', {
              'white-bg': bgColour === 'white',
            })}
          >
            {!doc ? (
              <CaptureDocLoading />
            ) : (
              <CaptureDoc
                doc={doc}
                product={product}
                onLoad={onImageLoad}
                showHeading={false}
              />
            )}
          </div>
          <div className="signals__aside">
            {!doc ? <CaptureDataLoading /> : <SignalsData />}
            {doc && (
              <div className="signals-doc-actions">
                <ul className="signals-doc-actions__list">
                  <li className="signals-doc-actions__item">
                    <a
                      href={doc.original}
                      className="signals-doc-actions__link"
                      download={doc.filename}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Download this Invoice example
                    </a>

                    <FaFileDownload className="signals-doc-actions__icon" />
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Signals);
