import { addNotification } from '../notifications';
import MailboxService from '../../services/MailboxService';
import {
  createdMessage,
  deletedMessage,
  errorMessage,
} from '../../components/UI/Notification';

export const RECEIVE_INBOUND_EMAIL = 'sypht/mailbox/RECEIVE_INBOUND_EMAIL';
export const REQUEST_EMAIL_LIST = 'sypht/mailbox/REQUEST_EMAIL_LIST';
export const RECEIVE_EMAIL_LIST = 'sypht/mailbox/RECEIVE_EMAIL_LIST';
export const RECEIVE_NEW_EMAIL = 'sypht/mailbox/RECEIVE_NEW_EMAIL';
export const RECEIVE_DELETED_EMAIL = 'sypht/mailbox/RECEIVE_DELETED_EMAIL';

export const CLEAR_ERROR = 'sypht/mailbox/CLEAR_ERROR';
export const RECEIVE_ERROR = 'sypht/mailbox/RECEIVE_ERROR';

const DEFAULT_ERROR_MSG = 'Unable to add email address.';

const receiveInboundEmail = emailAddress => {
  return {
    type: RECEIVE_INBOUND_EMAIL,
    payload: {
      emailAddress,
    },
  };
};

const requestEmailList = () => {
  return {
    type: REQUEST_EMAIL_LIST,
  };
};

const receiveEmailList = emailAddresses => {
  return {
    type: RECEIVE_EMAIL_LIST,
    payload: {
      emailAddresses,
    },
  };
};

const receiveNewEmail = emailAddress => {
  return {
    type: RECEIVE_NEW_EMAIL,
    payload: {
      emailAddress,
    },
  };
};

const receiveDeletedEmail = id => {
  return {
    type: RECEIVE_DELETED_EMAIL,
    payload: {
      id,
    },
  };
};

const clearError = () => {
  return {
    type: CLEAR_ERROR,
  };
};

const receiveError = error => {
  return {
    type: RECEIVE_ERROR,
    payload: {
      error,
    },
  };
};

export const getInboundEmail = companyId => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;

    const response = await MailboxService.getMailboxAddress(
      accessToken,
      companyId,
    );

    dispatch(receiveInboundEmail(response.data));
  };
};

export const getEmailAddresses = companyId => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;

    dispatch(requestEmailList());
    const response = await MailboxService.getMailboxIntegrations(
      accessToken,
      companyId,
    );

    dispatch(receiveEmailList(response.data));
  };
};

export const createEmailAddress = (companyId, email) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;

    dispatch(clearError());
    try {
      const response = await MailboxService.createMailboxIntegration(
        accessToken,
        companyId,
        { email },
      );

      if (!response.data) {
        dispatch(receiveError({ input: DEFAULT_ERROR_MSG }));
        return false;
      }

      dispatch(receiveNewEmail(response.data));
      dispatch(addNotification(createdMessage('Email address', email)));
      return true;
    } catch (e) {
      const error = e.response?.data;
      console.error(error);
      dispatch(receiveError({ input: error?.message || DEFAULT_ERROR_MSG }));
    }
  };
};

export const deleteEmailAddress = (companyId, id) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;

    try {
      const response = await MailboxService.deleteMailboxIntegration(
        accessToken,
        companyId,
        id,
      );

      const [deletedEmail] = response.data;
      if (!deletedEmail || !deletedEmail.id) {
        dispatch(addNotification(errorMessage));
        return false;
      }

      dispatch(receiveDeletedEmail(deletedEmail.id));
      dispatch(
        addNotification(deletedMessage('Email address', deletedEmail.email)),
      );
      return true;
    } catch (e) {
      console.error(e.response?.data);
      dispatch(addNotification(errorMessage));
    }
  };
};
