import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getInboundEmail } from '../../../../../reducers/mailbox/actions';
import { Box } from '../../../../../library/atoms/Box';

const mapStateToProps = state => {
  const { auth, mailbox } = state;
  const { user } = auth;
  return {
    companyId: user.data?.companyId,
    emailAddress: mailbox.inboundEmail,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      getInboundEmailAddress: companyId => dispatch(getInboundEmail(companyId)),
    },
  };
};

const InboundEmailBox = ({ companyId, emailAddress, dispatch }) => {
  useEffect(() => {
    if (!companyId) return;
    dispatch.getInboundEmailAddress(companyId);
  }, [companyId]);

  return (
    <Box className="inbound-box text-center">
      Send your documents as attachments to:
      <br />
      {emailAddress ? (
        <em className="inbound-box__email-address">{emailAddress}</em>
      ) : (
        <em className="inbound-box__email-address">Mailbox not available</em>
      )}
    </Box>
  );
};

InboundEmailBox.propTypes = {
  companyId: PropTypes.string,
  emailAddress: PropTypes.string,
  dispatch: PropTypes.shape({
    getInboundEmailAddress: PropTypes.func,
  }),
};

InboundEmailBox.defaultProps = {
  companyId: null,
  emailAddress: null,
  dispatch: {
    getInboundEmailAddress: () => {},
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(InboundEmailBox);
