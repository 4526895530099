import React from 'react';
import PropTypes from 'prop-types';

import GenericStructure from './types/GenericStructure';
import LineItemsField from './types/LineItemsField';
import MultiRowField from './types/MultiRowField';
import MultiValueField from './types/MultiValueField';
import TextField from './types/TextField';
import './_captureField.scss';

const CaptureField = props => {
  const { field, row } = props;
  switch (field.type) {
    case 'generic':
      return <GenericStructure field={field} row={row} />;

    // APP2-1429: We can display geocode, abrlookup using MultiRowField nicely
    // but it was decided to keep the display in capture demo simple as the
    // sample invoice used in the demo has some issues (2 ABN's, recipient
    // address same as the issuer address) which will only be further
    // highlighted by including these fields.
    case 'geocode':
    case 'abrlookup':
      return null;

    case 'dictionary':
      return <MultiRowField field={field} row={row} />;
    case 'lineitems':
    case 'tables:2':
      // tables:2 comes from lineitems:3 which is in invoices:2 product.
      return <LineItemsField field={field} row={row} />;
    case 'multiclass':
    case 'multilabel':
      return <MultiValueField field={field} row={row} />;
    default:
      return <TextField field={field} row={row} />;
  }
};

CaptureField.propTypes = {
  row: PropTypes.number.isRequired,
  field: PropTypes.shape({
    fieldName: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  }).isRequired,
};

export default CaptureField;
