import { STYLE } from '../../components/UI/Notification/Notification';

export const createdBatchMessage = (queued, numDocs) => ({
  style: STYLE.CONFIRMATION,
  message: `Tasks queued for ${queued} / ${numDocs} documents.`,
});

export const createdMessage = {
  style: STYLE.CONFIRMATION,
  message: `Tasks have been added.`,
};

export const errorMessage = {
  style: STYLE.ERROR,
  message:
    'Something went wrong, please try again. If this issue persists ' +
    'please contact us at support@sypht.com.',
  timeout: 8000,
};

/**
 * Was created to automatically redirect someone who tries to quick fix a doc twice - APP2-1264.
 */
export const goToExistingQuickFix = {
  style: STYLE.CONFIRMATION,
  message: `Redirecting you to existing document fix.`,
};

export const createdQuickFix = {
  style: STYLE.CONFIRMATION,
  message: `Redirecting you to document quick fix.`,
};

export const requestingFix = {
  style: STYLE.CONFIRMATION,
  message: `Starting quick fix...`,
};
