import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactLoading from 'react-loading';
import Card, {
  CardHeader,
  CardContent,
  CardTooltip,
} from '../../../../UI/Card';

const mapStateToProps = state => {
  const { tasks, docs } = state;
  return {
    tasks: {
      ...tasks?.data,
      isLoading: tasks.loading,
    },
    docs: {
      ...docs?.data,
      isLoading: docs.loading,
    },
    docsCount: docs?.data?.count,
    numTasks: tasks?.data?.numTasks,
  };
};

const StpPanel = ({ docsCount, numTasks, docs, tasks }) => {
  const displayStpRate = ({ numTasks, docsCount, rawData = false }) => {
    let stpRate = (1 - numTasks / docsCount) * 100;
    if (!rawData) {
      stpRate = `${stpRate.toFixed(2)}%`;
    }
    return stpRate;
  };
  return (
    <Card>
      <CardHeader>Straight-Through-Processing (STP) rate</CardHeader>
      <CardContent>
        {docsCount === null ||
        numTasks === null ||
        docs.isLoading ||
        tasks.isLoading ? (
          <ReactLoading color="#3C3C3B" type="spin" height={38} width={38} />
        ) : (
          <>
            {/* <span data-tooltip-content={displayStpRate({numTasks, docsCount, rawData: true})}> */}
            {displayStpRate({ numTasks, docsCount })}
            {/* </span> */}
          </>
        )}
      </CardContent>
      <CardTooltip tooltipId="task-company-tooltip">
        The % of documents extracted which did not require any human review, ie
        no review task was created for these documents
      </CardTooltip>
    </Card>
  );
};

StpPanel.propTypes = {
  docsCount: PropTypes.number,
  numTasks: PropTypes.number,
  docs: PropTypes.object,
  tasks: PropTypes.object,
};

StpPanel.defaultProps = {
  docsCount: null,
  numTasks: null,
  docs: null,
  tasks: null,
};

export default connect(mapStateToProps)(StpPanel);
