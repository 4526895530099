import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FaChartLine, FaTasks } from 'react-icons/fa';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { withRouter } from 'react-router-dom';
import ContextBar from '../../../UI/ContextBar/ContextBar';
import ContextBarBtn from '../../../UI/ContextBar/ContextBarBtn';

const mapStateToProps = state => {
  const { auth } = state;
  return {
    canReadAnalytics: auth.permissions.canReadAnalytics,
    canReadTaskAnalytics: auth.permissions.canReadTaskAnalytics,
  };
};

const Menu = ({ canReadAnalytics, canReadTaskAnalytics }) => {
  return (
    <ContextBar>
      {canReadAnalytics && (
        <ContextBarBtn to="/analytics/billing" label="Billing Dashboard">
          <FaChartLine style={{ fontSize: '17px' }} />
        </ContextBarBtn>
      )}
      {canReadTaskAnalytics && (
        <ContextBarBtn to="/analytics/tasks/company" label="Tasks Dashboard">
          <FaTasks style={{ fontSize: '17px' }} />
        </ContextBarBtn>
      )}
      <ReactTooltip id="contextbar-tooltip" variant="dark" />
    </ContextBar>
  );
};

Menu.propTypes = {
  canReadAnalytics: PropTypes.bool.isRequired,
  canReadTaskAnalytics: PropTypes.bool.isRequired,
};

Menu.defaultProps = {};

export default withRouter(connect(mapStateToProps)(Menu));
