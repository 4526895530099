import {
  REQUEST_PRODUCTS,
  RECEIVE_PRODUCTS,
  REFRESH_PRODUCTS,
} from './actions';

const initialState = {
  data: null,
  query: null,
  refresh: false,
  loading: true,
  lastUpdated: null,
};

const productsReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case REQUEST_PRODUCTS: {
      return {
        ...state,
        loading: true,
      };
    }

    case RECEIVE_PRODUCTS: {
      const { data, filters, receivedAt } = payload;

      const allProducts = (data.products || [])
        .sort((a, b) => (a.defaultSortIdx > b.defaultSortIdx ? 1 : -1))
        .map(p => ({
          ...p,
          defaultSortIdx: p.defaultSortIdx || Number.MAX_SAFE_INTEGER,
        }));

      return {
        ...state,
        data: allProducts,
        query: (filters && filters.query) || '',
        loading: false,
        refresh: false,
        lastUpdated: receivedAt,
      };
    }

    case REFRESH_PRODUCTS: {
      return {
        ...state,
        refresh: true,
      };
    }

    default:
      return state;
  }
};

export default productsReducer;
