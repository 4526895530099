import moment from 'moment';

const mapFromTo = (t, prev) => ({
  ...t,
  last: !prev ? 0 : prev.threshold,
  from: !prev ? 0 : prev.threshold + 1,
  to: t.threshold === 'inf' ? Infinity : t.threshold,
  basePrice: (!prev ? t.threshold : t.threshold - prev.threshold) * t.unitPrice,
});

const mapBasePrice = (t, prev) => {
  return {
    ...t,
    basePrice: !prev ? 0 : prev.basePrice,
  };
};

/**
 * Removes flat price tier for product pricing tiers to make
 * calculation easier. Flat price is handled in calculateSpend.
 * @param {*} tiers
 */
export const setVisibleTiers = tiers => {
  let visibleTiers = tiers.map(t => t);

  if (
    tiers.length > 1 &&
    tiers[0].flatPrice > 0 &&
    tiers[0].unitPrice === 0 &&
    Math.ceil(tiers[0].flatPrice / tiers[1].unitPrice) === tiers[0].threshold
  ) {
    visibleTiers.shift();
  }

  visibleTiers = visibleTiers.map((t, i) => mapFromTo(t, visibleTiers[i - 1]));
  visibleTiers = visibleTiers.map((t, i) =>
    mapBasePrice(t, visibleTiers[i - 1]),
  );

  return visibleTiers;
};

/**
 * For a given product's pricing tiers and noOfUnits extracted,
 * calculate the cost.
 * @param {*} noOfUnits
 * @param {*} tiers
 * @param {*} productId
 */
export const calculateSpend = (pages, tiers, productId) => {
  const visibleTiers = setVisibleTiers(tiers);
  /**
   * Absolute min spend set so you don't have to calculate it.
   * Then you just work out the difference the noOfUnits with the
   * the '.last' '.to' values and apply the current tier unit price to that
   *
   */
  const minSpend = tiers[0].flatPrice;
  const noOfUnits = pages;
  if (pages - tiers[0].threshold <= 0) return minSpend;
  const activeTier = visibleTiers.reduce((t, next) => {
    if (noOfUnits >= t.from && noOfUnits <= t.to) {
      return t;
    }
    return next;
  }, visibleTiers[0]); // this lil nugget gets the current active tier based off noOfUnits
  let totalSpend = minSpend;
  visibleTiers.forEach(t => {
    if (noOfUnits >= t.from) {
      totalSpend += t.basePrice;
    }
  });
  totalSpend += (noOfUnits - activeTier.last) * activeTier.unitPrice;
  /**
   * For debugging purposes
   */
  // console.info(
  //   `ProductId: ${productId} \n\nTiers: ${JSON.stringify(tiers)}\n\nNoOfUnits: ${noOfUnits}\n\nTotalSpend: ${totalSpend}`,
  // );
  return totalSpend;
};

/**
 * Helper function to return important information back about each
 * subscription, such as pricing tiers, trial end date, and pages extract
 * each day.
 * @param {*} productId
 * @param {*} subscriptions
 */
export const getBillingInfo = (usage, subscriptions) => {
  const defaultTier = {
    flatPrice: 0,
    unitPrice: 0,
    threshold: 'inf',
  };

  return Object.entries(usage).map(u => {
    const productId = u[0];
    const stats = u[1];
    let tiers = [defaultTier];
    const product = (subscriptions.data || []).find(
      s => s.productId === productId,
    );
    if (
      product &&
      product.pricing &&
      product.pricing.tiers &&
      product.pricing.tiers.length > 0
    ) {
      tiers = product.pricing.tiers;
    }
    const trialEndDate = product ? product.trialEndDate : null;
    const isTrial = product ? product.isTrial : null;
    return {
      productId,
      pagesDaily: stats.pagesDaily.map(num => parseInt(num)),
      docsDaily: stats.cleanDaily.map(num => parseInt(num)),
      totalDocs: parseInt(stats.total.count),
      tiers,
      isTrial,
      trialEndDate,
    };
  });
};

/**
 * Gets the total spend from start date for each day in a given billing period,
 * the total spend and total number of pages extracted for every product
 * user has a subscription to.
 *
 * Kept track of spend each day in case we want to switch to just viewing spend for a
 * given day instead of total spend accrued up to that day.
 * @param {*} usage
 * @param {*} subscriptions
 * @param {*} billingPeriod
 */
export const getDailySpend = (usage, subscriptions, billingPeriod) => {
  const dailySpend = { ...usage };
  let sumCost = 0.0; // Total cost for billing period
  let sumPages = 0; // Total number of pages extracted for billing period
  let sumDocs = 0; // Total number of docs extracted for a billing period
  const productsUsage = getBillingInfo(dailySpend, subscriptions);

  // Calculates cost for every product subscription for each day in a billing period
  for (const product of productsUsage) {
    let totalCount = 0; // Total pages extracted for a product
    const costDaily = []; // Array  of spend each day for a product
    const totalCostDaily = []; // Array of total cost up to current day for a product
    let day = 0; // To track when trial end date ends

    for (const noOfUnits of product.pagesDaily) {
      const count = noOfUnits + totalCount;
      let cost = 0.0;
      const trialEndDate = moment(product.trialEndDate);
      const billingStart = moment(billingPeriod.startDate);

      // If subscription's trial has ended and they have a full subscription add cost
      if (
        !product.isTrial &&
        trialEndDate.diff(billingStart.add(day, 'days'), 'days') <= 0
      ) {
        cost = calculateSpend(count, product.tiers, product.productId);
      }

      // Keep track of totals and difference from previous day
      totalCount += noOfUnits;
      const previousCost =
        totalCostDaily.length > 0
          ? totalCostDaily[totalCostDaily.length - 1]
          : 0;
      const diff = cost - previousCost;
      totalCostDaily.push(cost.toTwoDecimals());
      costDaily.push(diff.toTwoDecimals());
      day++;
    }

    // Update our usage summary for a given product
    const totalSpend = totalCostDaily[totalCostDaily.length - 1];
    sumCost += parseFloat(totalSpend);
    sumPages += parseInt(totalCount);
    sumDocs += product.totalDocs;
    dailySpend[product.productId] = {
      ...dailySpend[product.productId],
      spendDaily: costDaily,
      totalPages: totalCount,
      totalSpendDaily: totalCostDaily,
      totalSpend,
      totalDocs: product.totalDocs,
      docsDaily: product.docsDaily,
    };
  }
  return {
    dailySpend,
    totalSpend: sumCost,
    totalPages: sumPages,
    totalDocs: sumDocs,
  };
};

/**
 * Removes the pricing tier's property, eg. t.unitPrice if the value of that
 * prop is 0.
 * @param {*} tiers
 */
export const normalisePricingTiers = tiers => {
  const normalisedTiers = tiers.map(t => t);
  normalisedTiers.forEach((t, i) => {
    Object.entries(t).forEach(([key, value]) => {
      if (value === 0) {
        delete normalisedTiers[i][key];
      }
    });
  });
  return normalisedTiers;
};

/**
 * Gets billing periods by getting earliest start date from subscriptions
 * @param {*} subscriptions
 * @returns
 */
export const generateMonths = subscriptions => {
  const billingPeriods = [];
  const dateEnd = moment();

  // get subscription dates
  const subscriptionDates = subscriptions.map(v => v.startDate) || [];

  // sort from earliest
  subscriptionDates.sort();

  // get first item as start date
  const dateStart = moment(subscriptionDates[0]);

  while (dateStart <= dateEnd) {
    const id = moment(dateStart).format('YYYYMMDD');
    const startDate = moment(dateStart).format('YYYY-MM-DDTHH:mm:ssZZ');
    const startLabel = moment(startDate).format('DD MMM YY');

    // get the last day of the month
    const endDate = moment(startDate)
      .endOf('month')
      .format('YYYY-MM-DDTHH:mm:ssZZ');
    const endLabel = moment(endDate).format('DD MMM YY');

    billingPeriods.push({
      id,
      startDate,
      startLabel,
      endDate,
      endLabel,
      products: subscriptions
        .filter(s => {
          // let subStart = moment(s.startDate);
          const subEnd = s.endDate ? moment(s.endDate) : null;
          const billingStart = moment(startDate);
          // let billingEnd = moment(endDate);

          return !subEnd || subEnd.isAfter(billingStart);

          /**
           * TO-DO: Fix subscriptions table db to get
           * start date from earliest subscriptions in
           * customer db
           */

          // if (subStart.isAfter(billingEnd)) {
          //   return false;
          // }
          // if (!subEnd) {
          //   return true
          // } else {
          //   return subEnd.isBetween(billingStart, billingEnd, undefined, '[]');
          // }
        })
        .map(x => x.productId),
    });
    dateStart.add(1, 'month').startOf('month');
  }
  return billingPeriods;
};
