import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { Button } from '../../library/atoms/Buttons';
import TextLink from '../UI/TextLink/TextLink';
import Card, { CardContent } from '../UI/Card';
import Form, { TextInput, useForm, validatorType } from '../Form';

import { forgotPassword } from '../../reducers/user/actions';

import './Password.scss';
import { PageHelmet } from '../Page';

const mapStateToProps = state => {
  const { user } = state;
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isLoading: user.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      forgotPassword: email => dispatch(forgotPassword(email)),
    },
  };
};

const INITIAL_STATE = {
  email: {
    required: true,
    validators: [validatorType.Email],
  },
};

const ForgotPassword = ({ isLoading, dispatch, isAuthenticated }) => {
  const [form] = useForm({ ...INITIAL_STATE });
  const { fields, errors } = form;

  const handleSubmitForm = e => {
    e.preventDefault();
    dispatch.forgotPassword(fields.email.value);
  };

  return (
    <div className="password password-container">
      <PageHelmet title="Forgot Password" />
      {isAuthenticated ? <Redirect to="/" /> : null}
      <h2 className="password-heading">
        <span>Forgot password</span>
      </h2>

      {isLoading ? (
        <LoadingSpinner className="password-spinner" />
      ) : (
        <Card className="password-form">
          <CardContent>
            <p>
              Enter your email address below, we will send you an email with a
              link to change your password.
            </p>
            <Form onSubmit={e => handleSubmitForm(e)}>
              <TextInput
                type="email"
                label="Email"
                name="email"
                value={fields.email.value}
                errors={errors}
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                required
              />

              <Button className="submit-btn" type="submit">
                Change password
              </Button>
              <TextLink className="login-link" path="/">
                Log In
              </TextLink>
            </Form>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

ForgotPassword.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  dispatch: PropTypes.shape({
    registerUser: PropTypes.func,
  }),
};

ForgotPassword.defaultProps = {
  isLoading: false,
  dispatch: {
    registerUser: () => {},
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
