import axios from 'axios';
import * as utils from './utils';

const apiBaseUrl = process.env.APP_API_HOST_NAME;

class UserService {
  addUser = (accessToken, companyId, userData) => {
    return utils.postData(`${apiBaseUrl}/app/company/${companyId}/addUser`, {
      accessToken,
      data: userData,
    });
  };

  changePassword = (accessToken, oldPassword, newPassword) => {
    return utils.postData(`${apiBaseUrl}/app/changepassword`, {
      accessToken,
      data: {
        oldPassword,
        newPassword,
      },
    });
  };

  getUserById = (accessToken, userId) => {
    return utils.getData(`${apiBaseUrl}/app/users/${userId}`, {
      accessToken,
    });
  };

  getUsersByCompany = (accessToken, companyId, params) => {
    return utils.getData(`${apiBaseUrl}/app/company/${companyId}/users`, {
      accessToken,
      ...params,
    });
  };

  updateUser = (accessToken, userId, userData) => {
    return utils.putData(`${apiBaseUrl}/app/users/${userId}`, {
      accessToken,
      data: userData,
    });
  };

  updateUserProfile = (accessToken, userId, userProfile) => {
    const { profileData, onboarded } = userProfile;
    return utils.putData(`${apiBaseUrl}/app/users/${userId}`, {
      accessToken,
      data: {
        profileData,
        onboarded,
      },
    });
  };

  getCompanies = accessToken => {
    return utils.getData(`${apiBaseUrl}/app/companies`, {
      accessToken,
    });
  };

  resendWelcomeEmail = (accessToken, userId) => {
    return utils.postData(`${apiBaseUrl}/app/resendVerificationEmail`, {
      accessToken,
      data: {
        userId,
      },
    });
  };

  resendInviteEmail = (accessToken, userId) => {
    return utils.getData(
      `${apiBaseUrl}/app/users/${userId}/resendInviteEmail`,
      {
        accessToken,
      },
    );
  };

  registerUser = userData => {
    return axios.post(`${apiBaseUrl}/app/register/developer`, userData);
  };

  registerInviteUser = userData => {
    return axios.post(
      `${apiBaseUrl}/app/company/${userData.companyId}/users/register`,
      userData,
    );
  };
}

export default new UserService();
