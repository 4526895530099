/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './_form-section.scss';

const resizeSection = (outerRef, innerRef, isExpanded) => {
  if (outerRef.current && innerRef.current) {
    outerRef.current.classList.add('form-section__body--animating');
    outerRef.current.style.height = isExpanded
      ? `${innerRef.current.offsetHeight}px`
      : '0px';

    setTimeout(() => {
      outerRef.current.classList.remove('form-section__body--animating');
    }, 200);
  }
};

const FormSection = ({ children, className, heading, isExpanded }) => {
  const outerRef = useRef(null);
  const innerRef = useRef(null);

  // useEffect(() => {
  //   resizeSection(outerRef, innerRef, isExpanded);
  //   window.addEventListener('resize', () =>
  //     resizeSection(outerRef, innerRef, isExpanded),
  //   );
  //   return () => {
  //     window.removeEventListener('resize', resizeSection);
  //   };
  // }, []);

  // useEffect(() => {
  //   resizeSection(outerRef, innerRef, isExpanded);
  // }, [isExpanded]);

  return (
    <div
      className={classNames('form-section', className, {
        'form-section--expanded': isExpanded,
      })}
    >
      {heading && (
        <div className="form-section__header">
          <h2>{heading}</h2>
        </div>
      )}
      <div
        className={classNames('form-section__body', className, {
          'form-section__body--expanded': isExpanded,
          // 'form-section__body--animating': isExpanded,
        })}
        ref={outerRef}
      >
        <div className="form-section__inner" ref={innerRef}>
          {children}
        </div>
      </div>
    </div>
  );
};

FormSection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  heading: PropTypes.any,
  isExpanded: PropTypes.bool,
};

FormSection.defaultProps = {
  className: null,
  children: null,
  heading: null,
  isExpanded: true,
};

export default FormSection;
