import React from 'react';
import PropTypes from 'prop-types';
import productNames from '../../common/lookups/platformProductLookup';

const UserRoles = ({ roles }) => {
  if (roles.length === 0) {
    return (
      <div className="form__group-roles-field">
        No roles assigned for this user
      </div>
    );
  }
  return roles.map(({ productId, roleName }) => (
    <div key={productId} className="form__group-roles-item">
      <label className="form__field-label-compact" htmlFor={productId}>
        {productNames[productId] || productId}
      </label>
      <div className="form__group-roles-field">{roleName}</div>
    </div>
  ));
};

UserRoles.propTypes = {
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.string,
      roleName: PropTypes.string,
    }),
  ).isRequired,
};

export default UserRoles;
