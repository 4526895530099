import * as utils from './utils';

const apiBaseUrl = process.env.APP_API_HOST_NAME;
const analyticsServiceUrl =
  process.env.ANALYTIC_SERVICE_HOST_NAME || apiBaseUrl;

class AnalyticsService {
  getFieldsetsUsage = (
    accessToken,
    companyId,
    { startDate, endDate, fieldSets },
  ) => {
    return utils.getData(
      `${analyticsServiceUrl}/analytics/usage/${companyId}/fieldSets`,
      {
        accessToken,
        startDate,
        endDate,
        fieldSets,
      },
    );
  };
}

export default new AnalyticsService();
