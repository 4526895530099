import React from 'react';
import { FaCircleNotch, FaExternalLinkAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { replace } from 'connected-react-router';
import PropTypes from 'prop-types';
import Chip from '../../../../components/UI/Chip/Chip';
import { fetchDocTasks } from '../../../../reducers/docs/actions';
// import './style.scss';

const Tasks = ({
  row: {
    original: { id: docId, companyId, tasks },
  },
}) => {
  const noOfTasks = tasks.length;

  const taskUrl = companyId
    ? `/tasks?q=&docId=${docId}&companyId=${companyId}`
    : `/tasks?q=&docId=${docId}`;

  const tasksLink = (
    <Link to={taskUrl}>
      <div className="tasks-link">
        {noOfTasks} <FaExternalLinkAlt />
      </div>
    </Link>
  );

  return (
    <div>
      <Chip
        className="tasks"
        color={!noOfTasks ? 'grey' : 'purple'}
        value={!noOfTasks ? 'N/A' : tasksLink}
        key={`${docId}-tags`}
      />
    </div>
  );
};

Tasks.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      tasks: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
  }).isRequired,
};

// export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
export default Tasks;
