import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import '../Input/Input.scss';
import './_textarea.scss';

const TextArea = ({
  className,
  children,
  defaultValue,
  onChange,
  ...props
}) => {
  const [value, setValue] = useState(defaultValue || '');
  const contentRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (!inputRef.current || !contentRef.current) return;
    inputRef.current.style.height = `${contentRef.current.offsetHeight}px`;
  }, [value]);

  const handleChange = e => {
    setValue(e.target.value);
    onChange(e);
  };

  return (
    <div className="textarea">
      <div className="textarea__data">
        <div
          className={classNames('textarea__hidden', className)}
          aria-hidden="true"
          ref={contentRef}
        >
          {value}
        </div>
      </div>
      <textarea
        className={classNames('textarea__input', className)}
        value={value}
        ref={inputRef}
        onChange={handleChange}
        {...props}
      />
    </div>
  );
};

TextArea.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
};

TextArea.defaultProps = {
  className: '',
  children: null,
  defaultValue: null,
  onChange: () => {},
};

export default TextArea;
