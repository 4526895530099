/* eslint-disable no-multi-assign */
/* eslint-disable no-underscore-dangle */
import { useEffect } from 'react';

const hubspotTracker = path => {
  useEffect(() => {
    const _hsq = (window._hsq = window._hsq || []);
    _hsq.push(['setPath', `${path}`]);
    _hsq.push(['trackPageView']);
  }, []);
};

export default hubspotTracker;
