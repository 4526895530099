import React from 'react';
import TourContent from '../TourContent';
import DocumentView from '../../../../../../assets/images/onboarding/introduction-to-sypht/document-view.svg';

const ViewExtraction = () => (
  <TourContent
    icon={DocumentView}
    text="View the structured data extracted from your document(s), and where Sypht found it on the page."
  />
);

export default ViewExtraction;
