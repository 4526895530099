import axios from 'axios';

const apiBaseUrl = process.env.APP_API_HOST_NAME;

class FieldsService {
  getFields(accessToken) {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    };
    return axios.get(`${apiBaseUrl}/app/fields`, config);
  }
}

export default new FieldsService();
