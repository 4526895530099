import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchCountries } from '../../../reducers/lookups/actions';

import Form, {
  RadioInput,
  SelectInput,
  TextInput,
  useForm,
  validatorType,
} from '../../Form';

import { Button } from '../../../library/atoms/Buttons';
import Card, { CardContent } from '../../UI/Card';
import PasswordFormCheck from '../../User/PasswordFormCheck';
import hubspotTracker from '../../../common/helpers/hubspotTracker';

import '../../Form/components/TextInput/TextInput.scss';

const INITIAL_STATE = {
  firstName: {
    required: true,
  },
  lastName: {
    required: true,
  },
  email: {
    required: true,
    validators: [validatorType.Email],
  },
  companyName: {
    required: true,
  },
  country: {
    required: true,
  },
  password: {
    required: true,
    validators: [validatorType.Password],
  },
  verifyPassword: {
    required: true,
    validators: [validatorType.Match],
    match: 'password',
  },
  userType: {
    required: true,
  },
  jobFunction: {
    required: true,
  },
};

const JOB_FUNCTIONS_OPTIONS = [
  'Admin / Office Administration',
  'Business Development',
  'C-Suite',
  'Community & Social Services',
  'Consulting',
  'Education',
  'Engineering & Technical',
  'Finance',
  'Founder / Director / Owner',
  'Healthcare & Medical',
  'Human Resources',
  'Information Technology',
  'Legal',
  'Management',
  'Marketing',
  'Media & Communications & PR',
  'Operations',
  'Other',
  'Program & Product Management',
  'Purchasing Buyers',
  'Real Estate',
  'Research',
  'Sales',
  'Unknown',
];

const mapStateToProps = state => {
  const { lookups } = state;
  const { countries } = lookups;

  return {
    countries: countries && countries.data ? countries.data : [],
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      fetchCountries: () => dispatch(fetchCountries()),
    },
  };
};

const RegisterForm = ({ countries, handleSubmit, dispatch }) => {
  hubspotTracker('/register');

  const [form] = useForm({ ...INITIAL_STATE });
  const { fields, errors } = form;

  const [termsAccepted, updateTerms] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const countryOptions = (countries || []).map(c => ({
    value: c.code,
    label: c.name,
  }));

  const jobFunctionOptions = JOB_FUNCTIONS_OPTIONS.map(v => ({
    value: v,
    label: v,
  }));

  useEffect(() => {
    dispatch.fetchCountries();
  }, []);

  const handleSubmitForm = e => {
    e.preventDefault();
    const hubspotutk = /hubspotutk=([a-f0-9]*);/.exec(document.cookie)
      ? /hubspotutk=([a-f0-9]*);/.exec(document.cookie)[1]
      : null;

    const userData = {
      firstName: fields.firstName.value,
      lastName: fields.lastName.value,
      email: fields.email.value,
      companyName: fields.companyName.value,
      country: fields.country.value,
      password: fields.password.value,
      userType: fields.userType.value,
      jobFunction: fields.jobFunction.value,
      hubspotutk,
    };

    if (Object.keys(errors).length > 0 || !form.isValid) {
      setErrorMessage({
        message: 'Please fill-up required and invalid fields.',
      });
    } else {
      setErrorMessage(null);
      handleSubmit(userData);
    }
  };

  const handleTermsChange = () => {
    updateTerms(!termsAccepted);
  };

  const radioHandleChange = e => {
    fields.userType.isValid = true;
    form.handleChange({
      target: {
        name: e.target.name,
        value: e.target.value,
        type: 'radio',
      },
    });
  };

  return (
    <Card className="register-form">
      <CardContent>
        <Form onSubmit={e => handleSubmitForm(e)}>
          <TextInput
            label="First Name"
            name="firstName"
            value={fields.firstName.value}
            errors={errors}
            onBlur={form.handleBlur}
            onChange={form.handleChange}
            required
          />

          <TextInput
            label="Last Name"
            name="lastName"
            value={fields.lastName.value}
            errors={errors}
            onBlur={form.handleBlur}
            onChange={form.handleChange}
            required
          />

          <TextInput
            type="email"
            label="Business Email"
            name="email"
            value={fields.email.value}
            errors={errors}
            onBlur={form.handleBlur}
            onChange={form.handleChange}
            required
          />

          <TextInput
            label="Company Name"
            name="companyName"
            value={fields.companyName.value}
            errors={errors}
            onBlur={form.handleBlur}
            onChange={form.handleChange}
            required
          />

          <SelectInput
            className="country-select"
            label="Country"
            name="country"
            placeholder="Country"
            value={fields.country.value}
            errors={errors}
            onBlur={form.handleBlur}
            onChange={form.handleChange}
            options={countryOptions}
            required
          />

          <div>
            <label htmlFor="userType" className="form__input-label">
              Are you a technical or business user? *
            </label>

            <RadioInput
              label="Technical"
              name="userType"
              id="Technical"
              value="Technical"
              errors={errors}
              onBlur={form.handleBlur}
              onChange={radioHandleChange}
              checked={fields.userType.value === 'Technical'}
              required
            />

            <RadioInput
              label="Business"
              name="userType"
              id="Business"
              value="Business"
              errors={errors}
              onBlur={form.handleBlur}
              onChange={radioHandleChange}
              checked={fields.userType.value === 'Business'}
              required
            />
          </div>

          <SelectInput
            className="job-function-select"
            label="Job Function"
            name="jobFunction"
            placeholder="Job Function"
            value={fields.jobFunction.value}
            errors={errors}
            onBlur={form.handleBlur}
            onChange={form.handleChange}
            options={jobFunctionOptions}
            required
          />

          <TextInput
            type="password"
            label="Password"
            name="password"
            value={fields.password.value}
            errors={errors}
            onBlur={form.handleBlur}
            onChange={form.handleChange}
            required
          />

          <TextInput
            type="password"
            label="Verify Password"
            name="verifyPassword"
            value={fields.verifyPassword.value}
            errors={errors}
            onBlur={form.handleBlur}
            onChange={form.handleChange}
            required
          />

          <PasswordFormCheck password={fields.password.value} />

          <div className="form-check">
            <input
              id="termsAccepted"
              type="checkbox"
              className="form-check-input"
              value={termsAccepted}
              name="termsAccepted"
              onChange={handleTermsChange}
            />
            <label className="sub" htmlFor="termsAccepted">
              I have read and accept the{' '}
              <a
                target="_blank"
                href="https://www.sypht.com/terms"
                rel="noreferrer"
              >
                terms & conditions
              </a>{' '}
              and
              <a
                target="_blank"
                href="https://www.sypht.com/privacy-policy"
                rel="noreferrer"
              >
                {' '}
                privacy policy.
              </a>
            </label>
          </div>

          {errorMessage && (
            <span className="register-form__error">{errorMessage.message}</span>
          )}

          <Button type="submit" disabled={!termsAccepted}>
            Create an account
          </Button>
        </Form>
      </CardContent>
    </Card>
  );
};

RegisterForm.propTypes = {
  countries: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func,
  dispatch: PropTypes.shape({
    fetchCountries: PropTypes.func,
  }),
};

RegisterForm.defaultProps = {
  handleSubmit: () => {},
  dispatch: {
    fetchCountries: () => {},
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
