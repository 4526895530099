import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Button } from '../../../../../../library/atoms/Buttons';
import CheckboxList from '../../../../../UI/CheckboxList/CheckboxList';
import Form, { FormSection } from '../../../../../Form';
import { fetchSubscriptions } from '../../../../../../reducers/subscriptions/actions';
import history from '../../../../../../services/historyService';
import { isNullOrEmpty } from '../../../../../../common/helpers';

import ProductDropdown from '../ProductDropdown/ProductDropdown';
import RulePicker from '../RulePicker/RulePicker';
import { allowedFields, rulesLookup } from '../../../../store/rules/lookups';
import {
  createRule,
  retrieveRule,
  updateRule,
  retrieveProductFields,
} from '../../../../store/rules/actions';
import './_rule-form.scss';

const toShortName = str => {
  return str
    .substr(str.indexOf('.') + 1)
    .replace('[', ' (')
    .replace(']', ')')
    .toTitleCase();
};

const RULE_OPTIONS = Object.entries(rulesLookup).map(([k, v]) => ({
  value: k,
  label: v.label,
}));

const NEW_FORM = {
  conditions: [],
};

const getConditions = (fields, ruleType) => {
  const conditions = (fields || []).map(f => ({
    type: ruleType.type,
    field: f,
    ...(ruleType.min && { min: ruleType.min }),
    ...(ruleType.max && { max: ruleType.max }),
  }));
  return conditions;
};

const getDefaultOptions = conditions => {
  return (conditions || []).map(c => c.field);
};

const getDefaultRule = conditions => {
  const condition = conditions && conditions[0];
  if (!condition) return null;

  const { field, ...defaultRule } = condition;
  return defaultRule;
};

const isFormValid = (form, ruleType) => {
  let isValid = true;

  // validate step 2 has at least 1 option selected
  if (!form.conditions.length) {
    isValid = false;
    console.error('Step 2: At least one option must be selected.');
  }

  // validate step 3 fields - all fields completed
  if (Object.values(ruleType).filter(val => isNullOrEmpty(val)).length > 0) {
    isValid = false;
    console.error('Step 3: One or more required fields are empty.');
  }

  // validate step 3 fields - min < max
  if (ruleType.min > ruleType.max) {
    isValid = false;
    console.error('Step 3: Min < max.');
  }

  return isValid;
};

const mapStateToProps = (state, props) => {
  const { auth, subscriptions, validateRules } = state;
  const { fieldsets } = validateRules;
  const { productId } = props;

  return {
    fieldsets,
    productId,
    subscriptions: subscriptions.data,
    user: auth.user ? auth.user.data : null,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      fetchSubscriptions: () => dispatch(fetchSubscriptions()),
      createRule: (productId, form) => dispatch(createRule(productId, form)),
      retrieveRule: ruleId => dispatch(retrieveRule(ruleId)),
      updateRule: (ruleId, productId, ruleType, form) =>
        dispatch(updateRule(ruleId, productId, ruleType, form)),
      retrieveProductFields: productId =>
        dispatch(retrieveProductFields(productId)),
    },
  };
};

const RuleForm = ({
  fieldsets,
  productId,
  rule,
  subscriptions,
  user,
  dispatch,
}) => {
  const isNew = !rule;
  const [selectedProductId, setSelectedProductId] = useState(productId);
  const [submitCount, setSubmitCount] = useState(0);

  const [step, setStep] = useState(isNew ? 2 : 3);
  const [form, setForm] = useState(isNew ? NEW_FORM : rule);
  const [ruleType, setRuleType] = useState({});

  const defaultOptions = getDefaultOptions((rule || {}).conditions);
  const defaultRule = getDefaultRule((rule || {}).conditions);

  useEffect(() => {
    if (!subscriptions) {
      dispatch.fetchSubscriptions();
    }
  }, [user]);

  useEffect(() => {
    if (selectedProductId) {
      dispatch.retrieveProductFields(selectedProductId);
    }
  }, [selectedProductId]);

  useEffect(() => {
    // on load, get fieldsets
    if (!fieldsets || !fieldsets[productId]) {
      dispatch.retrieveProductFields(productId);
    }

    // fields selected, set to step 3
    if (rule && rule.conditions && rule.conditions.length > 0) {
      setStep(3);
    }
  }, []);

  const handleProductChange = newProduct => {
    if (newProduct) {
      setStep(2);
      setForm(NEW_FORM);
      setSelectedProductId(newProduct.id);
      window.history.replaceState(
        null,
        null,
        `?product=${encodeURIComponent(newProduct.id)}`,
      );
      return;
    }

    // on ai product clear
    setStep(1);
    setForm(NEW_FORM);
    setSelectedProductId(null);
    window.history.replaceState(null, null, window.location.pathname);
  };

  const handleRuleChange = newRule => {
    const fields = (form.conditions || []).map(c => c.field);
    const conditions = getConditions(fields, newRule);

    setForm({ conditions });
    setRuleType(newRule);
  };

  const handleSelectedFieldsChange = newFields => {
    const conditions = getConditions(newFields, ruleType);
    setForm({ conditions });

    if (newFields.length) setStep(3);
    else setStep(2);
  };

  const handleSaveClick = () => {
    setSubmitCount(n => n + 1);
    if (!isFormValid(form, ruleType)) return;

    // prettier-ignore
    const url = `/validate/rules?product=${encodeURIComponent(selectedProductId)}`;
    if (isNew)
      dispatch.createRule(selectedProductId, form).then(() => {
        history.push(url);
      });
    else
      dispatch.updateRule(rule.id, productId, defaultRule, form).then(() => {
        history.push(url);
      });
  };

  const handleSubmit = e => {
    // prevent all submits - handle directly via 'save' button
    e.preventDefault();
  };

  const fieldOptions = useMemo(() => {
    return !fieldsets || !selectedProductId
      ? null
      : (fieldsets[selectedProductId] || [])
          .map(fs => {
            return {
              group: toShortName(fs.name),
              options: fs.fields
                // exclude unpublished and not annotated fields
                .filter(f => f.published !== false && f.annotated !== false)
                .map(f => {
                  return {
                    group: fs.name,
                    id: f.id,
                    value: f.id,
                    label: f.name.replace('.', ''),
                    disabled: !allowedFields.includes(f.type),
                  };
                })
                .sort((a, b) => (a.label > b.label ? 1 : -1)),
            };
          }) // remove groups with no options
          .filter(f => f.options.length);
  }, [selectedProductId, fieldsets, form]);

  return (
    subscriptions &&
    form && (
      <Form className="rule-form" onSubmit={handleSubmit}>
        <FormSection heading="1. Select an AI product" isExpanded={step >= 1}>
          <ProductDropdown
            className="rules__product-select"
            subscriptions={subscriptions}
            defaultProductId={selectedProductId}
            isDisabled={!isNew}
            onChange={handleProductChange}
            isClearable
          />
        </FormSection>
        <FormSection
          heading="2. Select one or more fields"
          isExpanded={step >= 2}
        >
          <p>
            Please select one or more fields available within your AI product,
            for which you'd like to add a new rule.
          </p>
          {fieldOptions && (
            <CheckboxList
              options={fieldOptions}
              tooltips={{
                disabled:
                  'This field is not available for task generation rules.',
              }}
              defaultValues={defaultOptions}
              onChange={handleSelectedFieldsChange}
            />
          )}
        </FormSection>
        <FormSection
          heading="3. Select the condition for the rule"
          isExpanded={step >= 3}
        >
          <p>
            Choose a condition for the rule, that if met, will result in a human
            review task being generated in real-time, adding human-in-the-loop
            supervision for conditions that matter to you the most.
          </p>
          <p>
            <strong>Note:</strong> Please make sure this condition is relevant
            to the fields you've selected above, and use appropriate data type &
            format syntax expected for your selected field to avoid errors.
          </p>
          {(isNew || rule) && (
            <RulePicker
              options={RULE_OPTIONS}
              defaultRule={defaultRule}
              valCounter={submitCount}
              onChange={handleRuleChange}
            />
          )}
        </FormSection>
        <div className="rule-form__footer">
          <Button
            className="rule-form__button"
            color="blue"
            variant="outline"
            onClick={() => {
              history.push(
                `/validate/rules${
                  selectedProductId
                    ? `?product=${encodeURIComponent(selectedProductId)}`
                    : ''
                }`,
              );
            }}
          >
            Cancel
          </Button>
          <Button
            type="button"
            className="rule-form__button"
            color="blue"
            onClick={handleSaveClick}
            disabled={step < 3 || !ruleType.type}
          >
            Save
          </Button>
        </div>
      </Form>
    )
  );
};

RuleForm.propTypes = {
  ruleId: PropTypes.string,
  fieldsets: PropTypes.object,
  rule: PropTypes.object,
  subscriptions: PropTypes.arrayOf(PropTypes.object),
  user: PropTypes.object,
  dispatch: PropTypes.object,
};

RuleForm.defaultProps = {
  ruleId: null,
  fieldsets: null,
  rule: null,
  subscriptions: null,
  user: null,
  dispatch: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(RuleForm);
