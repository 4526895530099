import React, { useEffect, useRef, useState } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import InlineSVG from 'react-inlinesvg';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Modal from '../UI/Modal/Modal';

import quickstartImage from './assets/quickstart.svg';
import webAppImage from './assets/web-app.svg';

import '../UI/Slider/Slider.scss';
import './Onboarding.scss';
import { ONBOARDING_TYPE } from '../../common/constants';

const USER_TYPE_TECHNICAL = 'Technical';

const mapStateToProps = state => {
  const { auth } = state;
  const userData = auth.user.data;
  return {
    userType:
      userData && userData.profileData ? userData.profileData.userType : null,
  };
};

const OnboardingForm = ({ userType, isOpen, onCancel, onSubmit }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const syphtTourRef = useRef(null);
  const apiQuickstartRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (userType === USER_TYPE_TECHNICAL && apiQuickstartRef.current) {
        apiQuickstartRef.current.click();
        apiQuickstartRef.current.focus();
      } else if (syphtTourRef.current) {
        syphtTourRef.current.click();
        syphtTourRef.current.focus();
      }
    }, 500);
  }, [userType]);

  const handleRadioChange = e => {
    const input = e.target;
    const { value } = input;
    setSelectedOption(value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit(e, selectedOption);
  };

  return (
    <Modal
      className="onboarding-modal"
      onCancel={onCancel}
      isOpen={isOpen}
      onConfirm={handleSubmit}
      cancelLabel="Skip"
      confirmLabel={
        <span className="onboarding-modal__go-button">
          Go <MdKeyboardArrowRight className="button__icon-right" />
        </span>
      }
      modalLabel="Welcome to Sypht"
    >
      <form className="onboarding-form">
        <fieldset className="onboarding-form__section">
          <div className="onboarding-form__text-container">
            <p className="onboarding-form__text onboarding-form__text--larger">
              To get you started, we have automatically activated your free{' '}
              <em>14 day trial</em> of our Invoices AI + Receipts AI products.
            </p>
            <p className="onboarding-form__text onboarding-form__text--larger">
              All you need to do is just start uploading your documents. To see
              our full range of AI products, simply checkout{' '}
              <Link
                className="link"
                to="/marketplace/products"
                onClick={onCancel}
              >
                Sypht Marketplace
              </Link>
              .
            </p>
            <p className="onboarding-form__text onboarding-form__text--larger">
              You can activate a free 14 day trial for any product of your
              choosing, and begin Sypht-ing instantly!
            </p>
            <a
              href="#form_submit"
              className="onboarding-form__anchor"
              tabIndex={0}
            >
              Where do you want to go next?
            </a>
            <div id="#form_submit" className="onboarding-form__radio-group">
              <div className="onboarding-form__radio">
                <span className="onboarding-form__radio-text">
                  Upload a document
                </span>
                <input
                  type="radio"
                  className="onboarding-form__radio-input"
                  id="onboarding__firstUse_webApp"
                  name="onboarding__firstUse"
                  value={ONBOARDING_TYPE.TOUR}
                  onChange={handleRadioChange}
                  tabIndex={0}
                  ref={syphtTourRef}
                />
                <label
                  className="onboarding-form__radio-label"
                  htmlFor="onboarding__firstUse_webApp"
                >
                  <InlineSVG
                    className="onboarding-form__radio-image"
                    src={webAppImage}
                  />
                </label>
              </div>
              <div className="onboarding-form__radio">
                <span className="onboarding-form__radio-text">
                  API Documentation
                </span>
                <input
                  type="radio"
                  className="onboarding-form__radio-input"
                  id="onboarding__firstUse_api"
                  name="onboarding__firstUse"
                  value={ONBOARDING_TYPE.API}
                  onChange={handleRadioChange}
                  tabIndex={0}
                  ref={apiQuickstartRef}
                />
                <label
                  className="onboarding-form__radio-label"
                  htmlFor="onboarding__firstUse_api"
                >
                  <InlineSVG
                    className="onboarding-form__radio-image"
                    src={quickstartImage}
                  />
                </label>
              </div>
            </div>
            <p className="onboarding-form__text onboarding-form__text--larger">
              {selectedOption === ONBOARDING_TYPE.TOUR &&
                'The tour will guide you through the process of uploading your ' +
                  'first document and running your first data extraction.'}
              {selectedOption === ONBOARDING_TYPE.API &&
                `API Documentation shows you how to integrate our intelligent document data extraction with your processes.`}
            </p>
          </div>
        </fieldset>
      </form>
    </Modal>
  );
};

OnboardingForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  userType: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

OnboardingForm.defaultProps = {
  userType: null,
};

export default connect(mapStateToProps)(OnboardingForm);
