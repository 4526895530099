import React from 'react';
import ReactDom from 'react-dom';
import ReactLoading from 'react-loading';
import PropTypes from 'prop-types';
import { FaPlay } from 'react-icons/fa';
import classNames from 'classnames';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Button from '../Button/Button';
import './Modal.scss';

const Modal = ({
  isOpen,
  cancelLabel,
  onCancel,
  confirmLabel,
  onConfirm,
  className,
  children,
  disabledConfirm,
  modalLabel,
  includeLaunchBtn,
  launchTip,
  onLaunch,
  noCancel,
  noConfirm,
  isLoading,
  secondaryConfirmLabel,
  onSecondaryConfirm,
}) => {
  if (!isOpen) return null;
  return ReactDom.createPortal(
    <div className="modal__container" role="presentation">
      <div className={classNames('modal__dialog', className)}>
        {isLoading && (
          <div className="modal__loading">
            <ReactLoading className="modal__loading-icon" />
          </div>
        )}
        <div className="modal__heading">
          <div className="modal__title">{modalLabel}</div>
        </div>
        <div className="modal__content">{children}</div>
        <div className="modal__btn-group">
          {confirmLabel && (
            <div className={includeLaunchBtn ? 'modal__launch-task-btn' : ''}>
              {!noConfirm && (
                <Button
                  type="primary"
                  small
                  disabled={disabledConfirm}
                  onClick={onConfirm}
                  className="modal__btn-confirm"
                >
                  {confirmLabel || 'Confirm'}
                </Button>
              )}
              {includeLaunchBtn && (
                <Button
                  data-tooltip-content={launchTip}
                  data-tooltip-id="modal"
                  type="primary"
                  small
                  disabled={disabledConfirm}
                  onClick={onLaunch}
                  className="modal__btn-confirm"
                >
                  <FaPlay />
                </Button>
              )}
            </div>
          )}
          {secondaryConfirmLabel && (
            <Button
              type="secondary-border"
              small
              disabled={disabledConfirm}
              onClick={onSecondaryConfirm}
              className="modal__btn-secondary-confirm"
            >
              {secondaryConfirmLabel || 'Confirm'}
            </Button>
          )}
          {!noCancel && (
            <Button
              type="secondary-border"
              small
              onClick={onCancel}
              className="modal__btn-cancel"
            >
              {cancelLabel || 'Cancel'}
            </Button>
          )}
        </div>
      </div>
      {/* If you're using this modal, use this tooltip and target it with data-tooltip-id="modal". */}
      <ReactTooltip place="top" variant="dark" id="modal" />
    </div>,
    document.getElementById('portal'),
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  className: PropTypes.string,
  cancelLabel: PropTypes.string,
  onCancel: PropTypes.func,
  confirmLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onConfirm: PropTypes.func,
  disabledConfirm: PropTypes.bool,
  modalLabel: PropTypes.string,
  includeLaunchBtn: PropTypes.bool,
  launchTip: PropTypes.string,
  onLaunch: PropTypes.func,
  noCancel: PropTypes.bool,
  noConfirm: PropTypes.bool,
  isLoading: PropTypes.bool,
};

Modal.defaultProps = {
  className: '',
  cancelLabel: '',
  onCancel: () => {},
  confirmLabel: null,
  onConfirm: () => {},
  disabledConfirm: false,
  modalLabel: '',
  includeLaunchBtn: false,
  launchTip: null,
  onLaunch: () => {},
  noCancel: false,
  noConfirm: false,
  isLoading: false,
};

export default Modal;
