/* eslint-disable eqeqeq */
import { applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import { PERSIST } from 'redux-persist';
import { reduxTimeout } from 'redux-timeout';
import thunk from 'redux-thunk';
import { routerMiddleware, LOCATION_CHANGE } from 'connected-react-router';
import AuthMiddleware from './AuthMiddleware';
import notificationManager from './notificationManager';
import AuthService from '../services/AuthService';
import {
  authenticationSuccess,
  setAuthenticatedFailed,
  setAuthenticating,
  refreshSuccess,
  REFRESH_AUTH,
} from '../reducers/authReducer';

import {
  RECEIVE_DOC_TASKS,
  REQUEST_DOC_TASKS,
  RECEIVE_DOC_META,
  REQUEST_DOC_META,
} from '../reducers/docs/actions';
import trackingMiddleware from './trackingMiddleware';

const isReduxLogger = process.env.ENABLE_REDUX_LOGGER === 'true';

const excludedActions = [
  REQUEST_DOC_TASKS,
  RECEIVE_DOC_TASKS,
  RECEIVE_DOC_META,
  REQUEST_DOC_META,
];

const logger = createLogger({
  predicate: (getState, action) => !excludedActions.includes(action.type),
  collapsed: true,
});

const refreshEvents = [LOCATION_CHANGE, REFRESH_AUTH];

const authEvents = [PERSIST, '@@INIT'];

const middlewares = [
  thunk,
  reduxTimeout(),
  AuthMiddleware({
    AuthService,
    loginProvider: process.env.OAUTH_PROVIDER,
    refreshEvents,
    authEvents,
    refreshAction: refreshSuccess,
    authenticationSuccess,
    authenticationFailed: setAuthenticatedFailed,
    setAuthenticating,
  }),
  trackingMiddleware,
  notificationManager,
];

if (isReduxLogger) {
  middlewares.push(logger);
}

export default history =>
  applyMiddleware(routerMiddleware(history), ...middlewares);
