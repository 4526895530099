import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import './Dropdown.scss';

const Menu = ({
  isOpen,
  offset,
  items,
  title,
  setOpen,
  btnRef,
  disableItemClose,
  className,
}) => {
  const menuRef = useRef(null);
  const handleClickOutside = e => {
    if (
      menuRef.current &&
      !menuRef.current.contains(e.target) &&
      !btnRef.current.contains(e.target)
    ) {
      setOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleItemClick = () => {
    if (disableItemClose) {
    } else {
      setOpen(false);
    }
  };

  const getListComponent = () => {
    const style = offset
      ? {
          top: offset.top + offset.height + 10,
          left: offset.right - 200,
        }
      : {};

    return isOpen ? (
      <div
        style={style}
        className={classNames(className, 'dropdown__card')}
        ref={menuRef}
      >
        <div className="dropdown__card-title">{title}</div>
        <ul>
          {items.map((item, i) => (
            <li
              className="dropdown__card-item"
              key={i}
              onClick={handleItemClick}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>
    ) : null;
  };

  return ReactDOM.createPortal(
    getListComponent(),
    document.querySelector('#portal'),
  );
};

export default Menu;
