/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { FaBan, FaImage, FaChevronLeft } from 'react-icons/fa';
import SuggestionList from './SuggestionsList';
import FieldAnnotationBase from './FieldAnnotationBase';

export default class ContentFieldAnnotation extends FieldAnnotationBase {
  onSelectManualEntry = (initialAnnotation, originAnnotation) => {
    const initial = initialAnnotation || '';
    const origin = originAnnotation || null;
    this.props.candidateSelection.setFocus(null);
    this.setState({
      annotation: {
        source: {
          type: 'manual',
          origin,
        },
        data: {
          value: initial,
        },
      },
    });
  };

  onCancelManualMode = () => {
    this.setState(state => ({
      annotation: {
        ...state.annotation,
        data:{
          value: null
        }
      }
    }));
    this.props.candidateSelection.setFocus(null);
    this.props.setPredictionFocus(null);

    // return focus to parent
    setTimeout(() => this.ref.focus(), 0);
  };

  onChangeManualInputValue = e => {
    const value = e.target.value;
    this.setState(state => ({
      annotation: {
        ...state.annotation,
        data:{
          value: value
        }
      }
    }));
  };

  onSubmitManualEntry = e => {
    if (e) {
      e.preventDefault();
    }
    this.saveAnnotation(this.state.annotation);
  };

  onSelectNotPresent = () => {
    this.saveAnnotation({
      source: {
        type: 'manual',
      },
      data: {
        value: null,
      },
    });

    this.props.setPredictionFocus(null);
  };

  onSelectManualSelection = () => {
    const { field, context, annotation, candidateSelection } = this.props;

    const selectionContext = context
      ? context.neighbours
          .filter(c => c.source && c.source.type === 'selection')
          .map(c => c.data.value)
          .flat()
      : [];

    this.props.candidateSelection.setFocus(field, selectionContext);
    if (annotation && annotation.source.type === 'selection') {
      candidateSelection.onEdit(annotation.data.value);
    }

    this.setState({
      annotation: {
        source: {
          type: 'selection',
        },
        data: {
          value: [],
        },
      },
    });
  };

  onConfirmManualSelection = e => {
    const { selectedCandidates } = this.props.candidateSelection;
    const annotation = {
      source: {
        type: 'selection',
      },
      data: {
        value: Object.values(selectedCandidates),
      },
    };
    this.setState({
      annotation,
    });
    if (e) {
      e.preventDefault();
    }
    this.saveAnnotation(annotation);
  };

  onConfirmSplitSelection = e => {
    const { field, saveSplitAnnotations } = this.props;
    const { selectedCandidates } = this.props.candidateSelection;
    const groupIdxs = Object.values(selectedCandidates).map(c => c.token_idx);
    const annotations = Object.values(selectedCandidates).map(c => ({
      id: field.id,
      source: {
        type: 'selection',
        origin: {
          type: 'multi-selection-split',
          idxs: groupIdxs,
        },
      },
      data: {
        value: [c],
      },
    }));
    this.setState({
      annotation: annotations[0],
    });
    if (e) {
      e.preventDefault();
    }
    this.onCancelEdit();
    saveSplitAnnotations(annotations);
  };

  onClearManualSelection = () => {
    this.props.candidateSelection.onClear();
  };

  isSplitAnnotationEnabled = () => {
    const { selectedCandidates } = this.props.candidateSelection;

    return (
      this.props.field.type !== 'bounds' &&
      this.props.field.multiple &&
      Boolean(this.props.saveSplitAnnotations) &&
      Object.values(selectedCandidates).length > 1
    );
  };

  handleKeyDown = e => {
    let handled = true;

    if (this.isEditMode() && e.key === 's') {
      this.onSelectManualSelection();
    } else if (
      this.isEditMode() &&
      this.props.field.multiple &&
      e.key === 'r'
    ) {
      this.props.onSelectRemove();
    } else if (
      this.isEditMode() &&
      this.isSplitAnnotationEnabled() &&
      e.key === 't'
    ) {
      this.onConfirmSplitSelection();
    } else if (this.isEditMode() && e.key === 'e') {
      const { focusPrediction } = this.props;
      if (focusPrediction && focusPrediction.value) {
        this.onSelectManualEntry(focusPrediction.value, {
          type: 'prediction',
          data: focusPrediction,
        });
      } else {
        this.onSelectManualEntry();
      }
    } else {
      handled = false;
    }

    if (handled) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  handleInputKeyDown = e => {
    if (e.key === 'Escape') {
      this.onCancelManualMode();
      e.stopPropagation();
    } else if (e.key === 'Tab') {
      this.onCancelManualMode();
    } else {
      e.stopPropagation();
    }
  };

  handleSelectionKeyDown = e => {
    const { selectedCandidates } = this.props.candidateSelection;
    const selections = Object.values(selectedCandidates);
    let handled = true;

    if (e.key === 'Escape') {
      if (selections.length > 0) {
        this.onClearManualSelection();
      } else {
        this.onCancelManualMode();
      }
    } else if (e.key === 'Enter' || e.key === ' ') {
      if (selections.length > 0) {
        this.onConfirmManualSelection();
      }
    } else if (selections.length > 0 && e.key === 'e') {
      this.onSelectManualEntry(selections.map(v => v.token).join(' '), {
        type: 'selection',
        data: selections,
      });
    } else {
      handled = false;
    }

    if (handled) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  render() {
    const { field, prediction, editable, focusPrediction, needsFocus } =
      this.props;
    const { setPredictionFocus } = this.props;
    const { candidateSelection } = this.props;
    const annotation =
      !this.props.annotation || this.state.edit
        ? this.state.annotation
        : this.props.annotation;

    const sourcePrediction =
      annotation && annotation.source.type === 'prediction' ? prediction : null;
    const sourcePredictionFocusable =
      sourcePrediction && sourcePrediction.bounds;
    const onClickAnnotationValue = () => {
      if (annotation.source?.data) {
        setPredictionFocus(annotation.source.data);
      }
    };
    const editMode = this.isEditMode();
    const manualEntryMode =
      editMode &&
      annotation &&
      annotation.source.type === 'manual' &&
      annotation.data.value !== null;
    const hasSelectionFocus =
      candidateSelection.focusField &&
      candidateSelection.focusField.field === field;
    const manualSelectionMode = editMode && annotation && hasSelectionFocus;
    const manualMode = manualEntryMode || manualSelectionMode;
    const showPredictions = editMode && !manualMode;
    const selectedCandidates = Object.values(
      candidateSelection.selectedCandidates,
    );

    const augmentedPredictions = prediction ? [...prediction] : [];
    if (!augmentedPredictions.some(p => p.value_norm == null)) {
      // even if there is no null in the top-K we still want to suggest it
      augmentedPredictions.push({
        field: field.id,
        value: null,
        value_norm: null,
        confidence: null,
        confidence_norm: null,
        bounds: null,
      });
    }

    return (
      <div
        ref={r => {
          this.ref = r;
        }}
        className="field-value-instance"
        onKeyDown={this.handleKeyDown}
      >
        {!editMode && annotation ? (
          <div
            className={`field-value ${
              sourcePredictionFocusable ? 'selectable' : ''
            }`}
            onClick={onClickAnnotationValue}
          >
            <div style={{ wordBreak: 'break-word' }}>
              {annotation.data.value == null ? (
                <span className="null-value">
                  <FaBan /> Not present
                </span>
              ) : annotation.source.type === 'selection' &&
                field.type === 'bounds' ? (
                <div className="bounds-value">
                  {/* TODO: this would be much nicer as a small image preview of the cropped region */}
                  <FaImage />
                  {annotation.data.value.map((c, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <span key={i}>
                      {Math.round(c.bounds.topLeft.x * 100, 1)},{' '}
                      {Math.round(c.bounds.topLeft.y * 100, 1)} ⮕&nbsp;
                      {Math.round(c.bounds.bottomRight.x * 100, 1)},{' '}
                      {Math.round(c.bounds.bottomRight.y * 100, 1)}
                    </span>
                  ))}
                </div>
              ) : annotation.source.type === 'selection' ? (
                annotation.data.value.map(c => c.token).join(' ')
              ) : (
                annotation.data.value
              )}
            </div>
            {editable ? (
              <div className="button-div edit" onClick={this.editAnnotation}>
                <span>Edit</span>
              </div>
            ) : null}
          </div>
        ) : null}
        {showPredictions ? (
          <SuggestionList
            field={field}
            hasFocus={this.props.hasFocus}
            predictions={augmentedPredictions}
            focusPrediction={focusPrediction}
            selectPrediction={this.onSelectPrediction}
            setPredictionFocus={setPredictionFocus}
            needsFocus={needsFocus}
          />
        ) : null}
        {editMode && !manualMode ? (
          field.type === 'bounds' ? (
            <div className="field-input-select">
              <div
                className="button-div"
                onClick={this.onSelectManualSelection}
              >
                <span>Select bounds</span>
              </div>
              {field.multiple ? (
                <div className="button-div" onClick={this.props.onSelectRemove}>
                  Remove
                </div>
              ) : null}
              {this.props.annotation ? (
                <div className="button-div cancel" onClick={this.onCancelEdit}>
                  Cancel
                </div>
              ) : null}
            </div>
          ) : (
            <div className="field-input-select">
              <div
                className="button-div"
                onClick={() => this.onSelectManualEntry()}
              >
                Enter manually
              </div>
              <div
                className={
                  candidateSelection.candidates &&
                  candidateSelection.candidates.length > 0
                    ? 'button-div'
                    : 'button-div disabled'
                }
                onClick={
                  candidateSelection.candidates &&
                  candidateSelection.candidates.length > 0
                    ? this.onSelectManualSelection
                    : null
                }
              >
                Select values
              </div>
              {field.multiple ? (
                <div className="button-div" onClick={this.props.onSelectRemove}>
                  Remove
                </div>
              ) : null}
              {this.props.annotation ? (
                <div className="button-div cancel" onClick={this.onCancelEdit}>
                  Cancel
                </div>
              ) : null}
            </div>
          )
        ) : null}
        {manualEntryMode ? (
          <div className="field-input manual-entry">
            <div className="button-div back" onClick={this.onCancelManualMode}>
              <FaChevronLeft /> <span>Back</span>
            </div>
            <div className="label">Enter manually</div>
            <form onSubmit={this.onSubmitManualEntry}>
              <input
                type="text"
                ref={r => setTimeout(() => (r ? r.focus() : null), 0)}
                value={annotation.data.value}
                onChange={this.onChangeManualInputValue}
                onKeyDown={this.handleInputKeyDown}
              />
              <button type="submit">Confirm</button>
            </form>
          </div>
        ) : null}
        {manualSelectionMode ? (
          <div
            ref={r => setTimeout(() => (r ? r.focus() : null), 0)}
            className="field-input manual-select"
            onKeyDown={this.handleSelectionKeyDown}
          >
            <div className="button-div back" onClick={this.onCancelManualMode}>
              <FaChevronLeft /> <span>Back</span>
            </div>
            {field.type === 'bounds' ? (
              <>
                <div className="label">Select bounds</div>
                <div className="bounds">
                  {selectedCandidates.length === 0 ? (
                    <div className="placeholder">
                      Click and drag to select a region on the source document.
                    </div>
                  ) : (
                    <div>
                      {selectedCandidates.map((c, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <span key={i}>
                          ({Math.round(c.bounds.topLeft.x * 100, 1)},{' '}
                          {Math.round(c.bounds.topLeft.y * 100, 1)}) ⮕ (
                          {Math.round(c.bounds.bottomRight.x * 100, 1)},{' '}
                          {Math.round(c.bounds.bottomRight.y * 100, 1)})
                        </span>
                      ))}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="label">Select tokens</div>
                <div className="tokens">
                  {selectedCandidates.map((c, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <span key={i}>{c.token}</span>
                  ))}
                  {selectedCandidates.length === 0 ? (
                    <div className="placeholder">
                      Click to select values from the document. Drag to group
                      multiple values.
                    </div>
                  ) : null}
                </div>
              </>
            )}
            {selectedCandidates.length > 0 ? (
              <form onSubmit={this.onConfirmManualSelection}>
                <div
                  className="button-div reset"
                  onClick={this.onClearManualSelection}
                >
                  <span>Clear selection</span>
                </div>
                <div>
                  {this.isSplitAnnotationEnabled() ? (
                    <button
                      type="submit"
                      className="split-confirm"
                      onClick={() => this.onConfirmSplitSelection()}
                    >
                      Split
                    </button>
                  ) : null}
                  <button type="submit">Confirm</button>
                </div>
              </form>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}
