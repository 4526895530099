import { mergeRight as merge } from 'ramda';

const updateField = (field, fields, setFields) => {
  const key = Object.keys(field)[0];
  const value = field[key];
  setFields(
    merge(fields, {
      [key]: merge(fields[key], value),
    }),
  );
};

export default updateField;
