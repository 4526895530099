import { TRANSMIT_PAGEVIEW, TRANSMIT_EVENT } from './actions';

const initialState = {
  data: null,
};

const eventReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case TRANSMIT_EVENT: {
      return {
        ...state,
        data: { ...payload },
      };
    }

    case TRANSMIT_PAGEVIEW: {
      return {
        ...state,
        data: { ...payload },
      };
    }

    default:
      return state;
  }
};

export default eventReducer;
