import React from 'react';
import PropTypes from 'prop-types';
import { copyToClipboard } from '../../../../../common/helpers';
import './_cells.scss';

const FilenameCell = ({ row, tooltipId }) => {
  const task = row.original || {};

  const handleDocIdRightClick = (e, id) => {
    e.preventDefault();
    copyToClipboard(id);
  };

  return (
    <div className="filename-cell">
      <span
        className="filename-cell__filename"
        data-tooltip-id={tooltipId}
        data-tooltip-content={(task.document || {}).filename}
      >
        {(task.document || {}).filename}
      </span>
      <span
        className="filename-cell__id"
        onContextMenu={e => handleDocIdRightClick(e, task.docId)}
      >
        {task.docId}
      </span>
    </div>
  );
};

FilenameCell.propTypes = {
  row: PropTypes.object.isRequired,
  tooltipId: PropTypes.string,
};

FilenameCell.defaultProps = {
  tooltipId: undefined,
};

export default FilenameCell;
