import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaChevronDown } from 'react-icons/fa';
import classNames from 'classnames';

function DocsFilter({
  className,
  label,
  name,
  value,
  filters,
  forwardRef,
  onChange,
}) {
  const [expanded, setExpanded] = useState(false);

  const handleBlur = () => {
    setExpanded(false);
  };

  const handleChange = e => {
    onChange(e.target, filters);
  };

  const handleEnterPress = e => {
    if (e.key === 'Enter') {
      setExpanded(false);
    }
  };

  const handleFocus = () => {
    setExpanded(true);
  };

  const valueText = value ? `: ${value.split(0, 10).toString()}` : '';

  return (
    <label
      htmlFor={name}
      className={classNames(className, 'filter', {
        'filter--expanded': expanded,
      })}
    >
      <div className="search__filter-text">
        <span>
          {label}
          {valueText}
        </span>
        <FaChevronDown className="search__filter-icon" />
      </div>
      <div className="search__filter-dialog">
        <input
          id={name}
          className="search__filter-input"
          type="text"
          name={name}
          defaultValue={value}
          placeholder={`${label}...`}
          onChange={e => handleChange(e)}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyUp={e => handleEnterPress(e)}
          ref={forwardRef}
        />
      </div>
    </label>
  );
}

DocsFilter.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  filters: PropTypes.object,
  forwardRef: PropTypes.object,
  onChange: PropTypes.func,
};

DocsFilter.defaultProps = {
  className: '',
  label: '',
  value: '',
  defaultValue: '',
  filters: {},
  forwardRef: {},
  onChange: () => {},
};

export default DocsFilter;
