import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useId } from 'react-id-generator';
import '../../../library/atoms/Radio/_radio.scss';

const Radio = ({
  className,
  color,
  id,
  label,
  labelPos,
  name,
  value,
  size,
  variant,
  onChange,
}) => {
  const handleClick = e => {
    e.stopPropagation();
    e.preventDefault();
    onChange(!value);
  };
  const inputId = id || useId()[0];
  const radioVariant = variant ? `-${variant}` : '';
  const radioClass = classNames(
    className,
    'radio',
    color ? `radio--${color}${radioVariant}` : null,
    size ? `radio--${size}` : null,
    labelPos ? `radio--label-${labelPos}` : null,
  );
  return (
    <label className={radioClass} htmlFor={inputId} onClick={handleClick}>
      <input
        className="radio__input"
        id={inputId}
        name={
          name || inputId.replace(/[ -]+/g, '_').replace(/[^0-9a-zA-Z_]+/g, '')
        }
        onChange={onChange}
        checked={value}
        type="radio"
      />
      <span className="radio__border">
        <span className="radio__dot" />
      </span>
      {label ? <span className="radio__label">{label}</span> : null}
    </label>
  );
};

Radio.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  labelPos: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  size: PropTypes.string,
  variant: PropTypes.string,
  onChange: PropTypes.func,
};

Radio.defaultProps = {
  className: '',
  color: '',
  label: '',
  labelPos: 'right',
  name: '',
  size: '',
  variant: '',
  onClick: () => {},
};

export default Radio;
