import React from 'react';
import './_smart-split-icon.scss';

const SmartSplitIcon = () => {
  return (
    <div className="smart-split-icon">
      <svg
        width="88"
        height="88"
        viewBox="0 0 88 88"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="13"
          y="13"
          width="62"
          height="62"
          rx="1"
          fill="white"
          stroke="#A6A6A5"
          strokeWidth="2"
        />
        <rect
          x="23"
          y="23"
          width="42"
          height="42"
          rx="1"
          fill="white"
          stroke="#A6A6A5"
          strokeWidth="2"
        />
        <path
          d="M19 6L19 13"
          stroke="#A6A6A5"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M29 6L29 13"
          stroke="#A6A6A5"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M39 6L39 13"
          stroke="#A6A6A5"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M49 6L49 13"
          stroke="#A6A6A5"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M59 6L59 13"
          stroke="#A6A6A5"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M69 6L69 13"
          stroke="#A6A6A5"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M19 75L19 82"
          stroke="#A6A6A5"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M29 75L29 82"
          stroke="#A6A6A5"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M39 75L39 82"
          stroke="#A6A6A5"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M49 75L49 82"
          stroke="#A6A6A5"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M59 75L59 82"
          stroke="#A6A6A5"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M69 75L69 82"
          stroke="#A6A6A5"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M82 19L75 19"
          stroke="#A6A6A5"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M82 29L75 29"
          stroke="#A6A6A5"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M82 39L75 39"
          stroke="#A6A6A5"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M82 49L75 49"
          stroke="#A6A6A5"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M82 59L75 59"
          stroke="#A6A6A5"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M82 69L75 69"
          stroke="#A6A6A5"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M13 19L6 19"
          stroke="#A6A6A5"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M13 29L6 29"
          stroke="#A6A6A5"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M13 39L6 39"
          stroke="#A6A6A5"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M13 49L6 49"
          stroke="#A6A6A5"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M13 59L6 59"
          stroke="#A6A6A5"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M13 69L6 69"
          stroke="#A6A6A5"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.0713 44.0648C35.5613 47.749 38.6954 51.0507 38.6954 51.0507C37.8087 50.5301 36.9254 49.9458 36.0535 49.2979C34.4422 48.101 33.1565 46.9056 32.3618 46.1137C31.4966 45.2517 31.0076 44.6669 30.9872 44.6426L30.5 44.0648L30.9872 43.487C31.0076 43.4626 31.4966 42.8778 32.3618 42.016C33.1565 41.224 34.4422 40.0287 36.0535 38.8317C36.9254 38.1839 37.8087 37.5995 38.6954 37.0788C38.6954 37.0788 35.5613 40.3806 34.0713 44.0648Z"
          fill="#00B6EC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.9287 44.0648C52.4388 47.749 49.3047 51.0507 49.3047 51.0507C50.1913 50.5301 51.0747 49.9458 51.9466 49.2979C53.558 48.101 54.8436 46.9056 55.6382 46.1137C56.5034 45.2517 56.9925 44.6669 57.0129 44.6426L57.5001 44.0648L57.0129 43.487C56.9925 43.4626 56.5034 42.8778 55.6382 42.016C54.8436 41.224 53.558 40.0287 51.9466 38.8317C51.0747 38.1839 50.1913 37.5995 49.3047 37.0788C49.3047 37.0788 52.4388 40.3806 53.9287 44.0648Z"
          fill="#00B6EC"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.8812 49.5311C46.854 49.5311 49.2643 47.1348 49.2643 44.179C49.2643 41.2232 46.854 38.8269 43.8812 38.8269C40.9083 38.8269 38.498 41.2232 38.498 44.179C38.498 47.1348 40.9083 49.5311 43.8812 49.5311Z"
          fill="#3C3C3B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.3453 40.3561L44.6494 42.6312L45.4354 43.4144L47.7255 41.7478C47.3724 41.1895 46.9014 40.7138 46.3453 40.3561Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default SmartSplitIcon;
