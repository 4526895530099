/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const mapStateToProps = state => {
  return {
    genericData: state.docs.genericData ? state.docs.genericData : null,
    genericEntities:
      state.docs.genericData && state.docs.genericData.entities
        ? state.docs.genericData.entities
        : null,
  };
};

const GenericConnectors = ({
  padding,
  genericData,
  genericEntities,
  pageNum,
}) => {
  const [connectors, setConnectors] = useState([]);

  // Get start and end coordinates for connector pairs.
  const getCoordinates = keyValuePair => {
    const { keyBounds, valueBounds } = keyValuePair;
    const keyX1 = keyBounds.topLeft.x;
    const keyY1 = keyBounds.topLeft.y;
    const keyX2 = keyBounds.bottomRight.x;
    const keyY2 = keyBounds.bottomRight.y;

    const valX1 = valueBounds.topLeft.x;
    const valY1 = valueBounds.topLeft.y;
    const valX2 = valueBounds.bottomRight.x;
    const valY2 = valueBounds.bottomRight.y;

    // If value is within key's vertical range
    if (keyY1 <= valY2 && valY1 <= keyY2) {
      // If value is on the right of key
      if (keyX2 < valX1) {
        // Initialize start and end coordinates with padding.
        const start = {
          x: keyX2 + padding,
          y: (keyY1 + keyY2) / 2, // vertical mid point of key
        };
        const end = {
          x: valX1 - padding,
          y: (keyY1 + keyY2) / 2,
        };

        // If padding has caused key and value to overlap
        // reverse arrow horizontal direction
        if (start.x > end.x) {
          return {
            start: {
              x: end.x,
              y: start.y,
            },
            end: {
              x: start.x,
              y: end.y,
            },
          };
        }

        // If padding does not cause overlap
        return {
          start,
          end,
        };
      }
      // If value is on the left of key
      if (valX2 < keyX1) {
        const start = {
          x: keyX1 - padding,
          y: (keyY1 + keyY2) / 2,
        };
        const end = {
          x: valX2 + padding,
          y: (keyY1 + keyY2) / 2,
        };

        if (start.x < end.x) {
          return {
            start: {
              x: end.x,
              y: start.y,
            },
            end: {
              x: start.x,
              y: end.y,
            },
          };
        }
        return {
          start,
          end,
        };
      }
    }

    // If value is below key
    if (valY1 > keyY2) {
      const start = {
        x: (keyX1 + keyX2) / 2,
        y: keyY2 + padding,
      };
      const end = {
        x: (valX1 + valX2) / 2,
        y: valY1 - padding,
      };

      // If padding causes overlap
      // reverse direciton of arrow
      if (start.y > end.y) {
        return {
          start: {
            x: start.x,
            y: end.y,
          },
          end: {
            x: end.x,
            y: start.y,
          },
        };
      }
      return {
        start,
        end,
      };
    }

    // If value is above key
    if (valY2 < keyY1) {
      const start = {
        x: (keyX1 + keyX2) / 2,
        y: keyY1 - padding,
      };
      const end = {
        x: (valX1 + valX2) / 2,
        y: valY2 + padding,
      };

      // If padding causes overlap
      // reverse direciton of arrow
      if (end.y > start.y) {
        return {
          start: {
            x: start.x,
            y: end.y,
          },
          end: {
            x: end.x,
            y: start.y,
          },
        };
      }
      return {
        start,
        end,
      };
    }

    // Default coordinates
    return {
      start: {
        x: keyX1,
        y: keyY1,
      },
      end: {
        x: valX1,
        y: valY1,
      },
    };
  };

  const isOnPage = value => {
    return value === pageNum;
  };
  // Get field connector pairs
  const getFieldPairs = () => {
    const pairs = [];
    genericData.fields.forEach(field => {
      if (!isOnPage(genericEntities[field.label].bounds.pageNum)) return;
      pairs.push({
        keyBounds: genericEntities[field.label].bounds,
        valueBounds: genericEntities[field.value].bounds,
        isFieldPair: true,
      });
    });
    return pairs;
  };

  const getSibling = (index, row) => {
    for (let i = index + 1; i < row.length; i++) {
      const cell = row[i];
      if (cell) return genericEntities[cell];
    }
    return null;
  };

  // Get table connector pairs
  const getTablePairs = () => {
    const rowPairs = [];
    const headerChildPairs = [];
    (genericData.tables || []).forEach(table => {
      const tableHeaders = table.length > 0 ? Object.values(table[0]) : null;
      if (
        !tableHeaders ||
        !isOnPage(genericEntities[tableHeaders[0]].bounds.pageNum)
      )
        return;
      table.forEach(row => {
        const cells = Object.values(row);
        for (let i = 0; i < cells.length; i++) {
          const headerBounds = genericEntities[tableHeaders[i]].bounds;
          const cellBounds = cells[i] ? genericEntities[cells[i]].bounds : null;
          if (cellBounds) {
            // Add header child connectors
            if (cells[i] !== tableHeaders[i]) {
              headerChildPairs.push({
                keyBounds: headerBounds,
                valueBounds: cellBounds,
                isHeaderChild: true,
              });
            }
            const sibling = getSibling(i, cells);
            // Add sibling connectors
            if (sibling) {
              rowPairs.push({
                keyBounds: cellBounds,
                valueBounds: sibling.bounds,
              });
            }
          }
        }
      });
    });

    return { rowPairs, headerChildPairs };
  };

  const getStyle = coord => {
    return {
      position: 'absolute',
      top: `${(coord.start.y * 100).toPrecision(5)}%`,
      left: `${(coord.start.x * 100).toPrecision(5)}%`,
      right: `${100 - (coord.end.x * 100).toPrecision(5)}%`,
      bottom: `${100 - (coord.end.y * 100).toPrecision(5)}%`,
    };
  };

  useEffect(() => {
    if (!genericData) return;
    const fieldPairs = getFieldPairs();
    const { rowPairs, headerChildPairs } = getTablePairs();
    const allPairs = [...fieldPairs, ...rowPairs, ...headerChildPairs];

    // add coordinates and positions for connectors into array
    const coordinates = [];
    allPairs.forEach(pair => {
      const coord = getCoordinates(pair);
      if (pair.isFieldPair) coord.isFieldPair = true;
      if (pair.isHeaderChild) coord.isHeaderChild = true;
      coord.style = getStyle(coord);
      coordinates.push(coord);
    });
    setConnectors(coordinates);
  }, [genericData]);

  return (
    <>
      {genericData ? (
        <svg width="100%" height="100%">
          <defs>
            <marker
              id="markerArrow"
              viewBox="0 0 20 20"
              refX="8"
              refY="6.5"
              markerWidth="10"
              markerHeight="10"
              orient="auto"
            >
              <path d="M2,2 L2,11 L10,6 L2,2" style={{ fill: '#b666d2' }} />
            </marker>
          </defs>
          {connectors.map((line, index) => (
            <Fragment key={index}>
              <line
                x1={`${(line.start.x * 100).toPrecision(5)}%`}
                y1={`${(line.start.y * 100).toPrecision(5)}%`}
                x2={`${(line.end.x * 100).toPrecision(5)}%`}
                y2={`${(line.end.y * 100).toPrecision(5)}%`}
                style={{
                  stroke: line.isFieldPair ? '#b666d2' : '#f28b00',
                  strokeWidth: 2,
                  opacity: line.isHeaderChild ? '0.5' : '0.9',
                  markerEnd: line.isFieldPair ? 'url(#markerArrow)' : 'none',
                }}
              />
            </Fragment>
          ))}
        </svg>
      ) : null}
    </>
  );
};

GenericConnectors.propTypes = {
  genericData: PropTypes.object,
  genericEntities: PropTypes.object,
  padding: PropTypes.number.isRequired,
  pageNum: PropTypes.number.isRequired,
};

GenericConnectors.defaultProps = {
  genericData: null,
  genericEntities: null,
};

export default connect(mapStateToProps)(GenericConnectors);
