import { SET_TRACKER } from './actions';

const initialState = {
  clientId: null,
};

const trackingReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_TRACKER: {
      const { clientId } = payload;
      return {
        ...state,
        clientId,
      };
    }

    default:
      return state;
  }
};

export default trackingReducer;
