import React, { Fragment } from 'react';
import { FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './Page.scss';

const PageNav = ({ children, className, items }) => {
  const pageClass = classNames(className, `page__nav`);

  return (
    <nav className={pageClass}>
      {children}
      {items && (
        <ul className="page__nav-list">
          {(items || []).map((item, i) => (
            <li className="page__nav-item" key={`page__nav-${item.to}-${i}`}>
              {i < items.length - 1 ? (
                <>
                  <Link className="page__nav-link" to={item.to}>
                    {item.label}
                  </Link>
                  <i className="page__nav-item-divider">
                    <FaChevronRight />
                  </i>
                </>
              ) : (
                <span className="page__nav-current">{item.label}</span>
              )}
            </li>
          ))}
        </ul>
      )}
    </nav>
  );
};

PageNav.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      to: PropTypes.string,
    }),
  ),
};

PageNav.defaultProps = {
  children: null,
  className: null,
  items: null,
};

export default PageNav;
