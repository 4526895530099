import React, { useEffect, useState } from 'react';
import {
  FaEdit,
  FaCheckCircle,
  FaExclamationCircle,
  FaRegComments,
  FaInfinity,
} from 'react-icons/fa';
import { connect } from 'react-redux';
import ReactLoading from 'react-loading';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Markup } from 'interweave';
import MarkdownIt from 'markdown-it';
import moment from 'moment';
import { isEmpty } from 'ramda';
import PropTypes from 'prop-types';
import { pushPageView, pushEvent } from '../../../../reducers/events/actions';
import { fetchProduct } from '../../../../reducers/product/actions';
import {
  subscribeTrial,
  fetchSubscriptionById,
} from '../../../../reducers/subscriptions/actions';

import { TabsContainer, Tabs, Tab, TabPanel } from '../../../UI/Tabs';
import { Button } from '../../../../library/atoms/Buttons';
import NotFound from './NotFound';
import Page, {
  PageBody,
  PageCol,
  PageHeader,
  PageInner,
  PageNav,
} from '../../../Page';

import FreeTrialModal from '../FreeTrialModal/FreeTrialModal';
// eslint-disable-next-line import/no-cycle
import CheckoutModal from '../CheckoutModal/CheckoutModal';
import LegacyPricing from './LegacyPricing';
import ProductSummary from './ProductSummary';

import * as Events from '../../tracking/productTracking';
import { trackEvent } from '../../../../reducers/tracking/actions';
import '../../Marketplace.scss';
import './Product.scss';

import MeteredPricing from '../../../../library/organisms/Product/MeteredPricing';

export const setPricing = pricing => {
  let accessFee;
  let meteredPricing;

  if (pricing && Array.isArray(pricing)) {
    const accessFeeObj = (pricing || []).find(p => p.type === 'access_fee');
    if (accessFeeObj) {
      accessFee = Number(accessFeeObj.accessFee);
    }
    meteredPricing = (pricing || []).find(p => p.type === 'metered');
  }

  return [accessFee, meteredPricing];
};

const mdParser = new MarkdownIt();

const mapStateToProps = (state, props) => {
  const { product, subscriptions, auth, router } = state;
  const { match } = props;

  return {
    company: auth.user && auth.user.data ? auth.user.data.company : null,
    location: router.location,
    product: product.data || {},
    productId: match.params.id || null,
    activeSubscription: subscriptions.active,
    subscriptions: subscriptions.data || null,
    user: auth.user ? auth.user.data : null,
    isLoading: !!(product.loading && subscriptions.loading),
    isRefresh: product.refresh || false,
    isSending: subscriptions.sending || false,
    canSuperUserWrite: auth.permissions.canSuperUserWrite,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      fetchProduct: (productId, isPublic) =>
        dispatch(fetchProduct(productId, isPublic)),
      subscribeTrial: productId => dispatch(subscribeTrial(productId)),
      fetchSubscriptionById: productId =>
        dispatch(fetchSubscriptionById(productId)),
      pushPageView: pageViewData => dispatch(pushPageView(pageViewData)),
      pushEvent: eventData => dispatch(pushEvent(eventData)),
      trackEvent: ({ name, params }) => dispatch(trackEvent({ name, params })),
    },
  };
};

const Product = ({
  productId,
  product,
  activeSubscription,
  user,
  isLoading,
  isRefresh,
  isSending,
  dispatch,
  isPublic,
  canSuperUserWrite,
}) => {
  const [freeTrialStep, setFreeTrialStep] = useState(1);
  const [isFreeTrialModalOpen, setFreeTrialModalOpen] = useState(false);
  const [isCheckoutModalOpen, setCheckoutModalOpen] = useState(false);
  const [callToAction, setCallToAction] = useState(null);
  const [accessFee, meteredPricing] = setPricing(product.pricing);
  const isUserVerified = !(user && user.auth0 && !user.auth0.email_verified);
  const hasPricing = product.pricing && product.stripeMeta;

  useEffect(() => {
    dispatch.fetchProduct(productId, isPublic);
  }, [dispatch, isPublic, productId]);

  useEffect(() => {
    if (isRefresh) {
      dispatch.fetchProduct(productId);
      if (!isPublic) {
        dispatch.fetchSubscriptionById(productId);
      }
    }
  }, [dispatch, isPublic, isRefresh, productId]);

  useEffect(() => {
    if (user) {
      dispatch.fetchSubscriptionById(productId);
    }
  }, [dispatch, productId, user]);

  useEffect(() => {
    if (product && product.productId) {
      dispatch.trackEvent(Events.pageView());
      dispatch.trackEvent(Events.viewProduct(product));
    }
  }, [dispatch, product]);

  const imageStyle = {
    background: product && product.imageUrl ? 'none' : null,
  };

  const talkSalesHandler = () => {
    dispatch.trackEvent(Events.talkToSalesClick(product));
  };

  const subscribeTrialHandler = () => {
    dispatch.trackEvent(Events.confirmActivateFreeTrial(product));
    dispatch.subscribeTrial(productId).then(() => {
      setFreeTrialStep(2);
    });
  };

  const handleUpgradeTrial = () => {
    dispatch.trackEvent(Events.confirmCheckoutAiProduct(product));
  };

  const openFreeTrialModal = () => {
    setFreeTrialModalOpen(true);
    dispatch.trackEvent(Events.activateFreeTrial(product));
  };

  const cancelFreeTrialModal = () => {
    setFreeTrialModalOpen(false);
    dispatch.trackEvent(Events.cancelActivateFreeTrial(product));
  };

  const closeFreeTrialModal = () => {
    setFreeTrialModalOpen(false);
  };

  const openCheckoutModal = () => {
    setCheckoutModalOpen(true);
    dispatch.trackEvent(Events.addAiProductToCart(product)).then(() => {
      dispatch.trackEvent(Events.beginCheckoutAiProduct(product));
    });
  };

  const closeCheckoutModal = () => {
    setCheckoutModalOpen(false);
    dispatch.trackEvent(Events.cancelCheckoutAiProduct(product));
  };

  const trialButton = () => {
    if (activeSubscription && !activeSubscription.isTrial) {
      return null;
    }
    if (activeSubscription) {
      const { endDate } = activeSubscription;
      // Trial infinite
      if (!endDate) {
        return (
          <span className="subs-text subs-text--green">
            <FaInfinity />
            Unlimited Free Trial
          </span>
        );
      }
      // Trial expired
      if (moment(endDate) < moment()) {
        return (
          <span className="subs-text subs-text--red">
            <FaExclamationCircle />
            Trial subscription expired
          </span>
        );
      }

      // Trial active
      const numberOfDays = Math.ceil(
        moment(endDate).diff(moment(), 'seconds') / 86400,
      );

      // 3 - 14 days
      if (numberOfDays >= 3) {
        return (
          <span className="subs-text subs-text--green">
            <FaCheckCircle />
            Free Trial Activated. {numberOfDays} days remaining
          </span>
        );
      }

      // less 3 days left
      return (
        <span className="subs-text subs-text--yellow">
          <FaExclamationCircle />
          Free Trial Activated. {numberOfDays} days remaining
        </span>
      );
    }
    return (
      <Button onClick={openFreeTrialModal} variant="outline">
        Activate Free Trial
      </Button>
    );
  };

  const checkoutButton = () => {
    const isProductUpdated =
      product.stripeMeta &&
      Array.isArray(product.stripeMeta) &&
      product.stripeMeta.length > 0;

    if (activeSubscription) {
      if (activeSubscription.isTrial) {
        const { endDate } = activeSubscription;
        const numberOfDays = Math.ceil(
          moment(endDate).diff(moment(), 'seconds') / 86400,
        );

        // show talk to sales link if < 3 days
        if (numberOfDays < 3) {
          return (
            <>
              <span
                data-tooltip-id="verify-tooltip"
                data-tooltip-place="bottom"
              >
                <Button
                  onClick={openCheckoutModal}
                  disabled={!isUserVerified || !isProductUpdated}
                >
                  Unlock Full Subscription
                </Button>
              </span>
              <a
                className="subs-call"
                href="https://www.sypht.com/contact"
                target="_blank"
                onClick={talkSalesHandler}
                rel="noreferrer"
              >
                <FaRegComments />
                Talk to sales
              </a>
            </>
          );
        }

        return (
          <span data-tooltip-id="verify-tooltip" data-tooltip-place="bottom">
            <Button
              onClick={openCheckoutModal}
              disabled={!isUserVerified || !isProductUpdated}
            >
              Unlock Full Subscription
            </Button>
          </span>
        );
      }

      return (
        <span className="subs-text subs-text--green">
          <FaCheckCircle />
          Full Subscription Unlocked
        </span>
      );
    }

    return (
      <span data-tooltip-id="verify-tooltip" data-tooltip-place="bottom">
        <Button
          onClick={openCheckoutModal}
          disabled={!isUserVerified || !isProductUpdated}
        >
          Unlock Full Subscription
        </Button>
      </span>
    );
  };

  const publicCTA = () => {
    const href = user
      ? `/marketplace/products/${product.productId}`
      : '/register';
    return (
      <a className="btn btn--blue" href={href} target="_blank" rel="noreferrer">
        Sign up to activate
      </a>
    );
  };

  useEffect(() => {
    if (!isPublic) {
      setCallToAction(
        <>
          {trialButton()}
          {checkoutButton()}
        </>,
      );
    } else {
      setCallToAction(<>{publicCTA()}</>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSubscription, product, isPublic]);

  return (
    <Page
      className={classNames('marketplace', 'product-page', {
        public: isPublic,
      })}
      title={product.name ? `${product.name} - Marketplace` : 'Marketplace'}
    >
      {isLoading && (
        <div className="loading-wrapper">
          <ReactLoading type="spin" color="#dddddd" height={128} width={128} />
        </div>
      )}
      {!isLoading && product && (
        <>
          {product.error ? (
            <NotFound message={product.message || 'Product not found'} />
          ) : (
            <>
              <PageInner>
                <PageNav
                  items={
                    isPublic
                      ? [
                          { label: 'Marketplace', to: '/marketplace/catalog' },
                          { label: 'Products', to: '/marketplace/catalog' },
                          {
                            label: product.name,
                            to: `/marketplace/catalog/${product.productId}`,
                          },
                        ]
                      : [
                          { label: 'Marketplace', to: '/marketplace' },
                          { label: 'Products', to: '/marketplace/products' },
                          {
                            label: product.name,
                            to: `/marketplace/products/${product.productId}`,
                          },
                        ]
                  }
                />
                <PageHeader>
                  <PageCol className="page__col--image">
                    <figure className="page__header-image" style={imageStyle}>
                      {product.imageUrl && (
                        <img src={product.imageUrl} alt={product.name} />
                      )}
                    </figure>
                  </PageCol>
                  <PageCol className="page__col--heading">
                    <h1 className="page__heading">
                      {product.name}
                      {canSuperUserWrite && !isPublic && (
                        <Link
                          className="external-link"
                          to={`/marketplace/admin/products/${product.productId}/edit`}
                        >
                          <FaEdit />
                        </Link>
                      )}
                    </h1>
                    {product.companyName && (
                      <div className="company-name">
                        <span className="company-name__label">By:</span>
                        {product.companyName}
                      </div>
                    )}
                  </PageCol>
                  <PageCol className="page__col--cta">{callToAction}</PageCol>
                </PageHeader>
                <PageBody>
                  <TabsContainer defaultTab="overview">
                    <Tabs defaultTab="overview">
                      <Tab
                        tabFor="overview"
                        tracking={{ item_id: product.productId }}
                      >
                        Overview
                      </Tab>
                      {hasPricing && (
                        <Tab
                          tabFor="pricing"
                          tracking={{ item_id: product.productId }}
                        >
                          Pricing
                        </Tab>
                      )}
                      <Tab
                        tabFor="documentation"
                        tracking={{ item_id: product.productId }}
                      >
                        Documentation
                      </Tab>
                    </Tabs>
                    <TabPanel id="overview" className="product-body">
                      <div className="product-body__tab-wrapper">
                        <div className="product-body__description">
                          {product.content && (
                            <Markup
                              containerTagName="article"
                              content={mdParser.render(product.content)}
                            />
                          )}
                        </div>
                      </div>
                    </TabPanel>
                    {hasPricing && (
                      <TabPanel id="pricing" className="product-body">
                        <div className="product-body__tab-wrapper product-body__tab-wrapper--2-col">
                          {product.pricing && product.pricing.tiers && (
                            <LegacyPricing product={product} />
                          )}
                          {meteredPricing && (
                            <MeteredPricing
                              pricing={meteredPricing}
                              accessFee={accessFee}
                              productType={product.productType}
                            />
                          )}
                          {(!product.pricing || isEmpty(product.pricing)) && (
                            <div className="product-body__description">
                              <article>
                                <p>
                                  Please{' '}
                                  <a
                                    href="https://www.sypht.com/contact"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    contact us
                                  </a>{' '}
                                  to discuss pricing options.
                                </p>
                              </article>
                            </div>
                          )}
                        </div>
                      </TabPanel>
                    )}
                    <TabPanel id="documentation" className="product-body">
                      <div className="product-body__tab-wrapper product-body__tab-wrapper--2-col">
                        <div className="product-body__description">
                          {product.documentation && (
                            <Markup
                              containerTagName="article"
                              content={mdParser.render(product.documentation)}
                            />
                          )}
                        </div>
                        {product.productId && (
                          <div className="product-body__sidenote">
                            <div className="disclaimer">
                              <p className="disclaimer__text">
                                To learn more about how you can integrate with{' '}
                                <strong>{product.name} AI</strong> using our
                                Sypht API, please see our{' '}
                                <a
                                  href="https://docs.sypht.com"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Developer Documentation
                                </a>{' '}
                                and get started with our{' '}
                                <a
                                  href="https://github.com/sypht-team"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Sypht Clients
                                </a>{' '}
                                by using
                                <code>{product.productId}</code>
                                as the product ID for the API call
                              </p>
                            </div>
                          </div>
                        )}
                        <ProductSummary product={product} />
                      </div>
                    </TabPanel>
                  </TabsContainer>
                </PageBody>
              </PageInner>{' '}
              {!isPublic && (
                <>
                  <FreeTrialModal
                    selectedProduct={product}
                    step={freeTrialStep}
                    isLoading={isSending}
                    isOpen={isFreeTrialModalOpen}
                    onCancel={cancelFreeTrialModal}
                    onClose={closeFreeTrialModal}
                    subscribeTrial={subscribeTrialHandler}
                  />
                  <CheckoutModal
                    product={product}
                    subscription={activeSubscription}
                    isOpen={isCheckoutModalOpen}
                    onCancel={closeCheckoutModal}
                    onClose={closeCheckoutModal}
                    onUpgradeTrial={handleUpgradeTrial}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </Page>
  );
};

Product.propTypes = {
  productId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  product: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
  isLoading: PropTypes.bool,
  isSending: PropTypes.bool,
  isRefresh: PropTypes.bool,
  dispatch: PropTypes.shape({
    fetchProduct: PropTypes.func.isRequired,
    subscribeTrial: PropTypes.func.isRequired,
    pushPageView: PropTypes.func.isRequired,
    pushEvent: PropTypes.func.isRequired,
    trackEvent: PropTypes.func.isRequired,
    fetchSubscriptionById: PropTypes.func.isRequired,
  }),
  isPublic: PropTypes.bool.isRequired,
  activeSubscription: PropTypes.shape({
    endDate: PropTypes.string,
    isTrial: PropTypes.bool,
  }).isRequired,
  canSuperUserWrite: PropTypes.bool.isRequired,
};

Product.defaultProps = {
  product: {},
  user: null,
  isLoading: false,
  isSending: false,
  isRefresh: false,
  dispatch: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);
