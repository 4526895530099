import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Tour from 'reactour';
import { push } from 'connected-react-router';

import {
  FaCompressAlt,
  FaCheckCircle,
  FaRocket,
  FaAngleRight,
} from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import Accordion from '../../library/organisms/Accordion';

import {
  minimisePlatformTourPanel,
  dismissPlatformTourPanel,
  openPlatformTourPanel,
  captureTourProgress,
} from '../../reducers/authReducer';
import Button from '../UI/Button/Button';
import TourConfig from './TourConfig/TourConfig';

import './PlatformTourPanel.scss';
import useUrlChanges from './hooks/useUrlChanges';
import useElementHighlight from './ElementHighlight/useElementHighlight';

const getProgressPercentage = (tours, isTourCompleted) => {
  const completed = tours.filter(tour => isTourCompleted(tour));
  return (completed.length / tours.length) * 100;
};

const defaultPlatformTour = TourConfig[0];

const PlatformTourPanel = ({
  navigate,
  profile,
  handleDismissTourProgressPanel,
  handleMinimiseTourProgressPanel,
  handleOpenTourProgressPanel,
  handleCaptureTourProgress,
}) => {
  const getVisibleState = state =>
    profile.tourState &&
    profile.tourState.includes(state) &&
    window.location.pathname === defaultPlatformTour(navigate).url;
  const panelRef = useRef(null);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [expandedTour, setExpandedTour] = useState(null);
  const [isGetStartedButtonVisible, setIsGetStartedButtonVisible] = useState(
    getVisibleState('minimised'),
  );

  const isFirstUse = () => {
    return profile.tourState && profile.tourState === 'openOnboard';
  };

  const [isPanelVisible, setIsPanelVisible] = useState(getVisibleState('open'));

  const triggerDelayedResize = () => {
    // trigger resize event after open animation has completed
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 150);
  };

  const handleCloseTour = () => {
    setIsTourOpen(false);
    setIsGetStartedButtonVisible(getVisibleState('minimised'));
    setIsPanelVisible(getVisibleState('open'));
    triggerDelayedResize();
  };

  useUrlChanges(handleCloseTour);

  const [tours, setTours] = useState(defaultPlatformTour(navigate).tours);
  const [activeTourIndex, setActiveTourIndex] = useState(-1);
  const [activeToursStep, setActiveToursStep] = useState(-1);

  const handleOnTourClicked = e => {
    setTours(e.tours);
    setIsTourOpen(false);
    triggerDelayedResize();
    if (e.activeStep.expanded) {
      setActiveTourIndex(e.activeStep.index - 1);
    } else {
      setActiveTourIndex(-1);
    }
  };

  useEffect(() => {
    const isOpen = activeTourIndex > -1;
    setIsTourOpen(isOpen);
    triggerDelayedResize();

    if (isOpen) {
      setActiveToursStep(0);
    }
  }, [activeTourIndex]);

  const collapseAllTours = () => {
    tours.forEach(tour => {
      // set all accordions to be closed.
      // eslint-disable-next-line no-param-reassign
      tour.expanded = false;
    });
  };

  const handleDismissClicked = () => {
    handleDismissTourProgressPanel();
    collapseAllTours();
    handleOnTourClicked({
      activeStep: { ...expandedTour, expanded: false },
      tours,
    });
    setExpandedTour(null);
  };

  const handleMinimisedClicked = () => {
    handleMinimiseTourProgressPanel();
    collapseAllTours();
    handleOnTourClicked({
      activeStep: { ...expandedTour, expanded: false },
      tours,
    });
    setExpandedTour(null);
  };

  const handleGetStartedClicked = () => {
    handleOpenTourProgressPanel();
  };

  const handleAccordionClicked = e => {
    collapseAllTours();
    const tour = tours.find(s => s.id === e.name);
    tour.expanded = e.expanded;
    tour.index = tours.indexOf(tour) + 1;
    setExpandedTour(e.expanded ? tour : null);

    handleOnTourClicked({ activeStep: tour, tours });
  };

  const shouldSkipToNextTour = step => {
    const stepToCheck =
      tours[activeTourIndex].tourSteps.length === 1 ? 0 : step;
    const hasSkip =
      tours[activeTourIndex].tourSteps[stepToCheck].shouldShowSkipButton;

    return hasSkip ?? false;
  };

  const handleSkipClicked = step => {
    if (shouldSkipToNextTour(step)) {
      collapseAllTours();
      if (activeTourIndex + 1 < tours.length) {
        const tour = tours[activeTourIndex + 1];
        handleAccordionClicked({ name: tour.id, expanded: true });
      }
    }
  };

  useEffect(() => {
    setIsGetStartedButtonVisible(getVisibleState('minimised'));
    setIsPanelVisible(getVisibleState('open'));
    if (profile.tourState?.includes('open') && panelRef.current) {
      panelRef.current.scrollIntoView({ behavior: 'smooth' });

      if (isFirstUse() && activeTourIndex === -1) {
        setActiveTourIndex(0);
        setActiveToursStep(0);

        tours[0].expanded = true;
      }
      triggerDelayedResize();
    }
  });

  const isTourCompleted = tour =>
    profile.onboardProgress && profile.onboardProgress[tour.id];

  const toursContent = tours.map(tour => (
    <Accordion
      key={tour.id}
      isOpen={tours.find(s => s.id === tour.id).expanded || false}
      instantlyOpen
      variant="padded-with-line"
    >
      <Accordion.Header>
        <div
          className="accordion__header-inner"
          onClick={() =>
            handleAccordionClicked({ name: tour.id, expanded: !tour.expanded })
          }
        >
          {!isTourCompleted(tour) && (
            <span>{`${tours.indexOf(tour) + 1}. `}</span>
          )}
          {isTourCompleted(tour) && <FaCheckCircle className="completed" />}
          <div>{tour.name}</div>
          <FaAngleRight className="right-icon" />
        </div>
      </Accordion.Header>
      <Accordion.Body>{tour.description(handleCloseTour)}</Accordion.Body>
    </Accordion>
  ));

  useElementHighlight(
    activeTourIndex,
    activeToursStep,
    tours,
    handleCloseTour,
    isTourOpen,
    handleCaptureTourProgress,
  );

  return (
    <>
      <div
        className={`tour-panel ${isPanelVisible ? '' : 'hidden'}`}
        ref={panelRef}
      >
        <div className="tour-panel--inner">
          <div className="heading">
            <div className="heading-text">
              <div>{defaultPlatformTour(navigate).name}</div>
              <FaCompressAlt onClick={handleMinimisedClicked} />
            </div>
            <div className="progress-bar">
              <div
                style={{
                  width: `${getProgressPercentage(tours, isTourCompleted)}%`,
                }}
              />
            </div>
          </div>

          {toursContent}

          <div className="footer">
            <div className="footer-text" onClick={handleDismissClicked}>
              <MdClose strokeWidth={3} />
              <div>Dismiss platform tour</div>
            </div>
          </div>
        </div>
        <div
          className={`get-started-button-panel ${
            isGetStartedButtonVisible ? '' : 'hidden'
          }`}
        >
          <Button onClick={handleGetStartedClicked}>
            <FaRocket /> Get Started
          </Button>
        </div>
      </div>
      <Tour
        steps={(tours[activeTourIndex] || {}).tourSteps || []}
        isOpen={isTourOpen}
        closeWithMask={false}
        startAt={0}
        showCloseButton={false}
        className="platform-tour-highlight"
        highlightedMaskClassName="tour-highlight-mask"
        maskClassName="tour-mask"
        showNumber={false}
        showButtons={false}
        showNavigation={false}
        onRequestClose={handleCloseTour}
        getCurrentStep={handleSkipClicked}
        disableKeyboardNavigation
      />
    </>
  );
};

PlatformTourPanel.defaultProps = {
  navigate: () => {},
  handleDismissTourProgressPanel: () => {},
  handleMinimiseTourProgressPanel: () => {},
  handleOpenTourProgressPanel: () => {},
  handleCaptureTourProgress: () => {},
  profile: {},
};

PlatformTourPanel.propTypes = {
  navigate: PropTypes.func,
  handleDismissTourProgressPanel: PropTypes.func,
  handleMinimiseTourProgressPanel: PropTypes.func,
  handleOpenTourProgressPanel: PropTypes.func,
  handleCaptureTourProgress: PropTypes.func,
  profile: PropTypes.shape(),
};

const mapDispatchToProps = dispatch => ({
  handleDismissTourProgressPanel: () => dispatch(dismissPlatformTourPanel()),
  handleMinimiseTourProgressPanel: () => dispatch(minimisePlatformTourPanel()),
  handleOpenTourProgressPanel: () => dispatch(openPlatformTourPanel()),
  handleCaptureTourProgress: tourId => dispatch(captureTourProgress(tourId)),
  navigate: path => dispatch(push(path)),
});

const mapStateToProps = state => ({
  profile: state.auth.user.data?.profileData || {},
});

export default connect(mapStateToProps, mapDispatchToProps)(PlatformTourPanel);
