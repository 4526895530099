import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './Card.scss';

const CardContent = ({ children, className }) => {
  const cardContentClass = classNames(className, 'card__content');
  return <div className={cardContentClass}>{children}</div>;
};

CardContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

CardContent.defaultProps = {
  children: null,
  className: null,
};

export default CardContent;
