import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import Notification from '../components/UI/Notification/Notification';
import { removeNotification } from '../reducers/notifications';

const mapStateToProps = state => {
  const {
    notifications: { payload },
    auth: { isAuthenticated },
  } = state;
  return {
    isAuthenticated,
    notification: payload,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      removeNotification,
    },
    dispatch,
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Notification),
);
