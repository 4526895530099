const FIELD_TYPES = {
  MULTI_CLASS: 'multiclass',
  MULTI_CLASS_V2: 'multiclass:2',
  MULTI_LABEL: 'multilabel',
  ENTITY: 'entity',
  ENTITY_V2: 'entity:2',
  DERIVED_ENTITY: 'derivedentity',
  TABLE: 'table',
  TABLE_V2: 'table:2',
};

const isOptionalFieldAnnotation = field =>
  field.type === FIELD_TYPES.MULTI_CLASS ||
  field.type === FIELD_TYPES.MULTI_CLASS_V2 ||
  field.type === FIELD_TYPES.MULTI_LABEL;
const isEntityFieldAnnotation = field =>
  field.type === FIELD_TYPES.ENTITY ||
  field.type === FIELD_TYPES.ENTITY_V2 ||
  field.type === FIELD_TYPES.DERIVED_ENTITY;
const isTableAnnotation = field =>
  field.type === FIELD_TYPES.TABLE || field.type === FIELD_TYPES.TABLE_V2;

const isContentFieldAnnotation = field =>
  !isOptionalFieldAnnotation(field) &&
  !isEntityFieldAnnotation(field) &&
  !isTableAnnotation(field);

export {
  FIELD_TYPES,
  isOptionalFieldAnnotation,
  isEntityFieldAnnotation,
  isTableAnnotation,
  isContentFieldAnnotation,
};
