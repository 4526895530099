import {
  REQUEST_FIELDSETS,
  RECEIVE_FIELDSETS,
  REFRESH_FIELDSETS,
} from './actions';

const initialState = {
  data: null,
  refresh: false,
  loading: false,
  lastUpdated: null,
};

const fieldsetsReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case REQUEST_FIELDSETS: {
      return {
        ...state,
        loading: true,
      };
    }

    case RECEIVE_FIELDSETS: {
      const { data, receivedAt } = payload;

      return {
        ...state,
        data: Object.values(data).map(o => o),
        loading: false,
        refresh: false,
        lastUpdated: receivedAt,
      };
    }

    case REFRESH_FIELDSETS: {
      return {
        ...state,
        refresh: true,
      };
    }

    default:
      return state;
  }
};

export default fieldsetsReducer;
