import React, { useContext, useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { mergeRight as merge } from 'ramda';
import { ToggleContext } from './ToggleGroupContext';
import './ToggleGroup.scss';

const ToggleButton = ({ children, buttonFor, onClick }) => {
  const [buttons, setButtons] = useContext(ToggleContext);
  const buttonRef = useRef(null);
  const [isActive, setActive] = useState(false);

  useEffect(() => {
    if (buttons.active === buttonFor) {
      setActive(true);
      setButtons(
        merge(buttons, {
          activeElem: buttonRef.current,
        }),
      );
    } else {
      setActive(false);
    }
  }, [buttons.active]);

  const handleClick = e => {
    e.preventDefault();
    setButtons(
      merge(buttons, {
        active: buttonFor,
        activeElem: buttonRef.current,
      }),
    );
    onClick();
  };

  return (
    <button
      className={classNames('ToggleButton ToggleGroup-groupedHorizontal', {
        active: isActive,
      })}
      ref={buttonRef}
      buttonfor={buttonFor}
      onClick={e => handleClick(e)}
    >
      {children}
    </button>
  );
};

export default ToggleButton;
