import { addError, removeError } from '../../components/Form/validators';

export const isNumber = val => {
  return !Number.isNaN(val) && !Number.isNaN(parseFloat(val));
};

export const isNumberKey = e => {
  const charCode = e.which ? e.which : e.keyCode;
  return !(charCode > 31 && (charCode < 48 || charCode > 57));
};

export const isValidAbn = abnStr => {
  const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
  const abn = abnStr.replace(/[^\d]/, '');
  if (abn.length !== 11) return false;

  let sum = 0;
  for (let i = 0; i < weights.length; i++) {
    sum += (Number(abn[i]) - (i === 0 ? 1 : 0)) * weights[i];
  }
  return sum % 89 === 0;
};

export const isValidAuPostcode = postcode => {
  return Number(postcode) && postcode.length === 4;
};

export const isValidEmail = email => {
  if (!email) return false;
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
};

export const isNullOrEmpty = value => {
  return value === undefined || value === null || value.length <= 0;
};

export const hasValue = value => {
  return !value || value === 'null';
};

export const triggerValidation = elem => {
  if (!elem) return;
  setTimeout(() => {
    elem.focus();
    elem.blur();
  }, 0);
};
