const sortProducts = (a, b) => {
  const aVal = a.label.toLowerCase();
  const bVal = b.label.toLowerCase();

  // sort legacy
  if (a.tags.includes('legacy')) return 1;
  if (b.tags.includes('legacy')) return -1;

  // sort alphabetically
  if (aVal > bVal) return 1;
  if (aVal < bVal) return -1;

  return 0;
};

export default sortProducts;
