import React from 'react';
import PropTypes from 'prop-types';
import { BarLoader } from 'react-spinners';
import './DocumentListLoader.scss';

const DocumentListLoader = ({ label }) => {
  const loaderColour = '#00b6ec'; // copied from colours.scss because import is broken.
  return (
    <div className="loader-container">
      <div>{label}</div>
      <BarLoader color={loaderColour} />
    </div>
  );
};

DocumentListLoader.propTypes = {
  label: PropTypes.string,
};

DocumentListLoader.defaultProps = {
  label: '',
};

export default DocumentListLoader;
