import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const initialState = {
  active: null,
  activeElem: null,
};

const ToggleContext = createContext([{}, () => {}]);

const ToggleContainer = ({ children }) => {
  const [state, setState] = useState(initialState);

  return (
    <ToggleContext.Provider value={[state, setState]}>
      {children}
    </ToggleContext.Provider>
  );
};

ToggleContainer.propTypes = {
  children: PropTypes.node,
};

ToggleContainer.defaultProps = {
  children: null,
};

export default ToggleContainer;
export { ToggleContext };
