import CompanyService from '../../services/CompanyService';
import SubscriptionService from '../../services/SubscriptionService';
import { addNotification } from '../notifications';
import { errorMessage, updatedMessage } from './messages';
import history from '../../services/historyService';
import { fetchUsers } from '../users/actions';
import { fetchCredentials } from '../credentials/actions';

export const RECEIVE_COMPANIES = 'sypht/admin/RECEIVE_COMPANIES';
export const REQUEST_COMPANIES = 'sypht/admin/REQUEST_COMPANIES';
export const RECEIVE_COMPANY = 'sypht/admin/RECEIVE_COMPANY';
export const REQUEST_COMPANY = 'sypht/admin/REQUEST_COMPANY';
export const REQUEST_SUBSCRIPTIONS = 'sypht/admin/REQUEST_SUBSCRIPTIONS';
export const RECEIVE_SUBSCRIPTIONS = 'sypht/admin/RECEIVE_SUBSCRIPTIONS';
export const REQUEST_ADD_SUBSCRIPTION = 'sypht/admin/REQUEST_ADD_SUBSCRIPTION';
export const RECEIVE_ADD_SUBSCRIPTION = 'sypht/admin/RECEIVE_ADD_SUBSCRIPTION';
export const REQUEST_UPDATE_SUBSCRIPTION =
  'sypht/admin/REQUEST_UPDATE_SUBSCRIPTION';
export const RECEIVE_UPDATE_SUBSCRIPTION =
  'sypht/admin/RECEIVE_UPDATE_SUBSCRIPTION';
export const RECEIVE_BILLING_PERIODS = 'sypht/admin/RECEIVE_BILLING_PERIODS';

const requestUpdateSubscription = () => {
  return {
    type: REQUEST_UPDATE_SUBSCRIPTION,
    payload: {
      loading: true,
      sending: true,
      sent: false,
    },
  };
};

const receiveUpdateSubscription = (data, sent = true) => {
  return {
    type: RECEIVE_UPDATE_SUBSCRIPTION,
    payload: {
      data,
      loading: false,
      sending: false,
      sent,
      receivedAt: Date.now(),
    },
  };
};

const requestSubscriptions = () => {
  return {
    type: REQUEST_SUBSCRIPTIONS,
    payload: {
      loading: true,
      sending: true,
      sent: false,
    },
  };
};

const receiveSubscriptions = (data, sent = true) => {
  return {
    type: RECEIVE_SUBSCRIPTIONS,
    payload: {
      data,
      loading: false,
      sending: false,
      sent,
      receivedAt: Date.now(),
    },
  };
};

const receiveBillingPeriods = data => {
  return {
    type: RECEIVE_BILLING_PERIODS,
    payload: {
      data,
      receivedAt: Date.now(),
    },
  };
};

export const requestCompanies = () => ({
  type: REQUEST_COMPANIES,
  payload: {
    loading: true,
    sending: true,
    sent: false,
  },
});
export const receiveCompanies = (data, pages, sent = true) => ({
  type: RECEIVE_COMPANIES,
  payload: {
    data,
    pages,
    loading: false,
    sending: false,
    sent,
    receivedAt: Date.now(),
  },
});

const requestCompany = () => {
  return {
    type: REQUEST_COMPANY,
    payload: {
      loading: true,
      sending: true,
      sent: false,
    },
  };
};

const receiveCompany = (data, sent = true) => {
  return {
    type: RECEIVE_COMPANY,
    payload: {
      data,
      loading: false,
      sending: false,
      sent,
      receivedAt: Date.now(),
    },
  };
};

const requestAddSubscription = () => {
  return {
    type: REQUEST_ADD_SUBSCRIPTION,
    payload: {
      loading: true,
      sending: true,
      sent: false,
    },
  };
};

const receiveAddSubscription = (data, sent = true) => {
  return {
    type: RECEIVE_ADD_SUBSCRIPTION,
    payload: {
      data,
      loading: false,
      sending: false,
      sent,
      receivedAt: Date.now(),
    },
  };
};

export function fetchCompanies(params) {
  return async (dispatch, getState) => {
    const token = getState().auth.accessToken;
    dispatch(requestCompanies());
    return CompanyService.getCompanies(token, params)
      .then(({ data }) =>
        dispatch(
          receiveCompanies(data.results, Math.ceil(data.total / params.limit)),
        ),
      )
      .catch(error => {
        dispatch(receiveCompanies(null));
        dispatch(addNotification({ style: 'error', message: error.message }));
      });
  };
}

export const fetchCompany = companyId => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;
    dispatch(requestCompany());
    return CompanyService.getCompany(accessToken, companyId)
      .then(({ data }) => {
        dispatch(receiveCompany(data));
      })
      .catch(error => {
        dispatch(receiveCompany(null));
        dispatch(addNotification({ style: 'error', message: error.message }));
      });
  };
};

export const deleteCompany = companyId => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;
    return CompanyService.deleteCompany(accessToken, companyId).catch(error => {
      dispatch(addNotification({ style: 'error', message: error.message }));
    });
  };
};

export const createCompany = details => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;
    dispatch(requestCompany());
    return CompanyService.createCompany(accessToken, details)
      .then(({ data }) => {
        dispatch(receiveCompany(data));
        history.push(`/admin/companies/${data.id}`);
      })
      .catch(error => {
        dispatch(receiveCompany(null));
        dispatch(addNotification({ style: 'error', message: error.message }));
      });
  };
};

export const fetchSubscriptions = companyId => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;
    if (!companyId) return;
    dispatch(requestSubscriptions());

    // eslint-disable-next-line consistent-return
    return SubscriptionService.getSubscriptions(accessToken, companyId).then(
      ({ data }) => {
        dispatch(receiveSubscriptions(data));
        dispatch(receiveBillingPeriods(data));
      },
    );
  };
};

export const updateCompany = (companyId, companyData) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;
    return CompanyService.updateCompany(accessToken, companyId, companyData)
      .then(({ data }) => {
        dispatch(receiveCompany(data));
        dispatch(fetchUsers(companyId));
        dispatch(fetchCredentials(companyId));
        dispatch(fetchSubscriptions(companyId));
        dispatch(addNotification(updatedMessage(data.name)));
      })
      .catch(error => {
        dispatch(addNotification({ style: 'error', message: error.message }));
      });
  };
};

export const addSubscription = (companyId, details, isNotify = true) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;
    dispatch(requestAddSubscription());
    try {
      const response = await SubscriptionService.subscribe(
        accessToken,
        companyId,
        details,
      );
      if (response.data) {
        dispatch(receiveAddSubscription(response.data));
        dispatch(fetchSubscriptions(companyId));
        if (isNotify) {
          dispatch(
            addNotification({
              style: 'confirmation',
              message: 'Subscription Activated!',
            }),
          );
        }
      } else {
        dispatch(receiveAddSubscription(null));
        dispatch(addNotification(errorMessage));
      }
    } catch (error) {
      dispatch(receiveAddSubscription(null));
      dispatch(addNotification({ style: 'error', message: error.message }));
    }
  };
};

export const editSubscription = (companyId, details, isNotify = true) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;
    dispatch(requestUpdateSubscription());
    try {
      const response = await SubscriptionService.updateSubscription(
        accessToken,
        companyId,
        details,
      );
      if (response.data) {
        dispatch(receiveUpdateSubscription(response.data));
        dispatch(fetchSubscriptions(companyId));
        if (isNotify) {
          dispatch(
            addNotification({
              style: 'confirmation',
              message: 'Subscription Updated!',
            }),
          );
        }
      } else {
        dispatch(receiveUpdateSubscription(null));
        dispatch(addNotification(errorMessage));
      }
    } catch (error) {
      dispatch(receiveUpdateSubscription(null));
      dispatch(addNotification({ style: 'error', message: error.message }));
    }
  };
};
