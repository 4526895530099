import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import classNames from 'classnames';
import './Dropdown.scss';

const Styles = {
  container: (base, state) => {
    if (state && state.options) {
      return {
        ...base,
        fontSize: '14px',
        width: '100%',
      };
    }
  },
  control: (base, state) => ({
    ...base,
    marginTop: '10px',
    border: '1px solid #d1d5da',
    borderRadius: '4px',
    padding: '2px',
    cursor: 'pointer',
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    display: 'none',
  }),
  input: (base, state) => ({
    ...base,
    padding: '0px',
  }),
  placeholder: (base, state) => ({
    ...base,
    padding: '10px 8px',
    fontFamily: 'Roboto, sans-serif',
    color: '#757575',
  }),
  menu: base => ({
    ...base,
    zIndex: 3,
  }),
};

const Dropdown = ({
  label,
  name,
  className,
  value,
  placeholder,
  errorMessage,
  onChange,
  options,
  isClearable,
  formatOptionLabel,
  styles,
  isDisabled,
  forwardRef,
  classNamePrefix,
  ...props
}) => {
  return (
    <div className="dropdown">
      {label ? <p className="dropdown__label">{label}</p> : null}
      <Select
        isDisabled={isDisabled || false}
        formatOptionLabel={formatOptionLabel}
        isClearable={isClearable || false}
        styles={styles || Styles}
        name={name}
        className={classNames(
          { error: errorMessage },
          'dropdown__select',
          className,
        )}
        classNamePrefix={classNamePrefix || 'dropdown'}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        options={options}
        ref={forwardRef}
        {...props}
      />
      {errorMessage && <div className="form__error">{errorMessage}</div>}
    </div>
  );
};

Dropdown.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  errorMessage: PropTypes.string,
  forwardRef: PropTypes.object,
};

Dropdown.defaultProps = {
  label: '',
  name: '',
  className: '',
  placeholder: '',
  errorMessage: '',
  forwardRef: null,
};

export default Dropdown;
