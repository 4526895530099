import React from 'react';
import {
  DateCell,
  FilenameCell,
  CheckboxColumn,
  ShowColumns,
} from '../../library/organisms/Table';
import {
  ContributorsCell,
  PriorityCell,
  TagListCell,
  TaskStateCell,
} from './components/Cells';

/**
 * The columns for task table in react-table format.
 *
 * Columns with `accessor` are sortable, disable with `disableSortBy`.
 */
const taskColumns = [
  CheckboxColumn,
  {
    Header: 'Task state',
    accessor: 'state',
    Cell: TaskStateCell,
    disableSortBy: true,
  },
  {
    Header: 'Filename',
    accessor: 'document.filename',
    Cell: col => <FilenameCell {...col} tooltipId="tasks-tooltip" />,
    disableSortBy: true,
  },
  {
    Header: 'Task name',
    accessor: 'specificationName',
    disableSortBy: true,
  },
  {
    Header: 'Company name',
    accessor: 'company.name',
    disableSortBy: true,
  },
  {
    Header: 'Created',
    accessor: 'created',
    Cell: col => <DateCell {...col} tooltipId="tasks-tooltip" />,
    sortDescFirst: true,
  },
  {
    Header: 'In progress',
    accessor: 'inProgress',
    Cell: col => <DateCell {...col} tooltipId="tasks-tooltip" />,
    sortDescFirst: true,
  },
  {
    Header: 'Completed',
    accessor: 'completed',
    Cell: col => <DateCell {...col} tooltipId="tasks-tooltip" />,
    sortDescFirst: true,
  },
  {
    id: 'contributors',
    Header: 'Reviewers',
    Cell: ContributorsCell,
  },
  {
    Header: 'Priority',
    accessor: 'priority',
    Cell: PriorityCell,
    sortDescFirst: true,
  },
  {
    id: 'mergeColumn',
    Header: 'Tags',
    Cell: col => <TagListCell {...col} tooltipId="tasks-tooltip" />,
  },
  {
    id: 'lastColumn',
    Header: col => (
      <ShowColumns
        // eslint-disable-next-line react/destructuring-assignment
        allColumns={col.allColumns}
        toggleColumns={['created', 'inProgress', 'completed']}
      />
    ),
    Cell: <></>,
  },
];

export default taskColumns;
