import React from 'react';
import Skeleton, {
  SkeletonH2,
  SkeletonOL,
  SkeletonLI,
} from '../../../Skeleton';

const CaptureDataLoading = props => {
  return (
    <div className="capture-data">
      <Skeleton className="capture-data__skeleton">
        <SkeletonH2 />
        <SkeletonOL>
          <SkeletonLI />
          <SkeletonLI />
          <SkeletonLI />
          <SkeletonLI />
          <SkeletonLI />
          <SkeletonLI />
          <SkeletonLI />
          <SkeletonLI />
          <SkeletonLI />
        </SkeletonOL>
      </Skeleton>
    </div>
  );
};

CaptureDataLoading.propTypes = {};

export default CaptureDataLoading;
