import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs } from '../../../UI/Tabs';
import './_captureMenu.scss';

const getTarget = (product, match, bgColour) => {
  const bgColourStr = bgColour ? `&bgColour=${bgColour}` : '';
  const target = !match.path.includes('demo')
    ? `${match.url}?ai=${encodeURIComponent(product.productType)}${bgColourStr}`
    : `/demo/capture/${product.productType}?doc=${product.docId}${bgColourStr}`;
  return target;
};

const CaptureMenu = ({ products, match, bgColour }) => {
  return (
    <Tabs className="capture-menu">
      {(products || []).map(p => {
        return (
          <Tab
            href={getTarget(p, match, bgColour)}
            tabFor={p.productType}
            key={`capture-menu|${p.productType}`}
          >
            {p.name}
          </Tab>
        );
      })}
    </Tabs>
  );
};

CaptureMenu.propTypes = {
  bgColour: PropTypes.string,
  products: PropTypes.arrayOf(
    PropTypes.shape({ productType: PropTypes.string, docId: PropTypes.string }),
  ),
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
  }),
};

CaptureMenu.defaultProps = {
  bgColour: null,
  products: null,
  match: {},
};

export default CaptureMenu;
