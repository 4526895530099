/* eslint-disable consistent-return */
/* eslint-disable react/prop-types,no-restricted-syntax */
import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  forwardRef,
} from 'react';
import { FaTable } from 'react-icons/fa';
import classNames from 'classnames';

import { getTable, getTableData } from '../../../utils/getTableData';
import {
  TableContext,
  InitialTableState,
} from '../../../../DocumentView/TableContext';

const AnnotationTables = forwardRef(
  ({ field, focusAnnotationTbl, annotationTblClicked, openTable }, ref) => {
    const [, setTableContext] = useContext(TableContext);
    const tables = (field?.value || {}).items || [];
    const selectedTblKey = (focusAnnotationTbl || {}).key;

    const tableData = field?.value?.items;
    const fieldSpec = field?.fieldSpec?.specification?.components;

    const tableBoundsData = useMemo(
      () => tableData.map(t => getTableData(t)),
      [tableData],
    );

    const handleClick = (key, i) => {
      if (key === selectedTblKey) {
        setTableContext(InitialTableState);
        annotationTblClicked(null, i);
        return;
      }

      // Set context
      setTableContext(context => ({
        ...context,
        activeTable: tableBoundsData[i],
        pageId: '1',
        selectionMode: false,
        lockTable: true,
        readOnly: true,
      }));

      // auto-scroll highlight
      openTable(tableBoundsData[i]);

      annotationTblClicked(
        {
          key,
          table: getTable(tableData[i], fieldSpec),
        },
        i,
      );
    };

    // on focus out, reset table context
    useEffect(() => {
      if (!focusAnnotationTbl) {
        setTableContext(InitialTableState);
      }
    }, [focusAnnotationTbl, setTableContext]);

    if (!field) return;

    return (
      <>
        <div ref={ref} className="field lineitem-header">
          <h3>{field.fieldName}</h3>
        </div>
        <div className="lineitem2-content">
          {(tables || []).map((t, i) => {
            const key = `${field.id}_${i}`;
            return (
              <span
                className={classNames('field lineitem-entry', {
                  focus: selectedTblKey === key,
                })}
                style={{ padding: '10px 40px' }}
                onClick={() => handleClick(key, i)}
                role="button"
                tabIndex={0}
                key={key}
              >
                <FaTable />
                <span>
                  Table
                  {i + 1}
                </span>
              </span>
            );
          })}
          {!tables.length && (
            <span
              style={{ padding: '10px 40px' }}
              className="field lineitem-entry"
            >
              No Tables Found
            </span>
          )}
        </div>
      </>
    );
  },
);

export default AnnotationTables;
