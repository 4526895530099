import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { abbrevToK } from '../../../../common/helpers';
import { setVisibleTiers } from '../../helpers';
import './RateCard.scss';

const MAX_THRESHOLD = '30k';

const RateCard = ({ className, pricing, isShowFeatures }) => {
  if (!pricing || !pricing.tiers || pricing.tiers.length <= 0) return null;
  const visibleTiers = setVisibleTiers(pricing.tiers);

  return (
    <div
      className={classNames(
        'rate-card',
        `rate-card--${visibleTiers.length + 1}up`,
        className,
        {
          'rate-card--2r': isShowFeatures,
        },
      )}
    >
      {(visibleTiers || []).map((t, i) => (
        <div className="tier tier--short" key={`tier_${t.threshold}`}>
          <h3 className="tier__ppm">
            {t.threshold !== 'inf' && (
              <>
                Up to <strong>{abbrevToK(t.threshold)}</strong>
              </>
            )}{' '}
            {visibleTiers.length === 1 && (
              <>
                Up to <strong>{MAX_THRESHOLD}</strong>
              </>
            )}{' '}
            {visibleTiers.length > 1 && t.threshold === 'inf' && (
              <strong>
                {abbrevToK(visibleTiers[i - 1].threshold || 0)} to{' '}
                {MAX_THRESHOLD}
              </strong>
            )}{' '}
            pages
            <br /> per month
          </h3>
          <dl className="tier__price">
            <dt className="tier__price-amt">
              {`$${Number(t.unitPrice || 0).toFixed(2)}c`}
            </dt>
            <dd className="tier__price-unit">per page</dd>
          </dl>
        </div>
      ))}
      {isShowFeatures && (
        <div className="tier tier--colspan">
          <ul className="tier__list">
            <li className="tier__list-item">
              One-off free 14 day trial available for your first 500 pages
            </li>
            <li className="tier__list-item">
              Instantly unlock a full subscription by providing your payment
              details
            </li>
            <li className="tier__list-item">
              Track your spend, set monthly threshold & manage your billing
            </li>
          </ul>
        </div>
      )}
      <div
        className={classNames('tier', {
          'tier--short': !isShowFeatures,
        })}
      >
        <h3 className="tier__ppm">
          <strong>{MAX_THRESHOLD}+</strong> pages
          <br /> per month
        </h3>
        <a
          className="tier__cta"
          href="https://www.sypht.com/contact"
          target="_blank"
          rel="noopener noreferrer"
        >
          Talk to sales
        </a>
        {isShowFeatures && (
          <ul className="tier__list">
            <li className="tier__list-item">Enterprise SLAs</li>
            <li className="tier__list-item">Enterprise solutioning</li>
            <li className="tier__list-item">Implementation support</li>
          </ul>
        )}
      </div>
    </div>
  );
};

RateCard.propTypes = {
  pricing: PropTypes.shape({
    tiers: PropTypes.arrayOf(PropTypes.object),
  }),
  className: PropTypes.string,
  isShowFeatures: PropTypes.bool,
};

RateCard.defaultProps = {
  pricing: null,
  className: null,
  isShowFeatures: true,
};

export default RateCard;
