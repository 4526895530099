import React from 'react';
import classNames from 'classnames';

const GenericTable = ({
  table,
  idx,
  focusPrediction,
  refPanelGenericTables,
  predictionClicked,
}) => {
  const generateTblItemsField = data => {
    const tokenId = data.bounds.tokenIds ? data.bounds.tokenIds[0] : 'null';
    return `${data.type}:${data.bounds.pageNum}:${tokenId}`;
  };

  const tblBody = (
    <tbody key={`tbody-${idx}`}>
      {table.map((tblRow, rid) => {
        return (
          <tr key={`tbody-${rid}`}>
            {tblRow.map((tblCell, cellId) => {
              const field =
                tblCell && tblCell.bounds ? generateTblItemsField(tblCell) : '';

              const focusPreds =
                focusPrediction && focusPrediction.bounds
                  ? generateTblItemsField(focusPrediction)
                  : '';

              return (
                <td key={`tbody-${cellId}-td`}>
                  {tblCell.uid ? (
                    <span
                      role="button"
                      className={classNames(
                        'field field-d',
                        {
                          focus: focusPrediction && field === focusPreds,
                        },
                        { null: field == null },
                      )}
                      onClick={() => {
                        return predictionClicked(
                          focusPrediction && field === focusPreds
                            ? null
                            : tblCell,
                          true,
                        );
                      }}
                      tabIndex="0"
                    >
                      {tblCell.label ? tblCell.label : 'N/A'}
                    </span>
                  ) : (
                    <span className="field-item">N/A</span>
                  )}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
  return (
    <div
      ref={el => (refPanelGenericTables.current[idx] = el)}
      className="table-docview-panel"
    >
      <table className={classNames('table-docview', 'generic-table')}>
        {[tblBody]}
      </table>
    </div>
  );
};

export default GenericTable;
