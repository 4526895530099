import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';
import './SplitView.scss';
import ChildDocument from './ChildDocument';

const SplitView = ({
  childDocs,
  order,
  pages,
  onDragEnd,
  removeSplit,
  addSplit,
}) => {
  return (
    <div className="split-view">
      <DragDropContext onDragEnd={onDragEnd}>
        {childDocs && order
          ? order.map((childDocId, index) => {
              const childDoc = childDocs[childDocId];
              return (
                <ChildDocument
                  key={childDoc.id}
                  childDoc={childDoc}
                  pages={childDoc.pages.map(pageId => pages[pageId])}
                  isLastChildDoc={order.length - 1 === index}
                  nextChildDoc={
                    index === order.length ? null : childDocs[order[index + 1]]
                  }
                  removeSplit={removeSplit}
                  addSplit={addSplit}
                />
              );
            })
          : null}
      </DragDropContext>
    </div>
  );
};

SplitView.defaultProps = {
  childDocs: {},
  order: [],
  pages: {},
  onDragEnd: () => {},
  removeSplit: () => {},
  addSplit: () => {},
};

SplitView.propTypes = {
  childDocs: PropTypes.object,
  order: PropTypes.array,
  pages: PropTypes.object,
  onDragEnd: PropTypes.func,
  removeSplit: PropTypes.func,
  addSplit: PropTypes.func,
};

export default SplitView;
