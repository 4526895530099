import { REQUEST_SETTINGS, RECEIVE_SETTINGS } from './actions';

const initialState = {
  data: {},
  loading: false,
};

const settingsReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case REQUEST_SETTINGS: {
      const { loading } = payload;
      return {
        ...state,
        loading,
      };
    }

    case RECEIVE_SETTINGS: {
      const { settings, loading, receivedAt } = payload;
      return {
        ...state,
        data: settings,
        loading,
        lastUpdated: receivedAt,
      };
    }

    default:
      return state;
  }
};

export default settingsReducer;
