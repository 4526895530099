/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react';
import { FaUserLock } from 'react-icons/fa';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { addToList, removeFromList } from '../../../../../../common/helpers';
import { ConfirmModal } from '../../../../../UI/ModalV2';
import Dropdown from '../../../../../UI/DropdownV2';
import DataTable, {
  CheckboxColumn,
  DataColumn,
} from '../../../../../UI/DataTable';
import SearchField from '../../../../../UI/SearchField/SearchFieldV2';
import TagsColumm from '../../../../../UI/DataTable/columns/TagsColumn';
import Tag from '../../../../../../library/atoms/Tag';
import TagList from '../../../../../../library/molecules/TagList';
import './_assignUserModal.scss';

const DEFAULT_SEARCH = { value: null, type: null };
const DEFAULT_TAG_FILTER = { value: null, label: null };

const getName = user => {
  return `${user.firstName || ''} ${user.lastName || ''}`.trim().toLowerCase();
};

const AssignUserModal = ({
  companyId,
  selectedTagIds,
  tags,
  users,
  onConfirmClick,
  ...props
}) => {
  if (!users) return null;
  const [filteredUsers, setFilteredUsers] = useState();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isSelectAll, setSelectAll] = useState(false);
  const [searchFilter, setSearchFilter] = useState(DEFAULT_SEARCH);
  const [tagFilter, setTagFilter] = useState(DEFAULT_TAG_FILTER);

  const tagOptions = (tags || [])
    .filter(t => t.isAccessTag)
    .map(t => ({
      value: t.id,
      label: t.name,
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));

  useEffect(() => {
    let newFilteredUsers = [...users];

    if (searchFilter.value) {
      newFilteredUsers = [...newFilteredUsers].filter(u =>
        getName(u).includes((searchFilter.value || '').toLowerCase()),
      );
    }

    if (tagFilter.value) {
      newFilteredUsers = [...newFilteredUsers].filter(u =>
        u.tags.map(ut => ut.id).includes(tagFilter.value),
      );
    }

    setFilteredUsers(newFilteredUsers);
  }, [searchFilter, tagFilter]);

  useEffect(() => {}, [tagFilter]);

  const handleConfirmClick = e => {
    onConfirmClick(e, selectedUsers);
  };

  const handleFetchData = dataTable => {
    const { sorted } = dataTable;
    if (sorted[0] && sorted[0].desc) {
      setFilteredUsers(
        [...filteredUsers].sort((a, b) =>
          a.firstName + b.lastName > b.firstName + b.lastName ? -1 : 1,
        ),
      );
    } else {
      setFilteredUsers(
        [...filteredUsers].sort((a, b) =>
          a.firstName + b.lastName > b.firstName + b.lastName ? 1 : -1,
        ),
      );
    }
  };

  const handleFilterChange = filter => {
    setTagFilter(filter || DEFAULT_TAG_FILTER);
  };

  const handleSearchChange = result => {
    // on empty search input, reset search
    if (!result) {
      setSearchFilter(DEFAULT_SEARCH);
      return;
    }

    const { value } = result;
    setSearchFilter({ value });
  };

  const handleToggleAll = e => {
    e.stopPropagation();
    if (!e.target.checked) {
      setSelectedUsers([]);
      setSelectAll(false);
      return;
    }

    setSelectedUsers(users.map(u => u.id));
    setSelectAll(true);
  };

  const handleToggleRow = (e, userId) => {
    e.stopPropagation();

    if (selectedUsers.includes(userId)) {
      setSelectedUsers(removeFromList(selectedUsers, userId));
      setSelectAll(false);
      return;
    }

    setSelectedUsers(addToList(selectedUsers, userId));
  };

  return (
    <ConfirmModal
      className="assign-user-modal"
      heading="Assign access tags to users"
      confirmText="Assign tags"
      isConfirmDisabled={!selectedUsers.length}
      onConfirmClick={handleConfirmClick}
      {...props}
    >
      <p>
        <strong>
          {selectedTagIds
            .map(st => tags.find(t => t.id === st).name)
            .join(', ')}
        </strong>
      </p>
      <p>
        Assign your 'access tags' to users that should have permission to view
        the respective documents & tasks that have also been assigned with the
        same tags.
      </p>
      <div className="assign-user-modal__filters">
        <SearchField
          data={users.map(u => ({
            name: `${u.firstName} ${u.lastName}`.trim(),
            value: u.id,
          }))}
          placeholder="Search by name"
          onChange={handleSearchChange}
        />
        <Dropdown
          id="selected_tag_filter"
          options={tagOptions}
          placeholder="Tags"
          isClearable
          onChange={handleFilterChange}
        />
        <a
          href="/users"
          className="btn btn--blue-outline assign-user-modal__btn"
        >
          Manage users
        </a>
      </div>
      <div className="assign-user-modal__users">
        <DataTable
          data={filteredUsers}
          columns={[
            new CheckboxColumn({
              id: 'users_list',
              selected: selectedUsers,
              isSelectAll,
              onClick: handleToggleRow,
              onClickAll: handleToggleAll,
            }),
            new DataColumn({
              Header: 'Name',
              accessor: 'firstName',
              Cell: ({ original: user }) => {
                return (
                  <>
                    {user.firstName} {user.lastName}
                  </>
                );
              },
              sortable: true,
              width: 220,
            }),
            new TagsColumm({
              Header: 'Assigned access tags',
              Cell: ({ original: user }) => {
                return (
                  <TagList>
                    {(user.tags || []).map(t => (
                      <Tag
                        iconSize={14}
                        key={`tagItem|${user.id}|${t.id}`}
                        title={t.name}
                      >
                        <FaUserLock />
                        {t.name}
                      </Tag>
                    ))}
                  </TagList>
                );
              },
            }),
          ]}
          defaultPageSize={100}
          pages={100}
          style={{ height: '351px' }}
          sortBy="firstName"
          onFetchData={handleFetchData}
          onRowClick={(e, user) => {
            return handleToggleRow(e, user.id);
          }}
          setRowProps={(table, user) => ({
            className: classNames({
              '-selected': selectedUsers.includes(user.id),
            }),
          })}
        />
      </div>
    </ConfirmModal>
  );
};

AssignUserModal.propTypes = {
  companyId: PropTypes.string,
  selectedTagIds: PropTypes.arrayOf(PropTypes.string),
  tags: PropTypes.arrayOf(PropTypes.object),
  users: PropTypes.arrayOf(PropTypes.object),
  onConfirmClick: PropTypes.func,
};

AssignUserModal.defaultProps = {
  companyId: null,
  selectedTagIds: [],
  users: null,
  tags: [],
  onConfirmClick: () => {},
};

export default AssignUserModal;
