import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './NextTaskButton.scss';
import { FaPlay } from 'react-icons/fa';

import * as actions from '../../reducers/nextTask';

const mapStateToProps = state => {
  return {
    loading: state.nextTask.loading,
    currentTaskId: state.tasks.active.data
      ? state.tasks.active.data.task.id
      : null,
    nextTaskId: state.nextTask.data ? state.nextTask.data.nextTaskId : null,
    nextTask: state.nextTask.data ? state.nextTask.data.nextTask : null,
    numRemaining: state.nextTask.data
      ? Number(state.nextTask.data.numRemaining)
      : null,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchNextTaskUsingStoredFilters: payload =>
      dispatch(actions.fetchNextTaskUsingStoredFilters(payload)),
    goToNextTaskUsingStoredFilters: () =>
      dispatch(actions.goToNextTaskUsingStoredFilters()),
  };
};

/**
 * Allow a user to go to their next available task to work on as well as display
 * num remaining.
 *
 * Used on Task page and docsplit page.
 *
 * FIXME: also see src/pages/Tasks/components/NextTaskButton/NextTaskButton.jsx which is used on Task List page.
 */
class NextTaskButton extends Component {
  componentDidMount() {
    const { fetchNextTaskUsingStoredFilters, loading } = this.props;
    if (!loading) {
      fetchNextTaskUsingStoredFilters();
    }
  }

  render() {
    const {
      nextTaskId,
      numRemaining,
      disabled,
      loading,
      redirect,
      goToNextTaskUsingStoredFilters,
    } = this.props;
    const classNames = ['next-task', 'tasks__button'];
    const isDisabled = loading || !nextTaskId || disabled || redirect;
    if (isDisabled) {
      classNames.push('disabled');
    }
    const plural = numRemaining > 1 ? 's' : '';
    return (
      <button
        type="button"
        className={classNames.join(' ')}
        disabled={isDisabled}
        onClick={goToNextTaskUsingStoredFilters}
        data-tooltip-content={loading ? null : `Open next task`}
      >
        {numRemaining !== 0 ? <FaPlay /> : null}
        <span>
          {/* eslint-disable-next-line no-nested-ternary */}
          {loading
            ? redirect
              ? 'Loading next task...'
              : 'Checking tasks...'
            : numRemaining === 0
            ? `No pending tasks`
            : `${numRemaining} task${plural} to do`}
        </span>
      </button>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NextTaskButton);

NextTaskButton.propTypes = {
  numRemaining: PropTypes.number,
  disabled: PropTypes.bool.isRequired,
  redirect: PropTypes.bool,
  nextTaskId: PropTypes.string,
  nextTask: PropTypes.shape({
    specification: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  fetchNextTaskUsingStoredFilters: PropTypes.func.isRequired,
  goToNextTaskUsingStoredFilters: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

NextTaskButton.defaultProps = {
  numRemaining: 0,
  nextTask: undefined,
  nextTaskId: undefined,
  redirect: undefined,
};
