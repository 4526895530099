import React from 'react';

import { Grid, GridItem } from '../../../library/templates/Grid';

import Page, {
  PageBody,
  PageHeader,
  PageInner,
} from '../../../components/Page';

import EmailList from './components/EmailList/EmailList';
import InboundEmailBox from './components/InboundEmailBox/InboundEmailBox';

import './_emailIntegration.scss';

const EmailIntegration = () => {
  return (
    <Page className="email-integration" title="Email integration">
      <PageInner>
        <PageHeader>
          <h1>Email integration</h1>
        </PageHeader>
        <PageBody>
          <Grid colGap="primary" rowGap="primary">
            <GridItem col="12">
              <p>
                Integrate Sypht with your business email or mail server, to
                automatically upload email document attachments into Sypht for
                further processing.
              </p>
              <p>
                To setup Sypht email integration, simply specify your email
                addresses from which you&#39;ll be sending emails inbound to
                Sypht, and that&#39;s it! We will take care of the rest.
              </p>
            </GridItem>
            <GridItem col="12">
              <hr />
            </GridItem>
            <GridItem col="6">
              <h2>Inbound emails to Sypht</h2>
              <p>
                We have setup a private mailbox for your company, and the email
                address has intentionally been scrambled as a security measure.
                Please use this address when setting up your email forwarding
                rules.
              </p>
            </GridItem>
            <GridItem col="6">
              <InboundEmailBox />
            </GridItem>
            <GridItem col="12">
              <hr />
            </GridItem>
            <GridItem col="6">
              <h2>
                What email addresses will you use to send documents to Sypht?
              </h2>
              <p>
                Sypht will only accept documents that are sent from the email
                addresses specified below. These email addresses must be yours
                (no client email addresses).
              </p>
              <EmailList />
            </GridItem>
            <GridItem col="12">
              <hr />
            </GridItem>
          </Grid>
        </PageBody>
      </PageInner>
    </Page>
  );
};

export default EmailIntegration;
