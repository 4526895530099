/* eslint-disable react/prop-types,react/no-array-index-key */
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { FaCircle } from 'react-icons/fa';
import classNames from 'classnames';
import AbsentFields from './components/AbsentFields';
import DocumentInfo from './components/DocumentInfo';
import SidebarPredictions from './components/FieldValues';
import SidebarGeneric from './components/Generic';
import SidebarStructured from './components/Structured';
import LineItems from './components/LineItems';
import LineItemsV3 from './components/LineItemsV3';
import EntityValue from './components/EntityValue';
import SignalValue from './components/SignalValue';
import AnnotationTables from './components/AnnotationTables';
import FieldStatisticsValue from './components/FieldStatisticsValue';
import DocumentReferenceValue from './components/DocumentReferenceValue';
import Chip from '../../../UI/Chip/Chip';
import { ColorTypes } from '../../../UI/Chip/types';
import { getConfidence } from '../../../../common/helpers';
import DeveloperView from '../../../../library/organisms/DeveloperView/DeveloperView';
import Button from '../../../../library/atoms/Buttons/Button';
import './Sidebar.scss';

import useScrollPredictionIntoView from './hooks/useScrollPredictionIntoView';
import SignalsWrapper from './SignalsWrapper';

const mapStateToProps = state => {
  const { docs } = state;
  return {
    doc: docs.active.data,
  };
};

const Sidebar = ({
  doc,
  predictionClicked,
  focusPrediction,
  genericTblClicked,
  focusGenericTbl,
  refSidebarGenericFields,
  refSidebarGenericTables,
  focusAnnotationTbl,
  annotationTblClicked,
  openTable,
}) => {
  const [showDeveloperView, setShowDeveloperView] = useState(false);
  const predictionsByProduct = doc.productFieldMapping || {};
  const numOfPredictions = Object.keys(predictionsByProduct).length;
  const capturePredictions = Object.fromEntries(
    Object.entries(predictionsByProduct).filter(
      ([productName]) => doc.products[productName].productType === 'capture',
    ),
  );
  const signalsPredictions = Object.fromEntries(
    Object.entries(predictionsByProduct).filter(
      ([productName]) => doc.products[productName].productType === 'signals',
    ),
  );
  const showSignals = !!Object.keys(signalsPredictions).length;
  const getChildDocs = () => {
    const predictions = doc.predicted?.results || [];
    const children = predictions.find(
      p => p.id === 'sypht.components.children',
    );
    const childDocs = children?.predictions?.[0]?.value_norm;
    if (!childDocs) {
      return null;
    }
    return childDocs.map(p => ({ id: p.file_id }));
  };

  let signalsFieldsArray = null;
  const { setScrollToElement } = useScrollPredictionIntoView([focusPrediction]);
  const productSections = numOfPredictions
    ? Object.entries(capturePredictions).map(
        ([productName, fieldsArray], index) => {
          if (productName === 'Invoice Signals') {
            signalsFieldsArray = fieldsArray;
            return null;
          }
          const fields = fieldsArray;
          const fieldsWithPredictions = fields.filter(f => f.value);
          const fieldsWithNoPredictions = fields.filter(f => !f.value);
          return (
            <div key={index} className="fieldset">
              <div id="annotation-tray" />
              <div className="header">{productName} fields</div>
              <div className="fields">
                {fieldsWithPredictions.map((p, i) => {
                  if (p.field === 'generic.structure') {
                    return (
                      <SidebarGeneric
                        onClick={predictionClicked}
                        onTableClick={genericTblClicked}
                        focusGenericTbl={focusGenericTbl}
                        focusPrediction={focusPrediction}
                        fieldsRef={refSidebarGenericFields}
                        tablesRef={refSidebarGenericTables}
                        key={`${p.fieldName}-${i}`}
                      />
                    );
                  }
                  if (
                    p.type &&
                    (p.type === 'multilabel' || p.type === 'multiclass')
                  ) {
                    const confidence = getConfidence(p.confidence_norm);
                    return (
                      <Fragment key={`${i.fieldName}-${i}`}>
                        <div className="field field--type">
                          <h3>{p.fieldSpec.specification.name}</h3>
                          <ul className="field__list">
                            {p.value &&
                              Object.entries(p.value)
                                .filter(([, v]) => v.value)
                                .map(([k]) => (
                                  <li
                                    className="field__list-item"
                                    key={`${i.fieldName}-${i}-${k}`}
                                  >
                                    <Chip color={ColorTypes.Grey}>{k}</Chip>
                                  </li>
                                ))}
                          </ul>
                          <FaCircle
                            data-tooltip-id="document-tooltip"
                            data-tooltip-content={confidence.text}
                            data-tooltip-place="right"
                            size={10}
                            className={classNames(
                              'field-confidence',
                              confidence.label,
                            )}
                          />
                        </div>
                      </Fragment>
                    );
                  }
                  if (p.type && p.type === 'lineitems') {
                    return (
                      <LineItems
                        ref={el =>
                          setScrollToElement(p, focusAnnotationTbl, el)
                        }
                        key={`${i?.fieldName}-${i}`}
                        field={p}
                        focusAnnotationTbl={focusAnnotationTbl}
                        annotationTblClicked={annotationTblClicked}
                        openTable={openTable}
                      />
                    );
                  }
                  if (p.type && p.type === 'tables:2') {
                    return (
                      <LineItemsV3
                        ref={el =>
                          setScrollToElement(p, focusAnnotationTbl, el)
                        }
                        key={`${i?.fieldName}-${i}`}
                        field={p}
                        focusAnnotationTbl={focusAnnotationTbl}
                        annotationTblClicked={annotationTblClicked}
                        openTable={openTable}
                      />
                    );
                  }
                  if (p.type && p.type === 'tables') {
                    return (
                      <AnnotationTables
                        ref={el =>
                          setScrollToElement(p, focusAnnotationTbl, el)
                        }
                        field={p}
                        focusAnnotationTbl={focusAnnotationTbl}
                        annotationTblClicked={annotationTblClicked}
                        key={`${i.fieldName}-${i}`}
                        openTable={openTable}
                      />
                    );
                  }
                  if (
                    p.type &&
                    p.type === 'dictionary' &&
                    'percentile_rank' in p.value_norm
                  ) {
                    return (
                      <FieldStatisticsValue
                        key={`${i.fieldName}-${i}`}
                        field={p}
                      />
                    );
                  }
                  if (
                    p.type &&
                    p.type === 'list' &&
                    p.value_norm &&
                    p.value_norm.length > 0 &&
                    p.value_norm[0].file_id
                  ) {
                    return (
                      <DocumentReferenceValue
                        key={`${i.fieldName}-${i}`}
                        field={p}
                      />
                    );
                  }
                  if (
                    p.type &&
                    p.type === 'float' &&
                    p.source &&
                    p.source.type === 'multi-derived'
                  ) {
                    return (
                      <SignalValue key={`${i.fieldName}-${i}`} field={p} />
                    );
                  }
                  if (
                    p.type &&
                    (p.type === 'entity' ||
                      p.type === 'entity:2' ||
                      p.type === 'derivedentity')
                  ) {
                    return (
                      <EntityValue key={`${i.fieldName}-${i}`} field={p} />
                    );
                  }
                  if (
                    p.value !== null &&
                    (Array.isArray(p.value) || typeof p.value === 'object')
                  ) {
                    return (
                      <SidebarStructured
                        ref={el => setScrollToElement(p, focusPrediction, el)}
                        key={`${i.field}-${i}`}
                        predictions={p}
                        focusPrediction={focusPrediction}
                        onClick={predictionClicked}
                      />
                    );
                  }
                  return (
                    <SidebarPredictions
                      ref={el => setScrollToElement(p, focusPrediction, el)}
                      predictionClicked={predictionClicked}
                      focusPrediction={focusPrediction}
                      key={`${p.fieldName}-${i}`}
                      p={p}
                    />
                  );
                })}
                {fieldsWithNoPredictions.length > 0 ? (
                  <AbsentFields fields={fieldsWithNoPredictions} />
                ) : null}
              </div>
            </div>
          );
        },
      )
    : null;

  return (
    <div className="predictions-panel">
      {doc && (
        <>
          <DocumentInfo
            pages={doc.received?.numOfPages}
            uploadDate={doc.created}
            splitState={doc.splitState}
            extractDate={doc.extractedAt}
            exportDate={doc.exportedAt}
            fixDate={doc.validatedAt}
            parentDocId={doc.parentDocId}
            childDocs={getChildDocs(doc)}
          />
          {numOfPredictions > 0 && (
            <>
              {showSignals && (
                <div className="section-header">
                  <SignalsWrapper
                    fieldsArray={signalsFieldsArray}
                    predictions={signalsPredictions}
                  />
                </div>
              )}
              <div className="section-header">
                <div className="header">
                  <Button
                    className="btn-sidebar"
                    color="black"
                    variant="inverted"
                    onClick={() => {
                      setShowDeveloperView(false);
                    }}
                  >
                    Extractions
                  </Button>
                  <Button
                    className="btn-sidebar"
                    color="black"
                    variant="inverted"
                    onClick={() => {
                      setShowDeveloperView(true);
                    }}
                  >
                    JSON
                  </Button>
                </div>
              </div>
            </>
          )}
          {!showDeveloperView && (
            <div className="predictions" id="predictions">
              {productSections}
            </div>
          )}
          {showDeveloperView && (
            <div>
              <DeveloperView />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(Sidebar);
