import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getReviewerLabel } from '../../../helpers';
import Dropdown from '../../../../../library/molecules/Dropdown';
import StateFilterMenu from './StateFilterMenu';

const mapStateToProps = state => {
  const { auth } = state;
  return {
    userId: auth?.user?.data?.id,
  };
};

const ReviewerFilter = ({ id, value, onChange, options, active }) => {
  const [isOpen, setOpen] = useState(false);
  const filterRef = useRef();

  const handleChange = reviewerId => {
    onChange({
      value: reviewerId.length ? reviewerId : undefined,
    });
  };

  const handleClear = e => {
    e.stopPropagation();
    onChange({
      value: undefined,
    });
    filterRef.current.handleClear();
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(!isOpen);
  };

  return (
    <Dropdown
      id={id}
      className={[
        'tasks__filters-item',
        'tasks-filters__select--left',
        'tasks__filters-item--reviewer',
      ]}
      label={getReviewerLabel('Reviewer', value, options)}
      type="select"
      hasValue={value && value.length}
      onClear={handleClear}
      onClick={handleClick}
      isOpen={isOpen}
      active={active}
    >
      <StateFilterMenu
        className="state-filter-menu--review"
        options={options}
        defaultSelected={value}
        forwardRef={filterRef}
        heading="Filter by reviewer"
        isOpen={isOpen}
        onChange={handleChange}
        active={active}
        hasSelectAll
      />
    </Dropdown>
  );
};

ReviewerFilter.propTypes = {
  id: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  active: PropTypes.bool,
};

ReviewerFilter.defaultProps = {
  id: null,
  value: null,
  onChange: () => {},
  options: null,
  active: null,
};

export default connect(mapStateToProps)(ReviewerFilter);
