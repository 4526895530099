import React from 'react';
import PropTypes from 'prop-types';

const ButtonCell = ({ children, row, handlers }) => {
  const handleClick = e => {};

  return (
    <button type="button" onClick={handleClick}>
      {children}
    </button>
  );
};

ButtonCell.propTypes = {
  children: PropTypes.node,
  row: PropTypes.object.isRequired,
};

ButtonCell.defaultProps = {
  children: null,
};

export default ButtonCell;
