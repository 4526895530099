export const addToList = (values, valueToAdd) => {
  const newValues = [...values];
  if (!newValues.includes(valueToAdd)) {
    newValues.push(valueToAdd); // add to values string[]
    return newValues;
  }
  return values;
};

export const removeFromList = (values, valueToRemove) => {
  const newValues = [...values];
  newValues.splice(newValues.indexOf(valueToRemove), 1);
  return newValues;
};
