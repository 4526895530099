import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Slider.scss';

const Slider = ({ min, max, step, initialValue, onValueChanged, disabled }) => {
  const [value, setValue] = useState(initialValue);
  const sliderRef = useRef(null);

  const handleOnChange = e => {
    const newValue = e.target.value;
    setValue(newValue);
    onValueChanged(newValue);
  };

  useEffect(() => {
    if (sliderRef.current) {
      const backgroundSizeDisabled = '0%';
      const backgroundSize = `${((value - min) * 100) / (max - min)}% 100%`;
      if (!disabled) sliderRef.current.style.backgroundSize = backgroundSize;
      else sliderRef.current.style.backgroundSize = backgroundSizeDisabled;
    }
  });

  return (
    <input
      min={min}
      max={max}
      step={step}
      value={value}
      className="semantic-slider"
      type="range"
      onChange={handleOnChange}
      ref={sliderRef}
      disabled={disabled}
    />
  );
};

Slider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  initialValue: PropTypes.number,
  onValueChanged: PropTypes.func,
  disabled: PropTypes.bool,
};

Slider.defaultProps = {
  min: 0,
  max: 100,
  step: 1,
  initialValue: 0,
  onValueChanged: () => {},
  disabled: false,
};

export default Slider;
