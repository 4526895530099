export const getQueryStr = (url, key) => {
  const param = key.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${param}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);

  if (!results) return null;
  if (!results[2]) return '';

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const setQueryStr = (url, key, value) => {
  const domain = url.split('?')[0];
  const [, hash] = url.split('#');
  const newParam = `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  const currQueryStr =
    url.split('?')[1] !== undefined
      ? `?${url.split('?')[1].split('#')[0]}`
      : '';

  let queryStr = `?${newParam}`;
  if (currQueryStr) {
    const updateRegex = new RegExp(`([?&])${key}=[^&]*`);
    const removeRegex = new RegExp(`([?&])${key}=[^&;]+[&;]?`);

    // if value is empty then remove param
    if (value === undefined || value === null || value === '') {
      queryStr = currQueryStr.replace(removeRegex, '$1');
      queryStr = queryStr.replace(/[&;]$/, '');
    }
    // if param exists then update it
    else if (currQueryStr.match(updateRegex) !== null) {
      queryStr = currQueryStr.replace(updateRegex, `$1${newParam}`);
    }
    // if no existing query string then prefix ?
    else if (currQueryStr === '') {
      queryStr = `?${newParam}`;
    }
    // append to end of query string
    else {
      queryStr = `${currQueryStr}&${newParam}`;
    }
  }
  // if no param set then remove '?'
  queryStr = queryStr === '?' ? '' : queryStr.replace(/%2C/g, ',');
  const hashStr = hash ? `#${hash}` : '';

  window.history.replaceState({}, '', domain + queryStr + hashStr);
  return domain + queryStr + hashStr;
};

export const getQueryParams = search => {
  const params = {};
  const queryTokens = search.replace('?', '').split('&');

  queryTokens.forEach(q => {
    const [key, value] = q.split('=');
    if (key && value) {
      params[key] = decodeURIComponent(value);
    }
  });

  return params;
};

export const setQueryParams = params => {
  Object.entries(params).forEach(([key, value]) => {
    if (key) {
      setQueryStr(window.location.href, key, value);
    }
  });
  return window.location.href;
};

export const getUrlHash = search => {
  console.log(search);
  const [, hash] = search.split('#');
  return hash || '';
};

export const setUrlHash = hash => {
  const [url] = window.location.href.split('#');
  window.history.replaceState({}, '', `${url}#${hash}`);
  return `${url}#${hash}`;
};

/**
 * Parse single sort query parameter into useSortBy (react-table) sort object.
 *
 * @param {string} sortParam a react-table accessor + sort direction eg "<accessor>:asc"
 * @returns undefined or a sort object in useSortBy format
 */
export const decodeSortQueryParam = sortParam => {
  if (!sortParam) {
    return undefined;
  }
  const [field, order] = sortParam.split(':');
  if (!field) {
    return undefined;
  }
  const desc = order !== 'asc';
  const id = field;
  return { id, desc };
};

/**
 * Encodes useSortBy (react-table) sort object into a string for use as a query param - does NOT uri-encode it.
 *
 * @param {Object} param0 react-table useSortBy sort object
 * @returns string
 */
export const encodeSortQueryParam = ({ id, desc } = {}) => {
  if (!id) {
    return undefined;
  }
  return `${id}:${desc ? 'desc' : 'asc'}`;
};
