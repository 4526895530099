import React from 'react';
import PropTypes from 'prop-types';
import { FaTasks } from 'react-icons/fa';
import Button from '../../../../components/UI/Button/Button';

const AddTaskButton = ({ isDisabled, onClick, ...props }) => {
  return (
    <Button disabled={isDisabled} onClick={onClick} {...props}>
      <FaTasks />
      <span>Validate</span>
    </Button>
  );
};

AddTaskButton.propTypes = {
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

AddTaskButton.defaultProps = {
  isDisabled: false,
};

export default AddTaskButton;
