import React, { useEffect, useState, useMemo } from 'react';
import { FaPlus } from 'react-icons/fa';
import { connect } from 'react-redux';
import { isEmpty } from 'ramda';
import PropTypes from 'prop-types';

import { createUser } from '../../reducers/user/actions';
import { fetchUsers } from '../../reducers/users/actions';
import { fetchApplyTags, fetchCompanyTags, updateUsersTags } from '../../reducers/tags/action';

import TagDropDown, { getSelectedTags } from '../UI/TagDropdown/TagDropdown';
import SearchField from '../UI/SearchField/SearchField';
import Button from '../../library/atoms/Buttons/Button';
import Page, {
  PageInner,
  PageHeader,
  PageBody,
  PageRow,
  PageLoading,
  PageCta,
} from '../Page';

import AddUserForm from '../User/AddUserForm';
import UsersTable from './UsersTable';
import './Users.scss';

const initialApplyTagParams = {
  limit:100,
  includeCounts:true,
  sortDirection:'asc',
  sortColumn:'name',
  isAccessTag:true,
}

const mapStateToProps = (state, props) => {
  const { auth, tags, users, user: newUser } = state;
  const { user } = auth;
  const { refresh, loading } = users;

  return {
    users: users.data || [],
    tags: tags?.applyTags.data,
    companyId: props.companyId || user.data?.companyId || null,
    scopes: auth.claims.allowedScopes,
    newUser,
    refresh,
    isLoading: loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      addUser: (userData, companyId) =>
        dispatch(createUser(userData, companyId)),
      //fetchCompanyTags: companyId => dispatch(fetchCompanyTags(companyId)),
      fetchApplyTags: (companyId, params) => dispatch(fetchApplyTags(companyId, params)),
      fetchUsers: companyId =>
        dispatch(
          fetchUsers(companyId, {
            includeTags: true,
          }),
        ),
      updateUsersTags: data => dispatch(updateUsersTags(data)),
    },
  };
};

const Users = ({
  users,
  tags,
  newUser,
  refresh,
  dispatch,
  isLoading,
  admin,
  companyId,
}) => {
  const [filter, setFilter] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSent, setSent] = useState(newUser.sent);
  const [page, setPage] = useState(0);
  const [tagSearch, setTagSearch] = useState(null);

  const selectedUserTags = useMemo(() => getSelectedTags(selectedUsers), [selectedUsers]);

  const selections = useMemo(
    () => selectedUserTags && Object.keys(selectedUserTags).length > 0 ?
      Object.keys(selectedUserTags).sort().join(',') :
      null,
    [selectedUserTags],
  );
  const applyTagParams = useMemo(
    () => {
      let tagName = null;
      if(tagSearch && tagSearch !== ''){
        tagName = tagSearch;
      }
      return {...initialApplyTagParams, selections, name: tagName}
    },
    [selections, tagSearch],
  );

  // On load
  useEffect(() => {
    if (companyId) {
      dispatch.fetchUsers(companyId);
      //dispatch.fetchCompanyTags(companyId);
    }
  }, [companyId, dispatch]);

  // On refresh state changes
  useEffect(() => {
    if (refresh) {
      dispatch.fetchUsers(companyId);
    }
  }, [refresh, dispatch, companyId]);

  // On new user sent
  useEffect(() => {
    setSent(newUser.sent);
  }, [newUser]);

  useEffect(() => {
    if(companyId){
      dispatch.fetchApplyTags(companyId, applyTagParams);
    }
  }, [companyId, applyTagParams, dispatch]);

  const handleOpenModal = () => {
    setSent(false);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSearchChange = e => {
    setFilter(e.target.value);
  };

  const handleSelectedUsersChange = userIds => {
    setSelectedUsers(userIds);
  };

  const handleSubmit = (e, userData) => {
    dispatch.addUser(userData, companyId);
  };

  const handleTagDropdownConfirm = (addTags, removeTags) => {
    const userIds = Object.keys(selectedUsers);
    dispatch
      .updateUsersTags({ companyId, userIds, addTags, removeTags })
      .then(() => {
        dispatch.fetchUsers(companyId);
        dispatch.fetchApplyTags(companyId, applyTagParams);
      });
  };
  const handleTagDropdownSearch = (tagName) => {
    setTagSearch(tagName);
  } 

  return (
    <>
      <Page className="users" layout="full-width">
        <PageInner>
          {!admin && (
            <PageHeader>
              <h1 className="page__heading">My Users</h1>
              <PageCta>
                <Button className="add-button" onClick={handleOpenModal}>
                  <FaPlus /> Add user
                </Button>
                <TagDropDown
                  className="tag-dd-tasks"
                  selectedTags={selectedUserTags}
                  tags={(tags || []).filter(t => t.isAccessTag)}
                  isDisabled={isEmpty(selectedUsers)}
                  onConfirm={handleTagDropdownConfirm}
                  onSearch={handleTagDropdownSearch}
                  onCreateTag={() => {}}
                  enableTagCreation={false}
                />
              </PageCta>
            </PageHeader>
          )}
          {isLoading ? (
            <PageLoading />
          ) : (
            <PageBody>
              <PageRow>
                <div className="admin__section admin-users__actions">
                  <SearchField
                    id="users_search"
                    onChange={handleSearchChange}
                    placeholder="Search by name or email"
                  />
                </div>
              </PageRow>
              <PageRow>
                <UsersTable
                  users={users}
                  filter={filter}
                  admin={admin}
                  onSelectedChange={handleSelectedUsersChange}
                  page={page}
                  onPageChange={setPage}
                />
              </PageRow>
            </PageBody>
          )}
        </PageInner>
      </Page>
      {isModalOpen && (
        <AddUserForm
          isSending={newUser.loading}
          isSent={isSent}
          onSubmit={handleSubmit}
          onSent={handleCloseModal}
          onClose={handleCloseModal}
          isOpen={isModalOpen}
          companyId={companyId}
        />
      )}
    </>
  );
};

Users.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object),
  tags: PropTypes.arrayOf(PropTypes.object),
  companyId: PropTypes.string,
  newUser: PropTypes.shape({
    sent: PropTypes.bool,
    data: PropTypes.shape({}),
    loading: PropTypes.bool,
  }),
  admin: PropTypes.bool,
  refresh: PropTypes.bool,
  isLoading: PropTypes.bool,
  dispatch: PropTypes.shape({
    fetchUsers: PropTypes.func,
    addUser: PropTypes.func,
    fetchApplyTags: PropTypes.func,
    updateUsersTags: PropTypes.func,
  }),
};

Users.defaultProps = {
  users: [],
  tags: [],
  companyId: null,
  newUser: {
    data: null,
    loading: false,
  },
  admin: false,
  refresh: true,
  isLoading: false,
  dispatch: {
    fetchUsers: () => {},
    fetchApplyTags: () => {},
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
