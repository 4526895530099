import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaRegCopy } from 'react-icons/fa';
import FieldGroup from '../../../../library/molecules/fields/FieldGroup';
import './DuplicateSignal.scss';
import AccordionField from '../../../../library/molecules/fields/AccordionField';
import Field from '../../../../library/molecules/fields/Field';

const tooltip = `This signal compares extracted data from this document to all others you've extracted before, and returns ID's of documents where key field values match this document`;

const DuplicateSignalValue = ({ documents = [] }) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const additionalText =
    documents.length === 1
      ? `${documents.length} document`
      : `${documents.length} documents`;

  return (
    <FieldGroup>
      {documents.length === 0 ? (
        <Field
          name="Duplicate Document Signal"
          tooltip={tooltip}
          additionalText={`${documents.length} documents`}
        />
      ) : (
        <AccordionField
          name="Duplicate Document Signal"
          tooltip={tooltip}
          additionalText={additionalText}
          onClick={toggleOpen}
          isOpen={open}
          content={
            <ul className="duplicate-signal__body">
              {documents.map(doc => (
                <li key={doc.file_id}>
                  <a
                    href={`/documents/${doc.file_id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaRegCopy className="duplicate-signal__reg-copy" />
                    {doc.file_id.substring(0, 13)}
                  </a>
                </li>
              ))}
            </ul>
          }
        />
      )}
    </FieldGroup>
  );
};

DuplicateSignalValue.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.shape({ file_id: PropTypes.string })),
};
DuplicateSignalValue.defaultProps = {
  documents: [],
};

export default DuplicateSignalValue;
