import React, { useContext, useEffect, useRef, useState } from 'react';
import { FaPlusCircle } from 'react-icons/fa';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { mergeRight as merge } from 'ramda';
import { ModalContext } from '../UI/Modal/context/ModalContext';
import CredentialClientSecret from './CredentialClientSecret';
import CredentialInfo from './CredentialInfo';
import CredentialMenu from './CredentialMenu';

import {
  deleteCredential,
  fetchCredentials,
} from '../../reducers/credentials/actions';
import './Credentials.scss';
import Modal from '../UI/Modal/Modal';

const mapStateToProps = state => {
  const { credentials, auth } = state;
  return {
    credentials: credentials.data || [],
    user: auth.user ? auth.user.data : null,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      fetchCredentials: companyId => dispatch(fetchCredentials(companyId)),
      deleteCredential: (clientId, companyId) =>
        dispatch(deleteCredential(clientId, companyId)),
    },
  };
};

const CredentialsList = ({ credentials, dispatch, user, companyId }) => {
  const [, setModal] = useContext(ModalContext);
  const credentialRef = useRef(null);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const selectedClientId = useRef(null);
  const selectedClientName = useRef(null);

  useEffect(() => {
    dispatch.fetchCredentials(companyId);
  }, [user, companyId]);

  const handleCreateClick = () => {
    setModal(p => {
      return merge(p, {
        isOpen: true,
        credential: {
          clientName: `Credential set ${(credentials.length || 0) + 1}`,
          isNew: true,
        },
      });
    });
  };

  const handleEditClick = (e, credential) => {
    setModal(p => {
      return merge(p, {
        isOpen: true,
        credential,
      });
    });
  };

  const openConfirmModal = (e, clientId, clientName) => {
    setConfirmModalOpen(true);
    selectedClientId.current = clientId;
    selectedClientName.current = clientName;
  };

  const handleDeleteClick = () => {
    dispatch.deleteCredential(selectedClientId.current, companyId);
    setConfirmModalOpen(false);
  };

  return (
    <div className="credential-list" ref={credentialRef}>
      <button
        type="button"
        className="credential-list__add-button"
        onClick={handleCreateClick}
      >
        <span className="credential-list__add-text">Create credential</span>
        <FaPlusCircle />
      </button>
      {credentials
        .sort((a, b) =>
          a.clientName === b.clientName
            ? 1
            : a.clientName > b.clientName
            ? 1
            : -1,
        )
        .filter(credential => credential.active)
        .map(credential => {
          const { clientId, clientName, active, legacy } = credential;
          return (
            <div className="credential" key={clientId}>
              <div className="credential__header">
                <h3 className="credential__heading">{clientName}</h3>
                <CredentialMenu
                  id={clientId}
                  onDeleteClick={e => openConfirmModal(e, clientId, clientName)}
                  onEditClick={e => handleEditClick(e, credential)}
                />
              </div>
              <div className="credential__content">
                <CredentialClientSecret
                  credential={credential}
                  companyId={companyId}
                />
                <CredentialInfo legacy={!!legacy} />
              </div>
            </div>
          );
        })}
      <Modal
        isOpen={isConfirmModalOpen}
        className="modal-delete"
        modalLabel="Are you sure you want to delete your credential?"
        confirmLabel="Delete"
        onConfirm={handleDeleteClick}
        onCancel={() => setConfirmModalOpen(false)}
      >
        <div className="modal-delete-content">
          <p className="modal-delete-info">
            {`By clicking 'Delete', the authentication credentials for ` +
              `'${selectedClientName.current}' will become inactive. You cannot undo this action.`}
          </p>
        </div>
      </Modal>
    </div>
  );
};

CredentialsList.propTypes = {
  credentials: PropTypes.arrayOf(PropTypes.object),
  dispatch: PropTypes.shape({
    fetchCredentials: PropTypes.func,
    deleteCredential: PropTypes.func,
  }),
};

CredentialsList.defaultProps = {
  credentials: [],
  dispatch: {
    fetchCredentials: () => {},
    deleteCredential: () => {},
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(CredentialsList);
