/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import PropTypes from 'prop-types';
import { abbrevToK } from '../../../common/helpers';
import './RangeSlider.scss';

const getPercentage = (current, max) => (100 * current) / max;

const getValue = (percentage, max) => (max / 100) * percentage;

const getLeftPos = percentage => `calc(${percentage}% - 5px)`;

const normaliseValue = (value, increments) =>
  Math.ceil(value / increments) * increments;

const BillingSlider = ({ defaultValue, increments, min, max, onChange }) => {
  const initialPct = getPercentage(
    normaliseValue(defaultValue, increments),
    max,
  );
  const sliderRef = React.useRef();
  const thumbRef = React.useRef();
  const currentRef = React.useRef();
  const diff = React.useRef();

  const handleMouseMove = e => {
    const start = 0;
    const end = sliderRef.current.offsetWidth - thumbRef.current.offsetWidth;

    let newX =
      e.clientX - diff.current - sliderRef.current.getBoundingClientRect().left;
    if (newX < start) newX = 0;
    if (newX > end) newX = end;

    const newPct = getPercentage(newX, end);
    const newValue = normaliseValue(getValue(newPct, max), increments);
    thumbRef.current.style.left = getLeftPos(newPct);
    currentRef.current.textContent = newValue < min ? min : abbrevToK(newValue);
    onChange(newValue < min ? min : newValue);
  };

  const handleMouseUp = () => {
    document.removeEventListener('mouseup', handleMouseUp);
    document.removeEventListener('mousemove', handleMouseMove);
  };

  const handleMouseDown = e => {
    diff.current = e.clientX - thumbRef.current.getBoundingClientRect().left;
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <div className="range-slider">
      <div className="range-slider__label">
        Slide to estimate your monthly spend
      </div>
      <div className="range-slider__bar" ref={sliderRef}>
        <span
          className="range-slider__thumb"
          style={{ left: getLeftPos(initialPct) }}
          ref={thumbRef}
          onMouseDown={handleMouseDown}
          tabIndex="0"
        />
      </div>
      <div className="range-slider__desc">
        <span ref={currentRef}>{abbrevToK(defaultValue)}</span> / 30k pages
      </div>
    </div>
  );
};

BillingSlider.propTypes = {
  defaultValue: PropTypes.number,
  increments: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
};

BillingSlider.defaultProps = {
  defaultValue: PropTypes.number,
  increments: 1,
  min: 0,
  max: 30000,
  onChange: () => {},
};

export default BillingSlider;
