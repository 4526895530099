import { mergeRight as merge } from 'ramda';
import DocsService from '../services/DocsService';

export const REQUEST_UPLOAD_DOC = 'sypht/tasks/REQUEST_UPLOAD_DOC';
export const RECEIVE_UPLOAD_DOC = 'sypht/tasks/RECEIVE_UPLOAD_DOC';
export const RECEIVE_UPLOAD_DOC_STATUS =
  'sypht/tasks/RECEIVE_UPLOAD_DOC_STATUS';

export const REQUEST_DOC_RESULTS = 'sypht/tasks/REQUEST_DOC_RESULTS';
export const RECEIVE_DOC_RESULTS = 'sypht/tasks/RECEIVE_DOC_RESULTS';

export const CLEAR_FILE = 'sypht/tasks/CLEAR_FILE';

export const REQUEST_DOC_PROCESSED = 'sypht/upload/REQUEST_DOC_PROCESSED';
export const RECEIVE_DOC_PROCESSED = 'sypht/upload/RECEIVE_DOC_PROCESSED';
export const RESET_DOC_PROCESSED = 'sypht/upload/RESET_DOC_PROCESSED';

const initial = {
  upload: null,
  loading: false,
  results: null,
  status: null,
  task: null,
  uploadStartTime: null,
  receiveDocUploadTime: null,
  resultsFetchedTime: null,
  active: {
    docId: null,
    taskId: null,
    processed: false,
  },
};

export default function uploadReducer(state = initial, action) {
  switch (action.type) {
    case REQUEST_UPLOAD_DOC:
      return merge(state, {
        loading: true,
        uploadStartTime: new Date().getTime(),
        receiveDocUploadTime: null,
        resultsFetchedTime: null,
        file: action.file.file,
        status: null,
        upload: null,
        results: null,
        task: null,
      });

    case CLEAR_FILE:
      return initial;

    case RECEIVE_UPLOAD_DOC:
      return merge(state, {
        upload: action.data,
        receiveDocUploadTime: new Date().getTime(),
        loading: false,
        status: 'RECEIVED',
      });

    case RECEIVE_UPLOAD_DOC_STATUS:
      if (state.upload.fileId === action.docId) {
        return merge(state, {
          status: action.status,
        });
      }
      return state;

    case REQUEST_DOC_RESULTS:
      return merge(state, {
        loading: true,
      });

    case RECEIVE_DOC_RESULTS:
      return merge(state, {
        loading: false,
        results: action.data ? action.data.fields : null,
        resultsFetchedTime: new Date().getTime(),
      });

    case REQUEST_DOC_PROCESSED: {
      const { docId, taskId, processed } = action.data;
      return merge(state, {
        active: {
          docId: docId || state.active.docId,
          taskId: taskId || state.active.taskId,
          processed: processed || state.active.processed,
        },
      });
    }
    case RECEIVE_DOC_PROCESSED:
      return merge(state, {
        active: {
          ...state.active,
          processed: true,
        },
      });

    case RESET_DOC_PROCESSED:
      return merge(state, {
        active: {
          ...state.active,
          processed: false,
        },
      });
    default:
      return state;
  }
}

export const clearFile = () => ({ type: CLEAR_FILE });

export const receiveUploadDocStatus = (docId, status) => ({
  type: RECEIVE_UPLOAD_DOC_STATUS,
  docId,
  status,
});

export const requestUploadDoc = file => ({ type: REQUEST_UPLOAD_DOC, file });
export const receiveUploadDoc = (data, error) => ({
  type: RECEIVE_UPLOAD_DOC,
  data,
  error,
});

export const requestDocResults = () => ({ type: REQUEST_DOC_RESULTS });
export const receiveDocResults = (data, error) => ({
  type: RECEIVE_DOC_RESULTS,
  data,
  error,
});

export const requestDocProcessed = data => ({
  type: REQUEST_DOC_PROCESSED,
  data,
});

export const receivedDocProcessed = (data, error) => ({
  type: RECEIVE_DOC_PROCESSED,
  data,
  error,
});

export const resetDocProcessed = (data, error) => ({
  type: RESET_DOC_PROCESSED,
  data,
  error,
});

export function onReceiveUploadDocStatus(docId, companyId, status) {
  const ret = (dispatch, getState) => {
    let result;
    const state = getState();
    result = dispatch(
      receivedDocProcessed({
        docId,
      }),
    );
    if (
      state.upload &&
      state.upload.upload &&
      state.upload.upload.fileId === docId
    ) {
      result = dispatch(receiveUploadDocStatus(docId, status));
      if (status === 'RESULTFINALISED') {
        const token = state.auth.accessToken;
        result = dispatch(requestDocResults());
        return DocsService.getDocResults(token, docId, companyId)
          .then(r => {
            dispatch(receiveDocResults(r.data, null));
          })
          .catch(e => dispatch(receiveDocResults(null, e)));
      }
    }
    return result;
  };
  return ret;
}

export function uploadDoc(fileObj) {
  const ret = (dispatch, getState) => {
    const token = getState().auth.accessToken;
    dispatch(requestUploadDoc(fileObj));
    return DocsService.uploadDoc(token, fileObj)
      .then(r => {
        dispatch(receiveUploadDoc(r.data, null));
      })
      .catch(e => {
        dispatch(receiveUploadDoc(null, e));
      });
  };
  return ret;
}
