import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BillingSlider } from '../../../UI/RangeSlider';
import { setVisibleTiers } from '../../helpers';
import './BillingCalculator.scss';

const MAX_THRESHOLD = 30000;

const BillingCalculator = ({ pricing, accessFee }) => {
  if (!pricing || !pricing.tiers || pricing.tiers.length <= 0) return null;

  const { tiers } = pricing;
  const visibleTiers = setVisibleTiers(pricing.tiers);

  const minSpend = accessFee || tiers[0].flatPrice || 0;
  const [monthlySpend, setMonthlySpend] = useState(minSpend);
  const minThreshold =
    pricing.tiers.length > visibleTiers.length ? tiers[0].threshold : 0;

  const handleSliderChange = noOfUnits => {
    const activeTier = visibleTiers.reduce((t, next) => {
      if (noOfUnits >= t.from && noOfUnits <= t.to) {
        return t;
      }
      return next;
    }, visibleTiers[0]);

    let totalSpend = 0;
    visibleTiers.forEach(t => {
      if (noOfUnits >= t.from) {
        totalSpend += t.basePrice;
      }
    });
    totalSpend += (noOfUnits - activeTier.last) * activeTier.unitPrice;

    if (totalSpend > minSpend) setMonthlySpend(totalSpend + accessFee);
    else setMonthlySpend(minSpend);
  };

  return (
    <div className="billing-calculator">
      <div className="billing-calculator__row">
        <dl className="monthly-price">
          <dd className="monthly-price__label">
            {minSpend >= monthlySpend ? 'From' : 'Estimated monthly bill'}
          </dd>
          <dt className="monthly-price__amount">
            <span className="monthly-price__amount-prefix">$</span>
            <span className="monthly-price__amount-value">
              {Math.round(monthlySpend).toCommaFormat() || 0}
            </span>
            <span className="monthly-price__amount-unit">/ mth</span>
          </dt>
        </dl>
        <BillingSlider
          defaultValue={minThreshold}
          min={minThreshold}
          max={MAX_THRESHOLD}
          increments={100}
          precision={0}
          onChange={handleSliderChange}
        />
      </div>
    </div>
  );
};

BillingCalculator.propTypes = {
  pricing: PropTypes.object,
  accessFee: PropTypes.number,
};

BillingCalculator.defaultProps = {
  pricing: null,
  accessFee: null,
};

export default BillingCalculator;
