import { useEffect } from 'react';

/**
 * Generate chart js legend container and add click event
 * to hide legend
 */
export const generateLegend = ({ chartId, chartRef, data }) => {
  useEffect(() => {
    const jsLegend = document.getElementById(chartId);
    if (chartRef.current && jsLegend) {
      document.getElementById(chartId).innerHTML =
        chartRef.current.chartInstance.generateLegend();
      const legends = document.getElementById(chartId).querySelectorAll('li');
      for (let i = 0; i < legends.length; i++) {
        const legend = legends[i];
        legend.addEventListener('click', function () {
          legend.classList.toggle('strike');
          const ci = chartRef.current.chartInstance;
          const alreadyHidden =
            ci.getDatasetMeta(i).hidden === null
              ? false
              : ci.getDatasetMeta(i).hidden;
          ci.data.datasets.forEach(function (e, index) {
            const meta = ci.getDatasetMeta(index);
            if (index === i) {
              if (!alreadyHidden) {
                meta.hidden = true;
              } else {
                meta.hidden = null;
              }
            }
          });

          ci.update();
        });
      }
    }
  }, [data]);
};
