import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { FaChevronLeft, FaLink, FaBan } from 'react-icons/fa';
import _ from 'lodash';

import './_style.scss';

import EntityService from '../../../services/EntityService';

const mapStateToProps = (state, props) => {
  const { auth } = state;
  return {
    accessToken: auth.accessToken || null,
  };
};

const EntityAnnotation = ({
  field,
  editable,
  saveAnnotation,
  annotation,
  prediction,
  accessToken,
  setPredictionFocus,
  inline = false,
}) => {
  const { model } = field;
  const { referent } = model;
  const { company_id, entity_type } = referent;
  const [editMode, setEditMode] = useState(false);
  const [searchMode, setSearchMode] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [displayAttributes, setDisplayAttributes] = useState([]);
  const [focusPrediction, setFocusPrediction] = useState(null);

  const onSaveAnnotation = a => {
    a.id = field.id;
    saveAnnotation(a);
    setEditMode(false);
    setFocusPrediction(null);
  };

  const onCancelEdit = () => {
    setEditMode(false);
    setFocusPrediction(null);
  };

  const onConfirmSearchEntitySelection = e => {
    if (e) {
      e.preventDefault();
    }
    setSearchMode(false);
    onSaveAnnotation({
      source: {
        type: 'entity-selection',
        referent,
        selection: selectedEntity,
      },
      data: {
        value: {
          id: selectedEntity.value,
          type: entity_type,
          data: selectedEntity.data,
        },
      },
    });
  };

  const onConfirmPredictedEntitySelection = prediction => {
    onSaveAnnotation({
      source: {
        type: 'entity-prediction',
        referent,
        prediction,
      },
      data: {
        value: prediction.value,
      },
    });
  };

  const getEntity = entityId => {
    return EntityService.getEntity(
      accessToken,
      company_id,
      entity_type,
      entityId,
    )
      .then(({ data }) => data)
      .catch(e => console.log(e));
  };

  const searchEntities = (queryData, isSearchExact) => {
    const fuzzy = isSearchExact ? {} : queryData;
    const exact = isSearchExact ? queryData : {};

    return EntityService.searchEntities(
      accessToken,
      company_id,
      entity_type,
      fuzzy,
      exact,
    )
      .then(({ data }) =>
        Promise.all(data.map(s => s.id).map(eid => getEntity(eid))).then(
          entities =>
            entities.map((entity, i) => ({
              id: data[i].id,
              score: data[i].score,
              data: entity,
            })),
        ),
      )
      .catch(e => console.log(e));
  };

  useEffect(() => {
    // todo: replicate normalisation params
    if (field.attributes) {
      setDisplayAttributes(
        Object.keys(field.attributes).map(key => ({
          key,
          name: field.attributes[key].name || key,
          maxLength: Math.max(
            ...dropdownOptions.map(o => (o.data[key] || '').toString().length),
          ),
          lookup: model.candidates.lookups.find(
            l =>
              (l.type == 'field' || l.type == 'columns') && l.reference == key,
          ),
        })),
      );
    } else {
      setDisplayAttributes(
        model.candidates.lookups
          .filter(l => l.type == 'field' || l.type == 'columns')
          .map(l => ({
            key: l.reference,
            name: l.reference,
            maxLength: Math.max(
              ...dropdownOptions.map(
                o => (o.data[l.reference] || '').toString().length,
              ),
            ),
            lookup: l,
          })),
      );
    }
  }, [dropdownOptions]);

  // reset the task global prediction focus
  useEffect(() => {
    setPredictionFocus(focusPrediction);
  }, [focusPrediction]);

  const loadOptions = (inputValue, callback) => {
    const queryData = {};
    const isSearchExact = inputValue.includes(':');

    if (isSearchExact) {
      const split = inputValue.split(':');
      queryData[`${split[0]}`] = split[1];
    } else {
      model.candidates.lookups
        .filter(l => l.type == 'field' || l.type == 'columns')
        .map(l => {
          queryData[l.reference] = l.uppercase
            ? inputValue.toUpperCase()
            : inputValue;
        });
    }

    searchEntities(queryData, isSearchExact).then(searchResults => {
      const entityResults = (searchResults || []).map(se => ({
        value: se.id,
        label: se.id,
        score: se.score,
        data: se.data,
      }));
      setDropdownOptions(entityResults);
      callback(entityResults);
    });
  };

  const Option = props => {
    return (
      <>
        <components.Option key={props.value} {...props}>
          {renderEntity(props.value, props.data.data, 'entity-option-value')}
        </components.Option>
      </>
    );
  };

  const longestOptionAttribute = Math.max(
    ...displayAttributes
      .filter(da => !!da.name)
      .map(da =>
        Math.max(
          da.name.length + da.maxLength,
          da.name.length + entity_type.length,
        ),
      ),
  );

  const showEditMode = editable && (editMode || !annotation);

  // trim predictions, making sure not present is always an option
  const predictions = (prediction || []).slice(0, 4);
  if (!predictions.find(p => p.value == null)) {
    predictions.pop();
    predictions.push({
      field: field.id,
      value: null,
      confidence: null,
    });
  }

  const renderEntity = (entityId, data, containerClassName) => {
    return (
      <div className={`entity-value ${containerClassName}`}>
        <div className="entity-id-container">
          <FaLink />
          <span className="entity-id">
            {entity_type} /{entityId}
          </span>
        </div>
        <div className="entity-attributes">
          {displayAttributes
            .filter(da => !!da.name)
            .map((da, i) => (
              <div key={i} className="entity-attribute">
                <div className="entity-attribute-key">{da.name}</div>
                <div className="entity-attribute-value">
                  {data[da.key] || 'null'}
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  };

  return (
    <div className="field-annotation-instance entity" tabIndex="0">
      {!showEditMode && annotation ? (
        <div className="field-value">
          {annotation.data.value == null ? (
            <span className="null-value">Not present</span>
          ) : (
            <div className="entity-value-container">
              {renderEntity(
                annotation.data.value.id,
                annotation.data.value.data,
                'entity-annotated-value',
              )}
            </div>
          )}
          {editable ? (
            <div className="button-div edit" onClick={() => setEditMode(true)}>
              <span>Edit</span>
            </div>
          ) : null}
        </div>
      ) : null}
      {showEditMode && searchMode ? (
        <div className="field-input manual-select">
          <div className="button-div back" onClick={e => setSearchMode(false)}>
            <FaChevronLeft /> <span>Back</span>
          </div>
          <div className="label">Search entities</div>
          <AsyncSelect
            menuPortalTarget={!inline && document.getElementById('portal')}
            styles={{
              menuPortal: base => ({
                ...base,
                zIndex: 9999,
                width: `${Math.min(
                  960,
                  Math.max(longestOptionAttribute * 10 + 20, 270),
                )}px`,
              }),
            }}
            value={selectedEntity}
            placeholder={`Enter ${displayAttributes
              .map(da => `${da.name}`)
              .join(' or ')}...`}
            menuPosition={!inline && 'fixed'}
            onChange={c => setSelectedEntity(c)}
            loadOptions={_.debounce(loadOptions, 200)}
            defaultOptions={dropdownOptions}
            isClearable
            classNamePrefix="entity-field--dropdown"
            className="entity-field--dropdown"
            components={{
              IndicatorSeparator: () => null,
              Option,
            }}
            onKeyDown={e => e.stopPropagation()}
          />
          <form onSubmit={e => onConfirmSearchEntitySelection(e)}>
            <div className="button-div reset">
              <span />
            </div>
            <div>
              <button type="submit" disabled={selectedEntity == null}>
                Confirm
              </button>
            </div>
          </form>
        </div>
      ) : showEditMode ? (
        <>
          <div className="prediction-list">
            {predictions
              .filter(p => p)
              .map((prediction, i) => (
                <div
                  key={i}
                  className={`prediction${
                    focusPrediction && focusPrediction.value == prediction.value
                      ? ' focus'
                      : ''
                  }${prediction.value == null ? ' null' : ''}`}
                  onClick={() =>
                    focusPrediction && focusPrediction == prediction
                      ? setFocusPrediction(null)
                      : setFocusPrediction(prediction)
                  }
                >
                  <div className="value">
                    {prediction.value == null ? (
                      <span>
                        <FaBan /> Not present
                      </span>
                    ) : (
                      <div className="entity">
                        {renderEntity(
                          prediction.value.id,
                          prediction.value.data,
                          'entity-prediction-value',
                        )}
                      </div>
                    )}
                  </div>
                  <div className="confidence">
                    {`${String(Math.round(100 * prediction.confidence))}%`}
                  </div>
                  <button
                    className="select"
                    onClick={() =>
                      onConfirmPredictedEntitySelection(prediction)
                    }
                  >
                    Confirm
                  </button>
                </div>
              ))}
          </div>
          <div className="field-input-select">
            <div className="button-div" onClick={() => setSearchMode(true)}>
              Search entities
            </div>
            {annotation ? (
              <div className="button-div cancel" onClick={() => onCancelEdit()}>
                Cancel
              </div>
            ) : null}
          </div>
        </>
      ) : null}
    </div>
  );
};

EntityAnnotation.propTypes = {
  field: PropTypes.shape({
    annotated: PropTypes.bool,
    description: PropTypes.string,
    id: PropTypes.string.isRequired,
    model: PropTypes.shape({
      referent: PropTypes.shape({
        company_id: PropTypes.string.isRequired,
        entity_type: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(EntityAnnotation);
