import { addError, removeError } from './index';

const validateMatch = ({ elem, errors, setErrors }, match) => {
  const { name: key, value } = elem;
  const message = `Passwords do not match.`;
  const isValid = (confirmPassword, newPassword) => {
    return confirmPassword === newPassword;
  };

  if (!isValid(value, match)) {
    addError(key, message, errors, setErrors);
    return {
      isValid: false,
    };
  }

  removeError(key, message, errors, setErrors);
  return {
    isValid: true,
  };
};

export default validateMatch;
