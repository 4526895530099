import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getQueryStr } from '../../common/helpers';
import { TabsContainer } from '../UI/Tabs';

import { listProducts, retrieveDoc } from './store/actions';
import { CaptureData, CaptureDoc, CaptureMenu } from './components';
import './_capture.scss';

const getProduct = (products, productId) => {
  if (!products || !products.length) return undefined;
  if (productId) return products.find(p => p.productId === productId);
  return products[0];
};

const mapStateToProps = (state, props) => {
  const { docs, products } = state.capture;
  const { history, match } = props;

  return {
    docId: match.params.docId,
    productId: getQueryStr(history.location.search, 'ai'),
    doc: docs[match.params.docId] || null,
    products: products.data || null,
    match,
    isLoading: docs.loading || products.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      listProducts: () => dispatch(listProducts()),
      retrieveDoc: docId => dispatch(retrieveDoc(docId)),
    },
  };
};

const Capture = ({
  docId,
  productId,
  doc,
  products,
  match,
  isLoading,
  dispatch,
}) => {
  const extractedProducts = doc
    ? (products || []).filter(p =>
        Object.keys(doc.productFieldMapping).includes(p.name),
      )
    : [];

  const product = getProduct(extractedProducts, productId);

  useEffect(() => {
    if (!products) {
      dispatch.listProducts();
    }
    if (!doc || doc.isRefresh) {
      dispatch.retrieveDoc(docId);
    }
  }, [docId]);

  return !isLoading ? (
    <TabsContainer className="capture" defaultTab={productId} isNav>
      <div className="capture__header">
        <CaptureMenu match={match} products={extractedProducts} />
      </div>
      <div className="capture__body">
        <div className="capture__aside">
          <CaptureData doc={doc} product={product} />
        </div>
        <div className="capture__main">
          <CaptureDoc doc={doc} product={product} />
        </div>
      </div>
    </TabsContainer>
  ) : null;
};

Capture.propTypes = {
  docId: PropTypes.string,
  productId: PropTypes.string,
  doc: PropTypes.object,
  products: PropTypes.arrayOf(PropTypes.object),
  match: PropTypes.object,
  isLoading: PropTypes.bool,
  dispatch: PropTypes.shape({
    listProducts: PropTypes.func,
    retrieveDoc: PropTypes.func,
  }),
};

Capture.defaultProps = {
  docId: null,
  productId: null,
  doc: null,
  products: null,
  match: {},
  isLoading: false,
  dispatch: {
    listProducts: () => {},
    retrieveDoc: () => {},
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(Capture);
