/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import UserList, { User } from '../../../../components/UI/UserList';

const ContributorsCell = ({ row }) => {
  const task = row.original;
  const placeholders = new Array(
    Math.max(0, task.replication - task.annotators.length),
  );

  return (
    <UserList>
      {(task.annotators || []).map(a => {
        const name = `${(a.user || {}).firstName} ${
          (a.user || {}).lastName
        }`.trim();
        const action = a.state ? a.state : 'annotated';
        const fromWhen = moment(a.updated).fromNow();
        return (
          <NavLink
            className="user-link"
            to={`tasks/${task.id}/user/${a.user.id}`}
            key={`user|${task.id}|${a.user.id}|${task.annotators.indexOf(a)}`}
            onClick={e => e.stopPropagation()}
            data-tooltip-id="tasks-tooltip"
            data-tooltip-content={`${name} - ${action} ${fromWhen}`}
          >
            <User user={a.user} />
          </NavLink>
        );
      })}
      {(placeholders || []).fill().map((p, i) => {
        return (
          <div
            className="placeholder"
            key={`user|${task.id}|placeholder|${i}`}
          />
        );
      })}
    </UserList>
  );
};

ContributorsCell.propTypes = {
  row: PropTypes.object.isRequired,
};

export default ContributorsCell;
