import * as React from 'react';
import './_icons.scss';

function DocumentSplitParent(props) {
  const svg = (
    <svg
      width={36}
      height={36}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={18}
        cy={18}
        r={17}
        fill="#fff"
        stroke="#B7DDA2"
        strokeWidth={2}
      />
      <path
        d="M17.5 25.25h-6a.75.75 0 0 1-.75-.75v-9a.75.75 0 0 1 .75-.75h3.879a.75.75 0 0 1 .53.22l1.06 1.06 1.061 1.061c.141.14.22.331.22.53V24.5a.75.75 0 0 1-.75.75Z"
        fill="#fff"
        stroke="#3C3C3B"
        strokeWidth={1.5}
      />
      <path
        d="M21 23.25h-6a.75.75 0 0 1-.75-.75v-9a.75.75 0 0 1 .75-.75h3.879a.75.75 0 0 1 .53.22l1.06 1.06 1.061 1.061c.141.14.22.331.22.53V22.5a.75.75 0 0 1-.75.75Z"
        fill="#fff"
        stroke="#3C3C3B"
        strokeWidth={1.5}
      />
      <path
        d="M24.5 21.25h-6a.75.75 0 0 1-.75-.75v-9a.75.75 0 0 1 .75-.75h3.879a.75.75 0 0 1 .53.22l1.06 1.06 1.061 1.061c.141.14.22.331.22.53V20.5a.75.75 0 0 1-.75.75Z"
        fill="#fff"
        stroke="#3C3C3B"
        strokeWidth={1.5}
      />
      <path d="M21.5 10.5v3a1 1 0 0 0 1 1h3" stroke="#3C3C3B" />
    </svg>
  );

  return <div className="sypht-icon__container">{svg}</div>;
}

export default DocumentSplitParent;
