import React from 'react';
import PropTypes from 'prop-types';
import './_documentOption.scss';

const DocumentOption = ({ name, id }) => {
  return (
    <div className="document-option">
      <span className="document-option__name">{name}</span>
      <span className="document-option__id">{id}</span>
    </div>
  );
};

DocumentOption.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default DocumentOption;
