import axios from 'axios';

const getHeaders = accessToken => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }
  return headers;
};

/** Usage:
 * GET: util.getData('/app/user/{userId}?p1={p1}&p2={p2}&{...}', {
 *   accessToken,
 *   p1: 'optional param 1',
 *   p2: 'optional param 2',
 *   { ... }
 * })
 * */
export const getData = (url, { accessToken, ...params }) => {
  const headers = getHeaders(accessToken);
  return axios({
    method: 'GET',
    url,
    headers,
    params,
  });
};

/** Usage:
 * PUT: util.putData('/app/user/{userId}?p1={p1}&p2={p2}&{...}', {
 *   accessToken,
 *   data: { ... }
 *   p1: 'optional param 1',
 *   p2: 'optional param 2'
 *   { ... }
 * })
 * */
export const putData = (url, { accessToken, data, ...params }) => {
  const headers = getHeaders(accessToken);
  return axios({
    method: 'PUT',
    url,
    headers,
    data,
    params,
  });
};

/** Usage:
 * POST: util.putData('/app/user?p1={p1}&p2={p2}&{...}', {
 *   accessToken,
 *   data: { ... }
 *   p1: 'optional param 1',
 *   p2: 'optional param 2'
 *   { ... }
 * })
 * */
export const postData = (url, { accessToken, data, ...params }) => {
  const headers = getHeaders(accessToken);
  return axios({
    method: 'POST',
    url,
    headers,
    data,
    params,
  });
};

/** Usage:
 * DELETE: util.putData('/app/user?p1={p1}&p2={p2}&{...}', {
 *   accessToken,
 *   data: { ... }
 *   p1: 'optional param 1',
 *   p2: 'optional param 2'
 *   { ... }
 * })
 * */
export const deleteData = (url, { accessToken, data, ...params }) => {
  const headers = getHeaders(accessToken);
  return axios({
    method: 'DELETE',
    url,
    headers,
    data,
    params,
  });
};
