import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DocsFilter from './DocsFilter';
import CompanyFilter from './CompanyFilter';
import StateFilter from './StateFilter';
import { SplitStateLabelMap } from '../../../../common/lookups/splitStatesLookup';
import DropdownList from '../../../../library/atoms/DropdownList/DropdownList';
import DropdownListItem from '../../../../library/atoms/DropdownListItem/DropdownListItem';
import Dropdown from '../../../../library/molecules/Dropdown/Dropdown';
import TagsDropdownMenu from '../../../../library/molecules/TagsDropdownMenu/TagsDropdownMenu';
import {
  fetchFilterTags,
} from '../../../../reducers/tags/action';
import './FilterGroup.scss';
import { getByDisplayValue } from '@testing-library/react';

const LOGIC_OPTIONS = [
  { value: true, label: 'Includes' },
  { value: false, label: 'Excludes' },
];
const initialTagFilters = {
  limit:100,
  includeCounts:true,
  sortDirection:'desc',
  sortColumn:'created'
}

const mapStateToProps = state => {
  const { auth, tags } = state;

  return {
    companyId: auth.user.data ? auth.user.data.companyId : null,
    filterTags: tags.filterTags.data,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch: {
    fetchFilterTags: (companyId, params) => dispatch(fetchFilterTags(companyId, params)),
  },
});

const FilterGroup = ({
  filters,
  dispatch,
  handleFilterChange,
  filenameRef,
  isShowCompanyFilter,
  companies,
  handleCompanyChange,
  applyStateFilters,
  companyId,
  filterTags,
  
}) => {
  const splitStateDefaultLabel = 'Split State';
  const [tabsDropdownOpen, setTabsDropdownOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [splitStateDropdownPlaceholder, setSplitStateDropdownPlaceholder] =
    useState(splitStateDefaultLabel);

  const [tagsDropdownPlaceholder, setTagsDropdownPlaceholder] =
    useState('Tags');

  const [tagSearch, setTagSearch] = useState(null);

  const tagSelections = useMemo(
    () => selectedTags.length > 0 ? selectedTags.sort().join(',') : null,
    [selectedTags],
  )

  useEffect(() => {
    if(companyId){
      let {sortColumn, sortDirection} = initialTagFilters;
      let tagName = null;
      if(tagSearch && tagSearch !== ''){
        tagName = tagSearch;
        sortColumn = 'name';
        sortDirection = 'asc';
      }
      dispatch.fetchFilterTags(companyId, {
        ...initialTagFilters, 
        name: tagName, 
        selections: tagSelections, 
        sortColumn, 
        sortDirection}
      );


      //dispatch.fetchFilterTags(companyId, filterTagParams);
    }
  }, [companyId, selectedTags, tagSearch, dispatch, initialTagFilters]);

  const onTagsConfirmed = selected => {
    setSelectedTags(selected);
    if (selected.length > 0) {
      handleFilterChange({ name: 'tags', value: selected.join(',') }, filters);
    } else {
      handleFilterChange({ name: 'tags', value: null }, filters);
      setTagsDropdownPlaceholder('Tags');
    }

    setTabsDropdownOpen(false);
  };

  const onTagsClear = () => {
    setSelectedTags([]);
    //setFilterTagParams({...filterTagParams, selections: null});
    handleFilterChange({ name: 'tags', value: null }, filters);
    setTagsDropdownPlaceholder('Tags');
    
  };

  const onFiltersTagsChanged = () => {
    if (filters.tags) {
      if (filters.tags.length > 0) {
        const splitTags = filters.tags.split(',');
        setSelectedTags(splitTags);

        if (splitTags.length === 1) {
          setTagsDropdownPlaceholder('1 Tag');
        } else {
          setTagsDropdownPlaceholder(`${splitTags.length} Tags`);
        }
        //setFilterTagParams({...filterTagParams, selections: filters.tags});
      } else {
        setSelectedTags([]);
        setTagsDropdownPlaceholder('Tags');
        //setFilterTagParams({...filterTagParams, selections: null});
      }
    }
  };
  const onTagSearch = (tagName) => {
    setTagSearch(tagName)
  };

  const onFiltersSplitStateChanged = () => {
    if (filters.splitState) {
      setSplitStateDropdownPlaceholder(SplitStateLabelMap[filters.splitState]);
    } else {
      setSplitStateDropdownPlaceholder(splitStateDefaultLabel);
    }
  };

  useEffect(() => {
    if (filters) {
      onFiltersTagsChanged();
      onFiltersSplitStateChanged();
    }
  }, [filters]);

  const onSplitStateDropdownListItemClicked = state => {
    handleFilterChange({ name: 'splitState', value: state }, filters);
  };
  const onSplitStateDropdownListCleared = () => {
    handleFilterChange({ name: 'splitState', value: null }, filters);
  };
  const renderSplitStateDropdownListItems = () =>
    Object.entries(SplitStateLabelMap).map(entry => (
      <DropdownListItem
        onClick={() => {
          onSplitStateDropdownListItemClicked(entry[0]);
        }}
        key={entry[0]}
      >
        {entry[1]}
      </DropdownListItem>
    ));

  return (
    <>
      <span className="search__divider" />
      <div className="search__filters">
        <DocsFilter
          label="Filename"
          className="search__filter"
          name="filename"
          value={filters.filename}
          filters={filters}
          onChange={handleFilterChange}
          forwardRef={filenameRef}
        />
        <Dropdown
          type="select"
          className="search__filter"
          placeholder={tagsDropdownPlaceholder}
          isOpen={tabsDropdownOpen}
          onClick={(e, state) => {
            setTabsDropdownOpen(state);
          }}
          onBlur={() => {
            setTabsDropdownOpen(false);
          }}
          onClear={() => {
            onTagsClear();
          }}
          hasValue={selectedTags.length > 0}
          label={tagsDropdownPlaceholder}
        >
          <TagsDropdownMenu
            isOpen={tabsDropdownOpen}
            tags={filterTags ?? []}
            onConfirm={onTagsConfirmed}
            selectedTags={selectedTags}
            onSearch={onTagSearch}
          />
        </Dropdown>

        <Dropdown
          type="select"
          className="search__filter--split-state"
          placeholder={splitStateDropdownPlaceholder}
          label={splitStateDropdownPlaceholder}
          hasValue={filters.splitState}
          onClear={onSplitStateDropdownListCleared}
        >
          <DropdownList>{renderSplitStateDropdownListItems()}</DropdownList>
        </Dropdown>

        {isShowCompanyFilter && companies.length > 0 && (
          <CompanyFilter
            label="Company"
            className="search__filter--company"
            value={{ companyId: filters.companyId }}
            defaultValue={{ companyId: null }}
            options={companies.map(company => ({
              label: company.name,
              value: {
                companyId: company.id,
              },
              companyId: company.id,
              companyName: company.name,
            }))}
            onChange={handleCompanyChange}
          />
        )}
        <span className="search__filter-divider" />
        <StateFilter
          applyStateFilters={applyStateFilters}
          filters={{
            isExtracted:
              // eslint-disable-next-line no-nested-ternary
              filters.isExtracted === 'true'
                ? LOGIC_OPTIONS.find(l => l.value === true)
                : filters.isExtracted === 'false'
                ? LOGIC_OPTIONS.find(l => l.value === false)
                : null,
            isValidated:
              // eslint-disable-next-line no-nested-ternary
              filters.isValidated === 'true'
                ? LOGIC_OPTIONS.find(l => l.value === true)
                : filters.isValidated === 'false'
                ? LOGIC_OPTIONS.find(l => l.value === false)
                : null,
            isExported:
              // eslint-disable-next-line no-nested-ternary
              filters.isExported === 'true'
                ? LOGIC_OPTIONS.find(l => l.value === true)
                : filters.isExported === 'false'
                ? LOGIC_OPTIONS.find(l => l.value === false)
                : null,
          }}
        />
      </div>
    </>
  );
};
FilterGroup.propTypes = {
  filters: PropTypes.shape().isRequired,
  dispatch: PropTypes.shape({
    fetchFilterTags: PropTypes.func,
  }),
  filenameRef: PropTypes.shape().isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  handleCompanyChange: PropTypes.func.isRequired,
  applyStateFilters: PropTypes.func.isRequired,
  isShowCompanyFilter: PropTypes.bool,
  companies: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  companyId: PropTypes.string,
  companyTags: PropTypes.arrayOf(PropTypes.shape({})),
};

FilterGroup.defaultProps = {
  isShowCompanyFilter: false,
  companyId: null,
  companyTags: [],
  dispatch: {
    fetchFilterTags: () => {},
  },
};
export default connect(mapStateToProps, mapDispatchToProps)(FilterGroup);
