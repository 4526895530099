import { mergeRight as merge } from 'ramda';

export const REQUEST_INGEST = 'sypht/workflows/REQUEST_INGEST';
export const RECEIVE_INGEST_RESULTS = 'sypht/workflows/RECEIVE_INGEST_RESULTS';

const initial = {
  ingesting: false,
};

export default function workflowReducer(state = initial, action) {
  const { payload, type } = action;
  switch (type) {
    case REQUEST_INGEST:
      return merge(state, payload);
    case RECEIVE_INGEST_RESULTS:
      return merge(state, payload);
    default:
      return state;
  }
}
