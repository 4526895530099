import * as utils from './utils';

const apiBaseUrl = process.env.APP_API_HOST_NAME;

class SubscriptionService {
  createProduct(accessToken, product) {
    return utils.postData(`${apiBaseUrl}/app/products`, {
      accessToken,
      data: product,
    });
  }

  getSubscriptions(accessToken, companyId, params) {
    return utils.getData(
      `${apiBaseUrl}/app/company/${companyId}/subscriptions`,
      {
        accessToken,
        ...params,
      },
    );
  }

  subscribeTrial(accessToken, companyId, data) {
    return utils.postData(
      `${apiBaseUrl}/app/company/${companyId}/subscriptions`,
      {
        accessToken,
        data,
      },
    );
  }

  subscribePaid(accessToken, companyId, data) {
    return utils.postData(
      `${apiBaseUrl}/app/company/${companyId}/subscriptions`,
      {
        accessToken,
        data,
      },
    );
  }

  subscribe(accessToken, companyId, data) {
    return utils.postData(
      `${apiBaseUrl}/app/company/${companyId}/subscriptions`,
      {
        accessToken,
        data,
      },
    );
  }

  upgradeTrialSubscription(accessToken, companyId, data) {
    return utils.putData(
      `${apiBaseUrl}/app/company/${companyId}/subscriptions`,
      {
        accessToken,
        data,
      },
    );
  }

  updateSubscription(accessToken, companyId, data) {
    return utils.putData(
      `${apiBaseUrl}/app/company/${companyId}/subscriptions`,
      {
        accessToken,
        data,
      },
    );
  }
}

export default new SubscriptionService();
