import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FixButton from './FixButton';
import * as taskActions from '../../../../reducers/tasks/actions';
import { calcForListView } from './helper';

const mapStateToProps = state => {
  const { auth } = state;
  return {
    userId: auth.userId,
    canWriteTask: auth.permissions.canWriteTask,
  };
};

const mapDispatchToProps = {
  addQuickFix: taskActions.addQuickFix,
};

/**
 * The Fix button for 1) creating tasks or 2) doing a quick fix for use in document
 * list view.
 *
 * The list is assumed to not contain any pending docs.
 */
const DocListFixButton = ({
  addQuickFix,
  selectedDocs,
  noOfSelectedDocs,
  showModal,
  canWriteTask,
  hasValidateProduct,
  ...buttonProps
}) => {
  const {
    isDisabled,
    canDoBoth,
    goToQuickFixImmediately,
    goToTasksImmediately,
    tooltip,
  } = calcForListView({
    hasValidateProduct,
    canWriteTask,
    noOfSelectedDocs,
    selectedDocs,
  });

  const handleQuickFix = () => {
    const docId = Object.keys(selectedDocs)[0];
    const { companyId } = selectedDocs[docId];
    addQuickFix({ docId, companyId });
  };

  const handleAddTask = () => {
    showModal('isTaskModalOpen');
  };

  const handleClick = () => {
    if (goToTasksImmediately) {
      handleAddTask();
      return;
    }
    if (goToQuickFixImmediately) {
      handleQuickFix();
    }
  };

  const handleAction = (event, selectedAction) => {
    if (selectedAction === 'startTask') {
      handleAddTask();
      return;
    }
    if (selectedAction === 'addQuickFix') {
      handleQuickFix();
    }
  };

  return (
    <FixButton
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...buttonProps}
      disabled={isDisabled}
      disablePopover={!canDoBoth}
      onAction={handleAction}
      onClick={handleClick}
      tooltip={tooltip}
    />
  );
};

DocListFixButton.propTypes = {
  addQuickFix: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  noOfSelectedDocs: PropTypes.number.isRequired,
  selectedDocs: PropTypes.shape({}).isRequired,
  canWriteTask: PropTypes.bool.isRequired,
  hasValidateProduct: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocListFixButton);
