import React, { useContext } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import AppContext from '../../../App/AppContext';
import './CheckoutStep.scss';

const CheckoutStep3 = ({ selectedProduct, onClose }) => {
  const [, setAppState] = useContext(AppContext);

  const handleTourClick = e => {
    e.preventDefault();
    setAppState(x => ({ ...x, isTourOpen: true }));
    onClose();
  };

  return (
    <div className="checkout-step">
      <div className="checkout-step__inner">
        <div className="checkout-step__confirmation">
          <h3 className="checkout-step__heading">Subscription Unlocked! </h3>
          <div className="checkout-step__confirmation-icon">
            <FaCheckCircle />
          </div>
          <p>
            You can manage your subscriptions & billing in{' '}
            <Link to="/marketplace/subscriptions">My Subscriptions</Link>.
          </p>
          <p>
            New user? Our{' '}
            <a href="#virtual_tour" onClick={e => handleTourClick(e)}>
              virtual tour
            </a>{' '}
            will guide you through the process of uploading your document and
            running a data extraction.
          </p>
          <p>
            Already a user? Go directly to your{' '}
            <Link to="/documents">documents</Link> to begin uploading and
            extracting
            {selectedProduct.name}.
          </p>
        </div>
      </div>
    </div>
  );
};

CheckoutStep3.propTypes = {
  selectedProduct: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CheckoutStep3;
