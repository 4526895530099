/* eslint-disable react/sort-comp */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-spread */
/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { push } from 'connected-react-router';
import DocumentView from '../DocumentView/DocumentView';
import AnnotationList from './AnnotationList';
import DocumentToolbar from '../UI/DocumentToolbar/DocumentToolbar';
import DocumentToolbarContainer from '../UI/DocumentToolbar/DocumentToolbarContext';
import TableContextProvider from '../DocumentView/TableContext';

import './Task.scss';
import {
  fetchTaskAndPreAllocateUser,
  saveUserAnnotationData,
  saveUserAnnotationState,
} from '../../reducers/tasks';
import { goToNextTaskUsingStoredFilters } from '../../reducers/nextTask';
import { PageHelmet } from '../Page';

const mapStateToProps = state => {
  return {
    task: state.tasks.active.data ? state.tasks.active.data.task : null,
    error: state.tasks.active.error,
    annotators: state.tasks.active.data
      ? state.tasks.active.data.annotators
      : null,
    doc: state.tasks.active.data ? state.tasks.active.data.doc : null,
    predictions: state.tasks.active.data
      ? state.tasks.active.data.predictions
      : null,
    tokenCandidates: state.tasks.active.data
      ? state.tasks.active.data.candidates
      : null,
    receivedTime: state.tasks.active.data
      ? state.tasks.active.data.receivedTime
      : null,
    contextUserId: state.auth.userId,
    user: state.auth.user,
    loading: state.tasks.active.loading,
    loadingData: state.tasks.active.loadingData,
    canWriteTask: state.auth.permissions.canWriteTask,
    canSuperUserWrite: state.auth.permissions.canSuperUserWrite,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    navigate: path => dispatch(push(path)),
    fetchTaskAndPreAllocateUser: taskId =>
      dispatch(fetchTaskAndPreAllocateUser(taskId)),
    saveUserAnnotationData: (taskId, userId, data) =>
      dispatch(saveUserAnnotationData(taskId, userId, data)),
    saveUserAnnotationState: (taskId, userId, state, flaggedReason) =>
      dispatch(saveUserAnnotationState(taskId, userId, state, flaggedReason)),
    goToNextTaskUsingStoredFilters: () =>
      dispatch(goToNextTaskUsingStoredFilters()),
  };
};

class Task extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.scrollContainer = React.createRef();
  }

  getInitialState = () => {
    const currentTime = new Date().getTime();
    return {
      focusPrediction: null,
      pendingSaveTimeout: null,
      lastSyncTime: currentTime,
      saveIntervalMs: 2500,
      maxDebounceIntervalWaitMs: 0, // todo: re-introduce deadband when all interactions propagate up to defer save
      candidates: {
        created: {},
        selected: {},
        editing: null,
      },
      focus: {
        prediction: null,
        selection: null,
      },
      isShowDocCanvas: null,
    };
  };

  getTaskId(props) {
    return props.match.params.taskId;
  }

  getUserId(props) {
    return props.match.params.userId;
  }

  /*
    Document view candidate interactions. Mediated here as this is a shared
    depdencency between the AnnotationList and DocumentView. Given the complexity,
    this could be factored out into a service with its own section in the state store.
  */
  onAddCandidate = c => {
    const { candidates } = this.state;
    const { selected, created } = candidates;

    // todo: special case here to clear other selections when a new one is added
    //       this should be parameterised by the field specification, e.g. to allow
    //       for multi-bounds selection type fields
    Object.values(selected).map(this.onDeselectCandidate);

    created[c.id] = c;
    this.setState({
      candidates: Object.assign(candidates, {
        created: Object.assign(created, {
          [c.id]: c,
        }),
      }),
    });
    this.onSelectCandidate(c);
  };

  onSelectCandidate = c => {
    const { candidates } = this.state;
    const { selected } = candidates;
    selected[c.id] = c;
    this.setState({
      candidates: Object.assign(candidates, {
        selected,
      }),
    });
  };

  onEditCandidates = cs => {
    const { candidates } = this.state;
    const selected = {};
    cs.forEach(c => {
      selected[c.id] = c;
    });
    this.setState({
      candidates: Object.assign(candidates, {
        selected,
        editing: cs,
      }),
    });
  };

  onDeselectCandidate = c => {
    const { candidates } = this.state;
    const { selected, created } = candidates;
    delete selected[c.id];
    if (c.id in created) {
      delete created[c.id];
    }
    this.setState({
      candidates: Object.assign(candidates, {
        selected,
      }),
    });
  };

  onClearSelections = clearEditState => {
    const { candidates } = this.state;
    const { editing } = candidates;
    this.setState({
      candidates: Object.assign(candidates, {
        selected: {},
        editing: clearEditState ? null : editing,
      }),
    });
  };

  onSetSelectionFocus = (field, context) => {
    this.onClearSelections(true);
    this.setState({
      focus: {
        prediction: null,
        selection: {
          field,
          context,
        },
      },
    });
  };

  onSetPredictionFocus = prediction => {
    this.onClearSelections(true);
    this.setState({
      focus: {
        prediction,
        selection: null,
      },
    });
  };

  onTableChange = value => {
    if ((value || []).length === 0) {
      this.setState({ isShowDocCanvas: false });
      return;
    }
    this.setState({ isShowDocCanvas: true });
  };

  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    // eslint-disable-next-line no-shadow
    const { docsLoading, fetchTaskAndPreAllocateUser } = this.props;
    if (!docsLoading) {
      fetchTaskAndPreAllocateUser(this.getTaskId(this.props));
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    // eslint-disable-next-line no-shadow
    const { fetchTaskAndPreAllocateUser } = this.props;
    if (this.getTaskId(this.props) !== this.getTaskId(nextProps)) {
      this.setState(this.getInitialState());
      fetchTaskAndPreAllocateUser(this.getTaskId(nextProps));
    }
  }

  getCandidatesForFieldAnnotation = field => {
    if (field && field.id === 'column') {
      return [];
    }
    if (field && field.value && field.value.constructor === Array) {
      return [].concat.apply(
        [],
        field.value.map(this.getCandidatesForFieldAnnotation),
      );
    }
    if (field && field.fields) {
      return [].concat.apply(
        [],
        field.fields.map(this.getCandidatesForFieldAnnotation).flat(),
      );
    }
    if (
      field &&
      field.source &&
      field.source.type === 'selection' &&
      field.data &&
      field.data.value
    ) {
      return [field.data.value];
    }
    return [];
  };

  debouncedSaveUserAnnotationData = (
    timestamp,
    taskId,
    userId,
    newAnnotations,
  ) => {
    // eslint-disable-next-line no-shadow
    const { saveUserAnnotationData } = this.props;
    const { pendingSaveTimeout } = this.state;
    const { saveIntervalMs, maxDebounceIntervalWaitMs } = this.state;
    if (pendingSaveTimeout) {
      clearTimeout(pendingSaveTimeout);
    }
    const flushStateToServer = () => {
      saveUserAnnotationData(taskId, userId, newAnnotations).then(() => {
        this.setState({ lastSyncTime: timestamp });
      });
    };

    let timeout = null;
    let callback = null;
    const { lastSyncTime } = this.state;
    if (lastSyncTime && timestamp - lastSyncTime > maxDebounceIntervalWaitMs) {
      // save immediately if it's been too long since the last sync
      flushStateToServer();
    } else {
      callback = flushStateToServer;
      timeout = setTimeout(flushStateToServer, saveIntervalMs);
    }
    this.setState({
      pendingSaveTimeout: timeout,
      pendingSaveTimeoutCallback: callback,
    });
  };

  flushPendingUserAnnotationData = () => {
    const { pendingSaveTimeout, pendingSaveTimeoutCallback } = this.state;
    if (pendingSaveTimeout) {
      clearTimeout(pendingSaveTimeout);
      pendingSaveTimeoutCallback();
      this.setState({
        pendingSaveTimeout: null,
        pendingSaveTimeoutCallback: null,
      });
    }
  };

  render() {
    const {
      task,
      doc,
      predictions,
      tokenCandidates,
      receivedTime,
      contextUserId,
      user,
      annotators,
      loading,
      error,
      canWriteTask,
      canSuperUserWrite,
    } = this.props;

    const processingLimit = user?.company?.processingLimit;

    // eslint-disable-next-line no-shadow
    const { saveUserAnnotationState, goToNextTaskUsingStoredFilters } =
      this.props;
    const { mode, focus, isShowDocCanvas } = this.state;
    const taskId = this.getTaskId(this.props);
    const userId = this.getUserId(this.props);

    const isSubmitted = () => {
      if (task && task.annotations?.length > 0) {
        return task.state === 'complete';
      }

      return false;
    };

    const isSuperUserAnnotatingSubmitted = () => {
      return isSubmitted() && canSuperUserWrite;
    };
    const isEditable = () => {
      return isSuperUserAnnotatingSubmitted() || contextUserId === userId;
    };

    const editable = isEditable();

    let annotations = null;
    let state = null;
    let flaggedReason = null;
    let updated = null;
    let undoable = false;
    let annotatedCandidates = [];
    if (task && task.annotations) {
      const userAnnotation = task.annotations.find(
        a => a.submitterId === userId,
      );
      if (userAnnotation) {
        annotations = userAnnotation.data;
        state = userAnnotation.state;
        flaggedReason = userAnnotation.flaggedReason;
        updated = userAnnotation.updated;
        if (annotations && annotations.fields) {
          annotatedCandidates = [].concat.apply(
            [],
            annotations.fields.map(this.getCandidatesForFieldAnnotation),
          );
        }
        const isSubmittedOrFlagged = userAnnotation.undoable;
        // TODO: this appears to allow a validate/organiser the ability to edit
        // their OWN task even if not submitted or flagged - does this make
        // sense??
        undoable = editable && (canWriteTask || isSubmittedOrFlagged);
      }
    }

    const getAnnotator = () => {
      let annotator = null;
      if (editable && !isSuperUserAnnotatingSubmitted()) {
        annotator = user.data;
      }

      if (!annotator && annotators) {
        annotator = annotators.find(a => a.id === userId);
      }

      if (!annotator) {
        annotator = user.data;
      }
      return annotator;
    };

    const annotator = getAnnotator();

    const onSaveAnnotations = newAnnotations => {
      const currentTime = new Date().getTime();
      // eslint-disable-next-line no-param-reassign
      newAnnotations.taskId = task.id;
      // eslint-disable-next-line no-param-reassign
      newAnnotations.docId = doc.docId;
      // eslint-disable-next-line no-param-reassign
      newAnnotations.received =
        annotations && annotations.created
          ? annotations.received
          : receivedTime;
      // eslint-disable-next-line no-param-reassign
      newAnnotations.created =
        annotations && annotations.created ? annotations.created : currentTime;
      // eslint-disable-next-line no-param-reassign
      newAnnotations.updated = currentTime;
      // eslint-disable-next-line no-param-reassign
      newAnnotations.annotatorId = contextUserId;
      // eslint-disable-next-line no-param-reassign
      newAnnotations.specificationId = task.specification.id;

      this.debouncedSaveUserAnnotationData(
        currentTime,
        taskId,
        userId,
        newAnnotations,
      );
    };
    const onSaveState = (newState, reason, redirect) => {
      this.flushPendingUserAnnotationData();
      saveUserAnnotationState(taskId, userId, newState, reason).then(() => {
        if (redirect) {
          goToNextTaskUsingStoredFilters();
        }
      });
    };

    const { candidates } = this.state;
    const { created, selected, editing } = candidates;
    const candidateSelection = {
      enabled: mode === 'select',
      onSelect: this.onSelectCandidate,
      onDeselect: this.onDeselectCandidate,
      onEdit: this.onEditCandidates,
      onAdd: this.onAddCandidate,
      candidates:
        tokenCandidates && !tokenCandidates.response
          ? tokenCandidates
              .map(c =>
                Object.assign(c, {
                  id: c.token_idx,
                  type: 'token',
                }),
              )
              .concat(Object.values(created))
          : null,
      annotatedCandidates,
      selectedCandidates: selected,
      editingCandidates: editing,
      onClear: this.onClearSelections,
      setFocus: this.onSetSelectionFocus,
      focusField: focus.selection,
    };

    let annotatorOptions = annotators
      ? annotators.filter(a => a.id !== user.data.id)
      : [];
    if (user.data) {
      annotatorOptions = annotatorOptions.concat(user.data);
    }

    const createAnnotatorLabel = a => {
      let prefix = '';
      if (a.id === user.data.id && a.id !== userId) {
        if (!annotators) {
          prefix = '+ ';
        } else if (isSuperUserAnnotatingSubmitted()) {
          if (
            !annotators.some(aa => aa.id === user.id || aa.id === contextUserId)
          ) {
            prefix = '+ ';
          }
        } else if (!annotators.some(aa => aa.id === user.id)) {
          prefix = '+ ';
        }
      }

      return (
        prefix + (!a.lastName ? a.firstName : `${a.firstName} ${a.lastName}`)
      );
    };
    annotatorOptions = annotatorOptions.map(a => {
      return {
        label: createAnnotatorLabel(a),
        value: a.id,
      };
    });

    const initAnnotations =
      task && task.parameters && task.parameters.init
        ? task.parameters.init.annotation
        : null;

    const taskTitle =
      task && task.specification
        ? `${task.specification.description} - Tasks`
        : 'Validate - Tasks';

    const isCollapseSelections =
      task && task.collapseSelections ? task.collapseSelections : false;

    return !error && (loading || !task) ? (
      <div className="loading">
        <ReactLoading type="spin" color="#dddddd" height={128} width={128} />
      </div>
    ) : error || !annotator ? (
      <Redirect
        to={{
          pathname:
            error && error.response && error.response.status === 403
              ? '/403'
              : '/404',
          state: {
            referrer: `/tasks/${taskId}/user/${userId}`,
            entity: 'tasks',
            returnTo: '/tasks',
            returnLabel: 'Back to Tasks',
          },
        }}
      />
    ) : (
      <DocumentToolbarContainer>
        <div className="task">
          <PageHelmet title={taskTitle} />
          <DocumentToolbar
            doc={doc}
            isTask
            task={task}
            annotationState={state}
            editable={editable}
            annotator={annotator}
            annotatorOptions={annotatorOptions}
            saveAnnotationState={onSaveState}
            state={state}
          />
          <TableContextProvider>
            <div className="content">
              <AnnotationList
                doc={doc}
                state={state}
                flaggedReason={flaggedReason}
                annotations={annotations || initAnnotations}
                openTableView={this.openTableView}
                closeTableView={this.closeTableView}
                openTableSelectionMode={this.openTableSelectionMode}
                closeTableSelectionMode={this.closeTableSelectionMode}
                fields={
                  task && task.specification
                    ? task.specification.field_versions
                        .map(fv => fv.specification)
                        .filter(f => f.annotated)
                    : []
                }
                focusPrediction={focus.prediction}
                setPredictionFocus={this.onSetPredictionFocus}
                candidateSelection={candidateSelection}
                predictions={predictions}
                updated={updated}
                editable={editable}
                undoable={undoable}
                saveAnnotations={onSaveAnnotations}
                saveAnnotationState={onSaveState}
                initAnnotations={
                  initAnnotations ? initAnnotations.fields.map(a => a.id) : []
                }
                collapseSelections={isCollapseSelections}
                tableChange={this.onTableChange}
              />
              <div className="document-container">
                <DocumentView
                  doc={doc}
                  task={task}
                  isTaskPage
                  processingLimit={processingLimit}
                  companyId={task.companyId}
                  focusPrediction={focus.prediction}
                  candidateSelection={candidateSelection}
                  isShowDocCanvas={isShowDocCanvas}
                  isShowTagList
                />

                <div id="annotation-tray" />
              </div>
            </div>
          </TableContextProvider>
          <ReactTooltip id="task-tooltip" place="bottom" variant="dark" />
        </div>
      </DocumentToolbarContainer>
    );
  }
}

Task.propTypes = {
  doc: PropTypes.shape().isRequired,
  task: PropTypes.shape({
    id: PropTypes.string,
    specification: PropTypes.shape(),
    companyId: PropTypes.string,
    state: PropTypes.string,
    collapseSelections: PropTypes.bool,
    parameters: PropTypes.shape(),
    annotations: PropTypes.shape(),
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    data: PropTypes.shape(),
    company: PropTypes.shape({
      processingLimit: PropTypes.number,
    }),
  }).isRequired,
  annotators: PropTypes.shape({
    some: PropTypes.func,
    find: PropTypes.func,
    filter: PropTypes.func,
  }).isRequired,
  predictions: PropTypes.shape().isRequired,
  error: PropTypes.shape().isRequired,
  tokenCandidates: PropTypes.shape().isRequired,
  saveUserAnnotationState: PropTypes.func.isRequired,
  saveUserAnnotationData: PropTypes.func.isRequired,
  goToNextTaskUsingStoredFilters: PropTypes.func.isRequired,
  fetchTaskAndPreAllocateUser: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  docsLoading: PropTypes.bool.isRequired,
  receivedTime: PropTypes.string.isRequired,
  contextUserId: PropTypes.string,
  canWriteTask: PropTypes.bool,
  canSuperUserWrite: PropTypes.bool,
};

Task.defaultProps = {
  contextUserId: '',
  canWriteTask: false,
  canSuperUserWrite: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Task);
