import React from 'react';
import { FaChevronDown, FaFileDownload, FaFileUpload } from 'react-icons/fa';
import PropTypes from 'prop-types';
import './_captureDocActions.scss';

const DOWNLOAD_MODE = 'BUTTON';

const CaptureDocActions = ({
  doc,
  product,
  onDownloadClick,
  onUploadClick,
}) => {
  const { results } = doc.json;
  const noOfPages = doc && results ? Number(results.numPages) : 0;

  return (
    <div className="capture-doc-actions">
      <ul className="capture-doc-actions__list">
        <li className="capture-doc-actions__item">
          {DOWNLOAD_MODE === 'LINK' ? (
            <a
              href={doc.original}
              className="capture-doc-actions__link"
              download={doc.filename}
              target="_blank"
              rel="noreferrer"
            >
              Download this {product.name.toSingular()}
              {doc.id === product.docId ? ' example' : ''}
            </a>
          ) : (
            <button
              type="button"
              className="capture-doc-actions__link"
              onClick={onDownloadClick}
            >
              Download this {product.name.toSingular()}
              {doc.id === product.docId ? ' example' : ''}
            </button>
          )}
          <FaFileDownload className="capture-doc-actions__icon" />
        </li>
        <li className="capture-doc-actions__item">
          <button
            type="button"
            className="capture-doc-actions__link"
            onClick={onUploadClick}
          >
            Upload your own {product.name.toSingular('s')}
          </button>
          <FaFileUpload className="capture-doc-actions__icon" />
        </li>
      </ul>
      {noOfPages > 1 && (
        <div className="scroll-indicator">
          <span className="scroll-indicator__text">Scroll</span>
          <FaChevronDown className="scroll-indicator__icon" />
        </div>
      )}
    </div>
  );
};

CaptureDocActions.propTypes = {
  doc: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  onDownloadClick: PropTypes.func.isRequired,
  onUploadClick: PropTypes.func.isRequired,
};

export default CaptureDocActions;
