import React from 'react';
import PropTypes from 'prop-types';
import { Interweave } from 'interweave';
import MarkdownIt from 'markdown-it';

import { Box } from '../../atoms/Box';
import { Grid, GridItem } from '../../templates/Grid';
import RateCardList from '../../molecules/RateCardList/RateCardList';

import { BILLING_AND_PAYMENT, BILLING_AND_PAYMENT_SIGNALS } from './messages';

import './MeteredPricing.scss';

const mdParser = new MarkdownIt();

const MeteredPricing = ({ pricing, accessFee, productType }) => {
  let billingAndPaymentMessage = BILLING_AND_PAYMENT;
  if (productType === 'signals') {
    billingAndPaymentMessage = BILLING_AND_PAYMENT_SIGNALS;
  }
  console.log('mike: pricing ', pricing);
  return (
    <div className="metered-pricing__container">
      <Grid colGap="secondary">
        <GridItem col="8">
          <RateCardList
            pricing={pricing}
            accessFee={accessFee}
            productType={productType}
          />
        </GridItem>
        <GridItem col="4">
          <Box variant="payment">
            <h2>Billing & Payment</h2>
            <Interweave
              attributes={{
                className: 'metered-pricing__disclaimer__text',
              }}
              containerTagName="div"
              content={mdParser.render(billingAndPaymentMessage)}
            />
          </Box>
        </GridItem>
      </Grid>
    </div>
  );
};

MeteredPricing.propTypes = {
  productType: PropTypes.oneOf(['signals', 'capture', 'platform']),
  pricing: PropTypes.shape({
    tiers: PropTypes.arrayOf(
      PropTypes.shape({
        flatPrice: PropTypes.number,
        threshold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
  }),
  accessFee: PropTypes.number,
};

MeteredPricing.defaultProps = {
  pricing: {
    tiers: [
      { name: 'Base', flatPrice: 9, threshold: 5, unitPrice: 0 },
      { name: 'High', flatPrice: 0, threshold: 'inf', unitPrice: 0.1 },
    ],
  },
  accessFee: 0,
  productType: 'capture',
};

export default MeteredPricing;
