import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../../library/atoms/Buttons';

import './TourStepHighlight.scss';

const TourStepHighlight = ({
  current,
  title,
  body,
  isPreview,
  shouldShowSkipButton,
  isLastStep,
  closeTour,
  gotoStep,
}) => {
  const closeText = isLastStep ? 'Finish' : 'Close';

  return (
    <main className="TourStepHighlight__wrapper">
      <p className="TourStepHighlight__title">
        {title} {isPreview && <span>(Coming soon)</span>}
      </p>
      <p className="TourStepHighlight__body">{body}</p>
      <div className="TourStepHighlight__button-panel">
        {shouldShowSkipButton && (
          <Button
            onClick={() => gotoStep(current + 1)}
            variant="inverted"
            tabIndex="-1"
          >
            Skip
          </Button>
        )}
        <Button onClick={closeTour} variant="outline-inverted" tabIndex="-1">
          {closeText}
        </Button>
      </div>
    </main>
  );
};

TourStepHighlight.propTypes = {
  current: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  isPreview: PropTypes.bool,
  shouldShowSkipButton: PropTypes.bool,
  isLastStep: PropTypes.bool,
  closeTour: PropTypes.func.isRequired,
  gotoStep: PropTypes.func,
};

TourStepHighlight.defaultProps = {
  gotoStep: () => {},
  isPreview: false,
  shouldShowSkipButton: false,
  isLastStep: false,
};

export default TourStepHighlight;
