/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';

const Highlight = ({
  bounds,
  className,
  padding,
  disabled,
  onClick,
  children,
}) => {
  const basePadding = padding || 0.002;
  const xPad = basePadding;
  const yPad = xPad / 1.4;
  const topLeftX = ((bounds.topLeft.x - xPad) * 100).toPrecision(5);
  const topLeftY = ((bounds.topLeft.y - yPad) * 100).toPrecision(5);
  const bottomRightX = ((bounds.bottomRight.x + xPad) * 100).toPrecision(5);
  const bottomRightY = ((bounds.bottomRight.y + yPad) * 100).toPrecision(5);

  const style = {
    top: `${topLeftY}%`,
    left: `${topLeftX}%`,
    right: `${100 - bottomRightX}%`,
    bottom: `${100 - bottomRightY}%`,
    boxSizing: 'border-box',
  };
  if (disabled) {
    style.pointerEvents = 'none';
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={`highlight ${disabled ? 'disabled ' : ''}${className || ''}`}
      style={style}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

Highlight.propTypes = {
  bounds: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  padding: PropTypes.number,
  onClick: PropTypes.func,
  children: PropTypes.object,
};

Highlight.defaultProps = {
  bounds: null,
  className: '',
  disabled: false,
  padding: 0.005,
  onClick: () => {},
  children: null,
};

export default Highlight;
