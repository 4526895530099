import React from 'react';
import PropTypes from 'prop-types';
import './_productCell.scss';

const ProductsCell = ({
  row: {
    original: { products, extracting },
  },
}) => {
  return extracting ? (
    <div className="product-cell__extract-progress" />
  ) : (
    <span>{products.join(' ')}</span>
  );
};

ProductsCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      products: PropTypes.arrayOf(PropTypes.string),
      extracting: PropTypes.bool,
    }).isRequired,
  }).isRequired,
};

export default ProductsCell;
