import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const ContextBarBtn = ({ children, to, label }) => {
  return (
    <NavLink
      className="context-bar__btn"
      activeClassName="context-bar__btn--active"
      to={to}
      data-tooltip-content={label}
      data-tooltip-place="right"
      data-tooltip-id="contextbar-tooltip"
    >
      {children}
      <span className="btn__text">{label}</span>
    </NavLink>
  );
};

ContextBarBtn.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string.isRequired,
  label: PropTypes.string,
};

ContextBarBtn.defaultProps = {
  children: null,
  label: null,
};

export default ContextBarBtn;
