import React from 'react';
import Skeleton, {
  SkeletonLI,
  SkeletonOL,
} from '../../../../../components/Skeleton';

const EmailListSkeleton = () => {
  return (
    <Skeleton className="email-list__skeleton">
      <SkeletonOL>
        <SkeletonLI />
        <SkeletonLI />
        <SkeletonLI />
      </SkeletonOL>
    </Skeleton>
  );
};

EmailListSkeleton.propTypes = {};

export default EmailListSkeleton;
