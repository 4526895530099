const analysePassword = value => {
  const hasValidLength = value.length >= 8;
  const hasNumeric = /[0-9]/.test(value);
  const hasUppercase = /[A-Z]/.test(value);
  const hasLowercase = /[a-z]/.test(value);
  return {
    hasValidLength,
    hasNumeric,
    hasUppercase,
    hasLowercase,
    isValid: hasValidLength && hasNumeric && hasUppercase && hasLowercase,
  };
};

export default analysePassword;
