import * as utils from './utils';

const apiBaseUrl = process.env.APP_API_HOST_NAME;
const validateServiceBaseUrl =
  process.env.VALIDATE_SERVICE_HOST_NAME || apiBaseUrl;

class FieldsetService {
  getFieldsets = (accessToken, params) => {
    return utils.getData(`${validateServiceBaseUrl}/app/fieldsets`, {
      accessToken,
      ...params,
    });
  };
}

export default new FieldsetService();
