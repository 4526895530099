import CompanyService from '../../services/CompanyService';
import { errorNotification } from '../notifications';

export const REQUEST_SETTINGS = 'sypht/products/REQUEST_SETTINGS';
export const RECEIVE_SETTINGS = 'sypht/products/RECEIVE_SETTINGS';

// HELPERS
const getCredentials = auth => {
  const { accessToken, user } = auth;
  const { companyId } = user.data || auth.claims;

  return {
    accessToken,
    companyId,
    user,
  };
};

// DISPATCHERS
const requestSettings = () => {
  return {
    type: REQUEST_SETTINGS,
    payload: {
      loading: true,
    },
  };
};

const receiveSettings = settings => {
  return {
    type: RECEIVE_SETTINGS,
    payload: {
      settings,
      loading: false,
      receivedAt: Date.now(),
    },
  };
};

// SETTINGS: RETRIEVE
export const retrieveSettings = settings => {
  // settings: string
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken, companyId } = getCredentials(auth);

    dispatch(requestSettings());
    const response = await CompanyService.retrieveSettings(
      accessToken,
      companyId,
      settings,
    );

    if (response.data) {
      dispatch(receiveSettings(response.data));
    } else {
      dispatch(errorNotification('Error retrieving settings.'));
    }
  };
};

// SETTINGS: UPDATE
export const updateSettings = settings => {
  // settings: Object[] eg. [{ key1: value1 }, { key2: value2 }]
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken, companyId } = getCredentials(auth);

    dispatch(requestSettings());

    const response = await CompanyService.updateSettings(
      accessToken,
      companyId,
      settings,
    );

    dispatch(receiveSettings(response.data));
  };
};
