import { mergeRight as merge } from 'ramda';
import {
  TRANSMIT_COMPANY,
  RECEIVE_COMPANY,
  RECEIVE_COMPANY_ROLES,
} from './actions';

const initialState = {
  data: null,
  invalidate: false,
  loading: false,
  lastUpdated: null,
  roles: {
    error: null,
    data: null,
  },
};

const companyReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case TRANSMIT_COMPANY: {
      return merge(state, payload);
    }

    case RECEIVE_COMPANY: {
      const { data, receivedAt } = payload;
      return merge(state, {
        data,
        loading: false,
        invalidate: false,
        lastUpdated: receivedAt,
      });
    }

    case RECEIVE_COMPANY_ROLES: {
      const { data, error, loading } = payload;
      return merge(state, {
        roles: { data, error, loading },
      });
    }

    default:
      return state;
  }
};

export default companyReducer;
