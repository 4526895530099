export const getSplitStructure = ({ documents, filename, pages }) => {
  const childDocs = {};
  if (documents) {
    documents.forEach((childDoc, index) => {
      const childDocNum = index + 1;
      const childDocId = `childDoc_${childDocNum}`;
      const childDocName = getChildDocName(filename, childDocNum);
      const childPages = (childDoc.parts || []).map(part => {
        return pages[`page_${part.pageNum}`].id;
      });
      childDocs[childDocId] = {
        id: childDocId,
        title: childDocName,
        pages: childPages,
      };
    });
  } else {
    return null;
  }
  return childDocs;
};

export const getInitialData = ({
  predictions,
  numOfPages,
  filename,
  annotation,
  images,
}) => {
  const splitField = (predictions || []).find(
    p => p.id === 'sypht.format.components',
  );
  const prediction =
    splitField && splitField.predictions && splitField.predictions[0]
      ? splitField.predictions[0].value
      : null;
  const documents = prediction ? prediction.documents : null;
  const pages = {};
  let pageNum = 1;
  while (pageNum <= numOfPages) {
    const pageId = `page_${pageNum}`;
    pages[pageId] = {
      id: pageId,
      pageNum,
      image: images[pageNum - 1], // pageIndex
    };
    pageNum++;
  }
  let childDocs;
  if (annotation) {
    childDocs = displayAnnotatedStructure({ annotation, filename, pages });
  } else {
    childDocs = getSplitStructure({
      documents,
      numOfPages,
      filename,
      pages,
    });
  }
  if (!childDocs) {
    // Default if no predictions or annotation for original document
    const childDocId = `childDoc_1`;
    const childDocName = getChildDocName(filename, 1);
    childDocs = {
      [childDocId]: {
        id: childDocId,
        title: childDocName,
        pages: Object.values(pages).map(p => p.id),
      },
    };
  }

  return {
    pages,
    childDocs,
    order: Object.keys(childDocs),
    noSplitSpecified: Object.keys(childDocs).length === 1,
  };
};

export const displayAnnotatedStructure = ({ annotation, filename, pages }) => {
  const formatComponentsAnnotation = (
    annotation && annotation.data && annotation.data.fields
      ? annotation.data.fields
      : []
  ).find(f => f.id === 'format.components');
  if (!formatComponentsAnnotation) {
    return null;
  }

  const { fields } = formatComponentsAnnotation;
  const [field] = fields || [];
  const childDocs = {};
  const { value } = field || {};
  if (!value) return null;
  value.forEach((childDoc, index) => {
    const childDocNum = index + 1;
    const childDocId = `childDoc_${childDocNum}`;
    const childDocName = getChildDocName(filename, childDocNum);
    const parts = (childDoc.fields || []).find(f => f.id === 'parts');
    const childPages = (parts && parts.value ? parts.value : []).map(part => {
      const { pageNum } = part.data.value[0].bounds;
      return pages[`page_${pageNum}`].id;
    });
    childDocs[childDocId] = {
      id: childDocId,
      title: childDocName,
      pages: childPages,
    };
  });
  return childDocs;
};

export const getChildDocName = (filename, childDocNum) => {
  const filenameArr = filename.split('.');
  let childDocName = `${filenameArr[0]}_${childDocNum}`;
  if (filenameArr[1]) {
    childDocName += `.${filenameArr[1]}`;
  }
  return childDocName;
};

/**
 * Takes an array of child docs and converts it to
 * format.components annotation friendly structure.
 *
 * ChildDocs structure:
 * @param {*} childDocs
 */
export const getAnnotationStructure = ({
  childDocs,
  task,
  userId,
  companyId,
  predictions,
  pages,
}) => {
  const value = [];

  Object.keys(childDocs).forEach(childDocId => {
    const parts = [];
    const childDoc = childDocs[childDocId] || {};
    (childDoc.pages || []).forEach(page => {
      parts.push({
        id: 'bounds',
        source: {
          type: 'selection',
        },
        data: {
          value: [
            {
              type: 'bounds',
              bounds: {
                pageNum: pages[page].pageNum,
                topLeft: {
                  x: 0.0,
                  y: 0.0,
                },
                bottomRight: {
                  x: 1.0,
                  y: 1.0,
                },
              },
            },
          ],
        },
      });
    });

    const documents = {
      id: 'documents',
      source: {
        type: null,
      },
      fields: [
        {
          id: 'parts',
          source: {
            type: null,
          },
          value: parts,
        },
      ],
    };
    value.push(documents);
  });

  const fields = [
    {
      id: 'format.components',
      source: {
        type: null,
      },
      fields: [
        {
          id: 'documents',
          source: {
            type: null,
          },
          value,
        },
      ],
    },
  ];

  const currentTime = new Date().getTime();

  const annotation = {
    taskId: task.id,
    companyId,
    docId: task.docId,
    received: currentTime,
    created: currentTime,
    updated: currentTime,
    annotatorId: userId,
    specificationId: task.specification.id,
    predictions,
    fields,
  };

  return annotation;
};
