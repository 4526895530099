import Pusher from 'pusher-js';
import { onReceiveUploadDocStatus } from '../reducers/upload';
import {
  onReceiveExtract,
  batchExportProgress,
  fetchDocsWithStoredFilters,
} from '../reducers/docs';
import { addNotification, removeNotification } from '../reducers/notifications';
import { STYLE } from '../components/UI/Notification/Notification';
import SplitStatesLookup from '../common/lookups/splitStatesLookup';

const pusher = new Pusher(process.env.PUSHER_KEY, {
  cluster: process.env.PUSHER_CLUSTER,
  forceTls: true,
});

class NotificationService {
  subscribe(dispatch, companyId, user) {
    this.channelId = `tasks@${companyId}`;
    this.channel = pusher.subscribe(this.channelId);
    this.channel.bind('doc-status-change', data => {
      if (data.status === 'RESULTFINALISED' && data.user === user) {
        dispatch(onReceiveUploadDocStatus(data.docId, companyId, data.status));
        if (data.isSplit) {
          dispatch(fetchDocsWithStoredFilters());
          dispatch(removeNotification());
          dispatch(
            addNotification({
              style: STYLE.CONFIRMATION,
              message: `Auto Split complete, ${data.splitResult.length} documents added`,
            }),
          );
        }
      }
    });

    this.channel.bind('extract-complete', data => {
      if (data.user === user) {
        dispatch(onReceiveExtract(data));
      }
    });

    this.channel.bind('batch-export-progress', data => {
      if (data.user === user) {
        dispatch(batchExportProgress());
      }
    });

    this.channel.bind('doc-split-status-change', data => {
      if (
        data.status === SplitStatesLookup.split_pending ||
        data.status === SplitStatesLookup.split_complete
      ) {
        dispatch(fetchDocsWithStoredFilters());
      }
    });
  }

  unsubscribe() {
    pusher.unsubscribe(this.channelId);
  }
}

export default new NotificationService();
