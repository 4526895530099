const removeError = (key, message, errors, setErrors) => {
  if (!errors) return;

  const errorsList = (errors[key] || []).slice();
  const errorIndex = errorsList.indexOf(message);

  if (errorIndex > -1) {
    errorsList.splice(errorIndex, 1);
    if (errorsList.length) {
      setErrors({
        ...errors,
        [key]: errorsList,
      });
    } else {
      const updatedErrors = { ...errors };
      delete updatedErrors[key];
      setErrors(updatedErrors);
    }
  }
};

export default removeError;
