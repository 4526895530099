import React, { useContext, useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdNotificationsActive } from 'react-icons/md';
import { IoMdCloseCircle } from 'react-icons/io';
import { AlertBannerContext } from './AlertBannerContext';
import './AlertBanner.scss';
import { requestWelcomeEmail } from '../../../reducers/authReducer';
import { STYLE } from '../Notification/Notification';
import { updateCompany, fetchCompany } from '../../../reducers/company/actions';
import { addNotification } from '../../../reducers/notifications';

const mapStateToProps = state => {
  const { auth, company } = state;
  const user = auth.user && auth.user.data ? auth.user.data : null;
  return {
    isAuthenticated: auth.isAuthenticated,
    user,
    company: company.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      resendWelcomeEmail: () => dispatch(requestWelcomeEmail()),
      notifyEmailSent: () =>
        dispatch(
          addNotification({
            style: STYLE.CONFIRMATION,
            message: `Verification email sent.`,
          }),
        ),
      fetchCompany: companyId => dispatch(fetchCompany(companyId)),
      updateCompany: (companyId, updateDetails) =>
        dispatch(updateCompany(companyId, updateDetails)),
    },
  };
};

const AlertBanner = ({ user, company, dispatch }) => {
  const [alertBanner, setAlertBanner] = useContext(AlertBannerContext);
  const [alertMessage, setAlertMessage] = useState(null);
  const sendEmail = () => {
    dispatch.resendWelcomeEmail();
    dispatch.notifyEmailSent();
  };

  const onDismiss = () => {
    if (
      company &&
      company.currBillingRange &&
      company.alertDate &&
      !company.ackDate
    ) {
      dispatch.updateCompany(company.id, {
        ackDate: moment(),
      });
    }
    setAlertBanner(a => ({
      ...a,
      showAlert: false,
    }));
  };

  useEffect(() => {
    if (!company && user) {
      dispatch.fetchCompany(user.companyId);
    }
    if (user && user.auth0 && !user.auth0.email_verified) {
      setAlertMessage(
        <div className="alert-banner__message">
          Please verify your email to get the most out of Sypht.{' '}
          <span
            className="alert-banner__message-link"
            role="button"
            tabIndex={0}
            onClick={sendEmail}
          >
            Click here to resend verification email.
          </span>
        </div>,
      );
    } else if (
      company &&
      company.currBillingRange &&
      company.alertDate &&
      !company.ackDate
    ) {
      setAlertMessage(
        <div className="alert-banner__message">
          You have reached <strong>{company.currBillingRange * 100}%</strong> of{' '}
          <Link
            className="alert-banner__message-link"
            to="/marketplace/billing"
          >
            your preset monthly billing threshold
          </Link>
          .
        </div>,
      );
    }
  }, [user, company]);
  useEffect(() => {
    if (alertMessage) {
      setAlertBanner(a => ({
        ...a,
        showAlert: true,
      }));
    }
  }, [alertMessage]);
  return alertBanner.showAlert ? (
    <div id="alert-banner" className="alert-banner__content">
      <MdNotificationsActive className="alert-banner__icon-alert" />
      {alertMessage}

      <IoMdCloseCircle
        className="alert-banner__icon-dismiss"
        role="button"
        tabIndex={0}
        onClick={onDismiss}
      />
    </div>
  ) : null;
};

AlertBanner.propTypes = {
  user: PropTypes.object,
  company: PropTypes.object,
  dispatch: PropTypes.shape({
    resendWelcomeEmail: PropTypes.func,
    notifyEmailSent: PropTypes.func,
    fetchCompany: PropTypes.func,
    updateCompany: PropTypes.func,
  }),
};

AlertBanner.defaultProps = {
  user: null,
  company: null,
  dispatch: {
    resendWelcomeEmail: () => {},
    notifyEmailSent: () => {},
    fetchCompany: () => {},
    updateCompany: () => {},
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertBanner);
