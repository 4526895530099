import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Input.scss';

const Input = ({
  id,
  children,
  className,
  disabled,
  error,
  label,
  forwardRef,
  ...props
}) => {
  return (
    <div
      className={classNames('input-field', className, {
        'input-field--error': error && error.length,
        'input-field--disabled': disabled,
      })}
    >
      <div className="input-field__inner">
        {label && (
          <label htmlFor={id} className="input-field__label">
            {label}
          </label>
        )}
        <input
          id={id}
          className={classNames('input-field__input', className)}
          disabled={disabled}
          ref={forwardRef}
          {...props}
        />
        {error && error.length && (
          <div className="input-field__error">{error}</div>
        )}
      </div>
    </div>
  );
};

Input.propTypes = {
  id: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  forwardRef: PropTypes.object,
};

Input.defaultProps = {
  id: null,
  children: null,
  className: null,
  disabled: false,
  error: null,
  label: null,
  forwardRef: null,
};

export default Input;
