import { replace } from 'connected-react-router';
import TasksService from '../../services/TasksService';
import { addNotification } from '../notifications';
import {
  createdBatchMessage,
  createdMessage,
  errorMessage,
  requestingFix,
  goToExistingQuickFix,
  createdQuickFix,
} from './messages';
import { fetchDocMeta } from '../docs/actions';
import DocsService from '../../services/DocsService';

export const REQUEST_ADD_TASK = 'sypht/tasks/REQUEST_ADD_TASK';
export const RECEIVE_ADD_TASK = 'sypht/tasks/RECEIVE_ADD_TASK';
export const RECEIVE_ADD_BATCH_TASK = 'sypht/tasks/RECEIVE_ADD_BATCH_TASK';

const requestAddTask = () => ({
  type: REQUEST_ADD_TASK,
  payload: {
    sending: true,
    sent: false,
  },
});

const receiveAddTask = (data, error) => ({
  type: RECEIVE_ADD_TASK,
  payload: {
    data,
    loading: false,
    sending: false,
    sent: true,
    lastUpdated: Date.now(),
    error,
  },
});

const receiveAddBatchTask = (data, error) => ({
  type: RECEIVE_ADD_BATCH_TASK,
  payload: {
    data,
    loading: false,
    sending: false,
    sent: true,
    lastUpdated: Date.now(),
    error,
  },
});

export function addTask({
  companyId,
  docId,
  specification,
  replication,
  priority,
}) {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken, user: { data: user }} = auth; // prettier-ignore
    const reqCompanyId = companyId || user.companyId;
    const { region } = user.company;

    dispatch(requestAddTask());

    const response = await TasksService.addTask(
      accessToken,
      docId,
      specification,
      reqCompanyId,
      region,
      replication,
      priority,
    ).catch(() => {
      dispatch(addNotification(errorMessage));
    });

    if (response && response.data && response.data.task) {
      dispatch(receiveAddTask(response.data.task));
      dispatch(addNotification(createdMessage));
      dispatch(fetchDocMeta(docId));
    } else {
      dispatch(addNotification(errorMessage));
    }

    return response;
  };
}

export function addBatchTask({
  companyId,
  filters,
  specification,
  replication,
  priority,
}) {
  return async (dispatch, getState) => {
    const { auth, docs } = getState();
    const { accessToken, user: { data: user }} = auth; // prettier-ignore
    const targetCompanyId = companyId || user.companyId;
    const { region } = user.company;
    const numDocs = docs.data ? docs.data.count : 0;

    dispatch(requestAddTask());

    const { query, filename, tag } = filters;
    const response = await TasksService.addBatchTask({
      accessToken,
      companyId: targetCompanyId,
      filters: {
        query,
        filename,
        tag,
      },
      specification,
      replication,
      priority,
      region,
    });

    if (response && response.data && response.data.queued) {
      dispatch(receiveAddBatchTask(response.data.task));
      dispatch(
        addNotification(createdBatchMessage(response.data.queued, numDocs)),
      );
    } else {
      dispatch(addNotification(errorMessage));
    }
  };
}

/**
 * Use this to navigate to task after calling addTask or addQuickFix.
 *
 * TODO: was created for addQuickFix but we could apply to create task modal (addTask).
 */
export function navigateToTask(taskId) {
  return (dispatch, getState) => {
    const { auth } = getState();
    const {
      user: { data: user },
    } = auth;
    dispatch(replace(`/tasks/${taskId}/user/${user.id}`));
  };
}

/**
 * Perform a doc quick fix instead of creating a task.
 */
export function addQuickFix({ docId }) {
  return (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;

    dispatch(requestAddTask()); // FIXME: do we need this for quick fix?
    dispatch(addNotification(requestingFix)); // fetch task can be slow, let the user know

    return TasksService.addQuickFix(accessToken, docId).then(
      res => {
        const task = res?.data?.task;

        if (task) {
          dispatch(receiveAddTask(task));
          dispatch(addNotification(createdQuickFix));
          dispatch(navigateToTask(task.id));
        } else {
          dispatch(addNotification(errorMessage));
        }

        return { task };
      },
      err => {
        if (err.response?.status === 409) {
          // There is at least one existing quick fix or task already.
          // FIXME: We don't know if the existing conflict is a task or quick fix
          // (they are very similar, some users can do both).  Just taking the
          // first one here.
          const taskId = err.response.data?.context?.taskIds?.[0];
          if (taskId) {
            dispatch(addNotification(goToExistingQuickFix));
            dispatch(navigateToTask(taskId));
            return;
          }
        }
        dispatch(addNotification(errorMessage));
      },
    );
  };
}

/**
 * Similar to quick fix task but performs a manual doc split.
 */
export function addManualSplit({ docId }) {
  return (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;

    dispatch(requestAddTask());
    dispatch(addNotification(requestingFix));

    // NOTE: manualSplit lives under /docs because of autosplit.
    // But the logic is task-based.
    return DocsService.manualSplit(accessToken, docId).then(
      res => {
        const task = res?.data?.task;

        if (task) {
          dispatch(receiveAddTask(task));
          dispatch(addNotification(createdQuickFix));
          dispatch(navigateToTask(task.id));
        } else {
          dispatch(addNotification(errorMessage));
        }

        return { task };
      },
      err => {
        if (err.response?.status === 409) {
          // Same caveats as for quick fix.
          const taskId = err.response.data?.context?.taskIds?.[0];
          if (taskId) {
            dispatch(addNotification(goToExistingQuickFix));
            dispatch(navigateToTask(taskId));
            return;
          }
        }
        dispatch(addNotification(errorMessage));
      },
    );
  };
}
