import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import classnames from 'classnames';
import Form, {
  useForm,
  validatorType,
  TextInput,
  SelectInput,
  UnlimitedField,
} from '../../../../Form';
import { PageRow, PageCol, PageHelmet } from '../../../../Page';
import './Overview.scss';
import { Button } from '../../../../../library/atoms/Buttons';
import { fetchCountries } from '../../../../../reducers/lookups/actions';
import {
  updateCompany,
  deleteCompany,
} from '../../../../../reducers/admin/actions';
import documentDateFormat, * as docDateFmt from '../../../../../common/types/documentDateFormatType';

const getInitialState = company => {
  const initialState = {
    id: {
      value: company.id,
    },
    name: {
      value: company.name,
      required: true,
    },
    status: {
      value: company.status,
      required: true,
    },
    pageLimit: {
      value: company.pageLimit,
      validators: [validatorType.Number],
      required: true,
    },
    processingLimit: {
      value: company.processingLimit,
      validators: [validatorType.NumberMin, validatorType.NumberMax],
      min: 0,
      max: company.maxProcessingLimit,
      required: true,
    },
    maxFileSize: {
      value: company.maxFileSize,
      validators: [validatorType.Number],
      required: true,
    },
    maxUsers: {
      value: company.maxUsers,
      validators: [validatorType.Number],
      required: true,
    },
    uploadLimitPerMinute: {
      value: company.uploadLimitPerMinute,
      validators: [validatorType.Number],
      required: true,
    },
    documentDateFormat: {
      value: company.settings?.date_locale || docDateFmt.NONE,
      required: true,
    },
    region: {
      value: company.region,
    },
    created: {
      value: company.created,
    },
    address: {
      value: company.address,
    },
    country: {
      value: company.country,
    },
    abn: {
      value: company.abn,
    },
    stripeCustomerId: {
      value: company.stripeCustomerId,
    },
    accountOwner: {
      value:
        company && company.users && company.users.find(u => u.accountOwner)
          ? company.users.find(u => u.accountOwner).email
          : '',
    },
  };
  return initialState;
};

const mapStateToProps = state => {
  const { lookups } = state;

  const { countries } = lookups;

  return {
    countries: countries && countries.data ? countries.data : null,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    navigate: path => dispatch(replace(path)),
    dispatch: {
      fetchCountries: () => dispatch(fetchCountries()),
      updateCompany: (id, details) => dispatch(updateCompany(id, details)),
      deleteCompany: id => dispatch(deleteCompany(id)),
    },
  };
};

const Overview = ({ company, countries, dispatch, navigate }) => {
  const [form] = useForm(getInitialState(company || {}));
  const { fields, errors } = form;

  useEffect(() => {
    dispatch.fetchCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (company && company.id !== fields.id.value) {
    form.reset();
  }

  const countryOptions = (countries || []).map(f => ({
    value: f.name,
    label: f.name,
  }));

  const companyStatusOptions = [
    {
      value: 'active',
      label: 'Active',
    },
    {
      value: 'deactivated',
      label: 'Deactivated',
    },
    {
      value: 'pending',
      label: 'Pending',
    },
    {
      value: 'deleted',
      label: 'Deleted',
    },
  ];

  const formFields = [
    <TextInput label="ID" name="id" value={fields.id.value} disabled />,
    <TextInput
      label="Stripe Customer Id"
      name="stripeCustomerId"
      value={fields.stripeCustomerId.value || ''}
      disabled
    />,
    <TextInput
      label="Account Owner"
      name="accountOwner"
      value={fields.accountOwner.value}
      disabled
    />,
    <TextInput
      label="Region"
      name="region"
      value={fields.region.value}
      disabled
    />,
    <TextInput
      label="Created"
      name="created"
      value={fields.created.value}
      disabled
    />,
    <SelectInput
      className="sort-filter"
      label="Country"
      name="country"
      value={fields.country.value || ''}
      errors={errors}
      onBlur={form.handleBlur}
      onChange={form.handleChange}
      options={countryOptions}
    />,
    <TextInput
      label="Name"
      name="name"
      value={fields.name.value}
      onBlur={form.handleBlur}
      onChange={form.handleChange}
    />,

    <TextInput
      label="ABN"
      name="abn"
      value={fields.abn.value || ''}
      onBlur={form.handleBlur}
      onChange={form.handleChange}
    />,

    <TextInput
      label="Address"
      name="address"
      value={fields.address.value || ''}
      onBlur={form.handleBlur}
      onChange={form.handleChange}
    />,
    <SelectInput
      className={classnames('sort-filter', `status-${fields.status.value}`)}
      label="Status"
      name="status"
      value={fields.status.value || ''}
      errors={errors}
      onBlur={form.handleBlur}
      onChange={form.handleChange}
      options={companyStatusOptions}
    />,

    <SelectInput
      className="sort-filter"
      label="Document Date Format"
      tip={docDateFmt.tip}
      name="documentDateFormat"
      value={fields.documentDateFormat.value}
      errors={errors}
      onBlur={form.handleBlur}
      onChange={form.handleChange}
      options={documentDateFormat}
    />,

    <UnlimitedField
      id="maxUsers"
      label="Max Users"
      name="maxUsers"
      errors={errors}
      form={form}
      value={fields.maxUsers.value || ''}
    />,
    <UnlimitedField
      id="uploadLimitPerMinute"
      label="# File Uploads per minute"
      name="uploadLimitPerMinute"
      errors={errors}
      form={form}
      value={fields.uploadLimitPerMinute.value || ''}
    />,
    <UnlimitedField
      id="maxFileSize"
      label="Max File Size Per Upload (MB)"
      tip="Do not exceed a hard global limit set on fileupload api (currently about ~40MB)"
      name="maxFileSize"
      errors={errors}
      form={form}
      value={fields.maxFileSize.value || ''}
    />,
    <UnlimitedField
      id="pageLimit"
      label="Max # of Pages Per Upload"
      name="pageLimit"
      errors={errors}
      form={form}
      value={fields.pageLimit.value || ''}
    />,
    <TextInput
      id="processingLimit"
      label="Max # of Pages Processed"
      name="processingLimit"
      errors={errors}
      value={fields.processingLimit.value || ''}
      onChange={form.handleChange}
      onBlur={form.handleBlur}
    />,
  ];
  const mapFormFields = () => {
    const arr = [];
    for (let i = 0, j = 0; i < formFields.length; i += 2, j += 1) {
      arr.push(
        <PageRow key={`page__row-${j}`}>
          <PageCol>{formFields[i]}</PageCol>
          {formFields[i + 1] && <PageCol>{formFields[i + 1]}</PageCol>}
        </PageRow>,
      );
      j += 1;
    }
    return arr;
  };

  const onSubmit = () => {
    if (fields.status.value === 'deleted') {
      let previousLabel = '';

      // eslint-disable-next-line no-alert
      while (previousLabel !== null) {
        const previousMsg = previousLabel
          ? ` ${previousLabel} is incorrect.`
          : '';
        const msg = `Please enter the name of the company to delete: '${fields.name.value}' ? ${previousMsg}`;
        previousLabel = window.prompt(msg);
        if (previousLabel === fields.name.value) {
          dispatch.deleteCompany(company.id).then(() => {
            navigate('/admin/companies');
          });
          previousLabel = null;
        }
      }
    } else {
      const updateDetails = {
        country: fields.country.value,
        name: fields.name.value,
        abn: fields.abn.value,
        address: fields.address.value,
        status: fields.status.value,
        maxUsers: parseInt(fields.maxUsers.value, 10),
        pageLimit: parseInt(fields.pageLimit.value, 10),
        processingLimit: parseInt(fields.processingLimit.value, 10),
        uploadLimitPerMinute: parseInt(fields.uploadLimitPerMinute.value, 10),
        maxFileSize: parseInt(fields.maxFileSize.value, 10),
        settings: {
          ...company.settings,
          date_locale:
            fields.documentDateFormat.value === docDateFmt.NONE
              ? null
              : fields.documentDateFormat.value,
        },
      };
      dispatch.updateCompany(company.id, updateDetails);
    }
  };
  return (
    <Form className="company-form">
      <PageHelmet title="Company - Internal Admin" />
      <div className="form-fields">{mapFormFields()}</div>
      <PageRow className="company-form__actions">
        <Button onClick={() => form.reset()} size="lg" variant="outline">
          Reset
        </Button>
        <Button onClick={onSubmit} size="lg">
          Save
        </Button>
      </PageRow>
    </Form>
  );
};

Overview.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.shape({})),
  dispatch: PropTypes.shape({
    fetchCountries: PropTypes.func.isRequired,
    deleteCompany: PropTypes.func.isRequired,
    updateCompany: PropTypes.func.isRequired,
  }).isRequired,
  company: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    name: PropTypes.string,
    users: PropTypes.arrayOf(PropTypes.shape({})),
    settings: PropTypes.shape({}),
  }).isRequired,
  navigate: PropTypes.func.isRequired,
};

Overview.defaultProps = {
  countries: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
