/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import Checkbox from '../../Checkbox/Checkbox';

class CheckboxColumn {
  constructor({
    id,
    selected = [],
    isSelectAll = false,
    onClick = () => {},
    onClickAll = () => {},
    ...props
  }) {
    this.sortable = false;
    this.width = 60;

    this.Header = () => (
      <Checkbox
        className="rt-checkbox"
        name={`${id}|select_all`}
        checked={isSelectAll}
        onChange={onClickAll}
      />
    );

    this.Cell = ({ original: o }) => {
      return (
        <Checkbox
          className="rt-checkbox"
          id={`${id}|select_item|${o.id}`}
          name={`${id}|select_item|${o.id}`}
          value={o.id}
          checked={selected.includes(o.id)}
          onClick={e => onClick(e, o.id)}
        />
      );
    };

    Object.entries(props).forEach(([key, value]) => {
      this[key] = value;
    });
  }
}

CheckboxColumn.propTypes = {
  id: PropTypes.string,
  selected: PropTypes.arrayOf(PropTypes.string),
  isSelectAll: PropTypes.bool,
  onClick: PropTypes.func,
  onClickAll: PropTypes.func,
};

export default CheckboxColumn;
