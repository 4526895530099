import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { scrollPageIntoView } from '../../../../common/helpers';

const ChildPage = ({ page, index }) => {
  const handleScrollIntoView = () => {
    scrollPageIntoView(page.pageNum);
  };

  return (
    <Draggable draggableId={page.id} index={index}>
      {(provided, snapshot) => (
        <div
          className={classNames('child-page-wrapper', {
            isDragging: snapshot.isDragging,
          })}
          tabIndex={0}
          role="button"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          onClick={handleScrollIntoView}
        >
          <img
            src={page.image}
            alt={`page-${index}-image`}
            className={classNames('child-page', {
              isDragging: snapshot.isDragging,
            })}
          />

          <div className="child-page-number">{page.pageNum}</div>
        </div>
      )}
    </Draggable>
  );
};

ChildPage.defaultProps = {
  page: {},
  index: 0,
  images: [],
};

ChildPage.propTypes = {
  page: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  images: PropTypes.array.isRequired,
};

export default ChildPage;
