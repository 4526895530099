import React, { createContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import history from '../../../services/historyService';
import { trackEvent } from '../../../reducers/tracking/actions';

const initialState = {
  active: null,
  activeElem: null,
  defaultTab: null,
  isNav: false,
};

const TabsContext = createContext([{}, () => {}]);

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      trackEvent: ({ name, params }) => dispatch(trackEvent({ name, params })),
    },
  };
};

const TabsContainer = ({
  children,
  className,
  defaultTab,
  isNav,
  dispatch,
  onChange,
}) => {
  const [state, setState] = useState({
    ...initialState,
    defaultTab,
    dispatch,
    isNav,
  });

  useEffect(() => {
    onChange(state);
  }, [state.active]);

  useEffect(() => {
    const { hash } = history.location;
    if (hash !== '') {
      setState({
        ...state,
        active: hash.replace('#', ''),
      });
    } else {
      setState({
        ...state,
        active: defaultTab,
      });
    }
  }, []);

  return (
    <TabsContext.Provider value={[state, setState]}>
      {!className ? children : <div className={className}>{children}</div>}
    </TabsContext.Provider>
  );
};

TabsContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  defaultTab: PropTypes.string,
  isNav: PropTypes.bool,
  dispatch: PropTypes.shape({
    trackEvent: PropTypes.func,
  }),
  onChange: PropTypes.func,
};

TabsContainer.defaultProps = {
  children: null,
  className: null,
  defaultTab: null,
  isNav: false,
  dispatch: {
    trackEvent: () => {},
  },
  onChange: () => {},
};

export default connect(null, mapDispatchToProps)(TabsContainer);
export { TabsContext };
