// eslint-disable-next-line no-extend-native
Object.defineProperty(String.prototype, 'toTitleCase', {
  value: function toCamelCase() {
    return this.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  },
  writable: true,
  configurable: true,
});
