import { mergeRight as merge } from 'ramda';
import SpecsService from '../services/SpecsService';

export const REQUEST_SPECS = 'sypht/tasks/REQUEST_SPECS';
export const RECEIVE_SPECS = 'sypht/tasks/RECEIVE_SPECS';
export const REQUEST_SPEC = 'sypht/tasks/REQUEST_SPEC';
export const RECEIVE_SPEC = 'sypht/tasks/RECEIVE_SPEC';
export const REQUEST_SAVE_SPEC = 'sypht/tasks/REQUEST_SAVE_SPEC';
export const RECEIVE_SAVE_SPEC = 'sypht/tasks/RECEIVE_SAVE_SPEC';

const initial = {
  data: null,
  loading: false,
  active: null,
};

export const requestSpecs = () => ({ type: REQUEST_SPECS });
export const receiveSpecs = data => ({ type: RECEIVE_SPECS, data });
export const requestSpec = () => ({ type: REQUEST_SPEC });
export const receiveSpec = data => ({ type: RECEIVE_SPEC, data });
export const requestSaveSpec = () => ({ type: REQUEST_SAVE_SPEC });
export const receiveSaveSpec = data => ({ type: RECEIVE_SAVE_SPEC, data });

export default function specsReducer(state = initial, action) {
  switch (action.type) {
    case REQUEST_SPECS:
      return merge(state, {
        active: null,
        loading: true,
      });
    case RECEIVE_SPECS:
      return merge(state, {
        data: {
          specifications: action.data.allspecs || action.data.specifications,
        },
        loading: false,
      });
    case REQUEST_SPEC:
      return merge(state, {
        loading: true,
      });
    case RECEIVE_SPEC:
      return merge(state, {
        active: action.data,
        loading: false,
      });
    case REQUEST_SAVE_SPEC:
      return merge(state, {
        loading: true,
      });
    case RECEIVE_SAVE_SPEC:
      return merge(state, {
        loading: false,
        active: action.data.specification,
      });
    default:
      return state;
  }
}

export function fetchSpecs(filters) {
  return function (dispatch, getState) {
    const token = getState().auth.accessToken;
    dispatch(requestSpecs());
    return SpecsService.getSpecs(token, filters)
      .then(({ data }) => dispatch(receiveSpecs(data)))
      .catch(e => dispatch(receiveSpecs(e)));
  };
}

export function fetchSpec(name) {
  return function (dispatch, getState) {
    const token = getState().auth.accessToken;
    dispatch(requestSpec());
    return SpecsService.getSpec(token, name)
      .then(({ data }) => dispatch(receiveSpec(data)))
      .catch(e => dispatch(receiveSpec(e)));
  };
}

export function pushSpec(data) {
  return function (dispatch, getState) {
    const token = getState().auth.accessToken;
    dispatch(requestSaveSpec());
    return SpecsService.pushSpec(token, data)
      .then(({ data }) => dispatch(receiveSaveSpec(data)))
      .catch(e => dispatch(receiveSaveSpec(e)));
  };
}

export function pushSpecIfNotExist(specData) {
  return function (dispatch, getState) {
    const token = getState().auth.accessToken;
    return SpecsService.getSpec(token, specData.name)
      .then(({ data }) => {
        if (data && data.id) {
          return {
            error: 'Specification with this name already exists.',
          };
        }
        return dispatch(pushSpec(specData));
      })
      .catch(e => false);
  };
}
