export const ArrowTypes = Object.freeze({
  ArrowUp: 'arrow-up',
  ArrowDown: 'arrow-down',
  ArrowLeft: 'arrow-left',
  ArrowRight: 'arrow-right',
});

export const ColorTypes = Object.freeze({
  Primary: 'primary',
  Secondary: 'secondary',
  Tertiary: 'tertiary',
  Black: 'black',
  Blue: 'blue',
  Green: 'green',
  Grey: 'grey',
  Orange: 'orange',
  Purple: 'purple',
  Red: 'red',
  Yellow: 'yellow',
  White: 'white',
  ValidateBlue: 'validate-blue',
  DarkGrey: 'dark-grey',
});

export const DirectionTypes = Object.freeze({
  Up: 'up',
  Down: 'down',
  Left: 'left',
  Right: 'right',
});

export const SizeTypes = Object.freeze({
  Small: 'sm',
  Medium: 'md',
  Large: 'lg',
});
