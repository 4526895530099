import { STYLE } from '../../../UI/Notification/Notification';

export const createdMessage = {
  style: STYLE.CONFIRMATION,
  message: `Rule has been created.`,
  timeout: 8000,
};

export const updatedMessage = {
  style: STYLE.CONFIRMATION,
  message: `Rule has been updated.`,
  timeout: 8000,
};

export const deletedMessage = {
  style: STYLE.CONFIRMATION,
  message: `Rule has been deleted.`,
  timeout: 8000,
};

export const errorMessage = {
  style: STYLE.ERROR,
  message:
    'Something went wrong, please try again. If this issue persists ' +
    'please contact us at support@sypht.com.',
  timeout: 8000,
};

export const validationError = {
  style: STYLE.ERROR,
  message: 'Please complete all required fields.',
  timeout: 8000,
};

export const apiError = error => {
  return {
    style: STYLE.ERROR,
    message: error,
    timeout: 13000,
  };
};
