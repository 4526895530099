import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import DocumentView from '../../../DocumentView/DocumentView';
import useRefresh from '../../../../common/hooks/useRefresh';
import './_captureDoc.scss';

const CaptureDoc = ({ doc, product, onLoad, showHeading }) => {
  const isRefresh = useRefresh([doc]);
  const [isFileLoaded, setFileLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setFileLoaded(true);
    }, 300);
  }, [doc]);

  return (
    !isRefresh && (
      <div
        className={classNames('capture-doc', {
          'capture-doc--loaded': isFileLoaded,
        })}
      >
        {showHeading && (
          <h2 className="capture-doc__heading">
            {product.name.toSingular('s')}
          </h2>
        )}
        <DocumentView
          doc={doc}
          focusPrediction={null}
          openExtractModal={() => {}}
          extracting={false}
          imageUrls={doc.images}
          onLoad={onLoad}
        />
      </div>
    )
  );
};

CaptureDoc.propTypes = {
  doc: PropTypes.shape({
    productFieldMapping: PropTypes.shape({}),
    images: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  product: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  onLoad: PropTypes.func,
  showHeading: PropTypes.bool,
};

CaptureDoc.defaultProps = {
  onLoad: () => {},
  showHeading: true,
};

export default CaptureDoc;
