import 'core-js';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import axios from 'axios';

import interceptor from './services/AxiosInterceptor';

import history from './services/historyService';
import createStore from './store';
import Routes from './components/Routes/Routes';
import './assets/styles/base/screen.scss';

import authService from './services/AuthService';
import {
  setAuthenticatedSuccess,
  setAuthenticatedFailed,
  setAuthenticating,
} from './reducers/authReducer';
import './common/extensions';

const { store, persistor } = createStore({}, history);

axios.interceptors.request.use(
  interceptor(
    store,
    authService,
    setAuthenticating,
    setAuthenticatedSuccess,
    setAuthenticatedFailed,
  ),
);

render(
  // eslint-disable-next-line react/jsx-filename-extension
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <Routes />
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
