import * as React from 'react';
import './_icons.scss';

function DocumentSplitPending(props) {
  const svg = (
    <svg
      width={36}
      height={36}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={18}
        cy={18}
        r={17}
        fill="#fff"
        stroke="#FFB149"
        strokeWidth={2}
        strokeLinecap="round"
        strokeDasharray="4 6"
      />
      <path
        d="M10.75 15.5a.75.75 0 0 1 .75-.75h3.879a.75.75 0 0 1 .53.22l1.06 1.06 1.061 1.061.53-.53-.53.53c.141.14.22.331.22.53V24.5a.75.75 0 0 1-.75.75h-6a.75.75 0 0 1-.75-.75v-9Z"
        fill="#fff"
        stroke="#3C3C3B"
        strokeWidth={1.5}
      />
      <path
        d="M14.25 13.5a.75.75 0 0 1 .75-.75h3.879a.75.75 0 0 1 .53.22l1.06 1.06 1.061 1.061.53-.53-.53.53c.141.14.22.331.22.53V22.5a.75.75 0 0 1-.75.75h-6a.75.75 0 0 1-.75-.75v-9Z"
        fill="#fff"
        stroke="#3C3C3B"
        strokeWidth={1.5}
      />
      <path
        d="M17.75 11.5a.75.75 0 0 1 .75-.75h3.879a.75.75 0 0 1 .53.22l1.06 1.06 1.061 1.061.53-.53-.53.53c.141.14.22.331.22.53V20.5a.75.75 0 0 1-.75.75h-6a.75.75 0 0 1-.75-.75v-9Z"
        fill="#fff"
        stroke="#3C3C3B"
        strokeWidth={1.5}
      />
      <path d="M21.5 10.5v3a1 1 0 0 0 1 1h3" stroke="#3C3C3B" />
    </svg>
  );

  return <div className="sypht-icon__container">{svg}</div>;
}

export default DocumentSplitPending;
