/* eslint-disable no-return-await */
import TagService from '../../services/TagService';
import { errorMessage } from './messages';
import { addNotification } from '../notifications';
import { batchUpdatedMessage, updateTaskTagsMessage } from '../user/messages';

export const REQUEST_COMPANY_TAGS = 'sypht/tags/REQUEST_COMPANY_TAGS';
export const RECEIVE_COMPANY_TAGS = 'sypht/tags/RECEIVE_COMPANY_TAGS';

export const REQUEST_FILTER_TAGS = 'sypht/tags/REQUEST_FILTER_TAGS';
export const RECEIVE_FILTER_TAGS = 'sypht/tags/RECEIVE_FILTER_TAGS';

export const REQUEST_APPLY_TAGS = 'sypht/tags/REQUEST_APPLY_TAGS';
export const RECEIVE_APPLY_TAGS = 'sypht/tags/RECEIVE_APPLY_TAGS';

export const REQUEST_CREATE_COMPANY_TAG =
  'sypht/tags/REQUEST_CREATE_COMPANY_TAG';
export const RECEIVE_CREATE_COMPANY_TAG =
  'sypht/tags/RECEIVE_CREATE_COMPANY_TAG';

export const REQUEST_SET_DOC_TAGS = 'sypht/tags/REQUEST_SET_DOC_TAGS';
export const RECEIVE_SET_DOC_TAGS = 'sypht/tags/RECEIVE_SET_DOC_TAGS';

export const REQUEST_UPDATE_DOC_TAGS = 'sypht/tags/REQUEST_UPDATE_DOC_TAGS';
export const RECEIVE_UPDATE_DOC_TAGS = 'sypht/tags/RECEIVE_UPDATE_DOC_TAGS';

export const REQUEST_DOC_TAGS = 'sypht/tags/REQUEST_DOC_TAGS';
export const RECEIVE_DOC_TAGS = 'sypht/tags/RECEIVE_DOC_TAGS';

export const REQUEST_UPDATE_TASK_TAGS = 'sypht/tags/REQUEST_UPDATE_TASK_TAGS';
export const RECEIVE_UPDATE_TASK_TAGS = 'sypht/tags/RECEIVE_UPDATE_TASK_TAGS';

const requestCompanyTags = companyId => {
  return {
    type: REQUEST_COMPANY_TAGS,
    payload: {
      data: { companyId },
    },
  };
};

const receiveCompanyTags = (data, error) => ({
  type: RECEIVE_COMPANY_TAGS,
  payload: {
    data,
    error,
    lastUpdated: Date.now(),
  },
});

const requestFilterTags = companyId => {
  return {
    type: REQUEST_FILTER_TAGS,
    payload: {
      data: { companyId },
    },
  };
};

const receiveFilterTags = (data, error) => ({
  type: RECEIVE_FILTER_TAGS,
  payload: {
    data,
    error,
    lastUpdated: Date.now(),
  },
});

const requestApplyTags = companyId => {
  return {
    type: REQUEST_APPLY_TAGS,
    payload: {
      data: { companyId },
    },
  };
};

const receiveApplyTags = (data, error) => ({
  type: RECEIVE_APPLY_TAGS,
  payload: {
    data,
    error,
    lastUpdated: Date.now(),
  },
});

const requestCreateCompanyTag = data => {
  return {
    type: REQUEST_CREATE_COMPANY_TAG,
    payload: {
      data,
    },
  };
};

const receiveCreateCompanyTag = (data, error) => ({
  type: RECEIVE_CREATE_COMPANY_TAG,
  payload: {
    data,
    error,
    lastUpdated: Date.now(),
  },
});

const requestSetDocumentTags = ({ companyId, docId, tags }) => {
  return {
    type: REQUEST_SET_DOC_TAGS,
    payload: {
      data: { companyId, docId, tags },
    },
  };
};

const receiveSetDocumentTags = (data, error) => ({
  type: RECEIVE_SET_DOC_TAGS,
  payload: {
    data,
    error,
    lastUpdated: Date.now(),
  },
});

const requestBatchUpdateDocTags = ({ docIds, addTags, companyId }) => ({
  type: REQUEST_UPDATE_DOC_TAGS,
  payload: {
    data: { companyId, docIds, addTags },
  },
});

const receiveBatchUpdateDocTags = (data, error) => ({
  type: RECEIVE_UPDATE_DOC_TAGS,
  payload: {
    data,
    error,
    lastUpdated: Date.now(),
  },
});

const requestDocTags = ({ companyId, docId }) => {
  return {
    type: REQUEST_DOC_TAGS,
    payload: {
      data: { companyId, docId },
    },
  };
};

const receiveDocTags = (data, error) => ({
  type: RECEIVE_DOC_TAGS,
  payload: {
    data,
    error,
    lastUpdated: Date.now(),
  },
});

export const resetDocTags = () => ({
  type: RECEIVE_DOC_TAGS,
  payload: {
    data: null,
    error: null,
    lastUpdated: Date.now(),
  },
});

export const fetchFilterTags = (
  companyId,
  params = { includeCounts: true, limit:null, sortDirection: 'desc', sortColumn:'created',name:null },
) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;
    dispatch(requestFilterTags(companyId));

    return TagService.getCompanyTags(accessToken, companyId, params)
      .then(({ data }) => dispatch(receiveFilterTags(data)))
      .catch(error => dispatch(receiveFilterTags(null, error)));
  };
};

export const fetchApplyTags = (
  companyId,
  params = { includeCounts: true, limit:null, sortDirection: 'desc', sortColumn:'created',name:null },
) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;
    dispatch(requestApplyTags(companyId));

    return TagService.getCompanyTags(accessToken, companyId, params)
      .then(({ data }) => dispatch(receiveApplyTags(data)))
      .catch(error => dispatch(receiveApplyTags(null, error)));
  };
};

export const fetchCompanyTags = (
  companyId,
  params = { includeCounts: true, limit:null, sortDirection: 'desc', sortColumn:'created',name:null },
) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;
    dispatch(requestCompanyTags(companyId));

    return TagService.getCompanyTags(accessToken, companyId, params)
      .then(({ data }) => dispatch(receiveCompanyTags(data)))
      .catch(error => dispatch(receiveCompanyTags(null, error)));
  };
};

export const setDocumentTags = ({ docId, tags, companyId }) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;
    dispatch(requestSetDocumentTags(docId, companyId, tags));
    const data = {
      tags,
    };
    return await TagService.setDocumentTags(accessToken, docId, companyId, data)
      .then(({ data }) => dispatch(receiveSetDocumentTags(data)))
      .catch(error => dispatch(receiveSetDocumentDocTags(null, error)));
  };
};

export const batchUpdateDocumentTags = ({
  docIds,
  addTags,
  removeTags,
  companyId,
}) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;
    dispatch(requestBatchUpdateDocTags(docIds, addTags, removeTags, companyId));
    const data = {
      docIds,
      add: addTags,
      remove: removeTags,
    };
    return await TagService.batchUpdateDocumentTags(
      accessToken,
      companyId,
      data,
    )
      .then(({ data }) => {
        dispatch(receiveBatchUpdateDocTags(data));
        dispatch(
          addNotification(updateTaskTagsMessage(addTags, removeTags, null)),
        );
      })
      .catch(error => {
        dispatch(receiveBatchUpdateDocTags(null, error));
        dispatch(
          addNotification(updateTaskTagsMessage(addTags, removeTags, null)),
        );
      });
  };
};

export const fetchDocTags = ({ companyId, docId, params = {} }) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;
    dispatch(requestDocTags({ companyId, docId }));
    return await TagService.getDocumentTags(
      accessToken,
      docId,
      companyId,
      params,
    )
      .then(({ data }) => dispatch(receiveDocTags(data)))
      .catch(error => dispatch(receiveDocTags(null, error)));
  };
};

export const createCompanyTag = ({
  companyId,
  isAccessTag = false,
  description = null,
  name,
}) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;
    dispatch(
      requestCreateCompanyTag({ companyId, isAccessTag, description, name }),
    );
    const data = {
      description,
      isAccessTag,
      name,
    };
    return await TagService.createCompanyTag(accessToken, companyId, data)
      .then(({ data }) => {
        dispatch(receiveCreateCompanyTag(data));
        return data;
      })
      .catch(error => {
        dispatch(receiveCreateCompanyTag(null, error));
        throw error;
      });
  };
};

/**
 * Task Tags
 */

const requestUpdateTaskTags = ({
  taskIds,
  addTags,
  removeTags,
  companyId,
}) => ({
  type: REQUEST_UPDATE_TASK_TAGS,
  payload: {
    data: { companyId, taskIds, addTags, removeTags },
  },
});

const receiveUpdateTaskTags = (data, error) => ({
  type: RECEIVE_UPDATE_TASK_TAGS,
  payload: {
    data,
    error,
    lastUpdated: Date.now(),
  },
});

export const updateTaskTags = ({ taskIds, addTags, removeTags, companyId }) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;

    dispatch(requestUpdateTaskTags(taskIds, addTags, removeTags, companyId));

    const data = {
      taskIds,
      add: addTags,
      remove: removeTags,
    };

    return await TagService.updateTaskTags(accessToken, companyId, data)
      .then(({ data }) => {
        dispatch(
          addNotification(updateTaskTagsMessage(addTags, removeTags, null)),
        );
        dispatch(receiveUpdateTaskTags(data));
      })
      .catch(error => {
        dispatch(
          addNotification(updateTaskTagsMessage(addTags, removeTags, error)),
        );
        dispatch(receiveUpdateTaskTags(null, error));
      });
  };
};

export const updateUsersTags = ({
  userIds,
  addTags,
  removeTags,
  companyId,
}) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;

    return await TagService.updateUsersTags(accessToken, companyId, {
      userIds,
      add: addTags,
      remove: removeTags,
    })
      .then(() => {
        dispatch(addNotification(batchUpdatedMessage((userIds || []).length)));
      })
      .catch(error => {
        console.error(error);
        dispatch(addNotification(errorMessage));
      });
  };
};
