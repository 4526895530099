import { PriorityLookup, TaskStateLookup } from '../lookups';

export const mapToPriority = value => {
  const priority = Object.keys(PriorityLookup);
  const idx = Object.values(PriorityLookup).findIndex(p => p === value);
  if (idx >= 0 && idx < priority.length) {
    return priority[idx];
  }
  return value;
};

export const mapToTaskState = value => {
  const taskState = Object.keys(TaskStateLookup);
  const idx = Object.values(TaskStateLookup).findIndex(s => s === value);
  if (idx >= 0 && idx < taskState.length) {
    return taskState[idx];
  }
  return value;
};
