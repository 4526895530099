import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FaInfoCircle } from 'react-icons/fa';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import './ToggleGroup.scss';

const ToggleGroup = ({
  children,
  className,
  label,
  labelFor,
  type,
  tooltip,
  tooltipClick,
}) => {
  const toggleGroupClass = classNames(
    className,
    'form__group',
    'toggle-group',
    [`toggle-group--${type}`],
  );

  return (
    <div className={toggleGroupClass}>
      {label ? (
        <label className="form__group-label" htmlFor={labelFor}>
          {label}
          {tooltip && (
            <button
              className="form__tooltip-button"
              type="button"
              data-tooltip-content="What's this?"
              onClick={tooltipClick}
            >
              <FaInfoCircle />
              <ReactTooltip place="top" />
            </button>
          )}
        </label>
      ) : null}
      {children}
    </div>
  );
};

ToggleGroup.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  label: PropTypes.string,
  labelFor: PropTypes.string,
  type: PropTypes.oneOf(['single', 'multiple']),
  tooltip: PropTypes.bool,
  tooltipClick: PropTypes.func,
};

ToggleGroup.defaultProps = {
  children: null,
  className: '',
  label: '',
  labelFor: '',
  type: 'single',
  tooltip: false,
  tooltipClick: () => {},
};

export default ToggleGroup;
