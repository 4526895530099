import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import PropTypes from 'prop-types';
import { FaInfoCircle } from 'react-icons/fa';
import { FilePond } from 'react-filepond';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Toggle from '../../atoms/Toggle/Toggle';
import Button from '../../atoms/Buttons/Button';
import 'filepond/dist/filepond.min.css';
import './_DocumentUpload.scss';

const DocumentUpload = forwardRef(
  (
    {
      defaultSmartSplitEnabled,
      onProcessFile,
      onFilesAdded,
      onComplete,
      MaximumDocuments,
      products,
      onProductSelected,
    },
    ref,
  ) => {
    const filePondRef = useRef();
    const tooltipRef = useRef();
    const [filesToUpload, setFilesToUpload] = useState([]);
    const filenamesToUploadRef = useRef();
    const [isExceededFiles, setIsExceededFiles] = useState(false);
    const [isSmartSplit, setIsSmartSplit] = useState(defaultSmartSplitEnabled);
    const [hasProcessedFiles, setHasProcessedFiles] = useState(false);

    useImperativeHandle(
      ref,
      () => ({
        processFiles: () => {
          if (filePondRef.current) {
            setHasProcessedFiles(true);
            filePondRef.current.processFiles();
          }
        },
      }),
      [filePondRef],
    );

    const onUpdateFiles = fileItems => {
      const files = fileItems.map(f => f.file);
      filenamesToUploadRef.current = files.map(f => f.name);
      setFilesToUpload(files);
      onFilesAdded(files);
    };

    const server = {
      timeout: 15000,
      process: (_fieldName, file, _metadata, load, error, progress) => {
        onProcessFile(file, load, error, progress, isSmartSplit);
      },
    };

    const onProcessFilesComplete = () => {
      onComplete(isSmartSplit);
    };

    const onSmartSplitChange = state => {
      setIsSmartSplit(state);
    };

    const onAddFileStart = () => {
      setIsExceededFiles(false);
    };

    const onWarning = () => {
      setIsExceededFiles(true);
    };

    const beforeAddFile = context => {
      if (filenamesToUploadRef.current) {
        if (filenamesToUploadRef.current.includes(context.file.name)) {
          return false;
        }
      }
      return true;
    };

    const createButtonClass = label =>
      `btn--product-${label.replace(':', '-').replace('.', '-')}`;

    return (
      <div className="document-upload">
        <div>
          <h3>Your subscribed products</h3>
          <div className="product-list">
            {products.map(f => (
              <Button
                size="sm"
                className={createButtonClass(f.value)}
                variant={f.selected ? '' : 'outline'}
                onClick={() => {
                  onProductSelected(f);
                }}
                disabled={isSmartSplit}
              >
                {f.label}
              </Button>
            ))}
          </div>
        </div>
        {isExceededFiles && (
          <div className="warning">
            Please select a maximum of {MaximumDocuments} documents.
          </div>
        )}
        <FilePond
          ref={filePondRef}
          className="document-upload-filepond"
          allowDrop={!hasProcessedFiles}
          allowBrowse={!hasProcessedFiles}
          ignoredFiles={['.ds_store', 'thumbs.db', 'desktop.ini', '.DS_Store']}
          checkValidity
          filesToUpload={filesToUpload}
          instantUpload={false}
          maxFiles={MaximumDocuments}
          allowMultiple
          allowRevert={false}
          allowProcess={false}
          labelMaxFiles="Max files exceeded"
          beforeAddFile={beforeAddFile}
          labelFileProcessingError={error => {
            if (error.response.status === 429) {
              return 'Exceeded upload limit';
            }
            return 'Error during upload';
          }}
          labelIdle={
            'Drag & Drop to start uploading, or <span class="filepond--label-action">browse</span> to select files.'
          }
          onupdatefiles={onUpdateFiles}
          onprocessfiles={onProcessFilesComplete}
          server={server}
          onaddfilestart={onAddFileStart}
          onwarning={onWarning}
          credits=""
        />
        <div className="document-upload-smart-split-option">
          <Toggle
            label="Auto Split Documents"
            defaultChecked={defaultSmartSplitEnabled}
            checked={isSmartSplit}
            onChange={onSmartSplitChange}
            disabled={hasProcessedFiles}
          />
          <FaInfoCircle
            className="modal-upload__form--tooltip"
            data-tooltip-content="Sypht's Document Split feature uses AI to separate multi-document files into individual underlying documents"
            data-tooltip-id="document-upload-tooltip"
          />
          <ReactTooltip
            id="document-upload-tooltip"
            place="right"
            variant="dark"
            
          />
        </div>
      </div>
    );
  },
);

DocumentUpload.propTypes = {
  onProcessFile: PropTypes.func,
  onFilesAdded: PropTypes.func,
  onComplete: PropTypes.func,
  defaultSmartSplitEnabled: PropTypes.bool,
  MaximumDocuments: PropTypes.number,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      filter: PropTypes.func,
    }),
  ),
  onProductSelected: PropTypes.func,
};

DocumentUpload.defaultProps = {
  onProcessFile: () => {},
  onFilesAdded: () => {},
  onComplete: () => {},
  defaultSmartSplitEnabled: false,
  MaximumDocuments: 100,
  products: [],
  onProductSelected: () => {},
};

export default DocumentUpload;
