import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import { FaCheckCircle } from 'react-icons/fa';
import Page from '../Page/Page';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import EditUserForm from '../User/EditUserForm';
import UserPassword from '../User/PasswordForm';
import { ModalContextProvider } from '../UI/Modal/context/ModalContext';
import { fetchCountries } from '../../reducers/lookups/actions';
import { fetchUser, updateUser } from '../../reducers/user/actions';
import { requestWelcomeEmail } from '../../reducers/authReducer';
import { addNotification } from '../../reducers/notifications';
import { STYLE } from '../UI/Notification/Notification';
import '../User/User.scss';
import { PageBody, PageRow, PageInner, PageHeader } from '../Page';

const mapStateToProps = state => {
  const { auth, lookups, user } = state;
  const { userId } = auth;
  const { countries } = lookups;

  return {
    id: userId,
    countries: countries && countries.data ? countries.data : null,
    user: auth.user.data,
    loading: user.loading,
    sending: user.sending,
    canAdministrateUsers: auth.permissions.canAdministrateUsers,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      fetchCountries: () => dispatch(fetchCountries()),
      fetchUser: id => dispatch(fetchUser(id)),
      updateUser: (id, userData, companyId) =>
        dispatch(updateUser(id, userData, companyId)),
      resendWelcomeEmail: () => dispatch(requestWelcomeEmail()),
      notifyEmailSent: () =>
        dispatch(
          addNotification({
            style: STYLE.CONFIRMATION,
            message: `Verification email sent.`,
          }),
        ),
    },
  };
};

/**
 * Profile page allows the user to view and edit some of their account details.
 *
 * A user should be able to view their roles but never edit them from this page.
 */
const Profile = ({
  id,
  countries,
  user,
  loading,
  sending,
  canAdministrateUsers,
  dispatch,
}) => {
  useEffect(() => {
    if (!countries) {
      dispatch.fetchCountries();
    }
    dispatch.fetchUser(id);
  }, [countries, dispatch, id]);

  const handleSubmit = (e, data) => {
    e.preventDefault();
    dispatch.updateUser(id, data, user.companyId);
  };

  const sendEmail = () => {
    dispatch.resendWelcomeEmail();
    dispatch.notifyEmailSent();
  };

  return !user || (loading && !sending) ? (
    <div className="loading-wrapper">
      <ReactLoading type="spin" color="#dddddd" height={128} width={128} />
    </div>
  ) : (
    <ModalContextProvider>
      <Page className="admin admin-users" title="My Profile">
        <PageInner>
          <PageHeader>
            <h1 className="page__heading">My Profile</h1>
          </PageHeader>
          <PageBody>
            <PageRow>
              {user.auth0.email_verified ? (
                <div className="message-container verified">
                  <FaCheckCircle />
                  Verified account
                </div>
              ) : (
                <div className="message-container">
                  Please verify your email.
                  <button type="button" onClick={sendEmail}>
                    Resend
                  </button>{' '}
                  verification email.
                </div>
              )}
            </PageRow>

            <PageRow>
              <EditUserForm
                user={user}
                countries={countries}
                editorId={id}
                onSubmit={handleSubmit}
                isUserAdmin={canAdministrateUsers}
                variant="profile"
              />
            </PageRow>
          </PageBody>
        </PageInner>
      </Page>
      <UserPassword userId={user.id} />
      {loading ? <LoadingSpinner className="admin-form__spinner" /> : null}
    </ModalContextProvider>
  );
};

Profile.propTypes = {
  id: PropTypes.string,
  countries: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  sending: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.string,
    companyId: PropTypes.string,
    auth0: PropTypes.shape({
      email_verified: PropTypes.bool,
    }),
  }),
  canAdministrateUsers: PropTypes.bool.isRequired,
  dispatch: PropTypes.shape({
    fetchCountries: PropTypes.func,
    updateUser: PropTypes.func,
    fetchUser: PropTypes.func,
    resendWelcomeEmail: PropTypes.func,
    notifyEmailSent: PropTypes.func,
  }),
};

Profile.defaultProps = {
  id: null,
  countries: null,
  user: null,
  loading: true,
  sending: false,
  dispatch: {
    fetchCountries: () => {},
    fetchUser: () => {},
    updateUser: () => {},
    resendWelcomeEmail: () => {},
    notifyEmailSent: () => {},
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
