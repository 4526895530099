import React, { useState, useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import partition from 'lodash/partition';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FaCopy, FaTasks, FaUser, FaTags } from 'react-icons/fa';
import Input from '../../atoms/Input/Input';
import Checkbox from '../../atoms/Checkbox/Checkbox';
import Button from '../../atoms/Buttons/Button';
import colours from '../../styles/colours.scss';
import './_tagsDropdownMenu.scss';

const TagsDropdownMenu = ({
  isOpen,
  tags,
  onConfirm,
  selectedTags,
  onSearch,
}) => {
  const DEBOUNCE_INTERVAL = 400;
  //const [filter, setFilter] = useState('');
  const [createNewTag, setCreateNewTag] = useState(false);
  const [hasMadeSelection, setHasMadeSelection] = useState(false);
  const tagListItemsRef = useRef();
  const [tagList, setTagList] = useState(tags.map(t => ({
      isSelected: selectedTags.includes(t.name),
      ...t,
    })));
  const [internalSelectedTags, setInternalSelectedTags] = useState([]);

  const renderNumber = number =>
    number > 0 ? (
      <label className="row-value">{number}</label>
    ) : (
      <div className="row-value">&#8212;</div>
    );

  const selectTag = tag => {
    const foundTag = tagList.find(tf => tf.name === tag);
    if (foundTag) {
      foundTag.isSelected = !foundTag.isSelected;
      setInternalSelectedTags([...tagList.filter(t => t.isSelected)]);
      setTagList([...tagList]);
    }
  };

  const renderEmptyTagList = () => {
    return (
      <div className="no-tags-message">
        <div className="no-tags-message-icon">
          <FaTags color={colours['dark-300']} />
        </div>
        <p className="no-tags-message-title">You don’t have any tags</p>
        <p className="no-tags-message-content">
          Assign tags to documents and tasks to easily group and find them
          later.
        </p>
      </div>
    );
  };

  const renderTagList = () =>
    tagList.map(t => {
      return (
        <div className="item-row" key={t.name}>
          <Checkbox
            variant="unpadded"
            checked={t.isSelected}
            onChange={() => selectTag(t.name)}
          />
          <div className="row-name" onClick={() => selectTag(t.name)}>
            {t.name}
          </div>
          {renderNumber(t.numberOfDocuments)}
          {renderNumber(t.numberOfTasks)}
          {renderNumber(t.numberOfUsers)}
        </div>
      );
    });

  const onTagEntered = debounce(text => {
    onSearch(text);
  }, DEBOUNCE_INTERVAL);

  const onApplyClicked = () => {
    const tagsInternallySelected = internalSelectedTags.map(t => t.name)
    onConfirm(tagsInternallySelected);
    setInternalSelectedTags([]);
  };

  useEffect(() => {
    
    let newTags = []
    if(internalSelectedTags.some(it => tags.every(t => t.name !== it.name))){
      newTags = newTags.concat([...internalSelectedTags.filter(it => tags.every(t => t.name !== it.name))])
    }
    newTags = newTags.concat(tags.map(t => ({
      isSelected: selectedTags.includes(t.name) || internalSelectedTags.some(it => it.name === t.name),
      ...t,
    })))
    setTagList(newTags.sort((a, b) => b.isSelected - a.isSelected));
    
  }, [tags, selectedTags]);

  useEffect(() => {
    const tagsInternallySelected = tagList
      .filter(t => t.isSelected)
      .map(t => t.name);

    if (
      selectedTags &&
      tagsInternallySelected &&
      selectedTags.length === tagsInternallySelected.length
    ) {
      const isIdentical =
        JSON.stringify(selectedTags.sort()) ===
        JSON.stringify(tagsInternallySelected.sort());
      setHasMadeSelection(!isIdentical);
    } else {
      setHasMadeSelection(true);
    }
  }, [tagList, selectedTags]);

  useEffect(() => {
    if (isOpen && tagListItemsRef.current) {
      tagListItemsRef.current.scrollTo({ top: 0, behavior: 'instant' });
    }
  }, [tagListItemsRef, isOpen]);

  return (
    <div
      className={classNames('tags-dropdown-menu', {
        'tags-dropdown-menu--open': isOpen,
      })}
    >
      <Input onChange={onTagEntered} isOpen={isOpen} />
      {tagList.length > 0 && (
        <>
          <div className="tags-list-container">
            <h4 className="list-headings">TAG NAME</h4>
            <FaCopy className="list-headings" color={colours['dark-300']} />
            <FaTasks className="list-headings" color={colours['dark-300']} />
            <FaUser className="list-headings" color={colours['dark-300']} />
            {createNewTag && renderCreateNewTag()}
          </div>

          <div className="tag-list-items" ref={tagListItemsRef}>
            {renderTagList()}
          </div>
          {hasMadeSelection && (
            <Button
              className="apply"
              variant="outline"
              onClick={onApplyClicked}
            >
              Apply
            </Button>
          )}
        </>
      )}
      {tagList.length === 0 && renderEmptyTagList()}
    </div>
  );
};

TagsDropdownMenu.propTypes = {
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  onSearch: PropTypes.func,
  selectedTags: PropTypes.arrayOf(PropTypes.string),
  tags: PropTypes.arrayOf(PropTypes.shape()),
};

TagsDropdownMenu.defaultProps = {
  isOpen: false,
  onConfirm: () => {},
  onSearch: () => {},
  selectedTags: [],
  tags: [],
};

export default TagsDropdownMenu;
