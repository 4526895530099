import React, { useCallback } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import history from '../../../services/historyService';
import './BackButton.scss';

const BackButton = ({ className, text, to }) => {
  const buttonClass = classNames(className, 'back-button');

  const goBack = useCallback(() => {
    history.goBack();
  }, []);

  return to ? (
    <NavLink className={buttonClass} to={to}>
      <FaChevronLeft />
      <span className="back-button__text">{text}</span>
    </NavLink>
  ) : (
    <button type="button" className={buttonClass} onClick={goBack}>
      <FaChevronLeft />
      <span className="back-button__text">{text}</span>
    </button>
  );
};

BackButton.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string,
  text: PropTypes.string,
};

BackButton.defaultProps = {
  className: null,
  to: null,
  text: 'Back',
};

export default BackButton;
