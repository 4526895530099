import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Modal from '../../UI/Modal/Modal';
import { pushSpecIfNotExist } from '../../../reducers/specs';

import '../../Form/Form.scss';
import './AddSpecModal.scss';

const mapStateToProps = (state, props) => {
  return {
    loading: state.specs.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      pushSpecIfNotExist: data => dispatch(pushSpecIfNotExist(data)),
      navigate: path => dispatch(push(path)),
    },
  };
};

const AddSpecModal = ({
  sourceData,
  isOpen,
  onCancelModal,
  onCloseModal,
  loading,
  dispatch,
}) => {
  const [specName, setSpecName] = useState('');
  const [specData, setSpecData] = useState(sourceData);
  const [validationText, setValidationText] = useState('');

  const numDots = (specName.match(/\./g) || []).length;
  const isValid =
    specName.length > 3 &&
    numDots == 1 &&
    specName.slice(-1) != '.' &&
    specName.slice(0) != '.';

  useEffect(() => {
    const spec = { name: specName, ...sourceData };
    setSpecData(spec);
    setValidationText(
      isValid ? '' : specName ? 'Invalid specification name' : '',
    );
  }, [specName, sourceData]);

  const handleConfirmClick = () => {
    dispatch
      .pushSpecIfNotExist(specData)
      .then(result =>
        result.error
          ? setValidationText(result.error)
          : dispatch.navigate(specName),
      );
  };

  const handleModalClose = () => {
    onCancelModal('isAddSpecModalOpen');
  };
  return (
    <Modal
      showCloseBtn
      isOpen={isOpen}
      disabledConfirm={!isValid || loading}
      onClose={handleModalClose}
      onCancel={handleModalClose}
      onConfirm={e => handleConfirmClick()}
      confirmLabel="Save"
      cancelLabel="Cancel"
      className="add-spec-modal"
      modalLabel="Add new specification"
    >
      <div className="add-spec-modal__inner">
        <div className="form__group">
          <h3 className="form__group-label">Name</h3>
          <input
            className="search__query"
            type="text"
            defaultValue={specName}
            onChange={e => setSpecName(e.target.value)}
            placeholder="Specification name"
          />
          <div className="validation-hint-container">
            <span className="validation-hint">{validationText}</span>
          </div>
        </div>
        <div className="add-spec-modal__summary form__group">
          <pre>{JSON.stringify(specData, null, 2)}</pre>
        </div>
      </div>
    </Modal>
  );
};

AddSpecModal.propTypes = {
  isOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
  onCancelModal: PropTypes.func,
};

AddSpecModal.defaultProps = {
  isOpen: false,
  onCancelModal: () => {},
  onCloseModal: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSpecModal);
