import * as React from 'react';
import { DocumentSplitPending } from '../../atoms/Icons/index';

function DocumentListIconSplitPending() {
  return (
    <span
      data-tooltip-id="documents-tooltip"
      data-tooltip-content="Split Pending"
      data-tooltip-place="top"
    >
      <DocumentSplitPending />
    </span>
  );
}

export default DocumentListIconSplitPending;
