import { mergeRight as merge } from 'ramda';
import { generateMonths } from '../../common/helpers/billingHelper';
import {
  RECEIVE_COMPANIES,
  REQUEST_COMPANIES,
  RECEIVE_COMPANY,
  REQUEST_COMPANY,
  RECEIVE_SUBSCRIPTIONS,
  REQUEST_SUBSCRIPTIONS,
  REQUEST_ADD_SUBSCRIPTION,
  RECEIVE_ADD_SUBSCRIPTION,
  RECEIVE_BILLING_PERIODS,
} from './actions';

const initialState = {
  companies: {
    data: null,
    pages: 0,
    loading: true,
    lastUpdated: null,
  },
  company: {
    data: null,
    loading: true,
    lastUpdated: null,
  },
  subscriptions: {
    data: null,
    loading: true,
    lastUpdated: null,
  },
  subscription: {
    data: null,
    loading: true,
    lastUpdated: null,
  },
};

const adminReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case RECEIVE_BILLING_PERIODS: {
      const { data: subscriptions } = payload;

      // create a unique list of billing periods and their products
      const billingPeriods = generateMonths(subscriptions);

      // sort descending
      billingPeriods.sort((a, b) => (a.id < b.id ? 1 : -1));

      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          billingPeriods,
        },
      };
    }

    case REQUEST_SUBSCRIPTIONS: {
      return merge(state, {
        subscriptions: {
          ...state.subscriptions,
          payload,
        },
      });
    }

    case RECEIVE_SUBSCRIPTIONS: {
      const { data, receivedAt } = payload;
      return merge(state, {
        subscriptions: {
          data,
          loading: false,
          lastUpdated: receivedAt,
        },
      });
    }

    case REQUEST_COMPANIES: {
      return merge(state, {
        companies: {
          ...state.companies,
          payload,
        },
      });
    }

    case RECEIVE_COMPANIES: {
      const { data, pages, receivedAt } = payload;
      return merge(state, {
        companies: {
          data,
          pages,
          loading: false,
          lastUpdated: receivedAt,
        },
      });
    }
    case REQUEST_COMPANY: {
      return merge(state, {
        company: payload,
      });
    }

    case RECEIVE_COMPANY: {
      const { data, receivedAt } = payload;
      return merge(state, {
        company: {
          data,
          loading: false,
          lastUpdated: receivedAt,
        },
      });
    }
    case REQUEST_ADD_SUBSCRIPTION: {
      return merge(state, {
        subscription: payload,
      });
    }

    case RECEIVE_ADD_SUBSCRIPTION: {
      const { data, receivedAt } = payload;
      return merge(state, {
        subscription: {
          data,
          loading: false,
          lastUpdated: receivedAt,
        },
      });
    }

    default:
      return state;
  }
};

export default adminReducer;
