import React from 'react';
import PropTypes from 'prop-types';
import iconWarning from '../../../../assets/icon-warning.svg';
import { Table } from '../../../UI/Table';

const LegacyPriceWarning = ({ pricingRows }) => {
  return (
    <div className="legacy_price__info">
      <img
        className="notification-icon"
        src={iconWarning}
        alt="notification icon"
      />
      <div className="legacy_price__info-message">
        <p>
          This product is still configured to use our legacy pricing structure.
        </p>
        <p>
          Please update the pricing by entering the new access fee and usage
          rates below. A summary of the legacy pricing structure is provided for
          your reference:
        </p>
        <Table className="legacy_price__info-table">
          <tr>
            <th />
            <th>First Unit</th>
            <th>Last Unit</th>
            <th>Per Unit</th>
            <th>Flat Fee</th>
          </tr>

          {pricingRows.map((p, i) => (
            <tr key={i}>
              <td>{p.name}</td>
              <td>{p.firstUnit}</td>
              <td>{p.threshold}</td>
              <td>${p.unitPrice.toFixed(2)}</td>
              <td>${p.flatPrice.toFixed(2)}</td>
            </tr>
          ))}
        </Table>
      </div>
    </div>
  );
};

LegacyPriceWarning.propTypes = {
  pricingRows: PropTypes.array.isRequired,
};

export default LegacyPriceWarning;
