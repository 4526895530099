import { useEffect } from 'react';

const setAnimate = forceAnimate => {
  const current = window.location.pathname.split('/')[1];
  if (forceAnimate) {
    localStorage.setItem('sypht.page.previous', current);
    return true;
  }

  const isAnimate = localStorage.getItem('sypht.page.previous') !== current;
  localStorage.setItem('sypht.page.previous', current);
  return isAnimate;
};

const animateFadeIn = (elem, baseClass, enterClass, activeClass) => {
  if (!elem) return;
  setTimeout(() => {
    elem.classList.add(`${baseClass}--${activeClass}`);
  }, 0);
};

const useEnterAnimation = (elemRef, params, animateOnReload = false) => {
  const isAnimate = setAnimate(animateOnReload);
  let { baseClass, enterClass, activeClass } = params;
  if (typeof params === 'string') {
    baseClass = params;
    enterClass = `enter`;
    activeClass = `enter-active`;
  }

  useEffect(() => {
    if (isAnimate) {
      animateFadeIn(elemRef.current, baseClass, enterClass, activeClass);
    }
  }, [elemRef]);

  return isAnimate ? `${baseClass}--${enterClass}` : null;
};

export default useEnterAnimation;
