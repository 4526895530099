import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { FaCheck as IconCheck } from 'react-icons/fa';
import PropTypes from 'prop-types';
import '../../../UI/Checkbox/Checkbox.scss';

const Checkbox = ({
  id,
  className,
  hidden,
  label,
  labelPosition,
  size,
  forwardRef,
  ...props
}) => {
  const checkboxRef = useRef(null);
  const { name, value, defaultChecked, disabled } = props;
  const checkboxId = id || `${name}_${value}`;
  const checkboxClass = classNames(
    className,
    'checkbox',
    size ? `checkbox--${size}` : null,
    label ? `checkbox--label-${labelPosition}` : null,
    disabled ? 'checkbox--disabled' : null,
  );

  // Sync checkbox on defaultChecked change
  useEffect(() => {
    if (checkboxRef.current && checkboxRef.current.checked !== defaultChecked) {
      checkboxRef.current.checked = defaultChecked;
    }
  }, [defaultChecked]);

  return (
    <label className={checkboxClass} htmlFor={checkboxId}>
      <input
        id={checkboxId}
        className="checkbox__input"
        type="checkbox"
        ref={forwardRef || checkboxRef}
        {...props}
      />
      <span className="checkbox__box">
        <IconCheck className="checkbox__icon" />
      </span>
      {label ? <span className="checkbox__label">{label}</span> : null}
    </label>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  label: PropTypes.string,
  labelPosition: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  forwardRef: PropTypes.any,
};

Checkbox.defaultProps = {
  id: '',
  className: '',
  defaultChecked: false,
  disabled: false,
  hidden: false,
  label: '',
  labelPosition: 'right',
  value: '',
  size: 'md',
  forwardRef: null,
};

export default Checkbox;
