import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ConfirmModal } from '../../../../../UI/ModalV2';

const noOfEntities = tags => {
  const noOfDocs = tags.reduce((n, t) => n + t.numberOfDocuments, 0);
  const noOfTasks = tags.reduce((n, t) => n + t.numberOfTasks, 0);
  const noOfUsers = tags.reduce((n, t) => n + t.numberOfUsers, 0);
  return noOfDocs + noOfTasks + noOfUsers;
};

// prettier-ignore
const getEntities = tags => {
  const strArr = [];

  // n: total, t: tag
  const noOfDocs = tags.reduce((n, t) => n + t.numberOfDocuments, 0);
  const noOfTasks = tags.reduce((n, t) => n + t.numberOfTasks, 0);
  const noOfUsers = tags.reduce((n, t) => n + t.numberOfUsers, 0);

  if (noOfDocs > 0) strArr.push(`${noOfDocs} documents`);
  if (noOfTasks > 0) strArr.push(`${noOfTasks} tasks`);
  if (noOfUsers) strArr.push(`${noOfUsers} users`);

  if (strArr.length === 1) return strArr[0];
  return strArr.join(', ').replaceLast(', ', ' and ');
};

const DeleteModal = ({ tag, tags, onConfirmClick, ...props }) => {
  if (tag) {
    return (
      <ConfirmModal
        className="tags__delete-modal"
        heading={`Delete ${tag.name}?`}
        confirmText="Delete"
        onConfirmClick={onConfirmClick}
        {...props}
      >
        Are you sure you want to delete <strong>{tag.name}</strong>?{' '}
        {noOfEntities([tag]) > 0 && (
          <>This will remove the tag from {getEntities([tag])}.</>
        )}
      </ConfirmModal>
    );
  }

  if (tags) {
    return (
      <ConfirmModal
        className="tags__delete-modal"
        heading={`Delete ${tags.length} tags?`}
        confirmText="Delete"
        onConfirmClick={onConfirmClick}
        {...props}
      >
        Are you sure you want to delete the following tags?
        {tags.length > 0 && (
          <>
            <ul>
              {tags.map(t => (
                <li key={`delete|${t.id}`}>
                  <strong>{t.name}</strong>
                  {t.isAccessTag && <em> (access control)</em>}
                </li>
              ))}
            </ul>
            This will remove these tags from {getEntities(tags)}.
          </>
        )}
      </ConfirmModal>
    );
  }
};

DeleteModal.propTypes = {
  tag: PropTypes.object,
  tags: PropTypes.arrayOf(PropTypes.object),
  onConfirmClick: PropTypes.func,
};

DeleteModal.defaultProps = {
  tag: null,
  tags: null,
  onConfirmClick: () => {},
};

export default DeleteModal;
