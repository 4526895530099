import React from 'react';
import PropTypes from 'prop-types';

import './_modal-footer.scss';

const ModalFooter = ({ children }) => (
  <div className="m-modal__footer">{children}</div>
);

ModalFooter.propTypes = {
  children: PropTypes.node,
};

ModalFooter.defaultProps = {
  children: null,
};

export default ModalFooter;
