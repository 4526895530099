import React, { useState, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import { FaPencilAlt, FaPlay, FaQuestionCircle } from 'react-icons/fa';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import moment from 'moment';
import classNames from 'classnames';
import Annotation from './Annotation';
import MultiValueAnnotation from './MultiValueAnnotation';
import TaskContext from './TaskContext';
import Accordion from '../UI/Accordion/Accordion';
import { Button } from '../../library/atoms/Buttons';
import { resetDocProcessed } from '../../reducers/upload';
import './Task.scss';
import { TaskTypeLookup } from '../../common/lookups';

const mapDispatchToProps = dispatch => {
  return {
    navigate: path => dispatch(replace(path)),
    resetDocProcessedState: () => dispatch(resetDocProcessed()),
  };
};

const mapStateToProps = state => {
  const task = state.tasks.active.data ? state.tasks.active.data.task : null;
  return {
    user: state.auth.user ? state.auth.user.data : null,
    isFixTask: task && task.taskType === TaskTypeLookup.FIX,
    uploadDocProcessed: state.upload.active.processed,
    fieldParams:
      task && task.parameters && task.parameters.fieldParams
        ? task.parameters.fieldParams
        : null,
  };
};

const AnnotationList = ({
  doc,
  annotations,
  candidateSelection,
  editable,
  fieldParams,
  fields,
  focusPrediction,
  predictions,
  saveAnnotations,
  saveAnnotationState,
  setPredictionFocus,
  state,
  flaggedReason,
  undoable,
  updated,
  initAnnotations,
  collapseSelections,
  tableChange,
  isFixTask,
  navigate,
  uploadDocProcessed,
  resetDocProcessedState,
}) => {
  const [focusTargetField, setFocusTargetField] = useState(null);
  const [focusField, setFocusField] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const ref = createRef();
  const [redirectPending, setRedirectPending] = useState(false);

  useEffect(() => {
    if (
      ref &&
      !ref.current.contains(document.activeElement) &&
      focusPrediction == null
    ) {
      ref.current.focus();
    }

    if (ref && !ref.current.contains(document.activeElement)) {
      ref.current.focus();
    }
  }, [focusPrediction, ref]);

  useEffect(() => {
    if (redirectPending && state === 'submitted' && uploadDocProcessed) {
      navigate(`/documents/${doc.id}`);
    }
  }, [redirectPending, uploadDocProcessed, doc.id, navigate, state]);

  const onSetPredictionFocus = (f, p) => {
    const newTarget = f && f.id === focusTargetField ? null : focusTargetField;
    setFocusTargetField(newTarget);
    setFocusField(p ? f.id : null);

    setPredictionFocus(p);
  };

  const getAnnotationsByField = () => {
    const annotationsByField = {};
    if (annotations && annotations.fields && annotations.fields.length > 0) {
      annotations.fields.forEach(a => {
        annotationsByField[a.id] = a;
      });
    }
    return annotationsByField;
  };

  const isUserEditable = () => {
    return editable && state == null;
  };

  const navigateFocusFromField = (fid, offset) => {
    const annotationsByField = getAnnotationsByField();
    const focusIdx = fields.findIndex(f => f.id === fid);
    const nextFields = (
      fid !== null
        ? fields
            .slice(focusIdx + 1, fields.length)
            .concat(fields.slice(0, focusIdx))
        : fields
    ).filter(f => !annotationsByField[f.id]);

    if (offset < 0) {
      nextFields.reverse();
    }

    const nextField = nextFields[Math.abs(offset) - 1];
    if (nextField) {
      setTimeout(() => setFocusTargetField(nextField.id), 1);
    }
  };

  const isUserSubmittable = () => {
    const numAnnotations = Object.keys(getAnnotationsByField()).length;

    return (
      isUserEditable() && state == null && numAnnotations === fields.length
    );
  };

  const isUserFlaggable = () => {
    return isUserEditable() && state == null;
  };

  const toggleEditMode = flag => {
    setEditMode(flag);
  };

  const handleKeyDown = e => {
    let handled = true;

    if (isUserEditable()) {
      if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        e.preventDefault();
      } else if (e.key === 'Tab') {
        e.preventDefault();
        navigateFocusFromField(focusField, e.shiftKey ? -1 : 1);
      } else if (
        e.key === 'Enter' &&
        (e.metaKey || e.ctrlKey) &&
        isUserSubmittable()
      ) {
        e.preventDefault();
        saveAnnotationState('submitted', null, true);
      } else if (
        e.key === 'f' &&
        (e.metaKey || e.ctrlKey) &&
        e.shiftKey &&
        isUserFlaggable()
      ) {
        e.preventDefault();
        saveAnnotationState('flagged', 'document', true);
      } else {
        handled = false;
      }
    }

    if (handled) {
      e.stopPropagation();
    }
  };

  const getProgress = (field, data, unpack = true) => {
    let progress = [];
    if (field.multiple && data && data.value && unpack) {
      progress = progress.concat(
        data.value.map(v => getProgress(field, v, false)).flat(),
      );
    } else if (field.components && data && data.fields) {
      progress = progress.concat(
        field.components
          .map(c =>
            getProgress(
              c,
              data.fields.find(v => v.id === c.id),
            ),
          )
          .flat(),
      );
    }
    progress.push({
      field,
      annotated: !!(progress.every(p => p.annotated) && data),
    });
    return progress;
  };

  const predictionsByField = {};
  if (predictions && predictions.length > 0) {
    predictions.forEach(p => {
      predictionsByField[p.id] = p.predictions;
    });
  }

  fields.forEach(f => {
    if (!(f.id in predictionsByField) && !f.multiple) {
      predictionsByField[f.id] = [
        {
          field: f.id,
          value: null,
          value_norm: null,
          confidence: 0,
          confidence_norm: 0,
          bounds: null,
        },
      ];
    }
  });

  const annotationsByField = getAnnotationsByField();
  const onSaveAnnotation = (field, annotation) => {
    const currentTime = new Date().getTime();
    if (field.type === 'table') {
      tableChange(annotation.value);
    }
    saveAnnotations({
      fields: fields
        .filter(f => f.id === field.id || f.id in annotationsByField)
        .map(f => {
          if (f.id === field.id) {
            // eslint-disable-next-line no-param-reassign
            annotation.created = annotationsByField[f.id]
              ? annotationsByField[f.id].created
              : currentTime;
            // eslint-disable-next-line no-param-reassign
            annotation.updated = currentTime;
            return annotation;
          }
          return annotationsByField[f.id];
        }),
    });

    // This condition seems to be a proxy for when user clicks eg the "+" button
    // to add a new table to label a new table of lineitems in the doc (at least
    // for the case when field.type === 'table:2') .  We save the action of
    // creating this new table here but we need to keep interacting with it to
    // draw the table and identity rows/cols etc, so we don't want to navigate
    // away from it.  To test, annotate a new doc with lineitems using
    // invoices:2 or ndis-claims:2 and click the '+' on lineitems:3 panel
    // header.
    if (
      field.type !== 'group' &&
      field.type !== 'table' &&
      field.type !== 'table:2' &&
      !annotationsByField[field.id]
    ) {
      navigateFocusFromField(field.id, 1);
    }
  };

  const fieldProgress = fields
    .map(f => getProgress(f, annotationsByField[f.id]))
    .flat();

  const currentProgress = fieldProgress.filter(
    i => i.annotated === true,
  ).length;
  const totalProgress = fieldProgress.length ? fieldProgress.length : 1;
  const progress = Math.round((100 * currentProgress) / totalProgress);
  const userEditable = isUserEditable();

  const hasInitAnnotations = initAnnotations.length > 0;

  const FIELD_GROUPING = [
    {
      label: 'Fields For Review',
      value: fields.filter(f => initAnnotations.indexOf(f.id) < 0),
    },
    {
      label: 'Processed Fields',
      value: fields.filter(f => initAnnotations.indexOf(f.id) > -1),
    },
  ];

  const renderAnnotation = (f, i) =>
    f.multiple ? (
      <MultiValueAnnotation
        key={i}
        field={f}
        prediction={predictionsByField[f.id]}
        fieldPath={f.id}
        fieldParams={fieldParams}
        focusPrediction={focusPrediction}
        hasFocus={focusField === f.id}
        setPredictionFocus={p => onSetPredictionFocus(f, p)}
        candidateSelection={candidateSelection}
        annotation={annotationsByField[f.id]}
        editable={userEditable}
        saveAnnotation={a => onSaveAnnotation(f, a)}
        needsFocus={focusTargetField === f.id}
        progress={progress === 100}
      />
    ) : (
      <Annotation
        key={i}
        field={f}
        fieldPath={f.id}
        fieldParams={fieldParams ? fieldParams[f.id] : null}
        prediction={predictionsByField[f.id]}
        focusPrediction={focusPrediction}
        hasFocus={focusField === f.id}
        setPredictionFocus={p => onSetPredictionFocus(f, p)}
        candidateSelection={candidateSelection}
        annotation={annotationsByField[f.id]}
        editable={userEditable}
        saveAnnotation={a => onSaveAnnotation(f, a)}
        needsFocus={focusTargetField === f.id}
        progress={progress === 100}
      />
    );

  const createSubmitButton = () => {
    const buttonText = isFixTask ? 'Save' : 'Submit';
    const toolTipText = isFixTask ? 'Save corrections' : 'Mark as complete';

    const onClickSaveBtn = () => {
      saveAnnotationState('submitted', null);
      if (isFixTask) {
        resetDocProcessedState();
        setRedirectPending(true);
      }
    };

    return (
      <div
        className={classNames(
          'submission',
          { regular: !isFixTask },
          { quickfix: isFixTask },
        )}
      >
        <button
          type="button"
          id="submitAnnotationBtn"
          data-tooltip-content={toolTipText}
          onClick={() => onClickSaveBtn()}
        >
          {buttonText}
        </button>
        {!isFixTask && (
          <button
            type="button"
            data-tooltip-content="Mark as complete and launch next task"
            onClick={() => saveAnnotationState('submitted', null, true)}
          >
            <FaPlay />
          </button>
        )}
      </div>
    );
  };

  const onEditClicked = () => {
    saveAnnotationState('null');
    setPredictionFocus(null);
  };

  return (
    <TaskContext.Provider
      value={{
        editMode,
        toggleEditMode,
      }}
    >
      <div className="annotation-list" ref={ref} onKeyDown={handleKeyDown}>
        {state ? (
          <div className="state-description">
            <div className="state-toolbar">
              <div
                className={classNames(
                  'state',
                  state,
                  state === 'flagged' && `flagged-${flaggedReason}`,
                )}
              >
                {state}
              </div>
              {undoable && (
                <button
                  type="button"
                  className="state-undo"
                  onClick={onEditClicked}
                >
                  <FaPencilAlt className="state-undo-icon" />
                  Edit
                </button>
              )}
            </div>
            <div>{moment(updated).fromNow()}</div>
          </div>
        ) : null}
        {userEditable && currentProgress >= 1 ? (
          <div className="progress">
            {currentProgress >= totalProgress && !editMode ? (
              createSubmitButton()
            ) : (
              <div className="bar" style={{ position: 'relative' }}>
                <div style={{ flexBasis: `${progress}%` }} />
                <div style={{ flexBasis: `${1 - progress}%` }} />
                <div className="progress-text">
                  <div className="progress-text_left">
                    {currentProgress} /{totalProgress} Complete
                  </div>
                  <div className="progress-text_right">{progress}%</div>
                </div>
              </div>
            )}
          </div>
        ) : null}
        <div className="scroll-container">
          <div className="scroll-content">
            <div className="annotation-list-container">
              {hasInitAnnotations && collapseSelections
                ? FIELD_GROUPING.map(fg => {
                    return (
                      <Accordion
                        className="task-accordion"
                        iconPos="left"
                        defaultExpanded
                      >
                        <Accordion.Header>
                          {fg.label}{' '}
                          <span className="count">{`(${fg.value.length})`}</span>
                        </Accordion.Header>
                        <Accordion.Panel>
                          {fg.value.map((f, i) => renderAnnotation(f, i))}
                        </Accordion.Panel>
                      </Accordion>
                    );
                  })
                : fields.map((f, i) => renderAnnotation(f, i))}
            </div>
          </div>
        </div>
        {userEditable && !isFixTask && (
          <div className="footer-container">
            <div className="flag-container">
              <Button
                className="btn-flag"
                onClick={() => saveAnnotationState('flagged', 'task')}
                variant="outline"
                size="sm"
                fluid
              >
                <FaQuestionCircle />
                <span>Request help with this task</span>
              </Button>
            </div>
          </div>
        )}
      </div>
    </TaskContext.Provider>
  );
};

AnnotationList.defaultProps = {
  doc: { id: '' },
  annotations: {},
  candidateSelection: {},
  editable: false,
  fieldParams: null,
  fields: [],
  focusPrediction: {},
  predictions: [],
  saveAnnotations: () => {},
  saveAnnotationState: () => {},
  setPredictionFocus: () => {},
  state: null,
  flaggedReason: null,
  undoable: false,
  updated: '',
  initAnnotations: [],
  collapseSelections: false,
  tableChange: () => {},
  isFixTask: false,
  navigate: () => {},
};

AnnotationList.propTypes = {
  doc: PropTypes.shape({
    id: PropTypes.string,
  }),
  annotations: PropTypes.shape(),
  candidateSelection: PropTypes.shape(),
  editable: PropTypes.bool,
  fieldParams: PropTypes.shape(),
  fields: PropTypes.arrayOf(PropTypes.shape()),
  focusPrediction: PropTypes.shape(),
  predictions: PropTypes.arrayOf(PropTypes.shape()),
  saveAnnotations: PropTypes.func,
  saveAnnotationState: PropTypes.func,
  setPredictionFocus: PropTypes.func,
  state: PropTypes.string,
  flaggedReason: PropTypes.string,
  undoable: PropTypes.bool,
  updated: PropTypes.string,
  initAnnotations: PropTypes.arrayOf(PropTypes.shape()),
  collapseSelections: PropTypes.bool,
  tableChange: PropTypes.func,
  isFixTask: PropTypes.bool,
  navigate: PropTypes.func,
  uploadDocProcessed: PropTypes.bool.isRequired,
  resetDocProcessedState: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnnotationList);
