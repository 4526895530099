import { addError, removeError } from './index';

const validateEmail = ({ elem, errors, setErrors }) => {
  const { name: key, value } = elem;
  const message = `Invalid email address.`;

  if (value.length === 0) {
    removeError(key, message, errors, setErrors);
    return {
      isValid: true,
    };
  }

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
    addError(key, message, errors, setErrors);
    return {
      isValid: false,
    };
  }

  removeError(key, message, errors, setErrors);
  return {
    isValid: true,
  };
};

export default validateEmail;
