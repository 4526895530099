import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import { FaCut } from 'react-icons/fa';
import ChildPage from './ChildPage';
import { Button } from '../../../../library/atoms/Buttons';
import MergeSplit from '../../../../assets/merge-split.svg';

const ChildDocument = ({
  childDoc,
  pages,
  isLastChildDoc,
  removeSplit,
  nextChildDoc,
  addSplit,
}) => {
  return (
    <div className="container" id={childDoc.id}>
      <div className="title">{childDoc.title}</div>
      <Droppable droppableId={childDoc.id} direction="horizontal">
        {(provided, snapshot) => (
          <div
            className={classNames('child-page-list', {
              isDraggingOver: snapshot.isDraggingOver,
            })}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {pages.map((page, index) => (
              <Fragment key={`Page-Wrapper-${index}`}>
                <ChildPage key={page.id} page={page} index={index} />
                {index < pages.length - 1 && (
                  <div className="doc-split__horizontal-divider">
                    <div className="doc-split__horizontal-divider-line" />
                    <Button
                      onClick={() => addSplit(childDoc, index)}
                      hasIcon
                      small="true"
                      className="doc-split__horizontal-divider-remove-btn"
                    >
                      <FaCut />
                    </Button>
                  </div>
                )}
              </Fragment>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {!isLastChildDoc && (
        <div className="doc-split__divider">
          <div className="doc-split__divider-line" />
          <Button
            onClick={() => removeSplit(childDoc, nextChildDoc)}
            hasIcon
            small="true"
            className="doc-split__divider-remove-btn"
          >
            <img
              src={MergeSplit}
              className="merge-split--icon"
              alt="merge split icon"
            />
          </Button>
        </div>
      )}
    </div>
  );
};

ChildDocument.defaultProps = {
  childDoc: {},
  pages: [],
  isLastChildDoc: false,
  removeSplit: () => {},
  nextChildDoc: {},
  addSplit: () => {},
};

ChildDocument.propTypes = {
  childDoc: PropTypes.object,
  pages: PropTypes.array,
  isLastChildDoc: PropTypes.bool,
  removeSplit: PropTypes.func,
  nextChildDoc: PropTypes.object,
  addSplit: PropTypes.func,
};

export default ChildDocument;
