import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { EllipsisButton } from '../../atoms/Buttons';
import SelectButton from './types/SelectButton';
import './_dropdown.scss';
import useOnClickOutside from '../../../common/helpers/useOnClickOutside';

const Dropdown = ({
  children,
  className,
  type,
  isOpen,
  defaultOpen,
  hasValue,
  placeholder,
  onClear,
  onClick,
  onBlur,
  active,
  position,
  autoWidth,
  ...props
}) => {
  const [open, setOpen] = useState(isOpen || defaultOpen);
  const dropdownRef = useRef();
  useOnClickOutside(dropdownRef, () => {
    setOpen(false);
    onBlur();
  });

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClick = e => {
    if (active) {
      const newOpen = !open;
      setOpen(newOpen);
      onClick(e, newOpen);
    }
  };

  return (
    <div
      className={classNames('m-dropdown', className, {
        'm-dropdown--open': open,
        'm-dropdown--disabled': !active,
      })}
      ref={dropdownRef}
    >
      {type === 'ellipsis' ? (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <EllipsisButton onClick={handleClick} {...props} />
      ) : (
        <SelectButton
          placeholder={placeholder}
          onClear={onClear}
          onClick={handleClick}
          hasValue={hasValue}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      )}
      <div
        className={classNames(
          'm-dropdown__content',
          position === 'right' && 'm-dropdown__content--right',
          autoWidth && 'm-dropdown__content--autowidth',
        )}
      >
        {React.Children.map(children, child =>
          React.cloneElement(child, {
            /**
             * Intercept item clicks and close the dropdown.
             */
            onItemClick: (...args) => {
              if (child.props.onItemClick) {
                child.props.onItemClick(...args);
              }
              setOpen(false);
            },
          }),
        )}
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  type: PropTypes.oneOf(['ellipsis', 'select']),
  isOpen: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  hasValue: PropTypes.bool,
  /**
   * Set this to show the Clear (X) button.
   */
  onClear: PropTypes.func,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  active: PropTypes.bool,
  /**
   * The dropdown body is positioned absolutely.  By default it will render to
   * the left below the button.  You can set 'right' to make the body align its
   * right edge.
   */
  position: PropTypes.oneOf(['left', 'right']),
  /**
   * Use width auto instead of default width 100%.  You may want this if using
   * position=right.  Or set the width of the content inside the dropdown.
   */
  autoWidth: PropTypes.bool,
};

Dropdown.defaultProps = {
  children: null,
  className: undefined,
  placeholder: undefined,
  type: 'select',
  isOpen: undefined,
  defaultOpen: undefined,
  hasValue: false,
  onClear: undefined,
  onClick: () => {},
  onBlur: () => {},
  active: true,
  position: 'left',
  autoWidth: false,
};

export default Dropdown;
