/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { MdClose as CloseIcon } from 'react-icons/md';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './Modal.scss';

const fadeOutModal = (elem, container) => {
  document.body.classList.add('modal__blur--exit');
  document.body.classList.remove('modal__blur');
  setTimeout(() => {
    document.body.classList.remove('modal__blur--exit');
  }, 500);

  if (elem) elem.classList.add('modal--exit');

  if (container) {
    container.classList.add('modal__container--exit');
    setTimeout(() => {
      container.remove();
    }, 500);
  }
};

const Modal = ({
  children,
  className,
  container,
  heading,
  open,
  onBgClick,
  onCloseClick,
}) => {
  const modalRef = useRef(null);
  const focusRef = useRef(null);
  const [isOpen, setOpen] = useState(open);

  const closeModal = () => {
    fadeOutModal(modalRef.current, container);
    setTimeout(() => {
      setOpen(false);
    }, 500);
  };

  const handleBgClick = e => {
    onBgClick(e);
    closeModal();
  };

  const handleClose = e => {
    onCloseClick(e);
    closeModal();
  };

  const handleEsc = e => {
    if (e.key === 'Escape') {
      closeModal();
    }
  };

  useEffect(() => {
    if (focusRef.current) {
      focusRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (!open) {
      closeModal();
    } else {
      setOpen(open);
    }
  }, [open]);

  return !isOpen ? null : (
    <>
      {onBgClick === null ? (
        <div className="modal__bg" />
      ) : (
        <div
          className="modal__bg"
          onClick={handleBgClick}
          onKeyUp={handleEsc}
          role="button"
        />
      )}
      <div
        className={classNames('modal', className)}
        role="dialog"
        ref={modalRef}
      >
        {heading || onCloseClick ? (
          <div className="modal__header">
            {!heading ? null : <h2 className="modal__heading">{heading}</h2>}
            {onCloseClick === null ? null : (
              <button
                type="button"
                className="modal__close"
                onClick={handleClose}
              >
                <CloseIcon className="modal__close-icon" />
              </button>
            )}
          </div>
        ) : null}
        {children}
      </div>
    </>
  );
};

Modal.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  container: PropTypes.object,
  heading: PropTypes.node || PropTypes.string,
  onBgClick: PropTypes.func || null,
  onCloseClick: PropTypes.func || null,
};

Modal.defaultProps = {
  children: null,
  className: null,
  container: null,
  heading: null,
  open: true,
  onBgClick: () => {},
  onCloseClick: () => {},
};

export default Modal;
