import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextArea } from '../../../../../components/UI/TextArea';

const TextAreaCell = ({
  value: initialValue,
  column,
  row,
  editable: isEditable,
  handlers,
}) => {
  // const [value, setValue] = React.useState(initialValue);
  //
  // useEffect(() => {
  //   setValue(initialValue);
  // }, [initialValue]);
  //
  // const handleChange = e => {
  //   setValue(e.target.value);
  // };

  const handleBlur = e => {
    // handlers.onInputBlur(row.index, column.id, value, row.original);
  };

  if (!isEditable) {
    return <span className="table__value">${initialValue}</span>;
  }

  // return <input value={value} onChange={handleChange} onBlur={handleBlur} />;
  return (
    <TextArea defaultValue={initialValue} onBlur={handleBlur}>
      {initialValue}
    </TextArea>
  );
};

TextAreaCell.propTypes = {
  column: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  value: PropTypes.any,
  editable: PropTypes.bool,
  handlers: PropTypes.object,
};

TextAreaCell.defaultProps = {
  value: undefined,
  editable: false,
  handlers: {
    onInputBlur: () => {},
  },
};

export default TextAreaCell;
