import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FaExclamationCircle, FaCheck } from 'react-icons/fa';
import ContentFieldAnnotation from './ContentFieldAnnotation';
import OptionFieldAnnotation from './OptionFieldAnnotation';
import EntityAnnotation from './EntityAnnotation/EntityAnnotation';
import TableAnnotation from './TableAnnotation/TableAnnotation';
import * as helper from './helper';

const mapStateToProps = state => {
  return {
    loadingData: state.tasks.active.loadingData,
  };
};

const Annotation = props => {
  const {
    field,
    loadingData,
    fieldParams,
    editable,
    hasFocus,
    fieldName,
    annotation,
  } = props;

  const isOptionalFieldAnnotation = helper.isOptionalFieldAnnotation(field);
  const isEntityFieldAnnotation = helper.isEntityFieldAnnotation(field);
  const isTableAnnotation = helper.isTableAnnotation(field);
  const isContentFieldAnnotation = helper.isContentFieldAnnotation(field);

  const hasAnnotation = annotation && annotation.data;

  return (
    <div
      className={`annotation${loadingData ? ' disabled' : ''}${
        editable ? ' editable' : ''
      }`}
    >
      <div
        className="field-name"
        data-tooltip-html={`<div class="field-hint">${field.description}</div>`}
        data-tooltip-place="right"
        data-tooltip-id="task-tooltip"
      >
        {fieldName || field.name}
        {hasAnnotation && <FaCheck className="field-status" />}
      </div>

      {fieldParams && fieldParams.notes && (
        <div className="field-note-container">
          <div className="field-note-header">
            <FaExclamationCircle />
          </div>
          <div className="field-note-content">{fieldParams.notes.text}</div>
        </div>
      )}
      {isOptionalFieldAnnotation && <OptionFieldAnnotation {...props} />}
      {isContentFieldAnnotation && <ContentFieldAnnotation {...props} />}
      {isEntityFieldAnnotation && <EntityAnnotation {...props} />}
      {isTableAnnotation && <TableAnnotation {...props} />}
    </div>
  );
};

Annotation.propTypes = {
  field: PropTypes.object.isRequired,
  loadingData: PropTypes.bool,
  fieldParams: PropTypes.object,
};

Annotation.defaultProps = {
  loadingData: false,
  fieldParams: null,
};

export default connect(mapStateToProps)(Annotation);
