import React, { Fragment } from 'react';
import classNames from 'classnames';
import {
  FaChartLine,
  FaAngleDoubleDown,
  FaAngleDown,
  FaAngleDoubleUp,
  FaAngleUp,
  FaEquals,
  FaArrowsAltH,
} from 'react-icons/fa';

const FieldStatisticsValue = ({ field }) => {
  return (
    <div className={classNames('field', 'stats')}>
      <h3>{field.fieldName}</h3>
      <div className="stats-header">
        <FaChartLine /> {field.source.source}
      </div>
      <div className="stats-summary">
        <h3>Percentile</h3>
        <div className="stat-item">
          {Math.round(field.value_norm.percentile_rank * 10) / 10}%
          {field.value_norm.percentile_rank > 70 ? (
            <FaAngleDoubleUp className="large-increase" />
          ) : field.value_norm.percentile_rank > 50 ? (
            <FaAngleUp className="increase" />
          ) : field.value_norm.percentile_rank < 70 ? (
            <FaAngleDoubleDown className="decrease" />
          ) : field.value_norm.percentile_rank < 50 ? (
            <FaAngleDown className="large-decrease" />
          ) : (
            <FaEquals />
          )}
        </div>
        <h3>Average</h3>
        <div className="stat-item">
          ${Math.round(field.value_norm.avg * 10) / 10}
        </div>
        <h3>Range</h3>
        <div className="stat-item">
          ${Math.round(field.value_norm.min * 10) / 10}
          <FaArrowsAltH />${Math.round(field.value_norm.max * 10) / 10}
        </div>
      </div>
    </div>
  );
};

export default FieldStatisticsValue;
