import { useEffect, useState } from 'react';
import { mergeRight as merge } from 'ramda';
import { validate, updateField } from '../validators';

const initForm = initialState => {
  const fields = {};
  Object.keys(initialState).forEach(key => {
    const required = !!initialState[key].required;

    let value = '';
    if ('checked' in initialState[key]) {
      value = initialState[key].checked;
    }

    if (key.endsWith('[]')) {
      value = [];
    }

    fields[key] = merge(
      {
        value,
        required,
        isValid: !initialState[key].required,
      },
      initialState[key],
    );
  });

  return fields;
};

const isFormValid = fields => {
  const invalidFields = Object.values(fields).reduce(
    (total, f) => total + !f.isValid,
    0,
  );
  return invalidFields === 0;
};

const useForm = initialState => {
  const [fields, setFields] = useState(initForm(initialState));
  const [errors, setErrors] = useState({});
  const [isValid, setValid] = useState(false);

  useEffect(() => {
    setValid(isFormValid(fields));
  }, [fields]);

  const reset = () => {
    setFields(initForm(initialState));
  };

  const handleBlur = e => {
    validate({ elem: e.target, fields, setFields, errors, setErrors });
  };

  const validateIfAutoFilled = e => {
    if (document.activeElement !== e.target) {
      const elem = e.target;
      const { value } = e.target;
      if (Object.keys(fields).includes(elem.name)) {
        fields[elem.name].value = value;
        validate({ elem, fields, setFields, errors, setErrors });
      }
    }
  };

  const handleChange = (e, newValue) => {
    let { value } = e.target;
    const { name, type, checked } = e.target;

    // handle checkbox
    if (type === 'checkbox' && !name.endsWith('[]')) {
      value = newValue || e.target.checked;
    }

    // handle checkbox group
    if (type === 'checkbox' && name.endsWith('[]')) {
      const newValues = [...fields[name].value];
      if (checked) {
        if (!newValues.includes(value)) {
          newValues.push(value);
        }
      } else {
        const valueIndex = newValues.indexOf(value);
        if (valueIndex > -1) {
          newValues.splice(valueIndex, 1);
        }
      }
      value = newValues;
    }

    // update field
    updateField(
      {
        [name]: {
          value,
        },
      },
      fields,
      setFields,
    );

    if (type === 'text' || type === 'email' || type === 'password') {
      validateIfAutoFilled(e);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
  };

  return [
    {
      fields,
      errors,
      setErrors,
      isValid,
      reset,
      handleBlur,
      handleChange,
      handleSubmit,
    },
  ];
};

export default useForm;
