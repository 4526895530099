import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Slider from '../../atoms/Slider';
import { Grid, GridItem } from '../../templates/Grid';
import { setVisibleTiers } from '../../../components/Marketplace/helpers';

import './BillingCalculator.scss';

const MAX_THRESHOLD = 30000;

const BillingCalculator = ({ pricing, accessFee }) => {
  const { tiers } = pricing;
  const visibleTiers = setVisibleTiers(tiers);

  const minSpend = accessFee || tiers[0].flatPrice || 0;
  const [monthlySpend, setMonthlySpend] = useState(minSpend);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const minThreshold =
    pricing.tiers.length > visibleTiers.length ? tiers[0].threshold : 0;

  const handleSliderChange = noOfUnits => {
    const activeTier = visibleTiers.reduce((t, next) => {
      if (noOfUnits >= t.from && noOfUnits <= t.to) {
        return t;
      }
      return next;
    }, visibleTiers[0]);

    let totalSpend = 0;
    visibleTiers.forEach(t => {
      if (noOfUnits >= t.from) {
        totalSpend += t.basePrice;
      }
    });
    totalSpend += (noOfUnits - activeTier.last) * activeTier.unitPrice;

    if (totalSpend > minSpend) setMonthlySpend(totalSpend + accessFee);
    else setMonthlySpend(minSpend);

    setNumberOfPages(noOfUnits / 1000);
  };

  return (
    <>
      <div className="estimated-spend">Estimate spend</div>
      <Grid colGap="primary">
        <GridItem col="9">
          <div className="slider-container">
            <div className="slider-info-message">
              Slide to estimate your monthly spend
            </div>
            <Slider
              min={minThreshold}
              max={MAX_THRESHOLD}
              step={100}
              onValueChanged={handleSliderChange}
            />
            <div className="slider-number-of-pages">
              {' '}
              {numberOfPages}/30k pages
            </div>
          </div>
        </GridItem>
        <GridItem col="3">
          <div className="estimate-amount-info">From</div>
          <div className="estimate-amount">
            <span>${Math.round(monthlySpend)}</span>
            <span> / mth</span>
          </div>
        </GridItem>
      </Grid>
    </>
  );
};

BillingCalculator.propTypes = {
  pricing: PropTypes.shape({
    tiers: PropTypes.arrayOf(
      PropTypes.shape({
        flatPrice: PropTypes.number,
        threshold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
  }),
  accessFee: PropTypes.number,
};

BillingCalculator.defaultProps = {
  pricing: {
    tiers: [
      { name: 'Base', flatPrice: 9, threshold: 5, unitPrice: 0 },
      { name: 'High', flatPrice: 0, threshold: 'inf', unitPrice: 0.1 },
    ],
  },
  accessFee: 0,
};

export default BillingCalculator;
