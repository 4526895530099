/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import { clone } from 'ramda';
import moment from 'moment';
import axios from 'axios';
import { v4 as uuid } from 'uuid';

export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS';
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
export const REQUEST_ALL_FIELDSET_USAGES = 'REQUEST_ALL_FIELDSET_USAGES';
export const RECEIVE_ALL_FIELDSET_USAGES = 'RECEIVE_ALL_FIELDSET_USAGES';

const API_HOST = process.env.REACT_APP_API_HOST;
const analyticsServiceUrl =
  process.env.ANALYTIC_SERVICE_HOST_NAME || process.env.REACT_APP_API_HOST;

const initialState = {
  loading: false,
  products: {},
  productOptions: [],
  subscriptionOptions: [],
  aggregateSubscriptions: [],
  workflowsUsageRating: '',
  selectedProductId: null,
  selectedSubscriptionId: null,
  barColor: '#5EB8D0',
  chartTitle: 'Successful Predictions vs Time',
  lastUpdated: '',
};

export function requestProducts() {
  return {
    type: REQUEST_PRODUCTS,
  };
}

export function receiveProducts(products) {
  return {
    type: RECEIVE_PRODUCTS,
    products,
    receivedAt: moment().unix(),
  };
}

export function requestAllFieldSetUsages() {
  return {
    type: REQUEST_ALL_FIELDSET_USAGES,
  };
}

export function receiveAllFieldSetUsages(data) {
  return {
    type: RECEIVE_ALL_FIELDSET_USAGES,
    data,
    receivedAt: moment().unix(),
  };
}

export const SWITCH_FIELDSET_USAGE = 'SWITCH_FIELDSET_USAGE';
export function switchFieldSetUsage(data) {
  return {
    type: SWITCH_FIELDSET_USAGE,
    data,
    receivedAt: moment().unix(),
  };
}

export const SELECT_FIELDSET = 'SELECT_FIELDSET';
export function selectFieldSet(productId) {
  return {
    type: SELECT_FIELDSET,
    data: productId,
  };
}

export const SELECT_SUBSCRIPTION = 'SELECT_SUBSCRIPTION';
export function selectSubscription(subscriptionId) {
  return {
    type: SELECT_SUBSCRIPTION,
    data: subscriptionId,
  };
}

export default function stats(state = initialState, action) {
  let products;
  let selectedProductId;
  let productOptions;
  let subscriptionOptions;
  let selectedSubscriptionId;

  function extractProducts(productData) {
    const products = {};

    productData.forEach(product => {
      const subscriptions = {};

      product.subscriptions.forEach(subscription => {
        subscription.usage = {};
        subscriptions[subscription.id] = subscription;
      });

      product.currentSubscription = Object.values(subscriptions).find(s =>
        moment().isBetween(s.startDate, s.endDate),
      );

      product.subscriptions = subscriptions;
      product.workflowsLimit = product.limitCall;
      delete product.limitCall;

      products[product.productId] = product;
    });

    return products;
  }

  switch (action.type) {
    case REQUEST_PRODUCTS:
      return { ...state, loading: true };

    case RECEIVE_PRODUCTS:
      products = extractProducts(action.products);

      // eslint-disable-next-line prefer-destructuring
      selectedProductId = Object.keys(products)[0];
      productOptions = [
        { value: 'all', label: 'All Products' },
        ...Object.keys(products)
          .map(p => {
            if (products[p].fieldSet.indexOf('[') === -1) {
              return { value: p, label: products[p].fieldSet };
            }
            return null;
          })
          .filter(p => p !== null),
      ];

      subscriptionOptions = Object.values(
        products[selectedProductId].subscriptions,
      ).map(s => ({
        value: s.id,
        label: `${moment(s.startDate).format('DD MMM YY')} - ${moment(
          s.endDate,
        ).format('DD MMM YY')}`,
      }));

      // eslint-disable-next-line no-case-declarations
      const aggregateSubscriptions = [];
      // eslint-disable-next-line no-unused-vars
      for (const product of Object.values(products)) {
        // eslint-disable-next-line no-unused-vars
        for (const s of Object.values(product.subscriptions)) {
          let existingOpt;
          const label = `${moment(s.startDate).format('DD MMM YY')} - ${moment(
            s.endDate,
          ).format('DD MMM YY')}`;
          if (
            // eslint-disable-next-line no-cond-assign
            (existingOpt = aggregateSubscriptions.find(
              so => so.label === label,
            ))
          ) {
            existingOpt.value.push({
              productId: product.productId,
              subscriptionId: s.id,
              id: uuid(),
            });
          } else {
            aggregateSubscriptions.push({
              value: [{ productId: product.productId, subscriptionId: s.id }],
              label,
              id: uuid(),
            });
          }
        }
      }

      selectedSubscriptionId = products[selectedProductId].currentSubscription
        ? products[selectedProductId].currentSubscription.id
        : Object.keys(products[selectedProductId].subscriptions)[0];

      if (aggregateSubscriptions.length > 0) {
        const subs = aggregateSubscriptions.map(sub => ({
          label: sub.label,
          value: sub.id,
        }));
        subscriptionOptions = subs;
        const currentStartDate = moment().startOf('month');
        const currentEndDate = moment().startOf('month').add(1, 'M');

        const currentSub = subs.find(s => {
          const startDate = moment(s.label.split('-')[0], 'DD MMM YY');
          return moment(startDate).isBetween(
            currentStartDate,
            currentEndDate,
            null,
            '[)',
          );
        });
        selectedSubscriptionId = currentSub ? currentSub.value : subs[0].value;
        selectedProductId = productOptions[0].value;
      }
      return {
        ...state,
        loading: false,
        products,
        selectedProductId,
        selectedSubscriptionId,
        productOptions,
        subscriptionOptions,
        aggregateSubscriptions,
        lastUpdated: action.receivedAt,
      };
    case REQUEST_ALL_FIELDSET_USAGES:
      return { ...state, loading: true };

    case RECEIVE_ALL_FIELDSET_USAGES:
      products = clone(state.products);
      // eslint-disable-next-line no-case-declarations
      const results = Object.entries(action.data.usage);
      results.forEach(fieldSet => {
        const product = Object.values(products).find(
          p => p.fieldSet === fieldSet[0],
        );
        // eslint-disable-next-line prefer-destructuring
        products[product.productId].currentUsage = fieldSet[1];
      });
      return { ...state, loading: false, products };
    case SELECT_FIELDSET:
      selectedProductId = state.selectedProductId;
      selectedSubscriptionId = state.selectedSubscriptionId;
      products = clone(state.products);

      // eslint-disable-next-line no-case-declarations
      const newProductId = action.data;
      // eslint-disable-next-line no-case-declarations
      let newSubscription;

      if (newProductId === 'all' && selectedProductId !== newProductId) {
        subscriptionOptions = state.aggregateSubscriptions.map(s => ({
          label: s.label,
          value: s.id,
        }));
        newSubscription = state.aggregateSubscriptions.find(s =>
          s.value.find(v => v.subscriptionId === selectedSubscriptionId),
        );
        selectedSubscriptionId = newSubscription.id;
      } else if (selectedProductId !== action.data) {
        subscriptionOptions = Object.values(
          products[action.data].subscriptions,
        ).map(s => ({
          value: s.id,
          label: `${moment(s.startDate).format('DD MMM YY')} - ${moment(
            s.endDate,
          ).format('DD MMM YY')}`,
        }));

        if (selectedProductId === 'all') {
          const prevSub = state.subscriptionOptions.find(
            s => s.value === selectedSubscriptionId,
          ).label;

          const newSubscriptionOptions = Object.values(
            products[action.data].subscriptions,
          ).map(s => ({
            value: s.id,
            label: `${moment(s.startDate).format('DD MMM YY')} - ${moment(
              s.startDate,
            )
              .add(1, 'M')
              .format('DD MMM YY')}`,
          }));
          let newSub = newSubscriptionOptions.find(s => s.label === prevSub);
          // eslint-disable-next-line prefer-destructuring
          if (!newSub) newSub = newSubscriptionOptions[0];
          selectedSubscriptionId = newSub.value;
        } else {
          const prevSub =
            products[selectedProductId].subscriptions[selectedSubscriptionId];
          const prevStartDate = moment(prevSub.startDate).startOf('month');

          newSubscription =
            Object.values(products[action.data].subscriptions).find(s =>
              moment(s.startDate).startOf('month').isSame(prevStartDate),
            ) || Object.values(products[action.data].subscriptions)[0];
          selectedSubscriptionId = newSubscription.id;
        }
      }

      return {
        ...state,
        selectedProductId: action.data,
        selectedSubscriptionId,
        subscriptionOptions,
      };

    case SELECT_SUBSCRIPTION:
      return { ...state, selectedSubscriptionId: action.data };

    default:
      return state;
  }
}

function shouldRequestStats({ stats }) {
  return !stats.loading;
}

export function getAllFieldsetUsageStats(
  accessToken,
  companyId,
  fieldSets,
  startDate,
  endDate,
) {
  return dispatch => {
    dispatch(requestAllFieldSetUsages());
    axios
      .get(`${analyticsServiceUrl}/analytics/usage/${companyId}/fieldsets`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'content-type': 'application/json',
          Accept: 'application/json',
        },
        params: {
          startDate,
          endDate,
          fieldSets: Array.isArray(fieldSets) ? fieldSets.join(',') : fieldSets,
        },
      })
      .then(({ data }) => {
        dispatch(receiveAllFieldSetUsages(data));
      });
  };
}

export function loadStats(accessToken, companyId) {
  // eslint-disable-next-line consistent-return
  return (dispatch, getState) => {
    if (shouldRequestStats(getState())) {
      dispatch(requestProducts());
      return axios
        .get(`${API_HOST}/app/company/${companyId}/subscriptions`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(({ data }) => {
          dispatch(receiveProducts(clone(data)));
          const currentDate = moment().startOf('month');
          const endDate = moment(currentDate).add(1, 'M');
          const fieldsets = Object.values(data).map(s => s.productId);
          dispatch(
            getAllFieldsetUsageStats(
              accessToken,
              companyId,
              fieldsets,
              currentDate,
              endDate,
            ),
          );
        });
    }
  };
}
