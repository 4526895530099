/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { showRipple } from '../../../common/helpers';
import './_button.scss';

const AnchorButton = ({
  children,
  className,
  color,
  href,
  size,
  type,
  variant,
  onClick,
  ...props
}) => {
  const handleClick = e => {
    showRipple(e.target, e);
    onClick();
  };

  return (
    <a
      href={href}
      className={classNames(
        'btn',
        className,
        size && `btn--${size}`,
        color && `btn--${color}${variant && `-${variant}`}`,
      )}
      {...props}
      onClick={handleClick}
    >
      {children}
    </a>
  );
};

AnchorButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  href: PropTypes.string.isRequired,
  size: PropTypes.string,
  type: PropTypes.string,
  // This is a WIP and probably needs to map closer to the types of button in Figma
  // Which is more of a change to handle in this merge.
  variant: PropTypes.oneOf([
    '', // Default => Primary Button
    'outline', // Secondary Button
    'inverted', // Primary Button inverted
    'outline-inverted', // Secondary Button inverted
    'outline-warning', // Secondary Warning button
  ]),
  onClick: PropTypes.func,
};

AnchorButton.defaultProps = {
  children: null,
  className: null,
  color: 'blue',
  size: 'md',
  type: 'button',
  variant: '',
  onClick: () => {},
};

export default AnchorButton;
