// eslint-disable-next-line no-extend-native
Object.defineProperty(String.prototype, 'toSingular', {
  value: function toSingular(pluralStr = undefined) {
    const endings = {
      ces: 'ce',
      ves: 'fe',
      ies: 'y',
      i: 'us',
      zes: '',
      ses: '',
      es: '',
      s: '',
    };
    return this.replace(
      new RegExp(pluralStr || `(${Object.keys(endings).join('|')})$`),
      r => endings[r],
    );
  },
  writable: true,
  configurable: true,
});
