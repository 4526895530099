import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './TextLink.scss';

const TextLink = ({ className, type, style, children, path, ...props }) => (
  <Link
    className={classNames(`text-link-${type}`, className)}
    style={style}
    to={path}
    {...props}
  >
    {children}
  </Link>
);

TextLink.propTypes = {
  type: PropTypes.oneOf(['default', 'error', 'btn']),
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.any,
  path: PropTypes.string.isRequired,
};

TextLink.defaultProps = {
  type: 'default',
  className: '',
  style: {},
  children: null,
};

export default TextLink;
