import ProductService from '../../services/ProductService';
import { addNotification } from '../notifications';
import {
  createdMessage,
  updatedMessage,
  deletedMessage,
  errorMessage,
  apiError,
} from './messages';

export const REQUEST_PRODUCT = 'sypht/product/REQUEST_PRODUCT';
export const RECEIVE_PRODUCT = 'sypht/product/RECEIVE_PRODUCT';
export const TRANSMIT_DATA = 'sypht/product/TRANSMIT_DATA';
export const RECEIVE_ERROR = 'sypht/product/RECEIVE_ERROR';
export const REFRESH_PRODUCT = 'sypht/product/REFRESH_PRODUCT';

const transmitData = () => {
  return {
    type: TRANSMIT_DATA,
    payload: {
      loading: true,
      sent: false,
    },
  };
};

const requestProduct = () => {
  return {
    type: REQUEST_PRODUCT,
    payload: {
      loading: true,
    },
  };
};

const receiveError = () => {
  return {
    type: RECEIVE_ERROR,
    payload: {},
  };
};

const receiveProduct = (data, isSent = false) => {
  return {
    type: RECEIVE_PRODUCT,
    payload: { data, loading: false, sent: isSent },
  };
};

export const refreshProduct = () => {
  return {
    type: REFRESH_PRODUCT,
    payload: {},
  };
};

// CREATE
export const createProduct = product => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;
    dispatch(transmitData());

    let response;
    try {
      response = await ProductService.createProduct(accessToken, product);

      if (response.data && response.data.id) {
        dispatch(receiveProduct(response.data, true));
        dispatch(addNotification(createdMessage(response.data.name)));
      } else {
        dispatch(receiveError());
        dispatch(addNotification(errorMessage));
      }
    } catch (err) {
      // return api error
      dispatch(receiveError());
      if (err.response.data && err.response.data.message) {
        dispatch(addNotification(apiError(err.response.data.message)));
      }
      // unhandled exception
      else {
        dispatch(addNotification(errorMessage));
      }
    }
  };
};

// RETRIEVE
export const fetchProduct = (productId, isPublic = false) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;

    let response = null;
    try {
      dispatch(requestProduct());
      response = await ProductService.getProduct(
        accessToken,
        productId,
        isPublic,
      );
    } catch (err) {
      response = err.response;
      response.data.error = true;
    } finally {
      dispatch(receiveProduct(response.data));
    }
  };
};

// UPDATE
export const updateProduct = (productId, product) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;
    dispatch(transmitData());

    let response;
    try {
      response = await ProductService.updateProduct(
        accessToken,
        productId,
        product,
      );

      if (response.data && response.data.name) {
        dispatch(receiveProduct(response.data));
        dispatch(addNotification(updatedMessage(response.data.name)));
      } else {
        dispatch(receiveError());
        dispatch(addNotification(errorMessage));
      }
    } catch (err) {
      // return api error
      dispatch(receiveError());
      if (err.response.data && err.response.data.message) {
        dispatch(addNotification(apiError(err.response.data.message)));
      }
      // unhandled exception
      else {
        dispatch(addNotification(errorMessage));
      }
    }
  };
};

// DELETE
export const deleteProduct = productId => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    const { accessToken } = auth;
    dispatch(transmitData());

    const response = await ProductService.deleteProduct(accessToken, productId);

    if (response.data && response.data.clientName) {
      dispatch(receiveProduct(response.data));
      dispatch(addNotification(deletedMessage(response.data.name)));
    } else {
      dispatch(receiveError());
      dispatch(addNotification(errorMessage));
    }
  };
};
