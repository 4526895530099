import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FaRegCopy } from 'react-icons/fa';
import { Button } from '../../../../../library/atoms/Buttons';
import FieldGroup from '../../../../../library/molecules/fields/FieldGroup';
import TextField from '../../../../../library/molecules/fields/TextField';
import AccordionField from '../../../../../library/molecules/fields/AccordionField';
import './DocumentInfo.scss';
import '../../../../../library/molecules/fields/field.scss';

const splitStateTooltip = 'Indicates the split status of this document';
const childDocsTooltip = [
  'This document is a parent document which has been split into the following child documents',
];
const noValue = <>&mdash;</>;
const formatDate = date => {
  return date ? moment(date).format('D MMMM Y') : noValue;
};

const DocumentInfo = ({
  pages,
  uploadDate,
  fixDate,
  splitState,
  extractDate,
  exportDate,
  childDocs,
  parentDocId,
}) => {
  const [isOpen, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(state => !state);
  };
  const [isChildDocsOpen, setChildDocsOpen] = useState(false);
  const handleChildDocsOpen = () => {
    setChildDocsOpen(state => !state);
  };
  return (
    <div className="docinfo">
      <div className="docinfo__title">
        <h3 className="docinfo__title-txt">Document Info</h3>
        <Button
          className="docinfo__title-btn"
          onClick={handleOpen}
          size="sm"
          variant="outline"
        >
          View Processing History
        </Button>
      </div>
      <div className={classNames('docinfo__body', { 'is-open': isOpen })}>
        <div className="docinfo__body-col">
          <FieldGroup>
            <TextField name="Pages" value={pages || noValue} />
            <TextField name="Uploaded" value={formatDate(uploadDate)} />
            <TextField name="Fixed" value={formatDate(fixDate)} />
            {parentDocId && (
              <div className="field2">
                <div className="field2__section">
                  <h3 className="field2__header field2__header--light">
                    Parent Document
                  </h3>
                </div>
                <div className="field2__section">
                  <div className="field2__text">
                    <a
                      className="docinfo__doc-link"
                      href={`/documents/${parentDocId}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaRegCopy className="docinfo__reg-copy" />
                      {parentDocId.substring(0, 13)}
                    </a>
                  </div>
                </div>
              </div>
            )}
            {childDocs && (
              <AccordionField
                name="Documents split out"
                tooltip={childDocsTooltip}
                additionalText=""
                onClick={handleChildDocsOpen}
                isOpen={isChildDocsOpen}
                content={
                  <ul className="docinfo__accordion">
                    {childDocs.map(doc => (
                      <li key={doc.id}>
                        <a
                          className="docinfo__doc-link"
                          href={`/documents/${doc.id}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FaRegCopy className="docinfo__reg-copy" />
                          {doc.id.substring(0, 13)}
                        </a>
                      </li>
                    ))}
                  </ul>
                }
              />
            )}
          </FieldGroup>
        </div>
        <div className="docinfo__body-col">
          <FieldGroup>
            <TextField
              name="Split state"
              value={splitState || noValue}
              tooltip={splitStateTooltip}
            />
            <TextField name="Extracted" value={formatDate(extractDate)} />
            <TextField name="Exported" value={formatDate(exportDate)} />
          </FieldGroup>
        </div>
      </div>
    </div>
  );
};

DocumentInfo.propTypes = {
  pages: PropTypes.number,
  uploadDate: PropTypes.string,
  fixDate: PropTypes.string,
  splitState: PropTypes.string,
  extractDate: PropTypes.string,
  exportDate: PropTypes.string,
  childDocs: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  parentDocId: PropTypes.string,
};

DocumentInfo.defaultProps = {
  pages: null,
  uploadDate: null,
  fixDate: null,
  splitState: null,
  extractDate: null,
  exportDate: null,
  childDocs: null,
  parentDocId: null,
};

export default DocumentInfo;
