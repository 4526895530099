import React from 'react';
import { Line } from 'rc-progress';
import PropTypes from 'prop-types';

const BatchExportProgress = ({ progress, total }) => {
  return (
    <div className="batch-export-progress__outer">
      <p>
        Preparing export file, it will download automatically once complete.
      </p>
      <Line percent={parseInt((progress / total) * 100)} strokeWidth={2} />
    </div>
  );
};

BatchExportProgress.propTypes = {
  progress: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default BatchExportProgress;
