import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as modal from '../../library/molecules/Modal';
import * as choice from '../../library/molecules/ChoiceChip';
import { Button } from '../../library/atoms/Buttons';
import ManualSplitIcon from './ManualSplitIcon';
import SmartSplitIcon from './SmartSplitIcon';

export const SMART_SPLIT = 'SMART_SPLIT';
export const MANUAL_SPLIT = 'MANUAL_SPLIT';

/**
 * Ask the user to choose whether to do a smart auto split or a manual split on
 * one or more selected documents.
 */
const SplitModal = ({
  numDocs,
  defaultSplitType,
  onCancel,
  onContinue,
  disableManualSplit,
}) => {
  const [splitType, setSplitType] = useState(defaultSplitType);
  const handleChange = (_, value) => {
    if (disableManualSplit) {
      setSplitType(SMART_SPLIT);
      return;
    }
    setSplitType(value);
  };
  const handleContinue = evt => {
    onContinue(evt, splitType);
  };
  const header =
    numDocs === 1 ? `Split ${numDocs} document` : `Split ${numDocs} documents`;

  const renderManualSplitText = () => {
    if (disableManualSplit) {
      return (
        <>
          Manual split for more than one document at a time requires a
          subscription to our human review platform addon. Please{' '}
          <a
            target="_blank"
            href="https://www.sypht.com/contact"
            rel="noreferrer"
          >
            contact us
          </a>{' '}
          for more details
        </>
      );
    }
    return 'Manually separate multi-document files into individual documents of different lengths and formats.';
  };

  return (
    <modal.ModalContainer>
      <modal.ModalHeader>{header}</modal.ModalHeader>
      <modal.ModalBody>
        <choice.ChoiceChipGroup
          name="choose-split-type"
          value={splitType}
          onChange={handleChange}
        >
          <choice.ChoiceChip
            value={SMART_SPLIT}
            image={<SmartSplitIcon />}
            heading="Auto-split"
          >
            Sypht’s AI will automatically separate multi-document files into
            individual documents of different lengths and formats.
          </choice.ChoiceChip>
          <choice.ChoiceChip
            value={MANUAL_SPLIT}
            image={<ManualSplitIcon />}
            heading="Manual split"
            disabled={disableManualSplit}
            variant={disableManualSplit ? 'locked' : undefined}
          >
            {renderManualSplitText()}
          </choice.ChoiceChip>
        </choice.ChoiceChipGroup>
      </modal.ModalBody>
      <modal.ModalFooter>
        <Button variant="outline" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={handleContinue}>Next</Button>
      </modal.ModalFooter>
    </modal.ModalContainer>
  );
};

SplitModal.propTypes = {
  numDocs: PropTypes.number.isRequired,
  disableManualSplit: PropTypes.bool.isRequired,
  defaultSplitType: PropTypes.oneOf([SMART_SPLIT, MANUAL_SPLIT]),
  onCancel: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
};

SplitModal.defaultProps = {
  defaultSplitType: SMART_SPLIT,
};

export default SplitModal;
