import analysePassword from './analysePassword';
import { addError, removeError } from './index';

const validatePassword = ({ elem, errors = null, setErrors = null }) => {
  const { name: key, value } = elem;
  const message = `Invalid password.`;
  const result = analysePassword(value);

  if (!result.isValid) {
    addError(key, message, errors, setErrors);
  } else {
    removeError(key, message, errors, setErrors);
  }

  return result;
};

export default validatePassword;
