import React, { useEffect, useRef, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import classNames from 'classnames';
import { Interweave } from 'interweave';
import MarkdownIt from 'markdown-it';
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Radio from '../../../UI/Radio/Radio';
import CheckoutForm from './CheckoutForm';

import stripeBadge from '../../../../assets/images/badge-stripe.svg';
import { BILLING_AND_PAYMENT } from '../../store/messages';
import './CheckoutStep.scss';

const mdParser = new MarkdownIt();

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);

const toggleOpen = (outerRef, innerRef, isOpen) => {
  const outer = outerRef.current;
  const inner = innerRef.current;
  if (!outer || !inner) return;
  if (isOpen) {
    outer.style.height = `${inner.offsetHeight}px`;
  } else {
    outer.style.height = '0';
  }
};

const CheckoutStep2 = ({
  company,
  countries,
  paymentMethodId,
  paymentMethods,
  paymentResult,
  onChangePaymentMethod,
  onSavePaymentMethod,
}) => {
  const [isOpen, setIsOpen] = useState({
    paymentMethod: paymentMethods.length > 0,
    addPayment: paymentMethods.length <= 0,
    refresh: 0,
  });

  const paymentMethodRef = useRef(null);
  const paymentMethodInnerRef = useRef(null);
  const addPaymentRef = useRef(null);
  const addPaymentInnerRef = useRef(null);

  useEffect(() => {
    // workaround to get the accordion to expand more if card iframe hasnt loaded
    if (paymentMethods.length <= 0) {
      setTimeout(() => {
        if (
          isOpen.addPayment &&
          addPaymentRef.current &&
          addPaymentInnerRef.current
        ) {
          addPaymentRef.current.style.height = `${addPaymentInnerRef.current.offsetHeight}px`;
        }
      }, 300);
    }
  }, []);

  useEffect(() => {
    // select default payment method when saving
    if (paymentMethods[0] !== undefined) {
      onChangePaymentMethod(paymentMethods[0].id);
    }
  }, paymentMethods[0]);

  // toggle payment method
  useEffect(() => {
    const outer = paymentMethodRef.current;
    const inner = paymentMethodInnerRef.current;
    if (!outer || !inner) return;
    if (isOpen.paymentMethod) {
      outer.style.height = `${inner.offsetHeight}px`;
      setIsOpen({
        ...isOpen,
        addPayment: false,
      });
    } else {
      outer.style.height = '0';
    }
  }, [isOpen.paymentMethod]);

  // toggle add payment
  useEffect(() => {
    const outer = addPaymentRef.current;
    const inner = addPaymentInnerRef.current;
    if (!outer || !inner) return;
    if (isOpen.addPayment) {
      outer.style.height = `${inner.offsetHeight}px`;
      setIsOpen({
        ...isOpen,
        paymentMethod: false,
      });
      setTimeout(() => {
        outer.classList.add('expanded');
      }, 300);
    } else {
      outer.style.height = '0';
      outer.classList.remove('expanded');
    }
  }, [isOpen.addPayment]);

  useEffect(() => {
    if (paymentMethods && paymentMethods.length > 0 && isOpen.paymentMethod) {
      const outer = paymentMethodRef.current;
      const inner = paymentMethodInnerRef.current;
      outer.style.height = `${inner.offsetHeight}px`;
    }
  }, [paymentMethods]);

  const handlePaymentMethodClick = e => {
    e.preventDefault();
    setIsOpen({
      ...isOpen,
      paymentMethod: !isOpen.paymentMethod,
    });
  };

  const handleAddPaymentMethod = e => {
    e.preventDefault();
    setIsOpen({
      ...isOpen,
      addPayment: !isOpen.addPayment,
    });
  };

  const handleCountryChange = () => {
    setTimeout(() => {
      const outer = addPaymentRef.current;
      const inner = addPaymentInnerRef.current;
      outer.classList.remove('expanded');
      outer.style.height = `${inner.offsetHeight}px`;
      setTimeout(() => {
        outer.classList.add('expanded');
      }, 300);
    }, 0);
  };

  const handleSavePaymentMethod = payload => {
    onSavePaymentMethod(payload);
    setTimeout(() => {
      setIsOpen({
        ...isOpen,
        paymentMethod: true,
        addPayment: false,
      });
    }, 1000);
  };

  return (
    <div className="checkout-step">
      <div className="checkout-step__inner">
        <h3 className="checkout-step__heading">
          <span>Billing & Payment</span>
          <span>Step 2 of 2</span>
        </h3>
        <div className="checkout-step__section">
          <Interweave
            attributes={{ className: 'checkout-step__disclaimer' }}
            containerTagName="div"
            content={mdParser.render(BILLING_AND_PAYMENT)}
          />
        </div>
        <div className="checkout-accordion__group">
          {paymentMethods.length > 0 && (
            <div
              className={classNames('checkout-accordion', {
                'checkout-accordion--expanded': isOpen.paymentMethod,
              })}
            >
              <div
                className="checkout-accordion__header"
                onClick={handlePaymentMethodClick}
                role="button"
                tabIndex={0}
              >
                <h3 className="checkout-step__heading">
                  Choose default payment method
                </h3>
                <FaChevronDown className="checkout-accordion__header-icon" />
              </div>
              <div className="checkout-accordion__body" ref={paymentMethodRef}>
                <div
                  className="checkout-accordion__body-inner"
                  ref={paymentMethodInnerRef}
                >
                  <div className="checkout-payment-methods">
                    <div className="checkout-payment-methods__inner">
                      {paymentMethods.length > 0 ? (
                        paymentMethods.map(pm => {
                          return (
                            <Radio
                              key={pm.id}
                              label={`${pm.card.brand.toTitleCase()} ending in ${
                                pm.card.last4
                              }`}
                              onChange={() => onChangePaymentMethod(pm.id)}
                              value={paymentMethodId === pm.id}
                              name="paymentMethod"
                            />
                          );
                        })
                      ) : (
                        <p className="checkout-payment-methods__text">
                          No payment methods added.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className={classNames('checkout-accordion', {
              'checkout-accordion--expanded': isOpen.addPayment,
            })}
          >
            <div
              className="checkout-accordion__header"
              onClick={handleAddPaymentMethod}
              role="button"
              tabIndex={0}
            >
              <h3 className="checkout-step__heading">
                {paymentMethods && paymentMethods.length > 0
                  ? 'Change default payment method'
                  : 'Add payment method'}
              </h3>
              <FaChevronDown className="checkout-accordion__header-icon" />
            </div>
            <div className="checkout-accordion__body" ref={addPaymentRef}>
              <div
                className="checkout-accordion__body-inner"
                ref={addPaymentInnerRef}
              >
                <div className="checkout-add-payment">
                  <Elements
                    stripe={stripePromise}
                    options={{ hidePostalCode: true }}
                  >
                    <CheckoutForm
                      company={company}
                      countries={countries}
                      paymentMethods={paymentMethods}
                      onCountryChange={handleCountryChange}
                      onSavePaymentMethod={handleSavePaymentMethod}
                    />
                  </Elements>
                </div>
              </div>
            </div>
          </div>
        </div>
        {paymentResult && !paymentResult.isSuccess && (
          <div className="checkout-step__section">
            <div className="checkout-error">
              Something went wrong, please try again. If this issue persists
              please contact us at{' '}
              <a href="mailto:support@sypht.com">support@sypht.com</a>.
            </div>
          </div>
        )}
      </div>
      <img className="stripe-logo" src={stripeBadge} alt="Stripe" />
    </div>
  );
};

CheckoutStep2.propTypes = {
  company: PropTypes.object.isRequired,
  countries: PropTypes.array,
  paymentMethodId: PropTypes.string,
  paymentMethods: PropTypes.array.isRequired,
  paymentResult: PropTypes.object,
  onChangePaymentMethod: PropTypes.func.isRequired,
  onSavePaymentMethod: PropTypes.func.isRequired,
};

CheckoutStep2.defaultProps = {
  countries: [],
  paymentMethodId: null,
  paymentResult: null,
};

export default CheckoutStep2;
