import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';

import * as persistConfig from '../config/redux-persist/config';

// Public reducers
import analyticsReducer from './analytics/reducer';
import authReducer from './authReducer';
import notificationReducer from './notifications';
import tasksReducer from './tasks';
import nextTaskReducer from './nextTask';
import uploadReducer from './upload';
import specsReducer from './specs';
import fieldsReducer from './fields';
import docsReducer from './docs';
import statsReducer from './stats/reducer';
import lookupsReducer from './lookups/reducer';
import productsReducer from './products/reducer';
import productReducer from './product/reducer';
import fieldsetsReducer from './fieldsets/reducer';
import eventsReducer from './events/reducer';
import adminReducer from './admin/reducer';
import trackingReducer from './tracking/reducer';
import settingsReducer from './settings/reducer';
import tagReducer from './tags/reducer';
import taskAnalyticsReducer from './taskAnalytics/reducer';
import mailboxReducer from './mailbox/reducer';
import workflowReducer from './workflow/reducer';

// Private reducers
import captureReducer from '../components/Capture/store/reducer';
import signalsReducer from '../components/Signals/store/reducer';
// import docsV2Reducer from '../components/DocsV2/store/reducer';
import manageTagsReducer from '../components/Tags/store/reducer';
import validateRulesReducer from '../components/Validate/store/rules/reducer';

// Admin reducers
import companyReducer from './company/reducer';
import credentialsReducer from './credentials/reducer';
import subscriptionsReducer from './subscriptions/reducer';
import userReducer from './user/reducer';
import usersReducer from './users/reducer';

export default history =>
  combineReducers({
    analytics: analyticsReducer,
    taskAnalytics: taskAnalyticsReducer,
    auth: persistReducer(persistConfig.auth, authReducer),
    capture: captureReducer,
    validateRules: validateRulesReducer,
    signals: signalsReducer,
    router: connectRouter(history),
    notifications: notificationReducer,
    tasks: tasksReducer,
    specs: specsReducer,
    nextTask: persistReducer(persistConfig.nextTask, nextTaskReducer),
    upload: uploadReducer,
    fields: fieldsReducer,
    docs: docsReducer,
    credentials: credentialsReducer,
    user: userReducer,
    users: usersReducer,
    company: companyReducer,
    stats: statsReducer,
    subscriptions: subscriptionsReducer,
    lookups: lookupsReducer,
    products: productsReducer,
    product: productReducer,
    fieldsets: fieldsetsReducer,
    events: eventsReducer,
    admin: adminReducer,
    settings: settingsReducer,
    tracking: trackingReducer,
    tags: tagReducer,
    manageTags: manageTagsReducer,
    mailbox: mailboxReducer,
    workflow: workflowReducer,
  });
