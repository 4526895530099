import React from 'react';
import { FaRegSadTear } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const NotFound = ({ message }) => {
  return (
    <div className="error-page-wrapper">
      <div className="404">
        <span className="error-heading">4</span>
        <FaRegSadTear size="80" />
        <span className="error-heading">4</span>
      </div>
      <h2>{message}</h2>
      <span className="error-caption">
        Visit our <Link to="/marketplace">marketplace</Link> to view the list of
        available products.
      </span>
    </div>
  );
};

NotFound.propTypes = {
  message: PropTypes.string.isRequired,
};

export default NotFound;
