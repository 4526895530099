import React from 'react';
import PropTypes from 'prop-types';
import { IoMdCloseCircle } from 'react-icons/io';
import Dropdown from '../../../UI/Dropdown/Dropdown';
import './DashboardDropdown.scss';

const ClearIndicator = props => {
  // eslint-disable-next-line react/prop-types
  const { getStyles, innerProps } = props;
  const { ref, ...restInnerProps } = innerProps;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles('clearIndicator', props)}
    >
      <IoMdCloseCircle className="dropdown__clear" />
    </div>
  );
};

const FilterStyles = {
  container: base => ({
    ...base,
    fontSize: '16px',
    minWidth: '150px',
    width: 'auto',
  }),
  clearIndicator: base => ({
    ...base,
    color: '#5c666f',
    margin: '0',
    padding: '0',
  }),
  dropdownIndicator: base => ({
    ...base,
    color: '#5c666f',
  }),
  control: base => ({
    ...base,
    margin: '0',
    border: '2px solid #ccc',
    borderRadius: '4px',
    padding: '2px',
  }),
  indicatorSeparator: base => ({
    ...base,
    width: '0',
    display: 'none',
  }),
  input: base => ({
    ...base,
    padding: '0px',
  }),
  placeholder: base => ({
    ...base,
    padding: '0',
    fontFamily: 'Roboto, sans-serif',
    color: '#999999',
  }),
};

const DashboardDropdown = ({
  className,
  label,
  value,
  defaultValue,
  options,
  onChange: onFilterChange,
  ...props
}) => {
  const availableOptions = defaultValue
    ? [...[defaultValue], ...options]
    : options;

  return (
    <Dropdown
      className={className}
      classNamePrefix={className}
      components={{ ClearIndicator }}
      options={availableOptions}
      placeholder={label}
      styles={FilterStyles}
      value={value}
      isClearable={false}
      onChange={onFilterChange}
      {...props}
    />
  );
};

DashboardDropdown.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.object,
  defaultValue: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
};

DashboardDropdown.defaultProps = {
  className: null,
  label: null,
  value: null,
  defaultValue: null,
  onChange: () => {},
};

export default DashboardDropdown;
