/* eslint-disable no-redeclare */
import { v4 as uuid } from 'uuid';
import {
  CREATE_ROW,
  DELETE_ROW,
  UPDATE_ROW,
  RECEIVE_TAG,
  RECEIVE_TAGS,
  RECEIVE_DELETED_TAGS,
  RECEIVE_UPDATED_TAGS,
} from './actions';

const NEW_TAG = {
  name: '',
  description: '',
  isAccessTag: false,
  isNew: true,
};

const INITIAL_STATE = {
  data: {},
};

const manageTagsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  const { results: existingResults } = state.data;

  switch (type) {
    case CREATE_ROW: {
      const newTag = {
        id: uuid(),
        ...NEW_TAG,
      };

      return {
        ...state,
        data: {
          results: [newTag, ...existingResults],
          total: state.data.total + 1,
        },
      };
    }

    case DELETE_ROW: {
      const { tagId } = payload;

      const results = [...existingResults];
      results.splice(
        existingResults.findIndex(t => t.id === tagId),
        1,
      );

      return {
        ...state,
        data: {
          results,
          total: state.data.total - 1,
        },
      };
    }

    case UPDATE_ROW: {
      const { tagId, ...params } = payload;
      const results = [...existingResults];
      results.splice(
        existingResults.findIndex(t => t.id === tagId),
        1,
        {
          ...existingResults.find(t => t.id === tagId),
          ...params,
        },
      );

      return {
        ...state,
        data: {
          results,
        },
      };
    }

    case RECEIVE_TAG: {
      const { data: tag, tagId: targetTagId } = payload;
      const results = [...existingResults];
      const tagId = targetTagId || tag.id;

      results.splice(
        existingResults.findIndex(t => t.id === tagId),
        1,
        tag,
      );

      return {
        ...state,
        data: {
          results,
        },
      };
    }

    case RECEIVE_TAGS: {
      const { data } = payload;
      return {
        ...state,
        data,
      };
    }

    case RECEIVE_DELETED_TAGS: {
      const { data: tags } = payload;
      const tagIds = (tags || []).map(t => t.id);
      const results = [...existingResults].filter(t => !tagIds.includes(t.id));
      return {
        ...state,
        data: {
          results,
        },
      };
    }

    case RECEIVE_UPDATED_TAGS: {
      const { data: tags } = payload;
      const results = [...existingResults];

      tags.forEach(tag => {
        results.splice(
          existingResults.findIndex(t => t.id === tag.id),
          1,
          tag,
        );
      });

      return {
        ...state,
        data: {
          results,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default manageTagsReducer;
