import * as utils from './utils';

const apiBaseUrl = process.env.APP_API_HOST_NAME;

class TrackingService {
  // deprecated
  pushPageView = (accessToken, params) => {
    return utils.postData(`${apiBaseUrl}/app/analytics`, {
      accessToken,
      data: { ...params, t: 'pageview' },
    });
  };

  // deprecated
  pushEvent = (accessToken, params) => {
    return utils.postData(`${apiBaseUrl}/app/analytics`, {
      accessToken,
      data: { ...params, t: 'event' },
    });
  };

  trackEvent = params => {
    return utils.postData(`${apiBaseUrl}/app/v2/analytics`, {
      data: {
        ...params,
        // isDebug: true,
      },
    });
  };
}

export default new TrackingService();
