import React from 'react';
import { connect } from 'react-redux';
import { Switch, Redirect, Route } from 'react-router';
import PropTypes from 'prop-types';
import Companies, { AddCompany } from './Companies';
import Company from './Company/Company';
import './Admin.scss';
import User from '../User/User';

const mapStateToProps = ({ auth, router }) => {
  return {
    location: router.location,
    canSuperUserRead: auth.permissions?.canSuperUserRead,
  };
};

const Admin = ({ location, canSuperUserRead }) => {
  const defaultPath = () =>
    canSuperUserRead ? (
      <Redirect to="/admin/companies" />
    ) : (
      <Redirect to="/404" />
    );

  return (
    <Switch location={location}>
      <Route exact path="/admin" component={defaultPath} />
      {canSuperUserRead && (
        <Route exact path="/admin/companies" component={Companies} />
      )}
      {canSuperUserRead && (
        <Route exact path="/admin/companies/add" component={AddCompany} />
      )}
      {canSuperUserRead && <Route path="/admin/users/:id" component={User} />}
      {canSuperUserRead && (
        <Route path="/admin/companies/:id" component={Company} />
      )}
      <Redirect to="/404" />
    </Switch>
  );
};

Admin.propTypes = {
  location: PropTypes.shape({}),
  canSuperUserRead: PropTypes.bool,
};

Admin.defaultProps = {
  location: null,
  canSuperUserRead: false,
};

export default connect(mapStateToProps)(Admin);
