import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FaSearch } from 'react-icons/fa';
import { debounce } from '../../../../common/helpers';
import { fetchProducts } from '../../../../reducers/products/actions';

import { trackEvent } from '../../../../reducers/tracking/actions';
import { searchProduct } from '../../tracking/productTracking';
import './SearchBox.scss';

const DEBOUNCE_INTERVAL = 400;

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      fetchProducts: query => dispatch(fetchProducts({ query })),
      trackEvent: ({ name, params }) => dispatch(trackEvent({ name, params })),
    },
  };
};

const SearchBox = ({ dispatch }) => {
  const handleInputChange = useCallback(
    debounce(filter => {
      const { value: query } = filter;
      dispatch.fetchProducts(query);
      dispatch.trackEvent(searchProduct(query));
    }, DEBOUNCE_INTERVAL),
    [],
  );

  const handleKeydown = e => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  };

  return (
    <div className="text-search">
      <span className="search-glass">
        <FaSearch size={24} />
      </span>
      <input
        className="search-box"
        type="text"
        placeholder="Search for products, documents, use cases"
        onKeyDown={e => handleKeydown(e)}
        onChange={e => handleInputChange(e.target)}
      />
    </div>
  );
};

SearchBox.propTypes = {
  dispatch: PropTypes.shape({
    fetchProducts: PropTypes.func,
  }),
};

SearchBox.defaultProps = {
  dispatch: {
    fetchProducts: () => {},
  },
};

export default connect(undefined, mapDispatchToProps)(SearchBox);
