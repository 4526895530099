import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { 
  fetchFilterTags,
} from '../../../../../reducers/tags/action';
import Dropdown from '../../../../../library/molecules/Dropdown';
import { getTagsFilterLabel } from '../../../helpers';
//import TagsFilterMenu from './TagsFilterMenu';
import TagsDropdownMenu from '../../../../../library/molecules/TagsDropdownMenu/TagsDropdownMenu';

const initialTagParams = {
  limit:100,
  includeCounts:true,
  sortDirection:'desc',
  sortColumn:'created'
}

const mapStateToProps = state => {
  const { auth, tags } = state;
  return {
    tags: tags?.filterTags.data,
    user: auth?.user.data,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch: {
    fetchFilterTags: (companyId, params) => dispatch(fetchFilterTags(companyId, params)),
  },
});

const TagsFilter = ({
  id,
  companyId,
  value,
  onChange,
  // redux
  tags,
  user,
  dispatch,
}) => {
  const [isOpen, setOpen] = useState(false);
  //const [filterTagParams, setFilterTagParams] = useState(initialTagParams)
  const [tagSearch, setTagSearch] = useState(null);
  const filterRef = useRef();

  const tagSelections = useMemo(() => value ? value.sort().join(',') : null, [value]);

  useEffect(() => {
    if(companyId){
      let {sortColumn, sortDirection} = initialTagParams;
      let tagName = null;
      if(tagSearch && tagSearch !== ''){
        tagName = tagSearch;
        sortColumn = 'name';
        sortDirection = 'asc';
      }
      dispatch.fetchFilterTags(companyId, {
        ...initialTagParams, 
        name: tagName, 
        selections: tagSelections, 
        sortColumn, 
        sortDirection}
      );
    }
  }, [companyId, tagSearch, tagSelections, dispatch, initialTagParams]);

  const handleChange = newTags => {
    const resolvedTags = newTags?.length ? newTags : undefined;
    
    onChange({ value: resolvedTags });
  };

  const handleClear = e => {
    e.stopPropagation();
    onChange({ value: undefined });
    filterRef.current.handleClear();
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(!isOpen);
  };

  const onTagSearch = (tagName) => {
    setTagSearch(tagName);
  }

  return (
    <Dropdown
      id={id}
      className="tasks__filters-item tasks__filters-item--sm"
      label={getTagsFilterLabel(value)}
      type="select"
      hasValue={value && value.length}
      onClear={handleClear}
      onClick={handleClick}
      isOpen={isOpen}
    >
      <TagsDropdownMenu
        isOpen={isOpen}
        tags={tags ?? []}
        onConfirm={handleChange}
        selectedTags={value}
        onSearch={onTagSearch}
      />
    </Dropdown>
  );
};

TagsFilter.propTypes = {
  id: PropTypes.string,
  companyId: PropTypes.string,
  tags: PropTypes.array,
  user: PropTypes.object,
  value: PropTypes.array,
  onChange: PropTypes.func,
  dispatch: PropTypes.object,
};

TagsFilter.defaultProps = {
  id: null,
  companyId: null,
  tags: [],
  user: null,
  value: undefined,
  onChange: () => {},
  dispatch: {
    fetchCompanyTags: () => {},
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(TagsFilter);
