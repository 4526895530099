import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import './DocumentView.scss';
import DocumentContentView from './DocumentContentView';
import { DocumentToolbarContext } from '../UI/DocumentToolbar/DocumentToolbarContext';
import { TableContext } from './TableContext';

import DocumentTagList from './DocumentTagList';

const DocumentView = ({
  doc,
  companyId,
  focusPrediction,
  candidateSelection,
  highlights,
  onLoad,
  isTableView,
  tableSelectionMode,
  isShowDocCanvas,
  task,
  isShowTagList,
  processingLimit,
}) => {
  const [toolbarContext] = useContext(DocumentToolbarContext);
  const [, setTableContext] = useContext(TableContext);
  const { collapsed } = toolbarContext;

  useEffect(() => {
    setTableContext(state => ({
      ...state,
      activeTable: null,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowDocCanvas]);

  return (
    <>
      {isShowTagList && <DocumentTagList doc={doc} task={task} />}
      <div className="document-content">
        <div className="scroll-container" id="scrollContainer">
          <div
            className={`scroll-content ${collapsed ? ' fit-to-view ' : ''}`}
            id="scrollContent"
          >
            <DocumentContentView
              doc={doc}
              companyId={companyId}
              focusPrediction={focusPrediction}
              processingLimit={processingLimit}
              candidateSelection={candidateSelection || []}
              highlights={highlights}
              onLoad={onLoad}
              isTableView={isTableView}
              tableSelectionMode={tableSelectionMode}
            />
          </div>
        </div>
      </div>
    </>
  );
};

DocumentView.propTypes = {
  doc: PropTypes.shape({}),
  companyId: PropTypes.string,
  focusPrediction: PropTypes.shape({}),
  highlights: PropTypes.arrayOf(PropTypes.shape({})),
  candidateSelection: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onLoad: PropTypes.func,
  isTableView: PropTypes.bool,
  tableSelectionMode: PropTypes.bool,
  isShowDocCanvas: PropTypes.bool,
  task: PropTypes.shape({
    id: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  isShowTagList: PropTypes.bool,
  processingLimit: PropTypes.number,
};

DocumentView.defaultProps = {
  doc: null,
  companyId: null,
  focusPrediction: null,
  highlights: null,
  candidateSelection: null,
  onLoad: () => {},
  isTableView: false,
  tableSelectionMode: false,
  isShowDocCanvas: false,
  task: null,
  isShowTagList: false,
  processingLimit: null,
};

export default DocumentView;
