import validatorType from './types';
import {
  validateAbn,
  validateEmail,
  validateLength,
  validateMatch,
  validateNumber,
  validateNumberMin,
  validateNumberMax,
  validatePassword,
  validatePhone,
  validatePhoneAu,
  validateRequired,
  updateField,
  validateDecimal,
} from './index';
import getFieldValue from '../utils/getFieldValue';

const validate = {
  [validatorType.Abn]: o => validateAbn(o),
  [validatorType.Email]: o => validateEmail(o),
  [validatorType.Length]: (o, length) => validateLength(o, length),
  [validatorType.Match]: (o, match) => validateMatch(o, match),
  [validatorType.Number]: o => validateNumber(o),
  [validatorType.NumberMin]: (o, min) => validateNumberMin(o, min),
  [validatorType.NumberMax]: (o, max) => validateNumberMax(o, max),
  [validatorType.Password]: o => validatePassword(o),
  [validatorType.Phone]: o => validatePhone(o),
  [validatorType.PhoneAu]: o => validatePhoneAu(o),
  [validatorType.Required]: o => validateRequired(o),
  [validatorType.Decimal]: o => validateDecimal(o),
};

export default ({ elem, fields, setFields, errors, setErrors }) => {
  const currField = fields[elem.name];
  const { required, validators } = currField;
  let invalidCount = 0;

  // check for required validation
  if (required) {
    const isValid = validate.required({ elem, errors, setErrors });
    invalidCount += !isValid;
  }

  // check and count invalid validators
  (validators || []).forEach(validator => {
    if (validate[validator]) {
      let params;

      if (validator === validatorType.Length) {
        params = getFieldValue(fields, currField.length);
      }

      if (validator === validatorType.Match) {
        params = getFieldValue(fields, currField.match);
      }

      if (validator === validatorType.NumberMax) {
        params = currField.max;
      }

      if (validator === validatorType.NumberMin) {
        params = currField.min;
      }

      const result = validate[validator]({ elem, errors, setErrors }, params);
      const isValid = result.isValid === false ? false : result;
      invalidCount += !isValid;
    }
  });

  // update field's isValid state
  updateField(
    {
      [elem.name]: {
        isValid: invalidCount === 0,
      },
    },
    fields,
    setFields,
  );

  return invalidCount === 0;
};
