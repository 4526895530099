/* eslint-disable no-shadow */
/* eslint-disable no-restricted-syntax */

/**
 * Utils for Legacy Table (Lineitem v1)
 */
import { v4 as uuid } from 'uuid';

export const getColumnsFromRow = row => {
  return [row].map(col => {
    if (!col) return null;
    let tl = { x: 1.0, y: 1.0 };
    let br = { x: 0.0, y: 0.0 };
    for (const token of col) {
      if (token) {
        tl = {
          x: Math.min(token.bounds.topLeft.x, tl.x),
          y: Math.min(token.bounds.topLeft.y, tl.y),
        };
        br = {
          x: Math.max(token.bounds.bottomRight.x, br.x),
          y: Math.max(token.bounds.bottomRight.y, br.y),
        };
      }
    }
    return { bounds: { topLeft: tl, bottomRight: br } };
  });
};

const makeHashedKey = label => `${label}|${uuid().substring(0, 8)}`;

export const getTable = table => {
  if (!table) return {};
  const headers = table?.headers.map(h => ({
    key: makeHashedKey(h?.text),
    value: h?.text,
  }));
  const rows = table?.cells.map((r, ri) => {
    return {
      key: makeHashedKey(`row_${ri}`),
      cells: (r || []).map((c, ci) => ({
        key: makeHashedKey(`cell_${ri}_${ci}`),
        value: c?.text,
      })),
    };
  });
  if (!rows) return {};

  return {
    headers,
    rows,
  };
};

export const getTableRows = table => {
  return [table?.headers, ...table?.cells];
};

export const getColumns = (tableBounds, table) => {
  const columns = [];
  const tableColumns = [];
  const tableRows = getTableRows(table);

  tableRows.forEach(row => {
    row.forEach((col, j) => {
      tableColumns[j] = [...(tableColumns[j] || []), col];
    });
  });

  tableColumns.forEach((col, c) => {
    if (tableColumns[c + 1]) {
      const maxXA = col
        .filter(e => e !== null)
        .reduce((a, b) => Math.max(a, b.bounds.bottomRight.x), 0);

      const minXB = tableColumns[c + 1]
        .filter(e => e !== null)
        .reduce((a, b) => Math.min(a, b.bounds.topLeft.x), 1.0);
      const colX = (maxXA + minXB) / 2;
      columns.push({
        tl: {
          x: colX,
          y: tableBounds.tl.y,
        },
        br: {
          x: colX,
          y: tableBounds.br.y,
        },
      });
    }
  });

  return columns;
};

export const getRows = (tableBounds, table) => {
  const rowsResult = [];
  const rows = getTableRows(table);
  const rowBounds = [];

  rows.forEach((row, i) => {
    rowBounds[i] = {
      top: 1.0,
      bottom: 0.0,
    };

    row.forEach(rowItem => {
      rowBounds[i] = {
        top: Math.min(
          rowItem !== null ? rowItem.bounds.topLeft.y : 1.0,
          rowBounds[i].top,
        ),
        bottom: Math.max(
          rowItem !== null ? rowItem.bounds.bottomRight.y : 0.0,
          rowBounds[i].bottom,
        ),
      };
    });
  });

  rowBounds.forEach((row, i) => {
    if (rowBounds[i + 1]) {
      const rowY = (row.bottom + rowBounds[i + 1].top) / 2;
      rowsResult.push({
        tl: {
          x: tableBounds.tl.x,
          y: rowY,
        },
        br: {
          x: tableBounds.br.x,
          y: rowY,
        },
      });
    }
  });

  return rowsResult;
};

export const getBounds = table => {
  let tl = { x: 1.0, y: 1.0 };
  let br = { x: 0.0, y: 0.0 };
  const rows = getTableRows(table);

  for (const row of rows) {
    for (const rowItem of row) {
      if (rowItem) {
        tl = {
          x: Math.min(rowItem.bounds.topLeft.x, tl.x),
          y: Math.min(rowItem.bounds.topLeft.y, tl.y),
        };
        br = {
          x: Math.max(rowItem.bounds.bottomRight.x, br.x),
          y: Math.max(rowItem.bounds.bottomRight.y, br.y),
        };
      }
    }
  }
  return { tl, br };
};

export const getPage = table => {
  const rows = getTableRows(table);

  for (const row of rows) {
    for (const token of row) {
      if (token) {
        return token?.bounds?.pageNum;
      }
    }
  }
};

const getHeaderConfig = table => {
  let headerConfig = '';
  for (const header of table.headers) {
    if (header) {
      headerConfig = 'row';
      break;
    }
  }
  return headerConfig;
};

export const getTableData = table => {
  const tableBounds = getBounds(table);
  return {
    tableBounds,
    rows: getRows(tableBounds, table),
    columns: getColumns(tableBounds, table),
    header: getHeaderConfig(table),
    pageId: getPage(table),
    lockTable: false,
    disabledRows: [],
  };
};
