import { addError, removeError } from './index';

const validateNumber = ({ elem, errors, setErrors }) => {
  const { name: key, value } = elem;
  const message = `Invalid number value.`;

  if (value.length === 0) {
    removeError(key, message, errors, setErrors);
    return {
      isValid: true,
    };
  }

  if (!/^-?[0-9]*$/i.test(value)) {
    addError(key, message, errors, setErrors);
    return {
      isValid: false,
    };
  }

  removeError(key, message, errors, setErrors);
  return {
    isValid: true,
  };
};

export default validateNumber;
