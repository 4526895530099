import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import RangeSlider from '../../../../../../UI/RangeSliderV2/RangeSlider';

const SLIDER_MARKS = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

const AccuracySlider = ({ onChange, ...props }) => {
  const { defaultValue, step } = props;
  const [accuracy, setAccuracy] = useState(defaultValue);

  useEffect(() => {
    const value = Math.round(accuracy / step) * step;
    onChange({ min: value / 100 });
  }, [accuracy]);

  const handleSliderChange = newValue => {
    const value = Math.round(newValue / step) * step;
    setAccuracy(value);
  };

  return (
    <>
      <div className="rule-picker__col">
        <div className="rule-picker__slider">
          <h5 className="rule-picker__slider__label">
            Minimum Field % Prediction Confidence
          </h5>
          <div className="rule-picker__slider__container">
            <span className="rule-picker__slider__top-label">
              <i>≥</i>
              {accuracy}% confidence
            </span>
            <RangeSlider
              marks={SLIDER_MARKS}
              onChange={handleSliderChange}
              {...props}
            />
            <div className="rule-picker__slider__bottom-label">
              <div className="rule-picker__slider__bottom-label-left">
                Fewer review tasks generated, prioritising speed and
                productivity
              </div>
              <div className="rule-picker__slider__bottom-label-right">
                More review tasks generated, improving accuracy and quality
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rule-picker__col rule-picker__hint">
        <h3>What is field prediction confidence?</h3>
        <p>
          "Confidence" reflects the likelihood of an event occuring, so "field
          prediction confidence" measures the likelihood that the value Sypht
          picked for a given field, is the correct choice for that field.
        </p>
        <p>
          This condition let's you set a minimum threshold for field prediction
          confidence, so any of your selected fields that fall below this
          threshold will instantly trigger a human review task to be created to
          review that specific field
        </p>
        <h3>Is setting a higher confidence threshold always better?</h3>
        <p>
          The short answer is no. While setting a higher % threshold will result
          in higher quality output (ie better accuracy & faster self-learning)
          it will also generate more human review tasks for you to action to get
          to that level of quality, impacting productivity (ie slower speed &
          higher cost).
        </p>
        <p>
          <strong>Tip:</strong> Identify the business driver that matters most
          to your use case (productivity or quality) and the specific fields for
          which quality is critical. Then tune & test your % confidence
          threshold to find an optimal balance between productivity and quality.
        </p>
      </div>
    </>
  );
};

AccuracySlider.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  onChange: PropTypes.func,
};

AccuracySlider.defaultProps = {
  defaultValue: null,
  min: 1,
  max: 100,
  step: 1,
  onChange: () => {},
};

export default AccuracySlider;
