import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const useRefresh = data => {
  const [isRefresh, setRefresh] = useState(false);

  useEffect(() => {
    setRefresh(true);
  }, data);

  useEffect(() => {
    if (isRefresh) {
      setRefresh(false);
    }
  }, [isRefresh]);

  return isRefresh;
};

useRefresh.propTypes = {
  doc: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default useRefresh;
