import React from 'react';
import PropTypes from 'prop-types';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { getConfidence } from '../../../../../common/helpers';

const getObjectValues = (key, obj) => {
  if (typeof obj !== 'object') return [];
  let values = [];
  Object.values(obj).forEach(o => {
    if (Array.isArray(o)) {
      values = [...values, ...o];
    }
    if (typeof o === 'string') values.push(o);
  });
  return values;
};

const MultiRowField = ({ field, row }) => {
  return (
    field.value && (
      <li
        className="capture-field"
        data-row={row}
        data-confidence={getConfidence(field.confidence_norm).label}
      >
        <div className="capture-field__inner">
          <ul className="capture-field__field">
            <li className="capture-field__field-row">
              <span className="capture-field__field-heading">
                {field.fieldName.unCamelCase()}
              </span>
            </li>
            {Object.entries(field.value)
              .filter(([, value]) => value)
              .map(([key, value]) => {
                const objValues = getObjectValues(key, value);
                return (
                  <li
                    className="capture-field__field-row"
                    key={`capture-field|${field.id}|${key}`}
                  >
                    <span className="capture-field__field-sublabel">
                      {key.unCamelCase()}
                    </span>
                    {
                      {
                        boolean: value ? (
                          <FaCheckCircle className="capture-field__field-true" />
                        ) : (
                          <FaTimesCircle className="capture-field__field-false" />
                        ),
                        object: (
                          <ul className="capture-field__field-value">
                            {objValues
                              .filter((a, b) => objValues.indexOf(a) === b)
                              .map(val =>
                                val.length <= 24 ? (
                                  <li
                                    className="capture-field__field-value"
                                    key={`capture-field|${field.id}|${key}|${val}`}
                                  >
                                    {val}
                                  </li>
                                ) : (
                                  <li
                                    className="capture-field__field-long-value"
                                    title={val}
                                    key={`capture-field|${field.id}|${key}|${val}`}
                                  >
                                    {val}
                                  </li>
                                ),
                              )}
                          </ul>
                        ),
                        string:
                          value.length <= 24 ? (
                            <span className="capture-field__field-value">
                              {value}
                            </span>
                          ) : (
                            <span
                              className="capture-field__field-long-value"
                              title={value}
                            >
                              {value}
                            </span>
                          ),
                      }[typeof value]
                    }
                  </li>
                );
              })}
          </ul>
        </div>
      </li>
    )
  );
};

MultiRowField.propTypes = {
  field: PropTypes.object.isRequired,
  row: PropTypes.number,
};

MultiRowField.defaultProps = {
  row: null,
};

export default MultiRowField;
