/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useEffect, useState, useRef } from 'react';
import { FaTable } from 'react-icons/fa';
import classNames from 'classnames';
import { TableContext } from '../../DocumentView/TableContext';
import TaskContext from '../TaskContext';
import './TableView.scss';
import TableView from './TableView';

const TableAnnotation = ({
  openTableView,
  closeTableView,
  field,
  candidateSelection,
  annotation,
  prediction,
  isTableViewOpen,
  saveAnnotation,
  editable,
  index,
  onSelectRemove,
  takeFocus,
  anotatedTableData,
  setPredictionFocus,
  focusPrediction,
}) => {
  const [tableContext, setTableContext] = useContext(TableContext);
  const { editMode, toggleEditMode } = useContext(TaskContext);
  const [activeTable, setActiveTable] = useState(null);
  const [isTableEditMode, setIsTableEditMode] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [predictedTables, setPredictedTables] = useState([]);
  const ref = useRef(null);

  const onSaveTable = activeTableData => {
    const { tableData, activeTableId } = tableContext;
    const newAnnotation = {
      id: field.id,
      value: [],
    };

    setTableContext(state => ({
      ...state,
      activeTableId: null,
      tableBounds: null,
      rows: [],
      columns: [],
      header: '',
      pageId: 0,
      lockTable: false,
      disabledRows: [],
      activeTable: null,
      prediction: null,
      annotationIndex: null,
      existingTables: [],
      readOnly: true,
    }));
    saveAnnotation(activeTableData);
    toggleEditMode(false);
  };

  const onCancelTableView = () => {
    const { tableData, activeTableId } = tableContext;
    setTableContext(state => ({
      ...state,
      activeTableId: null,
      canvas: null,
      tableBounds: null,
      rows: [],
      columns: [],
      pageId: 0,
      lockTable: false,
      disabledRows: [],
      activeTable: null,
      prediction: null,
      annotationIndex: null,
      existingTables: [],
    }));
  };

  const showPrediction = (predIndex, annotationIndex) => {
    if (editable) {
      const { pageId, tableBounds } = predictedTables[predIndex];
      let scrollTo = 0;

      for (let i = 1; i < pageId; i++) {
        scrollTo +=
          document.getElementById(`pageContainer-${i}`).offsetHeight + 40;
      }
      if (tableBounds) {
        scrollTo +=
          tableBounds.tl.y *
            document.getElementById(`pageContainer-${pageId}`).offsetHeight -
          30;
      }

      document
        .getElementById('scrollContent')
        .scrollTo({ top: scrollTo, behavior: 'smooth' });

      setTableContext(state => ({
        ...state,
        activeTable: predictedTables[predIndex],
        prediction: prediction[predIndex],
        annotationIndex,
        existingTables: anotatedTableData,
        readOnly: !editable,
      }));
      setPredictionFocus(null);
    }
  };

  const createNewTable = () => {
    if (editable) {
      setTableContext(state => ({
        ...state,
        readOnly: false,
        activeTable: {
          tableBounds: null,
        },
        annotationIndex: index,
        existingTables: anotatedTableData,
      }));
    }
  };

  const openTable = () => {
    onCancelTableView();
    setPredictionFocus(null);

    const tableData = annotation.tableData || getTableData(annotation, field);
    const { pageId, tableBounds } = tableData;

    let scrollTo = 0;

    for (let i = 1; i < pageId; i++) {
      scrollTo +=
        document.getElementById(`pageContainer-${i}`).offsetHeight + 40;
    }
    if (tableBounds) {
      scrollTo +=
        tableBounds.tl.y *
          document.getElementById(`pageContainer-${pageId}`).offsetHeight -
        30;
    }

    document
      .getElementById('scrollContent')
      .scrollTo({ top: scrollTo, behavior: 'smooth' });

    setTableContext(state => ({
      ...state,
      activeTable: tableData,
      annotationIndex: index,
      readOnly: !editable,
    }));
  };

  const getTableData = (table, field) => {
    const tableBounds = getTableBounds(table, field);

    return {
      tableBounds,
      rows: getRows(tableBounds, table, field),
      columns: getColumns(tableBounds, table, field),
      header: 'row',
      pageId: getPage(table, field),
      lockTable: false,
      disabledRows: [],
    };
  };
  const getTableColumns = (table, field) => {
    if (field.type === 'table') {
      return table.components
        ? table.components.column.items
        : table.fields.find(f => f.id === 'column').value;
    }

    if (field.type === 'table:2') {
      return table.columns.filter(c =>
        field.columns.find(
          fc =>
            (fc.id === c.id || fc.id === c.category) && fc.type === 'column',
        ),
      );
    }
  };

  const getColumnTokens = (column, field) => {
    if (field.type === 'table') {
      return column.components
        ? [column.components.label, ...column.components.value.items]
        : [
            column.fields.find(f => f.id === 'label').data.value,
            ...(column.fields.find(f => f.id === 'value').value || []).map(
              v => v.data.value,
            ),
          ].flat();
    }
    if (field.type === 'table:2') {
      if (
        (column.header !== null &&
          Object.keys(column.header).includes('tokens')) ||
        column.cells.find(c => Object.keys(c || {}).includes('tokens'))
      ) {
        return [
          ...(column.header !== null ? column.header.tokens : []),
          ...column.cells
            .filter(c => c !== null)
            .map(c => c.tokens)
            .flat(),
        ];
      }
      return [
        ...(column.header.data.value || []),
        ...column.cells.map(c => c.data.value).flat(),
      ];
    }
  };
  const getRowsFromColumn = column => {
    if (column.cells) {
      return [column.header, ...column.cells];
    }
    if (column.components) {
      return [column.components.label, ...column.components.value.items];
    }
    return [
      column.fields.find(f => f.id === 'label').data.value,
      ...(column.fields.find(f => f.id === 'value').value || []).map(
        v => v.data.value,
      ),
    ].map(row => {
      if (!row) return null;
      let tl = { x: 1.0, y: 1.0 };
      let br = { x: 0.0, y: 0.0 };
      for (const token of row) {
        if (token) {
          tl = {
            x: Math.min(token.bounds.topLeft.x, tl.x),
            y: Math.min(token.bounds.topLeft.y, tl.y),
          };
          br = {
            x: Math.max(token.bounds.bottomRight.x, br.x),
            y: Math.max(token.bounds.bottomRight.y, br.y),
          };
        }
      }
      return { bounds: { topLeft: tl, bottomRight: br } };
    });
  };

  const getTableBounds = (table, field) => {
    let tl = { x: 1.0, y: 1.0 };
    let br = { x: 0.0, y: 0.0 };

    const columns = getTableColumns(table, field);

    for (const column of columns) {
      const tokens = getColumnTokens(column, field);
      for (const token of tokens) {
        if (token) {
          tl = {
            x: Math.min(token.bounds.topLeft.x, tl.x),
            y: Math.min(token.bounds.topLeft.y, tl.y),
          };
          br = {
            x: Math.max(token.bounds.bottomRight.x, br.x),
            y: Math.max(token.bounds.bottomRight.y, br.y),
          };
        }
      }
    }
    return { tl, br };
  };

  const getRows = (tableBounds, table, field) => {
    const rows = [];
    let rowBounds = [];

    const columns = getTableColumns(table, field);

    columns.forEach((column, i) => {
      const rows = getRowsFromColumn(column);
      if (columns[i - 1]) {
        rows.forEach((row, j, a) => {
          rowBounds[j] = {
            top: Math.min(
              // eslint-disable-next-line no-nested-ternary
              row !== null
                ? row.bounds
                  ? row.bounds.topLeft.y
                  : row.data.value
                  ? Math.min(...row.data.value.map(v => v.bounds.topLeft.y))
                  : 1.0
                : 1.0,
              rowBounds[j].top,
            ),
            bottom: Math.max(
              // eslint-disable-next-line no-nested-ternary
              row !== null
                ? row.bounds
                  ? row.bounds.bottomRight.y
                  : row.data.value
                  ? Math.max(...row.data.value.map(v => v.bounds.bottomRight.y))
                  : 0.0
                : 0.0,
              rowBounds[j].bottom,
            ),
          };
        });
      } else {
        rowBounds = rows.map(e =>
          e === null
            ? {
                top: 1.0,
                bottom: 0.0,
              }
            : {
                top: e.bounds
                  ? e.bounds.topLeft.y
                  : e.data.value
                  ? Math.min(...e.data.value.map(v => v.bounds.topLeft.y))
                  : 1.0,
                bottom: e.bounds
                  ? e.bounds.bottomRight.y
                  : e.data.value
                  ? Math.max(...e.data.value.map(v => v.bounds.bottomRight.y))
                  : 1.0,
              },
        );
      }
    });
    rowBounds.forEach((row, i) => {
      if (rowBounds[i + 1]) {
        const rowY = (row.bottom + rowBounds[i + 1].top) / 2;
        rows.push({
          tl: {
            x: tableBounds.tl.x,
            y: rowY,
          },
          br: {
            x: tableBounds.br.x,
            y: rowY,
          },
        });
      }
    });
    return rows;
  };
  const getColumns = (tableBounds, table, field) => {
    const columns = [];

    const tableColumns = getTableColumns(table, field);
    tableColumns.forEach((column, i) => {
      if (tableColumns[i + 1]) {
        const maxXA = getColumnTokens(column, field)
          .filter(e => e !== null)
          .reduce((a, b) => Math.max(a, b.bounds.bottomRight.x), 0);

        const minXB = getColumnTokens(tableColumns[i + 1], field)
          .filter(e => e !== null)
          .reduce((a, b) => Math.min(a, b.bounds.topLeft.x), 1.0);
        const colX = (maxXA + minXB) / 2;
        columns.push({
          tl: {
            x: colX,
            y: tableBounds.tl.y,
          },
          br: {
            x: colX,
            y: tableBounds.br.y,
          },
        });
      }
    });
    return columns;
  };

  const getPage = (table, field) => {
    const column = getTableColumns(table, field)[0];
    for (const token of getColumnTokens(column, field)) {
      if (token) {
        return token.bounds.pageNum;
      }
    }
  };

  /**
   * useEffect hooks
   */

  useEffect(() => {
    if (prediction !== null && !annotation) {
      setPredictedTables(prediction.map(t => getTableData(t, field)));
    }
  }, [prediction, annotation]);

  useEffect(() => {
    if (
      ref.current &&
      takeFocus &&
      !ref.current.contains(document.activeElement)
    ) {
      ref.current.focus();
    }
  });

  // close tableview annotation if user edits other fields that changes focus
  useEffect(() => {
    if (focusPrediction !== null) {
      onCancelTableView();
    }
  }, [focusPrediction]);

  return (
    <div ref={ref} className="table-annotation">
      <div className="instance-entry">
        <div className="instance-fields">
          {editable && !annotation && (
            <>
              <div className="prediction-list">
                {predictedTables &&
                  predictedTables.map((p, i) => (
                    <div className="prediction" key={i}>
                      <div
                        className="value"
                        onClick={() => showPrediction(i, index)}
                      >
                        <div className="table-suggestion">
                          <div className="table-suggestion-header">
                            Show Suggested Table
                          </div>
                          <div className="table-suggestion-content">
                            Page {p.pageId} &raquo; {p.rows.length} rows x{' '}
                            {p.columns.length} columns
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                {(!predictedTables || predictedTables.length === 0) && (
                  <div className="no-prediction">
                    <div className="value">No suggestions available</div>
                  </div>
                )}
              </div>
              <div className="field-input-select">
                <div
                  className={classNames('button-div', {
                    disabled: !editable,
                  })}
                  onClick={() => createNewTable()}
                >
                  Select table
                </div>
              </div>
            </>
          )}
          {annotation && (
            <div className="field-value-instance">
              <div className="field-value">
                <div className="table-detail">
                  <FaTable />
                  <span>
                    Table &raquo; Page{' '}
                    {annotation.tableData
                      ? annotation.tableData.pageId
                      : getTableData(annotation, field).pageId}
                    ,{' '}
                  </span>
                </div>
                {editable && (
                  <div
                    className={classNames('button-div', 'edit', {
                      disabled: isTableEditMode,
                    })}
                    onClick={!isTableEditMode ? () => openTable() : null}
                  >
                    Edit
                  </div>
                )}
                {!editable && (
                  <div
                    className={classNames('button-div', 'edit', {
                      disabled: isTableEditMode,
                    })}
                    onClick={!isTableEditMode ? () => openTable() : null}
                  >
                    View
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {tableContext.activeTable !== null &&
          tableContext.annotationIndex === index && (
            <TableView
              candidateSelection={candidateSelection}
              onCancelTableView={onCancelTableView}
              onSaveTableView={onSaveTable}
              savedAnnotation={annotation}
              field={field}
            />
          )}
      </div>
    </div>

    // </div>
  );
};

export default TableAnnotation;
