import React, { useEffect, useState, Fragment } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import ReactLoading from 'react-loading';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { fetchProducts } from '../../../../reducers/products/actions';
import Page, {
  PageInner,
  PageNav,
  PageHeader,
  PageBody,
  PageRow,
} from '../../../Page';
import Subscription from './Subscription';
import {
  fetchSubscriptions,
  subscribePaid,
} from '../../../../reducers/subscriptions/actions';
import usePrevious from '../../../../common/hooks/usePrevious';
import './SubscriptionList.scss';

function compare(a, b) {
  const aTags = a.tags || [];
  const bTags = b.tags || [];

  if (aTags.includes('legacy') && !bTags.includes('legacy')) return 1;
  if (bTags.includes('legacy') && !aTags.includes('legacy')) return -1;
  if (a.name > b.name) return 1;
  if (a.name < b.name) return -1;
  return 0;
}

const mapStateToProps = state => {
  const { products, subscriptions, auth } = state;
  return {
    products: products.data || null,
    subscriptions: subscriptions.data,
    isLoading: subscriptions.loading,
    user: auth.user ? auth.user.data : null,
    scopes: auth.claims.allowedScopes || [],
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      subscribe: productId => dispatch(subscribePaid(productId)),
      fetchProducts: () => dispatch(fetchProducts()),
      fetchSubscriptions: () => dispatch(fetchSubscriptions()),
    },
  };
};

const SubscriptionList = ({
  products,
  subscriptions,
  dispatch,
  user,
  isLoading,
}) => {
  const [sortedList, setSortedList] = useState(null);
  const prevProps = usePrevious({ user });

  useEffect(() => {
    if (
      user &&
      (!prevProps || !prevProps.user || prevProps.user.id !== user.id)
    ) {
      dispatch.fetchProducts();
      dispatch.fetchSubscriptions();
    }
  }, [user]);

  /**
   * Sort subscriptions with legacy products at the bottom
   */
  useEffect(() => {
    if (subscriptions) {
      setSortedList(
        subscriptions
          .filter(s => !s.endDate || moment(s.endDate) > moment())
          .sort(compare),
      );
    }
  }, [subscriptions]);

  return (
    <>
      <Page className="marketplace" title="My Subscriptions - Marketplace">
        <PageInner>
          <PageNav
            items={[
              { label: 'Marketplace', to: '/marketplace' },
              {
                label: 'My Subscriptions',
                to: '/marketplace/subscriptions',
              },
            ]}
          />
          <PageHeader>
            <h1 className="page__heading">My Subscriptions</h1>
          </PageHeader>
          <PageBody>
            <PageRow>
              <div className="subscriptions-container">
                {!sortedList || isLoading ? (
                  <div className="loading-wrapper">
                    <ReactLoading
                      type="spin"
                      color="#dddddd"
                      height={128}
                      width={128}
                    />
                  </div>
                ) : (
                  <ul className="subscription-list">
                    {sortedList.map(s => (
                      <li className="subscription-list__item" key={s.productId}>
                        <Subscription
                          product={(products || []).find(
                            p => p.productId === s.productId,
                          )}
                          subscription={s}
                        />
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </PageRow>
            <PageRow>
              <div className="footer-advice">
                <FaInfoCircle /> To cancel an active subscription or otherwise
                enquire about billing please contact:&nbsp;
                <a
                  target="_blank"
                  href="mailto:finance@sypht.com"
                  rel="noreferrer"
                >
                  finance@sypht.com
                </a>
              </div>
            </PageRow>
          </PageBody>
        </PageInner>
      </Page>
      <ReactTooltip place="right" variant="dark" />
    </>
  );
};

SubscriptionList.propTypes = {
  products: PropTypes.array,
  subscriptions: PropTypes.arrayOf(PropTypes.object),
  user: PropTypes.object,
  isLoading: PropTypes.bool,
  dispatch: PropTypes.shape({
    fetchSubscriptions: PropTypes.func.isRequired,
  }),
};

SubscriptionList.defaultProps = {
  products: [],
  subscriptions: [],
  user: null,
  isLoading: false,
  dispatch: {
    fetchSubscriptions: () => {},
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionList);
