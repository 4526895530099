import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import loadHubspot from '../../common/helpers/loadHubspot';

import PageLoading from '../Page/PageLoading';
import RegisterInviteForm from './components/RegisterInviteForm';

import { registerInviteUser } from '../../reducers/user/actions';

import './Register.scss';
import { PageHelmet } from '../Page';
import { DEFAULT_LANDING_ROUTE } from '../../common/constants';

const mapStateToProps = state => {
  const { user } = state;
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isLoading: user.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      registerInviteUser: user => dispatch(registerInviteUser(user)),
    },
  };
};

const RegisterInvite = ({ isLoading, dispatch, match, isAuthenticated }) => {
  const [user, setUser] = useState(null);

  // Inject Hubsport script on this page
  useEffect(() => {
    loadHubspot();
    window.sessionStorage.setItem('redirectTo', DEFAULT_LANDING_ROUTE);
  }, []);

  useEffect(() => {
    if (match.params.serial) {
      try {
        const userDetails = JSON.parse(
          window.atob(decodeURIComponent(match.params.serial)),
        );
        setUser(userDetails);
      } catch (err) {
        // redirect if user serial is invalid
        window.location.href = '/';
      }
    }
  }, [match]);

  const submitForm = formFields => {
    dispatch.registerInviteUser(formFields);
  };

  return (
    <div className="register register-container">
      <PageHelmet title="Register" />
      {isAuthenticated ? <Redirect to="/" /> : null}
      <h2 className="register-heading">
        <span>Create an account</span>
      </h2>

      {isLoading && <PageLoading />}

      {!isLoading && user && (
        <RegisterInviteForm handleSubmit={submitForm} user={user} />
      )}
    </div>
  );
};

RegisterInvite.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  match: PropTypes.object,
  dispatch: PropTypes.shape({
    registerInviteUser: PropTypes.func,
  }),
};

RegisterInvite.defaultProps = {
  isLoading: false,
  match: null,
  dispatch: {
    registerInviteUser: () => {},
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterInvite);
