import React from 'react';
import PropTypes from 'prop-types';
import ConfidenceIndicator from '../../../../library/molecules/fields/ConfidenceIndicator';
import * as signal from '../../../../library/atoms/Signal/Signal';
import SignalField from '../../../../library/molecules/fields/SignalField';
import FieldGroup from '../../../../library/molecules/fields/FieldGroup';
import { getConfidence } from '../../../../common/helpers/confidenceHelper';

const tooltip =
  'This signal compares the relationship between Bank BSB, Bank Account Number, and ABN from this payment document to all payment documents Sypht has extracted before, to indicate % likelihood that this payment document is fraudulent';

const getSignalVariant = fraudChancePerc => {
  if (fraudChancePerc < 30) {
    return 'success';
  }
  if (fraudChancePerc > 70) {
    return 'danger';
  }
  return 'warning';
};

const getFraudMessage = fraudChancePerc => {
  if (fraudChancePerc < 30) {
    return '<30% likelihood of fraud';
  }
  if (fraudChancePerc > 70) {
    return '>70% likelihood of fraud';
  }
  return `${fraudChancePerc}% likelihood of fraud`;
};

const FraudSignal = ({ fraudChance, confidence }) => {
  const fraudChancePerc = Math.round(fraudChance * 100);
  const signalConfidence = getConfidence(confidence);
  return (
    <FieldGroup>
      <SignalField name="Payment Fraud Signal" tooltip={tooltip}>
        <signal.Signal variant={getSignalVariant(fraudChancePerc)}>
          <signal.Message>{getFraudMessage(fraudChancePerc)}</signal.Message>
          <signal.Child>
            <ConfidenceIndicator
              variant={signalConfidence.label}
              tooltipId="document-tooltip"
              tooltip={signalConfidence.text}
            />
          </signal.Child>
        </signal.Signal>
      </SignalField>
    </FieldGroup>
  );
};

FraudSignal.propTypes = {
  fraudChance: PropTypes.number.isRequired,
  confidence: PropTypes.number.isRequired,
};

export default FraudSignal;
