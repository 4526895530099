import React, { useState } from 'react';
import { FaTable } from 'react-icons/fa';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toggleDrawer } from '../../../store/actions';

import CaptureDrawer from '../../Drawer/CaptureDrawer';
import CaptureTable from '../../Table/CaptureTable';

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      toggleDrawer: isOpen => dispatch(toggleDrawer(isOpen)),
    },
  };
};

/**
 * Convert a certain field types into format we can render as a table.
 */
function getLineItems(field) {
  switch (field.type) {
    case 'lineitems': {
      const result = field.value.map(({ headers, cells }) => {
        return {
          headers,
          rows: cells.map(cell => cell.map(item => item || { text: '' })),
        };
      });
      return result;
    }
    case 'tables:2': {
      const result = field.value.items
        .filter(item => item.type === 'table')
        .map(table => {
          // Pick a row to transpose table.columns...
          const rowToUse = table.columns[0].cells;
          // Transposing columns into rows...
          const rows = rowToUse
            .reduce((acc, _, idx) => {
              table.columns.forEach(col => {
                acc[idx] = acc[idx] || [];
                acc[idx].push(col.cells[idx] || { text: '' });
              });
              return acc;
            }, [])
            .filter(row => {
              // Remove dangling items.
              // Heurstic: if 1st field in the row is blank, hide it.
              // eg GST tax amount may be returned but not have any data like a normal lineitem.
              return !!row[0]?.text;
            });
          return {
            headers: table.columns.map(col => col.header),
            rows,
          };
        });
      return result;
    }
    default:
      return [];
  }
}

/**
 * Handles lineitems and renders as a table.
 *
 * Since this is basically a component that shows tabulated data, it has been
 * extended to support tables:2 field types (which are generated by lineitems:3 in invoices:2).
 */
const LineItemsField = ({ field, row, dispatch }) => {
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    dispatch.toggleDrawer(true);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch.toggleDrawer(false);
  };

  const lineItems = getLineItems(field);

  return field.value ? (
    <li className="capture-field" data-row={row}>
      <div className="capture-field__inner">
        <div className="capture-field__field">
          <div className="capture-field__field-row">
            <span className="capture-field__field-label">Line Items</span>
            <ul className="capture-field__field-value">
              {(lineItems || []).map((table, i) => {
                const { headers, rows } = table;
                const label = `Table${
                  field.value.length > 1 ? ` ${i + 1}` : ''
                }`;
                return (
                  <li
                    className="capture-field__field-value-row"
                    key={`capture-field|${field.id}`}
                  >
                    <button
                      type="button"
                      className="capture-field__field-value-btn"
                      onClick={handleOpen}
                    >
                      {label} <FaTable />
                    </button>
                    <CaptureDrawer isOpen={isOpen} onClose={handleClose}>
                      <div className="capture-drawer__header">
                        <h2 className="capture-drawer__heading">{label}</h2>
                      </div>
                      <div className="capture-drawer__body">
                        <CaptureTable>
                          <thead>
                            <tr>
                              {(headers || []).map(th => (
                                <th key={`${label}|th|${th.text}`}>
                                  {th.text}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {(rows || []).map((tr, idx) => {
                              const rowKey = `${label}|tr|${idx}`;
                              return (
                                <tr key={rowKey}>
                                  {tr.map((td, idx2) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <td key={`${rowKey}|${idx2}`}>{td.text}</td>
                                  ))}
                                </tr>
                              );
                            })}
                          </tbody>
                        </CaptureTable>
                      </div>
                    </CaptureDrawer>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </li>
  ) : null;
};

LineItemsField.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.arrayOf(PropTypes.shape({})),
    ]),
  }).isRequired,
  row: PropTypes.number,
  dispatch: PropTypes.shape({
    toggleDrawer: PropTypes.func,
  }),
};

LineItemsField.defaultProps = {
  row: null,
  dispatch: { toggleDrawer: () => {} },
};

export default connect(null, mapDispatchToProps)(LineItemsField);
