import { useEffect, useRef } from 'react';

const useScrollPredictionIntoView = (predictions = []) => {
  const scrollToElement = useRef(null);

  useEffect(() => {
    if (scrollToElement.current) {
      // scrollIntoViewOptions : { block: 'center', behavior: 'smooth' }
      // above options dont work in Chrome. Need to use options without behavior.
      scrollToElement.current.scrollIntoView({ block: 'center' });
      // reset it once we have scrolled into view.
      scrollToElement.current = null;
    }
  }, predictions);

  const isFieldTheSame = (f, prediction) =>
    prediction.field && f.field && f.field === prediction.field;

  const isKeyTheSame = (f, prediction) =>
    prediction.key && f.key && f.key === prediction.key;

  const isUidTheSame = (f, prediction) =>
    prediction.uid && f.uid && f.uid === prediction.uid;

  const setScrollToElement = (f, prediction, element) => {
    if (
      scrollToElement.current === null &&
      prediction &&
      (isFieldTheSame(f, prediction) ||
        isKeyTheSame(f, prediction) ||
        isUidTheSame(f, prediction))
    ) {
      scrollToElement.current = element;
    }
  };

  return {
    setScrollToElement,
  };
};

export default useScrollPredictionIntoView;
