import * as utils from './utils';

const apiBaseUrl = process.env.APP_API_HOST_NAME;

class LookupService {
  getCountries = accessToken => {
    return utils.getData(`${apiBaseUrl}/app/countries`, {
      accessToken,
    });
  };
}

export default new LookupService();
