import React from 'react';
import { FaSort, FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { getParentByClass } from '../../../../common/helpers';

export const isSkipCell = (row, i) => {
  const { cells } = row;
  if (i > 0 && cells[i - 1].column.id === 'mergeColumn') {
    return true;
  }

  if (cells[i].column.optionalSelector) {
    return cells[i].column.optionalSelector(row);
  }

  return false;
};

export const isOverrideRowClick = elem => {
  if (!elem) return false;
  const cell = getParentByClass(elem, 'table__td-inner');
  if (!cell) return false;
  return !!(
    cell.querySelector('input') ||
    cell.querySelector('button') ||
    cell.querySelector('a[href]')
  );
};

export const setClassName = (elem, modifier) => {
  return modifier ? `${elem}--${modifier.replaceAll('.', '-')}` : '';
};

export const setColSpan = (row, i) => {
  const { cells } = row;

  if (cells[i].column.colspanSelector) {
    return cells[i].column.colspanSelector(row);
  }

  if (cells[i].column.id.includes('mergeColumn')) {
    return 2;
  }
  return undefined;
};

export const setSortIcon = col => {
  if (!col.canSort) return undefined;
  if (!col.isSorted)
    return <FaSort className="table__sort-icon table__sort-icon--unsorted" />;
  return col.isSortedDesc ? (
    <FaCaretDown className="table__sort-icon table__sort-icon--sorted-desc" />
  ) : (
    <FaCaretUp className="table__sort-icon table__sort-icon--sorted-asc" />
  );
};
