import React, { useEffect, useMemo, useState } from 'react';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getTasks } from '../../helpers';
import { Button } from '../../../../library/atoms/Buttons';
import AddTaskModal, {
  ADD_TASK_MODES,
} from '../../../../components/AddTaskModal/AddTaskModal';
import { fetchSpecs } from '../../../../reducers/specs';
import { deleteTasks, editTasks } from '../../../../reducers/tasks';
import DeleteTasksModal from '../../../../components/DeleteTasksModal/DeleteTasksModal';
import TagDropDown, {
  getSelectedTags,
} from '../../../../components/UI/TagDropdown/TagDropdown';
import {
  updateTaskTags,
  createCompanyTag,
  fetchApplyTags,
} from '../../../../reducers/tags/action';
import NextTaskButton from '../NextTaskButton/NextTaskButton';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const initialApplyTagParams = {
  limit:100,
  includeCounts:true,
  sortDirection:'asc',
  sortColumn:'name'
}

const mapStateToProps = state => {
  const { auth, specs, tags, tasks } = state;
  return {
    specs: specs?.data?.specifications,
    tags: tags?.applyTags.data,
    tasks: tasks?.data?.tasks,
    canReviewTasks: auth.permissions.canReviewTasks,
    canWriteTask: auth.permissions.canWriteTask,
    canTagTask: auth.permissions.canTagTask,
    canWriteTag: auth.permissions.canWriteTag,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      fetchSpecs: () => dispatch(fetchSpecs()),
      editTasks: (tasks, replication, priority) =>
        dispatch(editTasks(tasks, replication, priority)),
      deleteTasks: tasks => dispatch(deleteTasks(tasks)),
      updateTaskTags: data => dispatch(updateTaskTags(data)),
      createCompanyTag: data => dispatch(createCompanyTag(data)),
      fetchApplyTags: (companyId, params) => dispatch(fetchApplyTags(companyId, params)),
    },
  };
};

const TasksMenu = ({
  companyId,
  filters,
  selectedTasks,
  onActionCompleted,
  onTagDropdownConfirm,
  specs,
  tags,
  tasks,
  dispatch,
  sortBy,
  canReviewTasks,
  canWriteTask,
  canTagTask,
  canWriteTag,
}) => {
  const [isEditTasksOpen, setEditTasksOpen] = useState(false);
  const [isDeleteTasksOpen, setDeleteTasksOpen] = useState(false);
  const [tagSearch, setTagSearch] = useState(null);

  const isTasksSelected = Object.keys(selectedTasks || []).length;

  const selectedTasksObj = useMemo(
    () => getTasks(selectedTasks, tasks),
    [selectedTasks, tasks],
  );

  const selectedTags = useMemo(
    () => getSelectedTags(selectedTasksObj),
    [selectedTasksObj],
  );
  const selections = useMemo(
    () => selectedTags && Object.keys(selectedTags).length > 0 ?
      Object.keys(selectedTags).sort().join(',') :
      null,
    [selectedTags],
  );
  const applyTagParams = useMemo(
    () => {
      let tagName = null;
      if(tagSearch && tagSearch !== ''){
        tagName = tagSearch;
      }
      return {...initialApplyTagParams, selections, name: tagName}
    },
    [selections, tagSearch],
  );


  useEffect(() => {
    if(companyId){
      let tagName = null;
      if(tagSearch && tagSearch !== ''){
        tagName = tagSearch;
      }
      dispatch.fetchApplyTags(companyId, {...initialApplyTagParams, selections, name: tagName});
    }
  }, [companyId, selections, tagSearch, dispatch]);
  
  useEffect(() => {
    if (!specs) dispatch.fetchSpecs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    setEditTasksOpen(false);
    setDeleteTasksOpen(false);
  };

  const handleActionCompleted = () => {
    setEditTasksOpen(false);
    setDeleteTasksOpen(false);
    onActionCompleted();
  };

  const handleTagDropdownConfirm = (addTags, removeTags) => {
    dispatch
      .updateTaskTags({
        taskIds: selectedTasks,
        addTags,
        removeTags,
        companyId: companyId,
      })
      .then(() => {
        onTagDropdownConfirm();
        dispatch.fetchApplyTags(companyId, applyTagParams);
      });
  };

  const onTagDropdownSearch = (tagName) => {
    setTagSearch(tagName);
  }

  const onCreateTag = tagName => {
    dispatch.createCompanyTag({ name: tagName, companyId }).then(() => {
      dispatch.fetchApplyTags(companyId, applyTagParams);
    });
  };

  return (
    <>
      <ul className="tasks-menu page__cta">
        {canReviewTasks && (
          <li className="page__cta-item" data-tooltip-id="edit-button-tooltip">
            <NextTaskButton filters={filters} sortBy={sortBy} />
            <ReactTooltip
              id="edit-button-tooltip"
              content="Edit selected tasks"
              place="bottom"
              style={{
                top: '4',
                bottom: '4',
                left: '4',
                position: 'fixed',
                zIndex: 9999,
              }}
            />
          </li>
        )}
        {canTagTask && (
          <li
            className="page__cta-item"
            data-tooltip-content="Edit selected tasks"
          >
            <TagDropDown
              className="tag-dd-tasks"
              selectedTags={selectedTags}
              tags={tags || []}
              isDisabled={!isTasksSelected}
              enableTagCreation={canWriteTag}
              onConfirm={handleTagDropdownConfirm}
              onCreateTag={onCreateTag}
              onSearch={onTagDropdownSearch}
            />
          </li>
        )}
        {canWriteTask && (
          <li className="page__cta-item" data-tooltip-id="edit-button-tooltip">
            <Button
              isResponsive
              disabled={!isTasksSelected}
              onClick={() => setEditTasksOpen(true)}
            >
              <FaEdit />
              <span>Edit Tasks</span>
            </Button>
          </li>
        )}
        {canWriteTask && (
          <li
            className="page__cta-item"
            data-tooltip-id="remove-button-tooltip"
          >
            <Button
              isResponsive
              disabled={!isTasksSelected}
              onClick={() => setDeleteTasksOpen(true)}
            >
              <FaTrashAlt className="fa-trash-alt" />
              <span>Remove</span>
            </Button>
            <ReactTooltip
              id="remove-button-tooltip"
              content="Remove selected tasks"
              place="bottom"
              style={{
                top: '4',
                bottom: '4',
                left: '4',
                position: 'fixed',
                zIndex: 9999,
              }}
            />
          </li>
        )}
      </ul>
      {isEditTasksOpen && (
        <AddTaskModal
          mode={ADD_TASK_MODES.EDIT}
          companyId={filters.companyId}
          editTasks={dispatch.editTasks}
          selectedTasks={selectedTasksObj}
          specs={specs}
          isOpen={isEditTasksOpen}
          onCancelModal={handleCancel}
          onCloseModal={handleActionCompleted}
        />
      )}
      {isDeleteTasksOpen && (
        <DeleteTasksModal
          deleteTasks={dispatch.deleteTasks}
          selectedTasks={selectedTasksObj}
          isOpen={isDeleteTasksOpen}
          onCancelModal={handleCancel}
          onCloseModal={handleActionCompleted}
        />
      )}
    </>
  );
};

TasksMenu.propTypes = {
  companyId: PropTypes.string,
  filters: PropTypes.shape({
    companyId: PropTypes.string,
  }),
  selectedTasks: PropTypes.arrayOf(PropTypes.shape({})),
  specs: PropTypes.arrayOf(PropTypes.shape({})),
  tags: PropTypes.arrayOf(PropTypes.shape({})),
  tasks: PropTypes.arrayOf(PropTypes.shape({})),
  onActionCompleted: PropTypes.func,
  sortBy: PropTypes.arrayOf(
    PropTypes.shape({
      column: PropTypes.string,
      order: PropTypes.string,
    }),
  ),
  dispatch: PropTypes.shape({
    deleteTasks: PropTypes.func,
    editTasks: PropTypes.func,
    createCompanyTag: PropTypes.func,
    fetchSpecs: PropTypes.func,
    updateTaskTags: PropTypes.func,
    fetchApplyTags: PropTypes.func,
  }).isRequired,
  canReviewTasks: PropTypes.bool.isRequired,
  canWriteTask: PropTypes.bool.isRequired,
  canTagTask: PropTypes.bool.isRequired,
  canWriteTag: PropTypes.bool.isRequired,
};

TasksMenu.defaultProps = {
  companyId: null,
  filters: null,
  selectedTasks: [],
  specs: null,
  tags: null,
  tasks: null,
  onActionCompleted: () => {},
  sortBy: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksMenu);
