/* eslint-disable react/prop-types */
import React from 'react';
import TourStepHighlight from './TourStepHighlight';
import UploadDocuments from './TourContent/IntroductionToSypht/UploadDocuments';
import SplitDocuments from './TourContent/IntroductionToSypht/SplitDocuments';
import ExtractInformation from './TourContent/IntroductionToSypht/ExtractInformation';
import ViewExtraction from './TourContent/IntroductionToSypht/ViewExtraction';
import ExportData from './TourContent/IntroductionToSypht/ExportData';
import HelpCentre from './TourContent/IntroductionToSypht/HelpCentre';

const IntroToSypht = navigate => ({
  name: 'Introduction to Sypht',
  url: '/documents',
  tours: [
    {
      id: 'tourUploadDocuments',
      name: 'Upload documents',
      description: closeTour => <UploadDocuments closeTour={closeTour} />,
      tourSteps: [
        {
          position: 'bottom',
          action: () => {
            if (window.location.pathname !== '/documents')
              navigate('/documents');
          },
          selector: '[data-tut="reactour_intro_1"]',
          content: ({ close, goTo, inDOM, step }) => (
            <TourStepHighlight
              title="Upload your first document"
              body='We support multiple file types, including PDF, and many popular image formats. If you have a bulk PDF containing multiple documents in one file, turn on "Smart Split" to separate them on upload.'
              closeTour={close}
              gotoStep={goTo}
              inDOM={inDOM}
              current={step}
            />
          ),
          mutationObservables: ['.doc-tabs'],
          resizeObservables: ['.doc-tabs'],
        },
      ],
    },
    {
      id: 'tourSplitDocuments',
      name: 'Split bulk documents',
      description: () => <SplitDocuments />,
      tourSteps: [
        {
          position: 'bottom',
          action: () => {
            if (window.location.pathname !== '/documents')
              navigate('/documents');
          },
          selector: '[data-tut="reactour_intro_2"]',
          shouldShowSkipButton: true,
          content: ({ close, goTo, inDOM, step }) => (
            <TourStepHighlight
              title="Split any bulk documents"
              body="If you have uploaded a bulk PDF containing multiple documents in one file, you can use Sypht AI to automatically identify all individual documents within the file, and split them out."
              closeTour={close}
              gotoStep={goTo}
              inDOM={inDOM}
              current={step}
              shouldShowSkipButton
            />
          ),
        },
      ],
    },
    {
      id: 'tourExtractInformation',
      name: 'Extract document data',
      description: closeTour => <ExtractInformation closeTour={closeTour} />,
      tourSteps: [
        {
          position: 'bottom',
          action: () => {
            if (window.location.pathname !== '/documents')
              navigate('/documents');
          },
          selector: '[data-tut="reactour_intro_3"]',
          content: ({ close, goTo, inDOM, step }) => (
            <TourStepHighlight
              title="Extract your first document"
              body="Visit Sypht Marketplace to get access to Sypht AI products that best reflect your document types. Once ready, select your document(s) and apply the relevant AI product to extract the valuable data trapped within them."
              closeTour={close}
              gotoStep={goTo}
              inDOM={inDOM}
              current={step}
            />
          ),
        },
      ],
    },
    {
      id: 'tourViewExtraction',
      name: 'View extracted data',
      description: () => <ViewExtraction />,
      tourSteps: [
        {
          position: 'bottom',
          action: () => {
            if (window.location.pathname !== '/documents')
              navigate('/documents');
          },
          selector:
            'div.documents-table .table__tbody .table__td:nth-of-type(2)',
          content: ({ close, goTo, inDOM, step }) => (
            <TourStepHighlight
              title="View your extraction"
              body="Open your processed document to see the data that Sypht AI extracted from the file, including the % confidence for each field showing you how confident Sypht AI was in its extraction."
              closeTour={close}
              gotoStep={goTo}
              inDOM={inDOM}
              current={step}
            />
          ),
        },
      ],
    },
    {
      id: 'tourExportStructuredData',
      name: 'Export extracted data',
      description: () => <ExportData />,
      tourSteps: [
        {
          position: 'bottom',
          action: () => {
            if (window.location.pathname !== '/documents')
              navigate('/documents');
          },
          selector: '[data-tut="reactour_intro_6"]',
          content: ({ close, goTo, inDOM, step }) => (
            <TourStepHighlight
              title="Export your extraction"
              body="Select your extracted document(s), and export their extracted data out of Sypht for further use. Specify what fields you'd like to export, their layout, and the .CSV delimiter format, ensuring exported data is ready for further processing, analysis, or other uses."
              closeTour={close}
              gotoStep={goTo}
              inDOM={inDOM}
              current={step}
            />
          ),
        },
      ],
    },
    {
      id: 'tourVisitHelpCenter',
      name: 'Get help & support',
      description: () => <HelpCentre />,
      tourSteps: [
        {
          position: 'bottom',
          action: () => {
            if (window.location.pathname !== '/documents')
              navigate('/documents');
          },
          selector: '.dropdown__help',
          content: ({ close, goTo, inDOM, step }) => (
            <TourStepHighlight
              title="Help resources"
              body="Access a range of resources to learn more, or contact us for help and support."
              closeTour={close}
              gotoStep={goTo}
              inDOM={inDOM}
              current={step}
            />
          ),
        },
      ],
    },
  ],
});

export default IntroToSypht;
