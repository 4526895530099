import React from 'react';
import { connect } from 'react-redux';
import { FaSearch, FaRegCreditCard } from 'react-icons/fa';
import { MdSettings, MdWidgets } from 'react-icons/md';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PropTypes from 'prop-types';
import ContextBar from '../../../UI/ContextBar/ContextBar';
import ContextBarBtn from '../../../UI/ContextBar/ContextBarBtn';
import ContextBarSubHeading from '../../../UI/ContextBar/ContextBarSubHeading';

const mapStateToProps = state => {
  const { auth } = state;
  return {
    canSuperUserRead: auth.permissions.canSuperUserRead,
  };
};

// eslint-disable-next-line no-unused-vars
const Menu = ({ canSuperUserRead }) => {
  return (
    <ContextBar>
      <ContextBarBtn to="/marketplace/products" label="Find Products">
        <FaSearch style={{ fontSize: '17px' }} />
      </ContextBarBtn>
      <ContextBarBtn to="/marketplace/subscriptions" label="My Subscriptions">
        <MdWidgets style={{ fontSize: '17px', left: '-1px' }} />
      </ContextBarBtn>
      <ContextBarBtn to="/marketplace/billing" label="My Billing">
        <FaRegCreditCard style={{ fontSize: '17px' }} />
      </ContextBarBtn>
      {canSuperUserRead && (
        <>
          <ContextBarSubHeading>Admin</ContextBarSubHeading>
          <ContextBarBtn to="/marketplace/admin/products" label="My Listings">
            <MdSettings style={{ fontSize: '17px', left: '-1px' }} />
          </ContextBarBtn>
        </>
      )}
      <ReactTooltip id="contextbar-tooltip" variant="dark" />
    </ContextBar>
  );
};

Menu.propTypes = {
  canSuperUserRead: PropTypes.bool.isRequired,
};

Menu.defaultProps = {};

export default connect(mapStateToProps, null)(Menu);
