import React from 'react';
import PropTypes from 'prop-types';
import { FaArrowsAltH } from 'react-icons/fa';

import FieldGroup from '../../../../library/molecules/fields/FieldGroup';
import Field from '../../../../library/molecules/fields/Field';
import SignalField from '../../../../library/molecules/fields/SignalField';
import { currency } from '../../../../common/helpers';
import './SpendSignal.scss';

const ToolTips = {
  DocsTip:
    'The more payment documents that you extract through Sypht, the more reliable and accurate the spend signals become',
  AnomalySignal:
    'This signal calculates the spend amount percentile, comparing this payment amount to all payment documents from the same supplier that Sypht has processed to-date, indicating the degree to which this payment amount is an outlier',
  AverageSignal:
    'This is the average spend amount across all of your payment documents from this supplier that Sypht has processed to-date',
  RangeSignal:
    'This is the min and max payment amount from this supplier in all of your payment documents from this supplier that Sypht has processed to-date',
};

function getDocsVariant(extractedDocs) {
  if (extractedDocs < 10) return 'danger'; // poor
  if (extractedDocs < 30) return 'warning'; // average
  if (extractedDocs < 100) return 'success'; // good
  return 'default'; // excellent
}

function getDocsMessage(extractedDocs) {
  if (extractedDocs < 10) return 'Poor';
  if (extractedDocs < 30) return 'Average';
  if (extractedDocs < 100) return 'Good';
  return 'Excellent';
}

const getAnomalyVariant = percentileRank => {
  if (percentileRank <= 30) {
    return 'warning';
  }
  if (percentileRank < 70) {
    return 'success';
  }
  return 'danger';
};

const getAnomalyMessage = percentileRank => {
  if (percentileRank >= 50) {
    const displayPerc = Math.max(100 - percentileRank, 5);
    return `In top ${displayPerc}% of all payment requests from this supplier`;
  }
  const displayPerc = Math.max(percentileRank, 5);
  return `In bottom ${displayPerc}% of all payment requests from this supplier`;
};

const SpendSignal = ({
  extractedDocs,
  anomalyPercentileRank,
  averageSignal,
  rangeLowSignal,
  rangeHightSignal,
}) => {
  return (
    <FieldGroup>
      <SignalField
        name="Spend Signals Quality"
        tooltip={ToolTips.DocsTip}
        signalVariant={getDocsVariant(extractedDocs)}
        signalMessage={getDocsMessage(extractedDocs)}
        additionalText={`Calculated using ${extractedDocs} of your documents from this supplier`}
      />
      <SignalField
        name="Spend Anomaly Signal"
        tooltip={ToolTips.AnomalySignal}
        signalVariant={getAnomalyVariant(anomalyPercentileRank)}
        signalMessage={getAnomalyMessage(anomalyPercentileRank)}
      />
      <Field
        name="Spend Average Signal"
        tooltip={ToolTips.AverageSignal}
        additionalText={currency(averageSignal, { decimalPlaces: 2 })}
      />
      <Field
        name="Spend Range Signal"
        tooltip={ToolTips.RangeSignal}
        additionalText={
          <>
            {currency(rangeLowSignal)}{' '}
            <FaArrowsAltH className="spend-signal__range-icon" />{' '}
            {currency(rangeHightSignal)}
          </>
        }
      />
    </FieldGroup>
  );
};

SpendSignal.propTypes = {
  extractedDocs: PropTypes.number.isRequired,
  anomalyPercentileRank: PropTypes.number.isRequired,
  averageSignal: PropTypes.number.isRequired,
  rangeLowSignal: PropTypes.number.isRequired,
  rangeHightSignal: PropTypes.number.isRequired,
};

export default SpendSignal;
