/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  DateCell,
  CheckboxColumn,
  ShowColumns,
  TagListCell,
  MultiCell,
  StatusCell,
} from '../../library/organisms/Table';

import {
  DocumentNameAndIdCell,
  ProductsCell,
  TasksCell,
} from './components/Cells';

import splitStatesLookup from '../../common/lookups/splitStatesLookup';

const isRowSplitPending = row =>
  row.original.splitState === splitStatesLookup.split_pending;

/**
 * The columns for documents table in react-table format.
 *
 * Columns with `accessor` are sortable, disable with `disableSortBy`.
 */
export const DocumentColumns = [
  CheckboxColumn,
  {
    Header: 'Document Name & ID',
    Cell: DocumentNameAndIdCell,
    id: 'document-name',
  },
  {
    Header: 'Uploaded',
    accessor: 'created',
    Cell: col => <DateCell {...col} tooltipId="documents-tooltip" />,
    disableSortBy: true,
  },
  {
    Header: 'Extracted',
    accessor: 'extractedAt',
    Cell: col => (
      <MultiCell
        {...col}
        tooltipId="documents-tooltip"
        selector={props => {
          if (isRowSplitPending(props.cell.row)) {
            return StatusCell;
          }
          return DateCell;
        }}
      />
    ),
    colspanSelector: row => (isRowSplitPending(row) ? 3 : 1),
    disableSortBy: true,
  },
  {
    Header: 'Fixed',
    accessor: 'validatedAt',
    Cell: col => <DateCell {...col} tooltipId="documents-tooltip" />,
    disableSortBy: true,
    optionalSelector: isRowSplitPending,
  },
  {
    Header: 'Exported',
    accessor: 'exportedAt',
    Cell: col => <DateCell {...col} tooltipId="documents-tooltip" />,
    disableSortBy: true,
    optionalSelector: isRowSplitPending,
  },
  {
    Header: 'AI Products Used',
    Cell: ProductsCell,
    id: 'products',
  },
  {
    Header: 'Tags',
    Cell: col => <TagListCell {...col} tooltipId="documents-tooltip" />,
    id: 'tags',
  },
  {
    id: 'mergeColumn',
    Header: 'Tasks',
    Cell: TasksCell,
  },
  {
    id: 'lastColumn',
    // eslint-disable-next-line react/prop-types
    Header: ({ allColumns }) => (
      <ShowColumns
        allColumns={allColumns}
        toggleColumns={['created', 'extractedAt', 'validatedAt', 'exportedAt']}
      />
    ),
    Cell: <></>,
  },
];

export default DocumentColumns;
