import React from 'react';
import ReactTable from 'react-table-6';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './Table.scss';

const DataTable = ({
  id,
  className,
  columns,
  data,
  sortBy,
  showPageSizeOptions,
  ...props
}) => {
  const tableClass = classNames(className, 'table');
  const defaultSort = sortBy ? [{ id: sortBy }] : [];
  const tableProps = { id };

  return (
    <ReactTable
      className={tableClass}
      data={data}
      columns={columns}
      minRows={0}
      defaultSorted={defaultSort}
      resizable
      showPageSizeOptions={showPageSizeOptions}
      getProps={() => tableProps}
      {...props}
    />
  );
};

DataTable.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  sortBy: PropTypes.string,
  showPageSizeOptions: PropTypes.bool,
};

DataTable.defaultProps = {
  id: undefined,
  className: null,
  sortBy: null,
  showPageSizeOptions: false,
};

export default DataTable;
