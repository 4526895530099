import React from 'react';
import PropTypes from 'prop-types';
import CredentialsList from './CredentialsList';
import CredentialForm from './CredentialForm';
import './Credentials.scss';
import { ModalContextProvider } from '../UI/Modal/context/ModalContext';
import Page, { PageInner, PageBody, PageRow, PageHeader } from '../Page';

const Credentials = ({ admin, companyId }) => {
  return (
    <ModalContextProvider>
      <Page className="credentials">
        <PageInner>
          {!admin && (
            <PageHeader>
              <h1 className="page__heading">My API Credentials</h1>
            </PageHeader>
          )}
          <PageBody>
            {!admin && (
              <PageRow className="admin__section">
                <p>
                  API credentials will enable you to generate a{' '}
                  <strong>bearer token</strong>, which is required to access the
                  Sypht API. Examples can be found in the{' '}
                  <a
                    href="https://docs.sypht.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Developer documentation
                  </a>{' '}
                  for more information.
                </p>
              </PageRow>
            )}
            <PageRow className="admin__section admin__section--fit">
              <CredentialsList companyId={companyId} />
            </PageRow>
          </PageBody>
        </PageInner>
      </Page>
      <CredentialForm companyId={companyId} />
    </ModalContextProvider>
  );
};

Credentials.propTypes = {
  admin: PropTypes.bool.isRequired,
  companyId: PropTypes.string.isRequired,
};

export default Credentials;
