/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Popover } from 'react-tiny-popover';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { debounce } from '../../../common/helpers';

import Tag from '../../atoms/Tag';
import './_tagList.scss';

const TagList = ({ className, tags, handleTagClick, children, variant }) => {
  const tagNodes = children || [];
  const maxRef = useRef(null);
  const dropdownRef = useRef(null);
  const [hiddenTagElements, setHiddenTagElements] = useState([]);
  const [sizeChanged, setSizeChanged] = useState(true);
  const [isMoreTagsOpen, setIsMoreTagsOpen] = useState(false);
  const tagRefs = [];

  const handleToggleClick = e => {
    setIsMoreTagsOpen(!isMoreTagsOpen);
    e.stopPropagation();
    e.preventDefault();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResize = useCallback(
    debounce(() => {
      setSizeChanged(true);
    }, 150),
    [],
  );

  const handleDropdownClick = e => {
    // this is here to handle clicking the dropdown div.
    // which selects a row and re-renders the entire component
    e.preventDefault();
    e.stopPropagation();
  };
  const handleClickOutside = e => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsMoreTagsOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleResize]);

  useEffect(() => {
    if (maxRef.current) {
      const containerWidth = maxRef.current.offsetWidth;

      const newlyHiddenTags = [];
      const buffer = variant === 'default' ? 70 : 200;
      let runningWidth = 0;
      tagRefs.map(({ element, node }) => {
        element.style.display = 'block'; // this is here so that we can calculate if its being clipped or not. (ie get its offsetLeft and offsetWidth)

        if (
          element &&
          runningWidth + element.offsetWidth + buffer > containerWidth
        ) {
          element.style.display = 'none';
          newlyHiddenTags.push({ element, node });
        } else {
          element.style.display = 'block';
          const { marginRight } = window.getComputedStyle(element);
          const [, marginValue] = (marginRight.match(/([0-9]+)px/) &&
            marginRight.match(/([0-9]+)px/)) || [null, 0];
          runningWidth += element.clientWidth + parseInt(marginValue, 10);
        }
      });
      if (newlyHiddenTags.length !== hiddenTagElements.length) {
        setHiddenTagElements(newlyHiddenTags);
      }
      setSizeChanged(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagNodes, sizeChanged, hiddenTagElements.length, variant]);

  const dropdownContent = (
    <div
      onClick={handleDropdownClick}
      ref={dropdownRef}
      className={classNames('tag-list__dropdown')}
    >
      <div className={classNames('tag-list__dropdown__inner')}>
        <div className={classNames('tag-list__dropdown__header')}>
          {tags.length ? tags.length : tagNodes.length} tags
        </div>
        <ul className={classNames('tag-list__dropdown__list', className)}>
          {tags.length > 0 &&
            tags.map(tag => {
              return (
                <li
                  id={`tag-dropdown|${tag.id}`}
                  className="tag-list__item"
                  key={`tagListItem|tag-dropdown|${tag.id}`}
                >
                  <Tag
                    id={`tag-dropdown|${tag.id}`}
                    key={`tag-dropdown|${tag.id}`}
                    onClick={e => handleTagClick(e, tag)}
                    role="button"
                    tabIndex={0}
                    data-tooltip-content={tag.name}
                    data-tooltip-id="taglist-dropdown-tag-tooltips"
                  >
                    {tag.name}
                  </Tag>
                </li>
              );
            })}
          {(tags || []).length === 0 &&
            tagNodes.map(tagNode => {
              return (
                <li
                  id={tagNode.key}
                  className="tag-list__item"
                  ref={el => tagRefs.push({ element: el, node: tagNode })}
                  key={`tagListItem|${tagNode.key}`}
                >
                  {tagNode}
                </li>
              );
            })}
        </ul>
      </div>

      <ReactTooltip
        id="taglist-dropdown-tag-tooltips"
        place="top"
        variant="dark"
      />
    </div>
  );

  return (
    <div className="tag-list">
      <ul
        className={classNames(
          'tag-list__list',
          className,
          `tag-list__list__${variant}`,
        )}
        ref={maxRef}
      >
        {tagNodes.map(tagNode => {
          return (
            <li
              id={tagNode.key}
              className="tag-list__item"
              ref={el => tagRefs.push({ element: el, node: tagNode })}
              key={`tagListItem|${tagNode.key}`}
              style={{ display: 'none' }}
            >
              {tagNode}
            </li>
          );
        })}
        {hiddenTagElements.length > 0 && (
          <li className="tag-list__item">
            <Popover
              isOpen={isMoreTagsOpen}
              positions={['bottom', 'top', 'left', 'right']} // preferred positions by priority
              content={dropdownContent}
              align="end"
              padding={8}
            >
              <span>
                <Tag className="tag--more" onClick={handleToggleClick}>
                  {`+${hiddenTagElements.length}`}
                </Tag>
              </span>
            </Popover>
          </li>
        )}
      </ul>
    </div>
  );
};

TagList.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  handleTagClick: PropTypes.func,
  variant: PropTypes.oneOf(['right-aligned', 'default']),
};

TagList.defaultProps = {
  className: '',
  children: <></>,
  tags: [],
  handleTagClick: () => {},
  variant: 'default',
};

export default TagList;
