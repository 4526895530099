import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FaInfoCircle } from 'react-icons/fa';
import Indicator from '../../atoms/Indicator/Indicator';
import './field.scss';

const TextField = ({ name, tooltip, value, indicator }) => {
  return (
    <div className="field2">
      <div className="field2__section">
        <h3 className="field2__header field2__header--light">{name}</h3>
        {tooltip && (
          <FaInfoCircle
            className="field2__info-icon"
            data-tooltip-id="document-tooltip"
            data-tooltip-place="right"
            data-tooltip-content={tooltip}
          />
        )}
      </div>
      <div
        className={classNames('field2__section', {
          'field2__section--indicator': !!indicator,
        })}
      >
        {indicator && <Indicator variant={indicator} />}
        <div
          className={classNames('field2__text', 'field2__text--dark', {
            'field2__text--indicator': !!indicator,
          })}
        >
          {value}
        </div>
      </div>
    </div>
  );
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  tooltip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  value: PropTypes.string,
  indicator: Indicator.propTypes.variant,
};

TextField.defaultProps = {
  tooltip: undefined,
  value: undefined,
  indicator: null,
};

export default TextField;
