import React from 'react';
import PropTypes from 'prop-types';
import TourContent from '../TourContent';
import Extract from '../../../../../../assets/images/onboarding/introduction-to-sypht/extract.svg';

const ExtractInformation = ({ closeTour }) => (
  <TourContent
    icon={Extract}
    text="Extract unstructured data from selected document(s) using one of Sypht's AI products. Visit Sypht Marketplace to explore our range of pre-trained AI products, and get access to those that best reflect your document types."
    buttonText="Visit Sypht Marketplace"
    buttonUrl="/marketplace/products"
    closeTour={closeTour}
  />
);

ExtractInformation.propTypes = {
  closeTour: PropTypes.func,
};

ExtractInformation.defaultProps = {
  closeTour: () => {},
};

export default ExtractInformation;
