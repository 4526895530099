import { STYLE } from '../components/UI/Notification/Notification';

export const ADD_NOTIFICATION = 'sypht/notifications/ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'sypht/notification/REMOVE_NOTIFICATION';

export default function notification(state = [], action) {
  const { payload, type } = action;
  switch (type) {
    case ADD_NOTIFICATION:
      return { payload };

    case REMOVE_NOTIFICATION:
      return {};

    default:
      return state;
  }
}

export const addNotification =
  (options = {}) =>
  dispatch => {
    dispatch({ type: ADD_NOTIFICATION, payload: options });
    setTimeout(() => {
      dispatch({ type: REMOVE_NOTIFICATION, payload: {} });
    }, options.timeout || 5000);
  };

export const removeNotification = () => ({
  payload: {},
  type: REMOVE_NOTIFICATION,
});

export const errorNotification = message => {
  return addNotification({
    style: STYLE.ERROR,
    message,
    timeout: 8000,
  });
};

export const addToast =
  (options = {}) =>
  dispatch => {
    dispatch({ type: ADD_NOTIFICATION, payload: options });
  };
