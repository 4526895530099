import React from 'react';
import { MdClose } from 'react-icons/md';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './_drawer.scss';

const Drawer = ({ children, className, isOpen, onClose, ...props }) => {
  const handleClose = e => {
    onClose(e);
  };

  return (
    <div
      className={classNames('drawer', className, {
        'drawer--open': isOpen,
      })}
      {...props}
    >
      <button
        type="button"
        className="drawer__close-button"
        onClick={handleClose}
      >
        <MdClose className="drawer__closer-icon" />
      </button>
      <div className="drawer__inner">{children}</div>
    </div>
  );
};

Drawer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

Drawer.defaultProps = {
  children: null,
  isOpen: false,
  className: '',
};

export default Drawer;
