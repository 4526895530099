import React from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Dropdown = ({ id, classNamePrefix, className, label, ...props }) => {
  return (
    <Select
      inputId={id}
      classNamePrefix={classNamePrefix}
      className={classNames(classNamePrefix, className)}
      {...props}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  );
};

Dropdown.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
};

Dropdown.defaultProps = {
  id: null,
  className: null,
  label: null,
};

export default Dropdown;
