import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getStateFilterLabel } from '../../../helpers';
import Dropdown from '../../../../../library/molecules/Dropdown';
import StateFilterMenu from './StateFilterMenu';

const STATE_OPTIONS = [
  {
    label: 'Created',
    value: 'created',
  },
  {
    label: 'In Progress',
    value: 'in_progress',
  },
  {
    label: 'Completed',
    value: 'complete',
  },
];

const mapStateToProps = state => {
  const { auth } = state;
  return {
    userId: auth?.user?.data?.id,
  };
};

const TaskStateFilter = ({ id, value, onChange }) => {
  const [isOpen, setOpen] = useState(false);
  const filterRef = useRef();

  const handleChange = newStates => {
    onChange({
      value: newStates.length ? newStates : undefined,
    });
  };

  const handleClear = e => {
    e.stopPropagation();
    onChange({
      value: undefined,
    });
    filterRef.current.handleClear();
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(!isOpen);
  };

  return (
    <Dropdown
      id={id}
      className={[
        'tasks-filters__select--right',
        'tasks__filters-item',
        'tasks__filters-item--task',
      ]}
      label={getStateFilterLabel('Task state', value, STATE_OPTIONS)}
      type="select"
      hasValue={value && value.length}
      onClear={handleClear}
      onClick={handleClick}
      isOpen={isOpen}
    >
      <StateFilterMenu
        options={STATE_OPTIONS}
        defaultSelected={value}
        forwardRef={filterRef}
        heading="Filter by task state"
        isOpen={isOpen}
        onChange={handleChange}
      />
    </Dropdown>
  );
};

TaskStateFilter.propTypes = {
  id: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,
};

TaskStateFilter.defaultProps = {
  id: undefined,
  value: undefined,
  onChange: () => {},
};

export default connect(mapStateToProps)(TaskStateFilter);
