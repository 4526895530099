import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './Signal.scss';

const variantProps = PropTypes.oneOf([
  'default',
  'success',
  'warning',
  'danger',
]);

/**
 * Use as a direct descendent flex child within Signal.
 */
export const Child = ({ children }) => {
  return <div className="signal__child">{children}</div>;
};

Child.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * The main content of the signal.
 */
export const Message = ({ children }) => {
  return <div className="signal__child signal__message">{children}</div>;
};

Message.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * Either pass a message prop OR pass children in and use signal.Message and similar for more complex content.
 */
export const Signal = ({ variant, message, children }) => {
  return (
    <div className={classNames('signal', `signal--${variant}`)}>
      {message && <Message>{message}</Message>}
      {children}
    </div>
  );
};

Signal.propTypes = {
  message: PropTypes.string,
  variant: variantProps.isRequired,
  children: PropTypes.node,
};

Signal.defaultProps = {
  message: undefined,
  children: undefined,
};

export default Signal;
