import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CancelToken } from 'axios';
import moment from 'moment';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import Page, { PageBody, PageHeader, PageInner } from '../../components/Page';
import { DynamicTable } from '../../library/organisms/Table';
import { DocsSearch, FilterGroup } from './components/Filters';
import { DefaultBtnGroup } from './components/ButtonGroups';
import Modals from './components/Modals/Modals';
import { DocsOptions } from './components/BatchSelect';

import { getQueryStr, setQueryParams, debounce } from '../../common/helpers';

import {
  fetchDocs,
  getDocsPredictions,
  fetchCompanies,
} from '../../reducers/docs';
import { fetchDocMeta } from '../../reducers/docs/actions';
import { fetchProducts } from '../../reducers/products/actions';
import { fetchSpecs } from '../../reducers/specs';
import { fetchSubscriptions } from '../../reducers/subscriptions/actions';
import {
  batchUpdateDocumentTags,
  createCompanyTag,
  fetchApplyTags,
} from '../../reducers/tags/action';

import history from '../../services/historyService';
import { DocumentColumns } from './columns';

import './_documents.scss';
import { useDocumentFilters } from './hooks/useDocumentFilters';
import mustBeAnArrayIfPresent from '../../common/helpers/mustBeAnArrayIfPresent';
import { getSelectedTags } from '../../components/UI/TagDropdown/TagDropdown';

const PAGE_SIZE = 10;
const MAX_DOCS = 1000;
const DEBOUNCE_INTERVAL = 400;
export const DEFAULT_SPECS_FILTER = {
  limit: 0,
  offset: 0,
  companyId: '',
  isPublic: false,
};
const initBatchSelectState = {
  isSelectAll: false,
  isSelectAllResults: false,
  isCleared: false,
};
const initialApplyTagParams = {
  limit:100,
  includeCounts:true,
  sortDirection:'asc',
  sortColumn:'name'
}

const getCurrentPage = (docsData, search) => {
  if (docsData) return docsData.currentPage;
  const page = getQueryStr(search, 'page');
  if (!page) return 0;

  return Number(page) > 0 ? Number(page) - 1 : 0;
};

const mapStateToProps = state => {
  const { auth, docs, subscriptions, specs, tags, company } = state;
  const { data: documentsData, loading, docsRequested } = docs;

  return {
    companyId: auth.user.data ? auth.user.data.companyId : null,
    userId: auth.user.data ? auth.user.data.id : null,
    documents: documentsData?.docs,
    pageIndex: getCurrentPage(documentsData, history.location.search),
    pageCount: documentsData?.numPages,
    numDocs: documentsData?.count || 0,
    lastRequest: docs.lastRequest,
    loading,
    isExporting: docs.exportLoading,
    isBatchExporting: docs.batchExportLoading,
    companies: docs.companies.data,
    scopes: auth?.claims.allowedScopes,
    specs: specs?.data?.specifications,
    applyTags: tags.applyTags.data,
    products: mustBeAnArrayIfPresent(subscriptions.data),
    docsRequested,
    hasValidateProduct: subscriptions.hasValidateProduct,
    canShowCompanyFilter: auth.permissions.canShowCompanyFilter,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch: {
    fetchDocs: (filters, cancelToken) =>
      dispatch(fetchDocs(filters, cancelToken)),
    fetchProducts: () => dispatch(fetchProducts()),
    fetchSpecs: filters => dispatch(fetchSpecs(filters)),
    fetchCompanies: () => dispatch(fetchCompanies()),
    getDocsPredictions: docs => dispatch(getDocsPredictions(docs)),
    fetchApplyTags: (companyId, params) => dispatch(fetchApplyTags(companyId, params)),
    createCompanyTag: data => dispatch(createCompanyTag(data)),
    batchUpdateDocumentTags: data => dispatch(batchUpdateDocumentTags(data)),
    fetchSubscriptions: () => dispatch(fetchSubscriptions()),
    fetchDocMeta: docId => dispatch(fetchDocMeta(docId)),
  },
});

const Documents = ({
  companyId,
  userId,
  documents,
  pageCount,
  loading,
  dispatch,
  companies,
  isExporting,
  applyTags,
  products,
  specs,
  numDocs,
  hasValidateProduct,
  canShowCompanyFilter,
}) => {
  const [filters] = useDocumentFilters({});
  const [selectedDocuments, setSelectedDocuments] = useState({});
  const [batchSelect, setBatchSelect] = useState(initBatchSelectState);
  const [extractedDocs, setExtractedDocs] = useState([]);
  const [activeModal, setActiveModal] = useState(null);
  const [selectedTags, setSelectedTags] = useState(null);
  const [fieldsetOptions, setFieldsetOptions] = useState([]);
  //const [applyTagParams, setApplyTagParams] = useState(initialApplyTagParams);
  const [tagSearch, setTagSearch] = useState(null);
  
  const tableRef = useRef();
  const queryRef = useRef();
  const tagRef = useRef();
  const filenameRef = useRef();

  const defaultCancelToken = useRef(CancelToken.source());

  const pageIndex = tableRef.current?.page ? tableRef.current?.page : 0;
  const noOfSelectedDocs = Object.keys(selectedDocuments).length;

  useEffect(() => {
    defaultCancelToken.current = CancelToken.source();
    return () => {
      if (defaultCancelToken.current)
        defaultCancelToken.current.cancel('Cancelled request');
    };
  }, []);

  // Fetch data on first load
  useEffect(() => {
    if (userId) {
      dispatch.fetchSpecs(DEFAULT_SPECS_FILTER);
      dispatch.fetchSubscriptions();
      dispatch.fetchProducts();
      if (canShowCompanyFilter) {
        dispatch.fetchCompanies();
      }
    }
  }, [canShowCompanyFilter, dispatch, userId]);

  const selections = useMemo(
    () => selectedTags && Object.keys(selectedTags).length > 0 ?
      Object.keys(selectedTags).sort().join(',') :
      null,
    [selectedTags],
  );
  const applyTagParams = useMemo(
    () => {
      let tagName = null;
      if(tagSearch && tagSearch !== ''){
        tagName = tagSearch;
      }
      return {...initialApplyTagParams, selections, name: tagName}
    },
    [selections, tagSearch],
  );

  useEffect(() => {
    if(companyId){
      dispatch.fetchApplyTags(companyId, applyTagParams);
    }
  }, [companyId, applyTagParams, dispatch]);


  useEffect(() => {
    tableRef.current.toggleHideColumn('mergeColumn', !hasValidateProduct);
  }, [hasValidateProduct]);

  // set fieldsetOptions when products data is ready
  useEffect(() => {
    // wrapping in useEffect prevents the filter from being re-run
    if ((products || []).length > 0 && fieldsetOptions.length === 0) {
      const options = (products || [])
        .filter(p => !p.endDate || moment(p.endDate) > moment())
        .map(p => ({
          label: p.name,
          value: p.productId,
          fieldsets: p.fieldsets,
          tags: p.tags,
        }));
      setFieldsetOptions(options);
    }
  }, [fieldsetOptions.length, products]);

  useEffect(() => {
    const selTags = getSelectedTags(selectedDocuments);
    setSelectedTags(selTags);
  }, [selectedDocuments]);

  const handleFetchDocumentsData = useCallback(
    // eslint-disable-next-line no-unused-vars
    ({ index, limit, sortBy, filters: newFilters, isReady }) => {
      defaultCancelToken.current.cancel('Cancelled request');
      defaultCancelToken.current = CancelToken.source();
      setQueryParams({
        ...newFilters,
        page: index + 1,
      });

      dispatch.fetchDocs(
        {
          ...newFilters,
          offset: index,
          limit,
        },
        defaultCancelToken.current,
      );
    },
    [dispatch],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleQueryChange = useCallback(
    debounce((filter, newFilters) => {
      const { value } = filter;
      const filterQuery = {
        ...newFilters,
        query: value,
        page: 1,
        filename: '',
        tags: '',
      };

      if (filenameRef.current) filenameRef.current.value = '';
      if (tagRef.current) tagRef.current.value = '';

      tableRef.current.setFilters(filterQuery);
      tableRef.current.gotoPage(0);
    }, DEBOUNCE_INTERVAL),
    [],
  );

  const handleQueryClear = () => {
    tableRef.current.setFilters({
      query: '',
      tags: '',
    });
    tableRef.current.gotoPage(0);
    queryRef.current.value = '';
    queryRef.current.focus();
  };

  const handleCompanyChange = filter => {
    tableRef.current.setFilters({
      ...tableRef.current.filters,
      companyId: filter.companyId ? filter.companyId : '',
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFilterChange = useCallback(
    debounce((filter, newFilters) => {
      const filterQuery = {
        ...newFilters,
        query: '',
        page: 1,
      };

      const { name, value } = filter;
      filterQuery[name] = value;
      queryRef.current.value = '';
      tableRef.current.setFilters(filterQuery);
      tableRef.current.gotoPage(0);
    }, DEBOUNCE_INTERVAL),
    [],
  );

  const applyStateFilters = stateFilters => {
    tableRef.current.setFilters(f => ({
      ...f,
      isExtracted: stateFilters.extracted
        ? stateFilters.extracted.value.toString()
        : '',
      isValidated: stateFilters.validated
        ? stateFilters.validated.value.toString()
        : '',
      isExported: stateFilters.exported
        ? stateFilters.exported.value.toString()
        : '',
    }));
  };

  // rows: Array<{ id, original, rowIndex }>
  const handleRowSelect = rows => {
    const newSelectedDocuments = Object.fromEntries(
      rows.map(r => [r.id, { ...r.original, id: r.id }]),
    );

    const docsRemaining = numDocs - tableRef.current.page * PAGE_SIZE;
    const isSelectAll =
      rows.length > 0 && rows.length === Math.min(docsRemaining, PAGE_SIZE);
    setSelectedDocuments(newSelectedDocuments);
    setBatchSelect({
      isSelectAll,
      isSelectAllResults: false,
      isCleared: false,
    });
  };

  const showModal = modalName => {
    setActiveModal(modalName);
  };

  const showExtractModal = () => {
    dispatch.getDocsPredictions(Object.keys(selectedDocuments)).then(res => {
      setActiveModal('isExtractModalOpen');
      setExtractedDocs(res.data);
    });
  };

  const cancelModal = () => {
    setActiveModal(null);
  };

  const closeModal = (_modalType, refresh = false) => {
    if (refresh) {
      dispatch.fetchDocs(tableRef.current.filters); // refresh data on modal close
    }
    tableRef.current.toggleAllRowsSelected(false);
    setActiveModal(null);
  };

  const onCreateTag = tagName => {
    dispatch.createCompanyTag({ name: tagName, companyId }).then(() => {
      dispatch.fetchApplyTags(companyId, applyTagParams);
    });
  };

  const handleSelectAllResultsClick = e => {
    setBatchSelect({
      ...batchSelect,
      isSelectAllResults: !batchSelect.isSelectAllResults,
      isCleared: false,
    });

    e.target.blur();
  };

  const resetSelectedDocs = () => {
    tableRef.current.toggleAllRowsSelected(false);
    tableRef.current.gotoPage(0);
    setBatchSelect(initBatchSelectState);
  };

  /**
   * TAG DROPDOWN METHODS
   */
  const onTagDropdownConfirm = (addTags, removeTags) => {
    // **** KEEP FOR DEBUGGING PURPOSES *****
    // console.info('selectedTags', selectedTags);
    // console.info('removeTags', removeTags);
    // v.info('tags', tags);
    // console.info('removePartialTags', removePartialTags);

    const docIds = Object.keys(selectedDocuments);

    dispatch
      .batchUpdateDocumentTags({
        docIds,
        addTags,
        removeTags,
        companyId: companyId,
      })
      .then(() => {
       
        dispatch.fetchDocs(tableRef.current.filters);
        dispatch.fetchApplyTags(companyId, applyTagParams);
        resetSelectedDocs();
      });
  };
  const onTagDropdownSearch = (tagName) => {
    setTagSearch(tagName);
  }

  const handleRowClick = (e, document) => {
    // const targetPath = getTargetPath(task, userId);
    const targetPath = `/documents/${document.id}`;
    if (e.metaKey || e.ctrlKey) {
      window.open(targetPath, '_blank');
    } else {
      history.push(targetPath);
    }
  };

  const handleTagClick = (e, tag) => {
    // const targetPath = getTargetPath(task, userId);
    e.stopPropagation();
    const filterQuery = {
      query: '',
      tags: tag.name,
      filename: '',
      page: 1,
    };
    queryRef.current.value = '';
    tableRef.current.setFilters(filterQuery);
    tableRef.current.gotoPage(0);
  };

  return (
    <Page className="documents" layout="full-width">
      <PageInner>
        <PageHeader>
          <h1>Documents</h1>
          <DefaultBtnGroup
            noOfSelectedDocs={noOfSelectedDocs}
            batchSelect={batchSelect}
            showModal={showModal}
            showExtractModal={showExtractModal}
            isExporting={isExporting}
            selectedDocs={selectedDocuments}
            cancelModal={cancelModal}
            closeModal={closeModal}
            applyTags={applyTags}
            onTagDropdownConfirm={onTagDropdownConfirm}
            onTagDropdownSearch={onTagDropdownSearch}
            onCreateTag={onCreateTag}
            selectedTags={selectedTags}
            hasValidateProduct={hasValidateProduct}
          />
        </PageHeader>
        <PageBody>
          <div className="default-filter-group__wrapper" data-tut="reactour_5">
            <div className="search">
              <div className="search__query-container">
                <DocsSearch
                  filters={tableRef.current?.filters || filters}
                  forwardRef={queryRef}
                  onChange={handleQueryChange}
                  onClear={handleQueryClear}
                />
              </div>
              <FilterGroup
                filters={tableRef.current?.filters || filters}
                queryRef={queryRef}
                tagRef={tagRef}
                filenameRef={filenameRef}
                handleFilterChange={handleFilterChange}
                isShowCompanyFilter={canShowCompanyFilter}
                companies={companies || []}
                handleCompanyChange={handleCompanyChange}
                applyStateFilters={applyStateFilters}
                
              />
            </div>
          </div>
          <DocsOptions
            filters={tableRef.current?.filters || filters}
            batchSelect={batchSelect}
            selectedDocuments={selectedDocuments}
            numDocs={numDocs}
            maxDocs={MAX_DOCS}
            isHidden={false}
            onChange={handleSelectAllResultsClick}
            onClear={resetSelectedDocs}
          />
          <div className="docs-table-wrapper">
            <DynamicTable
              className="documents-table"
              columns={DocumentColumns}
              data={documents}
              initialFilters={filters}
              pageIndex={pageIndex}
              pageSize={PAGE_SIZE}
              pageCount={pageCount}
              isLoading={loading}
              selectedRows={Object.keys(selectedDocuments)}
              onFetchData={handleFetchDocumentsData}
              forwardRef={tableRef}
              handlers={{
                onRowClick: handleRowClick,
                onRowSelect: handleRowSelect,
                onTagClick: handleTagClick,
              }}
            />
          </div>
        </PageBody>
        <Modals
          activeModal={activeModal}
          activeTab="uploaded"
          cancelModal={cancelModal}
          closeModal={closeModal}
          extractedDocs={extractedDocs}
          fieldsetOptions={fieldsetOptions}
          specs={specs}
          selectedDocs={selectedDocuments}
          filters={tableRef.current?.filters ?? filters}
          batchSelect={batchSelect}
          hasValidateProduct={hasValidateProduct}
        />
      </PageInner>
      <ReactTooltip
        id="documents-tooltip"
        className="tooltip"
        place="bottom"
        variant="dark"
      />
    </Page>
  );
};

Documents.propTypes = {
  companyId: PropTypes.string,
  userId: PropTypes.string,
  documents: PropTypes.arrayOf(PropTypes.shape({})),
  // pageIndex,
  pageCount: PropTypes.number,
  loading: PropTypes.bool,
  dispatch: PropTypes.shape({
    fetchDocs: PropTypes.func,
    fetchProducts: PropTypes.func,
    fetchSpecs: PropTypes.func,
    fetchCompanies: PropTypes.func,
    getDocsPredictions: PropTypes.func,
    fetchCompanyTags: PropTypes.func,
    createCompanyTag: PropTypes.func,
    batchUpdateDocumentTags: PropTypes.func,
    fetchSubscriptions: PropTypes.func,
    fetchDocMeta: PropTypes.func,
  }),
  companies: PropTypes.arrayOf(PropTypes.shape({})),
  isExporting: PropTypes.bool,
  companyTags: PropTypes.arrayOf(PropTypes.shape({})),
  products: PropTypes.arrayOf(PropTypes.shape({})),
  specs: PropTypes.arrayOf(PropTypes.shape({})),
  numDocs: PropTypes.number,
  hasValidateProduct: PropTypes.bool,
  canShowCompanyFilter: PropTypes.bool.isRequired,
};

Documents.defaultProps = {
  companyId: null,
  userId: '',
  documents: [],
  pageCount: 0,
  loading: false,
  dispatch: {
    fetchDocs: () => {},
    fetchProducts: () => {},
    fetchSpecs: () => {},
    fetchCompanies: () => {},
    getDocsPredictions: () => {},
    fetchCompanyTags: () => {},
    createCompanyTag: () => {},
    batchUpdateDocumentTags: () => {},
    fetchSubscriptions: () => {},
    fetchDocMeta: () => {},
  },
  companies: null,
  isExporting: false,
  companyTags: null,
  products: null,
  specs: null,
  numDocs: 0,
  hasValidateProduct: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
