/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTable, usePagination, useSortBy, useRowSelect } from 'react-table';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Skeleton, {
  SkeletonSpinner,
  SkeletonTable,
} from '../../../components/Skeleton';

import { Table, Tbody, Td, Th, Thead, Tr } from './components/Table';
import Pagination from './components/Pagination/Pagination';
import {
  isOverrideRowClick,
  isSkipCell,
  setClassName,
  setColSpan,
  setSortIcon,
} from './helpers';
import './_table.scss';

const DynamicTable = ({
  className,
  columns: initialColumns,
  data: initialData,
  initialFilters,
  initialSortBy,
  pageIndex,
  pageSize,
  pageCount,
  forwardRef,
  isLoading,
  onFetchData,
  handlers,
  noResults,
  disableMultiSort,
  disableSortRemove,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => initialColumns, []);
  const data = useMemo(() => initialData || [], [initialData]);
  const [filters, setFilters] = useState(initialFilters);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const tableRef = forwardRef || useRef();

  const tableProps = useTable(
    {
      columns,
      data: data || [],
      initialState: {
        pageIndex,
        pageSize,
        sortBy: initialSortBy || [],
      },
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort,
      disableSortRemove,
      autoResetPage: false,
      autoResetSortBy: false,
      // custom props
      pageCount,
      filters,
      handlers,
    },
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const {
    getTableProps,
    getTableBodyProps,
    gotoPage,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
    toggleAllRowsSelected,
    toggleHideColumn,
    state: {
      pageIndex: nextPageIndex,
      pageSize: nextPageSize,
      sortBy: nextSortBy,
    },
  } = tableProps;

  useEffect(() => {
    onFetchData({
      index: nextPageIndex,
      limit: nextPageSize,
      sortBy: nextSortBy,
      filters,
      // isReady: !!data, // if data exists, isReady = true
    });
  }, [onFetchData, filters, nextPageIndex, nextPageSize, nextSortBy]);

  useEffect(() => {
    if (!data) return;
    const selectedRows = selectedFlatRows.map(r => ({
      id: r.original.id,
      original: r.original,
      rowIndex: r.index,
    }));
    if (!handlers.onRowSelect) return;
    handlers.onRowSelect(selectedRows);

    // handlers object seems to be re-initialised on each render causing infinite looping,
    // To fix: we should flatten this object into sperate props
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedFlatRows]);

  // useEffect(() => {
  //   // on filter change, reset to first page
  //   if (data) gotoPage(0);
  // }, [filters]);

  useImperativeHandle(tableRef, () => ({
    gotoPage,
    toggleAllRowsSelected,
    filters,
    setFilters,
    page: nextPageIndex,
    limit: nextPageSize,
    toggleHideColumn,
    sortBy: nextSortBy,
  }));

  const getRowClickHandler = row => {
    if (!handlers.onRowClick) return {};
    return {
      onClick: e => {
        if (!isOverrideRowClick(e.target)) {
          handlers.onRowClick(e, row.original);
        }
      },
    };
  };

  const isRowSelected = id => {
    return !!selectedFlatRows.find(r => r.id === id);
  };

  return (
    <div className={classNames(className, 'table-container')}>
      {data ? (
        <>
          <div className={`${className}__scroll`}>
            <Table className="table--th-borders" {...getTableProps()}>
              <Thead>
                {headerGroups.map(headerGroup => (
                  <Tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(col => {
                      return (
                        <Th
                          className={classNames(
                            setClassName('table__th', col.id),
                            {
                              'table__th--sortable': false, // col.canSort,
                            },
                          )}
                          {...col.getHeaderProps()}
                        >
                          <div
                            className={classNames({
                              table__label: col.id !== 'checkbox',
                              table__checkbox: col.id === 'checkbox',
                            })}
                            {...col.getSortByToggleProps()}
                          >
                            {col.render('Header')}
                            {col.id !== 'checkbox' && setSortIcon(col)}
                          </div>
                        </Th>
                      );
                    })}
                  </Tr>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {page.map(row => {
                  prepareRow(row);
                  return (
                    <Tr
                      className={classNames({
                        'table__tr--clickable': !!handlers.onRowClick,
                        'table__tr--selected': isRowSelected(row.id),
                      })}
                      {...row.getRowProps()}
                      {...getRowClickHandler(row)}
                    >
                      {row.cells.map((cell, i) => {
                        if (isSkipCell(row, i)) return null;
                        return (
                          <Td
                            className={setClassName(
                              'table__td',
                              cell.column.id,
                            )}
                            colSpan={setColSpan(row, i)}
                            {...cell.getCellProps()}
                          >
                            <div className="table__td-inner">
                              {cell.render('Cell', { editable: true })}
                            </div>
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </div>
          {page.length === 0 && !isLoading && (
            <div className="table__no-results">{noResults}</div>
          )}
          <Pagination tableProps={tableProps} />
        </>
      ) : (
        <SkeletonTable noOfRows={pageSize} />
      )}
      {isLoading && (
        <Skeleton>
          <SkeletonSpinner />
        </Skeleton>
      )}
    </div>
  );
};

DynamicTable.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  filters: PropTypes.shape({}),
  /**
   * Set the initial sort state for the table (managed by useSortBy plugin, part
   * of react-table).
   *
   * NOTE: useSortBy can support multisort and uses an array format.  If you
   * disable multisort, you should still use the array format, but the only
   * value of interest will be the first value in the array.
   */
  initialSortBy: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, desc: PropTypes.bool }),
  ),
  pageIndex: PropTypes.number,
  pageCount: PropTypes.number,
  pageSize: PropTypes.number,
  forwardRef: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  onFetchData: PropTypes.func,
  handlers: PropTypes.shape({}),
  noResults: PropTypes.string,
  disableMultiSort: PropTypes.bool,
  disableSortRemove: PropTypes.bool,
};

DynamicTable.defaultProps = {
  className: null,
  columns: null,
  data: null,
  filters: {},
  initialSortBy: undefined,
  pageIndex: 0,
  pageCount: 1,
  pageSize: 10,
  forwardRef: undefined,
  isLoading: false,
  onFetchData: () => {},
  handlers: {
    onRowClick: undefined,
    onRowSelect: () => {},
  },
  noResults: 'There are no results that match your search criteria',
  disableMultiSort: true,
  disableSortRemove: false,
};

export default DynamicTable;
