import React from 'react';
import { FaCopy, FaTasks, FaUser, FaUserLock } from 'react-icons/fa';
import {
  DeleteCell,
  InputCell,
  NumberCell,
  TextAreaCell,
  CheckboxColumn,
  ShowColumns,
  ButtonCell,
} from '../../../library/organisms/Table';

export default [
  CheckboxColumn,
  {
    id: 'isAccessTag',
    Header: () => (
      <FaUserLock className="fa-user-lock" alt="Has access control" />
    ),
    Cell: col => {
      return (
        <ButtonCell {...col}>
          <FaUserLock />
        </ButtonCell>
      );
    },
    disableSortBy: true,
    width: 48,
  },
  {
    Header: 'Name',
    accessor: 'name',
    Cell: InputCell,
  },
  {
    Header: () => <FaCopy alt="Documents" />,
    accessor: 'numberOfDocuments',
    Cell: NumberCell,
    disableSortBy: true,
  },
  {
    Header: () => <FaTasks alt="Tasks" />,
    accessor: 'numberOfTasks',
    Cell: NumberCell,
    disableSortBy: true,
  },
  {
    Header: () => <FaUser alt="Users" />,
    accessor: 'numberOfUsers',
    Cell: NumberCell,
    disableSortBy: true,
  },
  {
    Header: 'Description',
    accessor: 'description',
    Cell: TextAreaCell,
    disableSortBy: true,
  },
  {
    id: 'lastColumn',
    Header: col => (
      <ShowColumns
        allColumns={col.allColumns}
        toggleColumns={['name', { isAccessTag: 'Access tag' }]}
      />
    ),
    Cell: DeleteCell,
  },
];
