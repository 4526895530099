import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router';
import { FaPencilRuler, FaHammer, FaRocket } from 'react-icons/fa';

import ContextBar from '../UI/ContextBar/ContextBar';
import ContextBarBtn from '../UI/ContextBar/ContextBarBtn';

import Page, { PageInner } from '../Page';
import Specs from './Specs/Specs';
import Spec from './Specs/Spec';
import './Workbench.scss';

const mapStateToProps = state => {
  const { router } = state;

  return {
    location: router.location,
  };
};

// eslint-disable-next-line no-unused-vars
const mapDispatchToProps = dispatch => {
  return {
    dispatch: {},
  };
};

const Workbench = ({ location }) => {
  const root = () => (
    <div className="page__body">
      <div className="page__row">
        <div className="page__header">
          <h1 className="page__heading">Workbench</h1>
        </div>
      </div>
      <div className="page__row how-to-container">
        <a className="step" href="/workbench/specs">
          <FaPencilRuler />
          <span>Specify</span>
        </a>
        <div className="step">
          <FaHammer />
          <span>Train</span>
        </div>
        <div className="step">
          <FaRocket />
          <span>Deploy</span>
        </div>
      </div>
    </div>
  );
  return (
    <Page className="workbench" title="Workbench">
      <ContextBar className="workbench__context-bar">
        <ContextBarBtn to="/workbench/specs">My Specs</ContextBarBtn>
      </ContextBar>
      <PageInner className="page__inner">
        <Switch location={location}>
          <Route
            exact
            path="/workbench/specs"
            component={Specs}
            defaultTo="/workbench"
          />
          <Route
            exact
            path="/workbench/specs/:id"
            component={Spec}
            defaultTo="/workbench"
          />
          <Route exact path="/workbench" component={root} />
        </Switch>
      </PageInner>
    </Page>
  );
};

Workbench.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

Workbench.defaultProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Workbench);
