import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../UI/Modal/Modal';
import './ConfirmModal.scss';

const ConfirmModal = ({
  filename,
  isOpen,
  onConfirm,
  onCancel,
  isDiscard,
  numDocs,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onCancel={onCancel}
      onConfirm={onConfirm}
      className="modal-split"
      confirmLabel="Continue"
      cancelLabel="Cancel"
      modalLabel={
        isDiscard
          ? `Discard splits and upload ${filename}`
          : `Split ${filename}`
      }
    >
      <div className="modal-split-content">
        {isDiscard
          ? 'Sypht will not separate this document and will upload it unchanged. Would you like to continue?'
          : `Sypht will separate this multi-document file into ${numDocs} individual documents of different lengths and formats. Would you like to continue?`}
      </div>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  filename: PropTypes.string.isRequired,
  isDiscard: PropTypes.bool.isRequired,
  numDocs: PropTypes.number.isRequired,
};

export default ConfirmModal;
