import ProductService from '../../services/ProductService';

export const REQUEST_PRODUCTS = 'sypht/products/REQUEST_PRODUCTS';
export const RECEIVE_PRODUCTS = 'sypht/products/RECEIVE_PRODUCTS';
export const REFRESH_PRODUCTS = 'sypht/products/REFRESH_PRODUCTS';

const requestProducts = () => {
  return {
    type: REQUEST_PRODUCTS,
    payload: {},
  };
};

const receiveProducts = (data, filters) => {
  return {
    type: RECEIVE_PRODUCTS,
    payload: {
      data,
      filters,
      loading: true,
      receivedAt: Date.now(),
    },
  };
};

const shouldFetchProducts = products => {
  if (!products.data) {
    return true;
  }
  if (products.loading) {
    return false;
  }
  return products.refresh;
};

export const fetchProducts = (filters = null, isPublic = false) => {
  return async (dispatch, getState) => {
    const { auth, products: retrievedProducts } = getState();

    if (filters || shouldFetchProducts(retrievedProducts)) {
      dispatch(requestProducts());
      const { accessToken } = auth;
      const response = await ProductService.getProducts(
        accessToken,
        {
          ...filters,
        },
        isPublic,
      );
      dispatch(receiveProducts(response.data, filters));
    }
  };
};

export const refreshProducts = () => {
  return {
    type: REFRESH_PRODUCTS,
    payload: {},
  };
};
