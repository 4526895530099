import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Form, { TextInput, validatorType, useForm } from '../Form';
import Modal from '../UI/Modal/Modal';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import '../Admin/AdminForm.scss';
import EditUserRoles from './EditUserRoles';
import useUserRoles from './useUserRoles';

const mapStateToProps = state => {
  return {
    companyRoles: state.company.roles,
    subscriptions: state.subscriptions,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      dispatch,
    },
  };
};

const initialState = {
  firstName: {
    required: true,
  },
  lastName: {
    required: true,
  },
  email: {
    required: true,
    validators: [validatorType.Email],
  },
};

const AddUserForm = ({
  isSending,
  isSent,
  onSubmit,
  onSent,
  onClose,
  isOpen,
  companyRoles,
  subscriptions,
  dispatch,
  companyId,
}) => {
  const [form] = useForm(initialState);
  const { fields, errors } = form;
  const { userRoles, roleData, seatData, handleRoleChange } = useUserRoles({
    dispatch: dispatch.dispatch,
    companyRoles,
    subscriptions,
    companyId,
  });

  // run sent tasks after form has been sent
  useEffect(() => {
    if (isSent && !isSending) {
      onSent();
    }
  }, [isSending, isSent, onSent]);

  const handleSubmit = e => {
    e.preventDefault();
    const { firstName, lastName, email } = fields;

    onSubmit(e, {
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      roles: userRoles,
    });
  };

  return (
    <Modal
      className="user-modal"
      onClose={onClose}
      onCancel={onClose}
      confirmLabel="Submit"
      onConfirm={handleSubmit}
      modalLabel="Add user"
      disabledConfirm={!form.isValid}
      isOpen={isOpen}
    >
      <Form className="admin-form" onSubmit={handleSubmit}>
        <TextInput
          label="First name"
          name="firstName"
          placeholder="First name"
          value={fields.firstName.value}
          errors={errors}
          onBlur={form.handleBlur}
          onChange={form.handleChange}
        />
        <TextInput
          label="Last name"
          name="lastName"
          placeholder="Last name"
          value={fields.lastName.value}
          errors={errors}
          onBlur={form.handleBlur}
          onChange={form.handleChange}
        />
        <TextInput
          label="Email address"
          name="email"
          placeholder="Email"
          value={fields.email.value}
          errors={errors}
          onBlur={form.handleBlur}
          onChange={form.handleChange}
        />
        <div className="form__group form__group-roles">
          <div className="form__group form__group-label">User Roles</div>
          <div className="form__group-roles-inner">
            <EditUserRoles
              roleData={roleData}
              seatData={seatData}
              onChange={handleRoleChange}
            />
          </div>
        </div>
        {isSending ? <LoadingSpinner className="admin-form__spinner" /> : null}
      </Form>
    </Modal>
  );
};

AddUserForm.propTypes = {
  companyId: PropTypes.string.isRequired,
  isSending: PropTypes.bool.isRequired,
  isSent: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSent: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  companyRoles: PropTypes.shape({
    error: PropTypes.string,
    data: PropTypes.objectOf(
      PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }),
      ),
    ),
  }).isRequired,
  subscriptions: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  dispatch: PropTypes.shape({
    dispatch: PropTypes.func,
  }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserForm);
