import { v4 as uuid } from 'uuid';

export const getColumnTokens = column => {
  return column.components
    ? [column.components.label, ...column.components.value.items]
    : [
        column.fields.find(f => f.id === 'label').data.value,
        ...(column.fields.find(f => f.id === 'value').value || []).map(
          v => v.data.value,
        ),
      ].flat();
};

export const getTableColumns = table => {
  return table.components
    ? table.components.column.items
    : table.fields.find(f => f.id === 'column').value;
};

export const getRowsFromColumn = column => {
  if (column.components) {
    return [column.components.label, ...column.components.value.items];
  }
  return [
    column.fields.find(f => f.id === 'label').data.value,
    ...(column.fields.find(f => f.id === 'value').value || []).map(
      v => v.data.value,
    ),
  ].map(row => {
    if (!row) return null;
    let tl = { x: 1.0, y: 1.0 };
    let br = { x: 0.0, y: 0.0 };
    for (const token of row) {
      if (token && token.bounds) {
        tl = {
          x: Math.min(token.bounds.topLeft.x, tl.x),
          y: Math.min(token.bounds.topLeft.y, tl.y),
        };
        br = {
          x: Math.max(token.bounds.bottomRight.x, br.x),
          y: Math.max(token.bounds.bottomRight.y, br.y),
        };
      }
    }
    return { bounds: { topLeft: tl, bottomRight: br } };
  });
};

export const getColumns = (tableBounds, table) => {
  const columns = [];
  const tableColumns = getTableColumns(table);
  tableColumns.forEach((column, i) => {
    if (tableColumns[i + 1]) {
      const maxXA = getColumnTokens(column)
        .filter(e => e !== null && e.bounds !== null)
        .reduce((a, b) => Math.max(a, b.bounds.bottomRight.x), 0);

      const minXB = getColumnTokens(tableColumns[i + 1])
        .filter(e => e !== null && e.bounds !== null)
        .reduce((a, b) => Math.min(a, b.bounds.topLeft.x), 1.0);
      const colX = (maxXA + minXB) / 2;
      columns.push({
        tl: {
          x: colX,
          y: tableBounds.tl.y,
        },
        br: {
          x: colX,
          y: tableBounds.br.y,
        },
      });
    }
  });
  return columns;
};

export const getRows = (tableBounds, table) => {
  let rowBounds;
  const rows = [];
  const columns = table.components
    ? table.components.column.items
    : table.fields.find(f => f.id === 'column').value;

  columns.forEach((column, i) => {
    // eslint-disable-next-line no-shadow
    const rows = getRowsFromColumn(column);
    if (columns[i - 1]) {
      rows.forEach((row, j) => {
        rowBounds[j] = {
          top: Math.min(
            row !== null && row.bounds !== null ? row.bounds.topLeft.y : 1.0,
            rowBounds[j].top,
          ),
          bottom: Math.max(
            row !== null && row.bounds !== null
              ? row.bounds.bottomRight.y
              : 0.0,
            rowBounds[j].bottom,
          ),
        };
      });
    } else {
      rowBounds = rows.map(e =>
        e === null || e.bounds === null
          ? {
              top: 1.0,
              bottom: 0.0,
            }
          : {
              top: e.bounds.topLeft.y,
              bottom: e.bounds.bottomRight.y,
            },
      );
    }
  });

  rowBounds.forEach((row, i) => {
    if (rowBounds[i + 1]) {
      const rowY = (row.bottom + rowBounds[i + 1].top) / 2;
      rows.push({
        tl: {
          x: tableBounds.tl.x,
          y: rowY,
        },
        br: {
          x: tableBounds.br.x,
          y: rowY,
        },
      });
    }
  });

  return rows;
};

export const getTableBounds = table => {
  let tl = { x: 1.0, y: 1.0 };
  let br = { x: 0.0, y: 0.0 };
  const columns = getTableColumns(table);
  for (const column of columns) {
    const tokens = getColumnTokens(column);
    for (const token of tokens) {
      if (token && token.bounds) {
        tl = {
          x: Math.min(token.bounds.topLeft.x, tl.x),
          y: Math.min(token.bounds.topLeft.y, tl.y),
        };
        br = {
          x: Math.max(token.bounds.bottomRight.x, br.x),
          y: Math.max(token.bounds.bottomRight.y, br.y),
        };
      }
    }
  }
  return { tl, br };
};

export const getPage = table => {
  const column = getTableColumns(table)[0];
  for (const token of getColumnTokens(column)) {
    if (token && token.bounds) {
      return token.bounds.pageNum;
    }
  }
};

export const getTableCategories = (columns, fieldSpec) => {
  const categories = [];
  const columnSpec = fieldSpec
    .filter(f => f.id === 'column')
    .map(c => c.components)
    .flat();

  const categoryOptions = columnSpec
    .filter(f => f.id === 'category')
    .map(c => c.options)
    .flat();

  // get category ids from the columns
  const categoryIds = columns.map(c => c.components?.category?.value);

  categoryIds.forEach(v => {
    categories.push(categoryOptions.find(c => c.id === v)?.title);
  });

  return categories;
};

const getHeaderConfig = table => {
  let headerConfig = '';
  const columns = table?.components?.column?.items;

  for (const column of columns) {
    if (column.components.label) {
      headerConfig = 'row';
      break;
    }
  }
  return headerConfig;
};

export const getTableData = table => {
  const tableBounds = getTableBounds(table);

  return {
    tableBounds,
    rows: getRows(tableBounds, table),
    columns: getColumns(tableBounds, table),
    header: getHeaderConfig(table),
    pageId: getPage(table),
    lockTable: false,
    disabledRows: [],
  };
};

const makeHashedKey = label => `${label}|${uuid().substring(0, 8)}`;

export const getTable = (table, fieldSpec = null) => {
  if (!table) return {};
  const columns = table?.components?.column?.items;
  if (!columns) return {};

  const headers = columns.map(c => ({
    key: makeHashedKey(c.components.label?.text || 'header'),
    value: c.components.label?.text || '',
  }));
  const rows = [];
  const categories = getTableCategories(columns, fieldSpec) || undefined;

  columns.forEach((c, i) => {
    c.components.value.items.forEach((item, j) => {
      if (!rows[j]) {
        rows[j] = {
          key: makeHashedKey(`row_${j}`),
          cells: [],
        };
      }
      rows[j].cells = [
        ...(rows[j].cells || []),
        { key: makeHashedKey(`cell_${i}_${j}`), value: item?.text },
      ];
    });
  });

  return {
    headers,
    rows,
    categories,
  };
};
