import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './Card.scss';
import { FaInfoCircle } from 'react-icons/fa';

const CardTooltip = ({ tooltipId, className, children }) => {
  const cardTooltipClass = classNames(className, 'card__tooltip');
  return (
    <div
      data-tooltip-id={tooltipId}
      data-tooltip-content={children}
      className={cardTooltipClass}
    >
      <FaInfoCircle />
    </div>
  );
};

CardTooltip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  tooltipId: PropTypes.string,
};

CardTooltip.defaultProps = {
  children: null,
  className: null,
  tooltipId: null,
};

export default CardTooltip;
