import {
  RECEIVE_ERROR,
  RECEIVE_PRODUCT,
  REQUEST_PRODUCT,
  REFRESH_PRODUCT,
  TRANSMIT_DATA,
} from './actions';
import { RECEIVE_FIELDSETS } from '../fieldsets/actions';

const initialState = {
  data: null,
  refresh: false,
  loading: true,
  created: false,
};

const productReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case TRANSMIT_DATA: {
      const { loading, sent } = payload;
      return {
        ...state,
        loading,
        sent,
      };
    }

    case RECEIVE_ERROR: {
      return {
        ...state,
        loading: false,
        sent: false,
      };
    }

    case REQUEST_PRODUCT: {
      const { data, loading } = payload;
      return {
        ...state,
        data,
        loading,
      };
    }

    case RECEIVE_PRODUCT: {
      const { data, loading, sent } = payload;
      return {
        ...state,
        data,
        loading,
        refresh: false,
        sent,
      };
    }

    case REFRESH_PRODUCT: {
      return {
        ...state,
        refresh: true,
      };
    }

    case RECEIVE_FIELDSETS: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
};

export default productReducer;
