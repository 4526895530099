import { useState } from 'react';
import { getQueryParams } from '../../../common/helpers';

export const useDocumentFilters = initialFilters => {
  const queryFilters = getQueryParams(window.location.search);

  return useState(
    Object.keys(queryFilters).length ? queryFilters : initialFilters,
  );
};

export default useDocumentFilters;
