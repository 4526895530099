import * as React from 'react';
import { DocumentSplitChild } from '../../atoms/Icons/index';

function DocumentListIconSplitChild() {
  return (
    <span
      data-tooltip-id="documents-tooltip"
      data-tooltip-content="Split Document"
      data-tooltip-place="top"
    >
      <DocumentSplitChild />
    </span>
  );
}

export default DocumentListIconSplitChild;
