/* eslint-disable no-restricted-syntax */
import { getRotatedBounds } from '../../../common/helpers';

// eslint-disable-next-line consistent-return
export const getCategoryOptionsFromSpec = field => {
  if (field.type === 'table') {
    return field.components
      .find(c => c.id === 'column')
      .components.find(c => c.id === 'category')
      .options.map(o => ({ value: o.id, label: o.title }));
  }
  if (field.type === 'table:2') {
    return field.columns
      .filter(c => c.type === 'column')
      .map(c => ({ value: c.id, label: c.description }));
  }
};

export const getCategoryFromAnnotation = (
  field,
  annotation,
  categoryOptions,
  index,
  // eslint-disable-next-line consistent-return
) => {
  if (field.type === 'table') {
    const categoryAnnotation = annotation.fields
      .find(f => f.id === 'column')
      .value[index].fields.find(f => f.id === 'category').data;

    if (categoryAnnotation === null) {
      return null;
    }
    const selection = Object.entries(categoryAnnotation.value).find(v => v[1]);

    if (selection) {
      return categoryOptions.find(o => o.value === selection[0]);
    }
    return categoryOptions.find(o => o.value === null);
  }
  if (field.type === 'table:2') {
    const column = annotation.columns[index];
    return categoryOptions.find(o => o.value === column.id);
  }
};

export const isInSection = (tl, br, candidate, { pageId, rotation }) => {
  const { topLeft, bottomRight } = candidate.bounds;
  const bounds = {
    topLeft: tl,
    bottomRight: br,
    pageNum: pageId,
  };

  const rotatedBounds = getRotatedBounds(bounds, rotation);
  const midPoint = {
    x: (topLeft.x + bottomRight.x) / 2,
    y: (topLeft.y + bottomRight.y) / 2,
  };
  return (
    midPoint.y >= rotatedBounds.topLeft.y &&
    midPoint.x >= rotatedBounds.topLeft.x &&
    midPoint.x <= rotatedBounds.bottomRight.x &&
    midPoint.y <= rotatedBounds.bottomRight.y
  );
};

export const selectCategoryInAnnotation = (field, annotation, value, index) => {
  const newAnnotation = { ...annotation };

  if (field.type === 'table') {
    const categoryField = field.components
      .find(c => c.id === 'column')
      .components.find(c => c.id === 'category');
    newAnnotation.fields
      .find(f => f.id === 'column')
      .value[index].fields.find(f => f.id === 'category').source = {
      type: 'option',
      fieldType: 'multiclass',
      inputType: 'button',
    };
    newAnnotation.fields
      .find(f => f.id === 'column')
      .value[index].fields.find(f => f.id === 'category').data = {
      value: Object.fromEntries(
        categoryField.options.map(o => [o.id, value === o.id]),
      ),
    };
  }
  if (field.type === 'table:2') {
    newAnnotation.columns.filter(c => c.source.type === 'column')[index].id =
      value;
  }

  return newAnnotation;
};

export const saveEntitySelectionInAnnotation = (
  annotation,
  entityId,
  cells,
  header,
  value,
) => {
  const newAnnotation = { ...annotation };

  newAnnotation.columns.find(c => c.id === entityId).cells =
    newAnnotation.columns
      .find(c => c.id === entityId)
      .cells.map((c, i) =>
        cells.includes(i + (header === 'row' ? 1 : 0)) ? value : c,
      );
  return newAnnotation;
};

export const getCellDataFromAnnotation = (
  field,
  annotation,
  header,
  row,
  column,
  columns,
) => {
  let cellData;

  if (field.type === 'table') {
    if (annotation.fields && annotation.fields.find(f => f.id === 'column')) {
      if (header === '' || header === 'row') {
        if (header === 'row' && row === 0) {
          cellData = annotation.fields
            .find(f => f.id === 'column')
            .value[column].fields.find(f => f.id === 'label');
        } else {
          cellData = annotation.fields
            .find(f => f.id === 'column')
            .value[column].fields.find(f => f.id === 'value').value[
            row - (header === 'row' ? 1 : 0)
          ];
        }
      }
      if (header === 'first-column' || header === 'last-column') {
        if (
          (header === 'first-column' && column === 0) ||
          (header === 'last-column' && column === columns.length - 1)
        ) {
          cellData = annotation.fields
            .find(f => f.id === 'column')
            .value[row].fields.find(f => f.id === 'label');
        } else {
          cellData = annotation.fields
            .find(f => f.id === 'column')
            .value[row].fields.find(f => f.id === 'value').value[
            column - (header === 'first-column' ? 1 : 0)
          ];
        }
      }
      return cellData.data.value;
    }
  }
  if (field.type === 'table:2') {
    if (header === '' || header === 'row') {
      if (header === 'row' && row === 0) {
        cellData = annotation.columns[column].header;
      } else {
        cellData =
          annotation.columns[column].cells[row - (header === 'row' ? 1 : 0)];
      }
    }
    if (header === 'first-column' || header === 'last-column') {
      if (
        (header === 'first-column' && column === 0) ||
        (header === 'last-column' && column === columns.length - 1)
      ) {
        cellData = annotation.columns[row].header;
      } else {
        cellData =
          annotation.columns[row].cells[
            column - (header === 'first-column' ? 1 : 0)
          ];
      }
    }
    return cellData.data.value;
  }
  return [];
};

export const getEntityFromAnnotation = (entityId, i, annotation) => {
  return annotation.columns.find(c => c.id === entityId).cells[i];
};

const getCandidatesByIds = (tokenIds, candidateSelection) =>
  candidateSelection.candidates.filter(c => tokenIds.includes(c.id));

export const buildAnnotationFromPrediction = (
  prediction,
  tableData,
  candidates,
  field,
  // eslint-disable-next-line consistent-return
) => {
  const fields = [];

  const fieldAnnotation = {
    id: 'column',
    value: [],
  };

  if (field.type === 'table') {
    // eslint-disable-next-line no-unused-vars
    for (const column of prediction.components.column.items) {
      const columnAnnotation = {
        fields: [],
      };
      if (column.components.label) {
        columnAnnotation.fields.push({
          id: 'label',
          data: {
            // eslint-disable-next-line no-use-before-define
            value: getCandidatesByIds(
              column.components.label.bounds.tokenIds,
              candidates,
            ),
          },
          source: {
            type: 'prediction-tokens',
          },
        });
      }
      // column.value
      const valueAnnotation = {
        id: 'value',
        value: [],
      };
      // eslint-disable-next-line no-restricted-syntax
      // eslint-disable-next-line no-unused-vars
      for (const colVal of column.components.value.items) {
        valueAnnotation.value.push({
          id: 'value',
          data: {
            value:
              colVal !== null
                ? // eslint-disable-next-line no-use-before-define
                  getCandidatesByIds(colVal.bounds.tokenIds, candidates)
                : null,
          },
          source: {
            type: 'prediction-tokens',
          },
        });
      }
      columnAnnotation.fields.push(valueAnnotation);
      // column.category
      columnAnnotation.fields.push({
        id: 'category',
        data: {
          value: Object.fromEntries(
            column.components.category.options.map(o => [
              o,
              o === column.components.category.value,
            ]),
          ),
        },
        source: { type: 'option' },
      });
      fieldAnnotation.value.push(columnAnnotation);
    }
    fields.push(fieldAnnotation);
    return {
      fields,
      tableData,
    };
  }
  if (field.type === 'table:2') {
    return {
      columns: prediction.columns
        .filter(c => c.type === 'column')
        .map(c => ({
          source: {
            type: 'column',
          },
          id: c.category,
          cells: c.cells.map(cl => ({
            id: 'cells[*]',
            source: {
              type: 'selection',
            },
            data: {
              value:
                cl !== null
                  ? getCandidatesByIds(
                      cl.tokens.map(t => t.tokenId),
                      candidates,
                    )
                  : [],
            },
          })),
          header: {
            id: 'header',
            source: {
              type: 'selection',
            },
            data: {
              value:
                c.header !== null
                  ? getCandidatesByIds(
                      c.header.tokens.map(t => t.tokenId),
                      candidates,
                    )
                  : [],
            },
          },
        })),
      tableData,
      source: { type: 'table' },
    };
  }
};

const buildColumnValueAnotationV2 = (
  field,
  candidates,
  target,
  i,
  tableData,
  // eslint-disable-next-line consistent-return
) => {
  const { header, rows, columns, tableBounds, disabledRows } = tableData;

  if (header === '' || header === 'row') {
    return rows
      .map((row, j) => {
        if (disabledRows.includes(j) || (header === 'row' && j === 0)) {
          return null;
        }
        const tl = {
          x: columns[i - 1] ? columns[i - 1].tl.x : tableBounds.tl.x,
          y: rows[j - 1] ? rows[j - 1].tl.y : tableBounds.tl.y,
        };
        const br = {
          x: target.br.x,
          y: row.br.y,
        };

        return {
          id: 'cells[*]',
          data: {
            value: candidates.filter(c => isInSection(tl, br, c, tableData)),
          },
          source: {
            type: 'selection',
          },
        };
      })
      .filter(r => r !== null);
  }
  if (header === 'first-column' || header === 'last-column') {
    return columns
      .map((column, j) => {
        if (
          (header === 'first-column' && j === 0) ||
          (header === 'last-column' && j === columns.length - 1)
        ) {
          return null;
        }
        const tl = {
          x: columns[j - 1] ? columns[j - 1].tl.x : tableBounds.tl.x,
          y: rows[i - 1] ? rows[i - 1].tl.y : tableBounds.tl.y,
        };
        const br = {
          x: column.br.x,
          y: target.br.y,
        };
        return {
          id: 'cells[*]',
          data: {
            value: candidates.filter(c => isInSection(tl, br, c, tableData)),
          },
          source: {
            type: 'selection',
          },
        };
      })
      .filter(c => c !== null);
  }
};

const buildColumnLabelAnotationV2 = (
  field,
  candidates,
  target,
  i,
  tableData,
) => {
  const { header, rows, columns, tableBounds } = tableData;
  let tl;
  let br;

  if (header === '') {
    return null;
  }

  if (header === 'row') {
    tl = {
      x: columns[i - 1] ? columns[i - 1].tl.x : tableBounds.tl.x,
      y: tableBounds.tl.y,
    };
    br = {
      x: target.br.x,
      y: rows[0].br.y,
    };
  }
  if (header === 'first-column') {
    tl = {
      x: tableBounds.tl.x,
      y: rows[i - 1] ? rows[i - 1].tl.y : tableBounds.tl.y,
    };
    br = {
      x: columns[0].br.x,
      y: target.br.y,
    };
  }
  if (header === 'last-column') {
    tl = {
      x: columns[columns.length - 2].tl.x,
      y: rows[i - 1] ? rows[i - 1].tl.y : tableBounds.tl.y,
    };
    br = {
      x: columns[columns.length - 1].br.x,
      y: target.br.y,
    };
  }
  return {
    id: 'header',
    data: {
      value: candidates.filter(c => isInSection(tl, br, c, tableData)),
    },
    source: {
      type: 'selection',
    },
  };
};

const buildColumnAnnotationV2 = (field, candidates, target, i, tableData) => {
  const { annotation } = tableData;
  return {
    id:
      annotation &&
      annotation.columns.filter(c => c.source.type === 'column')[i]
        ? annotation.columns.filter(c => c.source.type === 'column')[i].id
        : null,
    cells: buildColumnValueAnotationV2(field, candidates, target, i, tableData),
    header: buildColumnLabelAnotationV2(
      field,
      candidates,
      target,
      i,
      tableData,
    ),
    source: {
      type: 'column',
    },
  };
};

const buildEntityAnnotation = (derivedColumn, tableData) => {
  const { annotation, header, rows } = tableData;

  const cells = Array(rows.length - (header === 'row' ? 1 : 0))
    .fill(null)
    .map((_, i) => {
      if (
        annotation &&
        annotation.columns.find(c => c.id === derivedColumn.id)
      ) {
        return annotation.columns.find(c => c.id === derivedColumn.id).cells[i];
      }
      return null;
    });

  return {
    id: derivedColumn.id,
    cells,
    header: null,
    source: {
      type: 'derived-column',
    },
  };
};

// eslint-disable-next-line consistent-return
const buildAnnotationV2 = (field, candidates, tableData) => {
  const {
    columns,
    rows,
    header,
    tableBounds,
    pageId,
    lockTable,
    disabledRows,
  } = tableData;

  if (header === '' || header === 'row') {
    return {
      columns: [
        ...columns.map((column, i) =>
          buildColumnAnnotationV2(field, candidates, column, i, tableData),
        ),
        ...field.columns
          .filter(c => c.type === 'derived-column')
          .map(derivedColumn =>
            buildEntityAnnotation(derivedColumn, tableData),
          ),
      ],
      source: {
        type: 'table',
      },
      tableData: {
        tableBounds,
        rows: rows.slice(0, -1),
        columns: columns.slice(0, -1),
        header,
        pageId,
        lockTable,
        disabledRows,
      },
    };
  }
  if (header === 'first-column' || header === 'last-column') {
    return {
      columns: [
        ...rows.map((row, i) =>
          buildColumnAnnotationV2(field, candidates, row, i, tableData),
        ),
        ...field.columns
          .filter(c => c.type === 'derived-column')
          .map(derivedColumn =>
            buildEntityAnnotation(derivedColumn, tableData),
          ),
      ],
      source: {
        type: 'table',
      },
      tableData: {
        tableBounds,
        rows: rows.slice(0, -1),
        columns: columns.slice(0, -1),
        header,
        pageId,
        lockTable,
        disabledRows,
      },
    };
  }
};

const buildAnnotationV1 = (
  field,
  candidates,
  {
    tableBounds,
    rows,
    columns,
    header,
    pageId,
    lockTable,
    disabledRows,
    annotation,
    rotation,
  },
) => {
  const { components } = field;

  const fields = [];
  // eslint-disable-next-line no-unused-vars
  const tableCoords = Object.values(tableBounds).length
    ? tableBounds
    : { tl: { x: 0, y: 0 }, br: { x: 0, y: 0 } };
  // eslint-disable-next-line no-unused-vars
  for (const component of components) {
    // eslint-disable-next-line no-shadow
    const field = {
      id: component.id,
      value: [],
    };
    if (component.id === 'column') {
      if (header === '' || header === 'row') {
        // eslint-disable-next-line no-shadow
        columns.forEach((column, i, columns) => {
          field.value.push(
            // eslint-disable-next-line no-use-before-define
            buildColumnAnnotationV1(component, {
              column,
              i,
              columns,
              rows,
              candidates,
              tableBounds,
              header,
              disabledRows,
              annotation,
              pageId,
              rotation,
            }),
          );
        });
      }
      if (header === 'first-column' || header === 'last-column') {
        // eslint-disable-next-line no-shadow
        rows.forEach((row, i, rows) => {
          if (!disabledRows.includes(i)) {
            field.value.push(
              // eslint-disable-next-line no-use-before-define
              buildColumnAnnotationV1(component, {
                row,
                i,
                rows,
                columns,
                candidates,
                tableBounds,
                header,
                disabledRows,
                annotation,
                pageId,
                rotation,
              }),
            );
          }
        });
      }
    }
    fields.push(field);
  }
  return {
    fields,
    tableData: {
      tableBounds,
      rows: rows.slice(0, -1),
      columns: columns.slice(0, -1),
      header,
      pageId,
      lockTable,
      disabledRows,
    },
  };
};

const buildColumnValueAnnotation = (field, data) => {
  const { candidates, i, tableBounds, header, disabledRows } = data;
  const value = [];
  const tableCoords = Object.values(tableBounds).length
    ? tableBounds
    : { tl: { x: 0, y: 0 }, br: { x: 0, y: 0 } };

  if (header === '' || header === 'row') {
    const colTl = {
      x: data.columns[i - 1] ? data.columns[i - 1].tl.x : tableCoords.tl.x,
      y: data.columns[i - 1] ? data.columns[i - 1].tl.y : tableCoords.tl.y,
    };
    const colBr = {
      x: data.column.br.x,
      y: data.column.br.y,
    };
    const columnCandidates = candidates.filter(c =>
      isInSection(colTl, colBr, c, data),
    );
    data.rows.forEach((row, j, rows) => {
      const tl = {
        x: data.columns[i - 1] ? data.columns[i - 1].tl.x : tableCoords.tl.x,
        y: rows[j - 1] ? rows[j - 1].tl.y : tableCoords.tl.y,
      };
      const br = {
        x: data.column.br.x,
        y: row.br.y,
      };
      if (!disabledRows.includes(j) && !(header === 'row' && j === 0)) {
        value.push({
          source: {
            type: 'selection',
          },
          data: {
            value: columnCandidates.filter(c => isInSection(tl, br, c, data)),
          },
          id: field.id,
        });
      }
    });
  }
  if (header === 'first-column' || header === 'last-column') {
    const colTl = {
      x: data.rows[i - 1] ? data.rows[i - 1].tl.x : tableCoords.tl.x,
      y: data.rows[i - 1] ? data.rows[i - 1].tl.y : tableCoords.tl.y,
    };
    const colBr = {
      x: data.row.br.x,
      y: data.row.br.y,
    };
    const columnCandidates = candidates.filter(c =>
      isInSection(colTl, colBr, c, data),
    );

    data.columns.forEach((column, j, columns) => {
      const tl = {
        x: columns[j - 1] ? columns[j - 1].tl.x : tableCoords.tl.x,
        y: data.rows[i - 1] ? data.rows[i - 1].tl.y : tableCoords.tl.y,
      };
      const br = {
        x: column.br.x,
        y: data.row.br.y,
      };
      if (
        !(header === 'first-column' && j === 0) &&
        !(header === 'last-column' && j === columns.length - 1)
      ) {
        value.push({
          source: {
            type: 'selection',
          },
          data: {
            value: columnCandidates.filter(c => isInSection(tl, br, c, data)),
          },
          id: field.id,
        });
      }
    });
  }
  return value;
};

const buildColumnLabelAnnotation = (field, data) => {
  const { candidates, i, tableBounds, header } = data;
  const value = [];
  const tableCoords = Object.values(tableBounds).length
    ? tableBounds
    : { tl: { x: 0, y: 0 }, br: { x: 0, y: 0 } };
  if (header === 'row') {
    const tl = {
      x: data.columns[i - 1] ? data.columns[i - 1].tl.x : tableCoords.tl.x,
      y: tableCoords.tl.y,
    };
    const br = {
      x: data.column.br.x,
      y: data.rows[0].br.y,
    };
    value.push(...candidates.filter(c => isInSection(tl, br, c, data)));
  }
  if (header === 'first-column') {
    const tl = {
      x: tableCoords.tl.x,
      y: data.rows[i - 1] ? data.rows[i - 1].tl.y : tableCoords.tl.y,
    };
    const br = {
      x: data.columns[0].br.x,
      y: data.row.br.y,
    };
    value.push(...candidates.filter(c => isInSection(tl, br, c, data)));
  }
  if (header === 'last-column') {
    const tl = {
      x: data.columns[data.columns.length - 2].tl.x,
      y: data.rows[i - 1] ? data.rows[i - 1].tl.y : tableCoords.tl.y,
    };
    const br = {
      x: data.columns[data.columns.length - 1].br.x,
      y: data.row.br.y,
    };
    value.push(...candidates.filter(c => isInSection(tl, br, c, data)));
  }
  return { value };
};

const buildColumnCategoryAnnotation = (field, { i, annotation }) => {
  if (!annotation || !annotation.fields.find(f => f.id === 'column').value[i]) {
    return null;
  }
  return annotation.fields
    .find(f => f.id === 'column')
    .value[i].fields.find(f => f.id === 'category').data;
};

const buildColumnAnnotationV1 = (field, data) => {
  const { components } = field;
  const fields = [];

  fields.push({
    id: 'value',
    value: buildColumnValueAnnotation(
      components.find(c => c.id === 'value'),
      data,
    ),
  });
  fields.push({
    id: 'label',
    data: buildColumnLabelAnnotation(
      components.find(c => c.id === 'label'),
      data,
    ),
    source: {
      type: 'selection',
    },
  });
  fields.push({
    id: 'category',
    data: buildColumnCategoryAnnotation(
      components.find(c => c.id === 'category'),
      data,
    ),
    source: { type: 'option' },
  });

  return { fields };
};

// eslint-disable-next-line consistent-return
export const buildAnnotation = (field, candidates, tableData) => {
  if (field.type === 'table') {
    return buildAnnotationV1(field, candidates, tableData);
  }
  if (field.type === 'table:2') {
    return buildAnnotationV2(field, candidates, tableData);
  }
};

export const saveManualEditToAnnotaion = (
  field,
  annotation,
  editText,
  selectedCell,
  header,
  rows,
  columns,
) => {
  const { row, column } = selectedCell;

  const annotationValue = {
    source: {
      type: 'manual',
      origin: null,
    },
    data: {
      value: editText,
    },
    id: 'value',
  };
  const newAnnotation = { ...annotation };

  if (field.type === 'table') {
    if (header === '' || header === 'row') {
      if (header === 'row' && row === 0) {
        const label = newAnnotation.fields
          .find(f => f.id === 'column')
          .value[column].fields.find(f => f.id === 'label');
        label.data.value = editText;
        label.source = {
          type: 'manual',
          origin: null,
        };
      } else {
        newAnnotation.fields
          .find(f => f.id === 'column')
          .value[column].fields.find(f => f.id === 'value').value[
          row - (header === 'row' ? 1 : 0)
        ] = annotationValue;
      }
    }
    if (header === 'first-colunm' || header === 'last-column') {
      if (header === 'first-column' && column === 0) {
        const label = newAnnotation.fields
          .find(f => f.id === 'column')
          .value[row].fields.find(f => f.id === 'label');
        label.data.value = editText;
        label.source = {
          type: 'manual',
          origin: null,
        };
      } else if (header === 'last-column' && column === columns.length) {
        const label = newAnnotation.fields
          .find(f => f.id === 'column')
          .value[row].fields.find(f => f.id === 'label');
        label.data.value = editText;
        label.source = {
          type: 'manual',
          origin: null,
        };
      } else {
        newAnnotation.fields
          .find(f => f.id === 'column')
          .value[row].fields.find(f => f.id === 'value').value[
          column - (header === 'first-column' ? 1 : 0)
        ] = annotationValue;
      }
    }
  }
  if (field.type === 'table:2') {
    if (header === '' || header === 'row') {
      if (header === 'row' && row === 0) {
        annotationValue.id = 'header';
        newAnnotation.columns[column].header = annotationValue;
      } else {
        annotationValue.id = 'cells[*]';
        newAnnotation.columns[column].cells[row - (header === 'row' ? 1 : 0)] =
          annotationValue;
      }
    }
    if (header === 'first-colunm' || header === 'last-column') {
      if (
        (header === 'first-column' && column === 0) ||
        (header === 'last-column' && column === columns.length)
      ) {
        annotationValue.id = 'header';
        newAnnotation.columns[row].header = annotationValue;
      } else {
        annotationValue.id = 'cells[*]';
        newAnnotation.columns[row].cells[
          column - (header === 'first-column' ? 1 : 0)
        ] = annotationValue;
      }
    }
  }
  return newAnnotation;
};

export const saveTokenSelectionToAnnotation = (
  field,
  annotation,
  selectedCandidates,
  selectedCell,
  header,
  rows,
  columns,
) => {
  const { row, column } = selectedCell;
  const newAnnotation = { ...annotation };
  const annotationValue = {
    source: {
      type: 'selection',
    },
    data: {
      value: Object.values(selectedCandidates),
    },
    id: 'value',
  };

  if (field.type === 'table') {
    if (header === '' || header === 'row') {
      if (header === 'row' && row === 0) {
        const label = newAnnotation.fields
          .find(f => f.id === 'column')
          .value[column].fields.find(f => f.id === 'label');
        label.source = {
          type: 'selection',
        };
        label.data.value = Object.values(selectedCandidates);
      } else {
        newAnnotation.fields
          .find(f => f.id === 'column')
          .value[column].fields.find(f => f.id === 'value').value[
          row - (header === 'row' ? 1 : 0)
        ] = annotationValue;
      }
    }
    if (header === 'first-colunm' || header === 'last-column') {
      if (header === 'first-column' && column === 0) {
        const label = newAnnotation.fields
          .find(f => f.id === 'column')
          .value[row].fields.find(f => f.id === 'value');
        label.source = {
          type: 'selection',
        };
        label.data.value = Object.values(selectedCandidates);
      } else if (header === 'last-column' && column === columns.length) {
        const label = newAnnotation.fields
          .find(f => f.id === 'column')
          .value[row].fields.find(f => f.id === 'value');
        label.source = {
          type: 'selection',
        };
        label.data.value = Object.values(selectedCandidates);
      } else {
        newAnnotation.fields
          .find(f => f.id === 'column')
          .value[row].fields.find(f => f.id === 'value').value[
          column - (header === 'first-column' ? 1 : 0)
        ] = annotationValue;
      }
    }
  }
  if (field.type === 'table:2') {
    if (header === '' || header === 'row') {
      if (header === 'row' && row === 0) {
        annotationValue.id = 'header';
        newAnnotation.columns[column].header = annotationValue;
      } else {
        annotationValue.id = 'cells[*]';
        newAnnotation.columns[column].cells[row - (header === 'row' ? 1 : 0)] =
          annotationValue;
      }
    }
    if (header === 'first-colunm' || header === 'last-column') {
      if (
        (header === 'first-column' && column === 0) ||
        (header === 'last-column' && column === columns.length)
      ) {
        annotationValue.id = 'header';
        newAnnotation.columns[row].header = annotationValue;
      } else {
        annotationValue.id = 'cells[*]';
        newAnnotation.columns[row].cells[
          column - (header === 'first-column' ? 1 : 0)
        ] = annotationValue;
      }
    }
  }

  return newAnnotation;
};

// eslint-disable-next-line consistent-return
export const uncategorisedColumns = (field, annotation) => {
  if (field.type === 'table:2') {
    return !!annotation.columns.find(c => c.id === null);
  }
  if (field.type === 'table') {
    // eslint-disable-next-line no-unused-vars
    for (const column of annotation.fields.find(f => f.id === 'column').value) {
      const category = column.fields.find(f => f.id === 'category').data;
      if (category === null) {
        return true;
      }
    }
  }
};

// eslint-disable-next-line consistent-return
export const getEntitiesFromSpec = field => {
  if (!field || field.type === 'table') {
    return [];
  }
  if (field.type === 'table:2') {
    return field.columns
      .filter(c => c.type === 'derived-column')
      .map(c => ({
        ...c.cell,
        id: c.id,
        description: c.description,
        name: c.name,
      }));
  }
};

export const emptyEntityRows = (field, annotation) => {
  const fieldEntities = getEntitiesFromSpec(field);

  if (fieldEntities.length === 0) {
    return false;
  }
  const status = fieldEntities.map(entity => {
    const column = annotation.columns.find(c => c.id === entity.id);
    return column.cells.includes(null) || column.cells.includes(undefined);
  });
  return status.includes(true);
};
