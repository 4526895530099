import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { loadUser } from '../../reducers/authReducer';
import { fetchSubscriptions } from '../../reducers/admin/actions';
import { downloadDoc, exportDocs, exportNDIS } from '../../reducers/docs';
import {
  LineItemsLayoutOptions,
  DelimiterOptions,
  HeaderLayoutOptions,
} from './lookups';

import FieldsDropdown from '../Pickers/FieldsDropdown';
import Accordion, {
  toggleAccordionPanel,
} from '../../library/organisms/Accordion';
import { Button } from '../../library/atoms/Buttons';
import { Checkbox } from '../../library/atoms/Checkbox';
import Select from '../../library/atoms/Select';
import * as modal from '../../library/molecules/Modal';
import RadioList from '../../library/molecules/RadioList';

import './ExportModal.scss';

const getHeading = (noOfDocs = 0) => {
  return `Exporting ${noOfDocs} document${noOfDocs !== 1 ? 's' : ''}`;
};

const INITIAL_STATE = {
  docs: [],
  filters: undefined,
  settings: {
    fields: [],
    headerFieldsLayout: 'collapsed',
    lineItemsLayout: 'expanded',
    delimiterType: 'comma',
    isSaveExportSettings: false,
  },
  total: undefined,
  isSelectAll: undefined,
};

const getExportSettings = settings => {
  if (!settings) return {};
  return {
    settings: {
      ...settings,
      isSaveExportSettings: false,
      fields: [],
    },
  };
};

const mapStateToProps = state => {
  const { admin, auth, docs, products, specs } = state;
  return {
    products: products?.data,
    companyId: state.company.data.id,
    subscriptions: admin.subscriptions.data,
    specs: specs?.data?.specifications,
    totalDocs: docs.data ? docs.data.count : null,
    user: auth?.user?.data || {},
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      fetchSubscriptions: companyId => dispatch(fetchSubscriptions(companyId)),
      downloadDoc: (docUrl, filename) =>
        dispatch(downloadDoc(docUrl, filename)),
      exportDocs: payload => dispatch(exportDocs(payload)),
      exportNDIS: payload => dispatch(exportNDIS(payload)),
      loadUser: () => dispatch(loadUser()),
    },
  };
};

const ExportModal = ({
  docs,
  filters,
  maxDocs,
  isOpen,
  isSelectAll, // select up to next 1000 docs
  onCancelModal,
  onCloseModal,
  // redux
  products,
  companyId,
  subscriptions,
  specs,
  totalDocs,
  user,
  dispatch,
}) => {
  const [exportPayload, setExportPayload] = useState({
    ...INITIAL_STATE,
    docs: Object.keys(docs || {}),
    filters,
    isSelectAll,
  });

  const [isExport, setExport] = useState({
    original: false,
    data: false, // isLineItems
    ndisData: false, // isNDIS
  });

  const [exportHasLineItems, setExportHasLineItems] = useState(isSelectAll);
  const [lineItemFields, setLineItemFields] = useState(null);

  const outerRef = useRef();
  const innerRef = useRef();

  const selectedDocs = Object.keys(docs || {});
  const noOfSelectedDocs = (() => {
    let n = !isSelectAll ? selectedDocs.length : totalDocs;
    n = n < maxDocs ? n : maxDocs;
    return n;
  })();

  useEffect(() => {
    if (!subscriptions) {
      dispatch.fetchSubscriptions(companyId);
    }
  }, [companyId, subscriptions]);

  const selectedFieldsets = useMemo(() => {
    if (isSelectAll) {
      return specs; // return all on batch select
    }

    const docProducts = Object.values(docs || {})
      .flatMap(p => p.products)
      .reduce((list, i) => {
        return list.includes(i) ? list : [...list, i];
      }, []);

    const fieldsets = new Set();
    docProducts.forEach(dp => {
      (products.find(p => p.productId === dp)?.fieldsets || [dp]).forEach(fs =>
        fieldsets.add(fs),
      );
    });

    // fieldset with lineitems();
    const lineItems = (specs || [])
      .filter(s => Array.from(fieldsets).includes(s.id))
      .map(field =>
        field.field_versions
          .filter(
            v =>
              v.specification.type === 'lineitems' ||
              v.specification.type === 'table' ||
              v.specification.type === 'table:2',
          )
          .map(value => value.field_id),
      )
      .flat();

    setLineItemFields(lineItems);

    return (specs || [])
      .filter(s => Array.from(fieldsets).includes(s.id))
      .sort(
        (a, b) =>
          a.fieldset_version.description < b.fieldset_version.description,
      );
  }, [docs, specs, isSelectAll, products]);

  const checkLineItems = newSelectedFields => {
    let hasLineItems = false;

    (lineItemFields || []).forEach(id => {
      if (newSelectedFields.includes(id)) {
        hasLineItems = true;
      }
    });

    setExportHasLineItems(hasLineItems);
    return hasLineItems;
  };

  const getDefaultLineItemsLayout = hasLineItems => {
    // if there is lineitems - set value to export settings or expanded.
    if (hasLineItems === false) {
      return null;
    }
    return exportPayload.settings.lineItemsLayout || 'expanded';
  };

  useEffect(() => {
    setExportPayload(state => ({
      ...state,
      ...getExportSettings(user?.profileData?.exportSettings),
    }));
  }, [user]);

  useEffect(() => {
    toggleAccordionPanel(
      outerRef.current,
      innerRef.current,
      isExport.data || isExport.ndisData,
    );
  }, [isExport]);

  const handleCancel = () => {
    setExportPayload(INITIAL_STATE);
    onCancelModal('isExportModalOpen');
  };

  const handleExport = async () => {
    if (isExport.original && Object.keys(docs).length === 1) {
      Object.values(docs).forEach(doc => {
        dispatch.downloadDoc(doc.id, doc.filename);
      });
    }

    if (isExport.data) {
      await dispatch.exportDocs(exportPayload);
    }

    if (isExport.ndisData) {
      await dispatch.exportNDIS({
        docs: selectedDocs,
        filters,
        total: noOfSelectedDocs,
        isSaveExportSettings: exportPayload.settings.isSaveExportSettings,
        isSelectAll,
      });
    }

    dispatch.loadUser();
    onCloseModal('isExportModalOpen');
  };

  const handleSettingsChange = (key, newSelectedFields) => {
    let lineItemsExists;
    if (key === 'fields' && !isSelectAll) {
      lineItemsExists = checkLineItems(newSelectedFields);
    }
    const newPayload = {
      ...exportPayload,
      settings: {
        ...exportPayload.settings,
        lineItemsLayout: getDefaultLineItemsLayout(lineItemsExists),
        [key]: newSelectedFields,
      },
    };
    setExportPayload(newPayload);
  };

  return (
    <modal.Modal isOpen={isOpen}>
      <modal.ModalContainer>
        <modal.ModalHeader>{getHeading(noOfSelectedDocs)}</modal.ModalHeader>
        <modal.ModalBody>
          <div className="export-modal">
            <p>
              Please choose what you&apos;d like to export from the selected
              documents.
            </p>
            <ul className="export-modal__options list-none">
              {noOfSelectedDocs === 1 && (
                <li>
                  <Checkbox
                    id="export_original_chk"
                    checked={isExport.original}
                    label="Original source file"
                    onChange={() => {
                      setExport(exp => ({
                        ...exp,
                        original: !exp.original,
                      }));
                    }}
                  />
                </li>
              )}
              <li>
                <Checkbox
                  id="export_lineItems_chk"
                  checked={isExport.data}
                  label="Extracted data (predictions) as .csv"
                  onChange={() => {
                    setExport(exp => ({
                      ...exp,
                      data: !exp.data,
                      ndisData: false,
                    }));
                  }}
                />
              </li>
              {subscriptions &&
                subscriptions.find(s => s.productId.includes('ndis')) && (
                  <li>
                    <Checkbox
                      id="export_ndis_chk"
                      checked={isExport.ndisData}
                      label="Extracted NDIS data (predictions) as .csv"
                      onChange={() => {
                        setExport(exp => ({
                          ...exp,
                          data: false,
                          ndisData: !exp.ndisData,
                        }));
                      }}
                    />
                  </li>
                )}
            </ul>
            <Accordion isOpen={isExport.data} variant="padded-with-line">
              <Accordion.Body>
                <h4 className="export-modal__sub-heading">Select fields:</h4>
                <div className="export-modal__label" htmlFor="extracted_fields">
                  Extracted fields
                </div>
                <FieldsDropdown
                  id="extracted_fields"
                  className="export-modal__dropdown"
                  fieldsets={selectedFieldsets}
                  defaultValues={exportPayload.settings.fields}
                  onChange={selected =>
                    handleSettingsChange('fields', selected)
                  }
                />
                <hr />
                <h4 className="export-modal__sub-heading">Select layout:</h4>
                <RadioList
                  label="Layout for header fields"
                  name="header_layout"
                  options={HeaderLayoutOptions}
                  value={HeaderLayoutOptions.find(
                    o => o.value === exportPayload.settings.headerFieldsLayout,
                  )}
                  onChange={option =>
                    handleSettingsChange('headerFieldsLayout', option?.value)
                  }
                  info={`<div class="export-modal__tooltip">There are 2 options if header fields contain multiple components (e.g. address contains street, state, postcode, etc):
            <ul><li>Collapsed – All components appear in a single column as an array</li>
            <li>Expanded – Each component appears as its own column</li></ul></div>`}
                  tooltipId="export-modal-tooltip"
                />
                {exportHasLineItems && (
                  <RadioList
                    label="Layout for line-item fields"
                    name="line_items_layout"
                    options={LineItemsLayoutOptions}
                    value={LineItemsLayoutOptions.find(
                      o => o.value === exportPayload.settings.lineItemsLayout,
                    )}
                    onChange={option =>
                      handleSettingsChange('lineItemsLayout', option?.value)
                    }
                    info={`<ul class="export-modal__tooltip"><li>Collapsed – line items data is embedded with header data, such that each export row = 1x document, and all line items appear in a single row as an array</li>
              <li>Expanded – line items data is embedded with header data, such that each export row  = 1x line item, and all headers are copied for each line item row</li>
              <li>Separate – line items data is exported as an additional .CSV file where each export row = 1x line item</li></ul>`}
                    tooltipId="export-modal-tooltip"
                  />
                )}
                <hr />
                <Select
                  id="delimiter_type"
                  className="export-modal__dropdown"
                  label="Delimiter type"
                  placeholder="Specify the type of delimiter"
                  options={DelimiterOptions}
                  value={DelimiterOptions.find(
                    o => o.value === exportPayload.settings.delimiterType,
                  )}
                  onChange={option =>
                    handleSettingsChange('delimiterType', option?.value)
                  }
                />
              </Accordion.Body>
            </Accordion>
            <ReactTooltip
              id="export-modal-tooltip"
              className="tooltip"
              variant="dark"
              place="right"
            />
          </div>
        </modal.ModalBody>
        <modal.ModalFooter>
          <Button
            id="export_cancel_btn"
            className="modal__button"
            variant="outline"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <div className="export-modal__footer-rhs">
            {isExport.data && (
              <Checkbox
                id="export_save_settings_chk"
                label="Save layout settings"
                checked={exportPayload.settings.isSaveExportSettings}
                onChange={() =>
                  handleSettingsChange(
                    'isSaveExportSettings',
                    !exportPayload.settings.isSaveExportSettings,
                  )
                }
              />
            )}
            <Button
              id="export_ok_btn"
              className="modal__button"
              disabled={
                !(isExport.original || isExport.data || isExport.ndisData)
              }
              onClick={handleExport}
            >
              Export
            </Button>
          </div>
        </modal.ModalFooter>
      </modal.ModalContainer>
    </modal.Modal>
  );
};

ExportModal.propTypes = {
  docs: PropTypes.shape({}).isRequired,
  filters: PropTypes.shape({}),
  maxDocs: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  isSelectAll: PropTypes.bool,
  onCancelModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  // redux
  subscriptions: PropTypes.arrayOf(PropTypes.shape({})),
  companyId: PropTypes.string.isRequired,
  specs: PropTypes.arrayOf(PropTypes.shape({})),
  totalDocs: PropTypes.number,
  user: PropTypes.shape({
    profileData: PropTypes.shape({
      exportSettings: PropTypes.shape({}),
    }),
  }),
  dispatch: PropTypes.shape({
    fetchSubscriptions: PropTypes.func,
    downloadDoc: PropTypes.func,
    exportDocs: PropTypes.func,
    exportNDIS: PropTypes.func,
    loadUser: PropTypes.func,
  }),
  products: PropTypes.arrayOf(PropTypes.shape({})),
};

ExportModal.defaultProps = {
  filters: {},
  maxDocs: 1000,
  isSelectAll: false,
  // redux
  subscriptions: [],
  specs: [],
  totalDocs: 0,
  user: {},
  dispatch: {},
  products: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportModal);
