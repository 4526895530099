import React, { Fragment } from 'react';
import './DocsSearch.scss';
import { FaSearch, FaTimesCircle } from 'react-icons/fa';
import PropTypes from 'prop-types';

const DocsSearch = ({ filters, forwardRef, onChange, onClear }) => {
  const handleChange = e => {
    onChange(e.target, filters);
  };

  return (
    <>
      <input
        className="search__query"
        type="text"
        name="query"
        defaultValue={filters.query}
        onChange={e => handleChange(e)}
        ref={forwardRef}
        placeholder="Search by filename, id or tag"
      />
      {filters.query ? (
        <FaTimesCircle
          color="#bbb"
          className="search__icon search__icon-close"
          onClick={onClear}
        />
      ) : (
        <FaSearch color="#bbb" className="search__icon search__icon-search" />
      )}
    </>
  );
};

DocsSearch.propTypes = {
  filters: PropTypes.object.isRequired,
  forwardRef: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default DocsSearch;
