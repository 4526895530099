import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { mergeRight as merge } from 'ramda';
import Form, { TextInput, useForm, validatorType } from '../Form';
import Modal from '../UI/Modal/Modal';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import PasswordFormCheck from './PasswordFormCheck';
import '../Admin/AdminForm.scss';
import { ModalContext } from '../UI/Modal/context/ModalContext';
import { updatePassword } from '../../reducers/user/actions';

const initialState = {
  oldPassword: {
    required: true,
  },
  newPassword: {
    required: true,
    validators: [validatorType.Password],
  },
  confirmPassword: {
    required: true,
    validators: [validatorType.Match],
    match: 'newPassword',
  },
};

const closeModal = setModal => {
  setModal(p =>
    merge(p, {
      isOpen: false,
      isSending: false,
      isSent: true,
    }),
  );
};

const showLoading = setModal => {
  setModal(p =>
    merge(p, {
      isSending: true,
      isSent: false,
    }),
  );
};

const mapStateToProps = state => {
  const { user } = state;
  return {
    result: user.changePassword.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      changePassword: (oldPwd, newPwd) =>
        dispatch(updatePassword(oldPwd, newPwd)),
    },
  };
};

const PasswordForm = ({ result, dispatch }) => {
  const [modal, setModal] = useContext(ModalContext);
  const [form] = useForm(initialState);
  const { isOpen, isSending } = modal;
  const { fields, errors } = form;

  useEffect(() => {
    if (result != null) {
      handleCloseModal();
    }
  }, [result]);

  const handleCloseModal = () => {
    closeModal(setModal);
    form.reset();
  };

  const handleSubmit = e => {
    e.preventDefault();
    const { oldPassword, newPassword } = fields;
    if (form.isValid) {
      showLoading(setModal);
      dispatch.changePassword(oldPassword.value, newPassword.value);
    }
  };

  return (
    <Modal
      className="user-modal"
      onClose={handleCloseModal}
      onCancel={handleCloseModal}
      onConfirm={handleSubmit}
      disabledConfirm={!form.isValid}
      confirmLabel="Submit"
      modalLabel="Change password"
      isOpen={isOpen}
    >
      <Form className="admin-form" onSubmit={handleSubmit}>
        <TextInput
          type="password"
          label="Current password"
          name="oldPassword"
          placeholder="Current password"
          value={fields.oldPassword.value}
          errors={errors}
          required
          onBlur={form.handleBlur}
          onChange={form.handleChange}
        />
        <TextInput
          type="password"
          label="New password"
          name="newPassword"
          placeholder="New password"
          value={fields.newPassword.value}
          errors={errors}
          required
          onBlur={form.handleBlur}
          onChange={form.handleChange}
        />
        <TextInput
          type="password"
          label="Confirm password"
          name="confirmPassword"
          placeholder="Confirm password"
          value={fields.confirmPassword.value}
          errors={errors}
          required
          onBlur={form.handleBlur}
          onChange={form.handleChange}
        />
        <PasswordFormCheck password={fields.newPassword.value} />
        {isSending ? <LoadingSpinner className="admin-form__spinner" /> : null}
      </Form>
    </Modal>
  );
};

PasswordForm.propTypes = {
  result: PropTypes.object,
  dispatch: PropTypes.shape({
    changePassword: PropTypes.func,
  }),
};

PasswordForm.defaultProps = {
  result: null,
  dispatch: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordForm);
