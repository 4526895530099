const generateFriendlyString = str => {
  return str
    .trim()
    .replace(/\s+/g, '-')
    .replace(/--+/g, '-')
    .replace(/[^\w\-:.]+/g, '')
    .toLowerCase();
};

export default generateFriendlyString;
