/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {
  FaCopy,
  FaPlus,
  FaTasks,
  FaTrashAlt,
  FaUser,
  FaUserLock,
} from 'react-icons/fa';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { fetchUsers } from '../../../../reducers/users/actions';
import {
  addToList,
  blinkIcon,
  removeFromList,
} from '../../../../common/helpers';

import Page, {
  PageBody,
  PageContent,
  PageHeader,
  PageInner,
} from '../../../Page';
import { Button } from '../../../../library/atoms/Buttons';
import { showModal } from '../../../UI/ModalV2';
import TextArea from '../../../UI/TextArea/TextArea';
import DataTable, {
  CheckboxColumn,
  InputColumn,
  IconColumn,
  DeleteColumn,
  EmptyTable,
} from '../../../UI/DataTable';

import { AssignUserModal, DeleteModal, UnassignModal } from './components';
import {
  createRow,
  updateRow,
  deleteRow,
  updateTag,
  deleteTags,
  listTags,
  updateUsersTags,
  createTag,
} from '../../store/actions';
import './_tags.scss';

const MAX_TAGS = 10;

const INITIAL_STATE = {
  isSelectAll: false,
  isFirstLoad: true,
};

const mapStateToProps = state => {
  const { auth, users, manageTags } = state;
  const { results } = manageTags.data || {};
  return {
    companyId: auth.user.data ? auth.user.data.companyId : null,
    tags: results,
    users: users.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      // UI ROWS
      createRow: () => dispatch(createRow()),
      updateRow: (tagId, params) => dispatch(updateRow(tagId, params)),
      deleteRow: tagId => dispatch(deleteRow(tagId)),

      // TAGS
      createTag: (companyId, tagId, tag) =>
        dispatch(createTag(companyId, tagId, tag)),
      updateTag: (companyId, tagName, params) =>
        dispatch(updateTag(companyId, tagName, params)),
      deleteTags: (companyId, tagIds) =>
        dispatch(deleteTags(companyId, tagIds)),
      listTags: (companyId, params) =>
        dispatch(
          listTags(companyId, {
            ...params,
            includeCounts: true,
          }),
        ),

      // USERS
      retrieveUsers: companyId =>
        dispatch(fetchUsers(companyId, { includeTags: true })),
      updateUsersTags: (companyId, userIds, params) =>
        dispatch(updateUsersTags(companyId, userIds, params)),
    },
  };
};

const Tags = ({ companyId, tags, users, dispatch }) => {
  const [activeTagId, setActiveTagId] = useState(null); // string
  const [selectedTagIds, setSelectedTagIds] = useState([]); // string[id]
  const [valErrorTagIds, setValErrorTagIds] = useState([]); // string[id]
  const [visibleTagIds, setVisibleTagIds] = useState([]);
  const [lastField, setLastField] = useState({});

  const [state, setState] = useState(INITIAL_STATE);
  const firstRef = useRef();

  const selectedAccessTagIds = selectedTagIds.filter(
    id => (tags.find(t => t.id === id) || {}).isAccessTag,
  );

  useEffect(() => {
    if (!companyId) return;

    if (!users) {
      dispatch.retrieveUsers(companyId);
    }

    dispatch.listTags(companyId, {
      sortColumn: 'created',
      sortDirection: 'desc',
    });
  }, [companyId]);

  // checks if page selectAll is checked
  useEffect(() => {
    setState(s => ({
      ...s,
      isSelectAll:
        selectedTagIds.length > 0 &&
        visibleTagIds.every(vt => selectedTagIds.includes(vt)),
    }));
  }, [visibleTagIds]);

  // set last focused element to tag.name if duplicate error detected
  useEffect(() => {
    if (!tags || !lastField.target) return;
    const lastTag = tags.find(t => t.name === lastField.value);
    if (lastTag && lastTag.name !== lastField.target.value) {
      lastField.target.value = lastTag.name;
    }
  }, [tags, lastField]);

  const handleAddTagClick = () => {
    dispatch.createRow();
    setTimeout(() => {
      if (firstRef.current) {
        firstRef.current.click();
        firstRef.current.focus();
      }
      setActiveTagId(null);
    }, 0);
  };

  const handleAssignAccessCtaClick = () => {
    const tagNames = selectedTagIds
      .map(id => tags.find(t => t.id === id))
      .map(t => t.name);

    showModal(
      <AssignUserModal
        companyId={companyId}
        selectedTagIds={selectedTagIds}
        tags={tags}
        users={users}
        onConfirmClick={(e, selectedUserIds) => {
          dispatch
            .updateUsersTags(companyId, selectedUserIds, {
              add: tagNames,
            })
            .then(() => {
              dispatch.retrieveUsers(companyId);
            });
          setSelectedTagIds([]);
        }}
      />,
    );
  };

  const handleAccessIconClick = (e, tag) => {
    e.stopPropagation();
    blinkIcon(e.target);

    if (!tag.isAccessTag || !tag.numberOfUsers) {
      dispatch.updateTag(companyId, tag.name, {
        isAccessTag: !tag.isAccessTag,
      });
      return;
    }

    showModal(
      <UnassignModal
        tag={tag}
        onConfirmClick={() => {
          dispatch.updateTag(companyId, tag.name, {
            isAccessTag: !tag.isAccessTag,
          });
        }}
      />,
    );
  };

  const handleDeleteClick = (e, tag) => {
    e.stopPropagation();
    if (tag.isNew) {
      dispatch.deleteRow(tag.id);
      return;
    }

    showModal(
      <DeleteModal
        tag={tag}
        onConfirmClick={() => {
          dispatch.deleteTags(companyId, [tag.id]);
          setSelectedTagIds([]);
        }}
      />,
    );
  };

  const handleDeleteSelectedClick = () => {
    const selectedTags = selectedTagIds.map(id => tags.find(t => t.id === id));
    showModal(
      <DeleteModal
        tags={selectedTags}
        onConfirmClick={() => {
          dispatch.deleteTags(companyId, selectedTagIds);
          setSelectedTagIds([]);
        }}
      />,
    );
  };

  const handleFetchData = dataTable => {
    const { page, pageSize } = dataTable;
    const currVisibleTags = [...tags]
      .splice(page * pageSize, pageSize)
      .map(t => t.id);

    setVisibleTagIds(currVisibleTags);
    setState(s => ({ ...s, isSelectAll: false }));
  };

  const handleInputBlur = (e, tag) => {
    setActiveTagId(null);
    const { column } = e.target.dataset;

    // if there is an existing value, use that
    if (!e.target.value && lastField.value) {
      e.target.value = lastField.value;
      return;
    }

    // validate name field
    if (column === 'name' && !e.target.value) {
      setValErrorTagIds(tagIds => [tag.id, ...tagIds]);
      return;
    }
    if (!e.target.value) return;

    // update row column only
    if (column !== 'name' && tag.isNew) {
      dispatch.updateRow(tag.id, {
        [e.target.dataset.column]: e.target.value,
      });
      return;
    }

    // create new tag
    if (tag.isNew) {
      const { name, description, isAccessTag } = tag;
      dispatch.createTag(companyId, tag.id, {
        ...{
          name,
          description,
          isAccessTag,
        },
        [e.target.dataset.column]: e.target.value,
      });
      return;
    }

    // update existing tag
    if (e.target.value !== tag[e.target.dataset.column]) {
      dispatch
        .updateTag(companyId, tag.name, {
          [e.target.dataset.column]: e.target.value,
        })
        .then(() => {
          setLastField(f => ({ ...f, value: tag.name }));
        });
      return;
    }

    setLastField(f => ({ ...f, value: null }));
  };

  const handleToggleAll = e => {
    e.stopPropagation();
    if (!e.target.checked) {
      setSelectedTagIds([]);
      setState(s => ({ ...s, isSelectAll: false }));
      return;
    }

    setSelectedTagIds([...selectedTagIds, ...visibleTagIds]);
    setState(s => ({ ...s, isSelectAll: true }));
  };

  const handleToggleRow = (e, id) => {
    e.stopPropagation();
    if (selectedTagIds.includes(id)) {
      setSelectedTagIds(removeFromList(selectedTagIds, id));
      setState(s => ({ ...s, isSelectAll: false }));
      return;
    }
    setSelectedTagIds(addToList(selectedTagIds, id));
  };

  return (
    <Page className="tags" title="Tags">
      <PageInner>
        <PageHeader className="rules__header">
          <h1>Manage my tags</h1>
          <div className="page__cta">
            <Button onClick={handleAddTagClick}>
              <FaPlus className="fa-plus" /> Add a tag
            </Button>
            <Button
              onClick={handleAssignAccessCtaClick}
              disabled={
                selectedAccessTagIds.length <= 0 ||
                selectedAccessTagIds.length !== selectedTagIds.length
              }
            >
              <FaUserLock className="fa-user-lock" /> Assign access tags
            </Button>
            <Button
              onClick={handleDeleteSelectedClick}
              disabled={selectedTagIds.length <= 0}
            >
              <FaTrashAlt className="fa-trash-alt" /> Delete
            </Button>
          </div>
        </PageHeader>
        <PageBody>
          <PageContent>
            <p>
              Easily group your documents & tasks
              <sup>*</sup> using <strong>tags</strong>, making them easier to
              find & manage. To begin, simply:
            </p>
            <ol>
              <li>
                Add a tag to represent your clients, projects, locations, use
                cases, or anything else you'd like to group your documents &
                tasks by
              </li>
              <li>Assign relevant tags to your desired documents and tasks</li>
              <li>
                Search or filter your documents or tasks
                <sup>*</sup> on assigned tags to find the desired items
              </li>
            </ol>
            <p>
              Tags can also be specified to provide a flexible & powerful way to
              control access to specific documents & tasks
              <sup>*</sup>. Use <strong>access tags</strong> to segregate access
              to sensitive, project-centric, or client-centric information; or
              create dynamic task
              <sup>*</sup> queues for each reviewer to work on based on skillset
              or location. To use
              <strong>access tags</strong> simply select your tag and:
            </p>
            <ol>
              <li>
                Click on the <strong>access tags</strong> icon next to the tag
              </li>
              <li>
                Assign the access tag to users that should have permission to
                view the respective documents & tasks that have also been
                assigned with the same tag
              </li>
            </ol>
            <p className="small">
              <sup>*</sup>
              Tasks are available in Sypht Validate for document labelling or
              human review. Contact us to learn more.
            </p>
          </PageContent>
          {tags && tags.length === 0 ? (
            <EmptyTable>
              <p>Get started with your first tag</p>
              <Button onClick={handleAddTagClick}>
                <FaPlus className="fa-plus" /> Add a tag
              </Button>
            </EmptyTable>
          ) : (
            <DataTable
              data={tags}
              columns={[
                new CheckboxColumn({
                  id: 'tags_list',
                  width: 48,
                  selected: selectedTagIds,
                  isSelectAll: state.isSelectAll,
                  onClick: handleToggleRow,
                  onClickAll: handleToggleAll,
                }),
                new IconColumn({
                  Header: () => (
                    <FaUserLock
                      className="fa-user-lock"
                      alt="Has access control"
                    />
                  ),
                  Cell: ({ original: tag }) => {
                    return (
                      <button
                        type="button"
                        className={classNames('rt-icon-btn', '-access-tag', {
                          '-true': tag.isAccessTag,
                        })}
                        tabIndex="-1"
                        disabled={tag.isNew}
                        data-tooltip-content={
                          tag.isAccessTag
                            ? `This is an 'access tag'. Documents<br>
                                 & tasks with this tag can only be<br>
                                 viewed by users that are also<br>
                                 assigned with this tag.`
                            : `Turn this tag into an 'access tag'.<br>
                                 This will limit access to documents<br>
                                 & tasks with this tag only to users<br>
                                 that are also assigned with this tag.`
                        }
                        onClick={e => handleAccessIconClick(e, tag)}
                      >
                        <FaUserLock className="fa-user-lock" alt="Access tag" />
                      </button>
                    );
                  },
                }),
                new InputColumn({
                  Header: 'Name',
                  accessor: 'name',
                  Cell: ({ original: tag, viewIndex }) => {
                    return (
                      <input
                        type="text"
                        className={classNames('rt-input', {
                          '-error': valErrorTagIds.includes(tag.id),
                        })}
                        defaultValue={tag.name}
                        placeholder={
                          valErrorTagIds.includes(tag.id)
                            ? 'Required'
                            : undefined
                        }
                        title={tag.name}
                        required
                        data-column="name"
                        onBlur={e => handleInputBlur(e, tag)}
                        key={`name|${tag.id}`}
                        ref={viewIndex === 0 ? firstRef : undefined}
                      />
                    );
                  },
                  sortable: true,
                }),
                new IconColumn({
                  Header: () => <FaCopy alt="Documents" />,
                  Cell: ({ original: o }) => {
                    return <>{o.numberOfDocuments || <>&mdash;</>}</>;
                  },
                }),
                new IconColumn({
                  Header: () => <FaTasks alt="Tasks" />,
                  Cell: ({ original: o }) => {
                    return <>{o.numberOfTasks || <>&mdash;</>}</>;
                  },
                }),
                new IconColumn({
                  Header: () => <FaUser alt="Users" />,
                  Cell: ({ original: tag }) => {
                    return <>{tag.numberOfUsers || <>&mdash;</>}</>;
                  },
                }),
                new InputColumn({
                  Header: 'Description',
                  Cell: ({ original: tag }) => {
                    return (
                      <TextArea
                        className="rt-input"
                        defaultValue={tag.description}
                        placeholder="Add a description"
                        data-column="description"
                        onBlur={e => handleInputBlur(e, tag)}
                        key={`desc|${tag.id}`}
                        rows="1"
                      >
                        {tag.description}
                      </TextArea>
                    );
                  },
                }),
                new DeleteColumn({
                  onClick: (e, tag) => handleDeleteClick(e, tag),
                }),
              ]}
              defaultPageSize={MAX_TAGS}
              showPagination
              isLoading={tags === null}
              onFetchData={handleFetchData}
              setRowProps={(table, tag) => ({
                className: classNames({
                  '-selected': selectedTagIds.includes(tag.id),
                  '-active': activeTagId === tag.id,
                }),
              })}
              tooltip
            />
          )}
          <ReactTooltip className="tooltip" place="bottom" variant="dark" />
        </PageBody>
      </PageInner>
    </Page>
  );
};

Tags.propTypes = {
  companyId: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.object),
  dispatch: PropTypes.shape({
    createTag: PropTypes.func,
    updateTag: PropTypes.func,
    deleteTags: PropTypes.func,
    listTags: PropTypes.func,
    updateTagUsers: PropTypes.func,
  }),
};

Tags.defaultProps = {
  companyId: null,
  tags: null,
  dispatch: {
    createTag: () => {},
    updateTag: () => {},
    deleteTags: () => {},
    listTags: () => {},
    updateTagUsers: () => {},
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
