import { createStore, compose } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import { persistStore } from 'redux-persist';

import middleware from './middleware';
import reducers from './reducers';

const initialState = {};

export default (state = initialState, history) => {
  let composeMiddleware;
  if (process.env.NODE_ENV === 'production') {
    composeMiddleware = compose(middleware(history));
  } else {
    // Set trace to true to trace actions.
    composeMiddleware = composeWithDevTools({ trace: false, traceLimit: 25 })(
      middleware(history),
    );
  }
  const store = createStore(reducers(history), state, composeMiddleware);
  const persistor = persistStore(store);
  return { store, persistor };
};
