import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import 'filepond/dist/filepond.min.css';
import './UploadDocsModal.scss';
import { addNotification } from '../../reducers/notifications';
import { requestDocProcessed } from '../../reducers/upload';
import { addTask } from '../../reducers/tasks/actions';
import { requestIngest } from '../../reducers/workflow/actions';
import { ingestAndExtractDoc } from '../../reducers/docs';

import { STYLE } from '../UI/Notification/Notification';
import ConnectedDocumentUploadModal from '../../library/organisms/DocumentUploadModal/DocumentUploadModal';

const fileUploadServiceUrl =
  process.env.FILEUPLOAD_SERVICE_HOST_NAME || process.env.APP_API_HOST_NAME;

const mapStateToProps = state => {
  const { auth, company } = state;

  const user = auth.user && auth.user.data ? auth.user.data : null;
  const isSmartSplitAuto =
    user && user.company && user.company.settings
      ? user.company.settings.isSmartSplitAuto
      : false;

  return {
    isSmartSplitAuto,
    region: company && company.data ? company.data.region : '',
    companyId: company && company.data ? company.data.id : '',
    token: auth.accessToken,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      notifyDocsAdded: (timeout, message) =>
        dispatch(
          addNotification({
            style: STYLE.CONFIRMATION,
            message,
            timeout,
          }),
        ),
      addTask: t => dispatch(addTask(t)),
      requestDocProcessed: data => dispatch(requestDocProcessed(data)),
      requestIngest: (fileId, region, isManualSplit, isSmartSplit) =>
        dispatch(requestIngest(fileId, region, isManualSplit, isSmartSplit)),
      requestIngestAndExtract: (docId, region, products, companyId) =>
        dispatch(ingestAndExtractDoc(docId, region, products, companyId)),
    },
  };
};

const UploadDocsModal = ({
  token,
  dispatch,
  isOpen,
  onCloseModal,
  onCancelModal,
  isSmartSplitAuto,
  region,
  companyId,
}) => {
  const [hasFilesSelected, setHasFilesSelected] = useState(false);
  const [selectedFileCount, setSelectedFileCount] = useState(0);

  const notificationMessage = (isSmartSplit, hasSelectedProducts) => {
    const docString = selectedFileCount > 1 ? 'documents.' : 'document.';
    const prefix = `Uploaded ${selectedFileCount} new ${docString}`;
    if (isSmartSplit) {
      return `${prefix} Auto split is in progress...`;
    }
    if (hasSelectedProducts) {
      return `${prefix} Auto extraction is in progress...`;
    }
    return prefix;
  };

  const onFilesAdded = files => {
    setHasFilesSelected(files.length > 0);
    setSelectedFileCount(files.length);
  };

  const notificationTimeout = isSmartSplit => {
    return isSmartSplit ? 120000 : undefined;
  };

  const onClose = (isSmartSplit, hasSelectedProducts) => {
    if (hasFilesSelected) {
      dispatch.notifyDocsAdded(
        notificationTimeout(isSmartSplit),
        notificationMessage(isSmartSplit, hasSelectedProducts),
      );
      onCloseModal('isUploadModalOpen', true);
    }
  };

  const onCancel = () => {
    onCancelModal('isUploadModalOpen');
  };

  const processFile = (
    file,
    load,
    error,
    progress,
    isSmartSplit,
    selectedProducts,
  ) => {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    const bodyFormData = new FormData();
    bodyFormData.append('file', file, file.name);

    return axios({
      method: 'post',
      url: `${fileUploadServiceUrl}/fileupload/v2/multipart`,
      data: bodyFormData,
      headers: {
        Authorization: `Bearer ${token}`,
        // eslint-disable-next-line no-underscore-dangle
        'Content-Type': `multipart/form-data; boundary=${bodyFormData._boundary}`,
      },
      redirect: 'follow',
      cancelToken: source.token,
      onUploadProgress: e => {
        progress(e.lengthComputable, e.loaded, e.total);
      },
    })
      .then(response => {
        const docId = response.data.fileId;
        dispatch.requestDocProcessed({ docId });
        load(response);
        const products = selectedProducts.map(f => f.value);

        if (!isSmartSplit && products.length > 0) {
          dispatch.requestIngestAndExtract(docId, region, products, companyId);
        } else {
          dispatch.requestIngest(docId, region, false, isSmartSplit);
        }
      })
      .catch(err => {
        error(err);
      });
  };

  return (
    <ConnectedDocumentUploadModal
      defaultSmartSplitEnabled={isSmartSplitAuto}
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onCancel}
      onProcessFile={processFile}
      onFilesAdded={onFilesAdded}
    />
  );
};

UploadDocsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onCancelModal: PropTypes.func.isRequired,
  dispatch: PropTypes.shape({
    notifyDocsAdded: PropTypes.func,
    requestDocProcessed: PropTypes.func,
    requestIngest: PropTypes.func,
    addTask: PropTypes.func,
    requestIngestAndExtract: PropTypes.func,
  }),
  token: PropTypes.string,
  region: PropTypes.string.isRequired,
  isSmartSplitAuto: PropTypes.bool,
  companyId: PropTypes.string.isRequired,
};

UploadDocsModal.defaultProps = {
  token: null,
  dispatch: {
    notifyDocsAdded: () => {},
    requestDocProcessed: () => {},
    addTask: () => {},
    requestIngestAndExtract: () => {},
  },
  isSmartSplitAuto: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocsModal);
