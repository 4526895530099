import * as utils from './utils';

const apiBaseUrl = process.env.APP_API_HOST_NAME;

class MailboxService {
  async getMailboxAddress(accessToken, companyId) {
    return utils.getData(`${apiBaseUrl}/app/company/${companyId}/mailbox`, {
      accessToken,
    });
  }

  async createMailboxIntegration(accessToken, companyId, data) {
    return utils.postData(`${apiBaseUrl}/app/company/${companyId}/mailboxes`, {
      accessToken,
      data,
    });
  }

  async getMailboxIntegrations(accessToken, companyId) {
    return utils.getData(`${apiBaseUrl}/app/company/${companyId}/mailboxes`, {
      accessToken,
    });
  }

  async deleteMailboxIntegration(accessToken, companyId, id) {
    return utils.deleteData(
      `${apiBaseUrl}/app/company/${companyId}/mailboxes/${id}`,
      {
        accessToken,
      },
    );
  }
}

export default new MailboxService();
