import React from 'react';
import PropTypes from 'prop-types';
import { mapToPriority } from '../../../../common/helpers';

const PriorityCell = ({ row }) => {
  const priority = row?.original?.priority || 0;
  return <span>{mapToPriority(priority)}</span>;
};

PriorityCell.propTypes = {
  row: PropTypes.object.isRequired,
};

export default PriorityCell;
