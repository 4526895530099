import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import './Toolbar.scss';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { connect } from 'react-redux';
import logo from '../../assets/sypht-by-paymerang.svg';
import ToolbarButtons from './ToolbarButtons';
import { LinkButton } from '../../library/atoms/Buttons';
import TextLink from '../UI/TextLink/TextLink';

const mapStateToProps = state => {
  const user =
    state.auth.user && state.auth.user.data ? state.auth.user.data : null;
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user,
    company: user ? user.company : null,
  };
};

const Toolbar = ({ isAuthenticated, user }) => {
  return (
    <nav className="toolbar">
      <div className="toolbar__content">
        <Link to="/">
          <div className="logo">
            <img src={logo} alt="Logo" />
          </div>
        </Link>
        {isAuthenticated && user ? (
          <ToolbarButtons />
        ) : (
          <div className="login-controls">
            <TextLink path="/" className="login-controls__sign-in">
              Sign in
            </TextLink>
            <LinkButton
              className="login-controls__sign-up"
              href="/register"
              size="sm"
              onClick={() => {}}
            >
              Sign up
            </LinkButton>
          </div>
        )}
      </div>
      <ReactTooltip id="toolbar-tooltip" variant="dark" />
    </nav>
  );
};

Toolbar.propTypes = {
  isAuthenticated: PropTypes.bool,
  user: PropTypes.shape({
    data: PropTypes.shape({}),
  }),
};

Toolbar.defaultProps = {
  isAuthenticated: false,
  user: null,
};

export default connect(mapStateToProps)(withRouter(Toolbar));
