import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { listTags } from '../../../components/Tags/store/actions';
import { fetchUsers } from '../../../reducers/users/actions';

import Page, {
  PageBody,
  PageHeader,
  PageInner,
} from '../../../components/Page';
import { DynamicTable, StaticTable } from '../../../library/organisms/Table';

import tagColumns from './columns';
import './_tags.scss';

const mapStateToProps = state => {
  const { auth, users, manageTags } = state;
  const { results } = manageTags.data || {};
  return {
    companyId: auth.user.data ? auth.user.data.companyId : null,
    tags: results,
    users: users.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      listTags: (companyId, params) =>
        dispatch(
          listTags(companyId, {
            ...params,
            includeCounts: true,
          }),
        ),
      retrieveUsers: companyId =>
        dispatch(fetchUsers(companyId, { includeTags: true })),
    },
  };
};

const Tags = ({ companyId, tags, users, dispatch }) => {
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    if (!companyId) return;
    if (!users) {
      dispatch.retrieveUsers(companyId);
    }
    if (!tags) {
      dispatch.listTags(companyId, {
        sortColumn: 'created',
        sortDirection: 'desc',
      });
    }
  }, [companyId]);

  const handleDeleteRow = (id, row, columnId, rowIndex) => {
    // todo: remove before release
    console.info('handleDeleteRow:', { id, row, columnId, rowIndex });
  };

  const handleInputBlur = (value, row, columnId, rowIndex) => {
    // todo: remove before release
    console.info('handleInputBlur:', { value, row, columnId, rowIndex });
  };

  // rows: Array<{ id, original, rowIndex }>
  const handleRowSelect = rows => {
    const selectedRows = rows.map(r => r.id);
    // todo: remove before release
    console.info('selectedRows:', selectedRows);
    setSelectedTags(selectedRows);
  };

  const columns = useMemo(() => tagColumns, []);

  return (
    <Page className="tags">
      <PageInner>
        <PageHeader className="rules__header">
          <h1>Manage my tags</h1>
        </PageHeader>
        <PageBody>
          <StaticTable
            className="table--th-borders"
            columns={columns}
            data={tags}
            handlers={{
              onDeleteRow: handleDeleteRow,
              onInputBlur: handleInputBlur,
              onRowSelect: handleRowSelect,
            }}
            onRowSelect={handleRowSelect}
          />
        </PageBody>
      </PageInner>
    </Page>
  );
};

Tags.propTypes = {
  companyId: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.object),
  users: PropTypes.arrayOf(PropTypes.object),
  dispatch: PropTypes.shape({
    listTags: PropTypes.func,
    retrieveUsers: PropTypes.func,
  }),
};

Tags.defaultProps = {
  companyId: null,
  tags: null,
  users: null,
  dispatch: {
    listTags: () => {},
    retrieveUsers: () => {},
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
