import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import ReactLoading from 'react-loading';
import './_lineChart.scss';
import { generateLegend } from '../../../common/helpers/generateLegend';

const getConfig = (xLabel, yLabel) => {
  return {
    legend: {
      display: false,
      labels: {
        color: '#747472',
        font: {
          size: 14,
          family: 'Roboto',
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            fontSize: 14,
            labelString: xLabel,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: '#f1f1f1',
          },
          scaleLabel: {
            display: true,
            fontSize: 14,
            labelString: yLabel,
          },
          stacked: true,
          ticks: {
            beginAtZero: true,
            precision: 0,
          },
        },
      ],
    },
  };
};

const LineChart = ({ id, data, title, xLabel, yLabel, isLoading }) => {
  const chartRef = useRef();
  const labels = (data?.Created?.labels || []).map(l => l.substr(0, 5));
  const colors = {
    Created: '#00b6ec',
    'In progress': '#c78fbf',
    Completed: '#90ca6f',
  };
  const datasets = Object.keys(data || {}).map(k => ({
    label: k,
    data: data && k ? Object.values(data[k].usage) : [],
    fill: false,
    borderColor: colors[k],
    borderWidth: 1,
    pointBackgroundColor: colors[k],
    backgroundColor: colors[k],
    tension: 0.1,
  }));

  generateLegend({
    chartRef,
    chartId: 'task__analytics-legend',
    data,
  });

  return (
    <div className="line-chart">
      {title && (
        <div className="line-chart__header">
          <h2>{title}</h2>
        </div>
      )}
      <div className="line-chart__body">
        {!isLoading && data ? (
          <Line
            id={id}
            height={400}
            data={{
              labels,
              datasets,
            }}
            options={getConfig(
              `${xLabel} (${labels[0]} - ${labels[labels.length - 1]})`,
              yLabel,
            )}
            ref={chartRef}
          />
        ) : (
          <ReactLoading color="#3C3C3B" type="spin" height={38} width={38} />
        )}
      </div>
    </div>
  );
};

LineChart.propTypes = {
  id: PropTypes.string,
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    usage: PropTypes.object,
  }),
  title: PropTypes.string,
  xLabel: PropTypes.string,
  yLabel: PropTypes.string,
  isLoading: PropTypes.bool,
};

LineChart.defaultProps = {
  id: null,
  data: {
    labels: null,
    usage: null,
  },
  title: null,
  xLabel: null,
  yLabel: null,
  isLoading: false,
};

export default LineChart;
