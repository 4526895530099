import { REQUEST_DOC, RECEIVE_DOC, RECEIVE_ERROR } from './actions';

const initialState = {
  docs: {
    loading: true,
  },
};

const signalsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_DOC: {
      return {
        ...state,
        docs: {
          ...state.docs,
          loading: true,
        },
      };
    }

    case RECEIVE_DOC: {
      const { data } = payload;
      return {
        ...state,
        docs: {
          ...state.docs,
          [data.id]: {
            ...data,
            receivedAt: Date.now(),
            isRefresh: false,
          },
          loading: false,
        },
      };
    }

    case RECEIVE_ERROR: {
      return {
        docs: {
          ...state.docs,
          loading: false,
        },
        products: {
          ...state.products,
          loading: false,
        },
      };
    }

    default:
      return state;
  }
};

export default signalsReducer;
