import * as utils from './utils';

const apiBaseUrl = process.env.APP_API_HOST_NAME;

export const RULES = {
  invoices: [
    {
      id: 'ce74d7b0-91b4-4f85-ac6b-d794d6ec674b',
      productId: 'invoices',
      fields: [
        'sypht.invoice.total',
        'sypht.invoice.purchaseOrderNo',
        'sypht.bank.accountNo',
        'sypht.bank.bsb',
        'sypht.bpay.billerCode',
        'sypht.bpay.crn',
      ],
      rule: {
        type: 'field_accuracy_below_threshold',
        minimum: 90,
      },
      order: 1,
      isActive: true,
    },
    {
      id: '334f6b22-fe98-477f-87a1-55ca7303a7e8',
      productId: 'invoices',
      fields: ['sypht.invoice.total', 'sypht.invoice.purchaseOrderNo'],
      rule: {
        type: 'field_is_missing',
      },
      order: 2,
      isActive: true,
    },
    {
      id: 'ba611532-3772-4c4e-aed7-2dcd5d553ad7',
      productId: 'invoices',
      fields: ['sypht.invoice.amountDue', 'sypht.invoice.total'],
      rule: {
        type: 'field_value_out_of_range',
        minimum: 100,
        maximum: 10000,
      },
      order: 3,
      isActive: true,
    },
  ],
  passports: [
    {
      id: '5e871c60-aae3-4b6b-8672-2b55f09f06da',
      productId: 'passports',
      fields: [
        'sypht.passport.dateOfBirth',
        'sypht.passport.expiryDate',
        'sypht.passport.givenNames',
        'sypht.passport.nationality',
        'sypht.passport.number',
        'sypht.passport.surname',
      ],
      rule: {
        type: 'field_is_missing',
      },
      order: 1,
      isActive: false,
    },
    {
      id: 'b5a968cb-3d93-41ae-b010-7d5d703aa835',
      productId: 'passports',
      fields: ['sypht.passport.validityScore'],
      rule: {
        type: 'field_accuracy_below_threshold',
        threshold: 90,
      },
      order: 2,
      isActive: true,
    },
  ],
};

// RULE = {
//   id: undefined,
//   productId: 'productId',
//   fields: [],
//   rule: {},
//   order: 0,
//   isActive: true,
// };

class ValidateRulesService {
  async createRule(accessToken, companyId, productId, rule) {
    return utils.postData(`${apiBaseUrl}/app/validate/rules`, {
      accessToken,
      data: {
        companyId,
        productId,
        rule,
      },
    });
  }

  async retrieveRule(accessToken, ruleId) {
    return utils.getData(`${apiBaseUrl}/app/validate/rules/${ruleId}`, {
      accessToken,
    });
  }

  async updateRule(accessToken, ruleId, productId, ruleType, rule) {
    return utils.putData(`${apiBaseUrl}/app/validate/rules/${ruleId}`, {
      accessToken,
      data: {
        productId,
        ruleType,
        rule,
      },
    });
  }

  async deleteRule(accessToken, ruleId, ruleType) {
    return utils.deleteData(`${apiBaseUrl}/app/validate/rules/${ruleId}`, {
      accessToken,
      data: {
        ruleType,
      },
    });
  }

  async listProductRules(accessToken) {
    return utils.getData(`${apiBaseUrl}/app/validate/rules/products`, {
      accessToken,
    });
  }

  async createProductRules(accessToken, companyId, productId, rules) {
    return utils.postData(
      `${apiBaseUrl}/app/validate/rules/products/${productId}`,
      {
        accessToken,
        data: {
          companyId,
          rules,
        },
        productId,
      },
    );
  }

  async updateProductRules(accessToken, companyId, productId, rules) {
    return utils.postData(
      `${apiBaseUrl}/app/validate/rules/products/${productId}`,
      {
        accessToken,
        data: {
          companyId,
          rules,
        },
      },
    );
  }
}

export default new ValidateRulesService();
