import React, { useEffect, useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import DateSelector from '../../../../UI/DateSelector/DateSelector';
import Modal from '../../../../UI/Modal/Modal';
import PricingTable from './PricingTable';
import * as pm from './PricingModel';
import Form, {
  TextInput,
  Checkbox,
  SelectInput,
  useForm,
  validatorType,
} from '../../../../Form';
import { editSubscription } from '../../../../../reducers/admin/actions';

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      editSubscription: (companyId, details) =>
        dispatch(editSubscription(companyId, details)),
    },
  };
};

const mapStateToProps = state => {
  const { products } = state;
  return {
    products: products.data,
  };
};

const getInitialState = subscription => {
  const initialState = {
    subscription: {
      value: subscription.name,
    },
    endDate: {
      required: false,
      value: subscription.endDate
        ? moment(subscription.endDate).toDate()
        : null,
    },
    isTrial: {
      checked: subscription.isTrial,
    },
    freeThreshold: {
      validators: [validatorType.Number],
      value: subscription.freeThreshold || '',
      required: false,
    },
    licenseCount: {
      validators: [validatorType.Number],
      value: subscription.licenseCount || '',
      required: false,
    },
    billingUnit: {
      value: subscription.billingUnit || pm.DEFAULT_BILLING_UNIT,
      required: false,
    },
  };
  return initialState;
};

const EditSubscription = ({
  subscription,
  products,
  isOpen,
  onClose,
  companyId,
  dispatch,
}) => {
  const [form] = useForm(getInitialState(subscription));
  const [usagePricing, setUsagePricing] = useState(subscription.usagePricing);

  const { fields, errors } = form;
  const hasErrors = Object.keys(errors || {}).length > 0;
  const defaultSubscription = subscription.endDate
    ? moment(subscription.endDate).toDate()
    : null;
  const product = products.find(p => p.productId === subscription.productId);
  const handleDateChange = e => {
    form.handleChange({
      target: {
        name: 'endDate',
        value: e,
        type: 'text',
      },
    });
  };

  useEffect(() => {
    if (!fields.isTrial.value) {
      form.handleChange({
        target: {
          name: 'endDate',
          value: null,
          type: 'text',
        },
      });
    } else {
      form.handleChange({
        target: {
          name: 'endDate',
          value: defaultSubscription,
          type: 'text',
        },
      });
    }
  }, [fields.isTrial.value]);

  const handleClose = () => {
    form.reset();
    onClose();
  };

  const onSubmit = () => {
    const details = {
      productId: subscription.productId,
      endDate: fields.endDate.value,
      isTrial: fields.isTrial.value,
      freeThreshold:
        // eslint-disable-next-line no-nested-ternary
        product.productType === 'capture'
          ? fields.freeThreshold.value === ''
            ? null
            : parseInt(fields.freeThreshold.value, 10)
          : undefined,
      licenseCount:
        // eslint-disable-next-line no-nested-ternary
        product.productType === 'platform'
          ? fields.licenseCount.value === ''
            ? null
            : parseInt(fields.licenseCount.value, 10)
          : undefined,
      billingUnit: fields.billingUnit.value,
      usagePricing,
    };
    dispatch.editSubscription(companyId, details);
    handleClose();
  };

  const handlePricingTiersChange = tiers => {
    setUsagePricing(state => ({
      ...state,
      tiers,
    }));
  };

  return (
    <Modal
      modalLabel="Edit Subscription"
      isOpen={isOpen}
      className="modal-add-subscription"
      confirmLabel="Confirm"
      cancelLabel="Cancel"
      disabledConfirm={!form.isValid || hasErrors}
      onCancel={handleClose}
      onConfirm={onSubmit}
      onClose={handleClose}
    >
      <Form className="modal-add-subscription__content" onSubmit={onSubmit}>
        <TextInput
          label="Subscription"
          name="subscription"
          errors={errors}
          value={subscription.name}
          disabled
        />
        <Checkbox
          id="isTrial"
          className="modal-add-subscription__content-row"
          name="isTrial"
          label="Trial Subscription"
          defaultChecked={fields.isTrial.value}
          onChange={form.handleChange}
          disabled={!subscription.isTrial}
        />
        <DateSelector
          selected={fields.endDate.value}
          label="End Date"
          // className="modal-add-subscription__content-row"
          name="endDate"
          onChange={handleDateChange}
          placeholder="No end date"
          disabled={!fields.isTrial.value}
          minDate={moment().add(1, 'day').toDate()}
        />
        {product.productType === 'capture' && (
          <TextInput
            label="Free Threshold"
            name="freeThreshold"
            placeholder="E.g. 500"
            errors={errors}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={
              !fields.isTrial.value ? 'N/A' : fields.freeThreshold.value || ''
            }
            disabled={!fields.isTrial.value}
          />
        )}
        {product.productType === 'platform' && (
          <TextInput
            label="License Count"
            name="licenseCount"
            placeholder="E.g. 10"
            errors={errors}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={fields.licenseCount.value || ''}
          />
        )}

        <SelectInput
          disabled
          label="Billing Unit"
          placeholder="Billing unit"
          name="billingUnit"
          value={fields.billingUnit.value}
          errors={errors}
          onBlur={form.handleBlur}
          onChange={form.handleChange}
          options={[
            { value: pm.BU_DOCUMENT, label: 'document' },
            { value: pm.BU_PAGE, label: 'page' },
          ]}
        />

        <div className="form__input-label">Pricing</div>
        <PricingTable
          tiers={usagePricing?.tiers}
          editable
          onChange={handlePricingTiersChange}
        />
      </Form>
      <ReactTooltip />
    </Modal>
  );
};

EditSubscription.propTypes = {
  subscription: PropTypes.shape({
    productId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    endDate: PropTypes.string,
    isTrial: PropTypes.bool.isRequired,
    freeThreshold: PropTypes.number,
    licenseCount: PropTypes.number,
    usagePricing: PropTypes.shape({
      tiers: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      productType: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
  dispatch: PropTypes.shape({
    editSubscription: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSubscription);
