import * as utils from './utils';

const apiBaseUrl = process.env.APP_API_HOST_NAME;

class TagService {
  getCompanyTags = (accessToken, companyId, data) => {
    return utils.getData(`${apiBaseUrl}/app/company/${companyId}/tags`, {
      accessToken,
      ...data,
    });
  };

  createCompanyTag = (accessToken, companyId, data) => {
    return utils.postData(`${apiBaseUrl}/app/company/${companyId}/tags`, {
      accessToken,
      data,
    });
  };

  updateCompanyTag = (accessToken, companyId, name, data) => {
    return utils
      .putData(`${apiBaseUrl}/app/company/${companyId}/tags/${name}`, {
        accessToken,
        data,
      })
      .catch(err => {
        return Promise.reject(err);
      });
  };

  deleteCompanyTags = (accessToken, companyId, tagIds) => {
    // prettier-ignore
    return utils
      .deleteData(`${apiBaseUrl}/app/company/${companyId}/tags?ids=${tagIds.join(',')}`, {
        accessToken,
      })
      .catch(err => {
        return Promise.reject(err);
      });
  };

  setDocumentTags = (accessToken, docId, companyId, data) => {
    return utils.putData(
      `${apiBaseUrl}/app/company/${companyId}/documents/${docId}/tags`,
      {
        accessToken,
        data,
      },
    );
  };

  batchUpdateDocumentTags = (accessToken, companyId, data) => {
    return utils.postData(
      `${apiBaseUrl}/app/company/${companyId}/documents/tags/batch`,
      {
        accessToken,
        data,
      },
    );
  };

  getDocumentTags = (accessToken, docId, companyId, params) => {
    return utils.getData(
      `${apiBaseUrl}/app/company/${companyId}/documents/${docId}/tags`,
      {
        accessToken,
        ...params,
      },
    );
  };

  updateUsersTags = (accessToken, companyId, data) => {
    return utils.postData(
      `${apiBaseUrl}/app/company/${companyId}/users/tags/batch`,
      {
        accessToken,
        data,
      },
    );
  };

  updateTaskTags = (accessToken, companyId, data) => {
    return utils.postData(
      `${apiBaseUrl}/app/company/${companyId}/tasks/tags/batch`,
      {
        accessToken,
        data,
      },
    );
  };
}

export default new TagService();
