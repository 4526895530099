import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import loadHubspot from '../../common/helpers/loadHubspot';
import { registerUser } from '../../reducers/user/actions';

import PageLoading from '../Page/PageLoading';
import RegisterForm from './components/RegisterForm';
import './Register.scss';
import { PageHelmet } from '../Page';
import { DEFAULT_LANDING_ROUTE } from '../../common/constants';

const mapStateToProps = state => {
  const { user } = state;
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isLoading: user.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      registerUser: user => dispatch(registerUser(user)),
    },
  };
};

const Register = ({ isLoading, dispatch, isAuthenticated }) => {
  // Inject Hubsport script on this page
  useEffect(() => {
    loadHubspot();
    window.sessionStorage.setItem('redirectTo', DEFAULT_LANDING_ROUTE);
  }, []);

  const submitForm = formFields => {
    dispatch.registerUser(formFields);
  };

  return (
    <div className="register register-container">
      <PageHelmet title="Register" />
      {isAuthenticated ? <Redirect to="/" /> : null}
      {isLoading ? (
        <PageLoading />
      ) : (
        <>
          <h2 className="register-heading">
            <span>Create an account</span>
          </h2>
          <RegisterForm handleSubmit={submitForm} />
        </>
      )}
    </div>
  );
};

Register.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  dispatch: PropTypes.shape({
    registerUser: PropTypes.func,
  }),
};

Register.defaultProps = {
  isLoading: false,
  dispatch: {
    registerUser: () => {},
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
