import { addError, removeError } from './index';

const validateNumberMin = ({ elem, errors, setErrors }, minVal) => {
  const { name: key, value } = elem;
  const message = `Must be a number greater or equal to ${minVal}.`;

  if (value.length === 0) {
    removeError(key, message, errors, setErrors);
    return {
      isValid: true,
    };
  }

  if (!/^-?[0-9]*$/i.test(value)) {
    addError(key, message, errors, setErrors);
    return {
      isValid: false,
    };
  }

  const num = parseInt(value, 10);
  if (num < minVal) {
    addError(key, message, errors, setErrors);
  }

  removeError(key, message, errors, setErrors);
  return {
    isValid: true,
  };
};

export default validateNumberMin;
