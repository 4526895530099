import React from 'react';
import PropTypes from 'prop-types';
import './DropdownList.scss';

const DropdownList = ({ children, onItemClick }) => {
  return (
    <div className="dropdown-list">
      {React.Children.map(children, child =>
        React.cloneElement(child, {
          /**
           * Intercept child onClicks and call back to parent (Dropdown
           * component) via onItemClick.
           */
          onClick: (...args) => {
            if (child.props.onClick) {
              child.props.onClick(...args);
            }
            if (onItemClick) {
              onItemClick(...args);
            }
          },
        }),
      )}
    </div>
  );
};

DropdownList.propTypes = {
  children: PropTypes.node.isRequired,
  onItemClick: PropTypes.func,
};

DropdownList.defaultProps = {
  onItemClick: undefined,
};

export default DropdownList;
