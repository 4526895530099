import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  location: state.router.location,
});

class AuthRoute extends Component {
  render() {
    const {
      isAuthenticated,
      children,
      back,
      location: { pathname, search, hash },
    } = this.props;

    return (
      <div className="auth">
        {isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: back,
              state: {
                referrer: `${pathname || ''}${search || ''}${hash || ''}`,
              },
            }}
          />
        )}
      </div>
    );
  }
}

AuthRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
  children: PropTypes.node,
  back: PropTypes.string,
  location: PropTypes.object,
};

AuthRoute.defaultProps = {
  isAuthenticated: false,
  children: null,
  back: '/',
  location: {},
};

export default connect(mapStateToProps)(AuthRoute);
