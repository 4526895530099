import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import './_range-slider.scss';

// eslint-disable-next-line react/prop-types
const CustomHandle = ({ value, dragging, index, ...handleProps }) => {
  return <Slider.Handle value={value} {...handleProps} />;
};

const setMarks = marksArr => {
  const marks = {};
  marksArr.forEach(m => {
    marks[m] = m;
  });
  return marks;
};

const RangeSlider = ({ onChange, marks, ...props }) => {
  return (
    <div className="range-slider">
      <Slider
        marks={setMarks(marks)}
        handle={CustomHandle}
        onChange={onChange}
        {...props}
      />
    </div>
  );
};

RangeSlider.propTypes = {
  marks: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
};

RangeSlider.defaultProps = {
  marks: [],
  onChange: () => {},
};

export default RangeSlider;
