import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import DateSelector from '../../../../UI/DateSelector/DateSelector';
import Modal from '../../../../UI/Modal/Modal';
import Form, {
  SelectInput,
  Checkbox,
  useForm,
  validatorType,
  TextInput,
} from '../../../../Form';
import { addSubscription } from '../../../../../reducers/admin/actions';

const mapStateToProps = state => {
  const { admin, products } = state;
  return {
    subscriptions: admin.subscriptions.data || [],
    products: products.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      addSubscription: (companyId, details) =>
        dispatch(addSubscription(companyId, details)),
    },
  };
};

const initialState = {
  productId: {
    required: true,
  },
  endDate: {
    value: moment().add(2, 'weeks').toDate(),
  },
  bypassStripe: {
    value: false,
  },
  freeThreshold: {
    validators: [validatorType.Number],
    required: false,
  },
  licenseCount: {
    validators: [validatorType.Number],
    required: false,
    value: -1,
  },
};

const AddSubscription = ({
  isOpen,
  products,
  subscriptions,
  onClose,
  dispatch,
  companyId,
}) => {
  const [form] = useForm(initialState);
  const { fields, errors } = form;
  const [unsubbed, setUnsubbed] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const hasErrors = Object.keys(errors || {}).length > 0;

  useEffect(() => {
    if (products && subscriptions) {
      const unsubbedList = products.filter(
        p => !subscriptions.find(s => s.productId === p.productId),
      );
      setUnsubbed(
        unsubbedList.map(p => ({ label: p.name, value: p.productId })),
      );
    }
  }, [subscriptions, products]);

  const handleClose = () => {
    form.reset();
    onClose();
  };

  const onSubmit = () => {
    const details = {
      productId: fields.productId.value,
      endDate: fields.endDate.value,
      isTrial: !fields.bypassStripe.value,
      freeThreshold:
        selectedProduct.productType === 'capture' && fields.freeThreshold.value
          ? parseInt(fields.freeThreshold.value, 10)
          : undefined,
      licenseCount:
        selectedProduct.productType === 'platform' && fields.licenseCount.value
          ? parseInt(fields.licenseCount.value, 10)
          : undefined,
    };
    dispatch.addSubscription(companyId, details);
    handleClose();
  };

  useEffect(() => {
    if (fields.bypassStripe.value) {
      form.handleChange({
        target: {
          name: 'endDate',
          value: null,
          type: 'text',
        },
      });
    } else {
      form.handleChange({
        target: {
          name: 'endDate',
          value: moment().add(2, 'weeks').toDate(),
          type: 'text',
        },
      });
    }
    // This needs to be disabled otherwise adding all deps causes infinite re-renders.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.bypassStripe.value]);

  useEffect(() => {
    if (Array.isArray(products)) {
      setSelectedProduct(
        products.find(p => p.productId === fields.productId.value) || null,
      );
    }
  }, [fields.productId.value, products]);

  const handleDateChange = e => {
    form.handleChange({
      target: {
        name: 'endDate',
        value: e || moment().add(2, 'weeks').toDate(),
        type: 'text',
      },
    });
  };

  return (
    <Modal
      modalLabel="Add Subscription"
      isOpen={isOpen}
      className="modal-add-subscription"
      confirmLabel="Confirm"
      cancelLabel="Cancel"
      disabledConfirm={!form.isValid || hasErrors}
      onCancel={handleClose}
      onConfirm={onSubmit}
      onClose={handleClose}
    >
      <Form className="modal-add-subscription__content" onSubmit={onSubmit}>
        <SelectInput
          className="add-subscription"
          label="Product *"
          name="productId"
          placeholder="Product"
          value={fields.productId.value}
          errors={errors}
          onBlur={form.handleBlur}
          onChange={form.handleChange}
          options={unsubbed}
          required
        />
        <Checkbox
          id="bypassStripe"
          className="modal-add-subscription__content-row"
          name="bypassStripe"
          label="Bypass Stripe - Activate Full Subscription"
          defaultChecked={fields.bypassStripe.value}
          onChange={form.handleChange}
        />
        <DateSelector
          selected={fields.endDate.value}
          label="End Date"
          // className="modal-add-subscription__content-row"
          name="endDate"
          onChange={handleDateChange}
          placeholder="No end date"
          disabled={fields.bypassStripe.value}
          minDate={moment().add(1, 'day').toDate()}
        />
        {selectedProduct && selectedProduct.productType === 'capture' && (
          <TextInput
            label="Free Threshold"
            name="freeThreshold"
            placeholder="E.g. 500"
            errors={errors}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            disabled={fields.bypassStripe.value}
            value={
              fields.bypassStripe.value
                ? 'N/A'
                : fields.freeThreshold.value || ''
            }
          />
        )}
        {selectedProduct && selectedProduct.productType === 'platform' && (
          <TextInput
            label="License Count"
            name="licenseCount"
            placeholder="E.g. 10"
            errors={errors}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={fields.licenseCount.value || ''}
            tooltip
            dataTip="License Count to apply to the subscription. -1 indicates unlimited number of licenses."
          />
        )}
      </Form>
    </Modal>
  );
};

AddSubscription.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})),
  subscriptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClose: PropTypes.func.isRequired,
  dispatch: PropTypes.shape({
    addSubscription: PropTypes.func.isRequired,
  }).isRequired,
  companyId: PropTypes.string.isRequired,
};

AddSubscription.defaultProps = {
  products: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSubscription);
