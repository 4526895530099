import React from 'react';
import PropTypes from 'prop-types';
import { FaCircle } from 'react-icons/fa';
import './confidenceIndicator.scss';

const ConfidenceIndicator = ({ tooltip, variant, tooltipId }) => {
  return (
    <div className="confidence-indicator">
      <FaCircle
        data-tooltip-id={tooltipId}
        data-tooltip-place="right"
        data-tooltip-content={tooltip}
        size={10}
        className={`confidence-indicator--${variant}`}
      />
    </div>
  );
};

ConfidenceIndicator.propTypes = {
  variant: PropTypes.oneOf(['high', 'medium', 'low']).isRequired,
  tooltip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  tooltipId: PropTypes.string,
};

ConfidenceIndicator.defaultProps = {
  tooltipId: undefined,
};

export default ConfidenceIndicator;
