import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactLoading from 'react-loading';
import Card, {
  CardHeader,
  CardContent,
  CardTooltip,
} from '../../../../UI/Card';

const mapStateToProps = state => {
  const { tasks } = state;
  return {
    numTasks: tasks?.data?.numTasks,
    isLoading: tasks.loading,
  };
};

const TotalTasksPanel = ({ numTasks, isLoading }) => {
  return (
    <Card>
      <CardHeader>Total tasks created</CardHeader>
      <CardContent>
        {isLoading ? (
          <ReactLoading color="#3C3C3B" type="spin" height={38} width={38} />
        ) : (
          <span id="num_total_tasks">{numTasks}</span>
        )}
      </CardContent>
      <CardTooltip tooltipId="task-company-tooltip">
        Number of tasks generated manually or as a result of meeting one or more
        Task Generation Rules
      </CardTooltip>
    </Card>
  );
};

TotalTasksPanel.propTypes = {
  numTasks: PropTypes.number,
  isLoading: PropTypes.bool,
};

TotalTasksPanel.defaultProps = {
  numTasks: null,
  isLoading: false,
};

export default connect(mapStateToProps)(TotalTasksPanel);
