import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FaEdit, FaEllipsisV, FaTrashAlt } from 'react-icons/fa';

const handleInputEvents = eventHandler => {
  document.addEventListener('click', eventHandler, true);
  document.addEventListener('touchstart', eventHandler, true);
};

const cleanInputEvents = eventHandler => {
  document.removeEventListener('click', eventHandler, true);
  document.removeEventListener('touchstart', eventHandler, true);
};

const SubscriptionMenu = ({ onEditClick, onDeleteClick }) => {
  const [isOpen, setOpen] = useState(false);
  const menuRef = useRef(null);

  const closeDialog = () => {
    // note: potentially use e.target and check if contains .class
    setTimeout(() => {
      setOpen(false);
    }, 0);
  };

  const handleMoreButtonClick = () => {
    setOpen(o => !o);
  };

  useEffect(() => {
    if (isOpen) {
      handleInputEvents(closeDialog);
    }

    // clean up
    return () => {
      cleanInputEvents(closeDialog);
    };
  }, [isOpen]);

  return (
    <>
      <button
        className="subscription__more-button"
        type="button"
        onClick={handleMoreButtonClick}
      >
        <FaEllipsisV />
      </button>
      {!isOpen ? null : (
        <nav className="subscription-menu" ref={menuRef}>
          <h4 className="subscription-menu__heading">More options</h4>
          <ul className="subscription-menu__items">
            <li className="subscription-menu__item">
              <label
                className="subscription-menu__label"
                htmlFor="subscription_edit_button"
              >
                <button
                  id="subscription_edit_button"
                  type="button"
                  className="subscription-menu__button"
                  onClick={onEditClick}
                >
                  <span className="subscription-menu__button-text">
                    Edit subscription
                  </span>
                  <FaEdit className="edit-icon" />
                </button>
              </label>
            </li>
            {/**
             * To-Do: Cancel Subscription
             */}
            {/* <li className="subscription-menu__item">
              <label
                className="subscription-menu__label"
                htmlFor="subscription_delete_button"
              >
                <button
                  id="subscription_delete_button"
                  type="button"
                  className="subscription-menu__button"
                  onClick={handleDeleteClick}
                >
                  <span className="subscription-menu__button-text">
                    Cancel subscription
                  </span>
                  <FaTrashAlt />
                </button>
              </label>
            </li> */}
          </ul>
        </nav>
      )}
    </>
  );
};

SubscriptionMenu.propTypes = {
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func,
};

SubscriptionMenu.defaultProps = {
  onDeleteClick: () => {},
};

export default SubscriptionMenu;
