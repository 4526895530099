import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { fetchCountries } from '../../../../reducers/lookups/actions';
import { updateCompany } from '../../../../reducers/company/actions';
import {
  clearPaymentResult,
  savePaymentMethod,
  subscribePaid,
  upgradeTrial,
} from '../../../../reducers/subscriptions/actions';

import Modal from '../../../UI/Modal/Modal';
import CheckoutStep1 from './CheckoutStep1';
import CheckoutStep2 from './CheckoutStep2';
import CheckoutStep3 from './CheckoutStep3';
import './CheckoutModal.scss';

const mapStateToProps = (state, props) => {
  const { auth, lookups, subscriptions, user, company } = state;
  const { countries } = lookups;

  return {
    company: company && company.data ? company.data : null,
    countries: countries && countries.data ? countries.data : null,
    user: auth.user && auth.user.data ? auth.user.data : null,
    paymentMethods: company && company.data ? company.data.paymentMethods : [],
    paymentResult: subscriptions.paymentResult,
    isLoading: subscriptions.sending || false,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      fetchCountries: () => dispatch(fetchCountries()),
      clearPaymentResult: () => dispatch(clearPaymentResult()),
      savePaymentMethod: paymentMethod =>
        dispatch(savePaymentMethod(paymentMethod)),
      subscribePaid: (productId, paymentMethodId) =>
        dispatch(subscribePaid(productId, paymentMethodId)),
      updateCompany: (companyId, companyData) =>
        dispatch(updateCompany(companyId, companyData, false)),
      upgradeTrial: (productId, subscriptionId, paymentMethodId) =>
        dispatch(upgradeTrial(productId, subscriptionId, paymentMethodId)),
    },
  };
};

const CheckoutModal = ({
  company,
  countries,
  paymentMethods,
  paymentResult,
  product,
  subscription,
  user,
  isLoading,
  isOpen,
  onCancel,
  onClose,
  onUpgradeTrial,
  dispatch,
}) => {
  // Form state
  const [step, setStep] = useState(1);
  const [confirmText, setConfirmText] = useState('Next');
  const [isTermsAgree, setTermsAgree] = useState(false);
  const [disabledConfirm, setDisabledConfirm] = useState(true);
  const [disabledCancel, setDisabledCancel] = useState(false);
  const [paymentMethodId, setPaymentMethodId] = useState(null);
  const [, setPaymentMethods] = useState(company || {});

  useEffect(() => {
    if (!countries) dispatch.fetchCountries();
  }, []);

  useEffect(() => {
    if (company && company.stripeDefaultPaymentMethod && !paymentMethodId) {
      setPaymentMethodId(company.stripeDefaultPaymentMethod);
    }
  }, [company, step]);

  useEffect(() => {
    if (paymentResult) {
      if (paymentResult.isSuccess) {
        setStep(3);
        setDisabledCancel(true);
        setConfirmText('Close');
      } else {
        console.error(paymentResult);
      }
    }
  }, [paymentResult]);

  useEffect(() => {
    setDisabledConfirm(!isTermsAgree);
  }, [isTermsAgree]);

  useEffect(() => {
    if (step === 2) {
      setDisabledConfirm(paymentMethods.length <= 0);
    }
    if (step === 3) {
      setDisabledConfirm(false);
    }
  }, [step, paymentMethods.length]);

  const resetState = () => {
    setStep(1);
    setConfirmText('Next');
    setDisabledConfirm(false);
    setDisabledCancel(false);
    setPaymentMethodId(null);
  };

  const handleClose = e => {
    dispatch.clearPaymentResult();
    onClose(e);
  };

  const handleConfirm = () => {
    if (step === 1) {
      setStep(2);
      setDisabledConfirm(paymentMethods.length === 0);
      setConfirmText('Confirm');
    }

    if (step === 2) {
      if (subscription && subscription.isTrial) {
        dispatch.upgradeTrial(
          product.productId,
          subscription.id,
          paymentMethodId,
        );
        onUpgradeTrial(paymentMethodId);
      } else {
        dispatch.subscribePaid(product.productId, paymentMethodId);
      }
    }

    if (step === 3) {
      resetState();
      handleClose();
    }
  };

  const handleCancel = () => {
    if (step === 2) {
      setConfirmText('Next');
      setStep(1);
      return;
    }
    resetState();
    handleClose();
  };

  const handleChangePaymentMethod = paymentMethodId => {
    setPaymentMethodId(paymentMethodId);
    setDisabledConfirm(false);
  };

  const handleSavePaymentMethod = payload => {
    const { paymentMethod, companyData } = payload;
    dispatch.savePaymentMethod(paymentMethod).then(() => {
      dispatch.updateCompany(company.id, companyData);
    });
  };

  return (
    <Modal
      className="modal-checkout"
      modalLabel={`Unlock Full Subscription to ${product.name} AI`}
      cancelLabel={step === 1 ? 'Cancel' : 'Back'}
      confirmLabel={step === 3 ? 'Close' : confirmText}
      showCloseBtn // bool
      disabledConfirm={disabledConfirm} // bool
      noCancel={disabledCancel} // bool
      isOpen={isOpen}
      isLoading={isLoading}
      onCancel={handleCancel}
      onClose={handleClose}
      onConfirm={handleConfirm}
    >
      <div className="modal-checkout__content">
        {step === 1 && (
          <CheckoutStep1
            product={product}
            useTermsAgree={[isTermsAgree, setTermsAgree]}
          />
        )}

        {step === 2 && (
          <CheckoutStep2
            company={company}
            countries={countries}
            paymentMethodId={paymentMethodId}
            paymentMethods={paymentMethods}
            paymentResult={paymentResult}
            onChangePaymentMethod={handleChangePaymentMethod}
            onSavePaymentMethod={handleSavePaymentMethod}
          />
        )}

        {step === 3 && (
          <CheckoutStep3 selectedProduct={product} onClose={handleClose} />
        )}
      </div>
    </Modal>
  );
};

CheckoutModal.propTypes = {
  company: PropTypes.object,
  countries: PropTypes.array,
  paymentMethods: PropTypes.array.isRequired,
  paymentResult: PropTypes.any,
  product: PropTypes.object.isRequired,
  subscription: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpgradeTrial: PropTypes.func.isRequired,
  dispatch: PropTypes.object,
};

CheckoutModal.defaultProps = {
  company: null,
  countries: null,
  paymentResult: null,
  subscription: null,
  dispatch: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutModal);
