import PropTypes from 'prop-types';

class IconColumn {
  constructor({ Header, Cell, ...props }) {
    this.headerClassName = '-icon';
    this.className = '-icon';
    this.sortable = false;
    this.width = 48;

    this.Header = Header;
    this.Cell = Cell;

    Object.entries(props).forEach(([key, value]) => {
      this[key] = value;
    });
  }
}

IconColumn.propTypes = {
  Header: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  Cell: PropTypes.func,
};

IconColumn.defaultProps = {
  Cell: undefined,
};

export default IconColumn;
