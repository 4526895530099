import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FixButton from './FixButton';
import * as taskActions from '../../../../reducers/tasks/actions';
import { calcForDocView } from './helper';

const mapStateToProps = state => {
  const { auth } = state;
  return {
    userId: auth.userId,
    canWriteTask: auth.permissions.canWriteTask,
  };
};

const mapDispatchToProps = {
  addQuickFix: taskActions.addQuickFix,
};

/**
 * The Fix button for 1) creating tasks or 2) doing a quick fix for use in
 * single document view.
 */
const DocViewFixButton = ({
  addQuickFix,
  doc,
  showModal,
  canWriteTask,
  hasValidateProduct,
}) => {
  const {
    isDisabled,
    canDoBoth,
    goToQuickFixImmediately,
    goToTasksImmediately,
    tooltip,
  } = calcForDocView({
    hasValidateProduct,
    canWriteTask,
    doc,
  });

  const handleQuickFix = () => {
    const { docId, companyId } = doc;
    addQuickFix({ docId, companyId });
  };

  const handleAddTask = () => {
    showModal('isTaskModalOpen');
  };

  const handleClick = () => {
    if (goToTasksImmediately) {
      handleAddTask();
      return;
    }
    if (goToQuickFixImmediately) {
      handleQuickFix();
    }
  };

  const handleAction = (event, selectedAction) => {
    if (selectedAction === 'startTask') {
      handleAddTask();
      return;
    }
    if (selectedAction === 'addQuickFix') {
      handleQuickFix();
    }
  };

  return (
    <FixButton
      disabled={isDisabled}
      disablePopover={!canDoBoth}
      onAction={handleAction}
      onClick={handleClick}
      tooltip={tooltip}
    />
  );
};

DocViewFixButton.propTypes = {
  doc: PropTypes.shape({
    docId: PropTypes.string.isRequired,
    companyId: PropTypes.string,
    canQuickFix: PropTypes.bool,
  }).isRequired,
  addQuickFix: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  canWriteTask: PropTypes.bool.isRequired,
  hasValidateProduct: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocViewFixButton);
