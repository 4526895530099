import React from 'react';
import { FaEllipsisV } from 'react-icons/fa';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './_button-variants.scss';

const FilterButton = ({ className, onClick, title, ...props }) => {
  return (
    <button
      type="button"
      className={classNames('filter-btn', className)}
      title={title}
      onClick={onClick}
      {...props}
    >
      <FaEllipsisV />
      <span className="filter-btn__label">{title}</span>
    </button>
  );
};

FilterButton.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
};

FilterButton.defaultProps = {
  className: null,
  title: undefined,
  onClick: () => {},
};

export default FilterButton;
