import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { toggleAccordionPanel } from './helpers';
import './_accordion.scss';

const AccordionBody = ({ children, isOpen, variant }) => {
  const outerRef = useRef();
  const innerRef = useRef();

  useEffect(() => {
    toggleAccordionPanel(outerRef.current, innerRef.current, isOpen);
  }, [isOpen]);

  return (
    <div className="accordion__body" ref={outerRef}>
      <div
        className={classNames(
          'accordion__body-inner',
          variant === 'padded-with-line' &&
            'accordion__body-inner--padded-line',
        )}
        ref={innerRef}
      >
        {children}
      </div>
    </div>
  );
};

AccordionBody.propTypes = {
  variant: PropTypes.oneOf(['default', 'padded-with-line']),
  children: PropTypes.node,
  isOpen: PropTypes.bool,
};

AccordionBody.defaultProps = {
  variant: 'default',
  children: null,
  isOpen: false,
};

export default AccordionBody;
