import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Form, {
  Checkbox,
  TextInput,
  validatorType,
  useForm,
  RadioInput,
  SelectInput,
} from '../../Form';
import { fetchCountries } from '../../../reducers/lookups/actions';
import { createCompany } from '../../../reducers/admin/actions';
import { CompanyStatusType, UserType } from '../../../common/types';
import { RegionLookup } from '../../../common/lookups';
import { Button } from '../../../library/atoms/Buttons';
import Page, {
  PageInner,
  PageNav,
  PageHeader,
  PageBody,
  PageRow,
  PageCol,
} from '../../Page';
import './Companies.scss';

const initialState = {
  firstName: {
    required: true,
  },
  lastName: {
    required: true,
  },
  email: {
    required: true,
    validators: [validatorType.Email],
  },
  companyName: {
    required: true,
  },
  country: {
    required: true,
  },
  region: {
    required: true,
    value: RegionLookup.AUS,
  },
  userType: {
    required: true,
    value: UserType.Business,
  },
  sendInvite: {
    checked: false,
  },
};

const mapStateToProps = state => {
  const { admin, lookups } = state;
  const { countries } = lookups;

  return {
    admin,
    countries: countries && countries.data ? countries.data : null,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      createCompany: details => dispatch(createCompany(details)),
      fetchCountries: () => dispatch(fetchCountries()),
    },
  };
};

const AddCompanyForm = ({ dispatch, countries }) => {
  const [form] = useForm(initialState);
  const { fields, errors } = form;
  const hasErrors = Object.keys(errors || {}).length > 0;

  useEffect(() => {
    dispatch.fetchCountries();
  }, []);

  const countryOptions = (countries || []).map(f => ({
    value: f.name,
    label: f.name,
  }));

  const onSubmit = () => {
    const companyDetails = {
      email: fields.email.value,
      userType: fields.userType.value,
      firstName: fields.firstName.value,
      lastName: fields.lastName.value,
      companyName: fields.companyName.value,
      country: fields.country.value,
      sendInvite: fields.sendInvite.value,
      region: fields.region.value,
      status: CompanyStatusType.Pending,
    };

    dispatch.createCompany(companyDetails);
  };

  return (
    <Page className="admin admin-company" title="Add Company - Internal Admin">
      <PageInner>
        <PageNav
          items={[
            { label: 'Internal Admin', to: '/admin' },
            { label: 'Add company', to: `admin/companies/add` },
          ]}
        />
        <PageHeader>
          <h1 className="page__heading">Add company</h1>
        </PageHeader>
        <PageBody>
          <Form className="company-form" onSubmit={onSubmit}>
            <div>
              <label htmlFor="userType" className="form__input-label">
                Technical or business user?
              </label>

              <RadioInput
                label={UserType.Technical}
                name="userType"
                id={UserType.Technical}
                value={UserType.Technical}
                errors={errors}
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                checked={fields.userType.value === UserType.Technical}
              />

              <RadioInput
                label={UserType.Business}
                name="userType"
                id={UserType.Business}
                value={UserType.Business}
                errors={errors}
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                checked={fields.userType.value === UserType.Business}
              />
            </div>
            <PageRow>
              <PageCol>
                <TextInput
                  label="First Name"
                  name="firstName"
                  value={fields.firstName.value}
                  errors={errors}
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  required
                />
              </PageCol>
              <PageCol>
                <TextInput
                  label="Last Name"
                  name="lastName"
                  value={fields.lastName.value}
                  errors={errors}
                  onBlur={form.handleBlur}
                  onChange={form.handleChange}
                  required
                />
              </PageCol>
            </PageRow>
            <TextInput
              type="email"
              label="Email"
              name="email"
              value={fields.email.value}
              errors={errors}
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              required
            />
            <TextInput
              label="Company Name"
              name="companyName"
              value={fields.companyName.value}
              errors={errors}
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              required
            />
            <SelectInput
              className="country-select"
              label="Country *"
              name="country"
              placeholder="Country"
              value={fields.country.value}
              errors={errors}
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              options={countryOptions}
              required
            />
            <div>
              <label htmlFor="userType" className="form__input-label">
                Region
              </label>
              <RadioInput
                label="AUS"
                name="region"
                id={RegionLookup.AUS}
                value={RegionLookup.AUS}
                errors={errors}
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                checked={fields.region.value === RegionLookup.AUS}
              />

              <RadioInput
                label="US"
                name="region"
                id={RegionLookup.US}
                value={RegionLookup.US}
                errors={errors}
                onBlur={form.handleBlur}
                onChange={form.handleChange}
                checked={fields.region.value === RegionLookup.US}
              />
            </div>
            <div className="company-form__checkbox">
              <Checkbox
                id="sendInvite"
                name="sendInvite"
                label="Send Invite to Account Owner"
                defaultChecked={!!fields.sendInvite.value}
                onBlur={form.handleBlur}
                onChange={form.handleChange}
              />
            </div>
            <PageRow className="company-form__actions">
              <Button onClick={() => form.reset()} variant="outline" size="lg">
                Reset
              </Button>
              <Button disabled={hasErrors} onClick={onSubmit} size="lg">
                Submit
              </Button>
            </PageRow>
          </Form>
        </PageBody>
      </PageInner>
    </Page>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCompanyForm);
