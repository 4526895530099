const loadHubspot = callback => {
  const existingScript = document.getElementById('hubspot');
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = `//js.hs-scripts.com/${process.env.HUBSPOT_PORTAL_ID}.js`;
    script.id = 'hubspot';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};
export default loadHubspot;
