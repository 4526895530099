import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { replace } from 'connected-react-router';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {
  ExportButton,
  ExtractButton,
  DeleteButton,
  UploadButton,
  SplitButton,
} from '../Buttons';
import DocListFixButton from '../FixButton/DocListFixButton';
import TagDropDown from '../../../../components/UI/TagDropdown/TagDropdown';
import { addNotification } from '../../../../reducers/notifications';
import { setNextDocSplitTaskFilters } from '../../../../reducers/nextTask';
import { STYLE } from '../../../../components/UI/Notification/Notification';

const mapStateToProps = state => {
  const { auth, tags } = state;
  return {
    userId: auth.userId,
    canUploadDocument: auth.permissions.canUploadDocument,
    canSplitDocument: auth.permissions.canSplitDocument,
    canExportDocument: auth.permissions.canExportDocument,
    canDeleteDocument: auth.permissions.canExportDocument,
    canTagDocument: auth.permissions.canTagDocument,
    canWriteTag: auth.permissions.canWriteTag,
    canExtractDocument: auth.permissions.canExtractDocument,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      navigate: path => dispatch(replace(path)),
      notifyError: () =>
        dispatch(
          addNotification({
            style: STYLE.ERROR,
            message: 'Something went wrong, please try again later.',
          }),
        ),
      setNextDocSplitTaskFilters: () => dispatch(setNextDocSplitTaskFilters()),
    },
  };
};

const DefaultBtnGroup = ({
  noOfSelectedDocs,
  batchSelect,
  showModal,
  showExtractModal,
  isExporting,
  selectedDocs,
  applyTags,
  onTagDropdownConfirm,
  onTagDropdownSearch,
  onCreateTag,
  selectedTags,
  hasValidateProduct,
  canUploadDocument,
  canSplitDocument,
  canExportDocument,
  canDeleteDocument,
  canTagDocument,
  canWriteTag,
  canExtractDocument,
}) => {

  return (
    <div className="col-xs-12 col-lg-10 col-gutter-b">
      <div className="docs__actions" data-tut="reactour_4">
        {canUploadDocument && (
          <span
            data-tooltip-id="doctools-button-tooltip"
            data-tooltip-content="Upload document(s) that require extraction"
          >
            <UploadButton
              onClick={() => showModal('isUploadModalOpen')}
              data-tut="reactour_intro_1"
            />
          </span>
        )}
        {
          <>
            {canSplitDocument && (
              <span>
                <SplitButton
                  isDisabled={
                    !!(!noOfSelectedDocs || batchSelect.isSelectAllResults)
                  }
                  data-tut="reactour_intro_2"
                  showModal={showModal}
                  hasValidateProduct={hasValidateProduct}
                />
              </span>
            )}
            {canExtractDocument && (
              <span
                data-tooltip-id="doctools-button-tooltip"
                data-tooltip-content="Extract unstructured data from selected document(s) using one of your AI product subscriptions"
              >
                <ExtractButton
                  className="docs__action-button"
                  isDisabled={
                    !!(!noOfSelectedDocs || batchSelect.isSelectAllResults)
                  }
                  onClick={showExtractModal}
                  data-tut="reactour_intro_3"
                />
              </span>
            )}
            <span>
              <DocListFixButton
                selectedDocs={selectedDocs}
                showModal={showModal}
                noOfSelectedDocs={noOfSelectedDocs}
                hasValidateProduct={hasValidateProduct}
                data-tut="reactour_5"
              />
            </span>
            {canTagDocument && (
              <span>
                <TagDropDown
                  isDisabled={
                    !!(!noOfSelectedDocs || batchSelect.isSelectAllResults) ||
                    !applyTags
                  }
                  enableTagCreation={canWriteTag}
                  data-tut="reactour_6"
                  tags={applyTags}
                  onConfirm={onTagDropdownConfirm}
                  onSearch={onTagDropdownSearch}
                  onCreateTag={onCreateTag}
                  selectedTags={selectedTags}
                />
              </span>
            )}
            {canExportDocument && (
              <span
                data-tooltip-id="doctools-button-tooltip"
                data-tooltip-content="Export extracted data from selected document(s) as a CSV file"
              >
                <ExportButton
                  id="export_button"
                  className="docs__action-button"
                  isDisabled={!noOfSelectedDocs}
                  isExporting={isExporting}
                  onClick={() => showModal('isExportModalOpen')}
                  data-tut="reactour_intro_6"
                />
              </span>
            )}
            {canDeleteDocument && (
              <span
                data-tooltip-id="doctools-button-tooltip"
                data-tooltip-content="Delete selected document(s)"
              >
                <DeleteButton
                  className="docs__action-button"
                  isDisabled={
                    !!(!noOfSelectedDocs || batchSelect.isSelectAllResults)
                  }
                  onClick={() => showModal('isDeleteModalOpen')}
                  data-tut="reactour_5"
                />
              </span>
            )}
          </>
        }
      </div>
      <ReactTooltip
        id="doctools-button-tooltip"
        place="bottom"
        style={{
          top: '4',
          bottom: '4',
          left: '4',
          position: 'fixed',
          zIndex: 9999,
        }}
      />
    </div>
  );
};

DefaultBtnGroup.defaultProps = {
  noOfSelectedDocs: 0,
  batchSelect: {},
  showModal: () => {},
  showExtractModal: () => {},
  isExporting: false,
  hasValidateProduct: false,
  selectedDocs: {},
  companyTags: [],
  onTagDropdownConfirm: () => {},
  onCreateTag: () => {},
  selectedTags: {},
};

DefaultBtnGroup.propTypes = {
  dispatch: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
    notifyError: PropTypes.func.isRequired,
    setNextDocSplitTaskFilters: PropTypes.func.isRequired,
  }).isRequired,
  noOfSelectedDocs: PropTypes.number,
  batchSelect: PropTypes.shape({
    isSelectAllResults: PropTypes.bool,
  }),
  showModal: PropTypes.func,
  showExtractModal: PropTypes.func,
  isExporting: PropTypes.bool,
  selectedDocs: PropTypes.shape({}),
  companyTags: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  onTagDropdownConfirm: PropTypes.func,
  onCreateTag: PropTypes.func,
  selectedTags: PropTypes.shape({}),
  hasValidateProduct: PropTypes.bool,
  canUploadDocument: PropTypes.bool.isRequired,
  canSplitDocument: PropTypes.bool.isRequired,
  canExportDocument: PropTypes.bool.isRequired,
  canDeleteDocument: PropTypes.bool.isRequired,
  canWriteTag: PropTypes.bool.isRequired,
  canTagDocument: PropTypes.bool.isRequired,
  canExtractDocument: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultBtnGroup);
