import * as DemoService from '../../../services/DemoService';

export const REQUEST_DOC = 'sypht/signals/REQUEST_DOC';
export const RECEIVE_DOC = 'sypht/signals/RECEIVE_DOC';
export const RECEIVE_ERROR = 'sypht/signals/RECEIVE_ERROR';

// DISPATCHERS
const requestDoc = () => {
  return {
    type: REQUEST_DOC,
    payload: {},
  };
};

const receiveDoc = data => {
  return {
    type: RECEIVE_DOC,
    payload: {
      data,
    },
  };
};

const receiveError = () => {
  return {
    type: RECEIVE_ERROR,
  };
};

// RETRIEVE DOC
export const retrieveDoc = docId => {
  return async dispatch => {
    try {
      dispatch(requestDoc());
      const [doc] = await Promise.all([DemoService.retrieveDoc(docId)]);
      if (doc.data) {
        const data = {
          ...doc.data,
        };
        dispatch(receiveDoc(data));
      } else {
        dispatch(receiveError());
        console.error(`Error retrieving doc '${docId}'`, doc);
      }
    } catch (err) {
      dispatch(receiveError());
      console.error(err);
    }
  };
};
