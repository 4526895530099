import DropdownElem from './DropdownMenuElem';

export const DropdownMenuBody = props =>
  DropdownElem({ ...props, elemName: 'body' });
export const DropdownMenuFooter = props =>
  DropdownElem({ ...props, elemName: 'footer' });
export const DropdownMenuHeader = props =>
  DropdownElem({ ...props, elemName: 'header' });
export { default as DropdownListItem } from '../../atoms/DropdownListItem/DropdownListItem';

export { default as DropdownMenuHeading } from './DropdownMenuHeading';
export { default as DropdownMenu } from './DropdownMenu';
