/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { login, clearState } from '../../reducers/authReducer';
import { Button } from '../../library/atoms/Buttons';
import TextLink from '../UI/TextLink/TextLink';
import hubspotTracker from '../../common/helpers/hubspotTracker';
import Form, { TextInput, useForm, validatorType } from '../Form';
import { PageHelmet } from '../Page';
import './Login.scss';

const INITIAL_STATE = {
  email: {
    value: '',
    validators: [validatorType.Email],
  },
  password: {
    value: '',
  },
};

const mapStateToProps = state => ({
  error: state.auth.error,
  isAuthenticated: state.auth.isAuthenticated,
  isAuthenticating: state.auth.isAuthenticating,
  loginProvider: process.env.OAUTH_PROVIDER,
  location: state.router.location,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      login,
      clearState,
    },
    dispatch,
  );

const Login = ({
  error,
  isAuthenticated,
  location,
  login,
  isAuthenticating,
}) => {
  hubspotTracker('/login');

  const [form] = useForm({ ...INITIAL_STATE });
  const { fields, errors } = form;
  const [errorMessage, setErrorMessage] = useState(error);
  const [successMessage, setSuccessMessage] = useState(null);

  const authRedirectTo =
    location.state && location.state.referrer
      ? location.state.referrer
      : '/documents?page=1';

  // change password callback url
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('message')) {
      setSuccessMessage(urlParams.get('message'));
    }
  }, []);

  useEffect(() => {
    if (errorMessage) setSuccessMessage(null);
  }, [errorMessage]);

  useEffect(() => {
    window.sessionStorage.setItem('redirectTo', authRedirectTo);
  }, [location, authRedirectTo]);

  useEffect(() => {
    if (error && error.description) {
      setErrorMessage('Incorrect email or password');
    }
  }, [error]);

  const onSubmit = e => {
    e.preventDefault();

    setErrorMessage(null);
    const { email, password } = form.fields;
    const hasEmptyField = email.value === '' || password.value === '';

    form.handleBlur({
      target: {
        name: 'email',
        value: email.value,
      },
    });

    if (hasEmptyField || !form.isValid) {
      setErrorMessage('Please fill-up the fields correctly');
    } else {
      login(fields.email.value, fields.password.value);
    }
  };

  // eslint-disable-next-line no-nested-ternary
  return !isAuthenticated && !isAuthenticating ? (
    <div className="login__container">
      <PageHelmet title="Login" />
      <h1 className="login__title">Log in to Sypht</h1>
      <Form onSubmit={e => onSubmit(e)}>
        <div className="login__panel">
          {successMessage ? (
            <div className="login__success-message">{successMessage}</div>
          ) : null}
          <TextInput
            type="email"
            label="Email Address"
            name="email"
            value={fields.email.value}
            errors={errors}
            onBlur={form.handleBlur}
            onChange={form.handleChange}
          />

          <TextInput
            type="password"
            label="Password"
            name="password"
            value={fields.password.value}
            errors={errors}
            onBlur={form.handleBlur}
            onChange={form.handleChange}
          />

          {errorMessage ? (
            <div className="login__error-message">{errorMessage}</div>
          ) : null}

          <Button type="submit" id="login_button" className="login__btn">
            Login
          </Button>

          <div className="login__details">
            <p>
              Not a member? <TextLink path="/register">Sign up now</TextLink>
            </p>
            <p>
              <TextLink path="/forgotpassword">Forgot Password?</TextLink>
            </p>
          </div>
        </div>
      </Form>
    </div>
  ) : !isAuthenticating ? (
    <Redirect to={authRedirectTo} />
  ) : null;
};

Login.defaultProps = {
  error: null,
};

Login.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isAuthenticating: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      referrer: PropTypes.string,
    }),
  }).isRequired,
  error: PropTypes.shape({
    description: PropTypes.string,
  }),
  login: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
