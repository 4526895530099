export const blinkIcon = elem => {
  if (elem) {
    elem.classList.add('blink');
    setTimeout(() => {
      elem.classList.remove('blink');
    }, 300);
  }
};

export const showRipple = (elem, event) => {
  if (!elem) return;
  const { x, y } = elem.getBoundingClientRect();
  const { clientY, clientX } = event;

  // create ripple elem
  const rippleElem = document.createElement('div');
  rippleElem.classList.add('ripple');
  rippleElem.style.top = `${clientY - y}px`;
  rippleElem.style.left = `${clientX - x}px`;
  elem.appendChild(rippleElem);

  // clean up
  setTimeout(() => {
    rippleElem.remove();
  }, 5000);
};

export const scrollX = (elem, posX, direction) => {
  const scrollArea = elem;
  const duration = 500;

  let startTime;
  window.requestAnimationFrame(function step(stepTime) {
    // get start time
    if (!startTime) startTime = stepTime;

    // calculate distance vs point in time
    const time = stepTime - startTime;
    const timeElapsed = Math.min(time / duration, 1); // as a %

    // scroll left
    if (direction === 'left') {
      scrollArea.scrollLeft = posX * (1 - timeElapsed);
    }

    // scroll right
    else if (direction === 'right') {
      scrollArea.scrollLeft = posX * timeElapsed;
    }

    // exit if complete
    if (time < duration) {
      window.requestAnimationFrame(step);
    }
  });
};
