import React, { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import history from '../../../../services/historyService';
import { getQueryStr } from '../../../../common/helpers';
import { listProductRules } from '../../store/rules/actions';
import { fetchSubscriptions } from '../../../../reducers/subscriptions/actions';
import {
  retrieveSettings,
  updateSettings,
} from '../../../../reducers/settings/actions';

import { Button } from '../../../../library/atoms/Buttons';
import Page, { PageBody, PageCta, PageHeader, PageInner } from '../../../Page';
import { Tab, Tabs, TabsContainer, TabPanel } from '../../../UI/Tabs';
import Toggle from '../../../Form/components/Toggle/Toggle';
import ProductDropdown from './components/ProductDropdown/ProductDropdown';
import { ListView, DeveloperView } from './components';
import './_rules.scss';

const RULES_ENABLED = 'isAutoReviewTasks';

const mapStateToProps = state => {
  const { auth, settings, subscriptions, validateRules } = state;
  const productId = getQueryStr(history.location.search, 'product');

  return {
    productId,
    rulesets: validateRules.rules,
    user: auth.user ? auth.user.data : null,
    isEnabled: settings.data[RULES_ENABLED],
    subscriptions: subscriptions.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      fetchSubscriptions: () => dispatch(fetchSubscriptions()),
      listProductRules: () => dispatch(listProductRules()),
      retrieveSettings: s => dispatch(retrieveSettings(s)),
      updateSettings: s => dispatch(updateSettings(s)),
    },
  };
};

const Rules = ({
  productId,
  rulesets,
  subscriptions,
  user,
  isEnabled,
  dispatch,
}) => {
  const [selectedProductId, setSelectedProductId] = useState(productId);
  const rules = selectedProductId && rulesets && rulesets[selectedProductId];

  useEffect(() => {
    if (!rulesets) dispatch.listProductRules();
    if (!subscriptions) dispatch.fetchSubscriptions();
    if (isEnabled === undefined) dispatch.retrieveSettings([RULES_ENABLED]);
  }, [user]);

  const handleEnableChange = e => {
    dispatch.updateSettings([
      {
        [RULES_ENABLED]: e.target.checked,
      },
    ]);
  };

  const handleProductChange = product => {
    if (product) {
      setSelectedProductId(product.id);
      const url = `?product=${encodeURIComponent(product.id)}`;
      window.history.replaceState(null, null, url);
      return;
    }

    setSelectedProductId(null);
    window.history.replaceState(null, null, window.location.pathname);
  };

  return !subscriptions ? null : (
    <Page className="rules" title="Validate - Rules">
      <PageInner>
        <PageHeader className="rules__header">
          <h1 className="page__heading">Task Generation Rules</h1>
          <PageCta>
            <Button
              className="rules__create-btn"
              onClick={() => {
                history.push(
                  `/validate/rules/create${
                    selectedProductId
                      ? `?product=${encodeURIComponent(selectedProductId)}`
                      : ''
                  }`,
                );
              }}
            >
              <FaPlus className="fa-plus" /> Add Rule
            </Button>
          </PageCta>
        </PageHeader>
        <PageBody>
          <div className="rules__content">
            <p>
              Automate your human-in-the-loop supervision with task generation
              rules. Select from any of your available AI products, and define
              field-level rules to automatically generate tasks when:
            </p>
            <ul>
              <li>Field % prediction confidence is below set threshold</li>
              <li>Field label is missing</li>
              <li>Field value is outside of set range</li>
            </ul>
            <p>
              Rules are checked at the point of extraction, and if met then
              tasks are generated instantly for your human reviewers to action,
              assuring human-in-the-loop supervision and improving your
              confidence in data extraction quality.
            </p>
            <p className="rules__auto-check-container">
              {isEnabled !== undefined && (
                <Toggle
                  label="Automatically generate review tasks"
                  defaultChecked={isEnabled}
                  onChange={handleEnableChange}
                />
              )}
            </p>
          </div>
          <ProductDropdown
            className="product-dropdown"
            subscriptions={subscriptions}
            defaultProductId={selectedProductId}
            onChange={handleProductChange}
            isClearable
          />
          {selectedProductId && (
            <TabsContainer defaultTab="list_view">
              <Tabs className="rules__tabs">
                <Tab tabFor="list_view">List View</Tab>
                <Tab tabFor="developer_view">Developer View</Tab>
              </Tabs>
              <TabPanel id="list_view">
                <ListView productId={selectedProductId} rules={rules} />
              </TabPanel>
              <TabPanel id="developer_view">
                <DeveloperView productId={selectedProductId} rules={rules} />
              </TabPanel>
            </TabsContainer>
          )}
        </PageBody>
      </PageInner>
    </Page>
  );
};

Rules.propTypes = {
  productId: PropTypes.string,
  rulesets: PropTypes.object,
  user: PropTypes.object,
  subscriptions: PropTypes.arrayOf(PropTypes.object),
  isEnabled: PropTypes.bool,
  dispatch: PropTypes.shape({
    fetchSubscriptions: PropTypes.func,
    retrieveProductRules: PropTypes.func,
  }),
};

Rules.defaultProps = {
  productId: null,
  rulesets: null,
  user: null,
  subscriptions: null,
  isEnabled: undefined,
  dispatch: {
    fetchSubscriptions: () => {},
    retrieveProductRules: () => {},
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(Rules);
