import React, { useEffect, useRef, useState } from 'react';
import Proptypes from 'prop-types';
import classNames from 'classnames';
import Menu from './Menu';
import './Dropdown.scss';

/**
 * DropdownV3 is for menu dropdown rather than typical react-select
 * dropdowns. E.g. dropdown for help menu
 * @param {*} param0
 */
const Dropdown = ({
  name,
  icon,
  title,
  data,
  dataTip,
  disableItemClose,
  className,
}) => {
  const [open, setOpen] = useState(false);
  const dropRef = useRef(null);
  const offset = useRef(null);
  const btnRef = useRef(null);

  useEffect(() => {
    if (dropRef.current)
      offset.current = dropRef.current.getBoundingClientRect();
    window.addEventListener('resize', () => {
      if (dropRef.current)
        offset.current = dropRef.current.getBoundingClientRect();
    });
  }, []);

  return (
    <div className={`dropdown__${name}`} ref={dropRef}>
      <button
        ref={btnRef}
        data-tooltip-content={dataTip}
        className={classNames(className, 'dropdown__button', {
          active: open,
        })}
        onClick={() => setOpen(!open)}
      >
        {icon}
      </button>

      <Menu
        items={data}
        isOpen={open}
        className={className}
        offset={offset.current}
        title={title}
        setOpen={setOpen}
        btnRef={btnRef}
        disableItemClose={disableItemClose}
      />
    </div>
  );
};

Dropdown.propTypes = {
  name: Proptypes.string.isRequired,
  icon: Proptypes.node.isRequired,
  title: Proptypes.string.isRequired,
  data: Proptypes.array,
  dataTip: Proptypes.string,
};

Dropdown.defaultProps = {
  data: [],
  dataTip: null,
};

export default Dropdown;
