import React, { useEffect, useState } from 'react';
import { FaRegCopy as FaCopy, FaEye, FaEyeSlash } from 'react-icons/fa';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { blinkIcon, copyToClipboard } from '../../common/helpers';
import { fetchSecret } from '../../reducers/credentials/actions';
import './Credentials.scss';

const mapStateToProps = (state, props) => {
  const { credential } = props;
  return {
    credential,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: {
      fetchSecret: (id, companyId) => dispatch(fetchSecret(id, companyId)),
    },
  };
};

const CredentialClientSecret = ({ credential, dispatch, companyId }) => {
  const { clientId, clientSecret } = credential;
  const [isShowSecret, setShowSecret] = useState(false);

  const handleCopyKey = e => {
    e.stopPropagation();
    copyToClipboard(clientId);
    blinkIcon(e.currentTarget);
  };

  const handleCopySecret = e => {
    e.stopPropagation();
    copyToClipboard(clientSecret);
    blinkIcon(e.currentTarget);
  };

  const handleToggleSecret = () => {
    setShowSecret(s => !s);
  };

  useEffect(() => {
    if (!clientSecret) {
      dispatch.fetchSecret(clientId, companyId);
    }
  }, [clientId, clientSecret]);

  return (
    <form className="credential__client">
      <div className="credential__row">
        <label className="credential__label" htmlFor={`key_${clientId}`}>
          Client key
        </label>
        <input
          type="text"
          id={`key_${clientId}`}
          className="credential__input"
          defaultValue={clientId}
          readOnly
        />
        <div className="credential__actions">
          <button
            className="credential__button"
            type="button"
            onClick={handleCopyKey}
          >
            <span className="credential__button-text">Copy client key</span>
            <FaCopy />
          </button>
        </div>
      </div>
      <div className="credential__row">
        <label className="credential__label" htmlFor={`secret_${clientId}`}>
          Client secret
        </label>
        <input
          type={isShowSecret ? 'text' : 'password'}
          id={`secret_${clientId}`}
          className="credential__input credential__input--secret"
          defaultValue={clientSecret}
          readOnly
        />
        <div className="credential__actions">
          <button
            className="credential__button"
            type="button"
            onClick={handleCopySecret}
          >
            <span className="credential__button-text">Copy client secret</span>
            <FaCopy />
          </button>
          <button
            className="credential__button credential__button--toggle"
            type="button"
            onClick={handleToggleSecret}
          >
            <span className="credential__button-text">Show client secret</span>
            {isShowSecret ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>
      </div>
    </form>
  );
};

CredentialClientSecret.propTypes = {
  credential: PropTypes.object.isRequired,
  dispatch: PropTypes.shape({
    fetchSecret: PropTypes.func,
  }),
};

CredentialClientSecret.defaultProps = {
  dispatch: {
    fetchSecret: () => {},
  },
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CredentialClientSecret);
