import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import productNames from '../../common/lookups/platformProductLookup';
import Dropdown from '../../library/molecules/Dropdown';
import DropdownListItem from '../../library/atoms/DropdownListItem/DropdownListItem';
import DropdownList from '../../library/atoms/DropdownList/DropdownList';
import {
  rolesByProductFailedFetchText,
  roleDataNotAvailable,
} from '../../reducers/company/messages';

/**
 * Select roles for the user.
 */
const EditUserRoles = ({ roleData, seatData, onChange }) => {
  if (roleData.error) {
    return (
      <div className="form__group-roles-field">
        {rolesByProductFailedFetchText}
      </div>
    );
  }

  if (roleData.loading) {
    return null;
  }

  if (!roleData.data) {
    return (
      <div className="form__group-roles-field">{roleDataNotAvailable}</div>
    );
  }

  const renderSeats = productId => {
    if (!seatData[productId]) {
      return null;
    }
    const { usedSeats, seats, seatsAvailable } = seatData[productId];
    return (
      <span
        className={classNames('form__field-label-note', {
          alert: !seatsAvailable,
        })}
      >
        {usedSeats}/{seats} seats used
      </span>
    );
  };

  const renderNote = productId => {
    if (!seatData[productId]) {
      return null;
    }
    const { seatsAvailable, canStillAssignSeat } = seatData[productId];
    if (seatsAvailable) {
      return null;
    }
    if (canStillAssignSeat) {
      return null;
    }
    return (
      <span className={classNames('form__field-note alert')}>
        You have assigned all your seats. Please{' '}
        <a
          target="_blank"
          href="https://www.sypht.com/contact"
          rel="noreferrer"
        >
          contact us
        </a>{' '}
        to purchase more.
      </span>
    );
  };

  return roleData.data.map(({ productId, currentRole, roles }) => {
    return (
      <div key={productId} className="form__group-roles-item-expanded">
        <label className="form__field-label-compact" htmlFor={productId}>
          {productNames[productId] || productId}
          {renderSeats(productId)}
        </label>
        <Dropdown id={productId} label={currentRole?.roleName}>
          <DropdownList>
            {roles.map(role => (
              <DropdownListItem
                key={role.id}
                onClick={evt => onChange(evt, role, productId)}
                selected={currentRole?.id === role.id}
              >
                {role.roleName}
              </DropdownListItem>
            ))}
          </DropdownList>
        </Dropdown>
        {renderNote(productId)}
      </div>
    );
  });
};

EditUserRoles.propTypes = {
  onChange: PropTypes.func.isRequired,
  roleData: PropTypes.shape({
    error: PropTypes.shape({}),
    loading: PropTypes.bool,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        productId: PropTypes.string.isRequired,
        currentRole: PropTypes.oneOfType([
          PropTypes.oneOf([null]),
          PropTypes.shape({
            id: PropTypes.string,
            roleName: PropTypes.string,
          }),
        ]),
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            roleName: PropTypes.string,
          }),
        ),
      }),
    ),
  }).isRequired,
  seatData: PropTypes.shape({
    usedSeats: PropTypes.number,
    seats: PropTypes.number,
  }),
};

EditUserRoles.defaultProps = {
  seatData: {},
};

export default EditUserRoles;
