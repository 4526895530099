import UserService from '../../services/UserService';

export const REQUEST_USERS = 'sypht/users/REQUEST_USERS';
export const RECEIVE_USERS = 'sypht/users/RECEIVE_USERS';
export const REFRESH_USERS = 'sypht/users/REFRESH_USERS';

const requestUsers = () => {
  return {
    type: REQUEST_USERS,
    payload: {},
  };
};

const receiveUsers = data => {
  return {
    type: RECEIVE_USERS,
    payload: {
      data,
      loading: true,
      receivedAt: Date.now(),
    },
  };
};

const shouldFetchUsers = users => {
  if (!users.data) {
    return true;
  }
  if (users.loading) {
    return false;
  }
  return users.refresh;
};

export const fetchUsers = (companyId = undefined, params) => {
  return async (dispatch, getState) => {
    const { auth, users: retrievedUsers } = getState();
    dispatch(requestUsers());
    const { accessToken, user } = auth;
    const response = await UserService.getUsersByCompany(
      accessToken,
      companyId || user.data.companyId,
      params,
    );
    dispatch(receiveUsers(response.data));
  };
};

export const refreshUsers = () => {
  return {
    type: REFRESH_USERS,
    payload: {},
  };
};
