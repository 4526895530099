import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FieldsetChip = ({
  name,
  value,
  isExtracted,
  defaultSelected,
  onClick,
  ...props
}) => {
  const [isSelected, setSelected] = useState(defaultSelected);

  const handleClick = e => {
    setSelected(x => !x);
    onClick(e, value);
  };

  return (
    <button
      type="button"
      className={classNames('chip', {
        'chip--extracted': isExtracted,
        'chip--selected': isSelected,
      })}
      data-value={value}
      disabled={isExtracted}
      onClick={e => handleClick(e)}
      {...props}
    >
      {name}
    </button>
  );
};

FieldsetChip.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  defaultSelected: PropTypes.bool,
  isExtracted: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

FieldsetChip.defaultProps = {
  defaultSelected: false,
  onClick: () => {},
};

export default FieldsetChip;
