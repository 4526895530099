import { useEffect } from 'react';
import classNames from 'classnames';
import useUrlChanges from '../hooks/useUrlChanges';

import './ElementHighlight.scss';

const useElementHighlight = (
  activeTourIndex,
  activeToursStep,
  tours,
  closeTour,
  isTourOpen,
  handleCaptureTourProgress,
) => {
  const toggleReactToolTip = opacity => {
    // We want to disable ReactTooltip if we are showing the Tooltip of the Platform Tour.
    const reactToolTip = document.querySelector('.ReactTooltip');

    if (reactToolTip) {
      reactToolTip.style.opacity = opacity;
    }
  };

  const handleHideHighlight = () => {
    const overlay = document.querySelector('.overlay-container');
    overlay?.remove();

    toggleReactToolTip(1);
  };

  useUrlChanges(handleHideHighlight);

  useEffect(() => {
    if (isTourOpen === false) {
      handleHideHighlight();
    }
  }, [isTourOpen]);

  const handleElementOnClick = e => {
    handleHideHighlight();
    if (e.srcElement.dataset.tourIdentifier) {
      closeTour();
      handleCaptureTourProgress(e.srcElement.dataset.tourIdentifier);
    } else {
      let parent = e.srcElement.parentElement;
      do {
        if (parent.dataset.tourIdentifier) {
          closeTour();
          handleCaptureTourProgress(parent.dataset.tourIdentifier);
          parent = null;
        } else {
          parent = parent.parentElement;
        }
      } while (parent);
    }
  };

  useEffect(() => {
    if (activeTourIndex === -1 || activeToursStep === -1 || !isTourOpen) {
      return;
    }

    const stepSelector =
      tours[activeTourIndex].tourSteps[activeToursStep].selector;
    const element = document.querySelector(stepSelector);

    if (!element) return;

    element.onclick = handleElementOnClick;
    element.dataset.tourIdentifier = tours[activeTourIndex].id;
    const tourId = element.getAttribute('data-tour-identifier');

    const overlayHighlight = document.createElement('div');
    overlayHighlight.className = 'overlay-highlight';

    const arrow = document.createElement('div');
    arrow.className = 'arrow';

    const overlay = document.createElement('div');
    overlay.className = classNames(tourId, 'overlay-container');
    overlayHighlight.appendChild(arrow);
    overlay.appendChild(overlayHighlight);
    element.appendChild(overlay);

    toggleReactToolTip(0);
  }, [activeTourIndex, activeToursStep, isTourOpen]);
};

export default useElementHighlight;
